import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    authenticate(email, password) {
        const url = `${environment.baseApiUrlWithTrailingSlash}v1.0/restaurant/admin/auth`;
        const request = { email: email, password: password };
        const result = this.httpClient.post(url, request);
        return result;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
