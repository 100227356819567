import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {RestaurantSpecificArrayResultLoadingState} from './helper.statuses';
import {HttpClient} from '@angular/common/http';
import {AppState} from '../../app/store/app.state';
import {Store} from '@ngrx/store';
import ExistingTerminal = Orderly.RestaurantWeb.Api.Messages.Restaurant.ExistingTerminal;
import {CurrentRestaurantServiceBase} from './current-restaurant.service.base';
import GetTerminalsResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.GetTerminalsResponse;
import {first, flatMap, map, tap} from 'rxjs/operators';
import AddTerminalRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddTerminalRequest;
import DeleteTerminalResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.DeleteTerminalResponse;
import AddOrUpdateTerminalResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddOrUpdateTerminalResponse;
import UpdateTerminalRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.UpdateTerminalRequest;
import SetTerminalPinRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.SetTerminalPinRequest;
import UnlinkTerminalResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.UnlinkTerminalResponse;

@Injectable({
              providedIn: 'root'
            })
export class CurrentRestaurantTerminalsService extends CurrentRestaurantServiceBase<ExistingTerminal> {
  private baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  public get currentRestaurantTerminals$(): Observable<RestaurantSpecificArrayResultLoadingState<ExistingTerminal>> {
    return this.dataFeed$;
  }

  constructor(private httpClient: HttpClient, store: Store<AppState>) {
    super(store);
  }

  protected doLoadData(restaurantId: number): Observable<ExistingTerminal[]> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/terminals/all`;
    const response$ = this.httpClient.get<GetTerminalsResponse>(url);

    return response$.pipe(
      tap(x => {
        if (x.status === GetTerminalsResponse.OpStatus.UnknownFailure) {
          throw new Error('Server returned an error. Could not load list of terminals');
        }
      }),
      map(x => x.terminals)
    );
  }

  public add(request: AddTerminalRequest): Observable<AddOrUpdateTerminalResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 first(),
                 flatMap((restaurantId: number) => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/terminal/add`;

                   return this.httpClient.post<AddOrUpdateTerminalResponse>(url, request);
                 })
               );
  }

  public update(terminalId: number, request: UpdateTerminalRequest): Observable<AddOrUpdateTerminalResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 first(),
                 flatMap((restaurantId: number) => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/terminal/${terminalId}/update`;

                   return this.httpClient.post<AddOrUpdateTerminalResponse>(url, request);
                 })
               );
  }

  public setPin(terminalId: number, pin: string): Observable<AddOrUpdateTerminalResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 first(),
                 flatMap((restaurantId: number) => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/terminal/${terminalId}/set-pin`;
                   const request: SetTerminalPinRequest = {pin: pin};

                   return this.httpClient.post<AddOrUpdateTerminalResponse>(url, request);
                 })
               );
  }

  public unlinkDevice(terminalId: number): Observable<UnlinkTerminalResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 first(),
                 flatMap((restaurantId: number) => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/terminal/${terminalId}/unlink-device`;

                   return this.httpClient.post<UnlinkTerminalResponse>(url, {});
                 })
               );
  }

  public delete(terminalId: number): Observable<DeleteTerminalResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 first(),
                 flatMap((restaurantId: number) => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/terminal/${terminalId}/delete`;

                   return this.httpClient.delete<DeleteTerminalResponse>(url);
                 })
               );
  }
}
