<div class="d-flex align-items-center justify-content-center bg-br-primary ht-100v">

  <div class="login-wrapper wd-400 wd-xs-400 pd-25 pd-xs-40 bg-white rounded shadow-base">
    <div class="signin-logo tx-center tx-28 tx-bold tx-inverse pd-md-b-20"><span class="tx-normal">[</span>MY<span class="tx-info">CAFY</span> <span class="tx-normal">]</span></div>

    <div *ngIf="activatedSuccessfully" class="text-center">
      <h4>{{'Your account was successfully activated.' | translate}}</h4>
      <h6 class="mg-xl-t-30-force mg-xs-t-20-force"><a routerLink="/login">{{'Click here to navigate to login page' | translate}}</a></h6>
    </div>

    <form [formGroup]="form" (ngSubmit)="confirm()" *ngIf="!activatedSuccessfully" style="width: 100%;">
      <h4 class="mg-b-15">{{'Fill in the form below to activate your account'}}:</h4>

      <div class="form-group">
        <input class="form-control" type="password" formControlName="password" [placeholder]="'Password' | translate">

        <owc-input-validation-errors [control]="f.password"></owc-input-validation-errors>
      </div>

      <div class="form-group">
        <input class="form-control" type="password" formControlName="passwordConfirmation" [placeholder]="'Password confirmation' | translate">

        <owc-input-validation-errors [control]="f.passwordConfirmation"></owc-input-validation-errors>
      </div>

      <owc-progress-action-button type="submit"
                                  [btnText]="'Sign In' | translate"
                                  [btnClass]="'btn-info btn-block'"
                                  (execute)="confirm()"
                                  [actionInProgress]="actionIsInProgress"
                                  [disabled]="form.invalid"
                                  class="d-block">
      </owc-progress-action-button>
    </form>

  </div>
</div>
