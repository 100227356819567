<ng-container *ngIf="mode$ | async as mode">

  <app-modifier-groups-list [groups]="groups"
                            [ngClass]="{'opened': mode === 'list', 'closed': mode !== 'list'}"
                            (deleteGroup)="deleteGroup($event)"
                            (requestGroupUpdate)="startGroupUpdate($event)"
                            (requestModifierUpdate)="startModifierUpdate($event)"
                            (requestModifierCreate)="startModifierAdd($event)">
  </app-modifier-groups-list>

  <div [class.opened]="mode === 'group'" *ngIf="mode === 'group'">
    <h3 class="mg-b-20 text-white" *ngIf="currentGroupToEdit == null || currentGroupToEdit.id == null">{{'New modifier group' | translate}}</h3>
    <h3 class="mg-b-20 text-white" *ngIf="currentGroupToEdit != null && currentGroupToEdit.id != null">{{'Modifier group' | translate}} <span class="modifier-group-name">{{currentGroupToEdit.name.text}}</span></h3>

    <app-modifier-group-editor [group]="currentGroupToEdit"
                               (save)="saveGroup($event)"
                               (cancel)="cancelGroupEditing()"></app-modifier-group-editor>
  </div>

  <div [class.opened]="mode === 'modifier'" *ngIf="mode === 'modifier'">
    <h3 *ngIf="currentModifierToEdit!.modifier.id == null"
        class="mg-b-20 text-white">{{'New modifier in group' | translate}} <span class="modifier-group-name">{{groups[currentModifierToEdit!.parentGroupIndex].name!}}</span></h3>
    <h3 *ngIf="currentModifierToEdit!.modifier.id != null"
        class="mg-b-20 text-white">{{'Modifier in group' | translate}} <span class="modifier-group-name">{{groups[currentModifierToEdit!.parentGroupIndex].name!}}</span></h3>

    <app-modifier-editor [modifier]="currentModifierToEdit!.modifier"
                         (cancel)="cancelModifierEditing()"
                         (save)="saveModifier($event)" >
    </app-modifier-editor>
  </div>

  <div class="tfoot">
    <div class="row">

      <div class="col-12"
           *ngIf="mode === 'list'">

        <a class="new-element-btn btn btn-outline-light text-white float-right" (click)="addModifierGroup()">
          <i class="icon-plus"></i>New modifier group
        </a>
      </div>

    </div>
  </div>

</ng-container>
