import {Component, ElementRef, ViewChild} from '@angular/core';
import {ImageFileTypeValidator} from 'orderly-web-components';
import {CropImageModalComponent} from '../../../../../../crop-image-modal/crop-image-modal.component';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../../../util/form.utils';
import {
  CategoryImageSelectorFormFieldsDefinition,
} from './category-image-selector-form-fields.definition';
import {ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CategoryImageFromServerModel} from '../../category-image-from-server.model';
import {LocalCategoryImageModel} from '../../local-category-image.model';

@Component({
             selector: 'app-category-image-selector',
             templateUrl: './category-image-selector.component.html',
             styleUrls: ['./category-image-selector.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: CategoryImageSelectorComponent,
                 multi: true,
               },
             ],
           })
export class CategoryImageSelectorComponent implements ControlValueAccessor {

  @ViewChild('categoryImageFileInput', {static: false})
  categoryImageFileInput: ElementRef;

  public formDef: FormDefinition<keyof CategoryImageSelectorFormFieldsDefinition>;

  public isDisabled: boolean = false;

  public get imageName(): string | null {
    const form: CategoryImageSelectorFormFieldsDefinition = this.formDef.form.getRawValue();

    if (form.image == null) {
      return null;
    }

    if (form.image instanceof CategoryImageFromServerModel) {
      return form.image.image.name;
    }

    return form.image.name;
  }

  constructor(private formBuilder: FormBuilder,
              private trnService: TranslateService,
              private modalService: NgbModal) {

    const fieldsDef: FormFieldsDefinition<keyof CategoryImageSelectorFormFieldsDefinition> = {
      image: new FormFieldDefinition(null,
                                     false,
                                     [],
                                     []),
    };

    // const imageValidator = new ImageFileTypeValidator();
    // const imageControlName = nameof<Record<AddOrEditMenuItemCategoryComponentFormFields, any>>('imageName');
    // const imageControlValidator = imageValidator.validateControlAsImage(imageControlName, this.trnService);
    const formOptions = {
      validators: [
        // imageControlValidator
      ]
    };

    this.formDef = new FormDefinition<keyof CategoryImageSelectorFormFieldsDefinition>(fieldsDef,
                                                                                       this.formBuilder,
                                                                                       formOptions);
  }

  onChange: any = (source: LocalCategoryImageModel | null) => {
  };

  onTouched: any = () => {
  };


  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(image: CategoryImageFromServerModel | LocalCategoryImageModel | null): void {

    this.formDef.patchValue({image}, false);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const previewImageFiles = event.target.files;

      reader.readAsDataURL(previewImageFiles[0]);

      reader.onload = () => {
        const selectedFileName = previewImageFiles[0].name;

        if (typeof reader.result === 'string' && ImageFileTypeValidator.validateImageFileExtension(selectedFileName)) {

          const imageWithoutCrop = new LocalCategoryImageModel(previewImageFiles[0].name,
                                                               reader.result,
                                                               null,
                                                               null);

          this.formDef
              .patchValue({
                            image: imageWithoutCrop,
                          });

          this.onChange(imageWithoutCrop);

          const modalRef = this.modalService.open(CropImageModalComponent, {centered: true, size: 'lg'});
          const cropComponent: CropImageModalComponent = modalRef.componentInstance;

          cropComponent.base64ImageContent = reader.result;
          modalRef.result
                  .then((croppedEvent: ImageCroppedEvent | null) => {
                    if (croppedEvent != null) {

                      if (croppedEvent.height < 200 || croppedEvent.width < 200) {
                        // can happen when user opens an image that is smaller than 200x200

                        const sizeErrorMsg = this.trnService.instant('An image must be at least 200x200 pixel large');

                        this.formDef.getControl('image').setErrors({serverValidation: {messages: [sizeErrorMsg]}});

                        this.formDef.patchValue({
                                                  image: null,
                                                });

                        this.onChange(null);
                      } else {
                        const cropPosition: Orderly.Shared.Api.Messages.ImageCropPosition =  {
                          leftTopX: croppedEvent.imagePosition.x1,
                          leftTopY: croppedEvent.imagePosition.y1,
                          rightBottomX: croppedEvent.imagePosition.x2,
                          rightBottomY: croppedEvent.imagePosition.y2
                        };

                        const imageWithCroppedThumb = new LocalCategoryImageModel(imageWithoutCrop.name,
                                                                                  imageWithoutCrop.contentBase64,
                                                                                  cropPosition,
                                                                                  croppedEvent.base64!);

                        this.formDef.patchValue({image: imageWithCroppedThumb});

                        this.onChange(imageWithCroppedThumb);
                      }
                    }
                  });

        } else {
          this.formDef.patchValue({
                                    image: null,
                                  });

          this.onChange(null);
        }
      };
    }
  }

  public clearPreviewImage() {
    // should work for most modern browsers
    this.categoryImageFileInput.nativeElement.value = null;

    if (this.categoryImageFileInput.nativeElement.files.length > 0) {

      // a hack for other browsers
      const emptyFile = document.createElement('input');
      emptyFile.type = 'file';

      this.categoryImageFileInput.nativeElement.files = emptyFile.files;
    }

    this.formDef.patchValue({
                              image: null,
                            });

    this.onChange(null);
  }
}
