import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppState} from '../../app/store/app.state';
import {Store} from '@ngrx/store';
import {filter, switchMap} from 'rxjs/operators';
import GetAllReviewsResponse = Orderly.RestaurantWeb.Api.Messages.Review.GetAllReviewsResponse;
import {selectRestaurantIdParamFromAppState} from '../../app/store/selectors/restaurant-selectors';
import GetAllReviewsRequest = Orderly.RestaurantWeb.Api.Messages.Review.GetAllReviewsRequest;

@Injectable({
              providedIn: 'root'
            })
export class CurrentRestaurantReviewsService {
  private baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  protected readonly restaurantIdRouteParam$: Observable<number | null> = this.store.pipe(selectRestaurantIdParamFromAppState);

  constructor(private httpClient: HttpClient, private readonly store: Store<AppState>) {}

  public search(request: GetAllReviewsRequest): Observable<GetAllReviewsResponse> {

    return this.restaurantIdRouteParam$
               .pipe(
                 filter((restaurantId: number | null) => restaurantId != null),
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/reviews/search`;

                   return this.httpClient.post<GetAllReviewsResponse>(url, request);
                 })
               );
  }
}
