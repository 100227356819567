import {Component, EventEmitter, Input, Output} from '@angular/core';
import MenuItemModifierGroupWithModifiers = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupWithModifiers;

@Component({
             selector: 'app-modifier-group-row',
             templateUrl: './modifier-group-row.component.html',
             styleUrls: ['./modifier-group-row.component.scss'],
           })
export class ModifierGroupRowComponent {

  @Input()
  public group: MenuItemModifierGroupWithModifiers;


  @Output()
  public readonly addNewModifier: EventEmitter<MenuItemModifierGroupWithModifiers> = new EventEmitter<MenuItemModifierGroupWithModifiers>();

  @Output()
  public readonly delete: EventEmitter<MenuItemModifierGroupWithModifiers> = new EventEmitter<MenuItemModifierGroupWithModifiers>();

  @Output()
  public readonly update: EventEmitter<MenuItemModifierGroupWithModifiers> = new EventEmitter<MenuItemModifierGroupWithModifiers>();

  @Output()
  public readonly requestModifierUpdate = new EventEmitter<{group: MenuItemModifierGroupWithModifiers, modifierIndex: number}>();


  public deleteModifier(i: number): void {

    this.group.menuItemModifiers.splice(i, 1);

    this.update.emit(this.group);

  }

  public updateModifier(index: number): void {
    this.requestModifierUpdate.emit({group: this.group, modifierIndex: index});
  }
}
