<div class="modal-header pd-0">
  <a class="btn btn-close" (click)="close()">
    <i class="icon-cancel"></i>
  </a>
</div>
<div class="modal-body pd-0-force">

  <img [src]="src"
       [alt]="base64ContentOrUrl.name"
       class="image-preview">

</div>
