import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {NeverError, ToastService, isNotNullOrEmptyOrWhiteSpaceValidator} from 'orderly-web-components';
import {WaiterInvitationSuccessfulAction} from '../../../store/actions/waiter.actions';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../util/form.utils';
import {genericErrorHandlerWithToast, nameof} from '../../../../util/utils';
import {catchError, finalize, first, map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {CurrentRestaurantUsersService} from '../../../../services/active-route-bound/current-restaurant-users.service';
import AddRestaurantUserRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddRestaurantUserRequest;
import AddRestaurantUserResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddRestaurantUserResponse;
import {UserRole} from './helper.types';
import {ReplaySubject} from 'rxjs';
import {RestaurantRoles} from '../../../../util/trn.utils';

type FormFieldsDef = 'email' | 'firstName' | 'lastName' | 'roles';


@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss']
})
export class AddUserModalComponent implements OnInit, OnDestroy {

  public actionInProgress: boolean = false;

  public formDef: FormDefinition<FormFieldsDef>;

  public roles$: ReplaySubject<UserRole[]> = new ReplaySubject<UserRole[]>();

  constructor(public modalService: NgbActiveModal,
              private restaurantWaitersService: CurrentRestaurantUsersService,
              private formBuilder: FormBuilder,
              private toastService: ToastService,
              private trnService: TranslateService,
              private store: Store<AppState>) {

    trnService.get([RestaurantRoles.Waiter.name,
                    RestaurantRoles.Manager.name,
                    RestaurantRoles.ManagerAssistant.name])
              .pipe(
                map(trns => {
                  const waiterRoleName: string = trns[RestaurantRoles.Waiter.name];
                  const managerRoleName: string = trns[RestaurantRoles.Manager.name];
                  const managerAssistantRoleName: string = trns[RestaurantRoles.ManagerAssistant.name];

                  const roles: UserRole[] = [];

                  roles.push(new UserRole(RestaurantRoles.Waiter.code, waiterRoleName));
                  roles.push(new UserRole(RestaurantRoles.Manager.code, managerRoleName));
                  roles.push(new UserRole(RestaurantRoles.ManagerAssistant.code, managerAssistantRoleName));

                  return roles;
                })
              )
              .subscribe(this.roles$);
  }

  ngOnInit() {
    const fieldsDef: FormFieldsDefinition<FormFieldsDef> = {
      firstName: new FormFieldDefinition(null,
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.maxLength(20)],
                                    [nameof<AddRestaurantUserRequest>('firstName')]),
      lastName: new FormFieldDefinition(null,
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.maxLength(20)],
                                    [nameof<AddRestaurantUserRequest>('lastName')]),
      email: new FormFieldDefinition(null,
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.email, Validators.maxLength(50)],
                                    [nameof<AddRestaurantUserRequest>('email')]),
      roles: new FormFieldDefinition([],
                                     false,
                                     [Validators.required],
                                     [nameof<AddRestaurantUserRequest>('roles')])
    };

    this.formDef = new FormDefinition(fieldsDef, this.formBuilder);
  }

  ngOnDestroy(): void {
    this.roles$.complete();
  }

  public save() {
    this.actionInProgress = true;

    const firstName: string = this.formDef.getControl('firstName').value;
    const lastName: string = this.formDef.getControl('lastName').value;
    const email: string = this.formDef.getControl('email').value;
    const roles: string[] = this.formDef.getControl('roles').value;

    const requestData: AddRestaurantUserRequest = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      roles: roles
    };

    const fieldsDisableToken = this.formDef.disable('all-fields');
    const unexpectedErrorMsg = this.trnService.instant('Failed to add a waiter because of internal error.');

    this.restaurantWaitersService
        .invite(requestData)
        .pipe(
          first(),
          map((response: AddRestaurantUserResponse) => {
            switch (response.status) {
              case AddRestaurantUserResponse.StatusDef.Success:
                this.toastService.showSuccess(this.trnService.instant('Waiter was successfully invited.'));

                this.modalService.close();

                this.store.dispatch(new WaiterInvitationSuccessfulAction(response));
                break;

              case AddRestaurantUserResponse.StatusDef.WaiterAlreadyExists:
                fieldsDisableToken.reenable();

                const emailErrorTxt = this.trnService.instant('Waiter with this email already exists.');
                this.formDef.getControl('email').setErrors({serverValidation: {messages: [emailErrorTxt]}});
                this.toastService.showError(emailErrorTxt);
                break;

              case AddRestaurantUserResponse.StatusDef.UnexpectedError:
                this.toastService.showError(unexpectedErrorMsg);
                break;

              case AddRestaurantUserResponse.StatusDef.ValidationFailed:
                fieldsDisableToken.reenable();

                this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
                break;

              default:
                throw new NeverError(response.status);
            }
          }),
          catchError(
            genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedErrorMsg)
          ),
          finalize(() => {
            this.actionInProgress = false;

            fieldsDisableToken.reenable();
          })
        )
        .subscribe();
  }
}
