<div class="row">
  <div class="col-12 col-xxl-3 col-xl-3 col-lg-4 col-md-6">
    <div class="form-group">
      <label class="form-control-label">{{'Guests can order from QR-menu' | translate}}:</label>

      <app-shared-enabled-disabled-switch [formControl]="formDef.getControl('isMenuReadonly')"
                                          class="d-block">
      </app-shared-enabled-disabled-switch>

      <owc-input-validation-errors [control]="formDef.getControl('isMenuReadonly')"></owc-input-validation-errors>
    </div>
  </div>

  <div class="col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-6">
    <div class="form-group">
      <label class="form-control-label" for="qrMenuButtonAction">{{'Online "Menu" button action' | translate}}:</label>

      <app-qr-menu-action-select [formControl]="formDef.getControl('qrMenuButtonAction')"
                                 id="qrMenuButtonAction">
      </app-qr-menu-action-select>

      <owc-input-validation-errors [control]="formDef.getControl('qrMenuButtonAction')">
      </owc-input-validation-errors>
    </div>
  </div>

  <div class="col-12 col-xxl-5 col-xl-5 col-lg-4 col-md-6" *ngIf="formDef.getControl('menuFiles').enabled">
    <label class="form-control-label">{{'Menu in PDF format' | translate}}: <span class="tx-danger">*</span></label>

    <app-shared-file-upload [formControl]="formDef.getControl('menuFiles')"
                            [multipleFilesAllowed]="false"
                            (fileBlocked)="menuFileBlocked($event)"
                            class="input-group">
    </app-shared-file-upload>

    <small class="form-text text-muted">
      {{allowedFileExtensionHint | translate:{ext: '.pdf'} }}
    </small>

    <owc-input-validation-errors [control]="formDef.getControl('menuFiles')">
    </owc-input-validation-errors>
  </div>

</div>
