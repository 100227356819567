/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modifier-ingredient-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./modifier-ingredient-select.component";
var styles_ModifierIngredientSelectComponent = [i0.styles];
var RenderType_ModifierIngredientSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModifierIngredientSelectComponent, data: {} });
export { RenderType_ModifierIngredientSelectComponent as RenderType_ModifierIngredientSelectComponent };
export function View_ModifierIngredientSelectComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_ModifierIngredientSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-shared-modifier-ingredient-select", [], null, null, null, View_ModifierIngredientSelectComponent_0, RenderType_ModifierIngredientSelectComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.ModifierIngredientSelectComponent]), i1.ɵdid(2, 49152, null, 0, i3.ModifierIngredientSelectComponent, [], null, null)], null, null); }
var ModifierIngredientSelectComponentNgFactory = i1.ɵccf("app-shared-modifier-ingredient-select", i3.ModifierIngredientSelectComponent, View_ModifierIngredientSelectComponent_Host_0, { ingredients$: "ingredients$" }, {}, []);
export { ModifierIngredientSelectComponentNgFactory as ModifierIngredientSelectComponentNgFactory };
