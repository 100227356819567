<div class="form-layout restaurant-editor-form" style="width: 100%;">

  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="form-control-label" for="name">{{'Name' | translate}}: <span class="tx-danger">*</span></label>

        <input class="form-control"
               type="text"
               maxlength="50"
               labelForId="name"
               autocomplete="chrome-off"
               [formControl]="formDef.getControl('name')">

        <owc-input-validation-errors [control]="formDef.getControl('name')"></owc-input-validation-errors>
      </div>
    </div>
  </div>

  <h5>{{'Contact details' | translate}}</h5>

  <app-shared-address-input class="row" [formControl]="formDef.getControl('address')"></app-shared-address-input>

  <div class="row">
    <div class="col-12 col-xxl-2 col-xl-3 col-lg-4 col-md-6">
      <div class="form-group">
        <label class="form-control-label">{{'Contact phone' | translate}}: <span class="tx-danger">*</span></label>
        <input class="form-control"
               type="text"
               maxlength="30"
               autocomplete="chrome-off"
               [formControl]="formDef.getControl('contactPhone')">

        <owc-input-validation-errors [control]="formDef.getControl('contactPhone')"></owc-input-validation-errors>
      </div>
    </div>

    <div class="col-12 col-xxl-2 col-xl-3 col-lg-5 col-md-6">
      <div class="form-group">
        <label class="form-control-label" style="white-space: nowrap;">{{'Contact email' | translate}}: <span class="tx-danger">*</span></label>
        <input class="form-control"
               type="text"
               maxlength="120"
               labelForId="contactEmail"
               autocomplete="chrome-off"
               [formControl]="formDef.getControl('contactEmail')">

        <owc-input-validation-errors [control]="formDef.getControl('contactEmail')"></owc-input-validation-errors>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <h5>{{'Menu settings' | translate}}</h5>
        </div>

        <div class="col-12">
          <app-menu-settings [formControl]="formDef.getControl('menuSettings')" class="row"></app-menu-settings>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h5>{{'Online QR-menu settings' | translate}}</h5>
    </div>

    <app-online-menu-action [formControl]="formDef.getControl('qrMenuSettings')"
                            class="col-12">
    </app-online-menu-action>
  </div>

  <div class="row">
    <h5 class="col-12">{{'Self-order kiosk settings' | translate}}</h5>

    <div class="col-12">
      <app-kiosk-settings [formControl]="formDef.getControl('kioskSettings')" class="row"></app-kiosk-settings>
    </div>
  </div>

</div>
