import { Injectable } from '@angular/core';


@Injectable()
export class WaiterEffect {

  constructor() {
  }

  // TODO: reload services

  // @Effect()
  // inviteWaiterEffect: Observable<Action> = this.actions.pipe(
  //   ofType<WaiterInvitationSuccessfulAction>(ActionTypes.WAITER_INVITE_SUCCESSFUL),
  //
  //   map((action: WaiterInvitationSuccessfulAction) => {
  //     return new LoadRestaurantDashboardDataAction(action.data.restaurantId);
  //   })
  // );
  //
  // @Effect()
  // deleteWaiterEffect: Observable<Action> = this.actions.pipe(
  //   ofType<WaiterDeletionSuccessfulAction>(ActionTypes.WAITER_DELETE_SUCCESSFUL),
  //
  //   map((action: WaiterDeletionSuccessfulAction) => {
  //     return new LoadRestaurantDashboardDataAction(action.data.restaurantId);
  //   })
  // );
}
