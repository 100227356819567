import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {Observable} from 'rxjs';
import {selectRestaurantIdParamFromAppState} from '../../app/store/selectors/restaurant-selectors';
import {switchMap} from 'rxjs/operators';
import AddOrUpdateMenuItemResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemResponse;
import AddMenuItemRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddMenuItemRequest;
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import GetMenuItemResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.GetMenuItemResponse;
import DeleteMenuItemResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.DeleteMenuItemResponse;
import GetMenuItemsResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.GetMenuItemsResponse;
import GetMenuItemIngredientsResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.GetMenuItemIngredientsResponse;
import DeleteMenuItemIngredientResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.DeleteMenuItemIngredientResponse;
import AddOrUpdateMenuItemIngredientResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemIngredientResponse;
import UpdateMenuItemIngredientRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.UpdateMenuItemIngredientRequest;
import DeleteMenuItemModifierResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.DeleteMenuItemModifierResponse;
import DeleteMenuItemModifierGroupResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.DeleteMenuItemModifierGroupResponse;
import AddMenuItemIngredientRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddMenuItemIngredientRequest;
import UpdateMenuItemRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.UpdateMenuItemRequest;
import AddOrUpdateMenuItemModifierResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemModifierResponse;
import AddMenuItemModifierRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddMenuItemModifierRequest;

@Injectable({
              providedIn: 'root'
            })
export class CurrentRestaurantMenuItemsService {
  private readonly baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  protected readonly restaurantIdRouteParam$: Observable<number | null> = this.store.pipe(
    selectRestaurantIdParamFromAppState);

  protected constructor(private httpClient: HttpClient,
                        private store: Store<AppState>) {
  }

  public search(): Observable<GetMenuItemsResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/menu-items/all`;

                   return this.httpClient.get<GetMenuItemsResponse>(url);
                 })
               );
  }


  public get(menuItemId: number): Observable<GetMenuItemResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/menu-item/${menuItemId}`;

                   return this.httpClient.get<GetMenuItemResponse>(url);
                 })
               );
  }

  public delete(menuItemId: number): Observable<DeleteMenuItemResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/menu-item/${menuItemId}/delete`;

                   return this.httpClient.delete<DeleteMenuItemResponse>(url);
                 })
               );
  }

  public add(request: AddMenuItemRequest): Observable<AddOrUpdateMenuItemResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/menu-item/add`;

                   return this.httpClient.post<AddOrUpdateMenuItemResponse>(url, request);
                 })
               );
  }

  public update(menuItemId: number, request: UpdateMenuItemRequest): Observable<AddOrUpdateMenuItemResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/menu-item/${menuItemId}/update`;

                   return this.httpClient.post<AddOrUpdateMenuItemResponse>(url, request);
                 })
               );
  }

  public getMenuItemIngredients(menuItemId: number): Observable<GetMenuItemIngredientsResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/menu-item/${menuItemId}/ingredients/all`;

                   return this.httpClient.get<GetMenuItemIngredientsResponse>(url);
                 })
               );
  }

  public deleteMenuItemIngredient(menuItemIngredientId: number): Observable<DeleteMenuItemIngredientResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/menu-item-ingredient/${menuItemIngredientId}/delete`;

                   return this.httpClient.delete<DeleteMenuItemIngredientResponse>(url);
                 })
               );
  }

  public updateMenuItemIngredient(menuItemIngredientId: number, request: UpdateMenuItemIngredientRequest): Observable<AddOrUpdateMenuItemIngredientResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/menu-item-ingredient/${menuItemIngredientId}/update`;

                   return this.httpClient.post<AddOrUpdateMenuItemIngredientResponse>(url, request);
                 })
               );
  }

  public addMenuItemIngredient(menuItemId: number, request: AddMenuItemIngredientRequest): Observable<AddOrUpdateMenuItemIngredientResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/menu-item/${menuItemId}/ingredient/add`;

                   return this.httpClient.post<AddOrUpdateMenuItemIngredientResponse>(url, request);
                 })
               );
  }


  public deleteMenuItemModifier(menuItemId: number, modifierGroupId: number, modifierId: number): Observable<DeleteMenuItemModifierResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/` +
                                       `menu-item/${menuItemId}/` +
                                       `modifier-group/${modifierGroupId}/` +
                                       `modifier/${modifierId}/delete`;

                   return this.httpClient.delete<DeleteMenuItemModifierResponse>(url);
                 })
               );
  }

  public addMenuItemModifier(menuItemId: number,
                             modifierGroupId: number,
                             request: AddMenuItemModifierRequest): Observable<AddOrUpdateMenuItemModifierResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/` +
                                       `menu-item/${menuItemId}/` +
                                       `modifier-group/${modifierGroupId}/` +
                                       `modifier/add`;

                   return this.httpClient.post<AddOrUpdateMenuItemModifierResponse>(url, request);
                 })
               );
  }

  public updateMenuItemModifier(menuItemId: number,
                                modifierGroupId: number,
                                modifierId: number,
                                request: AddMenuItemModifierRequest): Observable<AddOrUpdateMenuItemModifierResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/` +
                                       `menu-item/${menuItemId}/` +
                                       `modifier-group/${modifierGroupId}/` +
                                       `modifier/${modifierId}/update`;

                   return this.httpClient.post<AddOrUpdateMenuItemModifierResponse>(url, request);
                 })
               );
  }


  public deleteMenuItemModifierGroup(menuItemId: number, modifierGroupId: number): Observable<DeleteMenuItemModifierGroupResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/menu-item/${menuItemId}/modifier-group/${modifierGroupId}/delete`;

                   return this.httpClient.delete<DeleteMenuItemModifierGroupResponse>(url);
                 })
               );
  }

}
