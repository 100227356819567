<ng-container>
  <div class="modal-header pd-y-20 pd-x-25">
    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{'Assign pre-generated code' | translate}}</h6>
    <button type="button" class="close" (click)="modalService.dismiss(null)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pd-25">

    <form [formGroup]="formDef.form" (ngSubmit)="handleSave()">

      <div class="row">
        <div class="form-layout form-layout-1" style="width: 100%;">

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label">{{'Code' | translate}}: <span class="tx-danger">*</span></label>

                <input class="form-control"
                       type="text"
                       maxlength="10"
                       [formControl]="formDef.getControl('code')"/>

                <owc-input-validation-errors [control]="formDef.getControl('code')"></owc-input-validation-errors>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer tx-white">
    <owc-progress-action-button [disabled]="formDef.form.invalid"
                                (execute)="handleSave()"
                                [btnText]="'Save' | translate"
                                type="link">
    </owc-progress-action-button>
  </div>
</ng-container>
