import { filter, map } from 'rxjs/operators';
import { CurrentRestaurantServiceBase } from './current-restaurant.service.base';
import * as i0 from "@angular/core";
import * as i1 from "../ingredients.service";
import * as i2 from "@ngrx/store";
export class CurrentRestaurantIngredientsService extends CurrentRestaurantServiceBase {
    constructor(ingredientsService, store) {
        super(store);
        this.ingredientsService = ingredientsService;
    }
    get currentLoadedRestaurantIngredients$() {
        return this.dataFeed$.pipe(filter(x => x.isLoaded()), map(x => x.items));
    }
    // TODO: obsolete
    get currentRestaurantIngredients$() {
        return this.dataFeed$;
    }
    doLoadData(restaurantId) {
        return this.ingredientsService.getAllIngredients(restaurantId).pipe(map(x => x.restaurantIngredients));
    }
}
CurrentRestaurantIngredientsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentRestaurantIngredientsService_Factory() { return new CurrentRestaurantIngredientsService(i0.ɵɵinject(i1.IngredientsService), i0.ɵɵinject(i2.Store)); }, token: CurrentRestaurantIngredientsService, providedIn: "root" });
