import {Component} from '@angular/core';


@Component({
             selector: 'app-restaurant-dashboard-container',
             templateUrl: './restaurant-dashboard-container.component.html',
             styleUrls: ['./restaurant-dashboard-container.component.scss']
           })
export class RestaurantDashboardContainerComponent {

  constructor() {

  }
}
