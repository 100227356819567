import {Component, EventEmitter, Output} from '@angular/core';
import {FormDefinition} from '../../../../../../util/form.utils';
import {createForm, DetailsTabFormFieldsDefinition} from './details-tab.form-fields-definition';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {filter, map, tap} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs';
import MenuItemCategory = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemCategoryResponse.MenuItemCategory;
import {CurrentRestaurantMenuItemCategoriesService} from '../../../../../../services/active-route-bound/current-restaurant-menu-item-categories.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {ActivatedRoute} from '@angular/router';
import {RestaurantComponent} from '../../../../../restaurant.component';

@Component({
             selector: 'app-menu-item-details-tab',
             templateUrl: './details-tab.component.html',
             styleUrls: ['./details-tab.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: DetailsTabComponent,
                 multi: true,
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: DetailsTabComponent,
                 multi: true
               },
             ],
           })
export class DetailsTabComponent extends RestaurantComponent implements ControlValueAccessor, Validator {

  private initInProgress: boolean = false;

  public readonly categories$: ReplaySubject<MenuItemCategory[]> = new ReplaySubject<MenuItemCategory[]>(1);

  public readonly formDef: FormDefinition<keyof DetailsTabFormFieldsDefinition> = createForm(this.formBuilder);

  @Output()
  public nameOrDescriptionChanged = new EventEmitter<void>();


  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private readonly formBuilder: FormBuilder,
              private readonly restaurantMenuItemCategoriesService: CurrentRestaurantMenuItemCategoriesService) {

    super(store, activatedRoute);

    this.registerForAutoDestroy(
      this.formDef
          .form
          .valueChanges
          .pipe(
            filter(() => !this.initInProgress),
            tap((formValue: DetailsTabFormFieldsDefinition) => {
              this.onChange(formValue);
            }),
          )
          .subscribe()
    );

    this.registerForAutoDestroy(
      this.formDef
          .getControl('name')
          .valueChanges
          .pipe(
            filter(() => !this.initInProgress),
            tap(() => {
              this.nameOrDescriptionChanged.emit();
            }),
          )
          .subscribe()
    );

    this.registerForAutoDestroy(
      this.formDef
          .getControl('description')
          .valueChanges
          .pipe(
            filter(() => !this.initInProgress),
            tap(() => {
              this.nameOrDescriptionChanged.emit();
            }),
          )
          .subscribe()
    );

    this.registerForAutoDestroy(
      this.restaurantMenuItemCategoriesService
          .currentRestaurantMenuItemCategories$
          .pipe(
            filter(x => x.isLoaded()),
            map(x => x.items.sort())
          )
          .subscribe(this.categories$)
    );
  }

  onChange: any = (value: DetailsTabFormFieldsDefinition) => {
  };

  onTouched: any = () => {
  };


  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: DetailsTabFormFieldsDefinition | null): void {
    if (value == null) {
      return;
    }

    this.initInProgress = true;

    this.formDef.form.patchValue(value, {emitEvent: false});

    this.initInProgress = false;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formDef.disable('all-fields');
    } else {
      this.formDef.enable('all-fields', false);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.formDef.form.invalid) {
      return {'details-tab': true};
    }

    return null;
  }

}
