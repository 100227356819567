import {LeftMenuState, LeftMenuStateItem} from '../../app.state';
import {
  RestaurantSuccessfullyUpdatedAction
} from '../../actions/restaurant.actions';

export function restaurantUpdatedActionHandler(currentState: LeftMenuState,
                                               action: RestaurantSuccessfullyUpdatedAction): LeftMenuState {

  const menuItems: LeftMenuStateItem[] = [];

  for (let i = 0; i < currentState.items.length; i++) {
    const item = currentState.items[i];

    if (item.id.restaurantId === action.restaurant.id) {
      menuItems.push(
        new LeftMenuStateItem(item.id,
                              action.restaurant.name,
                              item.url,
                              item.subItems)
      );
    } else {
      menuItems.push(item);
    }
  }

  return {...currentState, items: menuItems};
}
