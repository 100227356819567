export class LeftMenuConstants {
  public static START: string = 'start';

  public static CHAIN_DASHBOARD: string = 'chain-dashboard';

  public static RESTAURANT: string = 'restaurant';
  public static RESTAURANT_EDIT: string = 'restaurant-edit';
  public static RESTAURANT_AREAS: string = 'restaurant-areas';
  public static RESTAURANT_TABLES: string = 'restaurant-tables';
  public static RESTAURANT_TERMINALS: string = 'restaurant-terminals';
  public static RESTAURANT_MENU_ITEM_CATEGORIES: string = 'restaurant-menu-item-categories';
  public static RESTAURANT_MENU_ITEMS: string = 'restaurant-menu-items';
  public static RESTAURANT_INGREDIENT_CATEGORIES: string = 'restaurant-ingredient-categories';
  public static RESTAURANT_INGREDIENTS: string = 'restaurant-ingredients';
  public static RESTAURANT_REVIEWS: string = 'restaurant-reviews';
  public static RESTAURANT_OPENED_REQUESTS: string = 'restaurant-opened-requests';
  public static RESTAURANT_USERS: string = 'restaurant-users';
  public static RESTAURANT_COVID_CHECKINS: string = 'restaurant-covid-checkins';
}
