import MenuItemTranslationDefinition = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemTranslationDefinition;
import {TabSettingsDefinition} from './tab-settings.definition';
import {MenuItemTranslationsFormFieldsDefinition} from './translations-tab/menu-item-translations-form-fields.definition';

export function createTranslations(settings: TabSettingsDefinition,
                                   source: MenuItemTranslationsFormFieldsDefinition): MenuItemTranslationDefinition[] {

  const translations: MenuItemTranslationDefinition[] = [];
  const primaryLangCode2 = settings.primaryLanguage.code.toLowerCase();

  for (let i = 0; i < settings.menuTranslationLanguages.length; i++) {

    const langCode2 = settings.menuTranslationLanguages[i].code.toLowerCase();

    if (primaryLangCode2 === langCode2.toLowerCase()) {
      continue;
    }

    const nameIdx = source.name.findIndex((x) => x.langCode2.toLowerCase() === langCode2);
    const name = nameIdx === -1 ? '' : source.name[nameIdx].text;

    const descriptionIdx = source.description.findIndex((x) => x.langCode2.toLowerCase() === langCode2);
    const description = descriptionIdx === -1 ? '' : source.description[descriptionIdx].text;

    const trn: MenuItemTranslationDefinition = {
      languageCode2: settings.menuTranslationLanguages[i].code,
      name,
      description
    };

    translations.push(trn)
  }

  return translations;
}
