import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {first, flatMap, map, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {RestaurantSpecificArrayResultLoadingState} from './helper.statuses';
import {CurrentRestaurantServiceBase} from './current-restaurant.service.base';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import MenuItemCategory = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemCategoryResponse.MenuItemCategory;
import AddOrUpdateMenuItemCategoryResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemCategoryResponse;
import DeleteMenuItemCategoryResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.DeleteMenuItemCategoryResponse;
import AddMenuItemCategoryRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddMenuItemCategoryRequest;
import UpdateMenuItemCategoryRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.UpdateMenuItemCategoryRequest;
import GetMenuItemCategoriesResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.GetMenuItemCategoriesResponse;
import ReorderMenuItemCategoriesRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.ReorderMenuItemCategoriesRequest;
import ReorderMenuItemCategoriesResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.ReorderMenuItemCategoriesResponse;

@Injectable({
              providedIn: 'root'
            })
export class CurrentRestaurantMenuItemCategoriesService extends CurrentRestaurantServiceBase<MenuItemCategory> {
  private readonly baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  public get currentRestaurantMenuItemCategories$(): Observable<RestaurantSpecificArrayResultLoadingState<MenuItemCategory>> {
    return this.dataFeed$;
  }

  constructor(private httpClient: HttpClient, store: Store<AppState>) {
    super(store);
  }

  protected doLoadData(restaurantId: number): Observable<MenuItemCategory[]> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/menu-item-categories/all`;
    const response$ = this.httpClient.get<GetMenuItemCategoriesResponse>(url);

    return response$.pipe(
      tap(x => {
        if (x.status === GetMenuItemCategoriesResponse.StatusDef.UnknownFailure) {
          throw new Error('Server returned an error. Could not load list of menu item categories.');
        }
      }),
      map(x => x.items.sort((i1, i2) => {
        if (i1.displaySortOrder === i2.displaySortOrder) {
          return i1.id - i2.id;
        }

        return i1.displaySortOrder - i2.displaySortOrder;
      }))
    );
  }

  public add(request: AddMenuItemCategoryRequest): Observable<AddOrUpdateMenuItemCategoryResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 first(),
                 flatMap((restaurantId: number) => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/menu-item-category/add`;

                   return this.httpClient.post<AddOrUpdateMenuItemCategoryResponse>(url, request);
                 })
               );
  }

  public reorder(request: ReorderMenuItemCategoriesRequest): Observable<ReorderMenuItemCategoriesResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 first(),
                 flatMap((restaurantId: number) => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/menu-item-categories/reorder`;

                   return this.httpClient.post<ReorderMenuItemCategoriesResponse>(url, request);
                 })
               );
  }

  public update(categoryId: number, request: UpdateMenuItemCategoryRequest): Observable<AddOrUpdateMenuItemCategoryResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 first(),
                 flatMap((restaurantId: number) => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/menu-item-category/${categoryId}/update`;

                   return this.httpClient.post<AddOrUpdateMenuItemCategoryResponse>(url, request);
                 })
               );
  }

  public delete(categoryId: number): Observable<DeleteMenuItemCategoryResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 first(),
                 flatMap((restaurantId: number) => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/menu-item-category/${categoryId}/delete`;

                   return this.httpClient.delete<DeleteMenuItemCategoryResponse>(url);
                 })
               );
  }
}
