import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';

import AuthenticateRequest = Orderly.RestaurantWeb.Api.Messages.Authentication.AuthenticateRequest;
import RestaurantAuthenticateUserResponse = Orderly.RestaurantWeb.Api.Messages.Authentication.RestaurantAuthenticateUserResponse;

@Injectable({
              providedIn: 'root'
            })
export class AuthService {

  constructor(private httpClient: HttpClient) {
  }

  public authenticate(email: string, password: string): Observable<RestaurantAuthenticateUserResponse> {
    const url: string = `${environment.baseApiUrlWithTrailingSlash}v1.0/restaurant/admin/auth`;
    const request: AuthenticateRequest = { email: email, password: password };
    const result = this.httpClient.post<RestaurantAuthenticateUserResponse>(url, request);

    return result;
  }
}
