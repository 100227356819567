import {Component, OnDestroy} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../../util/form.utils';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {MenuSettingsFormFieldsDefinition} from './menu-settings-form-fields.definition';
import {Language} from 'orderly-web-components';

@Component({
             selector: 'app-menu-settings',
             templateUrl: './menu-settings.component.html',
             styleUrls: ['./menu-settings.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: MenuSettingsComponent,
                 multi: true
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: MenuSettingsComponent,
                 multi: true
               },
             ]
           })
export class MenuSettingsComponent implements ControlValueAccessor, Validator, OnDestroy {

  private readonly destroyed$: Subject<any> = new Subject<any>();

  private initInProgress: boolean = false;

  public formDef: FormDefinition<keyof MenuSettingsFormFieldsDefinition>;

  public selectedMenuLanguages$: Subject<Language[]> = new ReplaySubject(1);


  constructor(private readonly formBuilder: FormBuilder) {

    const fieldsDef: FormFieldsDefinition<keyof MenuSettingsFormFieldsDefinition> = {
      menuCurrency: new FormFieldDefinition(null,
                                            false,
                                            [Validators.required],
                                            []),
      menuDefaultLanguageId: new FormFieldDefinition(null,
                                                     true,
                                                     [Validators.required],
                                                     []),
      translationLanguages: new FormFieldDefinition([],
                                                    false,
                                                    [Validators.required],
                                                    []),
    };

    this.formDef = new FormDefinition<keyof MenuSettingsFormFieldsDefinition>(fieldsDef, this.formBuilder);

    this.formDef
        .form
        .valueChanges
        .pipe(
          filter(() => !this.initInProgress),
          tap((x: MenuSettingsFormFieldsDefinition) => {
            this.onChange(x);
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();

    this.formDef
        .getControl('translationLanguages')
        .valueChanges
        .pipe(
          filter(() => !this.initInProgress),
          tap((langs: Language[]) => {
            if (langs.length === 0) {
              this.formDef.getControl('menuDefaultLanguageId')
              this.formDef.disable(['menuDefaultLanguageId']);
            } else {
              this.formDef.enable(['menuDefaultLanguageId'], false);

              const menuDefaultLanguageId: number | null = this.formDef.getControlValue('menuDefaultLanguageId');

              if (menuDefaultLanguageId != null) {
                const mainLanguageIndex = langs.findIndex((l) => l.id === menuDefaultLanguageId);

                if (mainLanguageIndex < 0) {
                  this.formDef.patchValue({menuDefaultLanguageId: null}, true);
                }
              }
            }
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe(this.selectedMenuLanguages$);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: MenuSettingsFormFieldsDefinition) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: MenuSettingsFormFieldsDefinition | null): void {
    if (value == null) {
      return;
    }

    this.initInProgress = true;

    if (value.translationLanguages.length === 0 ||
        value.translationLanguages.findIndex((lang) => lang.id === value.menuDefaultLanguageId) < 0) {

      this.formDef.patchValue({
                                menuCurrency: value.menuCurrency,
                                translationLanguages: value.translationLanguages,
                                menuDefaultLanguageId: null
                              }, false);

      this.formDef.enable(['menuCurrency', 'translationLanguages'], false);
    } else {
      this.formDef.patchValue(value, false);

      this.selectedMenuLanguages$.next(value.translationLanguages);

      this.formDef.enable('all-fields', false);
    }

    this.initInProgress = false;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formDef.disable('all-fields');
    } else {
      const formValue: MenuSettingsFormFieldsDefinition = this.formDef.form.getRawValue();

      this.formDef.enable(['menuCurrency', 'translationLanguages'], false);

      if (formValue.translationLanguages.length > 0) {
        this.formDef.enable(['menuDefaultLanguageId'], false);
      } else {
        this.formDef.disable(['menuDefaultLanguageId']);
      }
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.formDef.form.invalid) {
      return {'menu-settings': true};
    }

    return null;
  }
}
