import {Injectable} from '@angular/core';
import {Actions, Effect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {map} from 'rxjs/operators';
import {AuthenticatedUserModel, LocalStorageService} from '../../../services/local-storage.service';
import {
  AutoLoginOnAppStartFailedAction,
  LoginSuccessfulAction,
  LogoutAction
} from '../actions/user.actions';
import defaultLanguage from './../../../assets/i18n/en.json';
import ukLanguage from './../../../assets/i18n/uk.json';
import deLanguage from './../../../assets/i18n/de.json';
import {TranslateService} from '@ngx-translate/core';


@Injectable()
export class AppInitEffect {

  constructor(private actions: Actions, private translate: TranslateService) {
  }

  @Effect()
  appInitEffect = this.actions.pipe(
    ofType(ROOT_EFFECTS_INIT),
    map(() => {
      this.translate.setTranslation('en', defaultLanguage);
      this.translate.setTranslation('de', deLanguage);
      this.translate.setTranslation('uk', ukLanguage);

      this.translate.setDefaultLang('en');

      const currentUser: AuthenticatedUserModel | null = LocalStorageService.getCurrentUser();

      if (currentUser == null || currentUser.user == null || currentUser.jwtTokenBase64 == null) {
        return new AutoLoginOnAppStartFailedAction();
      }

      if (currentUser.CURRENT_DEFINITION_VERSION == null ||
          currentUser.CURRENT_DEFINITION_VERSION < AuthenticatedUserModel.LAST_COMPATIBLE_DEFINITION_VERSION) {
        return new LogoutAction('serialized localstorage model is incompatible with current model structure');
      }

      return new LoginSuccessfulAction(currentUser.user, currentUser.jwtTokenBase64, true);
    })
  );
}
