<div class="terminals-list-table div-based-table table-dark table-colored">
  <div class="thead">
    <div class="row">
      <div class="col-11 title">{{'Terminals' | translate}}</div>
      <div class="col-1 actions">
        <a class="btn btn-sm btn-outline-secondary float-right" (click)="refreshTerminalsList()">
          <i class="icon-arrows-cw fa-15x" [class.animate-spin]="listIsLoading$ | async"></i>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-3">{{'Name' | translate}}</div>
      <div class="col-2">{{'Type' | translate}}</div>
      <div class="col-1">{{'Is active' | translate}}</div>
      <div class="col-2">{{'Tablet linked' | translate}}</div>
      <div class="col-4"></div>
    </div>
  </div>
  <div class="tbody">
    <ng-container *ngIf="listIsLoaded$ | async">
      <ng-container *ngIf="displayedGridItems$ | async as items">
        <div class="row" *ngIf="items.length === 0">
          <div class="ht-50 pos-relative text-center col-12">
            {{'No terminals available' | translate}}
          </div>
        </div>

        <div class="row" *ngFor="let terminal of items" style="word-wrap: break-word;">
          <div class="col-3">
            <a (click)="updateTerminal(terminal)">{{ terminal.name }}</a>
          </div>
          <div class="col-2">
            <span *ngIf="terminal.type === 1">{{'Central' | translate}}</span>
            <span *ngIf="terminal.type === 2">{{'Personal' | translate}}</span>
          </div>
          <div class="col-1 text-center">
            <i class="icon-ok" *ngIf="terminal.isActive"></i>
          </div>
          <div class="col-2 text-center">
            <i class="icon-ok" *ngIf="terminal.isDeviceLinked"></i>
          </div>
          <div class="col-4">
            <ng-container *ngIf="userIsManager | async">

              <a class="btn btn-sm btn-teal text-white mg-x-1" (click)="updateTerminal(terminal)">{{'Update' | translate}}</a>
              <a class="btn btn-sm btn-danger text-white mg-x-1" (click)="setTerminalPin(terminal)">{{'Set new pin' | translate}}</a>

              <div class="dropdown d-inline-block" style="cursor: pointer">
                <a class="more-actions-button" data-toggle="dropdown">
                  <div class="more-dots-container">
                    <span>...</span>
                  </div>
                </a>

                <div class="dropdown-menu pd-10 wd-300">
                  <nav class="nav nav-style-1 flex-column">
                    <a class="nav-link modal-table-action generate-new-qr-code" (click)="showAccessCode(terminal)">
                      <i class="icon-eye"></i>{{'Show access code' | translate}}
                    </a>

                    <a class="nav-link table-action delete-table" (click)="unlinkDevice(terminal)" *ngIf="terminal.isDeviceLinked">
                      <i class="icon-unlink"></i>{{'Unlink tablet' | translate}}
                    </a>

                    <a class="nav-link modal-table-action" (click)="deleteTerminal(terminal)">
                      <i class="icon-trash-empty"></i>{{'Delete' | translate}}
                    </a>
                  </nav>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="row" *ngIf="listIsLoading$ | async">
      <div class="ht-60 pos-relative align-items-center col-12">
        <owc-progress-wave></owc-progress-wave>
      </div>
    </div>

    <div class="row" *ngIf="listLoadingFailed$ | async">
      <div class="ht-50 pos-relative align-items-center col-12">
        <p class="loading-failed-text">{{'Could not load terminals list. Check if you have Internet connection and try again.' | translate}}</p>
      </div>
    </div>
  </div>

  <div class="tfoot" *ngIf="listIsLoaded$ | async">
    <div class="row">
      <div class="col-12">
        {{'Terminal application can be downloaded here' | translate}}: <i class="icon-android"></i><a href="https://play.google.com/store/apps/details?id=com.mycafy.terminal" target="_blank">Android 4.4+</a>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <ng-container *ngIf="userIsManager | async">
          <a class="new-element-btn" [class.disabled]="listIsLoading$ | async" (click)="addTerminal()">
            <i class="icon-plus"></i>{{'Add new terminal' | translate}}
          </a>
        </ng-container>
      </div>
      <div class="col-8 bd-l-0">
        <owc-pagination-block [visible$]="listIsLoaded$"
                              (filtersChanged)="pagingFiltersChanged($event)"
                              [disable$]="listLoadingFailed$"
                              [state$]="paginationState$">
        </owc-pagination-block>
      </div>
    </div>
  </div>
</div>
