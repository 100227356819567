<div class="areas-list-table div-based-table table-dark table-colored">
  <div class="thead">
    <div class="row">
      <div class="col-11 title">{{'Restaurant areas' | translate}}</div>
      <div class="col-1 actions">
        <a class="btn btn-sm btn-outline-secondary float-right" (click)="refreshAreasList()">
          <i class="icon-arrows-cw fa-15x" [class.animate-spin]="listIsLoading$ | async"></i>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-4">{{'Name' | translate}}</div>
      <div class="col-3">{{'Is smoking allowed' | translate}}</div>
      <div class="col-2">{{'Is outdoor' | translate}}</div>
      <div class="col-3"></div>
    </div>
  </div>
  <div class="tbody">
    <ng-container *ngIf="listIsLoaded$ | async">
      <div class="row" *ngFor="let area of displayedGridItems$ | async" style="word-wrap: break-word;">
        <div class="col-4">
          <a (click)="updateArea(area)">{{ area.name }}</a>
        </div>
        <div class="col-3 text-center">
          <i class="icon-ok" *ngIf="area.isSmokingArea"></i>
        </div>
        <div class="col-2 text-center">
          <i class="icon-ok" *ngIf="area.isOutdoor"></i>
        </div>
        <div class="col-3">
          <ng-container *ngIf="userIsManager | async">
            <a class="btn btn-sm btn-teal text-white mg-x-1" (click)="updateArea(area)">{{'Update' | translate}}</a>
            <a class="btn btn-sm btn-danger text-white mg-x-1" (click)="deleteArea(area)">{{'Delete' | translate}}</a>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="row" *ngIf="listIsLoading$ | async">
      <div class="ht-60 pos-relative align-items-center col-12">
        <owc-progress-wave></owc-progress-wave>
      </div>
    </div>

    <div class="row" *ngIf="listLoadingFailed$ | async">
      <div class="ht-50 pos-relative align-items-center col-12">
        <p class="loading-failed-text">{{'Could not load restaurant areas list. Check if you have Internet connection and try again.' | translate}}</p>
      </div>
    </div>
  </div>

  <div class="tfoot" *ngIf="listIsLoaded$ | async">
    <div class="row">
      <div class="col-4">
        <ng-container *ngIf="userIsManager | async">
          <a class="new-element-btn" [class.disabled]="listIsLoading$ | async" (click)="addArea()">
            <i class="icon-plus"></i>{{'Add restaurant area' | translate}}
          </a>
        </ng-container>
      </div>
      <div class="col-8 bd-l-0">
        <owc-pagination-block [visible$]="listIsLoaded$"
                              (filtersChanged)="pagingFiltersChanged($event)"
                              [disable$]="listLoadingFailed$"
                              [state$]="paginationState$">
        </owc-pagination-block>
      </div>
    </div>
  </div>
</div>
