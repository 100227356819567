<div class="row">
  <div class="col-12">
    <div class="form-group">
      <label class="form-control-label" for="category-name">{{'Name' | translate}}: <span class="tx-danger">*</span></label>

      <app-shared-text-input-with-language-flag [formControl]="formDef.getControl('name')"
                                                id="category-name">
      </app-shared-text-input-with-language-flag>

      <owc-input-validation-errors [control]="formDef.getControl('name')"></owc-input-validation-errors>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="form-group">
      <label class="form-control-label" for="category-description">{{'Description' | translate}}:</label>

      <app-shared-text-input-with-language-flag [formControl]="formDef.getControl('description')"
                                                id="category-description"
                                                mode="textarea">
      </app-shared-text-input-with-language-flag>

      <owc-input-validation-errors [control]="formDef.getControl('description')"></owc-input-validation-errors>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 col-xxl-3 col-xl-4 col-sm-6">
    <div class="form-group">
      <label class="form-control-label">{{'Image' | translate}}:</label>

      <app-category-image-selector [formControl]="formDef.getControl('image')"></app-category-image-selector>

      <owc-input-validation-errors [control]="formDef.getControl('image')"></owc-input-validation-errors>
    </div>
  </div>

  <app-image-preview class="col-12 col-xxl-3 col-xl-4 col-sm-6"
                     [image]="this.formDef.getControl('image').value">
  </app-image-preview>

</div>

<div class="row">
  <div class="col-12 col-xl-2 col-sm-4">
    <div class="form-group">
      <label class="form-control-label">{{'Menu items thumbs visibility' | translate}}:</label>

      <app-shared-visibility-select [formControl]="formDef.getControl('isMenuItemsThumbsVisible')"></app-shared-visibility-select>

      <owc-input-validation-errors [control]="formDef.getControl('isMenuItemsThumbsVisible')"></owc-input-validation-errors>
    </div>
  </div>
</div>
