<app-top-bar-with-language-switcher></app-top-bar-with-language-switcher>

<div class="d-flex align-items-center justify-content-center bg-br-primary ht-100v">

  <div class="login-wrapper wd-400 wd-xs-400 wd-lg-500 pd-25 pd-xs-40 bg-white rounded shadow-base">
    <div class="signin-logo tx-center tx-28 tx-bold tx-inverse pd-md-b-20"><span class="tx-normal">[</span>MY<span class="tx-info">CAFY</span> <span class="tx-normal">]</span></div>

    <div *ngIf="recoveredSuccessfully" class="text-center">
      <h4>{{'Your password was successfully changed.'}} <a routerLink="/login">{{'Click here to navigate to login page' | translate}}</a></h4>
    </div>

    <form [formGroup]="formDef.form" (ngSubmit)="completeRecovery()" *ngIf="!recoveredSuccessfully" style="width: 100%;">
      <h4 class="mg-b-15">{{'Please, provide your email address that you used to register' | translate}}:</h4>

      <div class="form-group">
        <input class="form-control" type="text" [formControl]="formDef.getControl('token')" [placeholder]="'Token from the email' | translate" />

        <owc-input-validation-errors [control]="formDef.getControl('token')"></owc-input-validation-errors>
      </div>

      <div class="form-group">
        <input class="form-control" type="text" [formControl]="formDef.getControl('newPassword')" [placeholder]="'New password' | translate" />

        <owc-input-validation-errors [control]="formDef.getControl('newPassword')"></owc-input-validation-errors>
      </div>

      <div class="form-group">
        <input class="form-control" type="text" [formControl]="formDef.getControl('newPasswordConfirmation')" [placeholder]="'New password confirmation' | translate" />

        <owc-input-validation-errors [control]="formDef.getControl('newPasswordConfirmation')"></owc-input-validation-errors>
      </div>

      <owc-progress-action-button type="submit"
                                  [btnText]="'Set new password' | translate"
                                  [btnClass]="'btn-info btn-block'"
                                  (execute)="completeRecovery()"
                                  [actionInProgress]="actionInProgress"
                                  [disabled]="formDef.form.invalid"
                                  class="d-block">
      </owc-progress-action-button>
    </form>

  </div>
</div>
