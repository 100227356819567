import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FormBuilder,
} from '@angular/forms';
import {FormDefinition} from '../../../../../../../util/form.utils';
import {createForm, ModifierGroupEditorFormFieldsDefinition} from './modifier-group-editor-form-fields.definition';
import {TextTranslationDefinition} from '../../../../../shared/components/text-input-with-language-flag/text-translation.definition';
import {Observable} from 'rxjs';
import {Language} from 'orderly-web-components';
import {map, tap} from 'rxjs/operators';
import {RestaurantComponent} from '../../../../../../restaurant.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
             selector: 'app-modifier-group-editor',
             templateUrl: './modifier-group-editor.component.html',
             styleUrls: ['./modifier-group-editor.component.scss'],
           })
export class ModifierGroupEditorComponent extends RestaurantComponent implements OnInit {

  public showMinMaxControls: boolean;

  public readonly formDef: FormDefinition<keyof ModifierGroupEditorFormFieldsDefinition> = createForm(this.formBuilder, this.trnService);

  public translationLanguages$: Observable<Language[]>;

  @Output()
  public readonly save: EventEmitter<ModifierGroupEditorFormFieldsDefinition> = new EventEmitter<ModifierGroupEditorFormFieldsDefinition>();

  @Output()
  public readonly cancel: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public group: ModifierGroupEditorFormFieldsDefinition | null;


  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private readonly trnService: TranslateService,
              private readonly formBuilder: FormBuilder,
              private readonly cdr: ChangeDetectorRef) {

    super(store, activatedRoute);

    this.translationLanguages$ = this.currentRestaurant$.pipe(map(x => x.settings.enabledMenuLanguages));

    this.registerForAutoDestroy(
      this.formDef
          .getControl('name')
          .valueChanges
          .pipe(
            tap((value: TextTranslationDefinition) => {
              this.nameChanged(value);
            }),
          )
          .subscribe()
    );
  }

  ngOnInit() {
    if (this.group != null) {
      this.formDef.patchValue(this.group, false);

      this.cdr.markForCheck();
    }
  }

  public nameChanged(nameValue: TextTranslationDefinition) {
    const nameTranslationsControl = this.formDef.getControl('nameTranslations');
    const translations: TextTranslationDefinition[] = nameTranslationsControl.value;
    const existingNameTranslationIndex =  translations.findIndex(x => x.langCode2.toLowerCase() === nameValue.langCode2.toLowerCase());

    if (existingNameTranslationIndex >= 0) {
      const newNameTranslations = [...translations];

      newNameTranslations[existingNameTranslationIndex].text = nameValue.text;

      nameTranslationsControl.patchValue(newNameTranslations, {emitEvent: false});
    } else {
      const newNameTranslations = [...translations, nameValue];

      nameTranslationsControl.patchValue(newNameTranslations, {emitEvent: false});
    }

    if (nameValue.text == null || nameValue.text === '') {
      nameTranslationsControl.disable({emitEvent: false});
    } else {
      nameTranslationsControl.enable({emitEvent: false});
    }

    this.cdr.markForCheck();
  }

  public saveChanges(): void {
    const formValue: ModifierGroupEditorFormFieldsDefinition = this.formDef.form.getRawValue();

    this.save.emit(formValue);
  }
}
