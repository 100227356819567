export class NewIngredientCategoryData {
  constructor(public readonly name: string) {
  }
}

export class ExistingIngredientCategoryData extends NewIngredientCategoryData {
  constructor(public readonly id: number,
              public readonly name: string) {
    super(name);
  }
}
