import {Component, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';
import {
  LoginFailedBecauseOfApiAction,
  LoginFailedBecauseOfHttpAction,
  LoginSuccessfulAction
} from '../store/actions/user.actions';
import {RoutingConstants} from '../routing-constants';
import {LoginEvent} from 'orderly-web-components/lib/events/LoginEvent';
import {catchError, finalize, first, tap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {
  LoginComponent,
  LoginErrorType,
  NeverError,
  ToastService
} from 'orderly-web-components';
import RestaurantAuthenticateResponse = Orderly.RestaurantWeb.Api.Messages.Authentication.RestaurantAuthenticateUserResponse;
import {TranslateService} from '@ngx-translate/core';
import {trnCheckInternetAndTryAgainLaterText} from '../../util/trn.utils';


@Component({
             selector: 'app-login-page',
             templateUrl: './login-page.component.html',
             styleUrls: ['./login-page.component.scss']
           })
export class LoginPageComponent {

  public loginInProgress: boolean = false;

  @ViewChild('loginComponent', {static: false})
  public loginComponent: LoginComponent;

  constructor(private authService: AuthService,
              private store: Store<AppState>,
              private toastService: ToastService,
              private trnService: TranslateService) {
  }

  public loginComponentCfg: { forgotPasswordUrl: string, registrationUrl: string } = {
    forgotPasswordUrl: '../' + RoutingConstants.FORGOT_PASSWORD,
    registrationUrl: '../' + RoutingConstants.REGISTRATION
  };

  login(e: LoginEvent) {
    this.loginInProgress = true;

    this.authService
        .authenticate(e.login, e.password)
        .pipe(
          first(),
          tap((authResult: RestaurantAuthenticateResponse) => {
            switch (authResult.status) {
              case RestaurantAuthenticateResponse.StatusDef.ValidationFailed:
                const validationError = {message: 'Email has wrong format', type: LoginErrorType.WrongCredentialsOrAccountDoesNotExist};
                this.loginComponent.errors$.next(validationError);
                break;
              case RestaurantAuthenticateResponse.StatusDef.WrongCredentialsOrAccountDoesNotExist:
                const credentialsError = {message: 'Wrong credentials or account does not exist', type: LoginErrorType.WrongCredentialsOrAccountDoesNotExist};
                this.loginComponent.errors$.next(credentialsError);

                this.store.dispatch(new LoginFailedBecauseOfApiAction(authResult));
                break;
              case RestaurantAuthenticateResponse.StatusDef.Success:
                this.store.dispatch(new LoginSuccessfulAction(authResult.user, authResult.jwtTokenBase64, false));
                break;
              case RestaurantAuthenticateResponse.StatusDef.AccountLocked:
                const lockedAccountError = {message: 'Account was locked', type: LoginErrorType.AccountWasLocked};
                this.loginComponent.errors$.next(lockedAccountError);
                break;
              default:
                throw new NeverError(authResult.status);
            }
          }),
          catchError((err, _) => {
            if (err.status === 0) {
              const msg = trnCheckInternetAndTryAgainLaterText(this.trnService);

              this.toastService.showError(msg);
            }

            this.store.dispatch(new LoginFailedBecauseOfHttpAction(err));

            return throwError(err);
          }),
          finalize(() => {
            this.loginInProgress = false;
          })
        )
        .subscribe();
  }
}
