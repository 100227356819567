import { OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { RestaurantSpecificArrayResultLoadingState, RestaurantSpecificResultLoadingStatusDefinition } from '../../../services/active-route-bound/helper.statuses';
import { NeverError } from 'orderly-web-components';
import { CheckinsListGridItem } from './helper.types';
import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/active-route-bound/current-restaurant-table-checkins.service";
export class GridItemsSourceService {
    constructor(checkinsService) {
        this.checkinsService = checkinsService;
        this.cache$ = new ReplaySubject(1);
        checkinsService.currentRestaurantTableCheckins$
            .pipe(map((x) => {
            switch (x.loadingStatus) {
                case RestaurantSpecificResultLoadingStatusDefinition.SUCCESS:
                    const gridItems = x.items.map(table => new CheckinsListGridItem(table));
                    return new RestaurantSpecificArrayResultLoadingState(x.loadingStatus, gridItems, x.error);
                case RestaurantSpecificResultLoadingStatusDefinition.FAILED:
                case RestaurantSpecificResultLoadingStatusDefinition.CLEARED_BECAUSE_NO_RESTAURANT_ID:
                case RestaurantSpecificResultLoadingStatusDefinition.LOADING:
                case RestaurantSpecificResultLoadingStatusDefinition.NOT_STARTED:
                    return new RestaurantSpecificArrayResultLoadingState(x.loadingStatus, [], x.error);
                default:
                    throw new NeverError(x.loadingStatus);
            }
        }))
            .subscribe(this.cache$);
    }
    get lastLoadedCheckinDate() {
        return this.checkinOn;
    }
    get items$() {
        return this.cache$;
    }
    forceReload(checkinOn) {
        this.checkinOn = checkinOn;
        return this.checkinsService.forceReload(checkinOn);
    }
    ngOnDestroy() {
        this.cache$.complete();
    }
}
GridItemsSourceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GridItemsSourceService_Factory() { return new GridItemsSourceService(i0.ɵɵinject(i1.CurrentRestaurantTableCheckinsService)); }, token: GridItemsSourceService, providedIn: "root" });
