import {LeftMenuState} from '../app.state';
import {ActionTypes} from '../actions/action-types';
import {LeftMenuActions} from '../actions/left-menu.actions';
import {loginSuccessfulActionHandler} from './left-menu/login-successful.action-handler';
import {restaurantCreatedActionHandler} from './left-menu/restaurant-created.action-handler';
import {restaurantUpdatedActionHandler} from './left-menu/restaurant-updated.action-handler';
import {userInformationLoadedActionHandler} from './left-menu/user-information-loaded.action-handler';

export const initialLeftMenuState: LeftMenuState = {
  items: [],
  activeItemId: null,
};

export function leftMenuReducer(state = initialLeftMenuState, action: LeftMenuActions): LeftMenuState {
  switch (action.type) {
    case ActionTypes.LEFT_MENU_ITEM_SELECTED:
      return {...state, activeItemId: action.id};

    case ActionTypes.LOGIN_SUCCESSFUL:
      return loginSuccessfulActionHandler(state, action);

    case ActionTypes.CURRENT_USER_INFORMATION_LOADED:
      return userInformationLoadedActionHandler(state, action);

    case ActionTypes.RESTAURANT_CREATE_SUCCESSFUL:
      return restaurantCreatedActionHandler(state, action);

    case ActionTypes.RESTAURANT_UPDATE_SUCCESSFUL:
      return restaurantUpdatedActionHandler(state, action);

    default:
      return state;
  }
}

