import {createSelector} from '@ngrx/store';
import {AppState, AuthState} from '../app.state';


const localizationData = (state: AppState) => state.auth;

export const getCurrentLanguageSelector = createSelector(
  localizationData,
  (state: AuthState) => {
    const defaultLang: Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Language = {
      id: 1,
      code: 'en',
      name: 'English'
    };

    return state == null
           ? defaultLang
           : state.currentUser == null
             ? defaultLang
             : state.currentUser.user.preferredLang;
  }
);
