import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {Observable, of, Subject} from 'rxjs';
import {City, GetAllStaticDataResponse, StaticDataService, zipCodeValidator} from 'orderly-web-components';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../util/form.utils';
import {TranslateService} from '@ngx-translate/core';
import {nameof} from '../../../../../util/utils';
import {environment} from '../../../../../environments/environment';
import {map, takeUntil, tap, filter} from 'rxjs/operators';
import {AddressFormDefinition} from './address-form.definition';
import {isNotNullOrEmptyOrWhiteSpaceValidator} from '../../../../../../../orderly-web-components/src/lib/validators';

@Component({
             selector: 'app-shared-address-input',
             templateUrl: './address-input.component.html',
             styleUrls: ['./address-input.component.scss'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: AddressInputComponent,
                 multi: true,
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: AddressInputComponent,
                 multi: true
               }
             ],
           })
export class AddressInputComponent implements ControlValueAccessor, Validator, OnDestroy {

  private initInProgress: boolean = false;

  private destroyed$: Subject<void> = new Subject<void>();

  public cities$: Observable<City[]> = of([]);

  public formDef: FormDefinition<keyof AddressFormDefinition>;


  constructor(private readonly formBuilder: FormBuilder,
              private readonly trnService: TranslateService,
              private readonly staticDataService: StaticDataService,
              private readonly cdr: ChangeDetectorRef) {

    const fieldsDef: FormFieldsDefinition<keyof AddressFormDefinition> = {
      cityId: new FormFieldDefinition(null,
                                      true,
                                      [Validators.required],
                                      []),
      zipCode: new FormFieldDefinition(null,
                                       true,
                                       [],
                                       []),
      street: new FormFieldDefinition(null,
                                      true,
                                      [isNotNullOrEmptyOrWhiteSpaceValidator],
                                      []),
    };


    const zipValidator = zipCodeValidator(nameof<AddressFormDefinition>('cityId'),
                                          nameof<AddressFormDefinition>('zipCode'),
                                          this.trnService);

    const formOpts = {
      validators: [zipValidator]
    };

    this.formDef = new FormDefinition<keyof AddressFormDefinition>(fieldsDef, this.formBuilder, formOpts);

    this.cities$ = this.staticDataService
                       .getAllStaticData(environment.baseApiUrlWithTrailingSlash)
                       .pipe(
                         map((x: GetAllStaticDataResponse) => x.cities),
                         tap((x: City[]) => {

                           if (x.length > 0) {
                             this.formDef.enable(['cityId', 'street'], false);
                           } else {
                             this.formDef.disable('all-fields');
                           }

                           this.cdr.markForCheck();
                         })
                       );

    this.formDef
        .getControl('cityId')
        .valueChanges
        .pipe(
          tap((cityId: number) => {

            if (this.isZipCodeRequired(cityId)) {
              this.formDef.enable(['zipCode'], false);
            } else {
              this.formDef.disable(['zipCode']);
              this.formDef.patchValue({zipCode: null}, false);
            }

            this.cdr.markForCheck();
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe();

    this.formDef
        .form
        .valueChanges
        .pipe(
          filter(() => !this.initInProgress),
          tap((value: AddressFormDefinition) => {

            const cityId = typeof value.cityId === 'number' ? value.cityId : parseInt(value.cityId, 10);
            const zipCode = this.isZipCodeRequired(cityId) ? value.zipCode : null;

            const updatedFormValue: AddressFormDefinition = {
              cityId,
              zipCode,
              street: value.street,
            };

            this.onChange(updatedFormValue);
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: AddressFormDefinition) => {
  };

  onTouched: any = () => {
  };

  public validate(control: AbstractControl): ValidationErrors | null {
    if (control.invalid || this.formDef.form.invalid) {
      return {cityZip: true};
    }

    return null;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: AddressFormDefinition | null): void {

    this.initInProgress = true;

    if (value == null) {
      this.formDef
          .patchValue({
                        cityId: null,
                        zipCode: null,
                        street: null,
                      },
                      false);
    } else {
      this.formDef
          .patchValue({
                        cityId: value.cityId,
                        zipCode: value.zipCode,
                        street: value.street,
                      },
                      false);
    }

    this.initInProgress = false;
  }

  setDisabledState(isDisabled: boolean): void {

    if (isDisabled) {
      this.formDef.disable('all-fields');
    } else {
      const formValue: AddressFormDefinition = this.formDef.form.getRawValue();

      this.formDef.enable(['cityId', 'street'], false);

      if (this.isZipCodeRequired(formValue.cityId)) {
        this.formDef.enable(['zipCode'], false);
      } else {
        this.formDef.disable(['zipCode']);
        this.formDef.patchValue({zipCode: null}, false);
      }
    }

  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  private isZipCodeRequired(cityId: number): boolean {
    return cityId === 2;
  }

}
