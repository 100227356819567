<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs" [destroyOnHide]="false">
  <li [ngbNavItem]="'details'">
    <a ngbNavLink>{{'Details' | translate}}</a>
    <ng-template ngbNavContent>
      <app-menu-item-category-details-tab [formControl]="formDef.getControl('details')"
                                          (nameOrDescriptionChanged)="nameOrDescriptionChanged()">
      </app-menu-item-category-details-tab>
    </ng-template>
  </li>
  <li [ngbNavItem]="'translations'">
    <a ngbNavLink>
      {{'Translations' | translate}}

      <i class="icon-attention-alt-1 name-or-description-changed-icon" *ngIf="indicateNameOrDescriptionChange"></i>
    </a>
    <ng-template ngbNavContent>
      <app-menu-item-category-translations-tab [formControl]="formDef.getControl('translations')">
      </app-menu-item-category-translations-tab>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>

<div class="row">
  <div class="col-12">

    <div class="actions">
      <a (click)="save()" [class.disabled]="formDef.form.invalid" class="btn btn-success">{{'Save' | translate}}</a>

      <a (click)="cancel()" class="btn btn-danger">{{'Cancel' | translate}}</a>
    </div>

  </div>
</div>
