import {Component, OnDestroy} from '@angular/core';
import {RestaurantRelatedItemsGrid} from '../restaurant-related-items-grid.component';
import {PaginationFiltersStateDefinition, ToastService} from 'orderly-web-components';
import {CheckinsListGridItem, SearchFormFieldsDefinition, TableSelectItem} from './helper.types';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {ActivatedRoute} from '@angular/router';
import {NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {GridItemsSourceService} from './grid-items-source.service';
import {catchError, filter, first, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {SearchFiltersDefinition} from './search-filters-definition';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../util/form.utils';
import {FormBuilder} from '@angular/forms';
import {ReplaySubject} from 'rxjs';
import moment from 'moment';
import {CurrentRestaurantTablesService} from '../../../services/active-route-bound/current-restaurant-tables.service';
import {
  formatFileNameWithoutExtension,
  genericErrorHandlerWithToast
} from '../../../util/utils';
import {CurrentRestaurantTableCheckinsService} from '../../../services/active-route-bound/current-restaurant-table-checkins.service';
import { saveAs } from 'file-saver';
import {TranslateService} from '@ngx-translate/core';

@Component({
             selector: 'app-covid-checkins-list',
             templateUrl: './covid-checkins-list.component.html',
             styleUrls: ['./covid-checkins-list.component.scss']
           })
export class CovidCheckinsListComponent
  extends RestaurantRelatedItemsGrid<CheckinsListGridItem, PaginationFiltersStateDefinition>
  implements OnDestroy {

  static today = moment();
  static todayNgbDate = new NgbDate(CovidCheckinsListComponent.today.year(),
                                    CovidCheckinsListComponent.today.month() + 1,
                                    CovidCheckinsListComponent.today.date());

  static initialFiltersState: SearchFiltersDefinition = {
    table: null,
    checkinOn: CovidCheckinsListComponent.todayNgbDate,
    itemsPerPage: 10,
    currentPage: 1
  };

  public maxSelectableFromDate: NgbDateStruct;

  public formDef: FormDefinition<keyof SearchFormFieldsDefinition>;
  public checkins$: ReplaySubject<CheckinsListGridItem[]> = new ReplaySubject<CheckinsListGridItem[]>(1);
  public tables$: ReplaySubject<TableSelectItem[]> = new ReplaySubject<TableSelectItem[]>(1);

  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              tablesService: CurrentRestaurantTablesService,
              private readonly formBuilder: FormBuilder,
              private readonly gridItemsSourceService: GridItemsSourceService,
              private readonly checkinsService: CurrentRestaurantTableCheckinsService,
              private readonly toastService: ToastService,
              private readonly trnService: TranslateService) {

    super(store, activatedRoute, RestaurantRelatedItemsGrid.basicInitialFiltersState, gridItemsSourceService.items$);

    this.maxSelectableFromDate = CovidCheckinsListComponent.todayNgbDate;

    tablesService.currentRestaurantTables$
                 .pipe(
                   filter(x => x.isLoaded()),
                   map(x => x.items.map(i => new TableSelectItem(i))),
                 )
                 .subscribe(this.tables$);

    this.initSearchForm();

    this.searchInLoadedCheckins(CovidCheckinsListComponent.initialFiltersState);
  }


  private initSearchForm() {
    const fieldsDef: FormFieldsDefinition<keyof SearchFormFieldsDefinition> = {
      checkinOn: new FormFieldDefinition(CovidCheckinsListComponent.initialFiltersState.checkinOn, false, [], []),
      table: new FormFieldDefinition(CovidCheckinsListComponent.initialFiltersState.table, false, [], []),
    };

    this.formDef = new FormDefinition<keyof SearchFormFieldsDefinition>(fieldsDef, this.formBuilder);

    this.formDef
        .form
        .valueChanges
        .pipe(
          tap((currentFiltersState: SearchFiltersDefinition) => this.searchInLoadedCheckins(currentFiltersState)),
          takeUntil(this.destroyed$)
        )
        .subscribe();
  }


  private searchInLoadedCheckins(currentFiltersState: SearchFiltersDefinition) {
    const selectedDate = moment();
    selectedDate.set('year', currentFiltersState.checkinOn.year);
    selectedDate.set('month', currentFiltersState.checkinOn.month - 1);
    selectedDate.set('date', currentFiltersState.checkinOn.day);
    selectedDate.startOf('day');

    const selectedTable: TableSelectItem | null = currentFiltersState.table;
    const diffDays = this.gridItemsSourceService.lastLoadedCheckinDate == null
                     ? 1
                     : selectedDate.diff(this.gridItemsSourceService.lastLoadedCheckinDate, 'days');

    const getFilteredCheckinsFn = () => {
      return this.gridItemsSourceService
                 .items$
                 .pipe(
                   filter(x => x.isLoaded()),
                   map(x => x.items),
                   map(items => {
                     const filteredItems = items.filter(item => {
                       if (selectedTable != null) {
                         if (item.source.tableId !== selectedTable.source.id) {
                           return false;
                         }
                       }

                       return true;
                     });

                     return filteredItems;
                   }),
                 );
    };

    if (diffDays !== 0) {
      this.gridItemsSourceService
          .forceReload(selectedDate.toDate())
          .pipe(
            switchMap(x => {
              return getFilteredCheckinsFn();
            }),
            tap(x => {
              this.checkins$.next(x);
            }),
            first()
          )
          .subscribe();
    } else {
      getFilteredCheckinsFn()
        .pipe(
          tap(x => {
            this.checkins$.next(x);
          }),
          first()
        )
        .subscribe();
    }
  }

  public refreshCheckinsList() {
    const searchFormValue: SearchFormFieldsDefinition = this.formDef.form.getRawValue();

    const selectedDate = moment();
    selectedDate.set('year', searchFormValue.checkinOn.year);
    selectedDate.set('month', searchFormValue.checkinOn.month - 1);
    selectedDate.set('date', searchFormValue.checkinOn.day);
    selectedDate.startOf('day');

    this.gridItemsSourceService
        .forceReload(selectedDate.toDate())
        .pipe(
          switchMap(x => this.filtersState$),
          first(),
          tap(filterState => {
            const currentFiltersStateClone = {...filterState};

            currentFiltersStateClone.currentPage = 1;

            this.pagingFiltersChanged(currentFiltersStateClone);
          })
        )
        .subscribe();
  }

  public downloadAsPdf(item: CheckinsListGridItem) {
    const unexpectedFailureText = this.trnService.instant('Download failed because of unexpected failure. Please try again later.');

    this.checkinsService
        .downloadCheckinAsPdf(item.id)
        .pipe(
          first(),
          tap((res: Blob) => {
            const fileName = formatFileNameWithoutExtension(item.tabelName + ' - ' + item.id) + '.pdf';

            saveAs(res, fileName);
          }),
          catchError(genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedFailureText))
        )
        .subscribe();
  }

  ngOnDestroy() {
    this.checkins$.complete();
    this.tables$.complete();

    super.ngOnDestroy();
  }

}
