<div class="modal-header pd-y-20 pd-x-25">
  <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{'Update profile' | translate}}</h6>
  <button type="button" class="close" [attr.aria-label]="'Close'" (click)="modalService.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pd-25">

  <form [formGroup]="form" (ngSubmit)="save()">

    <div class="row">
      <div class="form-layout form-layout-1" style="width: 100%;">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">{{'First name' | translate}}: <span class="tx-danger">*</span></label>
              <input class="form-control" type="text" formControlName="firstName" maxlength="20" />

              <owc-input-validation-errors [control]="form.get('firstName')"></owc-input-validation-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">{{'Last name' | translate}}: <span class="tx-danger">*</span></label>
              <input class="form-control" type="text" formControlName="lastName" maxlength="20" />

              <owc-input-validation-errors [control]="form.get('lastName')"></owc-input-validation-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">{{'Language' | translate}}: <span class="tx-danger">*</span></label>

              <ng-select [items]="languages$ | async"
                         formControlName="language"
                         bindLabel="name"
                         [compareWith]="compareLangs"
                         placeholder="Select language">
              </ng-select>

              <owc-input-validation-errors [control]="form.get('language')"></owc-input-validation-errors>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer tx-white">
  <owc-progress-action-button [actionInProgress]="actionInProgress"
                              (execute)="save()"
                              [disabled]="form.invalid"
                              [btnText]="'Save' | translate"
                              type="link">
  </owc-progress-action-button>
</div>
