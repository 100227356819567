import {AppState, AuthState} from '../app.state';
import {createSelector, select} from '@ngrx/store';
import {AuthenticatedUserModel} from '../../../services/local-storage.service';
import {KeyCloakUserRoles} from '../../../models/roles';

const routerStateData = (state: AppState) => state.router;
const userData = (state: AppState) => state.auth;

export const getAuthenticatedUserSelector = createSelector(
  userData,
  (state: AuthState) => (state.currentUser || {user: null}).user
);

export const getAuthenticatedUserIsRestaurantManagerSelector = createSelector(
  userData,
  (state: AuthState) => {
    if (state.currentUser == null) {
      return false;
    }

    const isManager = AuthenticatedUserModel.isInRole(state.currentUser, KeyCloakUserRoles.RESTAURANT_MANAGER) ||
      AuthenticatedUserModel.isInRole(state.currentUser, KeyCloakUserRoles.RESTAURANT_MANAGER_ASSISTANT) ||
      AuthenticatedUserModel.isInRole(state.currentUser, KeyCloakUserRoles.RESTAURANT_HOLDING_MANAGER) ||
      AuthenticatedUserModel.isInRole(state.currentUser, KeyCloakUserRoles.RESTAURANT_OWNER);

    return isManager;
  }
);

export const getRestaurantIdParamSelector = createSelector(
  routerStateData,
  (x) => {
    if (x == null) {
      // happens during app initialization
      return null;
    }

    const result = findDashboardContainer(x.state.root.firstChild);

    return result;
  }
);


export const selectRestaurantIdParamFromAppState = select(getRestaurantIdParamSelector);

export function findDashboardContainer(activatedRoute): number | null {
  if (activatedRoute == null) {
    return null;
  }

  const restaurantIdRaw = activatedRoute.paramMap.get('restaurantId');

  if (restaurantIdRaw == null) {
    return findDashboardContainer(activatedRoute.firstChild);
  }

  const restaurantId = parseInt(restaurantIdRaw, 10);

  return restaurantId;
}
