import {Component, ViewEncapsulation} from '@angular/core';
import {NeverError, PaginationFiltersStateDefinition, ToastService} from 'orderly-web-components';
import {catchError, filter, first, map, switchMap, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import MenuItemCategory = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemCategoryResponse.MenuItemCategory;
import DeleteMenuItemCategoryResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.DeleteMenuItemCategoryResponse;
import {CategoriesOrderModalComponent} from './categories-order-modal/categories-order-modal.component';
import {RestaurantRelatedItemsGrid} from '../../../../dashboard/restaurant-related-items-grid.component';
import {CurrentRestaurantMenuItemCategoriesService} from '../../../../../services/active-route-bound/current-restaurant-menu-item-categories.service';
import {AppState} from '../../../../store/app.state';
import {DeleteModalUtils} from '../../../../../util/delete-modal.utils';
import {genericErrorHandlerWithToast} from '../../../../../util/utils';
import {combineLatest, Observable} from 'rxjs';
import {CategoryWithEditUrlModel} from './category-with-edit-url.model';
import {RestaurantManagerRoutingConstants} from '../../../../routing-constants';

@Component({
  selector: 'app-menu-item-categories-list',
  templateUrl: './menu-item-categories-list.component.html',
  styleUrls: ['./menu-item-categories-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuItemCategoriesListComponent extends RestaurantRelatedItemsGrid<MenuItemCategory, PaginationFiltersStateDefinition> {

  public addCategoryUrl$: Observable<string>;

  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              public menuItemCategoriesService: CurrentRestaurantMenuItemCategoriesService,
              private modalService: NgbModal,
              private trnService: TranslateService,
              private toastService: ToastService,
              private deleteModalUtils: DeleteModalUtils) {

    super(store, activatedRoute, RestaurantRelatedItemsGrid.basicInitialFiltersState, menuItemCategoriesService.currentRestaurantMenuItemCategories$);


    this.addCategoryUrl$ = this.restaurantIdParam$
                               .pipe(
                                 map(rid => '/' + RestaurantManagerRoutingConstants.getRestaurantMenuItemCategoryAddUrl(rid))
                               );
  }

  public get gridItemsWithUrls$(): Observable<CategoryWithEditUrlModel[]> {
    return combineLatest([this.restaurantIdParam$, this.displayedGridItems$])
      .pipe(
        map((x: [number, MenuItemCategory[]]) => {
          return x[1].map(mic => this.convert(x[0], mic));
        }),
      );
  }

  private convert(restaurantId: number, source: MenuItemCategory): CategoryWithEditUrlModel {
    const editUrl = '/' + RestaurantManagerRoutingConstants.getRestaurantMenuItemCategoryEditUrl(restaurantId, source.id);

    return {...source, editUrl};
  }

  public refreshCategoriesList() {
    this.menuItemCategoriesService
        .forceReload()
        .pipe(
          switchMap(x => this.filtersState$),
          first(),
          tap(filterState => {
            const currentFiltersStateClone = {...filterState};

            currentFiltersStateClone.currentPage = 1;

            this.pagingFiltersChanged(currentFiltersStateClone);
          })
        )
        .subscribe();
  }

  public deleteCategory(category: MenuItemCategory) {
    const onDelete = (modalRef: NgbModalRef) => {

      const unexpectedErrorText = this.trnService.instant('Failed to delete a category because of unexpected error.');

      this.menuItemCategoriesService
          .delete(category.id)
          .pipe(
            tap(x => {
              switch (x.status) {
                case DeleteMenuItemCategoryResponse.StatusDef.Success:
                  this.menuItemCategoriesService.forceReload();
                  modalRef.close();
                  break;
                case DeleteMenuItemCategoryResponse.StatusDef.MenuIngredientsExist:
                  const menuItemsExistErrorText = this.trnService.instant('Failed to delete because there are menu items in this category.');
                  this.toastService.showError(menuItemsExistErrorText);
                  break;
                case DeleteMenuItemCategoryResponse.StatusDef.UnknownFailure:
                  this.toastService.showError(unexpectedErrorText);
                  break;
                default:
                  throw new NeverError(x.status);
              }
            }),
            catchError(
              genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedErrorText)
            )
          ).subscribe();
    };

    const modalTitle = this.trnService.instant(`Delete area`);
    const modalConfirmText = this.trnService.instant(`Do you want to delete a category '{{name}}'?`, {name: category.name});

    this.deleteModalUtils.showDeleteModal(category, modalTitle, [modalConfirmText], onDelete);
  }

  public changeSortingOrder() {
    this.menuItemCategoriesService
        .currentRestaurantMenuItemCategories$
        .pipe(
          filter(state => state.isLoaded()),
          map(x => x.items),
          first(),
          tap((categories: MenuItemCategory[]) => {
            const modalRef = this.modalService.open(CategoriesOrderModalComponent, {centered: true, size: 'lg'});
            const component: CategoriesOrderModalComponent = modalRef.componentInstance;

            component.categoriesList = categories;

            modalRef.result
                    .then((sortedCategories: MenuItemCategory[] | null) => {
                      if (sortedCategories != null) {
                        this.menuItemCategoriesService.setValue(sortedCategories);
                      }
                    });
          })
        )
        .subscribe();
  }
}
