/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../top-bar-with-language-switcher/top-bar-with-language-switcher.component.ngfactory";
import * as i3 from "../top-bar-with-language-switcher/top-bar-with-language-switcher.component";
import * as i4 from "@ngrx/store";
import * as i5 from "orderly-web-components";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../../dist/orderly-web-components/orderly-web-components.ngfactory";
import * as i8 from "@angular/forms";
import * as i9 from "./login-page.component";
import * as i10 from "../../services/auth.service";
var styles_LoginPageComponent = [i0.styles];
var RenderType_LoginPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginPageComponent, data: {} });
export { RenderType_LoginPageComponent as RenderType_LoginPageComponent };
export function View_LoginPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { loginComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-top-bar-with-language-switcher", [], null, null, null, i2.View_TopBarWithLanguageSwitcherComponent_0, i2.RenderType_TopBarWithLanguageSwitcherComponent)), i1.ɵdid(2, 180224, null, 0, i3.TopBarWithLanguageSwitcherComponent, [i4.Store, i5.StaticDataService, i6.TranslateService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "owc-login", [], null, [[null, "onLogin"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLogin" === en)) {
        var pd_0 = (_co.login($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_LoginComponent_0, i7.RenderType_LoginComponent)), i1.ɵdid(4, 245760, [[1, 4], ["loginComponent", 4]], 0, i5.LoginComponent, [i8.FormBuilder], { disableControls: [0, "disableControls"], cfg: [1, "cfg"] }, { onLogin: "onLogin" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loginInProgress; var currVal_1 = _co.loginComponentCfg; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_LoginPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-page", [], null, null, null, View_LoginPageComponent_0, RenderType_LoginPageComponent)), i1.ɵdid(1, 49152, null, 0, i9.LoginPageComponent, [i10.AuthService, i4.Store, i5.ToastService, i6.TranslateService], null, null)], null, null); }
var LoginPageComponentNgFactory = i1.ɵccf("app-login-page", i9.LoginPageComponent, View_LoginPageComponent_Host_0, {}, {}, []);
export { LoginPageComponentNgFactory as LoginPageComponentNgFactory };
