import {RestaurantEditorFormFieldsDefinitions} from './restaurant-editor-form-fields.definitions';
import RestaurantInfoWithSettingsAndContactDetails = Orderly.RestaurantWeb.Api.Messages.RestaurantInfoWithSettingsAndContactDetails;
import {ExistingFileData} from '../../../shared/components/file-upload/existing-file-data';

export function convertRestaurantInfoToEditorFormFieldsDefinitions(source: RestaurantInfoWithSettingsAndContactDetails): RestaurantEditorFormFieldsDefinitions {
  const menuFile: ExistingFileData | null = source.settings.menuFile == null
                                            ? null
                                            : new ExistingFileData(source.settings.menuFile.url,
                                                                   source.settings.menuFile.name,
                                                                   source.settings.menuFile.createdOn);
  const menuFiles = menuFile == null ? [] : [menuFile];

  const result: RestaurantEditorFormFieldsDefinitions = {
    address: {
      cityId: source.contactDetails.city.id,
      street: source.contactDetails.address,
      zipCode: source.contactDetails.zipCode,
    },
    contactEmail: source.contactDetails.contactEmail,
    contactPhone: source.contactDetails.contactPhone,
    name: source.name,
    qrMenuSettings: {
      qrMenuButtonAction: source.settings.qrMenuActionType,
      menuFiles: menuFiles,
      isMenuReadonly: source.settings.ordersFromQrMenuEnabled,
      actionTokenValidityPeriod: 5
    },
    kioskSettings: {
      selfOrderingKiosksEnabled: source.settings.selfOrderingKiosksEnabled,
      menuCategoriesListStyle: source.settings.selfServiceKiosksMenuItemGroupsListStyle,
      callWaiterEnabled: source.settings.callWaiterFromSelfServiceKiosksEnabled,
      guestsCanMakeOrders: source.settings.ordersFromSelfServiceKiosksEnabled
    },
    menuSettings: {
      menuCurrency: source.settings.defaultCurrency,
      menuDefaultLanguageId: source.settings.defaultMenuLanguage.id,
      translationLanguages: source.settings.enabledMenuLanguages,
    },
    onlineReviewsEnabled: source.settings.reviewsEnabled,
  };

  return result;
}
