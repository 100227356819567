import {ModifierGroupEditorFormFieldsDefinition} from './modifier-group-editor/modifier-group-editor-form-fields.definition';
import MenuItemModifier = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData.MenuItemModifier;
import {TextTranslationDefinition} from '../../../../shared/components/text-input-with-language-flag/text-translation.definition';

export class EditModifierGroupModel implements ModifierGroupEditorFormFieldsDefinition {
  constructor(
    public readonly groupIndex: number,
    public readonly menuItemModifiers: MenuItemModifier[],
    public readonly id: number | null,
    public readonly name: TextTranslationDefinition,
    public readonly minAllowedAmount: number,
    public readonly maxAllowedAmount: number | null,
    public readonly isOnlyOnePermitted: boolean,
    public readonly isActive: boolean,
    public readonly isVisible: boolean,
    public readonly nameTranslations: TextTranslationDefinition[]) {
  }
}
