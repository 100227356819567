<div class="br-header" style="left: 0;">
  <div class="br-header-left"></div>
  <div class="br-header-right">
    <nav class="nav">
      <div ngbDropdown *ngIf="activeLanguage$ | async as activeLanguage">
        <a href="javascript:void(0);" class="nav-link nav-link-profile" aria-expanded="false" ngbDropdownToggle>
          <img src="../../assets/lang-flags/{{activeLanguage.code}}.png"
               height="17"
               width="23"
               style="display: inline;"
               class="mg-r-5"
               [alt]="activeLanguage.name"
               *ngIf="activeLanguage != null"/> <span class="logged-name hidden-md-down" style="display: inline;" *ngIf="activeLanguage != null">{{activeLanguage.name}}</span>
        </a>

        <div ngbDropdownMenu
             class="dropdown-menu-header wd-250"
             x-placement="bottom-start"
             style="position: absolute; transform: translate3d(-65px, 48px, 0px); top: 0px; left: 0px; will-change: transform;"
             *ngIf="languages$ | async as langs">

          <ul class="list-unstyled languages-menu" x-placement="bottom-start">
            <li class="lang-item" *ngFor="let lang of langs" [class.active]="activeLanguage != null && lang.code == activeLanguage.code">
              <a (click)="onChangeLanguage(lang)" href="javascript:void(0);">
                <i class="icon-angle-right mg-r-8" *ngIf="activeLanguage != null && lang.code == activeLanguage.code"></i>

                <img src="../../assets/lang-flags/{{lang.code}}.png"
                     height="17"
                     width="23"
                     style="display: inline;"
                     class="mg-r-5"
                     [alt]="lang.name"
                     *ngIf="activeLanguage != null && lang.code != activeLanguage.code" />

                {{lang.name}}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div>
