import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RestaurantEditorComponent} from './components/restaurant-editor/restaurant-editor.component';
import {SharedModule} from '../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ImageCropperModule} from 'ngx-image-cropper';
import {
  CustomMissingTranslationHandler,
  TranslationHttpLoaderFactory,
  TranslationsModule
} from '../translations/translations.module';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {NgSelectModule} from '@ng-select/ng-select';
import {OrderlyWebComponentsModule} from 'orderly-web-components';
import {AddOrEditRestaurantComponent} from './components/add-or-edit-restaurant/add-or-edit-restaurant.component';
import {InputNumberModule} from '@deividfortuna/input-number';
import {QrMenuActionSelectComponent} from './components/restaurant-editor/online-menu-action/qr-menu-action-select/qr-menu-action-select.component';
import { OnlineMenuActionComponent } from './components/restaurant-editor/online-menu-action/online-menu-action.component';
import { KioskSettingsComponent } from './components/restaurant-editor/kiosk-settings/kiosk-settings.component';
import { MenuCategoriesListStyleSelectComponent } from './components/restaurant-editor/kiosk-settings/menu-categories-list-style-select/menu-categories-list-style-select.component';
import { MenuSettingsComponent } from './components/restaurant-editor/menu-settings/menu-settings.component';


@NgModule({
            declarations: [
              RestaurantEditorComponent,
              AddOrEditRestaurantComponent,
              QrMenuActionSelectComponent,
              OnlineMenuActionComponent,
              KioskSettingsComponent,
              MenuCategoriesListStyleSelectComponent,
              MenuSettingsComponent
            ],
            imports: [
              CommonModule,
              FormsModule,
              ReactiveFormsModule,
              NgSelectModule,
              ImageCropperModule,
              InputNumberModule,
              TranslationsModule,

              OrderlyWebComponentsModule,
              SharedModule,

              TranslateModule.forRoot({
                                        loader: {
                                          provide: TranslateLoader,
                                          useFactory: TranslationHttpLoaderFactory,
                                          deps: [HttpClient]
                                        },
                                        missingTranslationHandler: {
                                          provide: MissingTranslationHandler,
                                          useClass: CustomMissingTranslationHandler
                                        },
                                        isolate: true
                                      }),
            ],
            exports: [
              RestaurantEditorComponent
            ]
          })
export class RestaurantsModule {}

export {RestaurantEditorComponent} from './components/restaurant-editor/restaurant-editor.component';
