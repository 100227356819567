import {Component, EventEmitter, Input, Output} from '@angular/core';
import MenuItemModifier = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData.MenuItemModifier;

@Component({
             selector: 'app-modifier-row',
             templateUrl: './modifier-row.component.html',
             styleUrls: ['./modifier-row.component.scss'],
           })
export class ModifierRowComponent {

  @Input()
  public modifier: MenuItemModifier;

  @Output()
  public readonly delete: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly update: EventEmitter<MenuItemModifier> = new EventEmitter<MenuItemModifier>();

}
