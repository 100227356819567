import { CurrentRestaurantServiceBase } from './current-restaurant.service.base';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
export class CurrentRestaurantOpenedRequestsService extends CurrentRestaurantServiceBase {
    constructor(httpClient, store) {
        super(store);
        this.httpClient = httpClient;
        this.baseURL = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
    }
    get currentRestaurantOpenedRequests$() {
        return this.dataFeed$;
    }
    doLoadData(restaurantId) {
        const url = `${this.baseURL}/restaurant/${restaurantId}/table-action-requests/opened/all`;
        const response$ = this.httpClient.get(url);
        return response$.pipe(map(x => x.requests));
    }
}
CurrentRestaurantOpenedRequestsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentRestaurantOpenedRequestsService_Factory() { return new CurrentRestaurantOpenedRequestsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: CurrentRestaurantOpenedRequestsService, providedIn: "root" });
