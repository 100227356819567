import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {ActionTypes} from '../actions/action-types';
import {map, switchMap, tap} from 'rxjs/operators';
import {RestaurantService} from '../../../services/restaurant.service';
import {RestaurantOnboardingCompletedAction, RestaurantSuccessfullyUpdatedAction} from '../actions/restaurant.actions';
import {LocalStorageService} from '../../../services/local-storage.service';
import {CurrentUserSelectedLanguageChangedAction} from '../actions/user.actions';
import {AppState} from '../app.state';

@Injectable()
export class RestaurantEffect {

  constructor(private readonly actions: Actions,
              private readonly restaurantService: RestaurantService,
              private readonly store: Store<AppState>) {
  }

  // TODO:
  // @Effect()
  // loadDashboardDataEffect: Observable<Action> = this.actions.pipe(
  //   ofType<LoadRestaurantDashboardDataAction>(ActionTypes.RESTAURANT_LOAD_DASHBOARD_DATA),
  //
  //   exhaustMap((action: LoadRestaurantDashboardDataAction) => {
  //     return this.restaurantService
  //                .getRestaurant(action.restaurantId)
  //                .pipe(
  //                  map((data: GetRestaurantResponse) => {
  //                    return new RestaurantDashboardDataLoadedSuccessfullyAction(data);
  //                  }),
  //                  catchError((err, _) => {
  //                    return of(new RestaurantDashboardDataLoadFailedAction(err));
  //                  })
  //                );
  //   })
  // );

  @Effect({dispatch: false})
  onboardingCompletedEffect: Observable<Action> = this.actions.pipe(
    ofType<RestaurantOnboardingCompletedAction>(ActionTypes.RESTAURANT_ONBOARDING_COMPLETED),

    switchMap((action: RestaurantOnboardingCompletedAction) => {
      return this.restaurantService.completeOnboarding(action.restaurantHoldingId);
    }),

    switchMap((action: CurrentUserSelectedLanguageChangedAction) => {
      const authUser$ = this.store.select(x => x.auth);

      return authUser$.pipe(
        map(user => {
          return {
            action: action,
            authState: user
          };
        })
      );
    }),

    tap(data => {
      if (data.authState.currentUser != null) {
        const clonedUser: Orderly.RestaurantWeb.Api.Messages.UserWithRestaurantsInfo = {...data.authState.currentUser.user};

        clonedUser.holdingInfo.isOnboardingCompleted = true;

        LocalStorageService.setCurrentUser(data.authState.currentUser.jwtTokenBase64, clonedUser);
      }
    }),

    map(x => x.action)
  );


  @Effect({dispatch: false})
  restaurantUpdatedEffect: Observable<Action> = this.actions.pipe(
    ofType<RestaurantSuccessfullyUpdatedAction>(ActionTypes.RESTAURANT_UPDATE_SUCCESSFUL),

    tap((action: RestaurantSuccessfullyUpdatedAction) => {
      const currentUser = LocalStorageService.getCurrentUser();

      if (currentUser != null) {
        const userWithRestaurantsInfo = {...currentUser.user};
        const updatedRestaurantIdx = userWithRestaurantsInfo.restaurants.findIndex(x => x.id === action.restaurant.id);

        if (updatedRestaurantIdx > -1) {
          const updatedRestaurantClone = {...userWithRestaurantsInfo.restaurants[updatedRestaurantIdx]};

          updatedRestaurantClone.name = action.restaurant.name;

          userWithRestaurantsInfo.restaurants[updatedRestaurantIdx] = updatedRestaurantClone;

          LocalStorageService.setCurrentUser(currentUser.jwtTokenBase64, userWithRestaurantsInfo);
        }
      }
    })
  );
}
