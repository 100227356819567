import {v4 as uuidv4} from 'uuid';
import {Injectable, OnDestroy} from '@angular/core';
import {ProgressDialogToken} from './progress-dialog.token';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ProgressDialogService implements OnDestroy {

  private readonly displayRequests$ = new BehaviorSubject<ProgressDialogToken[]>([]);

  public get isVisible$(): Observable<any> {
    return this.displayRequests$.pipe(
      map((x: ProgressDialogToken[]) => x.length > 0),
    );
  }

  public display(): ProgressDialogToken {
    const newReq: ProgressDialogToken = new ProgressDialogToken(uuidv4());
    const reqs = [...this.displayRequests$.value, newReq];

    this.displayRequests$.next(reqs);

    return newReq;
  }

  public hideAll(): void {
    this.displayRequests$.next([]);
  }

  public hide(token: ProgressDialogToken): void {
    const reqs = [...this.displayRequests$.value];
    const tokenIdx = reqs.findIndex((x) => x.id === token.id);

    if (tokenIdx >= 0) {
      reqs.splice(
        tokenIdx, 1,
      );
    }

    this.displayRequests$.next(reqs);
  }

  ngOnDestroy(): void {
    this.displayRequests$.complete();
  }
}
