import {Component, Input, OnInit} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {TranslationsDefinition} from './translations-definition';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToastService, NeverError} from 'orderly-web-components';
import {catchError, finalize, first, map, tap} from 'rxjs/operators';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../util/form.utils';
import {genericErrorHandlerWithToast, nameof} from '../../util/utils';
import ReportBrokenDisplayRequest = Orderly.RestaurantWeb.Api.Messages.Display.ReportBrokenDisplayRequest;
import ReportBrokenDisplayResponse = Orderly.RestaurantWeb.Api.Messages.Display.ReportBrokenDisplayResponse;
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {assignTranslatedProperties, getArrayOfTranslationKeys, trnTryAgainLaterText} from '../../util/trn.utils';
import {CurrentRestaurantDisplaysService} from '../../services/active-route-bound/current-restaurant-displays.service';

type ReportBrokenDisplayModalComponentFormFields = 'message' | 'displayName';

@Component({
  selector: 'app-report-broken-display-modal',
  templateUrl: './report-broken-display-modal.component.html',
  styleUrls: ['./report-broken-display-modal.component.scss']
})
export class ReportBrokenDisplayModalComponent implements OnInit {

  @Input()
  public display: Orderly.RestaurantWeb.Api.Messages.Display.GetDisplaysResponse.Display;

  @Input()
  public restaurantId: number;

  public translationsCfg: ReplaySubject<TranslationsDefinition> = new ReplaySubject<TranslationsDefinition>(1);

  public formDef: FormDefinition<ReportBrokenDisplayModalComponentFormFields>;
  public actionRunning: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private trnService: TranslateService,
              private toastService: ToastService,
              private displayService: CurrentRestaurantDisplaysService) { }

  ngOnInit() {
    this.initTranslations();

    const fieldsDef: FormFieldsDefinition<ReportBrokenDisplayModalComponentFormFields> = {
      message: new FormFieldDefinition('',
                                       false,
                                       [Validators.maxLength(300)],
                                       [
                                         nameof<ReportBrokenDisplayRequest>('message')
                                       ]),
      displayName: new FormFieldDefinition(this.display.uniqueCode, true, [], [])
    };

    this.formDef = new FormDefinition<ReportBrokenDisplayModalComponentFormFields>(fieldsDef, this.formBuilder);
  }


  private initTranslations() {
    const initialTranslations: TranslationsDefinition = {
      reportButton: _('Report'),
      cancelButton: _('Cancel'),
      modalTitle: _('Report broken display'),
      displayNameLabel: _('Display code'),
      messageLabel: _('Message')
    };

    this.translationsCfg.next(initialTranslations);

    const translationKeys = getArrayOfTranslationKeys(initialTranslations);

    this.trnService
        .stream(translationKeys)
        .pipe(
          map(translations => {
            const result: TranslationsDefinition = assignTranslatedProperties(initialTranslations, translations);

            return result;
          })
        )
        .subscribe(this.translationsCfg);
  }

  public report() {
    if (this.formDef.form.invalid) {
      return;
    }

    const tryAgainLaterMsg = trnTryAgainLaterText(this.trnService);
    const message = this.formDef.getControl('message').value;
    const unexpectedServerFailureMsg = this.trnService.instant('There was an error while reporting broken display. ' + tryAgainLaterMsg);

    const request: ReportBrokenDisplayRequest = {
      message: message
    };

    const disabledFieldsToken = this.formDef.disable('all-fields');

    this.actionRunning = true;

    this.displayService
        .reportBroken(this.display.id, request)
        .pipe(
          first(),
          tap(x => {
            switch (x.status) {
              case ReportBrokenDisplayResponse.StatusDef.ValidationFailed:
                disabledFieldsToken.reenable();
                this.formDef.setFormFieldsServerValidationResults(x.validationErrors);
                break;
              case ReportBrokenDisplayResponse.StatusDef.Success:
                const successMessage = this.trnService.instant('Report was successfully submitted.');
                this.toastService.showSuccess(successMessage);
                this.activeModal.close(true);
                break;
              case ReportBrokenDisplayResponse.StatusDef.UnknownFailure:
                this.toastService.showError(unexpectedServerFailureMsg);
                break;
              default:
                throw new NeverError(x.status);
            }
          }),
          catchError(
            genericErrorHandlerWithToast<ReportBrokenDisplayResponse>(this.toastService, this.trnService, unexpectedServerFailureMsg)
          ),
          finalize(() => {
            disabledFieldsToken.reenable();

            this.actionRunning = false;
          })
        )
        .subscribe();
  }
}
