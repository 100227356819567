import {Currency, MeasureUnit, requiredIfSelectedValidator} from 'orderly-web-components';
import {FormBuilder, Validators} from '@angular/forms';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../../../util/form.utils';
import {nameof} from '../../../../../../../util/utils';
import {measureUnitValidator, sizesValidator} from './validators';
import {Modify} from '../../../../../shared/ts-utils';
import MenuItemWithAllData = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData;

export interface MenuItemSizeDef {
  id: number;
  name: string;
  price: number;
  amount: number;
  sortingOrder: number;
}

export interface PriceEditorFormFieldsDefinition {
  hasMultipleSizes: boolean;

  price: number;
  currency: Currency;

  amount: number | null;
  measureUnit: MeasureUnit | null;
  sizes: MenuItemSizeDef[];
}

export interface NewPriceEditorFormFieldsDefinition extends Modify<PriceEditorFormFieldsDefinition,
  {
    hasMultipleSizes: boolean | null,
    currency: Currency | null,
    price: number | null,
  }
  > {}

export function createForm(formBuilder: FormBuilder): FormDefinition<keyof PriceEditorFormFieldsDefinition> {
  const fieldsDef: FormFieldsDefinition<keyof PriceEditorFormFieldsDefinition> = {
    hasMultipleSizes: new FormFieldDefinition(false,
                                              false,
                                              [Validators.required],
                                              []),

    price: new FormFieldDefinition(null,
                                   false,
                                   [],
                                   []),
    currency: new FormFieldDefinition(null,
                                      true,
                                      [Validators.required],
                                      []),

    amount: new FormFieldDefinition(null,
                                    false,
                                    [],
                                    []),
    measureUnit: new FormFieldDefinition(null,
                                         true,
                                         [],
                                         []),


    sizes: new FormFieldDefinition([],
                                   false,
                                   [],
                                   []),
  };


  const formOpts = {
    validators: [
      requiredIfSelectedValidator(nameof<PriceEditorFormFieldsDefinition>('price'), {
        radioOrCheckboxControlName: nameof<PriceEditorFormFieldsDefinition>('hasMultipleSizes'),
        selectedValue: false
      }),
      measureUnitValidator(),
      sizesValidator(),
    ]
  };

  return new FormDefinition(fieldsDef, formBuilder, formOpts);
}

export function createNewPriceEditorFormFieldsDefinition(preselectedCurrency: Currency | null): NewPriceEditorFormFieldsDefinition {
  return {
    sizes: [],
    amount: null,
    measureUnit: null,
    price: null,
    currency: preselectedCurrency,
    hasMultipleSizes: null,
  }
}

export function createPriceEditorFormFieldsDefinition(existingMenuItem: MenuItemWithAllData): PriceEditorFormFieldsDefinition {

  const sizes: MenuItemSizeDef[] = existingMenuItem.sizes.map(x => {
    return {
      price: x.price,
      amount: x.amount,
      id: x.id,
      name: x.name,
      sortingOrder: x.sortingOrder,
    }
  });

  return {
    sizes,
    amount: existingMenuItem.sizes.length > 0 ? null : existingMenuItem.amount,
    measureUnit: existingMenuItem.measureUnit,
    price: existingMenuItem.sizes.length > 0 ? 0 : existingMenuItem.price,
    currency: existingMenuItem.currency,
    hasMultipleSizes: existingMenuItem.sizes.length > 0,
  }
}
