export class BooleanSelectItem {
  constructor(public value: boolean,
              public text: string) {
  }
}


export interface Translations {
  addTitle: string;
  updateTitle: string;
  nameLabel: string;
  measureUnitLabel: string;
  categoryLabel: string;
  containsAlcoholLabel: string;
  allergensLabel: string;
  saveButtonLabel: string;
}
