import {TranslateService} from '@ngx-translate/core';
import {YesNoTranslations} from '../../../../../util/trn.utils';
import {Component} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BinaryChoiceSwitchComponentBase} from './binary-choice-switch.component';

@Component({
             selector: 'app-shared-yes-no-switch',
             templateUrl: './binary-choice-switch.component.html',
             styleUrls: ['./binary-choice-switch.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: YesNoSwitchComponent,
                 multi: true,
               },
             ],
           })
export class YesNoSwitchComponent extends BinaryChoiceSwitchComponentBase {

  constructor(trnService: TranslateService) {

    super(trnService, YesNoTranslations.Yes, YesNoTranslations.No);

  }

}
