<!-- ########## START: LEFT PANEL ########## -->
<owc-left-menu [items]="leftMenuItems$ | async" (menuItemSelected)="menuItemSelected($event)"></owc-left-menu>
<!-- ########## END: LEFT PANEL ########## -->

<!-- ########## START: HEAD PANEL ########## -->
<owc-top-navigation-bar [user]="user | async"
                        (logout)="logout()"
                        (updateProfile)="onUpdateUserProfile()"
                        (changePassword)="onChangeUserPassword()"
                        (changeLanguage)="onChangeLanguage($event)"
                        [cfg$]="topNavigationBarCfg$"></owc-top-navigation-bar>
<!-- ########## END: HEAD PANEL ########## -->

<!-- ########## START: MAIN PANEL ########## -->
<div class="br-mainpanel">
  <div style="display: flex;"></div>

  <div class="br-pagebody">

    <router-outlet></router-outlet>
  </div>
</div>
