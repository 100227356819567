import {Component, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import RestaurantIngredientBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
import {Observable} from 'rxjs';

@Component({
             selector: 'app-shared-modifier-ingredient-select',
             templateUrl: './modifier-ingredient-select.component.html',
             styleUrls: ['./modifier-ingredient-select.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: ModifierIngredientSelectComponent,
                 multi: true,
               },
             ],
           })
export class ModifierIngredientSelectComponent implements ControlValueAccessor {

  @Input()
  public ingredients$: Observable<RestaurantIngredientBasic[]>;


  public lastValue: RestaurantIngredientBasic | null = null;

  public isDisabled: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: RestaurantIngredientBasic | null) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: RestaurantIngredientBasic | null = null): void {
    this.lastValue = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public changed(newValue: RestaurantIngredientBasic | null): void {

    if (this.lastValue == null && newValue == null) {
      return;
    }

    this.lastValue = newValue;

    this.onTouched();
    this.onChange(newValue);
  }

  public clear(): void {

    this.changed(null);

  }
}
