import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalDialogComponent, ModalMessageTextParagraph} from 'orderly-web-components';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({
              providedIn: 'root'
            })
export class DeleteModalUtils {
  constructor(private modalService: NgbModal,
              private trnService: TranslateService) {
  }

  public showDeleteModal(subject: any,
                         title: string,
                         dialogText: string[],
                         onDelete: (modalRef: NgbModalRef) => void,
                         deleteBtnText: string = _('Delete')) {
    const modalRef = this.modalService.open(ConfirmationModalDialogComponent, {centered: true, size: 'lg'});

    const modalInstance: ConfirmationModalDialogComponent = modalRef.componentInstance;

    modalInstance.cancelButtonText = this.trnService.instant('Cancel');
    modalInstance.okButtonText = this.trnService.instant(deleteBtnText);
    modalInstance.data = subject;
    modalInstance.title = title;
    modalInstance.messages = dialogText.map(x => new ModalMessageTextParagraph(x));

    const completedSubscription = modalInstance.completed.subscribe(($e) => {
      onDelete(modalRef);
    });

    modalRef.result.finally(() => {
      completedSubscription.unsubscribe();
    });
  }
}
