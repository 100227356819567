import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ActionTypes} from '../actions/action-types';
import {tap} from 'rxjs/operators';
import {
  IngredientCategoryAddSuccessfulAction,
  IngredientCategoryDeleteSuccessfulAction
} from '../actions/ingredient-category.actions';
import {CurrentRestaurantIngredientCategoriesService} from '../../../services/active-route-bound/current-restaurant-ingredient-categories.service';

@Injectable()
export class IngredientCategoryEffect {
  constructor(private actions: Actions,
              private currentRestaurantIngredientCategoriesService: CurrentRestaurantIngredientCategoriesService) {
  }

  @Effect({dispatch: false})
  addEffect: Observable<Action> = this.actions.pipe(
    ofType<IngredientCategoryAddSuccessfulAction>(ActionTypes.INGREDIENT_CATEGORY_ADD_SUCCESSFUL),

    tap((action: IngredientCategoryAddSuccessfulAction) => {
      this.currentRestaurantIngredientCategoriesService.forceReload();
    })
  );

  @Effect({dispatch: false})
  deleteEffect: Observable<Action> = this.actions.pipe(
    ofType<IngredientCategoryDeleteSuccessfulAction>(ActionTypes.INGREDIENT_CATEGORY_DELETE_SUCCESSFUL),

    tap((action: IngredientCategoryDeleteSuccessfulAction) => {

      this.currentRestaurantIngredientCategoriesService.forceReload();
    })
  );
}
