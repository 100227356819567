import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';
import {formatDate} from '@angular/common';
import moment from 'moment';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  public static isDateValid(value: string): boolean {
    const regexValid = value.match(/^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-(19|20)\d\d$/g) === null;

    if (!regexValid) {
      return false;
    }

    const dateItems: string[] = value.split(/-/g);

    const day = parseInt(dateItems[0], 10);
    const month = parseInt(dateItems[1], 10);
    const year = parseInt(dateItems[2], 10);

    return moment().utc().date(day).month(month).year(year).isValid();
  }

  parse(value: string): NgbDateStruct {

    if (value == null || value.length === 0 || !NgbDateCustomParserFormatter.isDateValid(value)) {
      // @ts-ignore
      return null;
    }


    const dateItems: string[] = value.split(/-/g);

    const day = parseInt(dateItems[0], 10);
    const month = parseInt(dateItems[1], 10);
    const year = parseInt(dateItems[2], 10);
    const result: NgbDateStruct = {year: year, month: month, day: day};

    return result;
  }

  format(date: NgbDateStruct): string {
    if (date == null || date.day == null || date.month == null || date.year == null) {
      return '';
    }

    const result = formatDate(new Date(date.year, date.month - 1, date.day), 'dd-MM-yyyy', 'en-US');

    return result;
  }
}
