import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginAuthGuard, AuthenticatedUserAuthGuard} from '../route.guards';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LoginPageComponent} from './login-page/login-page.component';
import {AuthenticatedUserComponent} from './authenticated-user/authenticated-user.component';
import {HoldingDashboardComponent} from './holding-dashboard/holding-dashboard.component';
import {RestaurantManagerRoutingConstants, RoutingConstants} from './routing-constants';
import {ConfirmAccountComponent} from './registration/confirm-account/confirm-account.component';
import {IngredientListComponent} from './dashboard/ingredient-list/ingredient-list.component';
import {MenuItemsListComponent} from './dashboard/menu-items-list/menu-items-list.component';
import {RestaurantDashboardContainerComponent} from './restaurant-dashboard-container/restaurant-dashboard-container.component';
import {RestaurantUsersListComponent} from './dashboard/restaurant-users-list/restaurant-users-list.component';
import {IngredientCategoriesListComponent} from './dashboard/ingredient-categories-list/ingredient-categories-list.component';
import {RequestedTableActionsListComponent} from './dashboard/requested-table-actions-list/requested-table-actions-list.component';
import {AreasListComponent} from './dashboard/areas-list/areas-list.component';
import {CompletePasswordRecoveryComponent} from './password-recovery/complete-password-recovery/complete-password-recovery.component';
import {PasswordRecoveryComponent} from './password-recovery/password-recovery.component';
import {ReviewsListComponent} from './dashboard/reviews-list/reviews-list.component';
import {TerminalsAndDisplaysListComponent} from './dashboard/terminals-and-displays-list/terminals-and-displays-list.component';
import {CovidCheckinsListComponent} from './dashboard/covid-checkins-list/covid-checkins-list.component';
import {RegistrationSimpleComponent} from './registration/registration-simple.component';
import {RegistrationComponent} from './registration/registration.component';
import {RegistrationWithMenuComponent} from './registration/registration-with-menu.component';
import {AddOrEditMenuItemComponent} from './modules/menu-items/menu-items.module';
import {TablesListComponent} from './modules/tables/tables.module';
import {AddOrEditRestaurantComponent} from './modules/restaurants/components/add-or-edit-restaurant/add-or-edit-restaurant.component';
import {MenuItemCategoriesListComponent} from './modules/menu-item-categories/menu-item-categories.module';
import {AddOrEditMenuItemCategoryComponent} from './modules/menu-item-categories/components/add-or-edit-menu-item-category/add-or-edit-menu-item-category.component';
import {LeftMenuConstants} from './left-menu.constants';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/' + RoutingConstants.LOGIN,
    pathMatch: 'full'
  },
  {
    path: RoutingConstants.LOGIN,
    component: LoginPageComponent,
    canActivate: [LoginAuthGuard]
  },
  {
    path: RoutingConstants.FORGOT_PASSWORD,
    component: PasswordRecoveryComponent,
    canActivate: [LoginAuthGuard]
  },
  {
    path: RoutingConstants.RESET_PASSWORD,
    component: CompletePasswordRecoveryComponent,
    canActivate: [LoginAuthGuard]
  },
  {
    path: RoutingConstants.REGISTRATION,
    component: RegistrationSimpleComponent,
    canActivate: [LoginAuthGuard]
  },
  {
    path: RoutingConstants.REGISTRATION_FULL,
    component: RegistrationComponent,
    canActivate: [LoginAuthGuard]
  },
  {
    path: RoutingConstants.REGISTRATION_WITH_PDF,
    component: RegistrationWithMenuComponent,
    canActivate: [LoginAuthGuard]
  },
  {
    path: RoutingConstants.ACCOUNT_CONFIRMATION_WITH_TOKEN_PARAM,
    component: ConfirmAccountComponent,
    canActivate: [LoginAuthGuard]
  },
  {
    path: RoutingConstants.ROOT,
    component: AuthenticatedUserComponent,
    canActivate: [AuthenticatedUserAuthGuard],
    children: [
      {
        path: RestaurantManagerRoutingConstants.DASHBOARD,
        component: HoldingDashboardComponent
      },

      {
        path: RestaurantManagerRoutingConstants.DASHBOARD + '/' +
              RestaurantManagerRoutingConstants.RESTAURANT + '/' +
              RestaurantManagerRoutingConstants.RESTAURANT_ADD,
        component: AddOrEditRestaurantComponent
      },
      {
        path: RestaurantManagerRoutingConstants.DASHBOARD + '/' + RestaurantManagerRoutingConstants.RESTAURANT_WITH_ID_PARAM,
        component: RestaurantDashboardContainerComponent,

        children: [
          {
            path: '',
            component: DashboardComponent,
            pathMatch: 'full'
          },

          {
            path: RestaurantManagerRoutingConstants.RESTAURANT_EDIT,
            component: AddOrEditRestaurantComponent
          },

          {
            path: RestaurantManagerRoutingConstants.AREAS_LIST,
            component: AreasListComponent
          },
          {
            path: RestaurantManagerRoutingConstants.TERMINALS_LIST,
            component: TerminalsAndDisplaysListComponent
          },
          {
            path: RestaurantManagerRoutingConstants.REVIEWS_LIST,
            component: ReviewsListComponent
          },
          {
            path: RestaurantManagerRoutingConstants.INGREDIENTS,
            component: IngredientListComponent
          },
          {
            path: RestaurantManagerRoutingConstants.INGREDIENT_CATEGORIES,
            component: IngredientCategoriesListComponent
          },
          {
            path: RestaurantManagerRoutingConstants.TABLES,
            component: TablesListComponent
          },
          {
            path: RestaurantManagerRoutingConstants.REQUESTED_TABLE_ACTIONS,
            component: RequestedTableActionsListComponent
          },
          {
            path: RestaurantManagerRoutingConstants.WAITERS,
            component: RestaurantUsersListComponent
          },
          {
            path: RestaurantManagerRoutingConstants.COVID_CHECKINS,
            component: CovidCheckinsListComponent
          },

          {
            path: RestaurantManagerRoutingConstants.MENU_ITEM_CATEGORIES_LIST,
            data: {
              leftMenuItem: LeftMenuConstants.RESTAURANT_MENU_ITEM_CATEGORIES
            },

            children: [
              {
                path: '',
                component: MenuItemCategoriesListComponent,
                pathMatch: 'full'
              },
              {
                path: RestaurantManagerRoutingConstants.MENU_ITEM_CATEGORY_CREATE,
                component: AddOrEditMenuItemCategoryComponent,
              },
              {
                path: RestaurantManagerRoutingConstants.MENU_ITEM_CATEGORY_EDIT_WITH_ID_PARAM,
                component: AddOrEditMenuItemCategoryComponent,
              }
            ]
          },

          {
            path: RestaurantManagerRoutingConstants.MENU_ITEMS_LIST,
            data: {
              leftMenuItem: LeftMenuConstants.RESTAURANT_MENU_ITEMS,
            },

            children: [
              {
                path: '',
                component: MenuItemsListComponent,
                pathMatch: 'full'
              },
              {
                path: RestaurantManagerRoutingConstants.MENU_ITEM_CREATE,
                component: AddOrEditMenuItemComponent,
              },
              {
                path: RestaurantManagerRoutingConstants.MENU_ITEM_EDIT_WITH_ID_PARAM,
                component: AddOrEditMenuItemComponent
              }
            ]
          }
        ]
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false, paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
