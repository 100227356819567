import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LocalStorageService} from '../services/local-storage.service';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';
import {RoutingConstants} from '../app/routing-constants';

@Injectable()
export class AddTokenHeaderHttpRequestInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router) {

  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Consider only adding the auth header to API requests as this will add it to all HTTP requests.
    const reqWithToken = this.addToken(request);

    return next.handle(reqWithToken)
               .pipe(
                 tap((response: HttpResponse<any>) => {
                   if (response.headers && response.headers.has('X-NEW-AUTH-TOKEN')) {
                     if (!environment.production) {
                       console.log('New JWT token was received from the server');
                     }

                     const newJwtTokenBase64 = response.headers.get('X-NEW-AUTH-TOKEN');

                     if (newJwtTokenBase64 != null && newJwtTokenBase64.length > 0) {
                       const currentUser = LocalStorageService.getCurrentUser();

                       if (currentUser != null) {
                         LocalStorageService.setCurrentUser(newJwtTokenBase64, currentUser.user);

                         if (!environment.production) {
                           console.log('JWT token was updated');
                         }
                       }
                     }
                   }
                 }, (err) => {
                   if (err.status === 401) {

                     return from(this.router.navigateByUrl('/' + RoutingConstants.LOGIN));
                   }
                 })
               );
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    const authUser = LocalStorageService.getCurrentUser();

    if (authUser != null) {
      request = request.clone({
                                headers: request.headers.set('Authorization', `Bearer ${authUser.jwtTokenBase64}`),
                                withCredentials: true
                              });
    }

    if (!environment.production) {
      console.log(`HTTP ${request.method}: ${request.url}`);
    }

    return request;
  }
}
