import {Injectable, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ReplaySubject, Subject} from 'rxjs';
import {BooleanValueItem} from '../../add-or-edit-menu-item-modifier-group/helper.types';
import {map, takeUntil} from 'rxjs/operators';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({
              providedIn: 'root'
            })
export class CommonTranslationsUtil implements OnDestroy {

  private readonly destroy$: Subject<any> = new Subject<any>();

  public readonly visibilityItems$: ReplaySubject<BooleanValueItem[]> = new ReplaySubject<BooleanValueItem[]>(1);

  public readonly activeItems$: ReplaySubject<BooleanValueItem[]> = new ReplaySubject<BooleanValueItem[]>(1);


  constructor(private readonly trnService: TranslateService) {
    const visibleText = _('Visible');
    const invisibleText = _('Hidden');
    const activeText = _('Active');
    const deactivatedText = _('Not active');


    this.trnService
        .stream([visibleText, invisibleText])
        .pipe(
          takeUntil(this.destroy$),
          map((x: []) => {
            const visibleTrn = x[visibleText];
            const invisibleTrn = x[invisibleText];

            return [new BooleanValueItem(true, visibleTrn), new BooleanValueItem(false, invisibleTrn)];
          })
        )
        .subscribe(this.visibilityItems$);


    this.trnService
        .stream([activeText, deactivatedText])
        .pipe(
          takeUntil(this.destroy$),
          map((x: []) => {
            const activeTrn = x[activeText];
            const deactivatedTrn = x[deactivatedText];

            return [new BooleanValueItem(true, activeTrn), new BooleanValueItem(false, deactivatedTrn)];
          })
        )
        .subscribe(this.activeItems$);
  }

  ngOnDestroy(): void {
    this.destroy$.complete();

    this.activeItems$.complete();
    this.activeItems$.complete();
  }
}
