import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {MenuItemModifierGroupRow} from '../menu-items-list.component';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import MenuItemModifierGroupWithModifiers = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupWithModifiers;
import {TranslateService} from '@ngx-translate/core';
import {RestaurantComponent} from '../../../restaurant.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {ActivatedRoute} from '@angular/router';
import {catchError, first, tap} from 'rxjs/operators';
import {AddOrEditMenuItemModifierGroupComponent} from '../../../add-or-edit-menu-item-modifier-group/add-or-edit-menu-item-modifier-group.component';
import MenuItemModifierGroupBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupBasic;
import AddOrUpdateMenuItemModifierGroupResponse =
  Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.AddOrUpdateMenuItemModifierGroupResponse;
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NeverError, ToastService} from 'orderly-web-components';
import {genericErrorHandlerWithToast} from '../../../../util/utils';
import {AddOrEditMenuItemModifierComponent} from '../../../add-or-edit-menu-item-modifier/add-or-edit-menu-item-modifier.component';
import DeleteMenuItemModifierResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.DeleteMenuItemModifierResponse;
import DeleteMenuItemModifierResponseStatusDef = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.DeleteMenuItemModifierResponse.StatusDef;
import {CurrentRestaurantMenuItemsService} from '../../../../services/active-route-bound/current-restaurant-menu-items.service';
import MenuItemModifier = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData.MenuItemModifier;
import AddOrUpdateMenuItemModifierResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemModifierResponse;
import {DeleteModalUtils} from '../../../../util/delete-modal.utils';
import {Observable} from 'rxjs';
import RestaurantIngredientBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
import {CurrentRestaurantIngredientsService} from '../../../../services/active-route-bound/current-restaurant-ingredients.service';

@Component({
             selector: 'app-modifier-group-row',
             templateUrl: './modifier-group-row.component.html',
             styleUrls: ['./modifier-group-row.component.scss'],
             encapsulation: ViewEncapsulation.None,
             changeDetection: ChangeDetectionStrategy.OnPush,
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: ModifierGroupRowComponent,
                 multi: true
               }
             ]
           })
export class ModifierGroupRowComponent extends RestaurantComponent implements ControlValueAccessor {

  public groupRow: MenuItemModifierGroupRow;
  public ingredients$: Observable<RestaurantIngredientBasic[]>;

  @Output()
  public readonly deleteGroup = new EventEmitter<MenuItemModifierGroupRow>();


  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private readonly restaurantMenuService: CurrentRestaurantMenuItemsService,
              private readonly ingredientsService: CurrentRestaurantIngredientsService,
              private readonly trnService: TranslateService,
              private readonly toastService: ToastService,
              private readonly modalService: NgbModal,
              private readonly deleteModalUtils: DeleteModalUtils,
              private readonly ref: ChangeDetectorRef) {

    super(store, activatedRoute);

    this.ingredients$ = this.ingredientsService.currentLoadedRestaurantIngredients$;
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };


  get value(): MenuItemModifierGroupRow {
    return this.groupRow;
  };

  set value(v: MenuItemModifierGroupRow) {
    if (v !== this.groupRow) {
      this.groupRow = v;
      this.onChange(v);
    }
  }

  writeValue(value: MenuItemModifierGroupRow) {

    // additional null check is required because of
    // https://github.com/angular/angular/issues/14988

    if (value != null) {
      if (value !== this.groupRow) {
        this.groupRow = value;

        this.ref.detectChanges();
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public getSelectableModifiersCountText(x: MenuItemModifierGroupWithModifiers): string {
    if (x.minAllowedAmount === 0 && x.maxAllowedAmount === 1) {
      return this.trnService.instant('Only one');
    }

    let result: string = this.trnService.instant('Min.') + ' ' + x.minAllowedAmount;

    if (x.maxAllowedAmount != null) {
      result += '; ' + this.trnService.instant('Max.') + ' ' + x.maxAllowedAmount;
    }

    return result;
  }


  public editMenuItemModifierGroup() {
    this.restaurantIdParam$
        .pipe(
          first(),
          tap(restaurantId => {
            const modalRef = this.modalService.open(AddOrEditMenuItemModifierGroupComponent,
                                                    {centered: true, size: 'lg'});

            const modalInstance: AddOrEditMenuItemModifierGroupComponent = modalRef.componentInstance;

            modalInstance.menuItemId = this.groupRow.parent.id;
            modalInstance.restaurantId = restaurantId;
            modalInstance.modifierGroupToEdit = {...this.groupRow.data};

            const completedSubscription = modalInstance.completed
                                                       .subscribe(($e: AddOrUpdateMenuItemModifierGroupResponse) => {
                                                         if ($e.status ===
                                                             AddOrUpdateMenuItemModifierGroupResponse.StatusDef.Success) {

                                                           this.handleModifierGroupAddOrUpdateResponse($e.group);
                                                         }
                                                       });

            modalRef.result.finally(() => {
              completedSubscription.unsubscribe();
            });
          })
        )
        .subscribe();
  }

  private handleModifierGroupAddOrUpdateResponse(savedGroup: MenuItemModifierGroupBasic) {

    this.groupRow.data.nameIsVisibleToUser = savedGroup.nameIsVisibleToUser;
    this.groupRow.data.isActive = savedGroup.isActive;
    this.groupRow.data.maxAllowedAmount = savedGroup.maxAllowedAmount;
    this.groupRow.data.minAllowedAmount = savedGroup.minAllowedAmount;
    this.groupRow.data.name = savedGroup.name;

    this.ref.markForCheck();
  }


  public addOrEditMenuItemModifier(modifier?: MenuItemModifier) {
    const modalRef = this.modalService.open(AddOrEditMenuItemModifierComponent, {centered: true, size: 'lg'});

    const modalInstance: AddOrEditMenuItemModifierComponent = modalRef.componentInstance;

    modalInstance.ingredients$ = this.ingredients$;

    modalInstance.modifierToEdit = {
      modifier: modifier,
      modifierGroupId: this.groupRow.data.id,
      menuItemId: this.groupRow.parent.id,
    };

    const completedSubscription = modalInstance.completed.subscribe(($e: AddOrUpdateMenuItemModifierResponse) => {
      if ($e.status === AddOrUpdateMenuItemModifierResponse.StatusDef.Success) {

        if (modifier != null) {
          const updatedModifierIndex = this.groupRow.data.menuItemModifiers.findIndex(x => x.id === $e.modifier.id);

          this.groupRow.data.menuItemModifiers[updatedModifierIndex] = $e.modifier;
        } else {
          this.groupRow.data.menuItemModifiers.push($e.modifier);
        }

        this.ref.markForCheck();
      }
    });

    modalRef.result.finally(() => {
      completedSubscription.unsubscribe();
    });
  }

  public deleteMenuItemModifier(modifier: MenuItemModifier) {
    const onDelete = (modalRef: NgbModalRef) => {
      const unexpectedFailureText = this.trnService.instant(
        'Failed to remove a modifier  because of unexpected failure. Please try again later.');

      this.restaurantMenuService
          .deleteMenuItemModifier(this.groupRow.parent.id, this.groupRow.data.id, modifier.id)
          .pipe(
            tap((response: DeleteMenuItemModifierResponse) => {
              switch (response.status) {
                case DeleteMenuItemModifierResponseStatusDef.Success:
                  modalRef.close(true);

                  this.groupRow.data.menuItemModifiers = this.groupRow.data.menuItemModifiers.filter(
                    value => value.id !== modifier.id);

                  this.ref.markForCheck();

                  const successText = this.trnService.instant('Modifier was successfully deleted.');

                  this.toastService.showSuccess(successText);
                  break;
                case DeleteMenuItemModifierResponseStatusDef.UnknownFailure:
                  this.toastService.showError(unexpectedFailureText);
                  break;
                default:
                  throw new NeverError(response.status);
              }
            }),
            first(),
            catchError(
              genericErrorHandlerWithToast<DeleteMenuItemModifierResponse>(this.toastService, this.trnService,
                                                                           unexpectedFailureText)
            )
          )
          .subscribe();
    };

    const modifierName = modifier.ingredient == null ? modifier.name : modifier.ingredient.name;
    const dialogText = [
      this.trnService.instant(
        'Are you sure you want to remove a modifier "{{modifierName}}" in group "{{modifierGroupName}}"?',
        {
          modifierName: modifierName,
          modifierGroupName: this.groupRow.data.name
        })
    ];

    this.deleteModalUtils.showDeleteModal(modifier,
                                          this.trnService.instant('Delete a modifier'),
                                          dialogText,
                                          onDelete);
  }
}
