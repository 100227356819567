import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {BooleanValueItem} from '../../../../add-or-edit-menu-item-modifier-group/helper.types';
import {CommonTranslationsUtil} from '../../../translations/common-translations.util';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
             selector: 'app-shared-visibility-select',
             templateUrl: './visibility-select.component.html',
             styleUrls: ['./visibility-select.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: VisibilitySelectComponent,
                 multi: true,
               },
             ],
           })
export class VisibilitySelectComponent {

  public visibilityItems$: Observable<BooleanValueItem[]> = this.trnUtils.visibilityItems$;

  public lastValue: boolean | null = null;

  public isDisabled: boolean = false;


  constructor(private readonly trnUtils: CommonTranslationsUtil) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: boolean) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: boolean | null): void {
    this.lastValue = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public changed(newValue: boolean): void {
    this.lastValue = newValue;

    this.onTouched();
    this.onChange(newValue);
  }
}
