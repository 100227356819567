import {Action} from '@ngrx/store';
import {ActionTypes} from './action-types';
import AddRestaurantUserResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddRestaurantUserResponse;
import DeleteUserResponse = Orderly.RestaurantWeb.Api.Messages.Waiter.DeleteUserResponse;

export class WaiterInvitationSuccessfulAction implements Action {
  readonly type = ActionTypes.WAITER_INVITE_SUCCESSFUL;

  constructor(public data: AddRestaurantUserResponse) {}
}

export class WaiterDeletionSuccessfulAction implements Action {
  readonly type = ActionTypes.WAITER_DELETE_SUCCESSFUL;

  constructor(public data: DeleteUserResponse) {
  }
}

export type WaiterActions = WaiterInvitationSuccessfulAction | WaiterDeletionSuccessfulAction;
