import {BehaviorSubject, EMPTY, Observable, of, Subject} from 'rxjs';
import {selectRestaurantIdParamFromAppState} from '../../app/store/selectors/restaurant-selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {catchError, distinctUntilChanged, filter, first, map, skipWhile, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {
  RestaurantSpecificArrayResultLoadingState,
  RestaurantSpecificResultLoadingStatusDefinition
} from './helper.statuses';

export const createNotStartedResult = <T>(): RestaurantSpecificArrayResultLoadingState<T> => {
  return new RestaurantSpecificArrayResultLoadingState<T>(RestaurantSpecificResultLoadingStatusDefinition.NOT_STARTED,
                                                          []);
};

export const createSuccessResult = <T>(data: T[]): RestaurantSpecificArrayResultLoadingState<T> => {
  return new RestaurantSpecificArrayResultLoadingState<T>(RestaurantSpecificResultLoadingStatusDefinition.SUCCESS,
                                                          data);
};

export const createFailedResult = <T>(error: any): RestaurantSpecificArrayResultLoadingState<T> => {
  return new RestaurantSpecificArrayResultLoadingState<T>(RestaurantSpecificResultLoadingStatusDefinition.FAILED,
                                                          [],
                                                          error);
};

export abstract class CurrentRestaurantServiceBase<T> {

  static loadingResult = new RestaurantSpecificArrayResultLoadingState(
    RestaurantSpecificResultLoadingStatusDefinition.LOADING, []);
  static clearedResult = new RestaurantSpecificArrayResultLoadingState(
    RestaurantSpecificResultLoadingStatusDefinition.CLEARED_BECAUSE_NO_RESTAURANT_ID, []);

  private readonly cache$: BehaviorSubject<RestaurantSpecificArrayResultLoadingState<T>>;
  protected readonly restaurantIdRouteParam$: Observable<number | null> = this.store.pipe(
    selectRestaurantIdParamFromAppState);


  protected get dataFeed$(): BehaviorSubject<RestaurantSpecificArrayResultLoadingState<T>> {

    // NOT_STARTED: if first subscriber -> try to load data
    // FAILED: if previous load failed -> try reload again
    if (this.cache$.value.isNotStarted() || this.cache$.value.isFailed()) {

      // this will be triggered either once on the beginning (for very first subscriber),
      // or for a new subscription in case if previous could not load data
      this.forceReload();
    }

    return this.cache$;
  }

  protected constructor(private store: Store<AppState>) {
    this.cache$ = new BehaviorSubject<RestaurantSpecificArrayResultLoadingState<T>>(createNotStartedResult<T>());

    this.restaurantIdRouteParam$
        .pipe(
          skipWhile(restaurantId => this.cache$.value.isNotStarted()),
          distinctUntilChanged((x, y) => x === y),

          tap(CurrentRestaurantServiceBase.logParameterChangeEvent),

          tap(restaurantId => this.pushLoadingOrClearedState(restaurantId)),

          filter(restaurantId => restaurantId != null),

          switchMap((restaurantId: number) => this.loadDataAndConvertToLoadState(restaurantId))
        )
        .subscribe(this.cache$);
  }


  private static logParameterChangeEvent(restaurantId: number | null) {
    if (!environment.production) {
      console.log(`router parameter change -> rid: ${restaurantId}`);
    }
  }

  protected abstract doLoadData(restaurantId: number): Observable<T[]>;

  public forceReload(): Observable<any> {
    if (this.cache$.value.isLoading()) {
      return EMPTY;
    }

    const result = new Subject<any>();

    this.restaurantIdRouteParam$.pipe(
      // NOTE: first is important! it will cancel this subscription after result is loaded
      first(),
      tap(CurrentRestaurantServiceBase.logParameterChangeEvent),

      tap(restaurantId => this.pushLoadingOrClearedState(restaurantId)),

      filter(restaurantId => restaurantId != null),

      switchMap((restaurantId: number) => this.loadDataAndConvertToLoadState(restaurantId)),

      tap(newState => this.cache$.next(newState))

    ).subscribe(result);

    return result;
  }

  private loadDataAndConvertToLoadState(restaurantId: number): Observable<RestaurantSpecificArrayResultLoadingState<T>> {
    return this.doLoadData(restaurantId)
               .pipe(
                 map(data => createSuccessResult(data)),
                 catchError((err) => of(createFailedResult<T>(err)))
               );
  }

  public setValue(value: T[]) {
    this.cache$.next(createSuccessResult(value));
  }

  private pushLoadingOrClearedState(restaurantId: number | null) {
    if (restaurantId == null) {
      this.cache$.next(CurrentRestaurantServiceBase.clearedResult);
    } else {
      this.cache$.next(CurrentRestaurantServiceBase.loadingResult);
    }
  }
}
