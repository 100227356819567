import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {catchError, first, map, tap} from 'rxjs/operators';
import {NeverError, ToastService} from 'orderly-web-components';
import { saveAs } from 'file-saver';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import {CurrentRestaurantTablesService} from '../../../../../services/active-route-bound/current-restaurant-tables.service';
import {formatTableFileNameWithoutExtension, genericErrorHandlerWithToast} from '../../../../../util/utils';
import ExistingTable = Orderly.RestaurantWeb.Api.Messages.ExistingTable;
import OpStatus = Orderly.RestaurantWeb.Api.Messages.Table.GetQrCodeResponse.OpStatus;

@Component({
             selector: 'app-table-qr-code-modal',
             templateUrl: './table-qr-code-modal.component.html',
             styleUrls: ['./table-qr-code-modal.component.scss']
           })
export class TableQrCodeModalComponent implements OnInit {

  @Input()
  public table: ExistingTable;

  public base64QrImageContent: Observable<string>;

  public base64QrImageContentLoading: boolean = true;
  public downloadRunning: boolean = false;

  public translationsCfg: {
    modalTitle: string;
    downloadAsPdf: string;
    downloadAsQrCodeImage: string;
    downloadAsStickerImage: string;
    unexpectedServerFailureMsg: string;
  };

  constructor(public readonly modalService: NgbActiveModal,
              public readonly domSanitizationService: DomSanitizer,
              private readonly trnService: TranslateService,
              private readonly toastService: ToastService,
              private readonly tableService: CurrentRestaurantTablesService) {
  }

  ngOnInit() {
    const downloadAsPdf: string = this.trnService.instant('Download as PDF');
    const downloadAsImageText: string = this.trnService.instant('Download as image');
    const qrCodeOnlyText: string = this.trnService.instant('only QR-code');
    const asStickerText: string = this.trnService.instant('as sticker');

    this.translationsCfg = {
      downloadAsQrCodeImage: `${downloadAsImageText} (${qrCodeOnlyText})`,
      downloadAsStickerImage: `${downloadAsImageText} (${asStickerText})`,
      downloadAsPdf: `${downloadAsPdf} (${asStickerText})`,
      unexpectedServerFailureMsg: this.trnService.instant('There was an error while downloading QR code. Please try again later.'),
      modalTitle: this.trnService.instant('QR code for table \'{{name}}\'', {name: this.table.name})
    };

    this.base64QrImageContent = this.tableService
                                    .getTableQrCodeAsBase64Image(this.table.id)
                                    .pipe(
                                      tap(x => {
                                        switch (x.status) {
                                          case OpStatus.Exception:
                                            this.modalService.dismiss(x);
                                            this.toastService.showError(this.translationsCfg.unexpectedServerFailureMsg);

                                            throw new Error('Failed to download QR code. TableId: ' + this.table.id);
                                          case OpStatus.Success:
                                            // do nothing
                                            break;
                                          default:
                                            throw new NeverError(x.status);
                                        }
                                      }),
                                      map(x => {
                                        switch (x.status) {
                                          case OpStatus.Exception:
                                            throw new Error('Unexpected error status. TableId: ' + this.table.id);
                                          case OpStatus.Success:
                                            return x.contentBase64;
                                          default:
                                            throw new NeverError(x.status);
                                        }
                                      }),
                                      catchError(
                                        genericErrorHandlerWithToast(this.toastService, this.trnService, this.translationsCfg.unexpectedServerFailureMsg)
                                      ),
                                      tap(() => this.base64QrImageContentLoading = false)
                                    );
  }


  public downloadAsPdf() {
    this.tableService
        .downloadTableQrCodeAsPdf(this.table.id)
        .pipe(
          first(),
          tap((res: Blob) => {
            const fileName = formatTableFileNameWithoutExtension(this.table) + '.pdf';

            saveAs(res, fileName);
          })
        )
        .subscribe();
  }

  public downloadAsImage() {
    this.tableService
        .downloadTableQrCodeAsImage(this.table.id)
        .pipe(
          first(),
          tap((res: Blob) => {
            const fileName = formatTableFileNameWithoutExtension(this.table) + '.jpeg';

            saveAs(res, fileName);
          })
        )
        .subscribe();
  }

  public downloadAsSticker() {
    this.tableService
        .downloadTableQrCodesAsStickersImage([this.table.id])
        .pipe(
          first(),
          tap((res: Blob) => {
            const fileName = formatTableFileNameWithoutExtension(this.table) + '_sticker' + '.jpeg';

            saveAs(res, fileName);
          })
        )
        .subscribe();
  }
}
