import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomMissingTranslationHandler} from './custom-missing-translation.handler';
import {TranslationHttpLoaderFactory} from './translation-http-loader.factory';
import {CommonTranslationsUtil} from './common-translations.util';
import { TranslationCommonValidators } from './validators/translation-common-validators';

@NgModule({
            declarations: [],
            imports: [
              CommonModule
            ],
            exports: []
          })
export class TranslationsModule {}


export {
  CustomMissingTranslationHandler,
  TranslationHttpLoaderFactory,
  CommonTranslationsUtil,
  TranslationCommonValidators
};
