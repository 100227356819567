<div class="form-layout" style="width: 100%;">
  <div class="row">
    <div class="col-xxl-5 col-lg-6 col-xs-12">
      <div class="form-group">
        <label class="form-control-label">{{'Name' | translate}}: <span class="tx-danger">*</span></label>

        <app-shared-text-input-with-language-flag [formControl]="formDef.getControl('name')">
        </app-shared-text-input-with-language-flag>

        <owc-input-validation-errors [control]="formDef.getControl('name')"></owc-input-validation-errors>
      </div>
    </div>

    <div class="col-xxl-5 col-lg-6 col-xs-12">
      <div class="form-group">
        <label class="form-control-label">{{'Category' | translate}}: <span class="tx-danger">*</span></label>

        <ng-select [items]="categories$ | async"
                   [searchable]="false"
                   [formControl]="formDef.getControl('category')"
                   [multiple]="false"
                   [clearable]="false"
                   [virtualScroll]="true"
                   bindLabel="name">
        </ng-select>

        <owc-input-validation-errors [control]="formDef.getControl('category')"></owc-input-validation-errors>
      </div>
    </div>

    <div class="col-xxl-2 col-xl-3 col-lg-12 col-xs-12">
      <div class="form-group">
        <label class="form-control-label">{{'Is visible in menu' | translate}}: <span class="tx-danger">*</span></label>

        <app-shared-yes-no-switch [formControl]="formDef.getControl('isActive')"
                                  class="input-group">
        </app-shared-yes-no-switch>

        <owc-input-validation-errors [control]="formDef.getControl('isActive')"></owc-input-validation-errors>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="form-control-label">{{'Description' | translate}}:</label>

        <app-shared-text-input-with-language-flag [formControl]="formDef.getControl('description')"
                                                  [maxlength]="300"
                                                  [mode]="'textarea'">
        </app-shared-text-input-with-language-flag>

        <owc-input-validation-errors [control]="formDef.getControl('description')"></owc-input-validation-errors>
      </div>
    </div>
  </div>


  <app-menu-item-price-editor [formControl]="formDef.getControl('price')">
  </app-menu-item-price-editor>

  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="form-control-label">{{'Photos' | translate}}:</label>

        <app-images-list [formControl]="formDef.getControl('images')"></app-images-list>
      </div>
    </div>
  </div>

</div>
