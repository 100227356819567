import {Component} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ReplaySubject} from 'rxjs';
import {ButtonsGroupSwitchItem} from 'orderly-web-components';
import {TranslateService} from '@ngx-translate/core';
import {map, tap} from 'rxjs/operators';
import {assignTranslatedProperties, getArrayOfTranslationKeys} from '../../../../../../../../util/trn.utils';
import {ItemsTranslationsDefinition} from './items-translations.definition';
import {BaseOrderlyComponent} from '../../../../../../shared/components/base-orderly.component';

@Component({
             selector: 'app-modifier-group-editor-modifiers-count-switch',
             templateUrl: './modifiers-count-switch.component.html',
             styleUrls: ['./modifiers-count-switch.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: ModifiersCountSwitchComponent,
                 multi: true,
               },
             ],
           })
export class ModifiersCountSwitchComponent extends BaseOrderlyComponent implements ControlValueAccessor {

  public items$: ReplaySubject<ButtonsGroupSwitchItem[]> = new ReplaySubject<ButtonsGroupSwitchItem[]>(1);

  public isDisabled: boolean = false;

  public lastValue: boolean | null = null;


  constructor(private readonly trnService: TranslateService) {
    super();

    const initialTranslations: ItemsTranslationsDefinition = {
      onlyOne: 'Only one',
      moreThanOne: 'More than one',
    };

    const translationKeys = getArrayOfTranslationKeys(initialTranslations);


    this.registerForAutoDestroy(
      this.trnService
          .stream(translationKeys)
          .pipe(
            map(translations => {
              const result: ItemsTranslationsDefinition = assignTranslatedProperties(initialTranslations, translations);

              return result;
            }),
            tap(trns => {
              const trueFalseList = this.createListItems(trns);

              this.items$.next(trueFalseList);
            }),
          )
          .subscribe()
    );
  }

  private createListItems(trns: Readonly<ItemsTranslationsDefinition>): ButtonsGroupSwitchItem[] {
    const trueFalseList: ButtonsGroupSwitchItem[] = [];

    trueFalseList.push(new ButtonsGroupSwitchItem(true, trns.onlyOne));
    trueFalseList.push(new ButtonsGroupSwitchItem(false, trns.moreThanOne));

    return trueFalseList;
  }


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: boolean | null) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: boolean | null): void {

    if (this.lastValue !== value) {
      this.lastValue = value;
    }

  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public changed(newValue: boolean | null) {
    this.lastValue = newValue;

    this.onTouched();
    this.onChange(newValue);
  }

}
