<app-top-bar-with-language-switcher></app-top-bar-with-language-switcher>

<div class="justify-content-center bg-br-primary ht-100v register-form-container" style="padding: 25px 0; margin: auto;" *ngIf="translations$ | async as trns">
  <div class="pd-25 pd-xs-40 bg-white rounded shadow-base">

    <div class="tx-center tx-28 tx-inverse pd-md-b-20">{{'Register your business now!' | translate}}</div>

    <form [formGroup]="this.formDef.form" (ngSubmit)="registerRestaurantHolding()" class="form-layout form-layout-1" autocomplete="off">

      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-control-label" for="isChain">{{'Do you own multiple restaurants?' | translate}}: <span class="tx-danger">*</span></label>

            <ng-select [items]="isChainSelectValues$ | async"
                       [formControl]="formDef.getControl('isChain')"
                       labelForId="isChain"
                       bindLabel="name"
                       bindValue="value">
            </ng-select>

            <owc-input-validation-errors [control]="formDef.getControl('isChain')"></owc-input-validation-errors>
          </div>

          <div class="form-group">
            <label class="form-control-label">{{formDef.getControl('isChain').value === true ? trns.holdingNameLabel : trns.restaurantNameLabel}}: <span class="tx-danger">*</span></label>
            <input type="text"
                   class="form-control"
                   maxlength="50"
                   [formControl]="formDef.getControl('name')"
                   placeholder="{{formDef.getControl('isChain').value === true ? trns.holdingNameInputPlaceholder : trns.restaurantInputPlaceholder}}" />

            <owc-input-validation-errors [control]="formDef.getControl('name')"></owc-input-validation-errors>
          </div>

          <div class="form-group">
            <label class="form-control-label" for="cityId">{{formDef.getControl('isChain').value === true ? trns.holdingAddressLabel : trns.restaurantAddressLabel}}: <span class="tx-danger">*</span></label>

            <div class="row">
              <div class="col-12 col-lg-6">
                <ng-select [items]="cities$ | async"
                           [formControl]="formDef.getControl('cityId')"
                           labelForId="cityId"
                           bindLabel="name"
                           bindValue="id"
                           [placeholder]="'Select city' | translate">
                </ng-select>

                <owc-input-validation-errors [control]="formDef.getControl('cityId')"></owc-input-validation-errors>
              </div>

              <div class="col-12 col-lg-6">
                <input type="text"
                       class="form-control"
                       maxlength="8"
                       autocomplete="none"
                       [formControl]="formDef.getControl('zipCode')"
                       [placeholder]="'Zip Code' | translate" />

                <owc-input-validation-errors [control]="formDef.getControl('zipCode')"></owc-input-validation-errors>
              </div>
            </div>

            <div class="row pd-t-5">
              <div class="col-12">
                <input type="text"
                       class="form-control"
                       maxlength="50"
                       autocomplete="none"
                       [formControl]="formDef.getControl('address')"
                       [placeholder]="'Street, block, house number, floor' | translate" />

                <owc-input-validation-errors [control]="formDef.getControl('address')"></owc-input-validation-errors>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-control-label">{{formDef.getControl('isChain').value === true ? trns.holdingPhoneLabel : trns.restaurantPhoneLabel}}: <span class="tx-danger">*</span></label>

            <input type="text"
                   class="form-control"
                   [maxLength]="30"
                   [formControl]="formDef.getControl('contactPhone')"
                   [placeholder]="'Enter contact phone' | translate" />

            <owc-input-validation-errors [control]="formDef.getControl('contactPhone')"></owc-input-validation-errors>
          </div>
        </div>

        <div class="hidden-xs visible-lg col-lg-1">
          <div style="border-left: 1px solid rgb(200,200,200); height: 100%; margin: auto; width:1%;"></div>
        </div>

        <div class="col-12 col-lg-5">
          <div class="form-group">
            <label class="form-control-label">{{'Language' | translate}}: <span class="tx-danger">*</span></label>

            <div class="row">
              <div class="col-12">
                <app-language2-select [languages$]="languages$"
                                      [formControl]="formDef.getControl('languageCode2')">

                </app-language2-select>

                <owc-input-validation-errors [control]="formDef.getControl('languageCode2')"></owc-input-validation-errors>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-control-label">{{'Account manager name' | translate}}: <span class="tx-danger">*</span></label>

            <div class="row">
              <div class="col-12">
                <input type="text"
                       class="form-control"
                       maxlength="30"
                       [formControl]="formDef.getControl('firstName')"
                       [placeholder]="'First name' | translate" />

                <owc-input-validation-errors [control]="formDef.getControl('firstName')"></owc-input-validation-errors>
              </div>
            </div>

            <div class="row pd-t-5">
              <div class="col-12">
                <input type="text"
                       class="form-control"
                       maxlength="30"
                       [formControl]="formDef.getControl('lastName')"
                       [placeholder]="'Last name' | translate" />

                <owc-input-validation-errors [control]="formDef.getControl('lastName')"></owc-input-validation-errors>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-control-label">{{'Account manager email' | translate}}: <span class="tx-danger">*</span></label>

            <input type="text"
                   class="form-control"
                   maxlength="120"
                   [formControl]="formDef.getControl('email')"
                   [placeholder]="'Enter account manager email' | translate" />

            <owc-input-validation-errors [control]="formDef.getControl('email')"></owc-input-validation-errors>
          </div>

          <div class="form-group">
            <label class="form-control-label">{{'Password' | translate}}: <span class="tx-danger">*</span></label>

            <input type="password"
                   class="form-control"
                   [formControl]="formDef.getControl('password')"
                   [placeholder]="'Enter password' | translate" />

            <owc-input-validation-errors [control]="formDef.getControl('password')"></owc-input-validation-errors>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 align-content-lg-end">
          <div class="form-group form-check">
            <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" />
            <label for="acceptTerms" class="form-check-label">{{trns.acceptTermsAndConditions}}</label>

            <owc-input-validation-errors [control]="formDef.getControl('acceptTerms')"></owc-input-validation-errors>
          </div>

          <div class="float-left">
            <owc-progress-action-button [actionInProgress]="registrationInProgress"
                                        [btnClass]="'btn-danger'"
                                        [btnText]="'Register' | translate"
                                        [type]="'submit'"
                                        [disabled]="formDef.form.invalid"
                                        (execute)="registerRestaurantHolding()">
            </owc-progress-action-button>
          </div>

          <a class="btn btn-link float-right" routerLink="/login">{{'Navigate to login' | translate}}</a>
        </div>
      </div>

    </form>
  </div>
</div>
