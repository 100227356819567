import { ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { RestaurantComponent } from '../../../restaurant.component';
import { NeverError } from 'orderly-web-components';
import { MenuItemModifierGroupRow } from '../menu-items-list.component';
import { catchError, first, tap } from 'rxjs/operators';
import { AddOrEditMenuItemModifierGroupComponent } from '../../../add-or-edit-menu-item-modifier-group/add-or-edit-menu-item-modifier-group.component';
import { genericErrorHandlerWithToast } from '../../../../util/utils';
export class ModifierGroupsTableComponent extends RestaurantComponent {
    constructor(store, activatedRoute, restaurantMenuService, trnService, toastService, modalService, deleteModalUtils, ref) {
        super(store, activatedRoute);
        this.restaurantMenuService = restaurantMenuService;
        this.trnService = trnService;
        this.toastService = toastService;
        this.modalService = modalService;
        this.deleteModalUtils = deleteModalUtils;
        this.ref = ref;
        this.modifierGroupRows = [];
        this.onChange = () => {
        };
        this.onTouched = () => {
        };
    }
    get value() {
        return this.menuItem;
    }
    ;
    set value(v) {
        if (v !== this.menuItem) {
            this.menuItem = v;
            this.modifierGroupRows = this.convertModifierGroups(v);
            this.onChange(v);
        }
    }
    writeValue(value) {
        // additional null check is required because of
        // https://github.com/angular/angular/issues/14988
        if (value != null) {
            if (value !== this.menuItem) {
                this.menuItem = value;
                this.modifierGroupRows = this.convertModifierGroups(value);
                this.ref.markForCheck();
            }
        }
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    addMenuItemModifierGroup() {
        this.restaurantIdParam$
            .pipe(first(), tap(restaurantId => {
            const modalRef = this.modalService.open(AddOrEditMenuItemModifierGroupComponent, { centered: true, size: 'lg' });
            const modalInstance = modalRef.componentInstance;
            modalInstance.menuItemId = this.menuItem.id;
            modalInstance.restaurantId = restaurantId;
            const completedSubscription = modalInstance.completed
                .subscribe(($e) => {
                if ($e.status ===
                    1 /* Success */) {
                    this.handleModifierGroupAddOrUpdateResponse($e.group);
                }
            });
            modalRef.result.finally(() => {
                completedSubscription.unsubscribe();
            });
        }))
            .subscribe();
    }
    handleModifierGroupAddOrUpdateResponse(savedGroup) {
        const groupTmp = {
            nameIsVisibleToUser: savedGroup.nameIsVisibleToUser,
            isActive: savedGroup.isActive,
            id: savedGroup.id,
            maxAllowedAmount: savedGroup.maxAllowedAmount,
            minAllowedAmount: savedGroup.minAllowedAmount,
            name: savedGroup.name,
            menuItemModifiers: [],
            translations: [],
        };
        this.modifierGroupRows.push(new MenuItemModifierGroupRow(groupTmp, this.menuItem));
        this.ref.markForCheck();
    }
    convertModifierGroups(menuItem) {
        return menuItem.modifierGroups.map(x => new MenuItemModifierGroupRow(x, menuItem));
    }
    deleteMenuItemModifierGroup(group) {
        const onDelete = (modalRef) => {
            const unexpectedFailureText = this.trnService.instant('Failed to remove a modifier group because of unexpected failure. Please try again later.');
            this.restaurantMenuService
                .deleteMenuItemModifierGroup(group.parent.id, group.data.id)
                .pipe(tap((response) => {
                switch (response.status) {
                    case 1 /* Success */:
                        modalRef.close(true);
                        this.modifierGroupRows = this.modifierGroupRows.filter(value => value.data.id !== group.data.id);
                        this.ref.markForCheck();
                        break;
                    case 2 /* UnknownFailure */:
                        this.toastService.showError(unexpectedFailureText);
                        break;
                    default:
                        throw new NeverError(response.status);
                }
            }), first(), catchError(genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedFailureText)))
                .subscribe();
        };
        const confirmationMsgCommon = this.trnService.instant('Are you sure you want to remove a modifier group "{{name}}"?', { name: group.data.name });
        const confirmationMsg = [confirmationMsgCommon];
        this.deleteModalUtils.showDeleteModal(group.data, this.trnService.instant('Delete a modifier group'), confirmationMsg, onDelete);
    }
}
