import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../util/form.utils';
import {ExistingIngredientCategoryData, NewIngredientCategoryData} from './event-data';
import {genericErrorHandlerWithToast, nameof} from '../../util/utils';
import {FormBuilder} from '@angular/forms';
import {Observable, ReplaySubject} from 'rxjs';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {catchError, finalize, first, map, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {assignTranslatedProperties, getArrayOfTranslationKeys, trnTryAgainLaterText} from '../../util/trn.utils';
import {isNotNullOrEmptyOrWhiteSpaceValidator, NeverError, ToastService} from 'orderly-web-components';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';
import {IngredientCategoryAddSuccessfulAction} from '../store/actions/ingredient-category.actions';
import {CurrentRestaurantIngredientCategoriesService} from '../../services/active-route-bound/current-restaurant-ingredient-categories.service';
import AddIngredientCategoryRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddIngredientCategoryRequest;
import UpdateIngredientCategoryRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.UpdateIngredientCategoryRequest;
import AddOrUpdateIngredientCategoryResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddOrUpdateIngredientCategoryResponse;

type AddOrEditIngredientCategoryComponentFormFields = 'name';

interface Translations {
  addTitle: string;
  updateTitle: string;
  nameLabel: string;
  saveButtonLabel: string;
}


@Component({
  selector: 'app-add-or-edit-ingredient-category',
  templateUrl: './add-or-edit-ingredient-category.component.html',
  styleUrls: ['./add-or-edit-ingredient-category.component.scss']
})
export class AddOrEditIngredientCategoryComponent implements OnInit {

  @Input()
  public categoryToEdit: ExistingIngredientCategoryData | null;

  @Output()
  public completed = new EventEmitter<NewIngredientCategoryData | ExistingIngredientCategoryData>();

  public actionInProgress: boolean = false;
  public formDef: FormDefinition<AddOrEditIngredientCategoryComponentFormFields>;
  public translations$: ReplaySubject<Translations> = new ReplaySubject<Translations>();

  constructor(private formBuilder: FormBuilder,
              private trnService: TranslateService,
              private toastService: ToastService,
              private ingredientCategoriesService: CurrentRestaurantIngredientCategoriesService,
              public activeModal: NgbActiveModal,
              private store: Store<AppState>) {

    this.initTranslations();
  }

  ngOnInit() {
    const nameValue = this.categoryToEdit == null ? '' : this.categoryToEdit.name;

    const fieldsDef: FormFieldsDefinition<AddOrEditIngredientCategoryComponentFormFields> = {
      name: new FormFieldDefinition(nameValue,
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceValidator],
                                    [
                                      nameof<AddIngredientCategoryRequest>('name'),
                                      nameof<UpdateIngredientCategoryRequest>('name')
                                    ])
    };

    this.formDef = new FormDefinition<AddOrEditIngredientCategoryComponentFormFields>(fieldsDef, this.formBuilder);
  }

  private initTranslations() {
    const initialTranslations: Translations = {
      addTitle: _('Add ingredient category'),
      updateTitle: _('Update ingredient category'),
      saveButtonLabel: _('Save'),
      nameLabel: _('Name')
    };

    this.translations$.next(initialTranslations);

    const translationKeys = getArrayOfTranslationKeys(initialTranslations);

    this.trnService
        .stream(translationKeys)
        .pipe(
          map(translations => {
            const result: Translations = assignTranslatedProperties(initialTranslations, translations);

            return result;
          })
        )
        .subscribe(this.translations$);
  }

  public save() {
    this.actionInProgress = true;

    if (this.formDef.form.invalid) {
      this.formDef.form.markAllAsTouched();
      this.actionInProgress = false;
    }

    let actionToExecute: Observable<AddOrUpdateIngredientCategoryResponse>;
    let unexpectedServerFailureMsg: string;
    let successMsg: string;
    const tryAgainLaterMsg = trnTryAgainLaterText(this.trnService);

    const ingredientName = this.formDef.getControl('name').value;

    if (this.categoryToEdit != null) {
      const request: UpdateIngredientCategoryRequest = {
        name: ingredientName,
        id: this.categoryToEdit.id
      };

      actionToExecute = this.ingredientCategoriesService.update(request);
      unexpectedServerFailureMsg = _('Failed to update an ingredient category because of unexpected failure.') + ' ' + tryAgainLaterMsg;
      successMsg = _('Successfully updated an ingredient category.');
    } else {
      const request: AddIngredientCategoryRequest = {
        name: ingredientName
      };

      actionToExecute = this.ingredientCategoriesService
                            .add(request)
                            .pipe(
                              tap((response: AddOrUpdateIngredientCategoryResponse) => {
                                if (response.status === AddOrUpdateIngredientCategoryResponse.StatusDef.Success) {
                                  this.store.dispatch(new IngredientCategoryAddSuccessfulAction(response));
                                }
                              })
                            );
      unexpectedServerFailureMsg = _('Failed to add an ingredient category because of unexpected failure.') + ' ' + tryAgainLaterMsg;
      successMsg = _('Successfully added an ingredient category.');
    }

    const reenableToken = this.formDef.disable('all-fields');

    actionToExecute.pipe(
      first(),
      tap((x: AddOrUpdateIngredientCategoryResponse) => {
        switch (x.status) {
          case AddOrUpdateIngredientCategoryResponse.StatusDef.Success:
            this.activeModal.dismiss(null);
            this.toastService.showSuccess(successMsg);

            const newValue = this.categoryToEdit == null
                             ? new NewIngredientCategoryData(ingredientName)
                             : new ExistingIngredientCategoryData(this.categoryToEdit.id, ingredientName);

            this.completed.emit(newValue);
            break;
          case AddOrUpdateIngredientCategoryResponse.StatusDef.ValidationFailed:
            // controls must be enabled before errors are set
            reenableToken.reenable();

            this.formDef.setFormFieldsServerValidationResults(x.validationErrors);
            break;
          case AddOrUpdateIngredientCategoryResponse.StatusDef.UnexpectedError:
            this.toastService.showError(unexpectedServerFailureMsg);
            break;
          default:
            throw new NeverError(x.status);
        }
      }),
      catchError(
        genericErrorHandlerWithToast<AddOrUpdateIngredientCategoryResponse>(this.toastService, this.trnService, unexpectedServerFailureMsg)
      ),
      finalize(() => {
        this.actionInProgress = false;

        reenableToken.reenable();
      }))
                   .subscribe();
  }
}
