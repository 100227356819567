<ng-container *ngIf="translationsCfg | async as translationsDefinition">
  <div class="modal-header pd-y-20 pd-x-25">
    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{translationsDefinition.modalTitle}}</h6>
    <button type="button" class="close" [attr.aria-label]="translationsDefinition.cancelButton" (click)="activeModal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pd-25">
    <form [formGroup]="formDef.form" (ngSubmit)="assign()">
      <div class="row">
        <div class="col-12">
          <h4>{{'After reporting this display as broken, it will be unlinked from the assigned table (if any) and deactivated. You will not be able to use the display until it gets fixed or replaced.' | translate}}</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-control-label">{{translationsDefinition.displayNameLabel}}:</label>

            <input class="form-control"
                   type="text"
                   [formControl]="formDef.getControl('display')" />

            <owc-input-validation-errors [control]="formDef.getControl('display')"></owc-input-validation-errors>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-control-label">{{translationsDefinition.tableLabel}}:</label>

            <ng-select [formControl]="formDef.getControl('table')"
                       [items]="tables$ | async"
                       bindLabel="clientTableName">
            </ng-select>

            <owc-input-validation-errors [control]="formDef.getControl('table')"></owc-input-validation-errors>
          </div>
        </div>
      </div>

    </form>

  </div>

  <div class="modal-footer tx-white">
    <owc-progress-action-button [actionInProgress]="actionRunning"
                                (execute)="assign()"
                                [btnText]="translationsDefinition.assignButton"
                                [disabled]="formDef.form.invalid"
                                type="link">
    </owc-progress-action-button>
  </div>

</ng-container>
