import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {isNotNullOrEmptyOrWhiteSpaceValidator} from 'projects/orderly-web-components/src/lib/validators';
import {RestaurantService} from '../../services/restaurant.service';
import {RestaurantHoldingRegistrationModel} from '../../models/registration.model';
import {
  Language,
  NeverError,
  StaticDataService,
  ToastService,
  zipCodeValidator
} from 'orderly-web-components';
import {catchError, finalize, first, flatMap, map, takeUntil, tap} from 'rxjs/operators';
import {Observable, of, ReplaySubject} from 'rxjs';
import {AppState} from '../store/app.state';
import {Store} from '@ngrx/store';
import {genericErrorHandlerWithToast, nameof} from '../../util/utils';
import {LoginSuccessfulAction} from '../store/actions/user.actions';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../util/form.utils';
import RegisterRestaurantHoldingRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.RegisterRestaurantHoldingRequest;
import RegisterRestaurantHoldingResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.RegisterRestaurantHoldingResponse;
import StatusDef = Orderly.RestaurantWeb.Api.Messages.Restaurant.RegisterRestaurantHoldingResponse.StatusDef;
import {RoutingConstants} from '../routing-constants';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';
import {IsChainSelectItem} from './helper.models';
import {getCurrentLanguageSelector} from '../store/selectors/localization.selectors';
import {RegistrationBaseComponent} from './registration-base.component';

@Component({
             selector: 'app-registration',
             templateUrl: './registration.component.html',
             styleUrls: ['./registration.component.scss']
           })
export class RegistrationComponent extends RegistrationBaseComponent implements OnInit {

  public formDef: FormDefinition<keyof RestaurantHoldingRegistrationModel | 'acceptTerms'>;

  public isChainSelectValues$: ReplaySubject<IsChainSelectItem[]> = new ReplaySubject<IsChainSelectItem[]>(1);

  public registrationInProgress: boolean = false;


  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private restaurantService: RestaurantService,
              private toastService: ToastService,
              private store: Store<AppState>,
              trnService: TranslateService,
              staticDataService: StaticDataService) {

    super(staticDataService, trnService);

    this.initIsChainSelectItems();
    this.initForm();
  }

  private initIsChainSelectItems() {
    const yesKey = _('Yes, I own multiple restaurants');
    const noKey = _('No, I have just one restaurant');

    this.trnService
        .stream([yesKey, noKey])
        .pipe(
          map(x => {
            const yesItem: IsChainSelectItem = {
              name: x[yesKey],
              value: true
            };
            const noItem: IsChainSelectItem = {
              name: x[noKey],
              value: false
            };

            return [yesItem, noItem];
          })
        ).subscribe(this.isChainSelectValues$);

  }

  private initForm() {
    const fieldsDef: FormFieldsDefinition<keyof RestaurantHoldingRegistrationModel | 'acceptTerms'> = {
      name: new FormFieldDefinition('',
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.minLength(3), Validators.maxLength(50)],
                                    [nameof<RegisterRestaurantHoldingRequest>('name')]),
      cityId: new FormFieldDefinition(null,
                                      false,
                                      [Validators.required],
                                      [nameof<RegisterRestaurantHoldingRequest>('cityId')]),
      zipCode: new FormFieldDefinition(null,
                                       true,
                                       [],
                                       [nameof<RegisterRestaurantHoldingRequest>('zipCode')]),
      address: new FormFieldDefinition('',
                                       false,
                                       [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.minLength(3), Validators.maxLength(50)],
                                       [nameof<RegisterRestaurantHoldingRequest>('address')]),
      contactPhone: new FormFieldDefinition('',
                                            false,
                                            [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.maxLength(30)],
                                            [nameof<RegisterRestaurantHoldingRequest>('contactPhone')]),
      firstName: new FormFieldDefinition('',
                                         false,
                                         [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.maxLength(30)],
                                         [nameof<RegisterRestaurantHoldingRequest>('ownerFirstName')]),
      lastName: new FormFieldDefinition('',
                                        false,
                                        [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.maxLength(30)],
                                        [nameof<RegisterRestaurantHoldingRequest>('ownerLastName')]),
      email: new FormFieldDefinition('',
                                     false,
                                     [Validators.required, Validators.email, Validators.maxLength(120)],
                                     [nameof<RegisterRestaurantHoldingRequest>('ownerEmail')]),
      password: new FormFieldDefinition('',
                                        false,
                                        [Validators.required, Validators.minLength(8)],
                                        [nameof<RegisterRestaurantHoldingRequest>('ownerPassword')]),
      isChain: new FormFieldDefinition(null,
                                       false,
                                       [Validators.required],
                                       [nameof<RegisterRestaurantHoldingRequest>('isChain')]),
      languageCode2: new FormFieldDefinition(null,
                                             false,
                                             [Validators.required],
                                             [nameof<RegisterRestaurantHoldingRequest>('languageCode2')]),
      acceptTerms: new FormFieldDefinition(null,
                                           false,
                                           [Validators.requiredTrue],
                                           []),
    };

    const formOpts = {
      validator: zipCodeValidator(nameof<RestaurantHoldingRegistrationModel>('cityId'),
                                  nameof<RestaurantHoldingRegistrationModel>('zipCode'),
                                  this.trnService)
    };
    this.formDef = new FormDefinition<keyof RestaurantHoldingRegistrationModel | 'acceptTerms'>(fieldsDef, this.formBuilder, formOpts);

    this.formDef
        .getControl('cityId')
        .valueChanges
        .pipe(
          tap((cityId: number) => {
            if (cityId === 2) {
              this.formDef.enable(['zipCode']);
            } else {
              this.formDef.disable(['zipCode']);
              this.formDef.setControlValue('zipCode', null);
            }
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe();

    this.formDef
        .getControl('languageCode2')
        .valueChanges
        .pipe(
          takeUntil(this.destroyed$),
          tap((langCode2: string | null) => {
            this.languageChanged(langCode2);
          })
        )
        .subscribe();
  }

  ngOnInit() {
    this.activatedRoute
        .queryParamMap
        .pipe(
          flatMap(params => {
            const preselectedLangCode2 = (params.get('lang') || this.trnService.currentLang || 'en').toLowerCase();

            if (preselectedLangCode2 == null || preselectedLangCode2.length !== 2) {
              return of(null);
            }

            return this.languages$
                       .pipe(
                         map(langs => {
                           const matchedLangs = langs.filter(l => l.code.toLowerCase() === preselectedLangCode2);

                           return matchedLangs.length >= 1 ? matchedLangs[0] : null;
                         })
                       );
          }),
          tap((lang: null | Language) => {
            if (lang == null) {
              return;
            }

            const langControl = this.formDef.getControl('languageCode2');

            if (langControl.value !== lang.id) {
              this.formDef.getControl('languageCode2').setValue(lang.id);
            }

            if (this.trnService.currentLang !== lang.code) {
              this.trnService.use(lang.code);
            }
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe();
  }

  public registerRestaurantHolding() {
    if (this.formDef.form.invalid) {
      return;
    }

    this.registrationInProgress = true;

    this.store
        .select(getCurrentLanguageSelector)
        .pipe(
          flatMap((lang: Language) => {
            const model: RestaurantHoldingRegistrationModel = this.formDef.form.value;

            model.languageCode2 = lang.code;

            return this.doRegister(model);
          }),
          first(),
          finalize(() => {
            this.registrationInProgress = false;
          })
        )
        .subscribe();
  }

  private doRegister(model: RestaurantHoldingRegistrationModel): Observable<RegisterRestaurantHoldingResponse | null> {
    const disableToken = this.formDef.disable('all-fields');
    const unexpectedServerFailureMsg = _('Failed to register your restaurant. Check your internet connection and try again.');

    return this.restaurantService
               .registerHolding(model)
               .pipe(
                 tap((response: RegisterRestaurantHoldingResponse) => {
                   // required to set the errors
                   disableToken.reenable();

                   switch (response.status) {
                     case StatusDef.ValidationFailed:
                       this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
                       break;
                     case StatusDef.UnexpectedException:
                       const unexpectedExceptionError = 'Failed to register your restaurant because of unexpected error. Please try again.';
                       this.toastService.showError(unexpectedExceptionError);
                       break;
                     case StatusDef.Success:
                       this.store.dispatch(new LoginSuccessfulAction(response.user, response.jwtTokenBase64, false));
                       break;
                     case StatusDef.FailedToAuthenticateAutomatically:
                       this.router.navigateByUrl('/' + RoutingConstants.LOGIN);
                       break;
                     default:
                       throw new NeverError(response.status);
                   }
                 }),
                 catchError(
                   genericErrorHandlerWithToast<RegisterRestaurantHoldingResponse>(this.toastService, this.trnService, unexpectedServerFailureMsg)
                 ),
                 finalize(() => {
                   disableToken.reenable();
                 })
               );
  }

  private languageChanged(code2: string | null) {
    let langCode2 = this.trnService.defaultLang.toLowerCase();

    if (code2 != null) {
      langCode2 = code2.toLowerCase();
    }

    if (this.trnService.currentLang == null && langCode2 === this.trnService.defaultLang.toLowerCase()) {
      return;
    } else if (this.trnService.currentLang != null) {
      if (langCode2 === this.trnService.currentLang.toLowerCase()) {
        return;
      }
    }

    this.router.navigate([], {queryParams: {lang: langCode2}});
  }
}
