<div class="modal-header pd-y-20 pd-x-25">
  <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{title}}</h6>
  <button type="button" class="close" [attr.aria-label]="cancelButtonText" (click)="modalService.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pd-25" *ngIf="messages != null && messages.length > 0">

  <ng-container *ngFor="let item of messages">
    <h5 [attr.style]="domSanitizationService.bypassSecurityTrustStyle(item.inlineStyle)">{{item.text}}</h5>
  </ng-container>

</div>
<div class="modal-footer tx-white">
  <owc-progress-action-button [actionInProgress]="actionInProgress"
                              (execute)="confirm()"
                              [btnText]="okButtonText"
                              type="link"
                              [autoFocus]="false">
  </owc-progress-action-button>
</div>
