import {LeftMenuState} from '../../app.state';
import {RestaurantSuccessfullyCreatedAction} from '../../actions/restaurant.actions';
import {createRestaurantMenuItem} from './helper.functions';

export function restaurantCreatedActionHandler(currentState: LeftMenuState,
                                               action: RestaurantSuccessfullyCreatedAction): LeftMenuState {

  const items = [...currentState.items];
  const newRestaurantMenuItem = createRestaurantMenuItem(action.restaurant);

  items.push(newRestaurantMenuItem);

  return {...currentState, items, activeItemId: newRestaurantMenuItem.id};
}
