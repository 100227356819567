<div class="col-12 col-xxl-2 col-xl-3 col-lg-4 col-md-6">
  <div class="form-group">
    <label class="form-control-label" for="menuCurrency">{{'Menu currency' | translate}}: <span class="tx-danger">*</span></label>

    <app-shared-currency-select [formControl]="formDef.getControl('menuCurrency')"
                                id="menuCurrency">
    </app-shared-currency-select>

    <owc-input-validation-errors [control]="formDef.getControl('menuCurrency')"></owc-input-validation-errors>
  </div>
</div>

<div class="col-12 col-xxl-4 col-xl-4 col-lg-5 col-md-6">
  <div class="form-group">
    <label class="form-control-label" for="menuTranslationLanguages">{{'Translation languages' | translate}}:</label>

    <app-shared-languages-select [formControl]="formDef.getControl('translationLanguages')"
                                 id="menuTranslationLanguages">
    </app-shared-languages-select>

    <owc-input-validation-errors [control]="formDef.getControl('translationLanguages')"></owc-input-validation-errors>
  </div>
</div>

<div class="col-12 col-xxl-2 col-xl-3 col-lg-4 col-md-6">
  <div class="form-group">
    <label class="form-control-label" for="menuLanguage">{{'Primary menu language' | translate}}: <span class="tx-danger">*</span></label>

    <app-shared-language-select [formControl]="formDef.getControl('menuDefaultLanguageId')"
                                [languages$]="selectedMenuLanguages$"
                                id="menuLanguage">
    </app-shared-language-select>

    <owc-input-validation-errors [control]="formDef.getControl('menuDefaultLanguageId')"></owc-input-validation-errors>
  </div>
</div>
