import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ActionTypes} from '../actions/action-types';
import {switchMap, tap} from 'rxjs/operators';
import {AuthenticatedUserModel, LocalStorageService} from '../../../services/local-storage.service';
import {Router} from '@angular/router';
import {getUserDefaultRedirectUrlByRole} from '../../../route.guards';
import {RestaurantManagerRoutingConstants, RoutingConstants} from '../../routing-constants';
import {
  LoginSuccessfulAction,
  LogoutAction,
  CurrentUserInformationChangedAction, CurrentUserSelectedLanguageChangedAction
} from '../actions/user.actions';
import {environment} from '../../../environments/environment';
import {RestaurantSuccessfullyCreatedAction} from '../actions/restaurant.actions';
import {from} from 'rxjs';

@Injectable()
export class LoginLogoutEffect {

  constructor(private actions: Actions, private router: Router) {
  }

  @Effect({dispatch: false})
  loginSuccessfulEffect = this.actions.pipe(
    ofType(ActionTypes.LOGIN_SUCCESSFUL),

    tap((action: LoginSuccessfulAction) => {
      const parsedUser = LocalStorageService.setCurrentUser(action.jwtTokenBase64, action.user);
      const restaurantIds: number[] = parsedUser.user.restaurants.map(x => x.id);

      const currentLocationIsLoginOrRegister: boolean = location.pathname.toLowerCase() === '/' + RoutingConstants.LOGIN ||
                                                        location.pathname.toLowerCase() === '/' + RoutingConstants.REGISTRATION;

      if (currentLocationIsLoginOrRegister) {
        const navigationUrl = getUserDefaultRedirectUrlByRole(parsedUser.roles, restaurantIds);

        this.router.navigateByUrl(navigationUrl);
      }

      return true;
    })
  );

  @Effect({dispatch: false})
  logoutEffect = this.actions.pipe(
    ofType(ActionTypes.LOGOUT),

    tap((action: LogoutAction) => {
      LocalStorageService.removeCurrentUser();
    }),
    switchMap((action: LogoutAction) => {
      return from(this.router.navigateByUrl('/' + RoutingConstants.LOGIN));
    })
  );

  @Effect({dispatch: false})
  currentUserInformationChangedEffect = this.actions.pipe(
    ofType(ActionTypes.CURRENT_USER_INFORMATION_CHANGED),

    tap((action: CurrentUserInformationChangedAction) => {
      const currentUserInfo: AuthenticatedUserModel | null = LocalStorageService.getCurrentUser();

      if (currentUserInfo != null) {
        const clonedUser: Orderly.RestaurantWeb.Api.Messages.UserWithRestaurantsInfo = {...currentUserInfo.user};

        clonedUser.preferredLang = action.lang;
        clonedUser.lastName = action.lastName;
        clonedUser.firstName = action.firstName;

        LocalStorageService.setCurrentUser(currentUserInfo.jwtTokenBase64, clonedUser);
      } else if (!environment.production) {
        throw new Error(
          `Action [${ActionTypes.CURRENT_USER_INFORMATION_CHANGED}] expects that current user information ` +
          'is available in local storage');
      }
    })
  );

  @Effect({dispatch: false})
  userLanguageChangedEffect$ = this.actions.pipe(
    ofType(ActionTypes.CURRENT_USER_SELECTED_LANGUAGE_CHANGED),

    tap((action: CurrentUserSelectedLanguageChangedAction) => {
      const currentUserInfo: AuthenticatedUserModel | null = LocalStorageService.getCurrentUser();

      if (currentUserInfo != null) {
        const clonedUser: Orderly.RestaurantWeb.Api.Messages.UserWithRestaurantsInfo = {...currentUserInfo.user};

        clonedUser.preferredLang = action.lang;

        LocalStorageService.setCurrentUser(currentUserInfo.jwtTokenBase64, clonedUser);
      }
    })
  );

  @Effect({dispatch: false})
  restaurantCreateSuccessfulEffect = this.actions.pipe(
    ofType(ActionTypes.RESTAURANT_CREATE_SUCCESSFUL),

    tap((action: RestaurantSuccessfullyCreatedAction) => {
      const currentUserInfo: AuthenticatedUserModel | null = LocalStorageService.getCurrentUser();

      if (currentUserInfo != null) {
        currentUserInfo.user.restaurants.push(action.restaurant);

        LocalStorageService.setCurrentUser(currentUserInfo.jwtTokenBase64, currentUserInfo.user);

        this.router.navigateByUrl(RestaurantManagerRoutingConstants.getRestaurantEditUrl(action.restaurant.id));

      } else if (!environment.production) {
        throw new Error(
          `Action [${ActionTypes.RESTAURANT_CREATE_SUCCESSFUL}] expects that current user information ` +
          'is available in local storage');
      }
    })
  );
}
