<div class="row">
  <div class="col-12 col-lg-6">
    <div class="form-group">
      <label class="form-control-label">{{'Name' | translate}}:</label>

      <app-shared-translations-input [languages]="translationLanguages$ | async"
                                     [primaryTextLanguage]="primaryLanguage$ | async"
                                     [formControl]="formDef.getControl('name')">
      </app-shared-translations-input>

      <owc-input-validation-errors [control]="formDef.getControl('name')"></owc-input-validation-errors>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="form-group">
      <label class="form-control-label">{{'Description' | translate}}:</label>

      <app-shared-translations-input [languages]="translationLanguages$ | async"
                                     [primaryTextLanguage]="primaryLanguage$ | async"
                                     [formControl]="formDef.getControl('description')"
                                     [textinputType]="'textarea'">
      </app-shared-translations-input>

      <owc-input-validation-errors [control]="formDef.getControl('description')"></owc-input-validation-errors>
    </div>
  </div>
</div>
