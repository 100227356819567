import { map, switchMap, tap } from 'rxjs/operators';
import { CurrentRestaurantServiceBase } from './current-restaurant.service.base';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
export class CurrentRestaurantAreasService extends CurrentRestaurantServiceBase {
    constructor(httpClient, store) {
        super(store);
        this.httpClient = httpClient;
        this.baseURL = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
    }
    get currentRestaurantAreas$() {
        return this.dataFeed$;
    }
    doLoadData(restaurantId) {
        const url = `${this.baseURL}/restaurant/${restaurantId}/areas/all`;
        const response$ = this.httpClient.get(url);
        return response$.pipe(tap(x => {
            if (x.status === 3 /* UnknownFailure */) {
                throw new Error('Server returned an error. Could not load list of areas');
            }
        }), map(x => x.items));
    }
    add(request) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            return this.addForRestaurant(restaurantId, request);
        }));
    }
    addForRestaurant(restaurantId, request) {
        const url = this.baseURL + `/restaurant/${restaurantId}/area/add`;
        return this.httpClient.post(url, request);
        ;
    }
    update(areaId, request) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = this.baseURL + `/restaurant/${restaurantId}/area/${areaId}/update`;
            return this.httpClient.post(url, request);
        }));
    }
    delete(areaId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = this.baseURL + `/restaurant/${restaurantId}/area/${areaId}/delete`;
            return this.httpClient.delete(url);
        }));
    }
}
CurrentRestaurantAreasService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentRestaurantAreasService_Factory() { return new CurrentRestaurantAreasService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: CurrentRestaurantAreasService, providedIn: "root" });
