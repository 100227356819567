<app-top-bar-with-language-switcher></app-top-bar-with-language-switcher>

<div class="justify-content-center bg-br-primary ht-100v register-form-container-sm" style="padding: 25px 0; margin: auto;" *ngIf="translations$ | async as trns">
  <div class="pd-25 pd-xs-40 bg-white rounded shadow-base">

    <div class="tx-center tx-28 tx-inverse pd-md-b-20">{{'Final step to your digital menu!' | translate}}</div>

    <div *ngIf="isCompleted === true" class="row text-center">
      <div class="col-12">
        {{'Registration was completed successfully! QR-Code will be sent on your email.' | translate}}
      </div>
    </div>

    <form *ngIf="isCompleted === false" [formGroup]="this.formDef.form" (ngSubmit)="registerRestaurantHolding()" class="form-layout form-layout-1" autocomplete="off">

      <div class="row">
        <div class="col-12">

          <div class="form-group">
            <label class="form-control-label">{{'Language' | translate}}: <span class="tx-danger">*</span></label>

            <div class="row">
              <div class="col-12">
                <app-language2-select [languages$]="languages$"
                                      [formControl]="formDef.getControl('languageCode2')">

                </app-language2-select>

                <owc-input-validation-errors [control]="formDef.getControl('languageCode2')"></owc-input-validation-errors>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-control-label">{{trns.restaurantNameLabel}}: <span class="tx-danger">*</span></label>
            <input type="text"
                   class="form-control"
                   maxlength="50"
                   [formControl]="formDef.getControl('name')"
                   placeholder="{{trns.restaurantInputPlaceholder}}" />

            <owc-input-validation-errors [control]="formDef.getControl('name')"></owc-input-validation-errors>
          </div>

          <div class="form-group">
            <label class="form-control-label">{{'Account manager email' | translate}}: <span class="tx-danger">*</span></label>

            <input type="text"
                   class="form-control"
                   maxlength="120"
                   [formControl]="formDef.getControl('email')"
                   [placeholder]="'Enter account manager email' | translate" />

            <small>{{'QR code will be sent on this email' | translate}}</small>

            <owc-input-validation-errors [control]="formDef.getControl('email')"></owc-input-validation-errors>
          </div>

          <div class="form-group">
            <label class="form-control-label">{{'Menu' | translate}} (PDF):</label>

            <div>
              <input type="file" name="category-image[]" id="category-image" class="inputfile" (change)="onFileChange($event)" #menuFileInput>

              <label for="category-image" class="tx-white bg-info btn-select-category-image">
                <i class="icon ion-ios-upload-outline tx-24"></i>
                <span *ngIf="this.formDef.getControl('menuFileName').value != null">{{this.formDef.getControl('menuFileName').value}}</span>
                <span *ngIf="this.formDef.getControl('menuFileName').value == null">{{'Choose a PDF file' | translate}}...</span>
              </label>

              <a *ngIf="this.formDef.getControl('menuFileName').value != null"
                 (click)="clearMenuFile()"
                 class="btn btn-danger" style="color: #fff; position: absolute; padding-top: 7px; padding-bottom: 7px;"><i class="icon-trash-empty"></i></a>
            </div>

            <owc-input-validation-errors [control]="formDef.getControl('menuFileName')"></owc-input-validation-errors>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-12 align-content-lg-end">
          <div class="form-group form-check">
            <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" />
            <label for="acceptTerms" class="form-check-label">{{trns.acceptTermsAndConditions}}</label>

            <owc-input-validation-errors [control]="formDef.getControl('acceptTerms')"></owc-input-validation-errors>
          </div>

          <div class="float-left">
            <owc-progress-action-button [actionInProgress]="registrationInProgress"
                                        [btnClass]="'btn-danger'"
                                        [btnText]="'Register' | translate"
                                        [type]="'submit'"
                                        [disabled]="formDef.form.invalid"
                                        (execute)="registerRestaurantHolding()">
            </owc-progress-action-button>
          </div>

          <a class="btn btn-link float-right" routerLink="/login">{{'Navigate to login' | translate}}</a>
        </div>
      </div>

    </form>
  </div>
</div>
