<ng-select [items]="measureUnits$ | async"
           [ngModel]="lastValue"
           (ngModelChange)="changed($event)"
           [disabled]="isDisabled"
           [appendTo]="appendTo"
           [searchable]="false"
           [placeholder]="translatedPlaceholder$ | async"
           [compareWith]="compareItems"
           bindLabel="name">
</ng-select>
