import RestaurantTerminalType = Orderly.Common.Enums.RestaurantTerminalType;
import RestaurantUser = Orderly.RestaurantWeb.Api.Messages.Restaurant.GetRestaurantUsersResponse.RestaurantUser;

export class RestaurantTerminalTypeSelectItem {
  constructor(public readonly value: RestaurantTerminalType, public readonly name: string) {
  }
}

export class OwnerSelectItem {
  constructor(public readonly id: number, public readonly fullName: string) {
  }

  public static from(source: RestaurantUser): OwnerSelectItem {
    return new OwnerSelectItem(source.id, `${source.lastName} ${source.firstName}`);
  }
}
