import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../../util/form.utils';
import {CategoryDetailsFieldsDefinition} from './category-details-fields.definition';
import {DomSanitizer} from '@angular/platform-browser';
import {filter, tap} from 'rxjs/operators';
import {TextTranslationDefinition} from '../../../../shared/components/text-input-with-language-flag/text-translation.definition';
import {BaseOrderlyComponent} from '../../../../shared/components/base-orderly.component';

@Component({
             selector: 'app-menu-item-category-details-tab',
             templateUrl: './details-tab.component.html',
             styleUrls: ['./details-tab.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: DetailsTabComponent,
                 multi: true,
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: DetailsTabComponent,
                 multi: true
               },
             ],
           })
export class DetailsTabComponent extends BaseOrderlyComponent implements ControlValueAccessor, Validator {

  @ViewChild('categoryImageFileInput', {static: false})
  categoryImageFileInput: ElementRef;


  @Output()
  public nameOrDescriptionChanged = new EventEmitter<void>();


  public formDef: FormDefinition<keyof CategoryDetailsFieldsDefinition>;

  public isDisabled: boolean = false;

  public initInProgress: boolean = false;

  constructor(private formBuilder: FormBuilder,
              public readonly domSanitizationService: DomSanitizer) {

    super();

    const isNotNullOrEmptyOrWhiteSpaceTranslationValidator = (control: AbstractControl): ValidationErrors | null => {

      if (control.invalid) {
        return null;
      }

      const translationValue: TextTranslationDefinition = control.value;

      if (translationValue == null || translationValue.text == null) {

        return {isNotNullOrEmptyOrWhiteSpace: true};
      }

      if ((translationValue.text || '').trim().length === 0) {

        return {isNotNullOrEmptyOrWhiteSpace: {value: translationValue.text}};
      }

      return null;
    }

    const fieldsDef: FormFieldsDefinition<keyof CategoryDetailsFieldsDefinition> = {
      id: new FormFieldDefinition(null,
                                  false,
                                  [],
                                  []),
      name: new FormFieldDefinition(null,
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceTranslationValidator],
                                    []),
      description: new FormFieldDefinition(null,
                                           false,
                                           [Validators.maxLength(500)],
                                           []),
      image: new FormFieldDefinition(null,
                                     false,
                                     [],
                                     []),
      isMenuItemsThumbsVisible: new FormFieldDefinition(null,
                                                         false,
                                                         [],
                                                         []),
    };

    this.formDef = new FormDefinition<keyof CategoryDetailsFieldsDefinition>(fieldsDef, this.formBuilder);

    this.registerForAutoDestroy(
      this.formDef
          .getControl('name')
          .valueChanges
          .pipe(
            filter(() => !this.initInProgress),
            tap(() => {
              this.nameOrDescriptionChanged.next();
            }),
          )
          .subscribe()
    );

    this.registerForAutoDestroy(
      this.formDef
          .getControl('description')
          .valueChanges
          .pipe(
            filter(() => !this.initInProgress),
            tap(() => {
              this.nameOrDescriptionChanged.next();
            }),
          )
          .subscribe()
    );

    this.registerForAutoDestroy(
      this.formDef
          .form
          .valueChanges
          .pipe(
            filter(() => !this.initInProgress),
            tap((formValue: CategoryDetailsFieldsDefinition) => {
              this.onChange(formValue);
            }),
          )
          .subscribe()
    );
  }

  onChange: any = (value: CategoryDetailsFieldsDefinition) => {
  };

  onTouched: any = () => {
  };


  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: CategoryDetailsFieldsDefinition | null): void {
    if (value == null) {
      return;
    }

    this.initInProgress = true;

    this.formDef.form.patchValue(value, {emitEvent: false});

    this.initInProgress = false;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;

    if (isDisabled) {
      this.formDef.disable('all-fields');
    } else {
      this.formDef.enable('all-fields', false);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.formDef.form.invalid) {
      return {'details-tab': true};
    }

    return null;
  }
}
