<div class="row group-header-row">
  <div class="col-3">
    {{'Name' | translate}}
  </div>
  <div class="col-2">
    {{'Is active' | translate}}
  </div>
  <div class="col-2">
    {{'Min selectable elements' | translate}}
  </div>
  <div class="col-2">
    {{'Max selectable elements' | translate}}
  </div>
  <div class="col-3">
    &nbsp;
  </div>
</div>

<div class="row group-data-row">
  <div class="col-3">
    {{group.name}}
  </div>
  <div class="col-2">
    {{group.isActive | yesno | translate}}
  </div>
  <div class="col-2">
    {{group.minAllowedAmount}}
  </div>
  <div class="col-2">
    <ng-container *ngIf="group.maxAllowedAmount != null">
      {{group.maxAllowedAmount}}
    </ng-container>
    <ng-container *ngIf="group.maxAllowedAmount == null">
      -
    </ng-container>
  </div>
  <div class="col-3">
    <a class="btn btn-sm btn-danger text-white mg-x-1" (click)="delete.emit(group)">
      <i class="icon-trash-empty"></i> {{'Delete' | translate}}
    </a>

    <a class="btn btn-sm btn-success text-white mg-x-1"
       (click)="update.emit(group)">
      <i class="icon-edit"></i> {{'Edit' | translate}}
    </a>

    <a class="btn btn-sm btn-success text-white mg-x-1"
       (click)="addNewModifier.emit(group)">
      <i class="icon-plus"></i> {{'Add modifier' | translate}}
    </a>
  </div>
</div>


<div class="row modifiers-list-row" *ngIf="group.menuItemModifiers != null && group.menuItemModifiers.length > 0">
  <div class="col-11 offset-1 modifiers-list-col">
    <div class="editable-modifiers-list-table div-based-table table-dark table-colored">

      <div class="thead">
        <div class="row">
          <div class="col-4">
            {{'Modifier name or ingredient' | translate}}
          </div>
          <div class="col-3">
            {{'Amount' | translate}}
          </div>
          <div class="col-2">
            {{'Price diff' | translate}}
          </div>
          <div class="col-3">
            &nbsp;
          </div>
        </div>
      </div>

      <div class="tbody">

        <app-modifier-row *ngFor="let modifier of group.menuItemModifiers; index as i;"
                          [modifier]="modifier"
                          (update)="updateModifier(i)"
                          (delete)="deleteModifier(i)"
                          class="row">
        </app-modifier-row>

      </div>
    </div>
  </div>
</div>
