import {Injectable, OnDestroy} from '@angular/core';
import {map} from 'rxjs/operators';
import {
  RestaurantSpecificArrayResultLoadingState,
  RestaurantSpecificResultLoadingStatusDefinition
} from '../../../services/active-route-bound/helper.statuses';
import {NeverError} from 'orderly-web-components';
import {CheckinsListGridItem} from './helper.types';
import {Observable, ReplaySubject} from 'rxjs';
import {RestaurantTableCheckinsServiceDefinition} from '../../../services/RestaurantTableCheckinsServiceDefinition';
import {CurrentRestaurantTableCheckinsService} from '../../../services/active-route-bound/current-restaurant-table-checkins.service';
import GetCovidTableCheckinListResponseItem = Orderly.RestaurantWeb.Api.Messages.Checkin.GetCovidTableCheckinListResponse.Item;

@Injectable({
              providedIn: 'root'
            })
export class GridItemsSourceService implements OnDestroy, RestaurantTableCheckinsServiceDefinition {

  private readonly cache$ = new ReplaySubject<RestaurantSpecificArrayResultLoadingState<CheckinsListGridItem>>(1);

  private checkinOn: Date | null;

  public get lastLoadedCheckinDate(): Date | null {
    return this.checkinOn;
  }

  constructor(private readonly checkinsService: CurrentRestaurantTableCheckinsService) {
    checkinsService.currentRestaurantTableCheckins$
                   .pipe(
                     map((x: RestaurantSpecificArrayResultLoadingState<GetCovidTableCheckinListResponseItem>) => {
                       switch (x.loadingStatus) {
                         case RestaurantSpecificResultLoadingStatusDefinition.SUCCESS:
                           const gridItems = x.items.map(table => new CheckinsListGridItem(table));
                           return new RestaurantSpecificArrayResultLoadingState(x.loadingStatus,
                                                                                gridItems,
                                                                                x.error);

                         case RestaurantSpecificResultLoadingStatusDefinition.FAILED:
                         case RestaurantSpecificResultLoadingStatusDefinition.CLEARED_BECAUSE_NO_RESTAURANT_ID:
                         case RestaurantSpecificResultLoadingStatusDefinition.LOADING:
                         case RestaurantSpecificResultLoadingStatusDefinition.NOT_STARTED:
                           return new RestaurantSpecificArrayResultLoadingState(x.loadingStatus,
                                                                                [],
                                                                                x.error);

                         default:
                           throw new NeverError(x.loadingStatus);
                       }
                     })
                   )
                   .subscribe(this.cache$);
  }

  public get items$(): Observable<RestaurantSpecificArrayResultLoadingState<CheckinsListGridItem>> {
    return this.cache$;
  }

  public forceReload(checkinOn: Date): Observable<any> {
    this.checkinOn = checkinOn;

    return this.checkinsService.forceReload(checkinOn);
  }

  ngOnDestroy(): void {
    this.cache$.complete();
  }
}
