import { v4 as uuidv4 } from 'uuid';
import { OnDestroy } from '@angular/core';
import { ProgressDialogToken } from './progress-dialog.token';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
export class ProgressDialogService {
    constructor() {
        this.displayRequests$ = new BehaviorSubject([]);
    }
    get isVisible$() {
        return this.displayRequests$.pipe(map((x) => x.length > 0));
    }
    display() {
        const newReq = new ProgressDialogToken(uuidv4());
        const reqs = [...this.displayRequests$.value, newReq];
        this.displayRequests$.next(reqs);
        return newReq;
    }
    hideAll() {
        this.displayRequests$.next([]);
    }
    hide(token) {
        const reqs = [...this.displayRequests$.value];
        const tokenIdx = reqs.findIndex((x) => x.id === token.id);
        if (tokenIdx >= 0) {
            reqs.splice(tokenIdx, 1);
        }
        this.displayRequests$.next(reqs);
    }
    ngOnDestroy() {
        this.displayRequests$.complete();
    }
}
ProgressDialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProgressDialogService_Factory() { return new ProgressDialogService(); }, token: ProgressDialogService, providedIn: "root" });
