/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modifiers-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./modifier-group-editor/modifier-group-editor.component.ngfactory";
import * as i5 from "./modifier-group-editor/modifier-group-editor.component";
import * as i6 from "@ngrx/store";
import * as i7 from "@angular/router";
import * as i8 from "@angular/forms";
import * as i9 from "./modifier-editor/modifier-editor.component.ngfactory";
import * as i10 from "./modifier-editor/modifier-editor.component";
import * as i11 from "../../../../../../services/active-route-bound/current-restaurant-ingredients.service";
import * as i12 from "./modifier-groups-list/modifier-groups-list.component.ngfactory";
import * as i13 from "./modifier-groups-list/modifier-groups-list.component";
import * as i14 from "./modifiers-tab.component";
var styles_ModifiersTabComponent = [i0.styles];
var RenderType_ModifiersTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModifiersTabComponent, data: {} });
export { RenderType_ModifiersTabComponent as RenderType_ModifiersTabComponent };
function View_ModifiersTabComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "mg-b-20 text-white"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("New modifier group")); _ck(_v, 1, 0, currVal_0); }); }
function View_ModifiersTabComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h3", [["class", "mg-b-20 text-white"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "modifier-group-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Modifier group")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.currentGroupToEdit.name.text; _ck(_v, 4, 0, currVal_1); }); }
function View_ModifiersTabComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], [[2, "opened", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifiersTabComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifiersTabComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-modifier-group-editor", [], null, [[null, "save"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.saveGroup($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (_co.cancelGroupEditing() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ModifierGroupEditorComponent_0, i4.RenderType_ModifierGroupEditorComponent)), i1.ɵdid(6, 245760, null, 0, i5.ModifierGroupEditorComponent, [i6.Store, i7.ActivatedRoute, i2.TranslateService, i8.FormBuilder, i1.ChangeDetectorRef], { group: [0, "group"] }, { save: "save", cancel: "cancel" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.currentGroupToEdit == null) || (_co.currentGroupToEdit.id == null)); _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.currentGroupToEdit != null) && (_co.currentGroupToEdit.id != null)); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.currentGroupToEdit; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (_v.parent.context.ngIf === "group"); _ck(_v, 0, 0, currVal_0); }); }
function View_ModifiersTabComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h3", [["class", "mg-b-20 text-white"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "modifier-group-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("New modifier in group")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.groups[_co.currentModifierToEdit.parentGroupIndex].name; _ck(_v, 4, 0, currVal_1); }); }
function View_ModifiersTabComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h3", [["class", "mg-b-20 text-white"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "modifier-group-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Modifier in group")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.groups[_co.currentModifierToEdit.parentGroupIndex].name; _ck(_v, 4, 0, currVal_1); }); }
function View_ModifiersTabComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], [[2, "opened", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifiersTabComponent_6)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifiersTabComponent_7)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-modifier-editor", [], null, [[null, "cancel"], [null, "save"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancel" === en)) {
        var pd_0 = (_co.cancelModifierEditing() !== false);
        ad = (pd_0 && ad);
    } if (("save" === en)) {
        var pd_1 = (_co.saveModifier($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_ModifierEditorComponent_0, i9.RenderType_ModifierEditorComponent)), i1.ɵdid(6, 245760, null, 0, i10.ModifierEditorComponent, [i6.Store, i7.ActivatedRoute, i8.FormBuilder, i1.ChangeDetectorRef, i11.CurrentRestaurantIngredientsService], { modifier: [0, "modifier"] }, { cancel: "cancel", save: "save" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.currentModifierToEdit.modifier.id == null); _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.currentModifierToEdit.modifier.id != null); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.currentModifierToEdit.modifier; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (_v.parent.context.ngIf === "modifier"); _ck(_v, 0, 0, currVal_0); }); }
function View_ModifiersTabComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "new-element-btn btn btn-outline-light text-white float-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addModifierGroup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["New modifier group "]))], null, null); }
function View_ModifiersTabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "app-modifier-groups-list", [], null, [[null, "deleteGroup"], [null, "requestGroupUpdate"], [null, "requestModifierUpdate"], [null, "requestModifierCreate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deleteGroup" === en)) {
        var pd_0 = (_co.deleteGroup($event) !== false);
        ad = (pd_0 && ad);
    } if (("requestGroupUpdate" === en)) {
        var pd_1 = (_co.startGroupUpdate($event) !== false);
        ad = (pd_1 && ad);
    } if (("requestModifierUpdate" === en)) {
        var pd_2 = (_co.startModifierUpdate($event) !== false);
        ad = (pd_2 && ad);
    } if (("requestModifierCreate" === en)) {
        var pd_3 = (_co.startModifierAdd($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i12.View_ModifierGroupsListComponent_0, i12.RenderType_ModifierGroupsListComponent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "opened": 0, "closed": 1 }), i1.ɵdid(5, 180224, null, 0, i13.ModifierGroupsListComponent, [], { groups: [0, "groups"] }, { deleteGroup: "deleteGroup", requestGroupUpdate: "requestGroupUpdate", requestModifierUpdate: "requestModifierUpdate", requestModifierCreate: "requestModifierCreate" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifiersTabComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifiersTabComponent_5)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "tfoot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifiersTabComponent_8)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, (_v.context.ngIf === "list"), (_v.context.ngIf !== "list")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.groups; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_v.context.ngIf === "group"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_v.context.ngIf === "modifier"); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_v.context.ngIf === "list"); _ck(_v, 13, 0, currVal_4); }, null); }
export function View_ModifiersTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ModifiersTabComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.mode$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ModifiersTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-modifiers-tab", [], null, null, null, View_ModifiersTabComponent_0, RenderType_ModifiersTabComponent)), i1.ɵprd(5120, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i14.ModifiersTabComponent]), i1.ɵprd(5120, null, i8.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i14.ModifiersTabComponent]), i1.ɵdid(3, 180224, null, 0, i14.ModifiersTabComponent, [], null, null)], null, null); }
var ModifiersTabComponentNgFactory = i1.ɵccf("app-modifiers-tab", i14.ModifiersTabComponent, View_ModifiersTabComponent_Host_0, { settings: "settings" }, {}, []);
export { ModifiersTabComponentNgFactory as ModifiersTabComponentNgFactory };
