import {FormBuilder, Validators} from '@angular/forms';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../../../util/form.utils';
import {TabSettingsDefinition} from '../../tab-settings.definition';
import {TextTranslationDefinition} from '../../../../../shared/components/text-input-with-language-flag/text-translation.definition';
import {Modify} from '../../../../../shared/ts-utils';
import {MeasureUnit} from 'orderly-web-components';
import RestaurantIngredientBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
import {TranslationUtils} from '../../../../../shared/shared.module';

export interface ModifierEditorFormDefinition {
  id: number | null;
  amount: number | null;
  basedOnIngredient: boolean;
  ingredient: RestaurantIngredientBasic | null;
  name: TextTranslationDefinition;
  isActive: boolean;
  measureUnit: MeasureUnit | null;
  priceDiff: number;

  nameTranslations: TextTranslationDefinition[];
}

export interface NewModifierEditorFormDefinition extends Modify<ModifierEditorFormDefinition,
  {
    basedOnIngredient: boolean | null,
    isActive: boolean | null,
    priceDiff: number | null,
  }> {
}

export function createDefaultModifierEditorFormDefinition(settings: TabSettingsDefinition): NewModifierEditorFormDefinition {

  const nameTranslations: TextTranslationDefinition[] = TranslationUtils.createDefaultTranslations(settings.menuTranslationLanguages);

  return {
    id: null,
    isActive: null,
    amount: null,
    measureUnit: null,
    ingredient: null,
    name: {
      text: '',
      langCode2: settings.primaryLanguage.code,
    },
    basedOnIngredient: null,
    priceDiff: null,
    nameTranslations,
  }
}

export function createModifierEditorFormDefinition(
  modifier: Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData.MenuItemModifier,
  settings: TabSettingsDefinition): ModifierEditorFormDefinition {

  const defaultNameTranslations: TextTranslationDefinition[] = TranslationUtils.createDefaultTranslations(settings.menuTranslationLanguages,
                                                                                                   {lang: settings.primaryLanguage, text: modifier.name});
  const actualNameTranslations: TextTranslationDefinition[] = modifier.translations.map(x => {
    return {
      langCode2: x.langCode2,
      text: x.name,
    }
  });
  const nameTranslations: TextTranslationDefinition[] = TranslationUtils.merge(defaultNameTranslations, actualNameTranslations);


  return {
    id: modifier.id,
    isActive: modifier.isActive,
    amount: modifier.amount,
    measureUnit: modifier.measureUnit,
    ingredient: modifier.ingredient,
    name: {
      text: modifier.name,
      langCode2: settings.primaryLanguage.code,
    },
    basedOnIngredient: modifier.ingredient != null,
    priceDiff: modifier.priceDiff,
    nameTranslations,
  };
}

export function createForm(formBuilder: FormBuilder): FormDefinition<keyof ModifierEditorFormDefinition> {
  const fieldsDef: FormFieldsDefinition<keyof ModifierEditorFormDefinition> = {
    id: new FormFieldDefinition(null, false, [], []),
    amount: new FormFieldDefinition(null, false, [Validators.min(1)], []),
    basedOnIngredient: new FormFieldDefinition(null, false, [Validators.required], []),
    ingredient: new FormFieldDefinition(null, false, [Validators.required], []),
    name: new FormFieldDefinition(null, false, [Validators.required], []),
    isActive: new FormFieldDefinition(null, false, [], []),
    measureUnit: new FormFieldDefinition(null, true, [Validators.required], []),
    priceDiff: new FormFieldDefinition(null, false, [Validators.required], []),
    nameTranslations: new FormFieldDefinition([], false,  [], []),
  };

  return new FormDefinition<keyof ModifierEditorFormDefinition>(fieldsDef, formBuilder);
}
