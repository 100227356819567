import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TablesListComponent} from './components/tables-list/tables-list.component';
import {TableQrCodeModalComponent} from './components/table-qr-code-modal/table-qr-code-modal.component';
import {AddTableModalComponent} from './components/add-table-modal/add-table-modal.component';
import {OrderlyWebComponentsModule} from 'orderly-web-components';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslationHttpLoaderFactory} from '../translations/translation-http-loader.factory';
import {HttpClient} from '@angular/common/http';
import {CustomMissingTranslationHandler} from '../translations/custom-missing-translation.handler';
import { AssignPregeneratedCodeModalComponent } from './components/assign-pregenerated-code-modal/assign-pregenerated-code-modal.component';


@NgModule({
            declarations: [
              TablesListComponent,
              TableQrCodeModalComponent,
              AddTableModalComponent,
              AssignPregeneratedCodeModalComponent,
            ],
            imports: [
              CommonModule,
              OrderlyWebComponentsModule,
              ReactiveFormsModule,
              TranslateModule,
              NgSelectModule,
              FormsModule,

              TranslateModule.forRoot({
                                        loader: {
                                          provide: TranslateLoader,
                                          useFactory: TranslationHttpLoaderFactory,
                                          deps: [HttpClient]
                                        },
                                        missingTranslationHandler: {
                                          provide: MissingTranslationHandler,
                                          useClass: CustomMissingTranslationHandler
                                        },
                                        isolate: true
                                      }),
            ],
            exports: [
              TablesListComponent
            ],
            entryComponents: [
              TableQrCodeModalComponent,
              AddTableModalComponent,
              AssignPregeneratedCodeModalComponent,
            ]
          })
export class TablesModule {}

export {TablesListComponent};
