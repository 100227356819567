import {RestaurantComponent} from '../restaurant.component';
import {
  PaginationFiltersStateDefinition,
  PaginationStateDefinition
} from 'orderly-web-components';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';
import {ActivatedRoute} from '@angular/router';
import {distinctUntilChanged, filter, first, map, tap} from 'rxjs/operators';
import {RestaurantSpecificArrayResultLoadingState} from '../../services/active-route-bound/helper.statuses';
import {OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {Moment} from 'moment';

export abstract class RestaurantRelatedItemsGrid<TItem, TSearchFilters extends PaginationFiltersStateDefinition>
  extends RestaurantComponent
  implements OnInit, OnDestroy {

  public static basicInitialFiltersState: PaginationFiltersStateDefinition = {itemsPerPage: 10, currentPage: 1};

  public readonly listIsLoading$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public readonly listIsLoaded$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public readonly listLoadingFailed$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public lastLoadedOn$: BehaviorSubject<Moment | null> = new BehaviorSubject<Moment | null>(null);

  private readonly filtersStateInternal$ = new ReplaySubject<TSearchFilters>(1);
  public get filtersState$(): Observable<TSearchFilters> {
    return this.filtersStateInternal$
               .pipe(
                 distinctUntilChanged(this.compareSearchFilters)
               );
  }

  private readonly paginationStateInternal$: ReplaySubject<PaginationStateDefinition> = new ReplaySubject<PaginationStateDefinition>(1);
  public get paginationState$(): Observable<PaginationStateDefinition> {
    return this.paginationStateInternal$
               .pipe(
                 distinctUntilChanged((x, y) => {
                   if (x.renderItems.length !== y.renderItems.length) {
                     return false;
                   }

                   if (x.itemsPerPage !== y.itemsPerPage ||
                       x.currentPage !== y.currentPage ||
                       x.totalPages !== y.totalPages) {
                     return false;
                   }

                   const uniqueElements = x.renderItems
                                           .concat(y.renderItems)
                                           .filter((v, i, a) => a.indexOf(v) === i);

                   return uniqueElements.length === x.renderItems.length &&
                          uniqueElements.length === y.renderItems.length;
                 })
               );
  }

  private readonly displayedGridItemsInternal$: ReplaySubject<TItem[]> = new ReplaySubject<TItem[]>(1);
  public get displayedGridItems$(): Observable<TItem[]> {
    return this.displayedGridItemsInternal$;
  }

  private readonly serviceLoadStateInternal$ = new ReplaySubject<RestaurantSpecificArrayResultLoadingState<TItem>>(1);

  protected preFilterServiceItems(items: TItem[]): TItem[] {
    return items;
  }

  protected compareSearchFilters(firstValue: TSearchFilters, secondValue: TSearchFilters): boolean {
    if (!environment.production) {
      const firstValueProps = Object.getOwnPropertyNames(firstValue);
      const secondValueProps = Object.getOwnPropertyNames(secondValue);

      if (firstValueProps.length > 2 || secondValueProps.length > 2) {
        console.log('Search filters are compared not correctly. Found more properties than expected:');
        console.log(firstValueProps);
      }
    }

    return firstValue.currentPage === secondValue.currentPage &&
           firstValue.itemsPerPage === secondValue.itemsPerPage;
  }

  protected constructor(store: Store<AppState>,
                        activatedRoute: ActivatedRoute,
                        initialFilterState: TSearchFilters,
                        serviceLoadState$: Observable<RestaurantSpecificArrayResultLoadingState<TItem>>) {
    super(store, activatedRoute);

    serviceLoadState$.subscribe(this.serviceLoadStateInternal$);

    this.filtersStateInternal$.next(initialFilterState);


    this.serviceLoadStateInternal$
        .pipe(
          map(x => {
            if (x.isLoadingOrCleared()) {
              return null;
            }

            return x.createdOn;
          })
        )
        .subscribe(this.lastLoadedOn$);
  }

  private calculatePaginationState(itemsToDisplay: TItem[], filtersState: TSearchFilters) {

    let totalPages = Math.floor(itemsToDisplay.length / filtersState.itemsPerPage);
    const reminder = itemsToDisplay.length % filtersState.itemsPerPage;

    if (reminder > 0) {
      totalPages++;
    }

    const renderPages: number[] = [];
    const startFromIndex = Math.max(filtersState.currentPage - 2, 1);
    const untilIndex = Math.min(filtersState.currentPage + 2, totalPages);

    for (let i = startFromIndex - 1; i < untilIndex; i++) {
      renderPages.push(i + 1);
    }

    const result: PaginationStateDefinition = {
      renderItems: renderPages,
      totalPages: totalPages,
      currentPage: filtersState.currentPage,
      itemsPerPage: filtersState.itemsPerPage
    };

    return result;
  }

  protected updateSearchFiltersState($event: TSearchFilters) {
    this.filtersStateInternal$
        .pipe(
          first(),
          tap(currentFiltersState => {

            // publish new state only if it differs from previous value
            if (!this.compareSearchFilters(currentFiltersState, $event)) {
              this.filtersStateInternal$.next($event);
            }
          })
        )
        .subscribe();
  }

  public pagingFiltersChanged($event: TSearchFilters) {
    this.filtersState$
        .pipe(
          first(),
          tap((currentState: TSearchFilters) => {
            const currentStateClone: TSearchFilters = {...currentState};

            currentStateClone.currentPage = $event.currentPage;
            currentStateClone.itemsPerPage = $event.itemsPerPage;

            // publish new state only if it differs from previous value
            if (!this.compareSearchFilters(currentState, currentStateClone)) {
              this.filtersStateInternal$.next(currentStateClone);
            }
          })
        ).subscribe();
  }

  ngOnInit(): void {
    const loadedState$ = this.serviceLoadStateInternal$
                             .pipe(
                               tap(state => {
                                 this.listIsLoading$.next(state.isLoadingOrCleared());
                                 this.listIsLoaded$.next(state.isLoaded());
                                 this.listLoadingFailed$.next(state.isFailed());
                               }),

                               // wait until data is loaded -> no reason to update pagination state for failed or not yet loaded states
                               filter(state => state.isLoaded())
                             );

    combineLatest([this.filtersState$, loadedState$])
      .pipe(
        map((data: [TSearchFilters, RestaurantSpecificArrayResultLoadingState<TItem>]) => {
          const fs = data[0];
          const loadedState = data[1];

          const prefilteredItems: TItem[] = this.preFilterServiceItems(loadedState.items);

          return {filterState: fs, prefilteredItems};
        }),
        map(x => {
          const allItemsAfterPrefiltering: TItem[] = x.prefilteredItems;
          const skipCount: number = x.filterState.itemsPerPage * (x.filterState.currentPage - 1);

          let start = skipCount;
          let end = skipCount + x.filterState.itemsPerPage;

          if (end > allItemsAfterPrefiltering.length) {
            end = allItemsAfterPrefiltering.length;
          }

          if (start >= end && end !== 0) {
            // This can happen, when, for example, we have 10 elements per page and 11 elements in total.
            // Then there is just one element on the second page
            // Then user deletes this single element on second page, as a result 'start' === 10 and 'end' === 10;

            // Best decision would be to show last page that precedes currently displayed empty page.

            // tslint:disable-next-line:no-bitwise
            const totalAvailablePagesCount = ~~(allItemsAfterPrefiltering.length / x.filterState.itemsPerPage);
            const itemsOnLastPageCount = allItemsAfterPrefiltering.length - allItemsAfterPrefiltering.length * totalAvailablePagesCount;

            if (itemsOnLastPageCount > 0) {
              start = allItemsAfterPrefiltering.length - itemsOnLastPageCount;
              end = allItemsAfterPrefiltering.length;
            } else {
              // go one page back
              start = allItemsAfterPrefiltering.length - x.filterState.itemsPerPage;
              end = allItemsAfterPrefiltering.length;
            }

            x.filterState.currentPage = totalAvailablePagesCount;
          }

          const visiblePageItems: TItem[] = allItemsAfterPrefiltering.slice(start, end);

          return {allItems: allItemsAfterPrefiltering, visiblePageItems, filterState: x.filterState };
        }),

        // update pagination state (i.e., how many pages are available for navigation)
        tap(x => {
          const newPaginationState = this.calculatePaginationState(x.allItems, x.filterState);

          this.paginationStateInternal$.next(newPaginationState);
        }),

        map(x => x.visiblePageItems)
      )
      .subscribe(this.displayedGridItemsInternal$);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.listIsLoading$.complete();
    this.listIsLoaded$.complete();
    this.listLoadingFailed$.complete();

    this.lastLoadedOn$.complete();

    this.filtersStateInternal$.complete();
    this.paginationStateInternal$.complete();
    this.displayedGridItemsInternal$.complete();
  }
}
