import {Component} from '@angular/core';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../util/form.utils';
import {PasswordRecoveryComponentFormFields} from './helper-classes';
import {FormBuilder, Validators} from '@angular/forms';
import {nameof} from '../../util/utils';
import {UserService} from '../../services/user.service';
import {catchError, finalize, tap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {NeverError, ToastService} from 'orderly-web-components';
import {TranslateService} from '@ngx-translate/core';
import {trnCheckInternetAndTryAgainLaterText, trnTryAgainLaterText} from '../../util/trn.utils';
import ResetPasswordResponse = Orderly.RestaurantWeb.Api.Messages.Authentication.ResetPasswordResponse;
import ResetPasswordRequest = Orderly.RestaurantWeb.Api.Messages.Authentication.ResetPasswordRequest;

@Component({
             selector: 'app-password-recovery',
             templateUrl: './password-recovery.component.html',
             styleUrls: ['./password-recovery.component.scss']
           })
export class PasswordRecoveryComponent {

  public actionInProgress: boolean = false;
  public requestedSuccessfully: boolean = false;

  public formDef: FormDefinition<keyof PasswordRecoveryComponentFormFields>;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private toastService: ToastService,
              private trnService: TranslateService) {
    const fieldsDef: FormFieldsDefinition<keyof PasswordRecoveryComponentFormFields> = {
      email: new FormFieldDefinition(null,
                                     false,
                                     [Validators.required, Validators.maxLength(50), Validators.email],
                                     [nameof<ResetPasswordRequest>('email')])
    };

    this.formDef = new FormDefinition<keyof PasswordRecoveryComponentFormFields>(fieldsDef, this.formBuilder);

  }

  requestRecovery() {
    if (this.formDef.form.invalid) {
      return;
    }

    const formValue: PasswordRecoveryComponentFormFields = this.formDef.form.getRawValue();

    this.actionInProgress = true;

    const fieldsDisableToken = this.formDef.disable('all-fields');

    const tryAgainLaterTxt = trnTryAgainLaterText(this.trnService);
    const errorTxt = this.trnService.instant('Failed to initiate password reset procedure because of unexpected failure.');
    const unexpectedFailureText = `${errorTxt} ${tryAgainLaterTxt}`;
    const currentLanguageCode2 = (this.trnService.currentLang || 'en').toLowerCase();

    this.userService
        .initiatePasswordReset(formValue.email, currentLanguageCode2)
        .pipe(
          tap((response: ResetPasswordResponse) => {
            switch (response.requestStatus) {
              case ResetPasswordResponse.RequestStatusCode.ValidationFailed:
                fieldsDisableToken.reenable();
                this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
                break;
              case ResetPasswordResponse.RequestStatusCode.Success:
                this.requestedSuccessfully = true;
                break;
              case ResetPasswordResponse.RequestStatusCode.UnknownError:
                this.toastService.showError(unexpectedFailureText);
                break;
              default:
                throw new NeverError(response.requestStatus);
            }
          }),
          catchError((err) => {
            if (err.status === 0) {
              const msg = trnCheckInternetAndTryAgainLaterText(this.trnService);

              this.toastService.showError(msg);
            } else {
              this.toastService.showError(unexpectedFailureText);
            }

            return throwError(err);
          }),
          finalize(() => {
            this.actionInProgress = false;
            fieldsDisableToken.reenable();
          })
        )
        .subscribe();
  }
}
