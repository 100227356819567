<div class="modal-header pd-y-20 pd-x-25">
  <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{'Change sorting order' | translate}}</h6>
  <button type="button" class="close" [attr.aria-label]="'Close' | translate" (click)="activeModal.dismiss(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pd-0-force categories-sorting-list-modal-body">

  <div cdkDropList
       [cdkDropListData]="categoriesList"
       class="categories-sorting-list"
       (cdkDropListDropped)="drop($event)">

    <div class="categories-list-item" *ngFor="let category of categoriesList" cdkDrag>
      <i class="icon-angle-right"></i> {{category.name}}
    </div>
  </div>

</div>
<div class="modal-footer tx-white">
  <owc-progress-action-button [actionInProgress]="actionInProgress"
                              [disabled]="false"
                              (execute)="save()"
                              [btnText]="'Save' | translate"
                              type=link>
  </owc-progress-action-button>
</div>
