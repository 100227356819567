import {FormBuilder, Validators} from '@angular/forms';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../../../util/form.utils';
import {TabSettingsDefinition} from '../../tab-settings.definition';
import MenuItemModifierGroupWithModifiers = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupWithModifiers;
import {TranslationUtils} from '../../../../../shared/shared.module';
import {TextTranslationDefinition} from '../../../../../shared/components/text-input-with-language-flag/text-translation.definition';
import {TranslationCommonValidators} from '../../../../../translations/validators/translation-common-validators';
import {
  greaterThanOrEqualValidator,
  positiveOrZeroIntegerValidator,
  requiredIfSelectedValidator
} from 'orderly-web-components';
import {nameof} from '../../../../../../../util/utils';
import {TranslateService} from '@ngx-translate/core';

export interface ModifierGroupEditorFormFieldsDefinition {
  id: number | null;
  name: TextTranslationDefinition;
  minAllowedAmount: number;
  maxAllowedAmount: number | null;
  isOnlyOnePermitted: boolean;
  isActive: boolean;
  nameTranslations: TextTranslationDefinition[];
}

export function createDefaultModifierGroupEditorFormFieldsDefinition(settings: TabSettingsDefinition): ModifierGroupEditorFormFieldsDefinition {

  const nameTranslations: TextTranslationDefinition[] = TranslationUtils.createDefaultTranslations(settings.menuTranslationLanguages);

  return {
    name: {
      langCode2: settings.primaryLanguage.code,
      text: '',
    },
    id: null,
    isActive: true,
    maxAllowedAmount: 1,
    minAllowedAmount: 1,
    isOnlyOnePermitted: true,
    nameTranslations,
  };
}

export function createModifierGroupEditorFormFieldsDefinition(group: MenuItemModifierGroupWithModifiers,
                                                              settings: TabSettingsDefinition): ModifierGroupEditorFormFieldsDefinition {

  const defaultNameTranslations: TextTranslationDefinition[] = TranslationUtils.createDefaultTranslations(settings.menuTranslationLanguages,
                                                                                                          {lang: settings.primaryLanguage, text: group.name});
  const actualNameTranslations: TextTranslationDefinition[] = group.translations.map(x => {
    return {
      langCode2: x.langCode2,
      text: x.name,
    }
  });
  const nameTranslations: TextTranslationDefinition[] = TranslationUtils.merge(defaultNameTranslations, actualNameTranslations);

  return {
    name: {
      langCode2: settings.primaryLanguage.code,
      text: group.name,
    },
    id: group.id,
    isActive: group.isActive,
    maxAllowedAmount: group.maxAllowedAmount,
    minAllowedAmount: group.minAllowedAmount,
    isOnlyOnePermitted: group.minAllowedAmount === 1 && group.maxAllowedAmount === 1,
    nameTranslations,
  };
}

export function createForm(formBuilder: FormBuilder, trnService: TranslateService): FormDefinition<keyof ModifierGroupEditorFormFieldsDefinition> {
  const fieldsDef: FormFieldsDefinition<keyof ModifierGroupEditorFormFieldsDefinition> = {
    id: new FormFieldDefinition(null, false, [], []),
    name: new FormFieldDefinition(null,
                                  false,
                                  [
                                    TranslationCommonValidators.isNotNullOrEmptyOrWhiteSpaceValidator,
                                    TranslationCommonValidators.maxLength(50)
                                  ],
                                  []),
    isActive: new FormFieldDefinition(true,
                                      false,
                                      [Validators.required],
                                      []),
    isOnlyOnePermitted: new FormFieldDefinition(true,
                                                false,
                                                [Validators.required],
                                                []),
    minAllowedAmount: new FormFieldDefinition(null,
                                              false,
                                              [
                                                positiveOrZeroIntegerValidator
                                              ],
                                              []),
    maxAllowedAmount: new FormFieldDefinition(null,
                                              false,
                                              [
                                                positiveOrZeroIntegerValidator
                                              ],
                                              []),
    nameTranslations: new FormFieldDefinition([], false,  [], []),
  };

  const minAllowedAmountCtrlName = nameof<ModifierGroupEditorFormFieldsDefinition>('minAllowedAmount');
  const maxAllowedAmountCtrlName = nameof<ModifierGroupEditorFormFieldsDefinition>('maxAllowedAmount');
  const isOnlyOnePermittedCtrlName = nameof<ModifierGroupEditorFormFieldsDefinition>('isOnlyOnePermitted');

  const minSelectableElementsCtrlName = trnService.instant('Min selectable elements');
  const greaterThanValidatorErrorMessage = trnService.instant('Value must be greater than "{{otherFieldName}}"',
                                                              {otherFieldName: minSelectableElementsCtrlName});

  const formOpts = {
    // use asyncValidators to get rid of 'trnService.instant' and use async version of translations
    validators: [
      greaterThanOrEqualValidator(minAllowedAmountCtrlName, maxAllowedAmountCtrlName, greaterThanValidatorErrorMessage),
      requiredIfSelectedValidator(minAllowedAmountCtrlName, {
        radioOrCheckboxControlName: isOnlyOnePermittedCtrlName,
        selectedValue: false
      })
    ],
  };

  return new FormDefinition<keyof ModifierGroupEditorFormFieldsDefinition>(fieldsDef, formBuilder, formOpts);
}
