import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import { ActionTypes } from '../actions/action-types';
import { map, switchMap } from 'rxjs/operators';
export class AreaEffect {
    constructor(actions, areaService) {
        this.actions = actions;
        this.areaService = areaService;
        this.creatAreaEffect = this.actions.pipe(ofType(ActionTypes.AREA_CREATE_SUCCESSFUL), switchMap((action) => {
            return this.areaService
                .forceReload()
                .pipe(map(x => action));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false })
], AreaEffect.prototype, "creatAreaEffect", void 0);
