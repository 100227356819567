/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./image-preview.component";
import * as i4 from "@angular/platform-browser";
var styles_ImagePreviewComponent = [i0.styles];
var RenderType_ImagePreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImagePreviewComponent, data: {} });
export { RenderType_ImagePreviewComponent as RenderType_ImagePreviewComponent };
function View_ImagePreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "preview"], ["class", "img-fluid category-image-preview"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf.thumb.url; _ck(_v, 0, 0, currVal_0); }); }
function View_ImagePreviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "preview"], ["class", "img-fluid category-image-preview"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.domSanitizationService.bypassSecurityTrustUrl((_v.context.ngIf.cropPositionContentBase64 || _v.context.ngIf.contentBase64)); _ck(_v, 0, 0, currVal_0); }); }
function View_ImagePreviewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "preview"], ["class", "img-fluid rounded-circle category-image-preview"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf.thumb.url; _ck(_v, 0, 0, currVal_0); }); }
function View_ImagePreviewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "preview"], ["class", "img-fluid rounded-circle category-image-preview"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.domSanitizationService.bypassSecurityTrustUrl((_v.context.ngIf.cropPositionContentBase64 || _v.context.ngIf.contentBase64)); _ck(_v, 0, 0, currVal_0); }); }
function View_ImagePreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagePreviewComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagePreviewComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagePreviewComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagePreviewComponent_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.asCategoryImageFromServerModel; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.asLocalCategoryImageModel; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.asCategoryImageFromServerModel; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.asLocalCategoryImageModel; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_ImagePreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagePreviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.image != null); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ImagePreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-preview", [], null, null, null, View_ImagePreviewComponent_0, RenderType_ImagePreviewComponent)), i1.ɵdid(1, 49152, null, 0, i3.ImagePreviewComponent, [i4.DomSanitizer], null, null)], null, null); }
var ImagePreviewComponentNgFactory = i1.ɵccf("app-image-preview", i3.ImagePreviewComponent, View_ImagePreviewComponent_Host_0, { image: "image" }, {}, []);
export { ImagePreviewComponentNgFactory as ImagePreviewComponentNgFactory };
