<div class="col-6">
  <div class="ingredients-list-table div-based-table table-dark table-colored">
    <div class="thead">
      <div class="row">
        <div class="col-11 title">{{'Ingredient categories' | translate}}</div>
        <div class="col-1 actions">
          <a class="btn btn-sm btn-outline-secondary float-right" (click)="refreshIngredientCategoriesList()">
            <i class="icon-arrows-cw fa-15x" [class.animate-spin]="listIsLoading$ | async"></i>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-8">{{'Name' | translate}}</div>
        <div class="col-4"></div>
      </div>
    </div>
    <div class="tbody">
      <ng-container *ngIf="listIsLoaded$ | async">
        <ng-container *ngIf="displayedGridItems$ | async as categories">
          <div class="row" *ngFor="let category of categories" style="word-wrap: break-word;">
            <div class="col-8">
              <a (click)="updateCategoryIngredient(category)">{{ category.name }}</a>
            </div>
            <div class="col-4">
              <ng-container *ngIf="userIsManager | async">
                <a class="btn btn-sm btn-teal text-white mg-x-1" (click)="updateCategoryIngredient(category)">{{'Update' | translate}}</a>
                <a class="btn btn-sm btn-danger text-white mg-x-1" (click)="deleteCategoryIngredient(category)">{{'Delete' | translate}}</a>
              </ng-container>
            </div>
          </div>
          <div class="row" *ngIf="categories.length === 0">
            <div class="col-12 text-center">
              {{'There are no ingredient categories' | translate}}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="row" *ngIf="listIsLoading$ | async">
        <div class="ht-60 pos-relative align-items-center col-12">
          <owc-progress-wave></owc-progress-wave>
        </div>
      </div>

      <div class="row" *ngIf="listLoadingFailed$ | async">
        <div class="ht-50 pos-relative align-items-center col-12">
          <p class="loading-failed-text">{{'Could not load ingredients. Check if you have Internet connection and try again.' | translate}}</p>
        </div>
      </div>
    </div>

    <div class="tfoot">
      <div class="row">
        <div class="col-4">
          <ng-container *ngIf="userIsManager | async">
            <a class="new-element-btn" [class.disabled]="listIsLoading$ | async" (click)="addIngredientCategory()">
              <i class="icon-plus"></i>{{'Add ingredient category' | translate}}
            </a>
          </ng-container>
        </div>
        <div class="col-8 bd-l-0">
          <owc-pagination-block [visible$]="listIsLoaded$"
                                (filtersChanged)="pagingFiltersChanged($event)"
                                [disable$]="listLoadingFailed$"
                                [state$]="paginationState$">
          </owc-pagination-block>
        </div>
      </div>
    </div>
  </div>
</div>
