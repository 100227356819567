import { BehaviorSubject, EMPTY, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { selectRestaurantIdParamFromAppState } from '../../app/store/selectors/restaurant-selectors';
import { createFailedResult, createNotStartedResult, createSuccessResult, CurrentRestaurantServiceBase } from './current-restaurant.service.base';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
export class CurrentRestaurantTableCheckinsService {
    constructor(httpClient, store) {
        this.httpClient = httpClient;
        this.store = store;
        this.baseURL = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
        this.restaurantIdRouteParam$ = this.store.pipe(selectRestaurantIdParamFromAppState);
        const notStartedResult = createNotStartedResult();
        this.cache$ = new BehaviorSubject(notStartedResult);
        this.restaurantIdRouteParam$
            .pipe(skipWhile(restaurantId => this.cache$.value.isNotStarted()), distinctUntilChanged((x, y) => x === y), tap(restaurantId => this.pushLoadingOrClearedState(restaurantId)), filter(restaurantId => restaurantId != null), switchMap((restaurantId) => this.loadDataAndConvertToLoadState(restaurantId, new Date())))
            .subscribe(this.cache$);
    }
    get currentRestaurantTableCheckins$() {
        return this.cache$;
    }
    doLoadData(restaurantId, checkinDate) {
        const request = {
            date: checkinDate,
            topRecords: 100,
        };
        const url = `${this.baseURL}/restaurant/${restaurantId}/checkins/list`;
        const response$ = this.httpClient.post(url, request);
        return response$.pipe(tap(x => {
            if (x.status === 3 /* UnknownFailure */) {
                throw new Error('Server returned an error. Could not load list of COVID checkins');
            }
        }), map(x => x.items));
    }
    forceReload(checkinDate) {
        if (this.cache$.value.isLoading()) {
            return EMPTY;
        }
        const result = new Subject();
        this.restaurantIdRouteParam$.pipe(
        // NOTE: first is important! it will cancel this subscription after result is loaded
        first(), tap(restaurantId => this.pushLoadingOrClearedState(restaurantId)), filter(restaurantId => restaurantId != null), switchMap((restaurantId) => this.loadDataAndConvertToLoadState(restaurantId, checkinDate)), tap(newState => this.cache$.next(newState))).subscribe(result);
        return result;
    }
    downloadCheckinAsPdf(id) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/checkin/${id}/as-pdf`;
            return this.httpClient.post(url, {}, { responseType: 'blob' });
        }));
    }
    loadDataAndConvertToLoadState(restaurantId, checkinDate) {
        return this.doLoadData(restaurantId, checkinDate)
            .pipe(map(data => createSuccessResult(data)), catchError((err) => of(createFailedResult(err))));
    }
    pushLoadingOrClearedState(restaurantId) {
        if (restaurantId == null) {
            this.cache$.next(CurrentRestaurantServiceBase.clearedResult);
        }
        else {
            this.cache$.next(CurrentRestaurantServiceBase.loadingResult);
        }
    }
}
CurrentRestaurantTableCheckinsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentRestaurantTableCheckinsService_Factory() { return new CurrentRestaurantTableCheckinsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: CurrentRestaurantTableCheckinsService, providedIn: "root" });
