import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';

import {AppComponent} from './app.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LoginPageComponent} from './login-page/login-page.component';
import {AuthenticatedUserComponent} from './authenticated-user/authenticated-user.component';
import {RegistrationComponent} from './registration/registration.component';
import {HoldingDashboardComponent} from './holding-dashboard/holding-dashboard.component';
import {IngredientListComponent} from './dashboard/ingredient-list/ingredient-list.component';
import {DisplaysListComponent} from './dashboard/displays-list/displays-list.component';
import {AddUserModalComponent} from './dashboard/restaurant-users-list/add-user-modal/add-user-modal.component';
import {RestaurantUsersListComponent} from './dashboard/restaurant-users-list/restaurant-users-list.component';
import {AddOrEditIngredientComponent} from './add-or-edit-ingredient/add-or-edit-ingredient.component';
import {MenuItemsListComponent} from './dashboard/menu-items-list/menu-items-list.component';

import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {
  ConfirmationModalDialogComponent,
  OrderlyWebComponentsModule,
  StaticDataService,
  UpdateUserProfileComponent,
  ChangePasswordComponent
} from 'orderly-web-components';
import {
  NgbModalModule,
  NgbToastModule,
  NgbDropdownModule,
  NgbTabsetModule,
  NgbTooltipModule,
  NgbDatepickerModule,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {HttpInterceptorModule} from '../interceptors/http-interceptor.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {routerReducer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';

import {EffectsModule} from '@ngrx/effects';
import {LoginLogoutEffect} from './store/effects/user.effect';
import {RestaurantEffect} from './store/effects/restaurant.effect';
import {WaiterEffect} from './store/effects/waiter.effect';
import {TableEffect} from './store/effects/table.effect';

import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {AppState} from './store/app.state';
import {userDataReducer} from './store/reducers/user-data.reducer';
import {leftMenuReducer} from './store/reducers/left-menu.reducer';
import {ConfirmAccountComponent} from './registration/confirm-account/confirm-account.component';
import {RestaurantDashboardContainerComponent} from './restaurant-dashboard-container/restaurant-dashboard-container.component';
import {AddOrEditMenuItemIngredientComponent} from './add-or-edit-menu-item-ingredient/add-or-edit-menu-item-ingredient.component';
import {AddOrEditMenuItemModifierGroupComponent} from './add-or-edit-menu-item-modifier-group/add-or-edit-menu-item-modifier-group.component';
import {AddOrEditMenuItemModifierComponent} from './add-or-edit-menu-item-modifier/add-or-edit-menu-item-modifier.component';
import {RestaurantCardComponent} from './holding-dashboard/restaurant-card/restaurant-card.component';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
} from '@ngx-translate/core';
import {LocalizationEffect} from './store/effects/localization.effect';
import {AppInitEffect} from './store/effects/app-init.effect';
import {AddOrEditIngredientCategoryComponent} from './add-or-edit-ingredient-category/add-or-edit-ingredient-category.component';
import {IngredientCategoriesListComponent} from './dashboard/ingredient-categories-list/ingredient-categories-list.component';
import {ReportBrokenDisplayModalComponent} from './report-broken-display-modal/report-broken-display-modal.component';
import {AssignDisplayToTableModalComponent} from './assign-display-to-table-modal/assign-display-to-table-modal.component';
import {RequestedTableActionsListComponent} from './dashboard/requested-table-actions-list/requested-table-actions-list.component';
import {AreasListComponent} from './dashboard/areas-list/areas-list.component';
import {AddOrEditAreaModalComponent} from './dashboard/add-or-edit-area-modal/add-or-edit-area-modal.component';
import {FirstStepsWizardComponent} from './first-steps-wizard/first-steps-wizard.component';
import {IngredientCategoryEffect} from './store/effects/ingredient-category.effect';
import {InputNumberModule} from '@deividfortuna/input-number';
import {PasswordRecoveryComponent} from './password-recovery/password-recovery.component';
import {CompletePasswordRecoveryComponent} from './password-recovery/complete-password-recovery/complete-password-recovery.component';
import {TopBarWithLanguageSwitcherComponent} from './top-bar-with-language-switcher/top-bar-with-language-switcher.component';
import {TerminalsListComponent} from './dashboard/terminals-list/terminals-list.component';
import {ReviewsListComponent} from './dashboard/reviews-list/reviews-list.component';
import {AddOrEditTerminalModalComponent} from './dashboard/terminals-list/add-or-edit-terminal-modal/add-or-edit-terminal-modal.component';
import {SetPinModalComponent} from './dashboard/terminals-list/set-pin-modal/set-pin-modal.component';
import {NgbDateCustomParserFormatter} from '../services/ngb-date-custom-parser-formatter';
import {StartRatingComponent} from './dashboard/reviews-list/start-rating/start-rating.component';
import {TerminalsAndDisplaysListComponent} from './dashboard/terminals-and-displays-list/terminals-and-displays-list.component';
import {AreaEffect} from './store/effects/area.effects';
import {JoinMenuItemCategoryNamesPipe} from '../pipes/join-menu-item-category-names.pipe';
import {EnabledDisabledTextPipe} from '../pipes/enabled-disabled-text.pipe';
import {MenuButtonActionDefTextPipe} from '../pipes/menu-button-action-def-text.pipe';
import {JoinRestaurantRolesPipe} from '../pipes/join-restaurant-roles.pipe';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CropImageModalComponent} from './crop-image-modal/crop-image-modal.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ModifierGroupRowComponent} from './dashboard/menu-items-list/modifier-group-row/modifier-group-row.component';
import {ModifierGroupsTableComponent} from './dashboard/menu-items-list/modifier-groups-table/modifier-groups-table.component';
import {CovidCheckinsListComponent} from './dashboard/covid-checkins-list/covid-checkins-list.component';
import {RegistrationSimpleComponent} from './registration/registration-simple.component';
import {RegistrationWithMenuComponent} from './registration/registration-with-menu.component';
import {ImagePreviewModalComponent} from './image-preview-modal/image-preview-modal.component';
import {MenuItemsModule} from './modules/menu-items/menu-items.module';
import {
  CustomMissingTranslationHandler,
  TranslationHttpLoaderFactory
} from './modules/translations/translations.module';
import {SharedModule} from './modules/shared/shared.module';
import {TablesModule} from './modules/tables/tables.module';
import {RestaurantsModule} from './modules/restaurants/restaurants.module';
import {MenuItemCategoriesModule} from './modules/menu-item-categories/menu-item-categories.module';

registerLocaleData(localeDe);

export const reducers: ActionReducerMap<AppState> = {
  auth: userDataReducer,
  leftMenu: leftMenuReducer,
  router: routerReducer
};

@NgModule({
            declarations: [
              AppComponent,
              DashboardComponent,
              LoginPageComponent,
              AuthenticatedUserComponent,
              RegistrationComponent,
              HoldingDashboardComponent,
              IngredientListComponent,
              DisplaysListComponent,
              RestaurantUsersListComponent,
              AddUserModalComponent,
              AddOrEditIngredientComponent,
              MenuItemsListComponent,
              ConfirmAccountComponent,
              AddOrEditIngredientComponent,
              RestaurantDashboardContainerComponent,
              JoinMenuItemCategoryNamesPipe,
              JoinRestaurantRolesPipe,
              EnabledDisabledTextPipe,
              MenuButtonActionDefTextPipe,
              AddOrEditMenuItemIngredientComponent,
              AddOrEditMenuItemModifierGroupComponent,
              AddOrEditMenuItemModifierComponent,
              RestaurantCardComponent,
              AddOrEditIngredientCategoryComponent,
              IngredientCategoriesListComponent,
              ReportBrokenDisplayModalComponent,
              AssignDisplayToTableModalComponent,
              RequestedTableActionsListComponent,
              AreasListComponent,
              AddOrEditAreaModalComponent,
              FirstStepsWizardComponent,
              PasswordRecoveryComponent,
              CompletePasswordRecoveryComponent,
              TopBarWithLanguageSwitcherComponent,
              TerminalsListComponent,
              ReviewsListComponent,
              AddOrEditTerminalModalComponent,
              SetPinModalComponent,
              StartRatingComponent,
              TerminalsAndDisplaysListComponent,
              CropImageModalComponent,
              ModifierGroupRowComponent,
              ModifierGroupsTableComponent,
              CovidCheckinsListComponent,
              RegistrationSimpleComponent,
              RegistrationWithMenuComponent,
              ImagePreviewModalComponent,
            ],
            imports: [
              BrowserModule,
              AppRoutingModule,
              OrderlyWebComponentsModule,
              NgbModalModule,
              NgbToastModule,
              NgbDropdownModule,
              NgbTabsetModule,
              NgbDatepickerModule,
              HttpClientModule,
              HttpInterceptorModule,
              FormsModule,
              ReactiveFormsModule,
              NgSelectModule,

              DragDropModule,

              StoreRouterConnectingModule.forRoot(),
              StoreModule.forRoot(reducers),
              StoreDevtoolsModule.instrument({
                                               maxAge: 30,
                                               logOnly: environment.production
                                             }),
              EffectsModule.forRoot([
                                      LoginLogoutEffect, RestaurantEffect, WaiterEffect, TableEffect,
                                      IngredientCategoryEffect, LocalizationEffect, AreaEffect,
                                      AppInitEffect,
                                    ]),
              TranslateModule.forRoot({
                                        loader: {
                                          provide: TranslateLoader,
                                          useFactory: TranslationHttpLoaderFactory,
                                          deps: [HttpClient]
                                        },
                                        missingTranslationHandler: {
                                          provide: MissingTranslationHandler,
                                          useClass: CustomMissingTranslationHandler
                                        },
                                        isolate: true
                                      }),
              NgbTooltipModule,
              InputNumberModule,
              ImageCropperModule,

              MenuItemsModule,
              TablesModule,
              SharedModule,
              RestaurantsModule,
              MenuItemCategoriesModule,
            ],
            providers: [
              {provide: APP_INITIALIZER, useFactory: init_app, deps: [StaticDataService], multi: true},
              {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
            ],
            bootstrap: [AppComponent],
            entryComponents: [
              UpdateUserProfileComponent,
              ChangePasswordComponent,
              AddUserModalComponent,
              ConfirmationModalDialogComponent,
              AddOrEditIngredientComponent,
              AddOrEditMenuItemIngredientComponent,
              AddOrEditMenuItemModifierGroupComponent,
              AddOrEditMenuItemModifierComponent,
              AddOrEditIngredientCategoryComponent,
              ReportBrokenDisplayModalComponent,
              AssignDisplayToTableModalComponent,
              AddOrEditAreaModalComponent,
              AddOrEditTerminalModalComponent,
              SetPinModalComponent,
              CropImageModalComponent,
              ImagePreviewModalComponent,
            ]
          })
export class AppModule {}

export function init_app(staticDataService: StaticDataService) {
  return () => staticDataService.getAllStaticData(environment.baseApiUrlWithTrailingSlash);
}
