<div class="row" *ngIf="image != null">
  <div class="col-6">
    <img *ngIf="asCategoryImageFromServerModel as serverImg"
         [src]="serverImg.thumb.url"
         class="img-fluid category-image-preview"
         alt="preview">

    <img *ngIf="asLocalCategoryImageModel as localImg"
         [src]="domSanitizationService.bypassSecurityTrustUrl(localImg.cropPositionContentBase64 || localImg.contentBase64)"
         class="img-fluid category-image-preview"
         alt="preview">
  </div>

  <div class="col-6">
    <img *ngIf="asCategoryImageFromServerModel as serverImg"
         [src]="serverImg.thumb.url"
         class="img-fluid rounded-circle category-image-preview"
         alt="preview">

    <img *ngIf="asLocalCategoryImageModel as localImg"
         [src]="domSanitizationService.bypassSecurityTrustUrl(localImg.cropPositionContentBase64 || localImg.contentBase64)"
         class="img-fluid rounded-circle category-image-preview"
         alt="preview">
  </div>
</div>
