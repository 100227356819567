import MenuItemCategoryBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemCategoryBasic;
import {MenuItemImageNewOrExisting} from '../form-fields-definitions';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../../util/form.utils';
import {FormBuilder, Validators} from '@angular/forms';
import {
  createNewPriceEditorFormFieldsDefinition, createPriceEditorFormFieldsDefinition,
  NewPriceEditorFormFieldsDefinition,
  PriceEditorFormFieldsDefinition
} from './price-editor/price-editor.form-fields-definition';
import {TextTranslationDefinition} from '../../../../shared/components/text-input-with-language-flag/text-translation.definition';
import {TranslationCommonValidators} from '../../../../translations/validators/translation-common-validators';
import {Modify} from '../../../../shared/ts-utils';
import {TabSettingsDefinition} from '../tab-settings.definition';
import MenuItemWithAllData = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData;

export interface DetailsTabFormFieldsDefinition {
  name: TextTranslationDefinition;
  description: TextTranslationDefinition;
  category: MenuItemCategoryBasic[] | MenuItemCategoryBasic;
  isActive: boolean;
  images: MenuItemImageNewOrExisting[];
  price: PriceEditorFormFieldsDefinition;
}

export interface NewDetailsTabFormFieldsDefinition extends Modify<DetailsTabFormFieldsDefinition,
  {
    isActive: boolean | null,
    price: NewPriceEditorFormFieldsDefinition
  }
  > {}

export function createForm(formBuilder: FormBuilder): FormDefinition<keyof DetailsTabFormFieldsDefinition> {
  const fieldsDef: FormFieldsDefinition<keyof DetailsTabFormFieldsDefinition> = {
    name: new FormFieldDefinition(null,
                                  false,
                                  [
                                    TranslationCommonValidators.isNotNullOrEmptyOrWhiteSpaceValidator,
                                    TranslationCommonValidators.maxLength(80)
                                  ],
                                  []),
    description: new FormFieldDefinition(null,
                                         false,
                                         [Validators.maxLength(550)],
                                         []),
    category: new FormFieldDefinition(null,
                                      false,
                                      [Validators.required],
                                      []),
    isActive: new FormFieldDefinition(true,
                                      false,
                                      [Validators.required],
                                      []),
    images: new FormFieldDefinition([],
                                    false,
                                    [],
                                    []),
    price: new FormFieldDefinition(null,
                                      false,
                                      [Validators.required],
                                      []),
  };


  return new FormDefinition(fieldsDef, formBuilder);
}

export function createNewDetailsTabFormFieldsDefinition(settings: TabSettingsDefinition): NewDetailsTabFormFieldsDefinition {
  const price: NewPriceEditorFormFieldsDefinition = createNewPriceEditorFormFieldsDefinition(settings.currency);

  return {
    name: {
      text: '',
      langCode2: settings.primaryLanguage.code
    },
    description: {
      text: '',
      langCode2: settings.primaryLanguage.code
    },
    isActive: true,
    category: [],
    images: [],
    price,
  }
}

export function createDetailsTabFormFieldsDefinition(settings: TabSettingsDefinition, existingMenuItem: MenuItemWithAllData): DetailsTabFormFieldsDefinition {
  const price = createPriceEditorFormFieldsDefinition(existingMenuItem);

  return {
    name: {
      text: existingMenuItem.name,
      langCode2: settings.primaryLanguage.code
    },
    description: {
      text: existingMenuItem.description || '',
      langCode2: settings.primaryLanguage.code
    },
    isActive: existingMenuItem.isActive,
    category: existingMenuItem.categories[0],
    images: existingMenuItem.images,
    price,
  }
}
