import { map, switchMap } from 'rxjs/operators';
import { CurrentRestaurantServiceBase } from './current-restaurant.service.base';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../ingredients.service";
import * as i2 from "@angular/common/http";
import * as i3 from "@ngrx/store";
export class CurrentRestaurantIngredientCategoriesService extends CurrentRestaurantServiceBase {
    constructor(ingredientsService, httpClient, store) {
        super(store);
        this.ingredientsService = ingredientsService;
        this.httpClient = httpClient;
        this.baseURL = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
    }
    get currentRestaurantCategories$() {
        return this.dataFeed$;
    }
    doLoadData(restaurantId) {
        return this.ingredientsService.getAllIngredientCategories(restaurantId).pipe(map(x => x.categories));
    }
    add(request) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap(restaurantId => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/ingredient-category/add`;
            const result = this.httpClient.post(url, request);
            return result;
        }));
    }
    update(data) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap(restaurantId => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/ingredient-category/${data.id}/update`;
            const result = this.httpClient.post(url, data);
            return result;
        }));
    }
    delete(ingredientCategoryId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap(restaurantId => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/ingredient-category/${ingredientCategoryId}/delete`;
            const result = this.httpClient.delete(url);
            return result;
        }));
    }
}
CurrentRestaurantIngredientCategoriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentRestaurantIngredientCategoriesService_Factory() { return new CurrentRestaurantIngredientCategoriesService(i0.ɵɵinject(i1.IngredientsService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Store)); }, token: CurrentRestaurantIngredientCategoriesService, providedIn: "root" });
