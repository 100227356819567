import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {ActionTypes} from '../actions/action-types';
import {filter, first, map, switchMap, tap} from 'rxjs/operators';
import {TableCreationSuccessfulAction, TableDeletionSuccessfulAction} from '../actions/table.actions';
import {CurrentRestaurantTablesService} from '../../../services/active-route-bound/current-restaurant-tables.service';


@Injectable()
export class TableEffect {

  constructor(private readonly actions: Actions,
              private readonly tableService: CurrentRestaurantTablesService) {
  }

  @Effect({dispatch: false})
  createTableEffect: Observable<Action> = this.actions.pipe(
    ofType<TableCreationSuccessfulAction>(ActionTypes.TABLE_CREATE_SUCCESSFUL),

    switchMap((action: TableCreationSuccessfulAction) => {

      return this.tableService
                 .forceReload()
                 .pipe(
                   map(x => action)
                 );
    })
  );

  @Effect({dispatch: false})
  deleteWaiterEffect: Observable<Action> = this.actions.pipe(
    ofType<TableDeletionSuccessfulAction>(ActionTypes.TABLE_DELETE_SUCCESSFUL),

    switchMap((action: TableDeletionSuccessfulAction) => {
      return this.tableService
                 .currentRestaurantTables$
                 .pipe(
                   first(),
                   filter(x => x.isLoaded()),
                   tap(loadedState => {
                     const notDeletedTables = loadedState.items.filter(tbl => tbl.id !== action.tableId);

                     this.tableService.setValue(notDeletedTables);
                   }),
                   map(x => action)
                 );
    })
  );
}
