import {LeftMenuState} from '../../app.state';
import {CurrentUserInformationLoadedAction} from '../../actions/user.actions';
import {createLeftMenuStateFromUserInfo, equalsMenuItemDataPayload} from './helper.functions';

export function userInformationLoadedActionHandler(currentState: LeftMenuState,
                                                   action: CurrentUserInformationLoadedAction) {

  const newState = createLeftMenuStateFromUserInfo(action.user.holdingInfo, action.user.associatedRestaurants);

  newState.activeItemId = currentState.activeItemId;

  if (currentState.activeItemId != null) {
    let foundActiveNode: boolean = false;

    for (let i = 0; i < newState.items.length; i++) {
      const rootNode = newState.items[i];

      if (equalsMenuItemDataPayload(rootNode.id, currentState.activeItemId)) {
        foundActiveNode = true;

        break;
      }

      for (let j = 0; j < rootNode.subItems.length; j++) {
        const subNode = rootNode.subItems[j];

        if (equalsMenuItemDataPayload(subNode.id, currentState.activeItemId)) {
          foundActiveNode = true;

          break;
        }
      }

      if (foundActiveNode) {
        break;
      }
    }

    if (!foundActiveNode && newState.items.length > 0) {
      newState.activeItemId = newState.items[0].id;
    }
  }

  return newState;
}
