<div class="editable-ingredients-list-table div-based-table table-dark table-colored">
  <div class="thead">
    <div class="row block-title">
      <div class="col-12 title">{{'Ingredients' | translate}}</div>
    </div>
    <div class="row">
      <div class="col-3">{{'Name' | translate}}</div>
      <div class="col-xs-1 col-lg-2">{{'Amount' | translate}}</div>
      <div class="col-xs-2 col-lg-1">{{'Measure unit' | translate}}</div>
      <div class="col-xs-2 col-lg-1">{{'Contains alcohol' | translate}}</div>
      <div class="col-xs-3 col-lg-4">{{'Allergens' | translate}}</div>
      <div class="col-1"></div>
    </div>
  </div>
  <div class="tbody">
    <ng-container *ngFor="let ingredient of ingredients.controls; let i=index">
      <div class="row" [formGroup]="ingredient">
        <ng-container *ngIf="ingredient.get('ingredient').value as val">
          <div class="col-3">
            <div>{{val.name}}</div>
          </div>
          <div class="col-xs-1 col-lg-2 p-0">
            <input class="form-control"
                   formControlName="amount"
                   type="text"
                   maxlength="10"
                   inputNumber
                   [acceptDecimalPlaces]="true"
                   [decimalPlaces]="3" />
          </div>
          <div class="col-xs-2 col-lg-1">
            <div>{{val.measureUnit.name}}</div>
          </div>
          <div class="col-xs-2 col-lg-1">
            <i *ngIf="val.containsAlcohol" class="icon-alert"></i>
          </div>
          <div class="col-xs-3 col-lg-4 pd-xs-l-5-force pd-xs-r-5-force pd-lg-l-10-force pd-lg-r-10-force">
            <div *ngFor="let allg of val.allergens; let allgIdx=index" class="badge badge-warning" [ngbTooltip]="allg.description">
              {{allg.description | showFirstSymbols:15}}
            </div>
          </div>
          <div class="col-1 pd-xs-l-5-force pd-xs-r-5-force">
            <a class="btn btn-sm btn-danger text-white mg-x-1" (click)="delete(i)">{{'Delete' | translate}}</a>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="tfoot">
    <div class="row" [formGroup]="formDef.form">
      <div class="col-3">
        <ng-select [items]="existingIngredients$ | async"
                   [formControl]="formDef.getControl('ingredient')"
                   [selectOnTab]="true"
                   [multiple]="false"
                   [searchable]="true"
                   [virtualScroll]="true"
                   [addTag]="false"
                   [clearable]="true"
                   (change)="onIngredientSelected($event)"
                   bindLabel="name">

          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <ng-container *ngIf="item.id != -1">
              <div class="ingredient-select-option-card">
                <div class="card-body">
                  <div class="title">
                    {{item.name}}

                    <sup class="contains-alcohol-warn" *ngIf="item.containsAlcohol">
                      <i class="icon-attention-alt"></i> {{'Contains alcohol' | translate}}
                    </sup>
                  </div>

                  <div class="category-name">
                    <span class="label">{{'Category' | translate}}: </span>
                    <span class="value">{{item.category.name}}</span>
                  </div>

                  <div class="allergens" *ngIf="item.allergens.length > 0">
                    <span class="label">{{'Allergens' | translate}}: </span>
                    <span class="allergen-codes">{{item.allergens | joinAllergenCodes}}</span>
                  </div>
                </div>
              </div>
            </ng-container>


          </ng-template>

        </ng-select>
      </div>
    </div>
  </div>
</div>
