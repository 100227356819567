import {Action} from '@ngrx/store';
import {ActionTypes} from './action-types';
import {HttpErrorResponse} from '@angular/common/http';
import RestaurantAuthenticateResponse = Orderly.RestaurantWeb.Api.Messages.Authentication.RestaurantAuthenticateUserResponse;
import GetCurrentUserResponse = Orderly.RestaurantWeb.Api.Messages.User.GetCurrentUserResponse;
import {
  RestaurantOnboardingCompletedAction,
  RestaurantSuccessfullyCreatedAction,
  RestaurantSuccessfullyUpdatedAction
} from './restaurant.actions';
import {Language} from 'orderly-web-components';

export class LoginSuccessfulAction implements Action {
  readonly type = ActionTypes.LOGIN_SUCCESSFUL;

  constructor(public user: Readonly<Orderly.RestaurantWeb.Api.Messages.UserWithRestaurantsInfo>,
              public jwtTokenBase64: string,
              public autoLoginOnAppStart: boolean) {
  }
}

export class LoginFailedBecauseOfHttpAction implements Action {
  readonly type = ActionTypes.LOGIN_FAILED_HTTP;

  constructor(readonly err: HttpErrorResponse) {
  }
}

export class LoginFailedBecauseOfApiAction implements Action {
  readonly type = ActionTypes.LOGIN_FAILED_API;

  constructor(readonly apiResponse: RestaurantAuthenticateResponse | null) {
  }
}

export class LogoutAction implements Action {
  readonly type = ActionTypes.LOGOUT;

  constructor(reason: string) {
  }
}

export class AutoLoginOnAppStartFailedAction implements Action {
  readonly type = ActionTypes.AUTO_LOGIN_ON_APP_START_FAILED;
}

export class CurrentUserInformationLoadedAction implements Action {
  readonly type = ActionTypes.CURRENT_USER_INFORMATION_LOADED;

  constructor(public user: GetCurrentUserResponse) {
  }
}

export class CurrentUserSelectedLanguageChangedAction implements Action {
  readonly type = ActionTypes.CURRENT_USER_SELECTED_LANGUAGE_CHANGED;

  constructor(public readonly lang: Language) {
  }
}

export class CurrentUserInformationChangedAction implements Action {
  readonly type = ActionTypes.CURRENT_USER_INFORMATION_CHANGED;

  constructor(public readonly lang: Language, public readonly firstName: string, public readonly lastName: string) {
  }
}

export type LocalizationActions = LoginSuccessfulAction |
                                  CurrentUserSelectedLanguageChangedAction |
                                  CurrentUserInformationLoadedAction |
                                  CurrentUserInformationChangedAction;

export type UserActions = LoginSuccessfulAction |
                          LoginFailedBecauseOfHttpAction |
                          LogoutAction |
                          AutoLoginOnAppStartFailedAction |
                          CurrentUserInformationChangedAction |
                          CurrentUserSelectedLanguageChangedAction |
                          RestaurantSuccessfullyCreatedAction |
                          RestaurantSuccessfullyUpdatedAction |
                          RestaurantOnboardingCompletedAction;
