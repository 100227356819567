<div class="col-4">

  <ng-container *ngIf="modifier.ingredient != null">
    {{modifier.ingredient.name}}
  </ng-container>

  <ng-container *ngIf="modifier.ingredient == null">
    {{modifier.name}}
  </ng-container>

</div>
<div class="col-1">
  {{modifier.amount}}
</div>
<div class="col-2">

  <ng-container *ngIf="modifier.measureUnit != null && modifier.ingredient == null">
    {{modifier.measureUnit.name | translate}}
  </ng-container>

  <ng-container *ngIf="modifier.ingredient != null">
    {{modifier.ingredient.measureUnit.name | translate}}
  </ng-container>

</div>
<div class="col-2">
  {{modifier.priceDiff | number:'1.2-2'}}
</div>
<div class="col-3 actions-column">

  <a class="btn btn-sm btn-danger text-white mg-x-1" (click)="update.emit(modifier)">
    <i class="icon-edit"></i> {{'Edit' | translate}}
  </a>

  <a class="btn btn-sm btn-danger text-white mg-x-1" (click)="delete.emit()">
    <i class="icon-trash-empty"></i> {{'Delete' | translate}}
  </a>

</div>
