import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {catchError, finalize, first, map, tap} from 'rxjs/operators';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../util/form.utils';
import {Observable, ReplaySubject} from 'rxjs';
import {TranslationsDefinition} from './translations-definition';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {ToastService, isNotNullOrEmptyOrWhiteSpaceValidator, NeverError} from 'orderly-web-components';
import {genericErrorHandlerWithToast, nameof} from '../../../util/utils';
import {BooleanSelectItem} from '../../add-or-edit-ingredient/helper-classes';
import {CurrentRestaurantAreasService} from '../../../services/active-route-bound/current-restaurant-areas.service';
import AddRestaurantAreaRequest = Orderly.RestaurantWeb.Api.Messages.Area.AddRestaurantAreaRequest;
import UpdateRestaurantAreaRequest = Orderly.RestaurantWeb.Api.Messages.Area.UpdateRestaurantAreaRequest;
import AddOrUpdateRestaurantAreaResponse = Orderly.RestaurantWeb.Api.Messages.Area.AddOrUpdateRestaurantAreaResponse;
import {trnTryAgainLaterText} from '../../../util/trn.utils';
import RestaurantArea = Orderly.RestaurantWeb.Api.Messages.RestaurantArea;

type FormFieldsDef = 'name' | 'isSmokingArea' | 'isOutdoor';

@Component({
  selector: 'app-add-or-edit-area-modal',
  templateUrl: './add-or-edit-area-modal.component.html',
  styleUrls: ['./add-or-edit-area-modal.component.scss']
})
export class AddOrEditAreaModalComponent implements OnInit {

  public actionInProgress: boolean = false;

  public formDef: FormDefinition<FormFieldsDef>;

  public translations$: ReplaySubject<TranslationsDefinition> = new ReplaySubject<TranslationsDefinition>(1);
  public isOutdoorSelectItems$: Observable<BooleanSelectItem[]>;
  public isSmokingAreaSelectItems$: Observable<BooleanSelectItem[]>;

  @Input()
  public existingArea: RestaurantArea;


  constructor(public modalService: NgbActiveModal,
              private restaurantAreasService: CurrentRestaurantAreasService,
              private formBuilder: FormBuilder,
              private toastService: ToastService,
              public trnService: TranslateService) {

    const initialTranslations: TranslationsDefinition = {
      closeLabel: _('Close'),
      addTitle: _('Add area'),
      updateTitle: _('Update area'),
      nameLabel: _('Name'),
      saveButton: _('Save'),
      isOutdoorLabel: _('Is outdoor'),
      isSmokingAreaLabel: _('Is smoking allowed')
    };

    this.translations$.next(initialTranslations);

    trnService.stream([
                        initialTranslations.saveButton,
                        initialTranslations.nameLabel,
                        initialTranslations.addTitle,
                        initialTranslations.updateTitle,
                        initialTranslations.closeLabel,
                        initialTranslations.isSmokingAreaLabel,
                        initialTranslations.isOutdoorLabel,
                      ])
              .pipe(
                map(x => {
                  const result: TranslationsDefinition = {
                    closeLabel: x[initialTranslations.closeLabel],
                    nameLabel: x[initialTranslations.nameLabel],
                    saveButton: x[initialTranslations.saveButton],
                    isOutdoorLabel: x[initialTranslations.isOutdoorLabel],
                    isSmokingAreaLabel: x[initialTranslations.isSmokingAreaLabel],
                    updateTitle: x[initialTranslations.updateTitle],
                    addTitle: x[initialTranslations.addTitle],
                  };

                  return result;
                })
              )
              .subscribe(this.translations$);

    const booleanTranslations: {yesText: string, noText: string} = {
      noText: _('No'),
      yesText: _('Yes')
    };

    this.isOutdoorSelectItems$ = this.trnService
                                     .stream([
                                               booleanTranslations.yesText, booleanTranslations.noText
                                             ])
                                     .pipe(
                                       map((x: []) => {
                                         const result: BooleanSelectItem[] = [
                                           new BooleanSelectItem(true, x[booleanTranslations.yesText]),
                                           new BooleanSelectItem(false, x[booleanTranslations.noText])
                                         ];

                                         return result;
                                       })
                                     );
    this.isSmokingAreaSelectItems$ = this.trnService
                                         .stream([
                                                   booleanTranslations.yesText, booleanTranslations.noText
                                                 ])
                                         .pipe(
                                           map((x: []) => {
                                             const result: BooleanSelectItem[] = [
                                               new BooleanSelectItem(true, x[booleanTranslations.yesText]),
                                               new BooleanSelectItem(false, x[booleanTranslations.noText])
                                             ];

                                             return result;
                                           })
                                         );
  }

  ngOnInit() {
    const fieldsDef: FormFieldsDefinition<FormFieldsDef> = {
      name: new FormFieldDefinition(this.existingArea == null ? null : this.existingArea.name,
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.maxLength(50)],
                                    [nameof<AddRestaurantAreaRequest>('name'),
                                     nameof<UpdateRestaurantAreaRequest>('name')]),
      isOutdoor: new FormFieldDefinition(this.existingArea == null ? null : this.existingArea.isOutdoor,
                                         false,
                                         [Validators.required],
                                         [nameof<AddRestaurantAreaRequest>('isOutdoor'),
                                          nameof<UpdateRestaurantAreaRequest>('isOutdoor')]),
      isSmokingArea: new FormFieldDefinition(this.existingArea == null ? null : this.existingArea.isSmokingArea,
                                             false,
                                             [Validators.required],
                                             [nameof<AddRestaurantAreaRequest>('isSmokingArea'),
                                              nameof<UpdateRestaurantAreaRequest>('isSmokingArea')])
    };

    this.formDef = new FormDefinition(fieldsDef, this.formBuilder);
  }

  public save() {
    if (this.formDef.form.invalid) {
      return;
    }

    if (this.existingArea == null) {
      this.add();
    } else {
      this.update();
    }
  }

  private add() {
    const isSmokingArea: boolean = this.formDef.getControl('isSmokingArea').value;
    const isOutdoor: boolean = this.formDef.getControl('isOutdoor').value;
    const name: string = this.formDef.getControl('name').value;

    const request: AddRestaurantAreaRequest = {
      isSmokingArea: isSmokingArea,
      isOutdoor: isOutdoor,
      name: name
    };

    const response$ = this.restaurantAreasService.add(request);

    this.processAddOrUpdateResponse(response$);
  }

  private update() {
    const isSmokingArea: boolean = this.formDef.getControl('isSmokingArea').value;
    const isOutdoor: boolean = this.formDef.getControl('isOutdoor').value;
    const name: string = this.formDef.getControl('name').value;

    const request: UpdateRestaurantAreaRequest = {
      isSmokingArea: isSmokingArea,
      isOutdoor: isOutdoor,
      name: name
    };

    const response$ = this.restaurantAreasService.update(this.existingArea.id, request);

    this.processAddOrUpdateResponse(response$);
  }

  private processAddOrUpdateResponse(response$: Observable<AddOrUpdateRestaurantAreaResponse>) {

    const tryAgainLaterMsg = trnTryAgainLaterText(this.trnService);
    const unexpectedServerFailureMsg = this.trnService.instant('Failed to save a restaurant area because of unexpected failure.') + ' ' + tryAgainLaterMsg;
    const successMsg = this.trnService.instant('Restaurant area was successfully saved.');

    const reenableToken = this.formDef.disable('all-fields');
    this.actionInProgress = true;

    response$.pipe(
      first(),
      tap((response: AddOrUpdateRestaurantAreaResponse) => {
        switch (response.status) {
          case AddOrUpdateRestaurantAreaResponse.StatusDef.ValidationFailed:
            reenableToken.reenable();
            this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
            break;
          case AddOrUpdateRestaurantAreaResponse.StatusDef.Success:
            this.toastService.showSuccess(successMsg);
            this.modalService.close(response);
            break;
          case AddOrUpdateRestaurantAreaResponse.StatusDef.AreaWithSameNameExists:
            const areaWithSameNameExistsTxt = this.trnService.instant(
              'Restaurant area with same name already exists.');
            this.formDef.addControlError('name', [areaWithSameNameExistsTxt]);
            break;
          case AddOrUpdateRestaurantAreaResponse.StatusDef.UnknownFailure:
            this.toastService.showError(unexpectedServerFailureMsg);
            break;
          default:
            throw new NeverError(response.status);
        }
      }),
      catchError(
        genericErrorHandlerWithToast<AddOrUpdateRestaurantAreaResponse>(this.toastService,
                                                                        this.trnService,
                                                                        unexpectedServerFailureMsg)
      ),
      finalize(() => {
        this.actionInProgress = false;

        reenableToken.reenable();
      })
             )
             .subscribe();
  }
}
