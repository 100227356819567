import {Component, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {CategoryImageFromServerModel} from '../../category-image-from-server.model';
import {LocalCategoryImageModel} from '../../local-category-image.model';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent {

  @Input()
  public image: CategoryImageFromServerModel | LocalCategoryImageModel | null = null;

  public get asCategoryImageFromServerModel(): CategoryImageFromServerModel | null {
    if (this.image instanceof CategoryImageFromServerModel) {
      return this.image;
    }

    return null;
  }

  public get asLocalCategoryImageModel(): LocalCategoryImageModel | null {
    if (this.image instanceof LocalCategoryImageModel) {
      return this.image;
    }

    return null;
  }

  constructor(public readonly domSanitizationService: DomSanitizer) { }

}
