import {Component, Input, OnInit} from '@angular/core';
import {EMPTY, Observable, of} from 'rxjs';
import {GetAllStaticDataResponse, MeasureUnit, StaticDataService} from 'orderly-web-components';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {environment} from '../../../../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
             selector: 'app-shared-measure-unit-select',
             templateUrl: './measure-unit-select.component.html',
             styleUrls: ['./measure-unit-select.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: MeasureUnitSelectComponent,
                 multi: true,
               },
             ],
           })
export class MeasureUnitSelectComponent implements ControlValueAccessor, OnInit {

  public readonly measureUnits$: Observable<MeasureUnit[]>;

  @Input()
  public placeholder: string = _('Select measure unit');

  @Input()
  public appendTo: string;

  public lastValue: MeasureUnit | null = null;

  public isDisabled: boolean = false;

  public translatedPlaceholder$: Observable<string> = EMPTY;


  constructor(private readonly staticDataService: StaticDataService,
              private readonly trnService: TranslateService) {

    this.measureUnits$ = this.staticDataService
                          .getAllStaticData(environment.baseApiUrlWithTrailingSlash)
                          .pipe(
                            map((x: GetAllStaticDataResponse) => x.measureUnits),
                            tap((x: MeasureUnit[]) => {

                              if (x.length === 0) {
                                this.isDisabled = true;
                              }
                            })
                          );
  }

  public ngOnInit(): void {
    this.translatedPlaceholder$ = this.placeholder == null || this.placeholder === ''
                                  ? of('')
                                  : this.trnService.get(this.placeholder);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: MeasureUnit | null) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: MeasureUnit | null): void {
    this.lastValue = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  changed(newValue: MeasureUnit | null) {
    this.lastValue = newValue;

    this.onTouched();
    this.onChange(newValue);
  }

  public compareItems(a: MeasureUnit | null, b: MeasureUnit | null): boolean {
    if (a == null || b == null) {
      return false;
    }

    return a.id === b.id;
  }
}
