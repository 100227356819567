import {ControlValueAccessor} from '@angular/forms';
import {Input, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, Subscription} from 'rxjs';
import {ButtonsGroupSwitchItem} from 'orderly-web-components';
import {TranslateService} from '@ngx-translate/core';
import {TranslationsDefinition} from './translations.definition';
import {
  assignTranslatedProperties,
  getArrayOfTranslationKeys
} from '../../../../../util/trn.utils';
import {map, tap} from 'rxjs/operators';


export abstract class BinaryChoiceSwitchComponentBase implements ControlValueAccessor, OnInit, OnDestroy {

  private translationSubscription: Subscription;

  public enabledDisabledItems$: ReplaySubject<ButtonsGroupSwitchItem[]> = new ReplaySubject<ButtonsGroupSwitchItem[]>(1);

  public isDisabled: boolean = false;

  public lastValue: boolean | null = null;

  @Input()
  public notSelectedButtonClass = 'btn-secondary';

  @Input()
  public selectedTrueButtonClass = 'btn-success';

  @Input()
  public selectedFalseButtonClass = 'btn-danger';


  protected constructor(private readonly trnService: TranslateService,
                        private readonly trueText: string,
                        private readonly falseText: string) {}

  public ngOnInit(): void {
    const initialTranslations: TranslationsDefinition = {
      trueText: this.trueText,
      falseText: this.falseText,
    };

    const translationKeys = getArrayOfTranslationKeys(initialTranslations);

    this.translationSubscription = this.trnService
                                       .stream(translationKeys)
                                       .pipe(
                                         map(translations => {
                                           const result: TranslationsDefinition = assignTranslatedProperties(
                                             initialTranslations, translations);

                                           return result;
                                         }),
                                         tap(trns => {
                                           const trueFalseList = this.createListItems(trns);

                                           this.enabledDisabledItems$.next(trueFalseList);
                                         }),
                                       )
                                       .subscribe();
  }

  private createListItems(trns: Readonly<TranslationsDefinition>): ButtonsGroupSwitchItem[] {
    const trueFalseList: ButtonsGroupSwitchItem[] = [];

    trueFalseList.push(new ButtonsGroupSwitchItem(true, trns.trueText, this.selectedTrueButtonClass));
    trueFalseList.push(new ButtonsGroupSwitchItem(false, trns.falseText, this.selectedFalseButtonClass));

    return trueFalseList;
  }


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: boolean | null) => {
  };

  onTouched: any = () => {
  };

  ngOnDestroy(): void {
    this.translationSubscription.unsubscribe();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: boolean | null): void {
    this.lastValue = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public changed(newValue: boolean | null) {
    this.lastValue = newValue;

    this.onTouched();
    this.onChange(newValue);
  }
}
