import {Component, OnDestroy} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import {ImageFileTypeValidator} from 'orderly-web-components';
import ImageWithBase64Def = Orderly.Shared.Api.Messages.ImageWithBase64Def;
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import MenuItemImageWithThumbsDef = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemImageWithThumbsDef;
import {ImagePreviewModalComponent} from '../../../../../../image-preview-modal/image-preview-modal.component';
import {ImageUrlDefinition} from '../../../../../../image-preview-modal/image-content.definition';

interface ImageWithBase64DefAndThumb extends ImageWithBase64Def {
  thumbContentBase64: string;
}

type ImageType = ImageWithBase64DefAndThumb | MenuItemImageWithThumbsDef;

@Component({
             selector: 'app-images-list',
             templateUrl: './images-list.component.html',
             styleUrls: [`./images-list.component.scss`],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: ImagesListComponent,
                 multi: true,
               },
             ],
           })
export class ImagesListComponent implements ControlValueAccessor, OnDestroy {

  private readonly destroyed$: Subject<any> = new Subject<any>();

  public images: ImageType[] = [];

  public isDisabled = false;

  constructor(private readonly modalService: NgbModal,
              public readonly domSanitizationService: DomSanitizer) {
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(images: MenuItemImageWithThumbsDef[]): void {
    this.images = images;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onFileChange(event) {
    this.onTouched();

    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const previewImageFiles = event.target.files;

      reader.readAsDataURL(previewImageFiles[0]);

      reader.onload = () => {
        const selectedFileName = previewImageFiles[0].name;

        if (typeof reader.result === 'string' && ImageFileTypeValidator.validateImageFileExtension(selectedFileName)) {

          const newImage: ImageWithBase64DefAndThumb = {
            name: selectedFileName,
            contentBase64: reader.result as string,
            cropPosition: null,
            thumbContentBase64: reader.result as string,
          };

          this.images.push(newImage);

          this.onChange(this.images);
        }
      };
    }
  }

  public deleteImage(img: ImageWithBase64DefAndThumb | MenuItemImageWithThumbsDef) {
    this.images = this.images.filter(x => x !== img);

    this.onChange(this.images);
  }

  public openImagePreview(img: ImageWithBase64DefAndThumb | MenuItemImageWithThumbsDef): void {

    const modalRef = this.modalService.open(ImagePreviewModalComponent, {centered: true, size: 'lg'});
    const imgPreviewComponent: ImagePreviewModalComponent = modalRef.componentInstance;

    // tslint:disable-next-line:no-string-literal
    if (img['contentBase64'] == null) {
      const tmp = img as MenuItemImageWithThumbsDef;

      imgPreviewComponent.base64ContentOrUrl = new ImageUrlDefinition(tmp.originalImage.url, tmp.originalImage.name);
    } else {
      const tmp = img as ImageWithBase64DefAndThumb;

      imgPreviewComponent.base64ContentOrUrl = new ImageUrlDefinition(tmp.contentBase64, tmp.name);
    }
  }
}
