import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';
import {filter, first, map, switchMap, tap} from 'rxjs/operators';
import {AuthenticatedUserModel} from '../../services/local-storage.service';
import {Observable, ReplaySubject} from 'rxjs';
import {getAuthenticatedUserSelector} from '../store/selectors/restaurant-selectors';
import {RestaurantService} from '../../services/restaurant.service';
import RestaurantWithStatistics = Orderly.RestaurantWeb.Api.Messages.Restaurant.RestaurantWithStatistics;
import {RestaurantManagerRoutingConstants} from '../routing-constants';

@Component({
             selector: 'app-holding-dashboard',
             templateUrl: './holding-dashboard.component.html',
             styleUrls: ['./holding-dashboard.component.scss'],
             encapsulation: ViewEncapsulation.None
           })
export class HoldingDashboardComponent implements OnInit {

  public addRestaurantUrl: string = '/' + RestaurantManagerRoutingConstants.getRestaurantAddUrl();

  public showOnboardingWizard$: Observable<boolean>;

  public restaurants$: ReplaySubject<RestaurantWithStatistics[]> = new ReplaySubject<RestaurantWithStatistics[]>(1);

  constructor(private readonly store: Store<AppState>,
              private readonly restaurantService: RestaurantService) {

    this.showOnboardingWizard$ = this.store
                                     .select(getAuthenticatedUserSelector)
                                     .pipe(
                                       // First is required to prevent immediate hide of wizard after table was created.
                                       // User has to read last notes as well.
                                       first(),
                                       map(x => x == null ? false : !x.holdingInfo.isOnboardingCompleted)
                                     );
  }

  ngOnInit() {
    this.loadRestaurants();
  }

  loadRestaurants() {
    this.store
        .select(x => x.auth.currentUser)
        .pipe(
          first(),
          filter(x => x != null),
          map((x: AuthenticatedUserModel) => {
            return x.user.holdingInfo.id;
          }),
          switchMap(holdingId => {
            return this.restaurantService.getAllHoldingRestaurants(holdingId);
          }),
          tap(x => {
            this.restaurants$.next(x.restaurants)
          })
        )
        .subscribe();
  }
}
