/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./add-or-edit-restaurant.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../restaurant-editor/restaurant-editor.component.ngfactory";
import * as i3 from "../../../shared/components/file-upload/file-upload-validator.definition";
import * as i4 from "../restaurant-editor/file-extension-validator";
import * as i5 from "../restaurant-editor/restaurant-editor.component";
import * as i6 from "@angular/forms";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./add-or-edit-restaurant.component";
import * as i9 from "orderly-web-components";
import * as i10 from "../../../../../services/restaurant.service";
import * as i11 from "@angular/router";
import * as i12 from "@ngrx/store";
var styles_AddOrEditRestaurantComponent = [i0.styles];
var RenderType_AddOrEditRestaurantComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddOrEditRestaurantComponent, data: {} });
export { RenderType_AddOrEditRestaurantComponent as RenderType_AddOrEditRestaurantComponent };
export function View_AddOrEditRestaurantComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-restaurant-editor", [["class", "restaurant-editor"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, i2.View_RestaurantEditorComponent_0, i2.RenderType_RestaurantEditorComponent)), i1.ɵprd(4608, null, i3.FileUploadValidatorDefinition, i4.FileExtensionValidator, []), i1.ɵdid(2, 180224, null, 0, i5.RestaurantEditorComponent, [i6.FormBuilder], null, null), i1.ɵprd(1024, null, i6.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i5.RestaurantEditorComponent]), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.RestaurantEditorComponent]), i1.ɵdid(5, 540672, null, 0, i6.FormControlDirective, [[6, i6.NG_VALIDATORS], [8, null], [6, i6.NG_VALUE_ACCESSOR], [2, i6.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i6.NgControl, null, [i6.FormControlDirective]), i1.ɵdid(7, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["style", "float: right;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["class", "btn btn-danger text-white mg-r-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.discard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 2, "a", [["class", "btn btn-success text-white"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formDef.getControl("restaurant"); _ck(_v, 5, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("Discard")); _ck(_v, 12, 0, currVal_8); var currVal_9 = _co.formDef.form.invalid; _ck(_v, 14, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("Save")); _ck(_v, 15, 0, currVal_10); }); }
export function View_AddOrEditRestaurantComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-add-or-edit-restaurant", [], null, null, null, View_AddOrEditRestaurantComponent_0, RenderType_AddOrEditRestaurantComponent)), i1.ɵdid(1, 180224, null, 0, i8.AddOrEditRestaurantComponent, [i6.FormBuilder, i9.ProgressDialogService, i10.RestaurantService, i11.Router, i7.TranslateService, i9.ToastService, i12.Store, i11.ActivatedRoute], null, null)], null, null); }
var AddOrEditRestaurantComponentNgFactory = i1.ɵccf("app-add-or-edit-restaurant", i8.AddOrEditRestaurantComponent, View_AddOrEditRestaurantComponent_Host_0, {}, {}, []);
export { AddOrEditRestaurantComponentNgFactory as AddOrEditRestaurantComponentNgFactory };
