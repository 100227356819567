import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
             selector: 'app-start-rating',
             templateUrl: './start-rating.component.html',
             styleUrls: ['./start-rating.component.scss'],
             encapsulation: ViewEncapsulation.None
           })
export class StartRatingComponent implements OnInit {

  @Input()
  public score: number | null;

  // hack because ngFor supports only arrays
  public filledStars: number[] = [];
  public emptyStars: number[] = [];

  constructor() {
  }

  ngOnInit(): void {
    if (this.score != null && this.score >= 0) {
      for (let i = 0; i < this.score; i++) {
        this.filledStars.push(i + 1);
      }

      for (let i = 0; i < 5 - this.score; i++) {
        this.emptyStars.push(i + 1);
      }
    } else {
      this.filledStars = [];
      this.emptyStars = [];
    }
  }
}
