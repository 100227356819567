<form [formGroup]="formDef.form">
  <div class="editable-prices-list-table div-based-table table-dark table-colored">
    <div class="thead">
      <div class="row">
        <div class="col-3">{{'Size name' | translate}} <span class="tx-danger">*</span></div>
        <div class="col-3">{{'Amount' | translate}} <span class="tx-danger">*</span></div>
        <div class="col-3">{{'Price' | translate}} <span class="tx-danger">*</span></div>
        <div class="col-3"></div>
      </div>
    </div>
    <div class="tbody">
      <ng-container *ngFor="let priceItem of formDef.getFormArray('items').controls; let i=index">
        <div class="row" [formGroup]="priceItem">

          <div class="col-3 input">

            <input class="form-control"
                   formControlName="name"
                   type="text"
                   maxlength="32"/>

          </div>

          <div class="col-3 input">

            <input class="form-control"
                   formControlName="amount"
                   type="text"
                   maxlength="10"
                   inputNumber
                   [acceptDecimalPlaces]="true"
                   [decimalPlaces]="3"/>

          </div>

          <div class="col-3 input">

            <input class="form-control"
                   formControlName="price"
                   type="text"
                   maxlength="10"
                   inputNumber
                   [acceptDecimalPlaces]="true"
                   [decimalPlaces]="3"/>

          </div>

          <div class="col-3 actions">

            <ng-container *ngIf="formDef.getFormArray('items').length > 1">
              <a class="btn btn-sm btn-danger text-white mg-r-10"
                 (click)="delete(i)"
                 *ngIf="formDef.getFormArray('items').length > 2"><i class="icon-trash-empty"></i></a>

              <a class="btn btn-sm btn-light text-black-50 mg-r-10"
                 (click)="moveDown(i)"
                 *ngIf="i < formDef.getFormArray('items').length - 1"><i class="icon-angle-down"></i></a>

              <a class="btn btn-sm btn-light text-black-50 mg-r-10"
                 (click)="moveUp(i)"
                 *ngIf="i > 0"><i class="icon-angle-up"></i></a>
            </ng-container>

          </div>
        </div>
      </ng-container>
    </div>

    <div class="tfoot">
      <div class="row">
        <div class="col-12">
          <a class="btn btn-sm btn-success text-white mg-x-1" (click)="addNew()"><i
            class="icon-plus"></i> {{'Add size' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</form>
