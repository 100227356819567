<div class="br-logo"><a href=""><span>[</span>MY<i>CAFY</i><span>]</span></a></div>
<div class="br-sideleft sideleft-scrollbar" *ngIf="items">
  <label class="sidebar-label">Navigation</label>
  <ul class="br-sideleft-menu">

    <li *ngFor='let item of items' class="br-menu-item">
      <a class="br-menu-link"
         (click)="onMenuItemClick($event)"
         href="#"
         [ngClass]="{'with-sub': item.subItems.length > 0,
                     'show-sub': item.subItems.length > 0,
                     'active': item.isActive}">
        <i class="menu-item-icon {{item.icon}} tx-14"></i>
        <span class="menu-item-label">{{item.name}}</span>
      </a>

      <ul class="br-menu-sub" [ngClass]="{'opened': item.anySubItemActive(), 'closed': !item.anySubItemActive()}">
        <li class="sub-item"
            [ngClass]="{'delimiter-after': subItem.cfg.showDelimiterAfter}"
            *ngFor='let subItem of item.subItems'>

          <a [routerLink]="[subItem.url]"
             [ngClass]="{'active': subItem.isActive, 'with-icon': subItem.cfg.icon != null}"
             class="sub-link"
             (click)="onMenuSubItemClick($event, item, subItem)">

            <i class="menu-item-icon {{subItem.cfg.icon}} tx-14" *ngIf="subItem.cfg.icon != null"></i> {{subItem.name}}

          </a>
        </li>
      </ul>
    </li>

  </ul>
</div>
