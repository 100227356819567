<ng-container *ngIf="translations$ | async as trns">
  <div class="modal-header pd-y-20 pd-x-25">
    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
      <ng-container *ngIf="ingredientToEdit == null">{{trns.addTitle}}</ng-container>
      <ng-container *ngIf="ingredientToEdit != null">{{trns.updateTitle}}</ng-container>
    </h6>
    <button type="button" class="close" [attr.aria-label]="'Close'" (click)="activeModal.dismiss(null)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pd-25">
    <form [formGroup]="formDef.form" (ngSubmit)="save()">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-control-label">{{trns.nameLabel}}: <span class="tx-danger">*</span></label>
            <input class="form-control" type="text" [formControl]="formDef.getControl('name')" maxlength="50" />

            <owc-input-validation-errors [control]="formDef.getControl('name')"></owc-input-validation-errors>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-control-label" for="measureUnit">{{trns.measureUnitLabel}}: <span class="tx-danger">*</span></label>

            <ng-select [items]="measureUnits$ | async"
                       [formControl]="formDef.getControl('measureUnit')"
                       [compareWith]="compareMeasureUnitsSelectItems"
                       labelForId="measureUnit"
                       bindLabel="name">
            </ng-select>

            <owc-input-validation-errors [control]="formDef.getControl('measureUnit')"></owc-input-validation-errors>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-control-label" for="category">{{trns.categoryLabel}}: <span class="tx-danger">*</span></label>

            <ng-select [items]="categories$ | async"
                       [formControl]="formDef.getControl('category')"
                       labelForId="category"
                       bindLabel="name">
            </ng-select>

            <owc-input-validation-errors [control]="formDef.getControl('measureUnit')"></owc-input-validation-errors>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-control-label" for="containsAlcohol">{{trns.containsAlcoholLabel}}: <span class="tx-danger">*</span></label>

            <ng-select [items]="containsAlcoholSelectItems$ | async"
                       [formControl]="formDef.getControl('containsAlcohol')"
                       [searchable]="false"
                       labelForId="containsAlcohol"
                       bindValue="value"
                       bindLabel="text">
            </ng-select>

            <owc-input-validation-errors [control]="formDef.getControl('measureUnit')"></owc-input-validation-errors>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-control-label" for="allergens">{{trns.allergensLabel}}:</label>

            <ng-select [items]="allergens$ | async"
                       [formControl]="formDef.getControl('allergens')"
                       [multiple]="true"
                       [searchable]="false"
                       [compareWith]="compareAllergensSelectItems"
                       bindLabel="description"
                       labelForId="allergens">

              <ng-template ng-option-tmp let-item="item">
                <strong>{{item.code}}</strong> - {{item.description}}
              </ng-template>
            </ng-select>

            <owc-input-validation-errors [control]="formDef.getControl('allergens')"></owc-input-validation-errors>
          </div>
        </div>
      </div>

    </form>
  </div>

  <div class="modal-footer tx-white">
    <owc-progress-action-button [actionInProgress]="actionInProgress"
                                [disabled]="formDef.form.invalid"
                                (execute)="save()"
                                [btnText]="trns.saveButtonLabel"
                                type="link">
    </owc-progress-action-button>
  </div>
</ng-container>
