import {CurrentRestaurantServiceBase} from './current-restaurant.service.base';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {RestaurantSpecificArrayResultLoadingState} from './helper.statuses';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {map} from 'rxjs/operators';
import GetOpenedClientRequestsResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.GetOpenedClientRequestsResponse;
import {Injectable} from '@angular/core';
import ClientRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.ClientRequest;

@Injectable({
              providedIn: 'root'
            })
export class CurrentRestaurantOpenedRequestsService extends CurrentRestaurantServiceBase<ClientRequest> {
  private baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  public get currentRestaurantOpenedRequests$(): Observable<RestaurantSpecificArrayResultLoadingState<ClientRequest>> {
    return this.dataFeed$;
  }

  constructor(private httpClient: HttpClient, store: Store<AppState>) {
    super(store);
  }

  protected doLoadData(restaurantId: number): Observable<ClientRequest[]> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/table-action-requests/opened/all`;
    const response$ = this.httpClient.get<GetOpenedClientRequestsResponse>(url);

    return response$.pipe(map(x => x.requests));
  }
}
