import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import AddOrUpdateIngredientResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddOrUpdateIngredientResponse;
import UpdateIngredientRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.UpdateIngredientRequest;
import AddIngredientRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddIngredientRequest;
import GetAllIngredientsResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.GetAllIngredientsResponse;
import DeleteIngredientResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.DeleteIngredientResponse;
import GetAllIngredientCategoriesResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.GetAllIngredientCategoriesResponse;

@Injectable({
              providedIn: 'root'
            })
export class IngredientsService {
  private baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  constructor(private httpClient: HttpClient) {
  }

  public getAllIngredientCategories(restaurantId: number): Observable<GetAllIngredientCategoriesResponse> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/ingredient-categories/all`;
    const result = this.httpClient.get<GetAllIngredientCategoriesResponse>(url);

    return result;
  }

  public getAllIngredients(restaurantId: number): Observable<GetAllIngredientsResponse> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/ingredients/all`;
    const result = this.httpClient.get<GetAllIngredientsResponse>(url);

    return result;
  }

  public addIngredient(restaurantId: number, request: AddIngredientRequest): Observable<AddOrUpdateIngredientResponse> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/ingredient/add`;
    const result = this.httpClient.post<AddOrUpdateIngredientResponse>(url, request);

    return result;
  }

  public updateIngredient(restaurantId: number, data: UpdateIngredientRequest): Observable<AddOrUpdateIngredientResponse> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/ingredient/${data.id}/update`;
    const result = this.httpClient.post<AddOrUpdateIngredientResponse>(url, data);

    return result;
  }

  public deleteIngredient(restaurantId: number, ingredientId: number): Observable<DeleteIngredientResponse> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/ingredient/${ingredientId}/delete`;
    const result = this.httpClient.delete<DeleteIngredientResponse>(url);

    return result;
  }
}
