<div class="form-layout mg-b-15 pd-b-0">
  <form [formGroup]="searchFiltersFormDef.form">
    <div class="row">
      <div class="col-12">
        <h4>{{'Search filters' | translate}}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
        <div class="form-group">
          <label class="form-control-label" for="category">{{'Category' | translate}}:</label>
          <ng-select [items]="categories$ | async"
                     [formControl]="searchFiltersFormDef.getControl('category')"
                     labelForId="category"
                     bindLabel="name">
          </ng-select>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
        <div class="form-group">
          <label class="form-control-label" for="allergens">{{'Allergens' | translate}}:</label>
          <ng-select [items]="allergens$ | async"
                     [formControl]="searchFiltersFormDef.getControl('allergens')"
                     [multiple]="true"
                     [searchable]="false"
                     [maxSelectedItems]="3"
                     bindLabel="description"
                     labelForId="allergens">

            <ng-template ng-option-tmp let-item="item">
              <strong>{{item.code}}</strong> - {{item.description}}
            </ng-template>

          </ng-select>
        </div>
      </div>
    </div>
  </form>
</div>


<div class="ingredients-list-table div-based-table table-dark table-colored">
  <div class="thead">
    <div class="row">
      <div class="col-11 title">{{'Ingredients' | translate}}</div>
      <div class="col-1 actions">
        <a class="btn btn-sm btn-outline-secondary float-right" (click)="refreshIngredientsList()">
          <i class="icon-arrows-cw fa-15x" [class.animate-spin]="listIsLoading$ | async"></i>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-3">{{'Name' | translate}}</div>
      <div class="col-lg-2 hidden-md-down">{{'Measure unit' | translate}}</div>
      <div class="col-3">{{'Category' | translate}}</div>
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-2">{{'Allergens' | translate}}</div>
      <div class="col-2"></div>
    </div>
  </div>
  <div class="tbody">
    <ng-container *ngIf="listIsLoaded$ | async">
      <div class="row" *ngFor="let ingredient of displayedGridItems$ | async" style="word-wrap: break-word;">
        <div class="col-3">
          <div>
            <a (click)="updateIngredient(ingredient)">{{ ingredient.name }}</a>

            <sup *ngIf="ingredient.containsAlcohol" class="contains-alcohol-warn">
              <i class="icon-attention-alt">{{'Contains alcohol' | translate}}</i>
            </sup>
          </div>
        </div>
        <div class="col-lg-2 hidden-md-down">{{ ingredient.measureUnit.name | translate }}</div>
        <div class="col-3">{{ ingredient.category.name }}</div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-2">
          <ul class="allergens-list">
            <li *ngFor="let item of ingredient.allergens" class="allergen-item">
              <span class="code">[{{item.code}}]</span> <span class="name">{{item.description}}</span>
            </li>
          </ul>
        </div>
        <div class="col-2">
          <ng-container *ngIf="userIsManager | async">
            <a class="btn btn-sm btn-teal text-white mg-x-1" (click)="updateIngredient(ingredient)">{{'Update' | translate}}</a>
            <a class="btn btn-sm btn-danger text-white mg-x-1" (click)="deleteIngredient(ingredient)">{{'Delete' | translate}}</a>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="row" *ngIf="listIsLoading$ | async">
      <div class="ht-60 pos-relative align-items-center col-12">
        <owc-progress-wave></owc-progress-wave>
      </div>
    </div>

    <div class="row" *ngIf="listLoadingFailed$ | async">
      <div class="ht-50 pos-relative align-items-center col-12">
        <p class="loading-failed-text">{{'Could not load ingredients. Check if you have Internet connection and try again.' | translate}}</p>
      </div>
    </div>
  </div>

  <div class="tfoot">
    <div class="row">
      <div class="col-4">
        <ng-container *ngIf="userIsManager | async">
          <a class="new-element-btn" [class.disabled]="listIsLoading$ | async" (click)="addIngredient()">
            <i class="icon-plus"></i>{{'Add ingredient' | translate}}
          </a>

          <a class="new-element-btn" [class.disabled]="listIsLoading$ | async" (click)="addIngredientCategory()">
            <i class="icon-plus"></i>{{'Add ingredient category' | translate}}
          </a>
        </ng-container>
      </div>
      <div class="col-8 bd-l-0">
        <owc-pagination-block [visible$]="listIsLoaded$"
                              (filtersChanged)="pagingFiltersChanged($event)"
                              [disable$]="listLoadingFailed$"
                              [state$]="paginationState$">
        </owc-pagination-block>
      </div>
    </div>
  </div>
</div>
