export class ImageFromServerModel implements Orderly.Shared.Api.Messages.ImageWithUrlAndSizeDef {

  constructor(
    public readonly id: any,
    public readonly format: Orderly.Common.Enums.ImageFormat,
    public readonly height: number,
    public readonly width: number,
    public readonly name: string,
    public readonly url: string) {
  }

  public static from(source: Orderly.Shared.Api.Messages.ImageWithUrlAndSizeDef | null): ImageFromServerModel | null {
    if (source == null) {
      return null;
    }

    return new ImageFromServerModel(source.id, source.format, source.height, source.width, source.name, source.url);
  }
}
