<ng-container *ngIf="translations$ | async as trns">
  <div class="modal-header pd-y-20 pd-x-25">
    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{existingArea == null ? trns.addTitle : trns.updateTitle}}</h6>
    <button type="button" class="close" [attr.aria-label]="trns.closeLabel" (click)="modalService.dismiss(null)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pd-25">

    <form [formGroup]="formDef.form" (ngSubmit)="save()">

      <div class="row">
        <div class="form-layout form-layout-1" style="width: 100%;">

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label">{{trns.nameLabel}}: <span class="tx-danger">*</span></label>
                <input class="form-control" type="text" [formControl]="formDef.getControl('name')"/>

                <owc-input-validation-errors [control]="formDef.getControl('name')"></owc-input-validation-errors>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-xs-12">
              <div class="form-group">
                <label class="form-control-label" for="isSmokingArea">{{trns.isSmokingAreaLabel}}: <span class="tx-danger">*</span></label>

                <ng-select [items]="isSmokingAreaSelectItems$ | async"
                           [formControl]="formDef.getControl('isSmokingArea')"
                           [searchable]="false"
                           labelForId="isSmokingArea"
                           bindValue="value"
                           bindLabel="text">
                </ng-select>


                <owc-input-validation-errors [control]="formDef.getControl('isSmokingArea')"></owc-input-validation-errors>
              </div>
            </div>

            <div class="col-lg-6 col-xs-12">
              <div class="form-group">
                <label class="form-control-label" for="isOutdoor">{{trns.isOutdoorLabel}}: <span class="tx-danger">*</span></label>

                <ng-select [items]="isOutdoorSelectItems$ | async"
                           [formControl]="formDef.getControl('isOutdoor')"
                           [searchable]="false"
                           labelForId="isOutdoor"
                           bindValue="value"
                           bindLabel="text">
                </ng-select>


                <owc-input-validation-errors [control]="formDef.getControl('isOutdoor')"></owc-input-validation-errors>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer tx-white">
    <owc-progress-action-button [actionInProgress]="actionInProgress"
                                [disabled]="formDef.form.invalid"
                                (execute)="save()"
                                [btnText]="trns.saveButton"
                                type="link">
    </owc-progress-action-button>
  </div>
</ng-container>
