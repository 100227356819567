import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, finalize, first, flatMap, map, tap} from 'rxjs/operators';

import {UserService} from '../../../services/user.service';
import {
  isNotNullOrEmptyOrWhiteSpaceValidator,
  mustMatch,
  NeverError,
  ToastService
} from 'orderly-web-components';
import {of} from 'rxjs';
import ConfirmInvitationResponse = Orderly.RestaurantWeb.Api.Messages.Waiter.ConfirmInvitationResponse;
import OpStatus = Orderly.RestaurantWeb.Api.Messages.Waiter.ConfirmInvitationResponse.OpStatus;
import {TranslateService} from '@ngx-translate/core';

@Component({
             selector: 'app-confirm-account',
             templateUrl: './confirm-account.component.html',
             styleUrls: ['./confirm-account.component.scss']
           })
export class ConfirmAccountComponent implements OnInit {

  public form: FormGroup;
  public actionIsInProgress: boolean = false;
  public activatedSuccessfully: boolean = false;

  constructor(private userService: UserService,
              private toastService: ToastService,
              private formBuilder: FormBuilder,
              router: Router,
              private activeRoute: ActivatedRoute,
              private trnService: TranslateService) {
  }

  ngOnInit() {

    const error = this.trnService.instant('{{firstControlName}} must match {{secondControlName}}',
                                          {
                                            firstControlName: this.trnService.instant('Password'),
                                            secondControlName: this.trnService.instant('Password confirmation')
                                          });

    this.form = this.formBuilder.group({
                                         password: ['', [Validators.required, isNotNullOrEmptyOrWhiteSpaceValidator]],
                                         passwordConfirmation: ['', [Validators.required]]
                                       }, {
                                         validators: mustMatch('password', 'passwordConfirmation', error)
                                       });
  }

  get f() {
    return this.form.controls;
  }

  public confirm() {
    if (this.form.invalid) {
      return;
    }

    this.actionIsInProgress = true;

    const passwordValue: string = this.form.controls.password.value;

    this.activeRoute
        .paramMap
        .pipe(
          map(x => x.get('token') || ''),
          first(),
          flatMap(token => this.userService.confirmAccount(token, passwordValue)),
          tap((response: ConfirmInvitationResponse) => {
            switch (response.status) {
              case OpStatus.InvitationDoesNotExist:
                this.toastService.showError('Invitation was not found. Check if you copy-pasted URL from your email correctly');
                break;
              case OpStatus.InvitationExpired:
                this.toastService.showError('Your invitation expired. Contact your manager to renew it.');
                break;
              case OpStatus.AccountAlreadyActivated:
                this.toastService.showError('Account was already activated.');
                break;
              case OpStatus.UnknownFailure:
                this.toastService.showError('An unknown error has occurred while processing your request. Please try again.');
                break;
              case OpStatus.ValidationFailed:
                this.toastService.showError('Please check if you filled all the required fields and try again.');
                break;
              case OpStatus.Success:
                this.activatedSuccessfully = true;
                break;
              default:
                throw new NeverError(response.status);
            }
          }),
          catchError((err, _) => {
            this.toastService.showError('Failed to conform your account. Check if you have Internet connection and try again.');

            return of(null);
          }),
          finalize(() => {
            this.actionIsInProgress = false;
          })
        ).subscribe();
  }
}
