import { map, switchMap, tap } from 'rxjs/operators';
import { CurrentRestaurantServiceBase } from './current-restaurant.service.base';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
export class CurrentRestaurantTablesService extends CurrentRestaurantServiceBase {
    constructor(httpClient, store) {
        super(store);
        this.httpClient = httpClient;
        this.baseURL = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
    }
    get currentRestaurantTables$() {
        return this.dataFeed$;
    }
    doLoadData(restaurantId) {
        const url = `${this.baseURL}/restaurant/${restaurantId}/tables/all`;
        const response$ = this.httpClient.get(url);
        return response$.pipe(tap(x => {
            if (x.status === 3 /* UnknownFailure */) {
                throw new Error('Server returned an error. Could not load list of tables');
            }
        }), map(x => x.items));
    }
    add(data) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            return this.addForRestaurant(restaurantId, data);
        }));
    }
    addForRestaurant(restaurantId, data) {
        const url = `${this.baseURL}/restaurant/${restaurantId}/table/add`;
        return this.httpClient.post(url, data);
    }
    delete(tableId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/table/${tableId}/delete`;
            return this.httpClient.delete(url);
        }));
    }
    generateNewQrCode(tableId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/table/${tableId}/qr-code/generate`;
            return this.httpClient.post(url, {});
        }));
    }
    getTableQrCodeAsBase64Image(tableId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/table/${tableId}/qr-code/as-base64-image`;
            return this.httpClient.get(url);
        }));
    }
    downloadTableQrCodeAsPdf(tableId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/table/${tableId}/qr-code/as-pdf`;
            return this.httpClient.post(url, {}, { responseType: 'blob' });
        }));
    }
    downloadTableQrCodeAsImage(tableId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/table/${tableId}/qr-code/as-image`;
            return this.httpClient.post(url, {}, { responseType: 'blob' });
        }));
    }
    downloadTableQrCodesAsStickersImage(tableIdList) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const request = {
                tablesList: tableIdList
            };
            const url = `${this.baseURL}/restaurant/${restaurantId}/qr-codes/print/as-stickers`;
            return this.httpClient.post(url, request, { responseType: 'blob' });
        }));
    }
    assignPregeneratedCode(tableId, code) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const request = {
                code: code,
            };
            const url = `${this.baseURL}/restaurant/${restaurantId}/table/${tableId}/qr-code/assign`;
            return this.httpClient.post(url, request);
        }));
    }
}
CurrentRestaurantTablesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentRestaurantTablesService_Factory() { return new CurrentRestaurantTablesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: CurrentRestaurantTablesService, providedIn: "root" });
