/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terminals-and-displays-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../displays-list/displays-list.component.ngfactory";
import * as i3 from "../displays-list/displays-list.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/router";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "../../../services/active-route-bound/current-restaurant-displays.service";
import * as i8 from "../terminals-list/terminals-list.component.ngfactory";
import * as i9 from "../terminals-list/terminals-list.component";
import * as i10 from "@ngx-translate/core";
import * as i11 from "orderly-web-components";
import * as i12 from "../../../util/delete-modal.utils";
import * as i13 from "../../../services/active-route-bound/current-restaurant-terminals.service";
import * as i14 from "@angular/common";
import * as i15 from "./terminals-and-displays-list.component";
var styles_TerminalsAndDisplaysListComponent = [i0.styles];
var RenderType_TerminalsAndDisplaysListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TerminalsAndDisplaysListComponent, data: {} });
export { RenderType_TerminalsAndDisplaysListComponent as RenderType_TerminalsAndDisplaysListComponent };
function View_TerminalsAndDisplaysListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12 col-xxl-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-displays-list", [], null, null, null, i2.View_DisplaysListComponent_0, i2.RenderType_DisplaysListComponent)), i1.ɵdid(2, 245760, null, 0, i3.DisplaysListComponent, [i4.Store, i5.ActivatedRoute, i6.NgbModal, i7.CurrentRestaurantDisplaysService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_TerminalsAndDisplaysListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row row-sm mg-t-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-12 col-xxl-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-terminals-list", [], null, null, null, i8.View_TerminalsListComponent_0, i8.RenderType_TerminalsListComponent)), i1.ɵdid(3, 245760, null, 0, i9.TerminalsListComponent, [i4.Store, i5.ActivatedRoute, i6.NgbModal, i10.TranslateService, i11.ToastService, i12.DeleteModalUtils, i13.CurrentRestaurantTerminalsService], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TerminalsAndDisplaysListComponent_1)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i14.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.selfOrderingKiosksEnabled$)); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_TerminalsAndDisplaysListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-terminals-and-displays-list", [], null, null, null, View_TerminalsAndDisplaysListComponent_0, RenderType_TerminalsAndDisplaysListComponent)), i1.ɵdid(1, 180224, null, 0, i15.TerminalsAndDisplaysListComponent, [i4.Store, i5.ActivatedRoute], null, null)], null, null); }
var TerminalsAndDisplaysListComponentNgFactory = i1.ɵccf("app-terminals-and-displays-list", i15.TerminalsAndDisplaysListComponent, View_TerminalsAndDisplaysListComponent_Host_0, {}, {}, []);
export { TerminalsAndDisplaysListComponentNgFactory as TerminalsAndDisplaysListComponentNgFactory };
