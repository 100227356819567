<div class="modal-header pd-y-20 pd-x-25">
  <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{'Invite a waiter' | translate}}</h6>
  <button type="button" class="close" [attr.aria-label]="'Close' | translate" (click)="modalService.dismiss(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pd-25">

  <form [formGroup]="formDef.form" (ngSubmit)="save()">

    <div class="row">
      <div class="form-layout form-layout-1" style="width: 100%;">

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">{{'First name' | translate}}: <span class="tx-danger">*</span></label>
              <input class="form-control" type="text" [formControl]="formDef.getControl('firstName')" />

              <owc-input-validation-errors [control]="formDef.getControl('firstName')"></owc-input-validation-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">{{'Last name' | translate}}: <span class="tx-danger">*</span></label>
              <input class="form-control" type="text" [formControl]="formDef.getControl('lastName')" />

              <owc-input-validation-errors [control]="formDef.getControl('lastName')"></owc-input-validation-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">{{'E-Mail' | translate}}: <span class="tx-danger">*</span></label>
              <input class="form-control" type="text" [formControl]="formDef.getControl('email')" />

              <owc-input-validation-errors [control]="formDef.getControl('email')"></owc-input-validation-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">{{'Roles' | translate}}: <span class="tx-danger">*</span></label>

              <ng-select [items]="roles$ | async"
                         [formControl]="formDef.getControl('roles')"
                         [searchable]="false"
                         [multiple]="true"
                         labelForId="roleId"
                         bindLabel="name"
                         bindValue="id">
              </ng-select>

              <owc-input-validation-errors [control]="formDef.getControl('roles')"></owc-input-validation-errors>
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>
<div class="modal-footer tx-white">
  <owc-progress-action-button [actionInProgress]="actionInProgress"
                              (execute)="save()"
                              [btnText]="'Send invitation' | translate"
                              [disabled]="formDef.form.invalid"
                              type=link>
  </owc-progress-action-button>
</div>
