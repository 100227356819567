export class KeyCloakUserRoles {
  public static readonly WAITER = 'waiter';
  public static readonly RESTAURANT_OWNER = 'restaurant-owner';
  public static readonly RESTAURANT_HOLDING_EXECUTIVE = 'holding-executive';
  public static readonly RESTAURANT_MANAGER_ASSISTANT = 'restaurant-manager-assistant';
  public static readonly RESTAURANT_MANAGER = 'restaurant-manager';
  public static readonly RESTAURANT_HOLDING_MANAGER = 'holding-manager';
  public static readonly TERMINAL_WRITE = 'terminal-write';
  public static readonly TABLE_DISPLAY = 'table-display';
  public static readonly SYS_ADMIN = 'system-admin';
}
