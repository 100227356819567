import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {NeverError, ToastService} from 'orderly-web-components';
import {TranslateService} from '@ngx-translate/core';
import {DeleteModalUtils} from '../../../util/delete-modal.utils';
import {CurrentRestaurantReviewsService} from '../../../services/active-route-bound/current-restaurant-reviews.service';
import {Observable, ReplaySubject, Subscription, throwError} from 'rxjs';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../util/form.utils';
import {SearchFiltersDefinition} from './search-filters-definition';
import {CurrentRestaurantAreasService} from '../../../services/active-route-bound/current-restaurant-areas.service';
import {FormBuilder} from '@angular/forms';
import {catchError, filter, finalize, first, map, tap} from 'rxjs/operators';
import {NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {nameof} from '../../../util/utils';
import Review = Orderly.RestaurantWeb.Api.Messages.Review.GetAllReviewsResponse.ReviewWithTable;
import GetAllReviewsRequest = Orderly.RestaurantWeb.Api.Messages.Review.GetAllReviewsRequest;
import GetAllReviewsResponse = Orderly.RestaurantWeb.Api.Messages.Review.GetAllReviewsResponse;
import RestaurantArea = Orderly.RestaurantWeb.Api.Messages.RestaurantArea;
import * as moment from 'moment';


@Component({
  selector: 'app-reviews-list',
  templateUrl: './reviews-list.component.html',
  styleUrls: ['./reviews-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReviewsListComponent implements OnDestroy {

  public readonly reviews$: ReplaySubject<Review[]> = new ReplaySubject<Review[]>(1);
  public readonly maxResults: number[] = [20, 50, 100, 150];

  public readonly listIsLoading$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public readonly listIsLoaded$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public readonly listLoadingFailed$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public lastLoadedOn: Date | null = null;
  public searchButtonVisible: boolean = true;
  public waitingForFirstSearch: boolean = true;

  public formDef: FormDefinition<keyof SearchFiltersDefinition>;
  public areas$: Observable<RestaurantArea[]>;
  public minSelectableDate: NgbDateStruct;
  public maxSelectableDate: NgbDateStruct;

  public minSelectableToDate: NgbDateStruct | null;
  public maxSelectableFromDate: NgbDateStruct | null;

  private formValueChangeSubscription: Subscription;

  constructor(private formBuilder: FormBuilder,
              trnService: TranslateService,
              toastService: ToastService,
              deleteModalUtils: DeleteModalUtils,
              private reviewsService: CurrentRestaurantReviewsService,
              areasService: CurrentRestaurantAreasService) {

    this.areas$ = areasService.currentRestaurantAreas$
                              .pipe(
                                filter(x => x.isLoaded()),
                                map(x => x.items)
                              );

    const todayDate = moment.utc();
    const yesterdayDate = todayDate.clone().subtract(1, 'days');

    const yesterday: NgbDateStruct = new NgbDate(yesterdayDate.year(), yesterdayDate.month(), yesterdayDate.date());
    const today: NgbDateStruct = new NgbDate(todayDate.year(), todayDate.month(), todayDate.date());

    this.minSelectableDate = new NgbDate(2020, 1, 1);
    this.maxSelectableDate = today;

    this.minSelectableToDate = yesterday;
    this.maxSelectableFromDate = yesterday;

    const fieldsDef: FormFieldsDefinition<keyof SearchFiltersDefinition> = {
      maxResults: new FormFieldDefinition(20, false, [], [nameof<GetAllReviewsRequest>('maxResults')]),
      area: new FormFieldDefinition(null, false, [], [nameof<GetAllReviewsRequest>('areaId')]),
      createdOnFrom: new FormFieldDefinition(yesterday, false, [], [nameof<GetAllReviewsRequest>('createdOnFrom')]),
      createdOnTo: new FormFieldDefinition(today, false, [], [nameof<GetAllReviewsRequest>('createdOnTo')])
    };

    this.formDef = new FormDefinition<keyof SearchFiltersDefinition>(fieldsDef, this.formBuilder);

    this.formValueChangeSubscription = this.formDef
                                           .form
                                           .valueChanges
                                           .pipe(
                                             tap((x: SearchFiltersDefinition) => {
                                               this.minSelectableToDate = x.createdOnFrom || this.minSelectableDate;
                                               this.maxSelectableFromDate = x.createdOnTo || this.maxSelectableDate;
                                             })
                                           )
                                           .subscribe();
  }

  ngOnDestroy(): void {
    this.formValueChangeSubscription.unsubscribe();
  }

  public showComment(review: Review) {

  }

  public doSearch() {
    if (this.formDef.form.invalid) {
      return;
    }

    this.searchButtonVisible = false;
    this.waitingForFirstSearch = false;
    this.lastLoadedOn = null;

    const formValue: SearchFiltersDefinition = this.formDef.form.getRawValue();

    const areaId = formValue.area == null ? null : formValue.area.id;
    const createdOnFrom: Date | null = formValue.createdOnFrom == null
                                       ? null
                                       : moment.utc()
                                               .second(0)
                                               .minute(0)
                                               .hour(0)
                                               .date(formValue.createdOnFrom.day)
                                               .month(formValue.createdOnFrom.month)
                                               .year(formValue.createdOnFrom.year)
                                               .toDate();

    const createdOnTo: Date | null = formValue.createdOnTo == null
                                     ? null
                                     : moment.utc()
                                             .second(59)
                                             .minute(59)
                                             .hour(23)
                                             .date(formValue.createdOnTo.day)
                                             .month(formValue.createdOnTo.month)
                                             .year(formValue.createdOnTo.year)
                                             .toDate();

    const request: GetAllReviewsRequest = {
      areaId: areaId,
      createdOnFrom: createdOnFrom,
      createdOnTo: createdOnTo,
      maxResults: formValue.maxResults < 0 ? 20 : formValue.maxResults || 20
    };

    this.listIsLoaded$.next(false);
    this.listIsLoading$.next(true);
    this.listLoadingFailed$.next(false);

    this.reviewsService
        .search(request)
        .pipe(
          first(),
          tap((response: GetAllReviewsResponse) => {
            switch (response.status) {
              case GetAllReviewsResponse.StatusDef.UnexpectedError:
                this.listIsLoaded$.next(false);
                this.listLoadingFailed$.next(true);
                break;
              case GetAllReviewsResponse.StatusDef.Success:
                this.listIsLoaded$.next(true);
                this.listLoadingFailed$.next(false);
                this.reviews$.next(response.reviews);
                break;
              case GetAllReviewsResponse.StatusDef.ValidationFailed:
                this.reviews$.next([]);
                this.listIsLoaded$.next(true);
                this.listIsLoading$.next(false);

                this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
                break;
              default:
                throw new NeverError(response.status);
            }
          }),
          catchError((err) => {
            this.listIsLoaded$.next(false);
            this.listIsLoading$.next(false);
            this.listLoadingFailed$.next(true);

            return throwError(err);
          }),
          finalize(() => {
            this.listIsLoading$.next(false);
            this.searchButtonVisible = true;
            this.lastLoadedOn = new Date();
          })
        )
      .subscribe();
  }
}
