import {RestaurantSuccessfullyCreatedAction, RestaurantSuccessfullyUpdatedAction} from './restaurant.actions';
import {CurrentUserInformationLoadedAction, LoginSuccessfulAction} from './user.actions';
import {Action} from '@ngrx/store';
import {ActionTypes} from './action-types';
import {MenuItemDataPayload} from '../app.state';

export class LeftMenuItemSelectedAction implements Action {
  readonly type = ActionTypes.LEFT_MENU_ITEM_SELECTED;

  constructor(public readonly id: MenuItemDataPayload) {
  }
}

export type LeftMenuActions = RestaurantSuccessfullyCreatedAction |
                              RestaurantSuccessfullyUpdatedAction |
                              LoginSuccessfulAction |
                              CurrentUserInformationLoadedAction |
                              LeftMenuItemSelectedAction;
