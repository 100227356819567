/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./start-rating.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./start-rating.component";
var styles_StartRatingComponent = [i0.styles];
var RenderType_StartRatingComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_StartRatingComponent, data: {} });
export { RenderType_StartRatingComponent as RenderType_StartRatingComponent };
function View_StartRatingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "icon-star"]], [[1, "score-value", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_StartRatingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "icon-star-empty"]], [[1, "score-value", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_StartRatingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "star-rating-score"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartRatingComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartRatingComponent_3)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filledStars; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.emptyStars; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_StartRatingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartRatingComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.score != null); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_StartRatingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-start-rating", [], null, null, null, View_StartRatingComponent_0, RenderType_StartRatingComponent)), i1.ɵdid(1, 114688, null, 0, i3.StartRatingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartRatingComponentNgFactory = i1.ɵccf("app-start-rating", i3.StartRatingComponent, View_StartRatingComponent_Host_0, { score: "score" }, {}, []);
export { StartRatingComponentNgFactory as StartRatingComponentNgFactory };
