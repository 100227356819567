import { KeyCloakUserRoles } from './models/roles';
import { RestaurantManagerRoutingConstants, RoutingConstants } from './app/routing-constants';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngrx/store";
export class AuthenticatedUserAuthGuard {
    constructor(router, store) {
        this.router = router;
        this.store = store;
    }
    static isJwtTokenValid(userWithToken) {
        return Date.now() < userWithToken.expiresOn;
    }
    canActivate(route, state) {
        return this.store
            .select(x => x.auth)
            .pipe(map((authState) => {
            if (authState == null || authState.currentUser == null) {
                return false;
            }
            const result = AuthenticatedUserAuthGuard.isJwtTokenValid(authState.currentUser);
            if (result === false) {
                const destination = this.router.parseUrl(RoutingConstants.LOGIN);
                return destination;
            }
            return result;
        }));
    }
}
AuthenticatedUserAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticatedUserAuthGuard_Factory() { return new AuthenticatedUserAuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Store)); }, token: AuthenticatedUserAuthGuard, providedIn: "root" });
export class LoginAuthGuard {
    constructor(router, store) {
        this.router = router;
        this.store = store;
    }
    canActivate(route, state) {
        return this.store
            .select(x => x.auth)
            .pipe(map((authState) => {
            if (authState == null || authState.currentUser == null) {
                return true;
            }
            const isJwtTokenValid = AuthenticatedUserAuthGuard.isJwtTokenValid(authState.currentUser);
            if (isJwtTokenValid) {
                const destination = this.router.parseUrl(getUserDefaultRedirectUrlByRole(authState.currentUser.roles));
                return destination;
            }
            return true;
        }));
    }
}
LoginAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginAuthGuard_Factory() { return new LoginAuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Store)); }, token: LoginAuthGuard, providedIn: "root" });
export function getUserDefaultRedirectUrlByRole(roles, restaurantIds) {
    if (roles.indexOf(KeyCloakUserRoles.RESTAURANT_HOLDING_EXECUTIVE) >= 0 ||
        roles.indexOf(KeyCloakUserRoles.RESTAURANT_HOLDING_MANAGER) >= 0 ||
        roles.indexOf(KeyCloakUserRoles.WAITER) >= 0 ||
        roles.indexOf(KeyCloakUserRoles.RESTAURANT_OWNER) >= 0 ||
        roles.indexOf(KeyCloakUserRoles.RESTAURANT_MANAGER) >= 0 ||
        roles.indexOf(KeyCloakUserRoles.RESTAURANT_MANAGER_ASSISTANT) >= 0) {
        if (restaurantIds != null && restaurantIds.length === 1) {
            return RestaurantManagerRoutingConstants.getRestaurantDashboardFullUrl(restaurantIds[0]);
        }
        return RestaurantManagerRoutingConstants.getHoldingDashboardFullUrl();
    }
    else {
        throw new Error(`None of the user roles [${roles.length}] matched. Update the condition if a new role was added.`);
    }
}
