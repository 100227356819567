import {Component, EventEmitter, Output} from '@angular/core';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../util/form.utils';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {isNotNullOrEmptyOrWhiteSpaceValidator} from 'orderly-web-components';
import {TranslateService} from '@ngx-translate/core';
import {nameof} from '../../../../../util/utils';
import {AssignPregeneratedCodeFormDefinition} from './assign-pregenerated-code-form.definition';
import AssignPregeneratedCodeRequest = Orderly.RestaurantWeb.Api.Messages.Table.AssignPregeneratedCodeRequest;

@Component({
  selector: 'app-assign-pregenerated-code-modal',
  templateUrl: './assign-pregenerated-code-modal.component.html',
  styleUrls: ['./assign-pregenerated-code-modal.component.scss']
})
export class AssignPregeneratedCodeModalComponent {

  public formDef: FormDefinition<keyof AssignPregeneratedCodeFormDefinition>;

  @Output()
  public save: EventEmitter<AssignPregeneratedCodeFormDefinition> = new EventEmitter<AssignPregeneratedCodeFormDefinition>();


  constructor(public modalService: NgbActiveModal,
              private formBuilder: FormBuilder,
              public trnService: TranslateService) {

    const fieldsDef: FormFieldsDefinition<keyof AssignPregeneratedCodeFormDefinition> = {
      code: new FormFieldDefinition(null,
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.minLength(4), Validators.maxLength(10)],
                                    [nameof<AssignPregeneratedCodeRequest>('code')])
    };

    this.formDef = new FormDefinition(fieldsDef, this.formBuilder);
  }

  public handleSave(): void {
    if (this.formDef.form.invalid) {
      return;
    }

    const formValue: AssignPregeneratedCodeFormDefinition = this.formDef.form.getRawValue();

    this.save.emit(formValue);
  }
}
