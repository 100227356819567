import {Language} from 'orderly-web-components';
import {TextTranslationDefinition} from './components/text-input-with-language-flag/text-translation.definition';

export class TranslationUtils {
  public static createDefaultTranslations(menuLanguages: Language[], primaryLanguageText?: {lang: Language, text: string}): TextTranslationDefinition[] {

    return menuLanguages.map((lang) => {

      const text = primaryLanguageText == null
                   ? ''
                   : lang.code.toLowerCase() === primaryLanguageText.lang.code.toLowerCase()
                     ? primaryLanguageText.text
                     : '';

      return {
        langCode2: lang.code,
        text,
      };
    });
  }

  public static merge(v1: TextTranslationDefinition[], v2: TextTranslationDefinition[]): TextTranslationDefinition[] {

    if (v1.length === 0 && v2.length === 0) {
      return [];
    }

    if (v1.length > 0 && v2.length === 0) {
      return v1;
    }

    if (v1.length === 0 && v2.length > 0) {
      return v2;
    }


    const v1LangCodes = v1.map(x => x.langCode2.toLowerCase());
    const v2LangCodes = v2.map(x => x.langCode2.toLowerCase());

    const allLangCodes = v1LangCodes.concat(v2LangCodes).reduce(
      (previousValue: string[], currentValue: string, currentIndex: number, array: string[]) => {

        if (previousValue.indexOf(currentValue) > -1) {
          return previousValue;
        }

        return [...previousValue, currentValue];
      }, []);

    const result: TextTranslationDefinition[] = [];

    for (let i = 0; i < allLangCodes.length; i++) {
      const langCode2 = allLangCodes[i];

      const v2TranslationIndex = v2.findIndex(x => x.langCode2.toLowerCase() === langCode2);

      if (v2TranslationIndex > -1) {
        result.push(v2[v2TranslationIndex]);
      } else {
        const v1TranslationIndex = v1.findIndex(x => x.langCode2.toLowerCase() === langCode2);

        result.push(v1[v1TranslationIndex]);
      }
    }

    return result;
  }
}
