/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/forms";
import * as i5 from "./file-upload.component";
import * as i6 from "./file-upload-validator.definition";
var styles_FileUploadComponent = [i0.styles];
var RenderType_FileUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadComponent, data: {} });
export { RenderType_FileUploadComponent as RenderType_FileUploadComponent };
function View_FileUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-danger btn-clear-file"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeMenuFile(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-trash-empty"]], null, null, null, null, null))], null, null); }
function View_FileUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["class", "tx-white bg-info btn-select-file selected"], ["for", "menu-file"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.isDisabled === false); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
function View_FileUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["class", "tx-white bg-danger btn-select-file error btn"], ["for", "menu-file"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-attention-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
function View_FileUploadComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "label", [["class", "btn tx-white bg-info btn-select-menu-file"], ["for", "menu-file"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "...\n"])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Choose a file")); _ck(_v, 1, 0, currVal_0); }); }
export function View_FileUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { menuFileInput: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["menuFileInput", 1]], null, 0, "input", [["class", "inputfile"], ["id", "menu-file"], ["name", "menu-file[]"], ["type", "file"]], [[8, "multiple", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_3)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.validFiles; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.invalidFiles; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.showSelectFileButton; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.multipleFilesAllowed; _ck(_v, 1, 0, currVal_0); }); }
export function View_FileUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-shared-file-upload", [], null, null, null, View_FileUploadComponent_0, RenderType_FileUploadComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.FileUploadComponent]), i1.ɵprd(5120, null, i4.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i5.FileUploadComponent]), i1.ɵdid(3, 180224, null, 0, i5.FileUploadComponent, [i6.FileUploadValidatorDefinition], null, null)], null, null); }
var FileUploadComponentNgFactory = i1.ɵccf("app-shared-file-upload", i5.FileUploadComponent, View_FileUploadComponent_Host_0, { multipleFilesAllowed: "multipleFilesAllowed" }, { fileBlocked: "fileBlocked" }, []);
export { FileUploadComponentNgFactory as FileUploadComponentNgFactory };
