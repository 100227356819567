import {OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

export abstract class BaseOrderlyComponent implements OnDestroy {

  private subscriptions: Subscription[] = [];
  private subjects: Subject<any>[] = [];

  ngOnDestroy() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.subscriptions.length; i++) {
      if (!this.subscriptions[i].closed) {
        this.subscriptions[i].unsubscribe();
      }
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.subjects.length; i++) {
      if (!this.subjects[i].closed) {
        this.subjects[i].complete();
        this.subjects[i].unsubscribe();
      }
    }

    this.subscriptions = [];
    this.subjects = [];
  }

  protected registerForAutoDestroy(value: Subscription | Subject<any>): void {

    if (value instanceof Subscription) {
      this.subscriptions.push(value);
    } else {
      this.subjects.push(value);
    }

  }
}
