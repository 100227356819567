import {Component} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {
  EnabledDisabledTranslations,
} from '../../../../../util/trn.utils';
import {BinaryChoiceSwitchComponentBase} from './binary-choice-switch.component';

@Component({
             selector: 'app-shared-enabled-disabled-switch',
             templateUrl: './binary-choice-switch.component.html',
             styleUrls: ['./binary-choice-switch.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: EnabledDisabledSwitchComponent,
                 multi: true,
               },
             ],
           })
export class EnabledDisabledSwitchComponent extends BinaryChoiceSwitchComponentBase {

  constructor(trnService: TranslateService) {

    super(trnService, EnabledDisabledTranslations.Enabled, EnabledDisabledTranslations.Disabled);

  }

}
