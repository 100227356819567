/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modifier-groups-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modifier-group-row/modifier-group-row.component.ngfactory";
import * as i3 from "./modifier-group-row/modifier-group-row.component";
import * as i4 from "@angular/common";
import * as i5 from "./modifier-groups-list.component";
var styles_ModifierGroupsListComponent = [i0.styles];
var RenderType_ModifierGroupsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModifierGroupsListComponent, data: {} });
export { RenderType_ModifierGroupsListComponent as RenderType_ModifierGroupsListComponent };
function View_ModifierGroupsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modifier-group-row", [["class", "modifier-group"]], null, [[null, "requestModifierUpdate"], [null, "addNewModifier"], [null, "delete"], [null, "update"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("requestModifierUpdate" === en)) {
        var pd_0 = (_co.updateModifier(_v.context.index, $event) !== false);
        ad = (pd_0 && ad);
    } if (("addNewModifier" === en)) {
        var pd_1 = (_co.addNewModifier(_v.context.index) !== false);
        ad = (pd_1 && ad);
    } if (("delete" === en)) {
        var pd_2 = (_co.deleteGroup.emit(_v.context.index) !== false);
        ad = (pd_2 && ad);
    } if (("update" === en)) {
        var pd_3 = (_co.requestGroupUpdate.emit(_v.context.index) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_ModifierGroupRowComponent_0, i2.RenderType_ModifierGroupRowComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModifierGroupRowComponent, [], { group: [0, "group"] }, { addNewModifier: "addNewModifier", delete: "delete", update: "update", requestModifierUpdate: "requestModifierUpdate" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ModifierGroupsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = "No modifiers"; _ck(_v, 2, 0, currVal_0); }); }
export function View_ModifierGroupsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "editable-modifier-groups-list-table div-based-table table-dark table-colored"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "tbody"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifierGroupsListComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifierGroupsListComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groups; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.groups.length === 0); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ModifierGroupsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modifier-groups-list", [], null, null, null, View_ModifierGroupsListComponent_0, RenderType_ModifierGroupsListComponent)), i1.ɵdid(1, 180224, null, 0, i5.ModifierGroupsListComponent, [], null, null)], null, null); }
var ModifierGroupsListComponentNgFactory = i1.ɵccf("app-modifier-groups-list", i5.ModifierGroupsListComponent, View_ModifierGroupsListComponent_Host_0, { isDisabled: "isDisabled", groups: "groups" }, { deleteGroup: "deleteGroup", requestGroupUpdate: "requestGroupUpdate", requestModifierUpdate: "requestModifierUpdate", requestModifierCreate: "requestModifierCreate" }, []);
export { ModifierGroupsListComponentNgFactory as ModifierGroupsListComponentNgFactory };
