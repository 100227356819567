import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VisibilitySelectComponent} from './components/visibility-select/visibility-select.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  ModifierIngredientSelectComponent
} from './components/modifier-ingredient-select/modifier-ingredient-select.component';
import {TranslateModule} from '@ngx-translate/core';
import {MeasureUnitSelectComponent} from './components/measure-unit-select/measure-unit-select.component';
import {OrderlyWebComponentsModule} from 'orderly-web-components';
import {AddressInputComponent} from './components/address-input/address-input.component';
import {CurrencySelectComponent} from './components/currency-select/currency-select.component';
import {LanguageSelectComponent} from './components/language-select/language-select.component';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {EnabledDisabledSwitchComponent} from './components/binary-choice-switch/enabled-disabled-switch.component';
import {YesNoSwitchComponent} from './components/binary-choice-switch/yes-no-switch.component';
import {ExistingFileData} from './components/file-upload/existing-file-data';
import {NewFileData} from './components/file-upload/new-file-data';
import {FileUploadValidatorDefinition} from './components/file-upload/file-upload-validator.definition';
import {LanguagesSelectComponent} from './components/languages-select/languages-select.component';
import {
  TextInputWithLanguageFlagComponent
} from './components/text-input-with-language-flag/text-input-with-language-flag.component';
import {TranslationsInputComponent} from './components/translations-input/translations-input.component';
import {YesNoPipe} from './pipes/yesno.pipe';
import {DebugPipe} from './pipes/debug.pipe';
import {TranslationUtils} from './translation-utils';
import {Language2SelectComponent} from './components/language2-select/language2-select.component';


@NgModule({
            declarations: [
              VisibilitySelectComponent,
              ModifierIngredientSelectComponent,
              MeasureUnitSelectComponent,
              AddressInputComponent,
              CurrencySelectComponent,
              LanguageSelectComponent,
              FileUploadComponent,
              EnabledDisabledSwitchComponent,
              YesNoSwitchComponent,
              LanguagesSelectComponent,
              TextInputWithLanguageFlagComponent,
              TranslationsInputComponent,
              YesNoPipe,
              DebugPipe,
              Language2SelectComponent,
            ],
            imports: [
              CommonModule,
              NgSelectModule,
              FormsModule,
              TranslateModule,
              FormsModule,
              ReactiveFormsModule,
              NgSelectModule,

              OrderlyWebComponentsModule,
            ],
            exports: [
              VisibilitySelectComponent,
              ModifierIngredientSelectComponent,
              MeasureUnitSelectComponent,
              AddressInputComponent,
              CurrencySelectComponent,
              LanguageSelectComponent,
              Language2SelectComponent,
              LanguagesSelectComponent,
              FileUploadComponent,
              TextInputWithLanguageFlagComponent,
              TranslationsInputComponent,
              EnabledDisabledSwitchComponent,
              YesNoSwitchComponent,
              YesNoPipe,
              DebugPipe,
            ]
          })
export class SharedModule {}

export {
  VisibilitySelectComponent,

  CurrencySelectComponent,
  LanguageSelectComponent,
  Language2SelectComponent,
  LanguagesSelectComponent,
  MeasureUnitSelectComponent,

  AddressInputComponent,

  FileUploadComponent,
  ExistingFileData,
  NewFileData,
  FileUploadValidatorDefinition,

  EnabledDisabledSwitchComponent,
  YesNoSwitchComponent,

  YesNoPipe,
  DebugPipe,

  TranslationUtils,
};
