import {Action} from '@ngrx/store';
import {ActionTypes} from './action-types';
import AddTableResponse = Orderly.RestaurantWeb.Api.Messages.Table.AddTableResponse;

export class TableCreationSuccessfulAction implements Action {
  readonly type = ActionTypes.TABLE_CREATE_SUCCESSFUL;

  constructor(public data: AddTableResponse) {
  }
}

export class TableDeletionSuccessfulAction implements Action {
  readonly type = ActionTypes.TABLE_DELETE_SUCCESSFUL;

  constructor(public readonly tableId: number) {
  }
}

export type TableActions = TableCreationSuccessfulAction | TableDeletionSuccessfulAction;
