import {
  Component,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../util/form.utils';
import {RestaurantEditorFormFieldsDefinitions} from './restaurant-editor-form-fields.definitions';
import {
  isNotNullOrEmptyOrWhiteSpaceValidator,
} from 'orderly-web-components';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {nameof} from '../../../../../util/utils';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import AddRestaurantRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddRestaurantRequest;
import UpdateRestaurantRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.UpdateRestaurantRequest;
import {FileUploadValidatorDefinition} from '../../../shared/shared.module';
import {FileExtensionValidator} from './file-extension-validator';

@Component({
             selector: 'app-restaurant-editor',
             templateUrl: './restaurant-editor.component.html',
             styleUrls: ['./restaurant-editor.component.scss'],
             encapsulation: ViewEncapsulation.None,
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: RestaurantEditorComponent,
                 multi: true
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: RestaurantEditorComponent,
                 multi: true
               },
               {
                 provide: FileUploadValidatorDefinition,
                 useClass: FileExtensionValidator,
               }
             ]
           })
export class RestaurantEditorComponent implements ControlValueAccessor, Validator, OnDestroy {

  private readonly destroyed$: Subject<any> = new Subject<any>();

  private initInProgress: boolean = false;

  public formDef: FormDefinition<keyof RestaurantEditorFormFieldsDefinitions>;


  constructor(private readonly formBuilder: FormBuilder) {

    const fieldsDef: FormFieldsDefinition<keyof RestaurantEditorFormFieldsDefinitions> = {
      name: new FormFieldDefinition('',
                                    false,
                                    [
                                      isNotNullOrEmptyOrWhiteSpaceValidator, Validators.minLength(3),
                                      Validators.maxLength(50)
                                    ],
                                    [
                                      nameof<AddRestaurantRequest>('name'),
                                      nameof<UpdateRestaurantRequest>('name')
                                    ]),

      address: new FormFieldDefinition(null,
                                       false,
                                       [Validators.required],
                                       []),

      contactPhone: new FormFieldDefinition('',
                                            false,
                                            [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.maxLength(30)],
                                            [
                                              nameof<AddRestaurantRequest>('contactPhone'),
                                              nameof<UpdateRestaurantRequest>('contactPhone')
                                            ]),
      contactEmail: new FormFieldDefinition('',
                                            false,
                                            [Validators.required, Validators.email, Validators.maxLength(120)],
                                            [
                                              nameof<AddRestaurantRequest>('contactEmail'),
                                              nameof<UpdateRestaurantRequest>('contactEmail')
                                            ]),
      menuSettings: new FormFieldDefinition(null,
                                              false,
                                              [Validators.required],
                                              [
                                                nameof<UpdateRestaurantRequest>('defaultCurrencyId')
                                              ]),
      onlineReviewsEnabled: new FormFieldDefinition(true,
                                                    false,
                                                    [Validators.required],
                                                    [
                                                      nameof<UpdateRestaurantRequest>('onlineReviewsEnabled')
                                                    ]),

      qrMenuSettings: new FormFieldDefinition(null,
                                              false,
                                              [Validators.required],
                                              []),
      kioskSettings: new FormFieldDefinition(null,
                                              false,
                                              [Validators.required],
                                              []),
    };

    this.formDef = new FormDefinition<keyof RestaurantEditorFormFieldsDefinitions>(fieldsDef, this.formBuilder);

    this.formDef
      .form
      .valueChanges
      .pipe(
        filter(() => !this.initInProgress),
        tap((x: RestaurantEditorFormFieldsDefinitions) => {
          this.onChange(x);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();

  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.formDef.form.invalid) {
      return {'restaurant-editor': true};
    }

    return null;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: boolean | null) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: RestaurantEditorFormFieldsDefinitions | null): void {
    this.initInProgress = true;

    if (value != null) {
      this.formDef.patchValue(value, false);
    }

    this.formDef.enable([
                          'qrMenuSettings',
                          'kioskSettings',
                          'onlineReviewsEnabled',
                        ]);

    this.initInProgress = false;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formDef.disable('all-fields');
    } else {
      this.formDef.enable('all-fields', false);
    }
  }
}
