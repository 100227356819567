import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import AddMenuItemModifierGroupRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.AddMenuItemModifierGroupRequest;
import AddOrUpdateMenuItemModifierGroupResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.AddOrUpdateMenuItemModifierGroupResponse;
import UpdateMenuItemModifierGroupRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.UpdateMenuItemModifierGroupRequest;

@Injectable({
              providedIn: 'root'
            })
export class RestaurantMenuService {
  private baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
  private menuControllerBaseURL: string = `${this.baseURL}/restaurant`;

  constructor(private httpClient: HttpClient) {
  }


  public addMenuItemModifierGroup(restaurantId: number, menuItemId: number, request: AddMenuItemModifierGroupRequest)
    : Observable<AddOrUpdateMenuItemModifierGroupResponse> {

    const url: string = `${this.menuControllerBaseURL}/${restaurantId}/menu-item/${menuItemId}/modifier-group/add`;

    return this.httpClient.post<AddOrUpdateMenuItemModifierGroupResponse>(url, request);
  }

  public updateMenuItemModifierGroup(restaurantId: number, menuItemId: number, menuItemModifierGroupId: number, request: UpdateMenuItemModifierGroupRequest)
    : Observable<AddOrUpdateMenuItemModifierGroupResponse> {

    const url: string = `${this.menuControllerBaseURL}/${restaurantId}/menu-item/${menuItemId}/modifier-group/${menuItemModifierGroupId}/update`;

    return this.httpClient.post<AddOrUpdateMenuItemModifierGroupResponse>(url, request);
  }
}
