<a class="btn" [ngClass]="btnClass" [class.disabled]="disabled" (click)="onClick()" *ngIf="actionInProgress === false && type === 'link'" [attr.ngbAutofocus]="autoFocus">{{btnText}}</a>

<button type="submit" [disabled]="disabled" class="btn" [ngClass]="btnClass" *ngIf="actionInProgress === false && type === 'submit'" [attr.ngbAutofocus]="autoFocus">{{btnText}}</button>

<div class="btn disabled" [ngClass]="btnClass" *ngIf="actionInProgress === true">
  <div class="sk-wave ht-25-force">
    <div class="sk-rect sk-rect1 bg-white"></div>
    <div class="sk-rect sk-rect2 bg-white"></div>
    <div class="sk-rect sk-rect3 bg-white"></div>
    <div class="sk-rect sk-rect4 bg-white"></div>
    <div class="sk-rect sk-rect5 bg-white"></div>
  </div>
</div>
