import { Component } from '@angular/core';
import {RestaurantRelatedItemsGrid} from '../restaurant-related-items-grid.component';
import {
  ConfirmationModalDialogComponent, ModalMessageTextParagraph,
  NeverError,
  PaginationFiltersStateDefinition,
  ToastService
} from 'orderly-web-components';
import ExistingTerminal = Orderly.RestaurantWeb.Api.Messages.Restaurant.ExistingTerminal;
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {ActivatedRoute} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {DeleteModalUtils} from '../../../util/delete-modal.utils';
import {CurrentRestaurantTerminalsService} from '../../../services/active-route-bound/current-restaurant-terminals.service';
import {catchError, first, switchMap, tap} from 'rxjs/operators';
import {genericErrorHandlerWithToast} from '../../../util/utils';
import DeleteTerminalResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.DeleteTerminalResponse;
import {AddOrEditTerminalModalComponent} from './add-or-edit-terminal-modal/add-or-edit-terminal-modal.component';
import AddOrUpdateTerminalResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddOrUpdateTerminalResponse;
import UnlinkTerminalResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.UnlinkTerminalResponse;
import {SetPinModalComponent} from './set-pin-modal/set-pin-modal.component';

@Component({
  selector: 'app-terminals-list',
  templateUrl: './terminals-list.component.html',
  styleUrls: ['./terminals-list.component.scss']
})
export class TerminalsListComponent extends RestaurantRelatedItemsGrid<ExistingTerminal, PaginationFiltersStateDefinition> {

  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private modalService: NgbModal,
              private trnService: TranslateService,
              private toastService: ToastService,
              private deleteModalUtils: DeleteModalUtils,
              private terminalsService: CurrentRestaurantTerminalsService) {

    super(store, activatedRoute, RestaurantRelatedItemsGrid.basicInitialFiltersState, terminalsService.currentRestaurantTerminals$);
  }

  public refreshTerminalsList() {
    this.terminalsService
        .forceReload()
        .pipe(
          switchMap(x => this.filtersState$),
          tap(filterState => {
            const currentFiltersStateClone = {...filterState};

            currentFiltersStateClone.currentPage = 1;

            this.pagingFiltersChanged(currentFiltersStateClone);
          }),
          first()
        )
        .subscribe();
  }

  public addTerminal() {
    const modalRef = this.modalService.open(AddOrEditTerminalModalComponent, {centered: true, size: 'md'});

    modalRef.result
            .then(
              (value: AddOrUpdateTerminalResponse) => {
                if (value != null && value.status === AddOrUpdateTerminalResponse.StatusDef.Success) {
                  this.terminalsService.forceReload();
                }
              });
  }

  public updateTerminal(terminal: ExistingTerminal) {
    const modalRef = this.modalService.open(AddOrEditTerminalModalComponent, {centered: true, size: 'md'});
    const component: AddOrEditTerminalModalComponent = modalRef.componentInstance;

    component.existingTerminal = terminal;

    modalRef.result
            .then(
              (value: AddOrUpdateTerminalResponse) => {
                if (value != null && value.status === AddOrUpdateTerminalResponse.StatusDef.Success) {
                  this.terminalsService.forceReload();
                }
              });
  }

  public setTerminalPin(terminal: ExistingTerminal) {
    const modalRef = this.modalService.open(SetPinModalComponent, {centered: true, size: 'md'});
    const component: SetPinModalComponent = modalRef.componentInstance;

    component.existingTerminal = terminal;
  }

  public deleteTerminal(terminal: ExistingTerminal) {
    const onDelete = (modalRef: NgbModalRef) => {

      const unexpectedErrorText = this.trnService.instant('Failed to delete a terminal because of unexpected error.');

      this.terminalsService
          .delete(terminal.id)
          .pipe(
            tap((x: DeleteTerminalResponse) => {
              switch (x.status) {
                case DeleteTerminalResponse.StatusDef.Success:
                  this.terminalsService.forceReload();
                  modalRef.close();
                  break;
                case DeleteTerminalResponse.StatusDef.UnexpectedError:
                  this.toastService.showError(unexpectedErrorText);
                  break;
                default:
                  throw new NeverError(x.status);
              }
            }),
            first(),
            catchError(
              genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedErrorText)
            )
          ).subscribe();
    };

    const modalTitle = this.trnService.instant(`Delete terminal`);
    const modalConfirmText = this.trnService.instant(`Do you want to delete a terminal '{{name}}'?`, {name: terminal.name});

    this.deleteModalUtils.showDeleteModal(terminal, modalTitle, [modalConfirmText], onDelete);
  }

  public showAccessCode(terminal: Orderly.RestaurantWeb.Api.Messages.Restaurant.ExistingTerminal) {
    const modalRef = this.modalService.open(ConfirmationModalDialogComponent, {centered: true, size: 'md'});

    const modalInstance: ConfirmationModalDialogComponent = modalRef.componentInstance;

    const group1 = terminal.externalId.slice(0, 4);
    const group2 = terminal.externalId.slice(4, 8);
    const group3 = terminal.externalId.slice(8, 12);

    modalInstance.okButtonText = this.trnService.instant('Close');
    modalInstance.title = this.trnService.instant('Terminal access code');
    modalInstance.messages = [new ModalMessageTextParagraph(`${group1} ${group2} ${group3}`, 'text-align: center;')];

    const completedSubscription = modalInstance.completed.subscribe(($e) => {
      modalRef.close();
    });

    modalRef.result.finally(() => {
      completedSubscription.unsubscribe();
    });
  }

  public unlinkDevice(terminal: Orderly.RestaurantWeb.Api.Messages.Restaurant.ExistingTerminal) {

    const modalTitle = this.trnService.instant(`Unlink tablet`);
    // tslint:disable-next-line:max-line-length
    const modalConfirmText = this.trnService.instant(`Do you want to unlink a tablet from '{{name}}'? You will not receive new incoming requests and orders from your guests on this device.`, {name: terminal.name});

    const onUnlink = (modalRef: NgbModalRef) => {

      const unexpectedErrorText = this.trnService.instant('Failed to unlink a tablet because of unexpected error.');

      this.terminalsService
          .unlinkDevice(terminal.id)
          .pipe(
            tap((x: UnlinkTerminalResponse) => {
              switch (x.status) {
                case UnlinkTerminalResponse.StatusDef.Success:
                  this.terminalsService.forceReload();
                  modalRef.close();
                  break;
                case UnlinkTerminalResponse.StatusDef.UnexpectedError:
                  this.toastService.showError(unexpectedErrorText);
                  break;
                default:
                  throw new NeverError(x.status);
              }
            }),
            first(),
            catchError(
              genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedErrorText)
            )
          ).subscribe();
    };


    const modal = this.modalService.open(ConfirmationModalDialogComponent, {centered: true, size: 'lg'});
    const modalInstance: ConfirmationModalDialogComponent = modal.componentInstance;

    modalInstance.cancelButtonText = this.trnService.instant('Cancel');
    modalInstance.okButtonText = this.trnService.instant('Unlink');
    modalInstance.data = terminal;
    modalInstance.title = modalTitle;
    modalInstance.messages = [new ModalMessageTextParagraph(modalConfirmText)];

    const completedSubscription = modalInstance.completed.subscribe(($e) => {
      onUnlink(modal);
    });

    modal.result.finally(() => {
      completedSubscription.unsubscribe();
    });
  }
}
