<ng-container *ngIf="visible$ | async">
  <div class="pagination-section right" *ngIf="state$ | async as pn">
    <div class="items-per-page-list">
      <ul class="pagination pagination-basic pagination-circle mg-b-0">
        <li class="page-item" [class.active]="pn.itemsPerPage == item" *ngFor="let item of cfg.itemsPerPage">
          <a class="page-link" (click)="setItemsPerPage(item)" [class.disabled]="disable$ | async">{{item}}</a>
        </li>
      </ul>
    </div>

    <div class="delimiter" *ngIf="pn.totalPages > 0">&nbsp;</div>

    <div class="pages-list">
      <ul class="pagination pagination-dark pagination-primary mg-b-0">

        <li class="page-item hidden-sm-down" *ngIf="pn.currentPage - 2 > 1">
          <a class="page-link" (click)="goToPage(1, pn.itemsPerPage)"><i class="icon-angle-double-left"></i></a>
        </li>
        <li class="page-item" *ngIf="pn.showOneStepAngleIcons === true && pn.currentPage - 1 > 1">
          <a class="page-link" (click)="goToPage(pn.currentPage - 1, pn.itemsPerPage)"><i class="icon-angle-left"></i></a>
        </li>

        <li class="page-item disabled" *ngIf="pn.currentPage > 3"><span class="page-link">...</span></li>

        <li class="page-item" *ngFor="let n of pn.renderItems" [class.active]="pn.currentPage == n">
          <a class="page-link" (click)="goToPage(n, pn.itemsPerPage)">{{n}}</a>
        </li>

        <li class="page-item disabled" *ngIf="pn.currentPage < pn.totalPages - 2"><span class="page-link">...</span></li>

        <li class="page-item" *ngIf="pn.showOneStepAngleIcons === true && pn.currentPage + 1 < pn.totalPages">
          <a class="page-link" (click)="goToPage(pn.currentPage + 1, pn.itemsPerPage)"><i class="icon-angle-right"></i></a>
        </li>
        <li class="page-item hidden-sm-down" *ngIf="pn.currentPage + 2 < pn.totalPages">
          <a class="page-link" (click)="goToPage(pn.totalPages, pn.itemsPerPage)"><i class="icon-angle-double-right"></i></a>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
