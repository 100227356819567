<div class="areas-list-table div-based-table table-dark table-colored">
  <div class="thead">
    <div class="row">
      <div class="col-11 title">{{'Menu item categories' | translate}}</div>
      <div class="col-1 actions">
        <a class="btn btn-sm btn-outline-secondary float-right" (click)="refreshCategoriesList()">
          <i class="icon-arrows-cw fa-15x" [class.animate-spin]="listIsLoading$ | async"></i>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-3">{{'Name' | translate}}</div>
      <div class="col-4">{{'Description' | translate}}</div>
      <div class="col-3">{{'Items thumbs visibility' | translate}}</div>
      <div class="col-2"></div>
    </div>
  </div>
  <div class="tbody">
    <ng-container *ngIf="listIsLoaded$ | async">
      <div class="row" *ngFor="let category of gridItemsWithUrls$ | async" style="word-wrap: break-word;">
        <div class="col-3">
          <img [src]="category.thumb.url" alt="category image" class="category-image-thumb img-fluid rounded-circle" *ngIf="category.thumb != null">
          <img src="assets/images/no-image.png" alt="category image placeholder" class="category-image-thumb img-fluid rounded-circle" *ngIf="category.thumb == null">

          {{category.name}}
        </div>
        <div class="col-4">
          <span [innerHTML]="category.description | showFirstSymbols:100 | textareaAsHtml"></span>
        </div>
        <div class="col-3">
          {{(category.itemsThumbVisibility === 0 ? 'Visible' : 'Hidden') | translate}}
        </div>
        <div class="col-2">
          <ng-container *ngIf="userIsManager | async">
            <a class="btn btn-sm btn-teal text-white mg-x-1"
               [routerLink]="category.editUrl">{{'Update' | translate}}</a>

            <a class="btn btn-sm btn-danger text-white mg-x-1" (click)="deleteCategory(category)">{{'Delete' | translate}}</a>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="row" *ngIf="listIsLoading$ | async">
      <div class="ht-60 pos-relative align-items-center col-12">
        <owc-progress-wave></owc-progress-wave>
      </div>
    </div>

    <div class="row" *ngIf="listLoadingFailed$ | async">
      <div class="ht-50 pos-relative align-items-center col-12">
        <p class="loading-failed-text">{{'Could not load menu item categories list. Check if you have Internet connection and try again.' | translate}}</p>
      </div>
    </div>
  </div>

  <div class="tfoot" *ngIf="listIsLoaded$ | async">
    <div class="row">
      <div class="col-4">
        <ng-container *ngIf="userIsManager | async">
          <a class="new-element-btn"
             [class.disabled]="listIsLoading$ | async"
             [routerLink]="addCategoryUrl$ | async">

            <i class="icon-plus"></i>{{'Add menu item category' | translate}}
          </a>

          <ng-container *ngIf="displayedGridItems$ | async as renderedItems">

            <!-- compiler believes that 'displayedGridItems$ | async' can be null, therefore two ng-containers and null check required -->
            <ng-container *ngIf="renderedItems != null && renderedItems.length > 1">
              <a class="change-sorting-order-btn" [class.disabled]="listIsLoading$ | async" (click)="changeSortingOrder()">
                <i class="icon-sort-alt-up"></i>{{'Change sorting order' | translate}}
              </a>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="col-8 bd-l-0">
        <owc-pagination-block [visible$]="listIsLoaded$"
                              (filtersChanged)="pagingFiltersChanged($event)"
                              [disable$]="listLoadingFailed$"
                              [state$]="paginationState$">
        </owc-pagination-block>
      </div>
    </div>
  </div>
</div>
