<div class="form-layout form-layout-1 mg-b-10 pd-b-0">
  <form [formGroup]="formDef.form">
    <div class="row">
      <div class="col-2">
        <div class="form-group">
          <label class="form-control-label">{{'Table' | translate}}:</label>

          <ng-select [items]="tables$ | async"
                     [formControl]="formDef.getControl('table')"
                     [virtualScroll]="true"
                     groupBy="areaName"
                     bindLabel="name">

          </ng-select>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group">
          <label class="form-control-label">{{'Checkin On' | translate}}:</label>

          <div class="input-group">
            <input #checkinOnFromDate="ngbDatepicker"
                   ngbDatepicker
                   class="form-control"
                   placeholder="dd-mm-yyyy"
                   [formControl]="formDef.getControl('checkinOn')"
                   [maxDate]="maxSelectableFromDate"
                   (click)="checkinOnFromDate.open()"
                   (keydown)="$event.preventDefault()">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="checkinOnFromDate.toggle()" type="button"><i class="icon-calendar"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="covid-checkins-list-table div-based-table table-dark table-colored">
  <div class="thead" #thead>
    <div class="row">
      <div class="col-11 title">{{'COVID Checkins' | translate}}</div>
      <div class="col-1 actions">
        <a class="btn btn-sm btn-outline-secondary float-right" (click)="refreshCheckinsList()">
          <i class="icon-arrows-cw fa-15x" [class.animate-spin]="listIsLoading$ | async"></i>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="row">
          <div class="col-2 p-0 bd-b-0-force"></div>
          <div class="col-10 p-0 bd-b-0-force bd-l-0-force">{{'Table' | translate}}</div>
        </div>
      </div>
      <div class="col-4">{{'Checkin On' | translate}}</div>
      <div class="col-4"></div>
    </div>
  </div>
  <div class="tbody">
    <ng-container *ngIf="listIsLoaded$ | async">
      <ng-container *ngIf="checkins$ | async as checkins">

        <div *ngIf="checkins.length === 0" class="row">
          <div class="col-12 text-center">
            {{'No results found.' | translate}}
          </div>
        </div>

        <div class="row" *ngFor="let checkin of checkins" style="word-wrap: break-word;">
          <div class="col-4">
            <div class="row">
              <div class="col-2 p-0">
                <input type="checkbox" [(ngModel)]="checkin.isSelected" />
              </div>
              <div class="col-10 p-0">
                {{ checkin.tabelName }}
              </div>
            </div>
          </div>

          <div class="col-4">
            {{ checkin.checkinOn | localDate:'DD-MM-YYYY HH:mm' }}
          </div>

          <div class="col-4">
            <a data-table-id="@item.Id" class="btn btn-sm btn-outline-primary mg-r-5" (click)="downloadAsPdf(checkin)">
              <i class="icon-file-pdf"></i>{{'Download as PDF' | translate}}
            </a>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="row" *ngIf="listIsLoading$ | async">
      <div class="ht-60 pos-relative align-items-center col-12">
        <owc-progress-wave></owc-progress-wave>
      </div>
    </div>

    <div class="row" *ngIf="listLoadingFailed$ | async">
      <div class="ht-50 pos-relative align-items-center col-12">
        <p class="loading-failed-text">{{'Could not load list of checkins. Check if you have Internet connection and try again.' | translate}}</p>
      </div>
    </div>
  </div>

  <div class="tfoot" *ngIf="listIsLoaded$ | async">
    <div class="row">
      <div class="col-6">
      </div>
      <div class="col-6 bd-l-0">
        <owc-pagination-block [visible$]="listIsLoaded$"
                              (filtersChanged)="pagingFiltersChanged($event)"
                              [disable$]="listLoadingFailed$"
                              [state$]="paginationState$">
        </owc-pagination-block>
      </div>
    </div>
  </div>
</div>
