import {Component, OnDestroy} from '@angular/core';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../util/form.utils';
import {FormBuilder} from '@angular/forms';
import {RestaurantDetailsFormDefinition} from './restaurant-details-form.definition';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {catchError, filter, finalize, first, map, mergeMap, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {RestaurantService} from '../../../../../services/restaurant.service';
import GetRestaurantResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.GetRestaurantResponse;
import {BehaviorSubject, from, Observable, of, Subject, throwError} from 'rxjs';
import {NeverError, ToastService, ProgressDialogService} from 'orderly-web-components';
import {RestaurantManagerRoutingConstants} from '../../../../routing-constants';
import AddOrUpdateRestaurantResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddOrUpdateRestaurantResponse;
import {
  trnCheckInternetAndTryAgainLaterText,
  trnUnknownErrorAndTryAgainLaterText
} from '../../../../../util/trn.utils';
import {TranslateService} from '@ngx-translate/core';
import AddRestaurantRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddRestaurantRequest;
import QrMenuActionTypeDef = Orderly.Common.Enums.QrMenuActionTypeDef;
import {NewFileData} from '../../../shared/components/file-upload/new-file-data';
import UpdateRestaurantRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.UpdateRestaurantRequest;
import {convertRestaurantInfoToEditorFormFieldsDefinitions} from '../restaurant-editor/converters';
import {
  RestaurantSuccessfullyCreatedAction,
  RestaurantSuccessfullyUpdatedAction
} from '../../../../store/actions/restaurant.actions';

@Component({
             selector: 'app-add-or-edit-restaurant',
             templateUrl: './add-or-edit-restaurant.component.html',
             styleUrls: ['./add-or-edit-restaurant.component.scss']
           })
export class AddOrEditRestaurantComponent implements OnDestroy {

  private readonly restaurantIdParam$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

  private readonly destroyed$: Subject<void> = new Subject<void>();

  public formDef: FormDefinition<keyof RestaurantDetailsFormDefinition>;

  constructor(private readonly formBuilder: FormBuilder,
              private readonly progressDialogService: ProgressDialogService,
              private readonly restaurantService: RestaurantService,
              private readonly router: Router,
              private readonly trnService: TranslateService,
              private toastService: ToastService,
              private readonly store: Store<AppState>,
              activatedRoute: ActivatedRoute) {

    activatedRoute.paramMap
                  .pipe(
                    map(pm => pm.get('restaurantId')),
                    filter((restaurantId: string | null) => restaurantId != null),
                    map((restaurantId: string) => parseInt(restaurantId, 10))
                  )
                  .subscribe(this.restaurantIdParam$);

    const fieldsDef: FormFieldsDefinition<keyof RestaurantDetailsFormDefinition> = {
      restaurant: new FormFieldDefinition(null, false, [], []),
    };

    this.formDef = new FormDefinition<keyof RestaurantDetailsFormDefinition>(fieldsDef, this.formBuilder);

    this.restaurantIdParam$
        .pipe(
          mergeMap((id) => {
            if (id == null) {
              return of(null);
            }

            const dlgToken = this.progressDialogService.display();

            return this.restaurantService
                       .getRestaurant(id)
                       .pipe(
                         tap((response: GetRestaurantResponse) => {
                           this.store.dispatch(new RestaurantSuccessfullyUpdatedAction(response.info.info));

                           const restaurantEditorFormDef = convertRestaurantInfoToEditorFormFieldsDefinitions(response.info.info);

                           this.formDef.patchValue({restaurant: restaurantEditorFormDef});

                           this.progressDialogService.hide(dlgToken);
                         }),
                         catchError((err) => {
                           this.progressDialogService.hide(dlgToken);

                           return throwError(err);
                         }),
                       );
          }),
          catchError((err, caught) => {
            this.toastService.showError(trnUnknownErrorAndTryAgainLaterText(this.trnService));

            return of(null);
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  private readonly processAddOrUpdateRestaurantResponse = (type: 'add' | 'update') => {
    return (response$: Observable<AddOrUpdateRestaurantResponse>): Observable<AddOrUpdateRestaurantResponse> => {

      return response$.pipe(
        tap(response => {
          switch (response.status) {
            case AddOrUpdateRestaurantResponse.StatusDef.UnknownFailure:
              this.toastService.showError(trnUnknownErrorAndTryAgainLaterText(this.trnService));
              break;
            case AddOrUpdateRestaurantResponse.StatusDef.Success:
              if(type === 'add') {
                this.store.dispatch(new RestaurantSuccessfullyCreatedAction(response.restaurant));
              } else {
                this.store.dispatch(new RestaurantSuccessfullyUpdatedAction(response.restaurant));
              }
              break;
            case AddOrUpdateRestaurantResponse.StatusDef.ValidationFailed:
              this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
              break;
            default:
              throw new NeverError(response.status);
          }
        }),
        first(),
        catchError(err => {
          if (err.status === 0) {
            const msg = trnCheckInternetAndTryAgainLaterText(this.trnService);

            this.toastService.showError(msg);
          } else {
            this.toastService.showError(trnUnknownErrorAndTryAgainLaterText(this.trnService));
          }

          return throwError(err);
        }),
      );
    };
  };


  public discard(): void {
    this.restaurantIdParam$
        .pipe(
          first(),
          map((id: number) => {
            return RestaurantManagerRoutingConstants.getRestaurantDashboardFullUrl(id);
          }),
          mergeMap((url) => {
            return from(this.router.navigateByUrl(url));
          }),
        )
        .subscribe();
  }

  public save(): void {
    const dlgToken = this.progressDialogService.display();

    this.restaurantIdParam$
      .pipe(
        switchMap((rid: number | null) => {
          return rid == null ? this.createRestaurantAction$() : this.updateRestaurantAction$(rid);
        }),
        first(),
        finalize(() => {
          this.progressDialogService.hide(dlgToken);
        }),
      )
      .subscribe();
  }


  private createRestaurantAction$(): Observable<AddOrUpdateRestaurantResponse> {
    const formValue: RestaurantDetailsFormDefinition = this.formDef.form.getRawValue();

    const request: AddRestaurantRequest = {
      address: formValue.restaurant.address.street,
      cityId: formValue.restaurant.address.cityId,
      contactEmail: formValue.restaurant.contactEmail!,
      contactPhone: formValue.restaurant.contactPhone!,
      name: formValue.restaurant.name!,
      zipCode: formValue.restaurant.address.zipCode!,
    };

    const disabledToken = this.formDef.disable('all-fields');

    return this.restaurantService
               .createNewRestaurant(request)
               .pipe(
                 this.processAddOrUpdateRestaurantResponse('add'),

                 finalize(() => {
                   disabledToken.reenable();
                 })
               );
  }

  private updateRestaurantAction$(restaurantId: number): Observable<AddOrUpdateRestaurantResponse> {
    const formValue: RestaurantDetailsFormDefinition = this.formDef.form.getRawValue();
    const qrMenuButtonAction: QrMenuActionTypeDef = formValue.restaurant.qrMenuSettings.qrMenuButtonAction == null
                                                    ? QrMenuActionTypeDef.CallWaiter
                                                    : formValue.restaurant.qrMenuSettings.qrMenuButtonAction;

    // Do not send file updates if user updated/removed the file and then changed action to 'call a waiter' or 'show online menu'.
    // It doesn't make sense to update the file that will never be shown.
    let menuFile: Orderly.Shared.Api.Messages.FileWithBase64Def | null = null;

    if (qrMenuButtonAction === QrMenuActionTypeDef.UploadedPdf) {
      if (formValue.restaurant.qrMenuSettings.menuFiles == null) {
        throw new Error('[qrMenuSettings.menuFiles] cannot be null');
      }

      const qrMenuFile = formValue.restaurant.qrMenuSettings.menuFiles[0];

      if (qrMenuFile instanceof NewFileData) {
        menuFile = {
          contentBase64: qrMenuFile.base64,
          name: qrMenuFile.name,
        };
      }
    }

    const menuTranslationLanguageIds = formValue.restaurant.menuSettings.translationLanguages.map(x => x.id);
    const request: UpdateRestaurantRequest = {
      address: formValue.restaurant.address.street,
      cityId: formValue.restaurant.address.cityId,
      contactEmail: formValue.restaurant.contactEmail!,
      contactPhone: formValue.restaurant.contactPhone!,
      name: formValue.restaurant.name!,
      zipCode: formValue.restaurant.address.zipCode!,
      qrMenuAction: qrMenuButtonAction,
      onlineReviewsEnabled: formValue.restaurant.onlineReviewsEnabled,
      defaultCurrencyId: formValue.restaurant.menuSettings.menuCurrency.id,
      menuDefaultLanguageId: formValue.restaurant.menuSettings.menuDefaultLanguageId!,
      menuFile: menuFile,
      menuFileUpdated: menuFile != null,
      guestsCanOrderFromQrMenu: formValue.restaurant.qrMenuSettings.isMenuReadonly,
      actionTokenValidityPeriod: formValue.restaurant.qrMenuSettings.actionTokenValidityPeriod,
      guestsCanOrderOnSelfServiceKioskDevice: formValue.restaurant.kioskSettings.guestsCanMakeOrders,
      selfServiceKioskCallWaiterEnabled: formValue.restaurant.kioskSettings.callWaiterEnabled,
      selfServiceKioskMenuItemGroupsListStyle: formValue.restaurant.kioskSettings.menuCategoriesListStyle,
      menuLanguageIds: menuTranslationLanguageIds,
    };

    return this.restaurantService
               .updateRestaurant(restaurantId, request)
               .pipe(
                 this.processAddOrUpdateRestaurantResponse('update'),
               );
  }
}
