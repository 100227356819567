import {AbstractControl, ValidationErrors, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';

export function isNotNullOrEmptyOrWhiteSpaceValidator(control: AbstractControl): ValidationErrors | null {

  if (control.invalid) {
    return null;
  }

  if (control.value == null) {

    return {isNotNullOrEmptyOrWhiteSpace: {value: control.value}};
  }

  if ((control.value || '').trim().length === 0) {

    return {isNotNullOrEmptyOrWhiteSpace: {value: control.value}};
  }

  return null;
}

export function mustMatch(controlName: string, matchingControlName: string, error: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {

      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({mustMatch: {message: error}});
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function greaterThanOrEqualValidator(controlName: string, greaterThanControlName: string, errorMessage: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const greaterThanControl = formGroup.controls[greaterThanControlName];

    if (control.value == null ||
        control.value === '' ||
        greaterThanControl.value == null ||
        greaterThanControl.value === '') {

      return;
    }

    try {
      const controlNumber: number = parseFloat(control.value);
      const greaterThanControlNumber: number = parseFloat(greaterThanControl.value);

      if (controlNumber > greaterThanControlNumber) {
        greaterThanControl.setErrors({greaterThanOrEqualValidator: {message: errorMessage}});
      }
    } catch {
      greaterThanControl.setErrors(null);
    }
  };
}

export function requiredIfSelectedValidator(controlName: string,
                                            cfg: { radioOrCheckboxControlName: string, selectedValue: any, message?: string }) {
  return (formGroup: FormGroup) => {
    const checkboxOrRadioControl = formGroup.controls[cfg.radioOrCheckboxControlName];
    const control = formGroup.controls[controlName];

    if (checkboxOrRadioControl.value === cfg.selectedValue) {
      if (control.value == null || control.value === '') {
        control.setErrors({requiredIfSelectedValidator: {message: cfg.message}});
      }  else {
        control.setErrors(null);
      }
    } else {
      control.setErrors(null);
    }
  };
}

export function requiredIfNotNullOrEmptyOrZeroValidator(controlName: string,
                                                        cfg: { primaryControlName: string }) {
  return (formGroup: FormGroup) => {
    const primaryControl = formGroup.controls[cfg.primaryControlName];
    const control = formGroup.controls[controlName];

    if (primaryControl.value !== 0 && primaryControl.value !== '' && primaryControl.value != null) {
      if (control.value == null || control.value === '' || control.value === 0) {
        control.setErrors({requiredIfSelectedValidator: true});
      }  else {
        control.setErrors(null);
      }
    } else {
      control.setErrors(null);
    }
  };
}

export function priceValidator(control: AbstractControl): ValidationErrors | null {

  if (control.value < 0) {

    return {priceValidator: {value: control.value}};
  }

  return {};
}

export function positiveOrZeroIntegerValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value == null || control.value === '') {

    return null;
  }

  if (/^\d+$/.test(control.value)) {
    if (parseInt(control.value, 10) >= 0) {
      return null;
    }
  }

  return {positiveOrZeroIntegerValidator: {value: control.value}};
}

export function zipCodeValidator(cityIdControlName: string, zipCodeControlName: string, trnService: TranslateService) {
  const validateViennaZipCodesFn = (zipCodeControl: AbstractControl) => {
    if (zipCodeControl.value == null || zipCodeControl.value === '') {
      zipCodeControl.setErrors({required: true});

      return;
    }

    const zipCode = zipCodeControl.value;
    const isNumberZipCode = /^\d{4}$/.test(zipCode);

    if (!isNumberZipCode) {
      // IMPORTANT! "_(...)" is required because translations extraction does not detect "trnService.instant(...)"!
      const errorMsg = trnService.instant(_('Wrong format'));
      zipCodeControl.setErrors({zipCode: {message: errorMsg}});

      return;
    }

    const zipCodeNumber = parseInt(zipCode, 10);

    if (zipCodeNumber !== 2100 && (zipCodeNumber < 1010 || zipCodeNumber > 1239)) {
      // IMPORTANT! "_(...)" is required because translations extraction does not detect "trnService.instant(...)"!
      const errorMsg = trnService.instant(_('Wrong ZIP code'));

      zipCodeControl.setErrors({zipCode: {message: trnService.instant(errorMsg)}});
    }
  };

  return (formGroup: FormGroup) => {
    const cityIdControl = formGroup.controls[cityIdControlName];
    const zipCodeControl = formGroup.controls[zipCodeControlName];

    if (cityIdControl.value == null) {
      return;
    }

    switch (cityIdControl.value) {
      case 2:
        validateViennaZipCodesFn(zipCodeControl);
        break;
    }
  };
}


export class ImageFileTypeValidator {

  public static validateImageFileExtension(name: string): boolean {

    if (name) {
      const valToLower: string = name.toLowerCase();
      const extRegex = new RegExp('(.*?)\.(jpg|png|jpeg)$');
      const extRegexResult = extRegex.test(valToLower);

      return extRegexResult;
    }

    return true;
  }

  public static validatePdfFileExtension(name: string): boolean {

    if (name) {
      const valToLower: string = name.toLowerCase();
      const extRegex = new RegExp('(.*?)\.(pdf)$');
      const extRegexResult = extRegex.test(valToLower);

      return extRegexResult;
    }

    return true;
  }


  public validateControlAsImage(fileNameControlName: string, trnService: TranslateService) {
    return (formGroup: FormGroup) => {
      const fileNameControl = formGroup.controls[fileNameControlName];

      if (fileNameControl.value == null || fileNameControl.invalid) {
        return;
      }

      if (typeof fileNameControl.value === 'string') {
        const extRegexResult = ImageFileTypeValidator.validateImageFileExtension(fileNameControl.value);

        if (!extRegexResult) {
          const error = trnService.instant(_('File {{fileName}} is not an image.'), {fileName: fileNameControl.value});

          fileNameControl.setErrors({wrongFileExtension: {message: error}});
        }
      }
    };
  }

  public validateControlAsPdf(fileNameControlName: string, trnService: TranslateService) {
    return (formGroup: FormGroup) => {
      const fileNameControl = formGroup.controls[fileNameControlName];

      if (fileNameControl.value == null || fileNameControl.invalid) {
        return;
      }

      if (typeof fileNameControl.value === 'string') {
        const extRegexResult = ImageFileTypeValidator.validatePdfFileExtension(fileNameControl.value);

        if (!extRegexResult) {
          const error = trnService.instant(_('File {{fileName}} is not PDF.'), {fileName: fileNameControl.value});

          fileNameControl.setErrors({wrongFileExtension: {message: error}});
        }
      }
    };
  }
}
