<div class="areas-list-table div-based-table table-dark table-colored">
  <div class="thead" #thead>
    <div class="row">
      <div class="col-6 title">{{'Opened requests' | translate}} {{'(last 150)' | translate}}</div>
      <div class="col-6 actions">
        <div class="float-right">
          <ng-container *ngIf="lastLoadedOn$ | async as loadedOn">
            <span *ngIf="loadedOn != null">{{'last loaded on' | translate}} {{loadedOn | localDate:'HH:mm'}}</span>
          </ng-container>

          <a class="btn btn-sm btn-outline-secondary" (click)="refreshRequestsList()">
            <i class="icon-arrows-cw fa-15x" [class.animate-spin]="listIsLoading$ | async"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">{{'Area' | translate}}</div>
      <div class="col-3">{{'Table' | translate}}</div>
      <div class="col-2">{{'Request type' | translate}}</div>
      <div class="col-2">{{'Requested on' | translate}}</div>
      <div class="col-2"></div>
    </div>
  </div>
  <div class="tbody">
    <ng-container *ngIf="listIsLoaded$ | async">
      <ng-container *ngIf="displayedGridItems$ | async as reqs">
        <div *ngIf="reqs.length === 0" class="row">
          <div class="col-12 text-center">
            {{ 'No opened requests' | translate }}
          </div>
        </div>

        <div class="row" *ngFor="let request of reqs" style="word-wrap: break-word;">
          <div class="col-3">{{ request.table.area.name }}</div>
          <div class="col-3">{{ request.table.name }}</div>
          <div class="col-2">
            <span *ngIf="request.typeId === 1">{{'Bring the menu' | translate}}</span>
            <span *ngIf="request.typeId === 2">{{'Want to pay' | translate}}</span>
            <span *ngIf="request.typeId === 3">{{'Other' | translate}}</span>
            <span *ngIf="request.typeId > 3">{{request.typeId}}</span>
          </div>
          <div class="col-2">
            {{ request.createdOn | localDate:'HH:mm' }}
          </div>
          <div class="col-2">
            <ng-container *ngIf="userIsManager | async">

            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="row" *ngIf="listIsLoading$ | async">
      <div class="ht-60 pos-relative align-items-center col-12">
        <owc-progress-wave></owc-progress-wave>
      </div>
    </div>

    <div class="row" *ngIf="listLoadingFailed$ | async">
      <div class="ht-50 pos-relative align-items-center col-12">
        <p class="loading-failed-text">{{'Could not load restaurant areas list. Check if you have Internet connection and try again.' | translate}}</p>
      </div>
    </div>
  </div>

  <ng-container *ngIf="paginationState$ | async">
    <div class="tfoot" *ngIf="listIsLoaded$ | async">
      <div class="row">
        <div class="col-4">
        </div>
        <div class="col-8 bd-l-0">
          <owc-pagination-block [visible$]="listIsLoaded$"
                                (filtersChanged)="pagingFiltersChanged($event)"
                                [disable$]="listLoadingFailed$"
                                [state$]="paginationState$">
          </owc-pagination-block>
        </div>
      </div>
    </div>
  </ng-container>
</div>
