import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {RestaurantComponent} from '../../../restaurant.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {ActivatedRoute} from '@angular/router';
import {CurrentRestaurantMenuItemsService} from '../../../../services/active-route-bound/current-restaurant-menu-items.service';
import {TranslateService} from '@ngx-translate/core';
import {NeverError, ToastService} from 'orderly-web-components';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MenuItemModifierGroupRow} from '../menu-items-list.component';
import MenuItemWithAllData = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData;
import {catchError, first, tap} from 'rxjs/operators';
import {AddOrEditMenuItemModifierGroupComponent} from '../../../add-or-edit-menu-item-modifier-group/add-or-edit-menu-item-modifier-group.component';
import MenuItemModifierGroupWithModifiers = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupWithModifiers;
import MenuItemModifierGroupBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupBasic;
import AddOrUpdateMenuItemModifierGroupResponse =
  Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.AddOrUpdateMenuItemModifierGroupResponse;
import {genericErrorHandlerWithToast} from '../../../../util/utils';
import DeleteMenuItemModifierGroupResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.DeleteMenuItemModifierGroupResponse;
import DeleteMenuItemModifierGroupResponseStatusDef =
  Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.DeleteMenuItemModifierGroupResponse.StatusDef;
import {DeleteModalUtils} from '../../../../util/delete-modal.utils';

@Component({
             selector: 'app-modifier-groups-table',
             templateUrl: './modifier-groups-table.component.html',
             styleUrls: ['./modifier-groups-table.component.scss'],
             encapsulation: ViewEncapsulation.None,
             changeDetection: ChangeDetectionStrategy.OnPush,
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: forwardRef(() => ModifierGroupsTableComponent),
                 multi: true
               }
             ]
           })
export class ModifierGroupsTableComponent extends RestaurantComponent implements ControlValueAccessor {

  private menuItem: MenuItemWithAllData;

  public modifierGroupRows: MenuItemModifierGroupRow[] = [];

  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private readonly restaurantMenuService: CurrentRestaurantMenuItemsService,
              private readonly trnService: TranslateService,
              private readonly toastService: ToastService,
              private readonly modalService: NgbModal,
              private readonly deleteModalUtils: DeleteModalUtils,
              private readonly ref: ChangeDetectorRef) {

    super(store, activatedRoute);

  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };


  get value(): MenuItemWithAllData {
    return this.menuItem;
  };

  set value(v: MenuItemWithAllData) {
    if (v !== this.menuItem) {
      this.menuItem = v;
      this.modifierGroupRows = this.convertModifierGroups(v);
      this.onChange(v);
    }
  }

  writeValue(value: MenuItemWithAllData) {

    // additional null check is required because of
    // https://github.com/angular/angular/issues/14988
    if (value != null) {
      if (value !== this.menuItem) {
        this.menuItem = value;
        this.modifierGroupRows = this.convertModifierGroups(value);

        this.ref.markForCheck();
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }


  public addMenuItemModifierGroup() {
    this.restaurantIdParam$
        .pipe(
          first(),
          tap(restaurantId => {
            const modalRef = this.modalService.open(AddOrEditMenuItemModifierGroupComponent,
                                                    {centered: true, size: 'lg'});

            const modalInstance: AddOrEditMenuItemModifierGroupComponent = modalRef.componentInstance;

            modalInstance.menuItemId = this.menuItem.id;
            modalInstance.restaurantId = restaurantId;

            const completedSubscription = modalInstance.completed
                                                       .subscribe(($e: AddOrUpdateMenuItemModifierGroupResponse) => {
                                                         if ($e.status ===
                                                             AddOrUpdateMenuItemModifierGroupResponse.StatusDef.Success) {

                                                           this.handleModifierGroupAddOrUpdateResponse($e.group);
                                                         }
                                                       });

            modalRef.result.finally(() => {
              completedSubscription.unsubscribe();
            });
          })
        )
        .subscribe();
  }

  private handleModifierGroupAddOrUpdateResponse(savedGroup: MenuItemModifierGroupBasic) {

    const groupTmp: MenuItemModifierGroupWithModifiers = {
      nameIsVisibleToUser: savedGroup.nameIsVisibleToUser,
      isActive: savedGroup.isActive,
      id: savedGroup.id,
      maxAllowedAmount: savedGroup.maxAllowedAmount,
      minAllowedAmount: savedGroup.minAllowedAmount,
      name: savedGroup.name,
      menuItemModifiers: [],
      translations: [],
    };

    this.modifierGroupRows.push(new MenuItemModifierGroupRow(groupTmp, this.menuItem));

    this.ref.markForCheck();
  }


  private convertModifierGroups(menuItem: MenuItemWithAllData): MenuItemModifierGroupRow[] {
    return menuItem.modifierGroups.map(x => new MenuItemModifierGroupRow(x, menuItem));
  }


  public deleteMenuItemModifierGroup(group: MenuItemModifierGroupRow) {

    const onDelete = (modalRef: NgbModalRef) => {
      const unexpectedFailureText = this.trnService.instant('Failed to remove a modifier group because of unexpected failure. Please try again later.');

      this.restaurantMenuService
          .deleteMenuItemModifierGroup(group.parent.id, group.data.id)
          .pipe(
            tap((response: DeleteMenuItemModifierGroupResponse) => {
              switch (response.status) {
                case DeleteMenuItemModifierGroupResponseStatusDef.Success:
                  modalRef.close(true);

                  this.modifierGroupRows = this.modifierGroupRows.filter(value => value.data.id !== group.data.id);

                  this.ref.markForCheck();
                  break;
                case DeleteMenuItemModifierGroupResponseStatusDef.UnknownFailure:
                  this.toastService.showError(unexpectedFailureText);
                  break;
                default:
                  throw new NeverError(response.status);
              }
            }),
            first(),
            catchError(
              genericErrorHandlerWithToast<DeleteMenuItemModifierGroupResponse>(this.toastService, this.trnService, unexpectedFailureText)
            )
          )
          .subscribe();
    };

    const confirmationMsgCommon = this.trnService.instant('Are you sure you want to remove a modifier group "{{name}}"?', {name: group.data.name});
    const confirmationMsg: string[] = [confirmationMsgCommon];

    this.deleteModalUtils.showDeleteModal(group.data,
                                          this.trnService.instant('Delete a modifier group'),
                                          confirmationMsg,
                                          onDelete);
  }


}
