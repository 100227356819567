<div class="d-flex align-items-center justify-content-center bg-br-primary ht-100v">

  <div class="login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-white rounded shadow-base">
    <div class="signin-logo tx-center tx-28 tx-bold tx-inverse pd-md-b-20"><span class="tx-normal">[</span>MY<span class="tx-info">CAFY</span> <span class="tx-normal">]</span></div>

    <form [formGroup]="form" (ngSubmit)="login()">

      <div class="form-group">
        <input type="text" formControlName="email" class="form-control" [placeholder]="'E-Mail' | translate" />

        <owc-input-validation-errors [control]="form.get('email')"></owc-input-validation-errors>
      </div>

      <div class="form-group">
        <input type="password" formControlName="password" class="form-control" [placeholder]="'Password' | translate">

        <owc-input-validation-errors [control]="form.get('password')"></owc-input-validation-errors>
      </div>

      <owc-progress-action-button type="submit"
                                  [btnText]="'Sign In' | translate"
                                  [btnClass]="'btn-info btn-block'"
                                  (execute)="login()"
                                  [actionInProgress]="disableControls"
                                  [disabled]="form.invalid"
                                  class="d-block">
      </owc-progress-action-button>

      <div class="mg-t-30 tx-right" *ngIf="cfg && cfg.forgotPasswordUrl">
        <a [routerLink]="cfg.forgotPasswordUrl">{{'Forgot password' | translate}}</a>
      </div>

      <div class="mg-t-30 tx-right" *ngIf="cfg && cfg.registrationUrl">
        <a [routerLink]="cfg.registrationUrl">{{'Registration' | translate}}</a>
      </div>
    </form>
  </div><!-- login-wrapper -->
</div><!-- d-flex -->
