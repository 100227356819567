<ng-select [items]="currencies$ | async"
           [disabled]="isDisabled"
           [searchable]="false"
           [clearable]="false"
           [ngModel]="lastValue"
           (ngModelChange)="changed($event)"
           [placeholder]="translatedPlaceholder$ | async"
           [compareWith]="compareItems"
           bindLabel="code3">
</ng-select>
