<ng-container *ngIf="groupRow != null && groupRow.data != null">
  <div class="row modifier-group-row">
    <div class="col-3">{{groupRow.data.name}}</div>
    <div class="col-1">
      <i class="icon-ok" *ngIf="groupRow.data.isActive"></i>
    </div>
    <div class="col-3">
      <i class="icon-eye" *ngIf="groupRow.data.nameIsVisibleToUser"></i>
      <i class="icon-eye-off" *ngIf="groupRow.data.nameIsVisibleToUser === false"></i>
    </div>
    <div class="col-2">{{getSelectableModifiersCountText(groupRow.data)}}</div>
    <div class="col-3">
      <ng-container *ngIf="userIsManager | async">
        <a class="btn btn-sm btn-danger text-white mg-r-10" (click)="deleteGroup.emit(groupRow)"><i class="icon-trash-empty"></i></a>
      </ng-container>
    </div>
  </div>
  <div class="div-based-table table-dark table-colored" *ngIf="groupRow.data.menuItemModifiers.length > 0">
    <div class="thead">
      <div class="row modifier-data-header">
        <div class="col-4 offset-1">{{'Modifier name or ingredient' | translate}}</div>
        <div class="col-2">{{'Amount' | translate}}</div>
        <div class="col-2">{{'Price diff' | translate}}</div>
        <div class="col-3"></div>
      </div>
    </div>
    <div class="tbody">
      <div class="row modifier-data-row" *ngFor="let modifier of groupRow.data.menuItemModifiers">
        <div class="col-4 offset-1" [class.is-active]="modifier.isActive">

          <ng-container *ngIf="modifier.ingredient != null">
            {{modifier.ingredient.name}}
          </ng-container>

          <ng-container *ngIf="modifier.ingredient == null">
            {{modifier.name}}
          </ng-container>

        </div>
        <div class="col-2">

          {{modifier.amount}}

          <ng-container *ngIf="modifier.amount != null && modifier.ingredient != null">{{modifier.ingredient.measureUnit.name | translate}}</ng-container>
          <ng-container *ngIf="modifier.amount != null && modifier.measureUnit != null">{{modifier.measureUnit.name | translate}}</ng-container>

        </div>
        <div class="col-2">
          <ng-container *ngIf="modifier.priceDiff > 0"><strong>+</strong></ng-container>
          <ng-container *ngIf="modifier.priceDiff < 0"><strong>-</strong></ng-container>

          {{modifier.priceDiff | currency:groupRow.parent.currency.code3:'symbol-narrow'}}
        </div>
        <div class="col-3">
          <ng-container *ngIf="userIsManager | async">
            <a class="btn btn-sm btn-danger text-white" (click)="deleteMenuItemModifier(modifier)"><i class="icon-trash-empty"></i></a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
