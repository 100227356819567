import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../util/form.utils';
import {isNotNullOrEmptyOrWhiteSpaceValidator, NeverError, ToastService} from 'orderly-web-components';
import {FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {BooleanSelectItem} from '../add-or-edit-ingredient/helper-classes';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {catchError, filter, finalize, first, map, switchMap, tap} from 'rxjs/operators';
import {CurrentRestaurantAreasService} from '../../services/active-route-bound/current-restaurant-areas.service';
import {trnTryAgainLaterText} from '../../util/trn.utils';
import {genericErrorHandlerWithToast, nameof} from '../../util/utils';
import {TableCreationSuccessfulAction} from '../store/actions/table.actions';
import {CurrentRestaurantTablesService} from '../../services/active-route-bound/current-restaurant-tables.service';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';
import {ActivatedRoute, Router} from '@angular/router';
import {RestaurantManagerRoutingConstants} from '../routing-constants';
import {
  RestaurantOnboardingCompletedAction
} from '../store/actions/restaurant.actions';
import {getAuthenticatedUserSelector} from '../store/selectors/restaurant-selectors';
import {RestaurantComponent} from '../restaurant.component';
import {AreaCreationSuccessfulAction} from '../store/actions/area.actions';
import RestaurantArea = Orderly.RestaurantWeb.Api.Messages.RestaurantArea;
import AddTableResponse = Orderly.RestaurantWeb.Api.Messages.Table.AddTableResponse;
import AddOrUpdateRestaurantAreaResponse = Orderly.RestaurantWeb.Api.Messages.Area.AddOrUpdateRestaurantAreaResponse;
import AddTableRequest = Orderly.RestaurantWeb.Api.Messages.Table.AddTableRequest;
import AddRestaurantAreaRequest = Orderly.RestaurantWeb.Api.Messages.Area.AddRestaurantAreaRequest;
import RestaurantShortInfo = Orderly.RestaurantWeb.Api.Messages.RestaurantShortInfo;
import {RestaurantEditorComponent} from '../modules/restaurants/components/restaurant-editor/restaurant-editor.component';


type AreaFormFieldsDef = 'name' | 'isSmokingArea' | 'isOutdoor';
type TableFormFieldsDef = 'name';

@Component({
  selector: 'app-first-steps-wizard',
  templateUrl: './first-steps-wizard.component.html',
  styleUrls: ['./first-steps-wizard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FirstStepsWizardComponent extends RestaurantComponent implements OnInit {

  @ViewChild('restaurantForm', {static: false})
  private restaurantForm: RestaurantEditorComponent;

  @Input()
  public createRestaurant: boolean = false;

  public newRestaurant: RestaurantShortInfo;
  public newArea: RestaurantArea;

  public tableCreated: boolean = false;

  public actionInProgress: boolean = false;

  public areaFormDef: FormDefinition<AreaFormFieldsDef>;
  public tableFormDef: FormDefinition<TableFormFieldsDef>;

  public isOutdoorSelectItems$: Observable<BooleanSelectItem[]>;
  public isSmokingAreaSelectItems$: Observable<BooleanSelectItem[]>;

  public activeTab: 'restaurantTab' | 'areaTab' | 'tableTab' | 'guideTab' = 'areaTab';

  constructor(
    private formBuilder: FormBuilder,
    private trnService: TranslateService,
    private restaurantAreasService: CurrentRestaurantAreasService,
    private tableService: CurrentRestaurantTablesService,
    private toastService: ToastService,
    router: Router,
    store: Store<AppState>,
    activatedRoute: ActivatedRoute) {

    super(store, activatedRoute);

    this.areaFormDef = this.buildAreaForm();
    this.tableFormDef = this.buildTableForm();

    this.initTranslations();
  }

  ngOnInit(): void {
    if (this.createRestaurant) {

      this.store
          .pipe(
            map(x => x.auth.currentUser!.user.restaurants),
            tap((restaurants: RestaurantShortInfo[]) => {
              if (restaurants.length === 0) {
                this.activeTab = 'restaurantTab';
              } else {
                this.activeTab = 'areaTab';
                this.newRestaurant = restaurants[0];
              }
            }),
            first()
          )
          .subscribe();
    }
  }

  private buildTableForm() {
    const fieldsDef: FormFieldsDefinition<TableFormFieldsDef> = {
      name: new FormFieldDefinition(null,
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.maxLength(50)],
                                    [
                                      nameof<AddTableRequest>('name')
                                    ])
    };

    return new FormDefinition(fieldsDef, this.formBuilder);
  }

  private buildAreaForm() {
    const fieldsDef: FormFieldsDefinition<AreaFormFieldsDef> = {
      name: new FormFieldDefinition(null,
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.maxLength(50)],
                                    [nameof<AddRestaurantAreaRequest>('name')]),
      isOutdoor: new FormFieldDefinition(null,
                                         false,
                                         [Validators.required],
                                         [nameof<AddRestaurantAreaRequest>('isOutdoor')]),
      isSmokingArea: new FormFieldDefinition(null,
                                             false,
                                             [Validators.required],
                                             [nameof<AddRestaurantAreaRequest>('isSmokingArea')])
    };

    return new FormDefinition(fieldsDef, this.formBuilder);
  }

  private initTranslations() {
    const booleanTranslations: {yesText: string, noText: string} = {
      noText: _('No'),
      yesText: _('Yes')
    };

    this.isOutdoorSelectItems$ = this.trnService
                                     .stream([
                                               booleanTranslations.yesText, booleanTranslations.noText
                                             ])
                                     .pipe(
                                       map((x: []) => {
                                         const result: BooleanSelectItem[] = [
                                           new BooleanSelectItem(true, x[booleanTranslations.yesText]),
                                           new BooleanSelectItem(false, x[booleanTranslations.noText])
                                         ];

                                         return result;
                                       })
                                     );
    this.isSmokingAreaSelectItems$ = this.trnService
                                         .stream([
                                                   booleanTranslations.yesText, booleanTranslations.noText
                                                 ])
                                         .pipe(
                                           map((x: []) => {
                                             const result: BooleanSelectItem[] = [
                                               new BooleanSelectItem(true, x[booleanTranslations.yesText]),
                                               new BooleanSelectItem(false, x[booleanTranslations.noText])
                                             ];

                                             return result;
                                           })
                                         );
  }

  public getTerminalsListUrl(): Observable<string> {
    const rid$ = this.newRestaurant == null ? this.restaurantIdParam$ : of(this.newRestaurant.id);

    return rid$.pipe(
      map(rid => '/' + RestaurantManagerRoutingConstants.getRestaurantTerminalsListUrl(rid))
    );
  }

  public getTablesListUrl(): Observable<string> {
    const rid$ = this.newRestaurant == null ? this.restaurantIdParam$ : of(this.newRestaurant.id);

    return rid$.pipe(
      map(rid => '/' + RestaurantManagerRoutingConstants.getRestaurantTablesCreateUrl(rid))
    );
  }

  public getWaitersUrl(): Observable<string> {
    const rid$ = this.newRestaurant == null ? this.restaurantIdParam$ : of(this.newRestaurant.id);

    return rid$.pipe(
      map(rid => '/' + RestaurantManagerRoutingConstants.getRestaurantWaitersCreateUrl(rid))
    );
  }

  public getReviewsUrl(): Observable<string> {
    const rid$ = this.newRestaurant == null ? this.restaurantIdParam$ : of(this.newRestaurant.id);

    return rid$.pipe(
      map(rid => '/' + RestaurantManagerRoutingConstants.getRestaurantReviewsListUrl(rid))
    );
  }

  public getMenuItemsUrl(): Observable<string> {
    const rid$ = this.newRestaurant == null ? this.restaurantIdParam$ : of(this.newRestaurant.id);

    return rid$.pipe(
      map(rid => '/' + RestaurantManagerRoutingConstants.getRestaurantMenuItemsListUrl(rid))
    );
  }


  public saveRestaurant() {
    if (this.restaurantForm.formDef.form.invalid) {
      return;
    }

    this.actionInProgress = true;
    //
    // this.restaurantForm
    //     .saveAction$
    //     .pipe(
    //       tap((x: AddOrUpdateRestaurantResponse) => {
    //         switch (x.status) {
    //           case AddOrUpdateRestaurantResponse.StatusDef.UnknownFailure:
    //           case AddOrUpdateRestaurantResponse.StatusDef.ValidationFailed:
    //             break;
    //           case AddOrUpdateRestaurantResponse.StatusDef.Success:
    //             this.newRestaurant = x.restaurant;
    //             this.activeTab = 'areaTab';
    //
    //             this.store.dispatch(new RestaurantSuccessfullyCreatedAction(x.restaurant));
    //
    //             break;
    //           default:
    //             throw new NeverError(x.status);
    //         }
    //       }),
    //       first(),
    //       finalize(() => {
    //         this.actionInProgress = false;
    //       })
    //     )
    //     .subscribe();
  }

  public saveArea() {
    const isSmokingArea: boolean = this.areaFormDef.getControl('isSmokingArea').value;
    const isOutdoor: boolean = this.areaFormDef.getControl('isOutdoor').value;
    const name: string = this.areaFormDef.getControl('name').value;

    const request: AddRestaurantAreaRequest = {
      isSmokingArea: isSmokingArea,
      isOutdoor: isOutdoor,
      name: name
    };

    const response$ = this.newRestaurant == null
                      ? this.restaurantAreasService.add(request)
                      : this.restaurantAreasService.addForRestaurant(this.newRestaurant.id, request);

    this.processAddOrUpdateResponse(response$);
  }

  public saveTable() {
    if (this.tableFormDef.form.invalid) {
      return;
    }

    const name: string = this.tableFormDef.getControl('name').value;

    const requestData: AddTableRequest = {
      name: name,
      areaId: this.newArea.id
    };

    this.actionInProgress = true;
    const disableFieldsToken = this.tableFormDef.disable('all-fields');
    const unexpectedServerFailureMsg = this.trnService.instant('Failed to save a table because of unexpected failure.');
    const action$ = this.newRestaurant == null
                    ? this.tableService.add(requestData)
                    : this.tableService.addForRestaurant(this.newRestaurant.id, requestData);


    action$.pipe(
      tap((response: AddTableResponse) => {
        switch (response.status) {
          case AddTableResponse.StatusDef.Success:
            this.store.dispatch(new TableCreationSuccessfulAction(response));

            this.activeTab = 'guideTab';
            this.tableCreated = true;

            break;
          case AddTableResponse.StatusDef.ValidationFailed:
            disableFieldsToken.reenable();

            this.tableFormDef.setFormFieldsServerValidationResults(response.validationErrors);
            break;
          case AddTableResponse.StatusDef.TableWithSameNameExists:
            disableFieldsToken.reenable();

            const tableError = this.trnService.instant('Table with this name already exists.');
            this.tableFormDef.getControl('name').setErrors({serverValidation: {messages: [tableError]}});
            break;
          case AddTableResponse.StatusDef.UnexpectedError:
            this.toastService.showError(unexpectedServerFailureMsg);
            break;
          default:
            throw new NeverError(response.status);
        }
      }),
      filter((response: AddTableResponse) => response.status === AddTableResponse.StatusDef.Success),
      switchMap((response: AddTableResponse) => {
        return this.store.select(getAuthenticatedUserSelector);
      }),
      first(),
      tap((user: Orderly.RestaurantWeb.Api.Messages.UserWithRestaurantsInfo) => {
        this.store.dispatch(new RestaurantOnboardingCompletedAction(user.holdingInfo.id));
      }),
      catchError(
        genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedServerFailureMsg)
      ),
      finalize(() => {
        this.actionInProgress = false;
      })
           )
           .subscribe();
  }

  // TODO: copy paste
  private processAddOrUpdateResponse(response$: Observable<AddOrUpdateRestaurantAreaResponse>) {

    const tryAgainLaterMsg = trnTryAgainLaterText(this.trnService);
    const unexpectedServerFailureMsg = this.trnService.instant('Failed to save a restaurant area because of unexpected failure.') + ' ' + tryAgainLaterMsg;

    const reenableToken = this.areaFormDef.disable('all-fields');
    this.actionInProgress = true;

    response$.pipe(
      first(),
      tap((response: AddOrUpdateRestaurantAreaResponse) => {
        switch (response.status) {
          case AddOrUpdateRestaurantAreaResponse.StatusDef.ValidationFailed:
            reenableToken.reenable();
            this.areaFormDef.setFormFieldsServerValidationResults(response.validationErrors);
            break;
          case AddOrUpdateRestaurantAreaResponse.StatusDef.Success:
            this.newArea = response.area;

            this.activeTab = 'tableTab';

            this.store.dispatch(new AreaCreationSuccessfulAction(response));
            break;
          case AddOrUpdateRestaurantAreaResponse.StatusDef.AreaWithSameNameExists:
            const areaWithSameNameExistsTxt = this.trnService.instant('Restaurant area with same name already exists.');
            this.areaFormDef.addControlError('name', [areaWithSameNameExistsTxt]);
            break;
          case AddOrUpdateRestaurantAreaResponse.StatusDef.UnknownFailure:
            this.toastService.showError(unexpectedServerFailureMsg);
            break;
          default:
            throw new NeverError(response.status);
        }
      }),
      catchError(
        genericErrorHandlerWithToast<AddOrUpdateRestaurantAreaResponse>(this.toastService,
                                                                        this.trnService,
                                                                        unexpectedServerFailureMsg)
      ),
      finalize(() => {
        this.actionInProgress = false;

        reenableToken.reenable();
      })
             )
             .subscribe();
  }
}
