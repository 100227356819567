import {Injectable} from '@angular/core';
import {CurrentRestaurantServiceBase} from './current-restaurant.service.base';
import Display = Orderly.RestaurantWeb.Api.Messages.Display.GetDisplaysResponse.Display;
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {RestaurantSpecificArrayResultLoadingState} from './helper.statuses';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {map, switchMap} from 'rxjs/operators';
import GetDisplaysResponse = Orderly.RestaurantWeb.Api.Messages.Display.GetDisplaysResponse;
import ChangeLinkDisplayStatusResponse = Orderly.RestaurantWeb.Api.Messages.Display.ChangeLinkDisplayStatusResponse;
import LinkDisplayToTableRequest = Orderly.RestaurantWeb.Api.Messages.Display.LinkDisplayToTableRequest;
import ReportBrokenDisplayResponse = Orderly.RestaurantWeb.Api.Messages.Display.ReportBrokenDisplayResponse;
import ReportBrokenDisplayRequest = Orderly.RestaurantWeb.Api.Messages.Display.ReportBrokenDisplayRequest;
import UnlinkDisplayFromTableRequest = Orderly.RestaurantWeb.Api.Messages.Display.UnlinkDisplayFromTableRequest;

@Injectable({
              providedIn: 'root'
            })
export class CurrentRestaurantDisplaysService extends CurrentRestaurantServiceBase<Display> {
  private baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  public get currentRestaurantDisplays$(): Observable<RestaurantSpecificArrayResultLoadingState<Display>> {
    return this.dataFeed$;
  }

  constructor(private httpClient: HttpClient, store: Store<AppState>) {
    super(store);
  }

  protected doLoadData(restaurantId: number): Observable<Display[]> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/displays/all`;
    const response$ = this.httpClient.get<GetDisplaysResponse>(url);

    return response$.pipe(map(x => x.displays));
  }


  public reportBroken(displayId: number, data: ReportBrokenDisplayRequest): Observable<ReportBrokenDisplayResponse> {

    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap(restaurantId => {
                   const url: string = this.baseURL + `/display/${restaurantId}/report-broken/${displayId}`;

                   return this.httpClient.post<ReportBrokenDisplayResponse>(url, data);
                 })
               );
  }


  public link(data: LinkDisplayToTableRequest): Observable<ChangeLinkDisplayStatusResponse> {

    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap(restaurantId => {
                   const url: string = this.baseURL + `/display/${restaurantId}/link`;

                   return this.httpClient.post<ChangeLinkDisplayStatusResponse>(url, data);
                 })
               );
  }


  public unlink(data: UnlinkDisplayFromTableRequest): Observable<ChangeLinkDisplayStatusResponse> {

    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap(restaurantId => {
                   const url: string = this.baseURL + `/display/${restaurantId}/unlink`;

                   return this.httpClient.post<ChangeLinkDisplayStatusResponse>(url, data);
                 })
               );
  }
}
