/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-bar-with-language-switcher.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "./top-bar-with-language-switcher.component";
import * as i5 from "@ngrx/store";
import * as i6 from "orderly-web-components";
import * as i7 from "@ngx-translate/core";
var styles_TopBarWithLanguageSwitcherComponent = [i0.styles];
var RenderType_TopBarWithLanguageSwitcherComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopBarWithLanguageSwitcherComponent, data: {} });
export { RenderType_TopBarWithLanguageSwitcherComponent as RenderType_TopBarWithLanguageSwitcherComponent };
function View_TopBarWithLanguageSwitcherComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "mg-r-5"], ["height", "17"], ["style", "display: inline;"], ["width", "23"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "../../assets/lang-flags/", _v.parent.context.ngIf.code, ".png"); var currVal_1 = _v.parent.context.ngIf.name; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TopBarWithLanguageSwitcherComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "logged-name hidden-md-down"], ["style", "display: inline;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.name; _ck(_v, 1, 0, currVal_0); }); }
function View_TopBarWithLanguageSwitcherComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "icon-angle-right mg-r-8"]], null, null, null, null, null))], null, null); }
function View_TopBarWithLanguageSwitcherComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "mg-r-5"], ["height", "17"], ["style", "display: inline;"], ["width", "23"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "../../assets/lang-flags/", _v.parent.context.$implicit.code, ".png"); var currVal_1 = _v.parent.context.$implicit.name; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TopBarWithLanguageSwitcherComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["class", "lang-item"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onChangeLanguage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarWithLanguageSwitcherComponent_6)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarWithLanguageSwitcherComponent_7)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(6, null, [" ", ""]))], function (_ck, _v) { var currVal_1 = ((_v.parent.parent.context.ngIf != null) && (_v.context.$implicit.code == _v.parent.parent.context.ngIf.code)); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_v.parent.parent.context.ngIf != null) && (_v.context.$implicit.code != _v.parent.parent.context.ngIf.code)); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.ngIf != null) && (_v.context.$implicit.code == _v.parent.parent.context.ngIf.code)); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_3); }); }
function View_TopBarWithLanguageSwitcherComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0]], null, 5, "div", [["class", "dropdown-menu-header wd-250"], ["ngbDropdownMenu", ""], ["style", "position: absolute; transform: translate3d(-65px, 48px, 0px); top: 0px; left: 0px; will-change: transform;"], ["x-placement", "bottom-start"]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 1).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, [[1, 4]], 1, i3.NgbDropdownMenu, [i3.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [["class", "list-unstyled languages-menu"], ["x-placement", "bottom-start"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarWithLanguageSwitcherComponent_5)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_3 = _v.context.ngIf; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).dropdown.isOpen(); var currVal_2 = i1.ɵnov(_v, 1).placement; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_TopBarWithLanguageSwitcherComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(1, 1720320, null, 3, i3.NgbDropdown, [i1.ChangeDetectorRef, i3.NgbDropdownConfig, i2.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i3.NgbNavbar]], null, null), i1.ɵqud(603979776, 1, { _menu: 0 }), i1.ɵqud(603979776, 2, { _menuElement: 0 }), i1.ɵqud(603979776, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 6, "a", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "nav-link nav-link-profile dropdown-toggle"], ["href", "javascript:void(0);"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i3.NgbDropdownToggle, [i3.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i3.NgbDropdownAnchor, null, [i3.NgbDropdownToggle]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarWithLanguageSwitcherComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopBarWithLanguageSwitcherComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TopBarWithLanguageSwitcherComponent_4)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_v.context.ngIf != null); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_v.context.ngIf != null); _ck(_v, 11, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.languages$)); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_1); }); }
export function View_TopBarWithLanguageSwitcherComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "br-header"], ["style", "left: 0;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "br-header-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "br-header-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "nav", [["class", "nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TopBarWithLanguageSwitcherComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.activeLanguage$)); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_TopBarWithLanguageSwitcherComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-bar-with-language-switcher", [], null, null, null, View_TopBarWithLanguageSwitcherComponent_0, RenderType_TopBarWithLanguageSwitcherComponent)), i1.ɵdid(1, 180224, null, 0, i4.TopBarWithLanguageSwitcherComponent, [i5.Store, i6.StaticDataService, i7.TranslateService], null, null)], null, null); }
var TopBarWithLanguageSwitcherComponentNgFactory = i1.ɵccf("app-top-bar-with-language-switcher", i4.TopBarWithLanguageSwitcherComponent, View_TopBarWithLanguageSwitcherComponent_Host_0, {}, {}, []);
export { TopBarWithLanguageSwitcherComponentNgFactory as TopBarWithLanguageSwitcherComponentNgFactory };
