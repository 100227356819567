import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {Observable, ReplaySubject} from 'rxjs';
import {catchError, finalize, first, map, tap} from 'rxjs/operators';
import {
  NeverError,
  StaticDataService,
  ToastService,
  isNotNullOrEmptyOrWhiteSpaceValidator
} from 'orderly-web-components';
import AddOrUpdateIngredientResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddOrUpdateIngredientResponse;
import StatusDef = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddOrUpdateIngredientResponse.StatusDef;
import MeasureUnit = Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.MeasureUnit;
import {IngredientsService} from '../../services/ingredients.service';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../util/form.utils';
import {compareAllergens, genericErrorHandlerWithToast, nameof} from '../../util/utils';
import AddIngredientRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddIngredientRequest;
import UpdateIngredientRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.UpdateIngredientRequest;
import {TranslateService} from '@ngx-translate/core';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import Allergen = Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Allergen;
import IngredientCategory = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddOrUpdateIngredientCategoryResponse.IngredientCategory;
import {
  assignTranslatedProperties,
  getArrayOfTranslationKeys,
  getTranslatedAllergens,
  getTranslatedMeasureUnits,
  trnTryAgainLaterText
} from '../../util/trn.utils';
import {BooleanSelectItem, Translations} from './helper-classes';
import {CompareWithFn} from '@ng-select/ng-select/lib/ng-select.component';
import RestaurantIngredientBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;

type AddOrEditIngredientComponentFormFields = 'name' | 'measureUnit' | 'allergens' | 'category' | 'containsAlcohol';

@Component({
             selector: 'app-add-ingredient',
             templateUrl: './add-or-edit-ingredient.component.html',
             styleUrls: ['./add-or-edit-ingredient.component.scss']
           })
export class AddOrEditIngredientComponent implements OnInit {
  public actionInProgress: boolean = false;

  @Input() restaurantId: number;
  @Input() ingredientToEdit: RestaurantIngredientBasic | null;

  @Output()
  public completed: EventEmitter<RestaurantIngredientBasic> = new EventEmitter<RestaurantIngredientBasic>();

  public formDef: FormDefinition<AddOrEditIngredientComponentFormFields>;
  public translations$: ReplaySubject<Translations> = new ReplaySubject<Translations>();
  public measureUnits$: Observable<MeasureUnit[]>;
  public allergens$: Observable<Allergen[]>;
  public categories$: Observable<IngredientCategory[]>;
  public containsAlcoholSelectItems$: Observable<BooleanSelectItem[]>;

  constructor(private ingredientsService: IngredientsService,
              public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private trnService: TranslateService,
              private toastService: ToastService,
              private staticDataService: StaticDataService) {
  }

  ngOnInit() {
    const nameValue = this.ingredientToEdit == null ? '' : this.ingredientToEdit.name;
    const measureUnitValue = this.ingredientToEdit == null ? null : this.ingredientToEdit.measureUnit;
    const allergensValue = this.ingredientToEdit == null ? [] : this.ingredientToEdit.allergens;
    const categoryValue = this.ingredientToEdit == null ? null : this.ingredientToEdit.category;
    const containsAlcoholValue = this.ingredientToEdit == null ? null : this.ingredientToEdit.containsAlcohol;

    const fieldsDef: FormFieldsDefinition<AddOrEditIngredientComponentFormFields> = {
      name: new FormFieldDefinition(nameValue,
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.maxLength(50)],
                                    [
                                      nameof<AddIngredientRequest>('name'),
                                      nameof<UpdateIngredientRequest>('name')
                                    ]),
      measureUnit: new FormFieldDefinition(measureUnitValue,
                                           false,
                                           [Validators.required],
                                           [
                                             nameof<AddIngredientRequest>('measureUnitId'),
                                             nameof<UpdateIngredientRequest>('measureUnitId')
                                           ]),
      allergens: new FormFieldDefinition(allergensValue,
                                         false,
                                         [],
                                         [
                                           nameof<AddIngredientRequest>('allergens'),
                                           nameof<UpdateIngredientRequest>('allergens')
                                         ]),
      category: new FormFieldDefinition(categoryValue,
                                        false,
                                        [Validators.required],
                                        [
                                          nameof<AddIngredientRequest>('categoryId'),
                                          nameof<UpdateIngredientRequest>('categoryId')
                                        ]),
      containsAlcohol: new FormFieldDefinition(containsAlcoholValue,
                                        false,
                                        [Validators.required],
                                        [
                                          nameof<AddIngredientRequest>('containsAlcohol'),
                                          nameof<UpdateIngredientRequest>('containsAlcohol')
                                        ])
    };

    this.formDef = new FormDefinition<AddOrEditIngredientComponentFormFields>(fieldsDef, this.formBuilder);

    this.measureUnits$ = getTranslatedMeasureUnits(this.staticDataService, this.trnService);
    this.allergens$ = getTranslatedAllergens(this.staticDataService, this.trnService);
    this.categories$ = this.ingredientsService
                           .getAllIngredientCategories(this.restaurantId)
                           .pipe(
                             map(x => x.categories)
                           );

    this.initTranslations();
  }

  private initTranslations() {
    const initialTranslations: Translations = {
      addTitle: _('Add ingredient'),
      updateTitle: _('Update ingredient'),
      saveButtonLabel: _('Save'),
      measureUnitLabel: _('Measure unit'),
      nameLabel: _('Name'),
      allergensLabel: _('Allergens'),
      categoryLabel: _('Category'),
      containsAlcoholLabel: _('Contains alcohol'),
    };

    this.translations$.next(initialTranslations);

    const translationKeys = getArrayOfTranslationKeys(initialTranslations);

    this.trnService
        .stream(translationKeys)
        .pipe(
          map(translations => {
            const result: Translations = assignTranslatedProperties(initialTranslations, translations);

            return result;
          })
        )
        .subscribe(this.translations$);

    const booleanTranslations: {yesText: string, noText: string} = {
      noText: _('No'),
      yesText: _('Yes')
    };

    this.containsAlcoholSelectItems$ = this.trnService
                                           .stream([
                                                     booleanTranslations.yesText, booleanTranslations.noText
                                                   ])
                                           .pipe(
                                             map((x: []) => {
                                               const result: BooleanSelectItem[] = [
                                                 new BooleanSelectItem(true, x[booleanTranslations.yesText]),
                                                 new BooleanSelectItem(false, x[booleanTranslations.noText])
                                               ];

                                               return result;
                                             })
                                           );
  }


  public save() {
    this.actionInProgress = true;

    if (this.formDef.form.invalid) {
      this.formDef.form.markAllAsTouched();
      this.actionInProgress = false;
    }

    let actionToExecute: Observable<AddOrUpdateIngredientResponse>;
    let unexpectedServerFailureMsg: string;
    let successMsg: string;
    const tryAgainLaterMsg = trnTryAgainLaterText(this.trnService);

    const selectedMeasureUnit: MeasureUnit = this.formDef.getControl('measureUnit').value;
    const ingredientName = this.formDef.getControl('name').value;
    const allergens: Allergen[] = this.formDef.getControl('allergens').value;
    const category: IngredientCategory = this.formDef.getControl('category').value;
    const containsAlcohol: boolean = this.formDef.getControl('containsAlcohol').value;
    const allergenCodes: string[] = allergens.map(x => x.code);

    if (this.ingredientToEdit != null) {
      const request: UpdateIngredientRequest = {
        allergens: allergenCodes,
        categoryId: category.id,
        measureUnitId: selectedMeasureUnit.id,
        name: ingredientName,
        id: this.ingredientToEdit.id,
        containsAlcohol: containsAlcohol
      };

      actionToExecute = this.ingredientsService.updateIngredient(this.restaurantId, request);
      unexpectedServerFailureMsg = _('Failed to update an ingredient because of unexpected failure.') + ' ' + tryAgainLaterMsg;
      successMsg = _('Successfully updated an ingredient.');
    } else {
      const request: AddIngredientRequest = {
        allergens: allergenCodes,
        categoryId: category.id,
        measureUnitId: selectedMeasureUnit.id,
        name: ingredientName,
        containsAlcohol: containsAlcohol
      };

      actionToExecute = this.ingredientsService.addIngredient(this.restaurantId, request);
      unexpectedServerFailureMsg = _('Failed to add an ingredient because of unexpected failure.') + ' ' + tryAgainLaterMsg;
      successMsg = _('Successfully added an ingredient.');
    }

    const reenableToken = this.formDef.disable('all-fields');

    actionToExecute.pipe(
      first(),
      tap((x: AddOrUpdateIngredientResponse) => {
        switch (x.status) {
          case StatusDef.Success:
            this.activeModal.dismiss(null);
            this.toastService.showSuccess(successMsg);

            this.completed.emit(x.ingredient);
            break;
          case StatusDef.ValidationFailed:
          case StatusDef.IngredientAlreadyExists:
            // controls must be enabled before errors are set
            reenableToken.reenable();

            this.formDef.setFormFieldsServerValidationResults(x.validationErrors);
            break;
          case StatusDef.UnexpectedError:
            this.toastService.showError(unexpectedServerFailureMsg);
            break;
          default:
            throw new NeverError(x.status);
        }
      }),
      catchError(
        genericErrorHandlerWithToast<AddOrUpdateIngredientResponse>(this.toastService, this.trnService, unexpectedServerFailureMsg)
      ),
      finalize(() => {
        this.actionInProgress = false;

        reenableToken.reenable();
      }))
                   .subscribe();
  }


  public compareMeasureUnitsSelectItems: CompareWithFn = (a1: MeasureUnit, a2: MeasureUnit) => {
    if (a1 != null && a2 == null || a1 == null && a2 != null) {
      return false;
    }

    if (a1 == null || a2 == null) {
      return false;
    }

    return a1.id === a2.id;
  };

  public compareAllergensSelectItems: CompareWithFn = (a1: Allergen, a2: Allergen) => {
    return compareAllergens(a1, a2);
  };
}


