<div class="areas-list-table div-based-table table-dark table-colored">
  <div class="thead">
    <div class="row">
      <div class="col-11 title">{{'Self-order kiosks' | translate}}</div>
      <div class="col-1 actions">
        <a class="btn btn-sm btn-outline-secondary float-right" (click)="refreshDisplaysList()">
          <i class="icon-arrows-cw fa-15x" [class.animate-spin]="listIsLoading$ | async"></i>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-3">{{'Name' | translate}}</div>
      <div class="col-4">{{'Screen type' | translate}} / {{'Resolution' | translate}}</div>
      <div class="col-5"></div>
    </div>
  </div>
  <div class="tbody">
    <ng-container *ngIf="listIsLoaded$ | async">
      <div class="row" *ngFor="let display of displayedGridItems$ | async" style="word-wrap: break-word;">
        <div class="col-3">{{ display.uniqueCode }}</div>
        <div class="col-4">
          {{ display.screenName }} / {{display.displayHeight}}x{{display.displayWidth}}
        </div>
        <div class="col-5">
          <ng-container *ngIf="userIsManager | async">
            <a (click)="assignDisplayToTable(display)"
               data-table-id="@item.Id"
               class="btn btn-sm btn-outline-primary mg-r-5"
               *ngIf="display.tableId == null">

              <i class="icon-forward"></i>{{'Assign to a table' | translate}}
            </a>

            <a (click)="reportBrokenDisplay(display)"
               data-table-id="@item.Id"
               class="btn btn-sm btn-outline-primary mg-r-5">

              <i class="icon-alert"></i>{{'Report broken' | translate}}
            </a>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="row" *ngIf="listIsLoading$ | async">
      <div class="ht-60 pos-relative align-items-center col-12">
        <owc-progress-wave></owc-progress-wave>
      </div>
    </div>

    <div class="row" *ngIf="listLoadingFailed$ | async">
      <div class="ht-50 pos-relative align-items-center col-12">
        <p class="loading-failed-text">{{'Could not load list of kiosks. Check if you have Internet connection and try again.' | translate}}</p>
      </div>
    </div>
  </div>

  <div class="tfoot" *ngIf="listIsLoaded$ | async">
    <div class="row">
      <div class="col-4">
        <ng-container *ngIf="userIsManager | async">
          <a class="btn btn-outline-info" (click)="requestMoreDisplays()"><i class="icon-plus"></i>&nbsp;{{'Request more kiosks' | translate}}</a>
        </ng-container>
      </div>
      <div class="col-8 bd-l-0">
        <owc-pagination-block [visible$]="listIsLoaded$"
                              (filtersChanged)="pagingFiltersChanged($event)"
                              [disable$]="listLoadingFailed$"
                              [state$]="paginationState$">
        </owc-pagination-block>
      </div>
    </div>
  </div>
</div>
