<div>
  <input type="file"
         name="category-image[]"
         id="category-image"
         class="inputfile"
         (change)="onFileChange($event)"
         #categoryImageFileInput>

  <label for="category-image" class="tx-white bg-info btn-select-category-image">
    <i class="icon ion-ios-upload-outline tx-24"></i>

    <span *ngIf="imageName != null">{{imageName}}</span>
    <span *ngIf="imageName == null">{{'Choose an image' | translate}}...</span>

  </label>

  <a *ngIf="imageName != null"
     (click)="clearPreviewImage()"
     class="btn btn-danger btn-clear-category-image"><i class="icon-trash-empty"></i></a>
</div>

<small>{{'An image must be at least 200x200 pixel large' | translate}}</small>
