import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuItemCategoriesListComponent} from './components/menu-item-categories-list/menu-item-categories-list.component';
import {AddOrEditMenuItemCategoryComponent} from './components/add-or-edit-menu-item-category/add-or-edit-menu-item-category.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslationHttpLoaderFactory} from '../translations/translation-http-loader.factory';
import {HttpClient} from '@angular/common/http';
import {CustomMissingTranslationHandler} from '../translations/custom-missing-translation.handler';
import {OrderlyWebComponentsModule} from 'orderly-web-components';
import {NgSelectModule} from '@ng-select/ng-select';
import {SharedModule} from '../shared/shared.module';
import {CategoriesOrderModalComponent} from './components/menu-item-categories-list/categories-order-modal/categories-order-modal.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {RouterModule} from '@angular/router';
import { DetailsTabComponent } from './components/add-or-edit-menu-item-category/details-tab/details-tab.component';
import { TranslationsTabComponent } from './components/add-or-edit-menu-item-category/translations-tab/translations-tab.component';
import {NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import { ImagePreviewComponent } from './components/add-or-edit-menu-item-category/details-tab/image-preview/image-preview.component';
import {CategoryImageSelectorComponent} from './components/add-or-edit-menu-item-category/details-tab/category-image-selector/category-image-selector.component';


@NgModule({
            declarations: [
              MenuItemCategoriesListComponent,
              AddOrEditMenuItemCategoryComponent,
              CategoriesOrderModalComponent,
              CategoryImageSelectorComponent,
              DetailsTabComponent,
              TranslationsTabComponent,
              ImagePreviewComponent,
            ],
            imports: [
              CommonModule,
              RouterModule,
              FormsModule,
              ReactiveFormsModule,
              TranslateModule.forRoot({
                                        loader: {
                                          provide: TranslateLoader,
                                          useFactory: TranslationHttpLoaderFactory,
                                          deps: [HttpClient]
                                        },
                                        missingTranslationHandler: {
                                          provide: MissingTranslationHandler,
                                          useClass: CustomMissingTranslationHandler
                                        },
                                        isolate: true
                                      }),
              OrderlyWebComponentsModule,
              NgSelectModule,
              SharedModule,
              DragDropModule,
              NgbNavModule,
            ],
            entryComponents: [
              AddOrEditMenuItemCategoryComponent,
              CategoriesOrderModalComponent,
            ],
            exports: [
              MenuItemCategoriesListComponent,
              AddOrEditMenuItemCategoryComponent,
            ]
          })
export class MenuItemCategoriesModule {}

export {MenuItemCategoriesListComponent, AddOrEditMenuItemCategoryComponent};
