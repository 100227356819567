<div class="editable-modifier-groups-list-table div-based-table table-dark table-colored">
  <div class="tbody">

    <app-modifier-group-row *ngFor="let item of groups; let i = index;"
                            [group]="item"
                            (requestModifierUpdate)="updateModifier(i, $event)"
                            (addNewModifier)="addNewModifier(i)"
                            (delete)="deleteGroup.emit(i)"
                            (update)="requestGroupUpdate.emit(i)"
                            class="modifier-group">
    </app-modifier-group-row>

    <div *ngIf="groups.length === 0"
         class="row">

      <div class="col-12 text-center">
        {{'No modifiers'}}
      </div>
    </div>

  </div>
</div>
