<div class="btn-group" role="group">

  <button type="button"
          class="btn {{item.value === selectedValue ? (item.selectedClass || selectedButtonClass) : notSelectedButtonClass}}"
          [class.btn-group-lg]="size === 3"
          [class.btn-group-sm]="size === 1"
          [class.disabled]="isDisabled"
          [attr.disabled]="isDisabled ? 'disabled' : null"
          (click)="selectItem(item)"

          *ngFor="let item of items">{{item.text}}</button>

</div>
