import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { StaticDataService } from 'orderly-web-components';
import { routerReducer } from '@ngrx/router-store';
import { environment } from '../environments/environment';
import { ActionReducerMap } from '@ngrx/store';
import { userDataReducer } from './store/reducers/user-data.reducer';
import { leftMenuReducer } from './store/reducers/left-menu.reducer';
import { TranslationHttpLoaderFactory } from './modules/translations/translations.module';
registerLocaleData(localeDe);
export const reducers = {
    auth: userDataReducer,
    leftMenu: leftMenuReducer,
    router: routerReducer
};
const ɵ0 = TranslationHttpLoaderFactory;
export class AppModule {
}
export function init_app(staticDataService) {
    return () => staticDataService.getAllStaticData(environment.baseApiUrlWithTrailingSlash);
}
export { ɵ0 };
