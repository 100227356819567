<div class="modal-header pd-y-20 pd-x-25">
  <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold" *ngIf="ingredientToEdit.id == null">{{'Add menu item ingredient' | translate}}</h6>
  <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold" *ngIf="ingredientToEdit.id != null">{{'Update menu item ingredient' | translate}}</h6>
  <button type="button" class="close" [attr.aria-label]="'Close'" (click)="activeModal.dismiss(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pd-25">
  <form [formGroup]="formDef.form" (ngSubmit)="save()">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="form-control-label" for="ingredient">{{'Ingredient' | translate}}: <span class="tx-danger">*</span></label>

          <ng-select [items]="ingredients | async"
                     formControlName="ingredient"
                     labelForId="ingredient"
                     bindLabel="name"
                     [compareWith]="compareIngredientOptions"
                     [addTag]="false"
                     [placeholder]="'Select an ingredient' | translate"
                     [addTagText]="'Add new ingredient' | translate"
                     [virtualScroll]="true"

                     (change)="onIngredientChange($event)">
          </ng-select>

          <owc-input-validation-errors [control]="formDef.getControl('ingredient')"></owc-input-validation-errors>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="form-control-label" for="amount">{{'Amount' | translate}}: <span class="tx-danger">*</span></label>

          <input class="form-control" [formControl]="formDef.getControl('amount')">

          <owc-input-validation-errors [control]="formDef.getControl('amount')"></owc-input-validation-errors>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label class="form-control-label" for="measureUnitId">{{'Measure unit' | translate}}: <span class="tx-danger">*</span></label>

          <ng-select [items]="measureUnits | async"
                     formControlName="measureUnitId"
                     labelForId="measureUnitId"
                     bindLabel="name"
                     bindValue="id"
                     [placeholder]="'Select measure unit' | translate">
          </ng-select>

          <owc-input-validation-errors [control]="formDef.getControl('measureUnitId')"></owc-input-validation-errors>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer tx-white">
  <owc-progress-action-button [actionInProgress]="actionInProgress"
                              (execute)="save()"
                              [btnText]="'Save' | translate"
                              type="link"
                              [disabled]="formDef.form.invalid">
  </owc-progress-action-button>
</div>
