import {LeftMenuState, LeftMenuStateItem, LeftMenuStateSubItem, MenuItemDataPayload} from '../../app.state';
import {RestaurantManagerRoutingConstants} from '../../../routing-constants';
import {LeftMenuConstants} from '../../../left-menu.constants';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import RestaurantShortInfo = Orderly.RestaurantWeb.Api.Messages.RestaurantShortInfo;

export function equalsMenuItemDataPayload(a: MenuItemDataPayload, b: MenuItemDataPayload | null): boolean {
  return b != null && a.restaurantId === b.restaurantId && a.nodeId === b.nodeId;
}

export function createLeftMenuStateFromUserInfo(
  holdingInfo: Orderly.RestaurantWeb.Api.Messages.RestaurantHoldingShortInfo,
  restaurants: RestaurantShortInfo[]): LeftMenuState {

  if (restaurants.length === 0) {
    const firstStepsMenu = [
      new LeftMenuStateItem(new MenuItemDataPayload(0, LeftMenuConstants.START),
                            _('Start'),
                            RestaurantManagerRoutingConstants.getHoldingDashboardFullUrl(),
                            [])
    ];

    return {items: firstStepsMenu, activeItemId: firstStepsMenu[0].id};
  } else {
    let rootMenuItems: LeftMenuStateItem[] = [];

    if (holdingInfo.isMultiRestaurantChain) {

      const mainDashboard = new LeftMenuStateItem(new MenuItemDataPayload(0, LeftMenuConstants.CHAIN_DASHBOARD),
                                                  _('Main dashboard'),
                                                  RestaurantManagerRoutingConstants.getHoldingDashboardFullUrl(),
                                                  []);

      rootMenuItems.push(mainDashboard);
    }

    const restaurantMenuItems = restaurants.map(x => createRestaurantMenuItem(x))

    rootMenuItems = [...rootMenuItems, ...restaurantMenuItems];

    return {items: rootMenuItems, activeItemId: null};
  }

}

export function createRestaurantMenuItem(restaurant: RestaurantShortInfo): LeftMenuStateItem {

  const restaurantMenuItems: LeftMenuStateSubItem[] = createRestaurantMenuSubItems(restaurant);
  const restaurantDashboardUrl = RestaurantManagerRoutingConstants.getRestaurantDashboardFullUrl(restaurant.id);

  return new LeftMenuStateItem(
    new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT),
    restaurant.name,
    restaurantDashboardUrl,
    restaurantMenuItems);

}

function createRestaurantMenuSubItems(restaurant: RestaurantShortInfo): LeftMenuStateSubItem[] {
  const restaurantMenuItems: LeftMenuStateSubItem[] = [];

  const tablesUrl = RestaurantManagerRoutingConstants.getRestaurantTablesCreateUrl(restaurant.id);
  const requestedTableActionsUrl = RestaurantManagerRoutingConstants.getRestaurantRequestedTableActionsCreateUrl(restaurant.id);
  const waitersUrl = RestaurantManagerRoutingConstants.getRestaurantWaitersCreateUrl(restaurant.id);
  const covidCheckinsUrl = RestaurantManagerRoutingConstants.getCovidCheckinsCreateUrl(restaurant.id);
  const ingredientsUrl = RestaurantManagerRoutingConstants.getRestaurantIngredientsCreateUrl(restaurant.id);
  const ingredientCategoriesUrl = RestaurantManagerRoutingConstants.getRestaurantIngredientCategoriesCreateUrl(restaurant.id);
  const menuItemCategoriesUrl = RestaurantManagerRoutingConstants.getRestaurantMenuItemCategoriesListUrl(restaurant.id);
  const menuItemsUrl = RestaurantManagerRoutingConstants.getRestaurantMenuItemsListUrl(restaurant.id);
  const areasUrl = RestaurantManagerRoutingConstants.getRestaurantAreasListUrl(restaurant.id);
  const terminalsUrl = RestaurantManagerRoutingConstants.getRestaurantTerminalsListUrl(restaurant.id);
  const reviewsUrl = RestaurantManagerRoutingConstants.getRestaurantReviewsListUrl(restaurant.id);
  const restaurantEditUrl = RestaurantManagerRoutingConstants.getRestaurantEditUrl(restaurant.id);

  restaurantMenuItems.push(
    new LeftMenuStateSubItem(new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT_EDIT),
                             _('Restaurant details'),
                             restaurantEditUrl)
  );

  restaurantMenuItems.push(
    new LeftMenuStateSubItem(new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT_AREAS),
                             _('Areas / Halls / Rooms'),
                             areasUrl)
  );

  restaurantMenuItems.push(
    new LeftMenuStateSubItem(new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT_TABLES),
                             _('Tables'),
                             tablesUrl)
  );

  restaurantMenuItems.push(
    new LeftMenuStateSubItem(new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT_TERMINALS),
                             _('Terminals'),
                             terminalsUrl)
  );

  restaurantMenuItems.push(
    new LeftMenuStateSubItem(new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT_MENU_ITEM_CATEGORIES),
                             _('Menu item categories'),
                             menuItemCategoriesUrl)
  );

  restaurantMenuItems.push(
    new LeftMenuStateSubItem(new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT_MENU_ITEMS),
                             _('Menu items'),
                             menuItemsUrl)
  );

  restaurantMenuItems.push(
    new LeftMenuStateSubItem(new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT_INGREDIENT_CATEGORIES),
                             _('Ingredient categories'),
                             ingredientCategoriesUrl)
  );

  restaurantMenuItems.push(
    new LeftMenuStateSubItem(new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT_INGREDIENTS),
                             _('Ingredients'),
                             ingredientsUrl)
  );

  restaurantMenuItems.push(
    new LeftMenuStateSubItem(new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT_REVIEWS),
                             _('Reviews'),
                             reviewsUrl)
  );

  restaurantMenuItems.push(
    new LeftMenuStateSubItem(new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT_OPENED_REQUESTS),
                             _('Opened client requests'),
                             requestedTableActionsUrl)
  );

  restaurantMenuItems.push(
    new LeftMenuStateSubItem(new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT_USERS),
                             _('Users'),
                             waitersUrl)
  );

  restaurantMenuItems.push(
    new LeftMenuStateSubItem(new MenuItemDataPayload(restaurant.id, LeftMenuConstants.RESTAURANT_COVID_CHECKINS),
                             _('COVID Checkins'),
                             covidCheckinsUrl)
  );

  return restaurantMenuItems;
}
