<div class="areas-list-table div-based-table table-dark table-colored">
  <div class="thead">
    <div class="row">
      <div class="col-6 title">{{'Users' | translate}}</div>
      <div class="col-6 actions">
        <div class="float-right">
          <ng-container *ngIf="lastLoadedOn$ | async as loadedOn">
            <span *ngIf="loadedOn != null">{{'last loaded on' | translate}} {{loadedOn | localDate:'HH:mm'}}</span>
          </ng-container>

          <a class="btn btn-sm btn-outline-secondary" (click)="refreshUsersList()">
            <i class="icon-arrows-cw fa-15x" [class.animate-spin]="listIsLoading$ | async"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <small>{{'Only invited waiters that confirmed their registration will be able to receive notifications from your clients' | translate}}</small>
      </div>
    </div>

    <div class="row">
      <div class="col-3">{{'Full Name' | translate}}</div>
      <div class="col-3">{{'Account status' | translate}}</div>
      <div class="col-3">{{'Roles' | translate}}</div>
      <div class="col-3"></div>
    </div>
  </div>
  <div class="tbody">
    <ng-container *ngIf="listIsLoaded$ | async">
      <ng-container *ngIf="displayedGridItems$ | async as waiters">
        <div class="row" *ngFor="let waiter of waiters" style="word-wrap: break-word;">
          <div class="col-3">
            {{ waiter.firstName }} {{ waiter.lastName }}<br />
            {{'Added on' | translate}}: {{ waiter.createdOn | localDate:'DD-MM-YYYY HH:mm' }}
          </div>
          <div class="col-3">
            <div *ngIf="waiter.invitationConfirmed; else notConfirmed">
              <span *ngIf="waiter.isActive; else notActive">{{'Active' | translate}}</span>
              <ng-template #notActive><span>{{'Deactivated' | translate}}</span></ng-template>
            </div>
            <ng-template #notConfirmed><span>{{'Invitation not confirmed' | translate}}</span></ng-template>
          </div>
          <div class="col-3">
            {{waiter.roles | joinRestaurantRoles | async}}
          </div>
          <div class="col-3">
            <ng-container *ngIf="userIsManager | async">
              <a class="btn btn-sm btn-danger text-white mg-x-1" (click)="deleteWaiter(waiter)">{{'Delete' | translate}}</a>
              <a class="btn btn-sm btn-outline-primary mg-x-1" (click)="resendWaiterInvitation(waiter)" *ngIf="!waiter.isActive">{{'Re-send invitation' | translate}}</a>
            </ng-container>
          </div>
        </div>
        <div class="row" *ngIf="waiters.length === 0">
          <div class="col-12 text-center">
            {{'No waiters found' | translate}}
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="row" *ngIf="listIsLoading$ | async">
      <div class="ht-60 pos-relative align-items-center col-12">
        <owc-progress-wave></owc-progress-wave>
      </div>
    </div>

    <div class="row" *ngIf="listLoadingFailed$ | async">
      <div class="ht-50 pos-relative align-items-center col-12">
        <p class="loading-failed-text">{{'Could not load waiters list. Check if you have Internet connection and try again.' | translate}}</p>
      </div>
    </div>
  </div>

  <div class="tfoot" *ngIf="listIsLoaded$ | async">
    <div class="row">
      <div class="col-4">
        <ng-container *ngIf="userIsManager | async">
          <a class="btn btn-outline-info" (click)="addUser()"><i class="icon-plus"></i>&nbsp;{{'Invite a waiter' | translate}}</a>
        </ng-container>
      </div>
      <div class="col-8 bd-l-0">
        <owc-pagination-block [visible$]="listIsLoaded$"
                              (filtersChanged)="pagingFiltersChanged($event)"
                              [disable$]="listLoadingFailed$"
                              [state$]="paginationState$">
        </owc-pagination-block>
      </div>
    </div>
  </div>
</div>
