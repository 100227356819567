import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { RestaurantHoldingRegistrationModel } from '../models/registration.model';

import GetRestaurantResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.GetRestaurantResponse;
import RegisterRestaurantHoldingRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.RegisterRestaurantHoldingRequest;
import RegisterRestaurantHoldingResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.RegisterRestaurantHoldingResponse;
import AddRestaurantRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddRestaurantRequest;
import AddOrUpdateRestaurantResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddOrUpdateRestaurantResponse;
import UpdateRestaurantRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.UpdateRestaurantRequest;
import GetHoldingRestaurantsResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.GetHoldingRestaurantsResponse;
import {switchMap} from 'rxjs/operators';
import {RestaurantHoldingRegistrationWithMenuModel} from '../app/registration/helper.models';
import RegisterRestaurantHoldingSimpleWithPdfMenuResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.RegisterRestaurantHoldingSimpleWithPdfMenuResponse;
import RegisterRestaurantHoldingSimpleWithPdfMenuRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.RegisterRestaurantHoldingSimpleWithPdfMenuRequest;

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {
  private baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  constructor(private readonly httpClient: HttpClient,
              private readonly userService: UserService) {
  }

  public getRestaurant(restId: number): Observable<GetRestaurantResponse> {
    const url: string = `${this.baseURL}/restaurant/${restId}`;

    return this.httpClient.get<GetRestaurantResponse>(url);
  }

  public getAllHoldingRestaurants(restaurantHoldingId: number): Observable<GetHoldingRestaurantsResponse> {
    const url: string = `${this.baseURL}/restaurant-holding/${restaurantHoldingId}/restaurants/all`;

    return this.httpClient.get<GetHoldingRestaurantsResponse>(url);
  }

  public completeOnboarding(restaurantHoldingId: number): Observable<any> {
    const url: string = `${this.baseURL}/restaurant-holding/${restaurantHoldingId}/onboarding/complete`;

    return this.httpClient.post<any>(url, {});
  }

  public registerHoldingWithMenu(restaurant: RestaurantHoldingRegistrationWithMenuModel): Observable<RegisterRestaurantHoldingSimpleWithPdfMenuResponse> {
    const url: string = `${this.baseURL}/restaurant-holding/register-simple-with-pdf`;

    const request: RegisterRestaurantHoldingSimpleWithPdfMenuRequest = {
      languageCode2: restaurant.languageCode2,
      menu: {
        contentBase64: restaurant.menuFileContentBase64,
        name: restaurant.menuFileName
      },
      name: restaurant.name,
      ownerEmail: restaurant.email
    };

    const result = this.httpClient.post<RegisterRestaurantHoldingSimpleWithPdfMenuResponse>(url, request);

    return result;
  }

  public registerHolding(restaurant: RestaurantHoldingRegistrationModel): Observable<RegisterRestaurantHoldingResponse> {
    const url: string = `${this.baseURL}/restaurant-holding/register`;

    const request: RegisterRestaurantHoldingRequest = {
      address: restaurant.address,
      cityId: restaurant.cityId,
      contactPhone: restaurant.contactPhone,
      isChain: restaurant.isChain,
      languageCode2: restaurant.languageCode2,
      ownerFirstName: restaurant.firstName,
      ownerLastName: restaurant.lastName,
      ownerEmail: restaurant.email,
      ownerPassword: restaurant.password,
      name: restaurant.name,
      zipCode: restaurant.zipCode
    };

    const result = this.httpClient.post<RegisterRestaurantHoldingResponse>(url, request);

    return result;
  }

  public createNewRestaurant(data: AddRestaurantRequest): Observable<AddOrUpdateRestaurantResponse> {
    return this.userService
               .getCurrentUser('from-cache')
               .pipe(
                 switchMap(user => {
                   const url: string = this.baseURL + `/restaurant-holding/${user.holdingInfo.id}/restaurant/add`;

                   return this.httpClient.post<AddOrUpdateRestaurantResponse>(url, data);
                 })
               );
  }

  public updateRestaurant(restId: number, data: UpdateRestaurantRequest): Observable<AddOrUpdateRestaurantResponse> {
    const url: string = `${this.baseURL}/restaurant/${restId}/update`;

    return this.httpClient.post<AddOrUpdateRestaurantResponse>(url, data);
  }
}
