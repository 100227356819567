import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {isNotNullOrEmptyOrWhiteSpaceValidator, NeverError, ToastService} from 'orderly-web-components';
import {catchError, filter, finalize, first, map, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {TranslationsDefinition} from './translations-definition';
import {Observable, ReplaySubject} from 'rxjs';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import AddTableRequest = Orderly.RestaurantWeb.Api.Messages.Table.AddTableRequest;
import AddTableResponse = Orderly.RestaurantWeb.Api.Messages.Table.AddTableResponse;
import StatusDef = Orderly.RestaurantWeb.Api.Messages.Table.AddTableResponse.StatusDef;
import RestaurantArea = Orderly.RestaurantWeb.Api.Messages.RestaurantArea;
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../util/form.utils';
import {CurrentRestaurantTablesService} from '../../../../../services/active-route-bound/current-restaurant-tables.service';
import {CurrentRestaurantAreasService} from '../../../../../services/active-route-bound/current-restaurant-areas.service';
import {AppState} from '../../../../store/app.state';
import {assignTranslatedProperties, getArrayOfTranslationKeys} from '../../../../../util/trn.utils';
import {genericErrorHandlerWithToast, nameof} from '../../../../../util/utils';
import {TableCreationSuccessfulAction} from '../../../../store/actions/table.actions';

type FormFieldsDef = 'name' | 'area';

@Component({
             selector: 'app-add-table-modal',
             templateUrl: './add-table-modal.component.html',
             styleUrls: ['./add-table-modal.component.scss']
           })
export class AddTableModalComponent implements OnInit {

  public actionInProgress: boolean = false;

  public formDef: FormDefinition<FormFieldsDef>;

  public translations$: ReplaySubject<TranslationsDefinition> = new ReplaySubject<TranslationsDefinition>(1);

  public areas$: Observable<RestaurantArea[]>;

  constructor(public modalService: NgbActiveModal,
              private tableService: CurrentRestaurantTablesService,
              private formBuilder: FormBuilder,
              private toastService: ToastService,
              public trnService: TranslateService,
              private store: Store<AppState>,
              restaurantAreasService: CurrentRestaurantAreasService) {

    const initialTranslations: TranslationsDefinition = {
      closeLabel: _('Close'),
      modalTitle: _('Add table')
    };

    this.translations$.next(initialTranslations);

    const translationKeys = getArrayOfTranslationKeys(initialTranslations);

    trnService.stream(translationKeys)
              .pipe(
                map(translations => {
                  const result: TranslationsDefinition = assignTranslatedProperties(initialTranslations, translations);

                  return result;
                })
              )
              .subscribe(this.translations$);

    this.areas$ = restaurantAreasService.currentRestaurantAreas$
                                        .pipe(
                                          filter(x => x.isLoaded()),
                                          map(x => x.items)
                                        );
  }

  ngOnInit() {
    const fieldsDef: FormFieldsDefinition<FormFieldsDef> = {
      name: new FormFieldDefinition(null,
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.maxLength(50)],
                                    [nameof<AddTableRequest>('name')]),
      area: new FormFieldDefinition(null,
                                    false,
                                    [Validators.required],
                                    [nameof<AddTableRequest>('areaId')])
    };

    this.formDef = new FormDefinition(fieldsDef, this.formBuilder);
  }

  public save() {
    if (this.formDef.form.invalid) {
      return;
    }

    const name: string = this.formDef.getControl('name').value;
    const area: RestaurantArea = this.formDef.getControl('area').value;

    const requestData: AddTableRequest = {
      name: name,
      areaId: area.id
    };

    this.actionInProgress = true;
    const disableFieldsToken = this.formDef.disable('all-fields');
    const unexpectedServerFailureMsg = this.trnService.instant('Failed to save a table because of unexpected failure.');

    this.tableService
        .add(requestData)
        .pipe(
          first(),
          tap((response: AddTableResponse) => {
            switch (response.status) {
              case StatusDef.Success:
                const successMsg = this.trnService.instant('Table was successfully added.');
                this.toastService.showSuccess(successMsg);

                this.modalService.close(true);

                this.store.dispatch(new TableCreationSuccessfulAction(response));
                break;
              case StatusDef.ValidationFailed:
                disableFieldsToken.reenable();

                this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
                break;
              case StatusDef.TableWithSameNameExists:
                disableFieldsToken.reenable();

                const tableError = this.trnService.instant('Table with this name already exists.');
                this.formDef.getControl('name').setErrors({serverValidation: {messages: [tableError]}});
                this.toastService.showError(tableError);
                break;
              case StatusDef.UnexpectedError:
                this.toastService.showError(unexpectedServerFailureMsg);
                break;
              default:
                throw new NeverError(response.status);
            }
          }),
          catchError(
            genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedServerFailureMsg)
          ),
          finalize(() => {
            this.actionInProgress = false;
          })
        )
        .subscribe();
  }
}
