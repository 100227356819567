import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import RestaurantWithStatistics = Orderly.RestaurantWeb.Api.Messages.Restaurant.RestaurantWithStatistics;
import {ActivatedRoute} from '@angular/router';
import {RestaurantManagerRoutingConstants} from '../../routing-constants';
import {RestaurantComponent} from '../../restaurant.component';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-restaurant-card',
  templateUrl: './restaurant-card.component.html',
  styleUrls: ['./restaurant-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RestaurantCardComponent extends RestaurantComponent {

  @Input()
  public restaurant: RestaurantWithStatistics;

  public editUrl$: Observable<string>;

  constructor(protected store: Store<AppState>,
              protected activatedRoute: ActivatedRoute) {

    super(store, activatedRoute);

    this.editUrl$ = this.restaurantIdParam$
                        .pipe(
                          map(id => RestaurantManagerRoutingConstants.getRestaurantEditUrl(id))
                        );
  }
}
