import {Action} from '@ngrx/store';
import {ActionTypes} from './action-types';
import AddOrUpdateIngredientCategoryResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddOrUpdateIngredientCategoryResponse;
import DeleteIngredientCategoryResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.DeleteIngredientCategoryResponse;

export class IngredientCategoryAddSuccessfulAction implements Action {
  readonly type = ActionTypes.INGREDIENT_CATEGORY_ADD_SUCCESSFUL;

  constructor(public data: AddOrUpdateIngredientCategoryResponse) {}
}

export class IngredientCategoryDeleteSuccessfulAction implements Action {
  readonly type = ActionTypes.INGREDIENT_CATEGORY_DELETE_SUCCESSFUL;

  constructor(public data: DeleteIngredientCategoryResponse) {}
}
