import {Component, Input} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor, FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {BaseOrderlyComponent} from '../../../../shared/components/base-orderly.component';
import {FormDefinition} from '../../../../../../util/form.utils';
import {TabSettingsDefinition} from '../tab-settings.definition';
import {filter, tap} from 'rxjs/operators';
import {createForm, MenuItemTranslationsFormFieldsDefinition} from './menu-item-translations-form-fields.definition';

@Component({
             selector: 'app-translations-tab',
             templateUrl: './translations-tab.component.html',
             styleUrls: ['./translations-tab.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: TranslationsTabComponent,
                 multi: true,
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: TranslationsTabComponent,
                 multi: true
               },
             ],
           })
export class TranslationsTabComponent extends BaseOrderlyComponent implements ControlValueAccessor, Validator {


  private initInProgress: boolean = false;

  public formDef: FormDefinition<keyof MenuItemTranslationsFormFieldsDefinition> = createForm(this.formBuilder);

  @Input()
  public settings: TabSettingsDefinition;


  constructor(private readonly formBuilder: FormBuilder) {
    super();

    this.registerForAutoDestroy(
      this.formDef
          .form
          .valueChanges
          .pipe(
            filter(() => !this.initInProgress),
            tap((formValue: MenuItemTranslationsFormFieldsDefinition) => {
              this.onChange(formValue);
            }),
          )
          .subscribe()
    );
  }



  onChange: any = (value: MenuItemTranslationsFormFieldsDefinition) => {
  };

  onTouched: any = () => {
  };


  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: MenuItemTranslationsFormFieldsDefinition | null): void {

    if (value == null) {
      return;
    }

    this.initInProgress = true;

    this.formDef.form.patchValue(value, {emitEvent: false});

    this.initInProgress = false;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formDef.disable('all-fields');
    } else {
      this.formDef.enable('all-fields', false);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.formDef.form.invalid) {
      return {'details-tab': true};
    }

    return null;
  }

}
