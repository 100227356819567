import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RestaurantMenuService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.baseURL = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
        this.menuControllerBaseURL = `${this.baseURL}/restaurant`;
    }
    addMenuItemModifierGroup(restaurantId, menuItemId, request) {
        const url = `${this.menuControllerBaseURL}/${restaurantId}/menu-item/${menuItemId}/modifier-group/add`;
        return this.httpClient.post(url, request);
    }
    updateMenuItemModifierGroup(restaurantId, menuItemId, menuItemModifierGroupId, request) {
        const url = `${this.menuControllerBaseURL}/${restaurantId}/menu-item/${menuItemId}/modifier-group/${menuItemModifierGroupId}/update`;
        return this.httpClient.post(url, request);
    }
}
RestaurantMenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestaurantMenuService_Factory() { return new RestaurantMenuService(i0.ɵɵinject(i1.HttpClient)); }, token: RestaurantMenuService, providedIn: "root" });
