import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {
  greaterThanOrEqualValidator,
  isNotNullOrEmptyOrWhiteSpaceValidator, NeverError,
  positiveOrZeroIntegerValidator,
  requiredIfSelectedValidator,
  ToastService
} from 'orderly-web-components';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../util/form.utils';
import {catchError, finalize, first, map, takeUntil, tap} from 'rxjs/operators';
import {Observable, ReplaySubject, Subject, Subscription, throwError} from 'rxjs';
import {nameof, parseInteger} from '../../util/utils';
import {RestaurantMenuService} from '../../services/restaurant-menu.service';
import AddMenuItemModifierGroupRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.AddMenuItemModifierGroupRequest;
import AddOrUpdateMenuItemModifierGroupResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.AddOrUpdateMenuItemModifierGroupResponse;
import StatusDef = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.AddOrUpdateMenuItemModifierGroupResponse.StatusDef;
import UpdateMenuItemModifierGroupRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.UpdateMenuItemModifierGroupRequest;
import {TranslateService} from '@ngx-translate/core';
import {BooleanValueItem} from './helper.types';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import MenuItemModifierGroupBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupBasic;

type AddOrEditMenuItemModifierGroupComponentFormFields =
  'name'
  | 'minAllowedAmount'
  | 'maxAllowedAmount'
  | 'isOnlyOnePermitted'
  | 'isActive'
  | 'isVisible';

@Component({
             selector: 'app-add-or-edit-menu-item-modifier-group',
             templateUrl: './add-or-edit-menu-item-modifier-group.component.html',
             styleUrls: ['./add-or-edit-menu-item-modifier-group.component.scss']
           })
export class AddOrEditMenuItemModifierGroupComponent implements OnInit, OnDestroy {

  @Input()
  public restaurantId: number;

  @Input()
  public menuItemId: number;

  @Output()
  public completed: EventEmitter<AddOrUpdateMenuItemModifierGroupResponse> = new EventEmitter<AddOrUpdateMenuItemModifierGroupResponse>();

  @Input()
  public modifierGroupToEdit: MenuItemModifierGroupBasic | null = null;


  private destroy$: Subject<any> = new Subject<any>();

  public visibilityItems$: ReplaySubject<BooleanValueItem[]> = new ReplaySubject<BooleanValueItem[]>(1);
  public activeItems$: ReplaySubject<BooleanValueItem[]> = new ReplaySubject<BooleanValueItem[]>(1);

  public showMinMaxControls: boolean;
  public actionInProgress: boolean = false;
  public formDef: FormDefinition<AddOrEditMenuItemModifierGroupComponentFormFields>;
  public formValueChangesSubscription: Subscription;

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private toastService: ToastService,
              private trnService: TranslateService,
              private restaurantMenuService: RestaurantMenuService) {

    const visibleText = _('Visible');
    const invisibleText = _('Invisible');
    const activeText = _('Active');
    const deactivatedText = _('Not active');


    this.trnService
        .stream([visibleText, invisibleText])
        .pipe(
          takeUntil(this.destroy$),
          map((x: []) => {
            const visibleTrn = x[visibleText];
            const invisibleTrn = x[invisibleText];

            return [new BooleanValueItem(true, visibleTrn), new BooleanValueItem(false, invisibleTrn)];
          })
        )
        .subscribe(this.visibilityItems$);


    this.trnService
        .stream([activeText, deactivatedText])
        .pipe(
          takeUntil(this.destroy$),
          map((x: []) => {
            const activeTrn = x[activeText];
            const deactivatedTrn = x[deactivatedText];

            return [new BooleanValueItem(true, activeTrn), new BooleanValueItem(false, deactivatedTrn)];
          })
        )
        .subscribe(this.activeItems$);
  }

  ngOnInit() {
    const name: string = this.modifierGroupToEdit == null ? '' : this.modifierGroupToEdit.name;
    const isOnlyOnePermitted: boolean = this.modifierGroupToEdit == null
                                        ? true
                                         : this.modifierGroupToEdit.minAllowedAmount === 0 && this.modifierGroupToEdit.maxAllowedAmount === 1;
    const isActive: boolean = this.modifierGroupToEdit == null
                              ? true
                              : this.modifierGroupToEdit.isActive;
    const isVisible: boolean = this.modifierGroupToEdit == null
                               ? true
                               : this.modifierGroupToEdit.nameIsVisibleToUser;
    const minAllowedAmount: number | null = this.modifierGroupToEdit == null ? null
                                                                             : this.modifierGroupToEdit.minAllowedAmount;
    const maxAllowedAmount: number | null = this.modifierGroupToEdit == null ? null
                                                                             : this.modifierGroupToEdit.maxAllowedAmount;

    this.showMinMaxControls = !isOnlyOnePermitted;

    const fieldsDef: FormFieldsDefinition<AddOrEditMenuItemModifierGroupComponentFormFields> = {
      name: new FormFieldDefinition(name,
                                    false,
                                    [
                                      isNotNullOrEmptyOrWhiteSpaceValidator,
                                      Validators.maxLength(50)
                                    ],
                                    [
                                      nameof<AddMenuItemModifierGroupRequest>('name'),
                                      nameof<UpdateMenuItemModifierGroupRequest>('name')
                                    ]),
      isActive: new FormFieldDefinition(isActive,
                                        false,
                                        [Validators.required],
                                        []),
      isVisible: new FormFieldDefinition(isVisible,
                                         false,
                                         [Validators.required],
                                        []),
      isOnlyOnePermitted: new FormFieldDefinition(isOnlyOnePermitted,
                                                  false,
                                                  [],
                                                  []),
      minAllowedAmount: new FormFieldDefinition(minAllowedAmount,
                                                false,
                                                [
                                                  positiveOrZeroIntegerValidator
                                                ],
                                                [
                                                  nameof<AddMenuItemModifierGroupRequest>('minAllowedAmount'),
                                                  nameof<UpdateMenuItemModifierGroupRequest>('minAllowedAmount')
                                                ]),
      maxAllowedAmount: new FormFieldDefinition(maxAllowedAmount,
                                                false,
                                                [
                                                  positiveOrZeroIntegerValidator
                                                ],
                                                [
                                                  nameof<AddMenuItemModifierGroupRequest>('maxAllowedAmount'),
                                                  nameof<UpdateMenuItemModifierGroupRequest>('maxAllowedAmount')
                                                ]),
    };

    const minAllowedAmountCtrlName = nameof<Record<AddOrEditMenuItemModifierGroupComponentFormFields, any>>(
      'minAllowedAmount');
    const maxAllowedAmountCtrlName = nameof<Record<AddOrEditMenuItemModifierGroupComponentFormFields, any>>(
      'maxAllowedAmount');
    const isOnlyOnePermittedCtrlName = nameof<Record<AddOrEditMenuItemModifierGroupComponentFormFields, any>>(
      'isOnlyOnePermitted');

    const minSelectableElementsCtrlName = this.trnService.instant('Min selectable elements');
    const greaterThanValidatorErrorMessage = this.trnService.instant('Value must be greater than "{{otherFieldName}}"', {otherFieldName: minSelectableElementsCtrlName});

    const formOpts = {
      validators: [
        greaterThanOrEqualValidator(minAllowedAmountCtrlName, maxAllowedAmountCtrlName, greaterThanValidatorErrorMessage),
        requiredIfSelectedValidator(minAllowedAmountCtrlName, {
          radioOrCheckboxControlName: isOnlyOnePermittedCtrlName,
          selectedValue: false
        })
      ]
    };
    this.formDef = new FormDefinition(fieldsDef, this.formBuilder, formOpts);

    this.formValueChangesSubscription = this.formDef.getControl('isOnlyOnePermitted').valueChanges.pipe(
      tap((selectedValue: boolean) => {
        this.showMinMaxControls = !selectedValue;

        const minAllowedAmountCtrl = this.formDef.getControl('minAllowedAmount');
        const maxAllowedAmountCtrl = this.formDef.getControl('maxAllowedAmount');

        if (!this.showMinMaxControls) {
          minAllowedAmountCtrl.setValue(0);
          maxAllowedAmountCtrl.setValue(1);
        } else {
          if (minAllowedAmountCtrl.value === 0) {
            minAllowedAmountCtrl.setValue(null);
          }

          if (maxAllowedAmountCtrl.value === 1) {
            maxAllowedAmountCtrl.setValue(null);
          }
        }
      })
    ).subscribe();
  }

  public save() {
    if (this.formDef.form.invalid) {
      return;
    }

    const minAllowedAmountRaw: number | null = parseInteger(this.formDef.getControl('minAllowedAmount').value);
    const maxAllowedAmountRaw: number | null = parseInteger(this.formDef.getControl('maxAllowedAmount').value);
    const isOnlyOnePermitted: boolean = this.formDef.getControl('isOnlyOnePermitted').value;
    const name: string = this.formDef.getControl('name').value;
    const isVisible: boolean = this.formDef.getControl('isVisible').value;


    const minAllowedAmount: number = isOnlyOnePermitted ? 0 : minAllowedAmountRaw!;
    const maxAllowedAmount: number = isOnlyOnePermitted ? 1 : maxAllowedAmountRaw!;

    const unexpectedFailureText = this.trnService.instant('Failed to add a modifier group because of unexpected failure. Please try again later.');

    let saveAction$: Observable<AddOrUpdateMenuItemModifierGroupResponse>;

    if (this.modifierGroupToEdit == null || this.modifierGroupToEdit.id == null) {
      const request: AddMenuItemModifierGroupRequest = {
        name: name,
        maxAllowedAmount: maxAllowedAmount,
        minAllowedAmount: minAllowedAmount,
        nameIsVisibleToUser: isVisible,
      };

      saveAction$ = this.restaurantMenuService.addMenuItemModifierGroup(this.restaurantId, this.menuItemId, request);
    } else {
      const isActive: boolean = this.formDef.getControl('isActive').value;

      const request: UpdateMenuItemModifierGroupRequest = {
        name: name,
        maxAllowedAmount: maxAllowedAmount,
        minAllowedAmount: minAllowedAmount,
        isActive: isActive,
        nameIsVisibleToUser: isVisible
      };

      saveAction$ = this.restaurantMenuService.updateMenuItemModifierGroup(this.restaurantId, this.menuItemId, this.modifierGroupToEdit.id, request);
    }

    const disableFormToken = this.formDef.disable('all-fields');

    saveAction$.pipe(
      first(),
      tap((x: AddOrUpdateMenuItemModifierGroupResponse) => {
        switch (x.status) {
          case StatusDef.Success:
            this.completed.emit(x);

            this.activeModal.close();
            break;
          case StatusDef.ValidationFailed:
            disableFormToken.reenable();

            this.formDef.setFormFieldsServerValidationResults(x.validationErrors);
            break;
          case StatusDef.GroupWithSameNameExists:
            disableFormToken.reenable();

            const groupWithSameNameExistsError = this.trnService.instant('Modifier group with the same name already exists.');

            this.formDef.getControl('name').setErrors({serverValidation: {messages: [groupWithSameNameExistsError]}});
            break;
          case StatusDef.UnexpectedFailure:
            this.toastService.showError(unexpectedFailureText);
            break;
          default:
            throw new NeverError(x.status);
        }
      }),
      catchError((err) => {
        this.toastService.showError(unexpectedFailureText);

        return throwError(err);
      }),
      finalize(() => disableFormToken.reenable())
               )
               .subscribe();
  }

  ngOnDestroy(): void {
    this.formValueChangesSubscription.unsubscribe();

    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
