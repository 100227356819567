import {Component, OnDestroy} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {
  assignTranslatedProperties,
  getArrayOfTranslationKeys,
  QrMenuActionTypeDefTranslations
} from '../../../../../../../util/trn.utils';
import {TranslateService} from '@ngx-translate/core';
import QrMenuActionTypeDef = Orderly.Common.Enums.QrMenuActionTypeDef;
import {TranslationsDefinition} from './translations.definition';
import {QrMenuButtonActionSelectItem} from './qr-menu-button-action-select-item';

@Component({
             selector: 'app-qr-menu-action-select',
             templateUrl: './qr-menu-action-select.component.html',
             styleUrls: ['./qr-menu-action-select.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: QrMenuActionSelectComponent,
                 multi: true,
               },
             ],
           })
export class QrMenuActionSelectComponent implements ControlValueAccessor, OnDestroy {

  private destroyed$: Subject<any> = new Subject<any>();

  public qrMenuButtonActions$: ReplaySubject<QrMenuButtonActionSelectItem[]> = new ReplaySubject<QrMenuButtonActionSelectItem[]>(1);

  public lastValue: QrMenuActionTypeDef | null = null;

  public isDisabled: boolean = false;


  constructor(private readonly trnService: TranslateService) {
    const initialTranslations: TranslationsDefinition = {
      qrMenuActionTypeDefCallWaiter: QrMenuActionTypeDefTranslations.CallWaiter,
      qrMenuActionTypeDefOnlineMenu: QrMenuActionTypeDefTranslations.OnlineMenu,
      qrMenuActionTypeDefUploadedPdf: QrMenuActionTypeDefTranslations.UploadedPdf,
    };

    const initQrMenuButtonActionsList = this.createQrMenuButtonActionsList(initialTranslations);

    this.qrMenuButtonActions$.next(initQrMenuButtonActionsList);

    const translationKeys = getArrayOfTranslationKeys(initialTranslations);

    this.trnService
        .stream(translationKeys)
        .pipe(
          map(translations => {
            const result: TranslationsDefinition = assignTranslatedProperties(initialTranslations, translations);

            return result;
          }),
          tap(trns => {
            const qrMenuButtonActionsList = this.createQrMenuButtonActionsList(trns);

            this.qrMenuButtonActions$.next(qrMenuButtonActionsList);
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe();
  }

  private createQrMenuButtonActionsList(trns: Readonly<TranslationsDefinition>): QrMenuButtonActionSelectItem[] {
    const qrMenuButtonActionsList: QrMenuButtonActionSelectItem[] = [];

    qrMenuButtonActionsList.push({name: trns.qrMenuActionTypeDefCallWaiter, value: QrMenuActionTypeDef.CallWaiter});
    qrMenuButtonActionsList.push({name: trns.qrMenuActionTypeDefOnlineMenu, value: QrMenuActionTypeDef.OnlineMenu});
    qrMenuButtonActionsList.push({name: trns.qrMenuActionTypeDefUploadedPdf, value: QrMenuActionTypeDef.UploadedPdf});

    return qrMenuButtonActionsList;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();

    this.qrMenuButtonActions$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: number | null) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: QrMenuActionTypeDef | null): void {
    this.lastValue = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  changed(newValue: QrMenuActionTypeDef) {
    this.lastValue = newValue;

    this.onTouched();
    this.onChange(newValue);
  }
}
