import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {RestaurantSpecificArrayResultLoadingState} from './helper.statuses';
import {CurrentRestaurantServiceBase} from './current-restaurant.service.base';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import AddTableRequest = Orderly.RestaurantWeb.Api.Messages.Table.AddTableRequest;
import AddTableResponse = Orderly.RestaurantWeb.Api.Messages.Table.AddTableResponse;
import DeleteTableResponse = Orderly.RestaurantWeb.Api.Messages.Table.DeleteTableResponse;
import GetAllRestaurantTablesResponse = Orderly.RestaurantWeb.Api.Messages.Table.GetAllRestaurantTablesResponse;
import GetQrCodeResponse = Orderly.RestaurantWeb.Api.Messages.Table.GetQrCodeResponse;
import {RestaurantTablesServiceDefinition} from '../RestaurantTablesServiceDefinition';
import PrintQrCodesRequest = Orderly.RestaurantWeb.Api.Messages.Table.PrintQrCodesRequest;
import ExistingTableWithAreaAndDisplays = Orderly.RestaurantWeb.Api.Messages.ExistingTableWithAreaAndDisplays;
import AssignPregeneratedCodeRequest = Orderly.RestaurantWeb.Api.Messages.Table.AssignPregeneratedCodeRequest;
import AssignPregeneratedCodeResponse = Orderly.RestaurantWeb.Api.Messages.Table.AssignPregeneratedCodeResponse;

@Injectable({
              providedIn: 'root'
            })
export class CurrentRestaurantTablesService extends CurrentRestaurantServiceBase<ExistingTableWithAreaAndDisplays>
  implements RestaurantTablesServiceDefinition {

  private baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  public get currentRestaurantTables$(): Observable<RestaurantSpecificArrayResultLoadingState<ExistingTableWithAreaAndDisplays>> {
    return this.dataFeed$;
  }

  constructor(private httpClient: HttpClient, store: Store<AppState>) {
    super(store);
  }

  protected doLoadData(restaurantId: number): Observable<ExistingTableWithAreaAndDisplays[]> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/tables/all`;
    const response$ = this.httpClient.get<GetAllRestaurantTablesResponse>(url);

    return response$.pipe(
      tap(x => {
        if (x.status === GetAllRestaurantTablesResponse.StatusDef.UnknownFailure) {
          throw new Error('Server returned an error. Could not load list of tables');
        }
      }),
      map(x => x.items)
    );
  }

  public add(data: AddTableRequest): Observable<AddTableResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   return this.addForRestaurant(restaurantId, data);
                 })
               );
  }

  public addForRestaurant(restaurantId: number, data: AddTableRequest): Observable<AddTableResponse> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/table/add`;

    return this.httpClient.post<AddTableResponse>(url, data);
  }

  public delete(tableId: number): Observable<DeleteTableResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/table/${tableId}/delete`;

                   return this.httpClient.delete<DeleteTableResponse>(url);
                 })
               );
  }

  public generateNewQrCode(tableId: number): Observable<GetQrCodeResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/table/${tableId}/qr-code/generate`;

                   return this.httpClient.post<GetQrCodeResponse>(url, {});
                 })
               );
  }


  public getTableQrCodeAsBase64Image(tableId: number): Observable<GetQrCodeResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/table/${tableId}/qr-code/as-base64-image`;

                   return this.httpClient.get<GetQrCodeResponse>(url);
                 })
               );
  }

  public downloadTableQrCodeAsPdf(tableId: number): Observable<Blob> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/table/${tableId}/qr-code/as-pdf`;

                   return this.httpClient.post(url, {},  { responseType: 'blob' });
                 })
               );
  }

  public downloadTableQrCodeAsImage(tableId: number): Observable<Blob> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/table/${tableId}/qr-code/as-image`;

                   return this.httpClient.post(url, {},  { responseType: 'blob' });
                 })
               );
  }

  public downloadTableQrCodesAsStickersImage(tableIdList: number[]): Observable<Blob> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const request: PrintQrCodesRequest = {
                     tablesList: tableIdList
                   };

                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/qr-codes/print/as-stickers`;

                   return this.httpClient.post(url, request,  { responseType: 'blob' });
                 })
               );
  }

  public assignPregeneratedCode(tableId: number, code: string): Observable<AssignPregeneratedCodeResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const request: AssignPregeneratedCodeRequest = {
                     code: code,
                   };

                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/table/${tableId}/qr-code/assign`;

                   return this.httpClient.post<AssignPregeneratedCodeResponse>(url, request);
                 })
               );
  }
}
