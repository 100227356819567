import {LeftMenuState, MenuItemDataPayload} from '../store/app.state';
import {LeftMenuItem, LeftMenuSubItem} from 'orderly-web-components';
import {TranslateService} from '@ngx-translate/core';
import {equalsMenuItemDataPayload} from '../store/reducers/left-menu/helper.functions';
import {LeftMenuConstants} from '../left-menu.constants';
import {ActivatedRouteSnapshot, Router} from '@angular/router';

export function convertLeftMenuState(state: LeftMenuState,
                                     trnService: TranslateService,
                                     router: Router): LeftMenuItem[] {
  const result: LeftMenuItem[] = [];
  const activeNodeId = getActiveId(state, router);

  for (let i = 0; i < state.items.length; i++) {
    const rootNode = state.items[i];
    const subItems: LeftMenuSubItem[] = [];

    for (let j = 0; j < rootNode.subItems.length; j++) {
      const subNode = rootNode.subItems[j];
      const translatedSubNodeName = trnService.instant(subNode.englishName);
      const subItemIcon = getIcon(subNode.id);
      const showDelimiterAfter = subNode.id.nodeId === LeftMenuConstants.RESTAURANT_EDIT;

      subItems.push(new LeftMenuSubItem(subNode.id,
                                        translatedSubNodeName,
                                        subNode.url,
                                        equalsMenuItemDataPayload(subNode.id, activeNodeId),
                                        {icon: subItemIcon, showDelimiterAfter})
      );
    }

    const translatedRootNodeName = rootNode.id.nodeId === LeftMenuConstants.RESTAURANT
                                   ? rootNode.englishName
                                   : trnService.instant(rootNode.englishName);
    const icon = getIcon(rootNode.id);
    const isActive = equalsMenuItemDataPayload(rootNode.id, activeNodeId);

    result.push(new LeftMenuItem(rootNode.id,
                                 icon,
                                 translatedRootNodeName,
                                 false,
                                 rootNode.url,
                                 subItems,
                                 isActive)
    );
  }

  return result;
}

function getLastActivatedRouteSnapshot(root: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
  if (root.firstChild != null) {
    return getLastActivatedRouteSnapshot(root.firstChild);
  }

  return root;
}

function getActiveId(state: LeftMenuState,
                     router: Router): MenuItemDataPayload | null {
  if (state.activeItemId != null) {
    return state.activeItemId;
  }


  const routeSnapshot = getLastActivatedRouteSnapshot(router.routerState.snapshot.root);
  const restaurantIdAsString: string | null = routeSnapshot.paramMap.get('restaurantId');
  const leftMenuItem: string | null = routeSnapshot.data.leftMenuItem;

  if (leftMenuItem != null && restaurantIdAsString != null) {
    const restaurantId = parseInt(restaurantIdAsString, 10);
    const activeId = new MenuItemDataPayload(restaurantId, leftMenuItem);

    for (let i = 0; i < state.items.length; i++) {
      if (equalsMenuItemDataPayload(activeId, state.items[i].id)) {
        return state.items[i].id;
      }

      for (let j = 0; j < state.items[i].subItems.length; j++) {
        if (equalsMenuItemDataPayload(activeId, state.items[i].subItems[j].id)) {
          return state.items[i].subItems[j].id;
        }
      }
    }
  }

  for (let i = 0; i < state.items.length; i++) {
    if (router.isActive(state.items[i].url, true)) {
      return state.items[i].id;
    }

    for (let j = 0; j < state.items[i].subItems.length; j++) {
      if (router.isActive(state.items[i].subItems[j].url, true)) {
        return state.items[i].subItems[j].id;
      }
    }
  }

  return null;
}

function getIcon(nodeId: MenuItemDataPayload): string | null {
  switch (nodeId.nodeId) {
    case LeftMenuConstants.RESTAURANT_EDIT:
      return 'icon-edit';
    case LeftMenuConstants.RESTAURANT:
      return 'icon-bank';
    default:
      return null;
  }
}
