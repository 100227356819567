<app-first-steps-wizard [createRestaurant]="true" *ngIf="showOnboardingWizard$ | async"></app-first-steps-wizard>

<div class="row" *ngIf="(showOnboardingWizard$ | async) === false">
  <div class="col-xxl-4 col-lg-6 col-12 restaurant-item" *ngFor="let restaurant of restaurants$ | async">
    <app-restaurant-card [restaurant]="restaurant"></app-restaurant-card>
  </div>
  <div class="col-xxl-1 col-lg-2 col-4 restaurant-item">
    <a [routerLink]="addRestaurantUrl" class="btn btn-outline-primary btn-add-new-restaurant"><i class="icon-plus"></i></a>
  </div>
</div>
