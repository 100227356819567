import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
             selector: 'app-crop-image-modal',
             templateUrl: './crop-image-modal.component.html',
             styleUrls: ['./crop-image-modal.component.scss']
           })
export class CropImageModalComponent implements OnInit {

  public actionInProgress: boolean = true;

  @Input()
  public base64ImageContent: string;

  private lastEvent: ImageCroppedEvent;

  constructor(public modalService: NgbActiveModal) {
  }

  ngOnInit() {
  }

  imageCropped(event: ImageCroppedEvent) {
    this.lastEvent = event;
  }

  public finishEdit() {
    this.modalService.close(this.lastEvent);
  }

  public imageLoaded() {
    this.actionInProgress = false;
  }
}
