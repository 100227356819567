import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {KeyCloakUserRoles} from './models/roles';
import {AuthenticatedUserModel} from './services/local-storage.service';
import {RestaurantManagerRoutingConstants, RoutingConstants} from './app/routing-constants';
import {Store} from '@ngrx/store';
import {AppState, AuthState} from './app/store/app.state';
import {map} from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class AuthenticatedUserAuthGuard implements CanActivate {
  constructor(private router: Router, private store: Store<AppState>) {
  }

  public static isJwtTokenValid(userWithToken: AuthenticatedUserModel): boolean {
    return Date.now() < userWithToken.expiresOn;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store
               .select(x => x.auth)
               .pipe(
                 map((authState: AuthState) => {
                   if (authState == null || authState.currentUser == null) {
                     return false;
                   }

                   const result = AuthenticatedUserAuthGuard.isJwtTokenValid(authState.currentUser);

                   if (result === false) {
                     const destination = this.router.parseUrl(RoutingConstants.LOGIN);

                     return destination;
                   }

                   return result;
                 })
               );
  }
}

@Injectable({ providedIn: 'root' })
export class LoginAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store
               .select(x => x.auth)
               .pipe(
                 map((authState: AuthState) => {
                   if (authState == null || authState.currentUser == null) {
                     return true;
                   }

                   const isJwtTokenValid = AuthenticatedUserAuthGuard.isJwtTokenValid(authState.currentUser);

                   if (isJwtTokenValid) {
                     const destination = this.router.parseUrl(getUserDefaultRedirectUrlByRole(authState.currentUser.roles));

                     return destination;
                   }

                   return true;
                 })
               );
  }
}

export function getUserDefaultRedirectUrlByRole(roles: string[], restaurantIds?: number[]): string {
  if (roles.indexOf(KeyCloakUserRoles.RESTAURANT_HOLDING_EXECUTIVE) >= 0 ||
    roles.indexOf(KeyCloakUserRoles.RESTAURANT_HOLDING_MANAGER) >= 0 ||
    roles.indexOf(KeyCloakUserRoles.WAITER) >= 0 ||
    roles.indexOf(KeyCloakUserRoles.RESTAURANT_OWNER) >= 0 ||
    roles.indexOf(KeyCloakUserRoles.RESTAURANT_MANAGER) >= 0 ||
    roles.indexOf(KeyCloakUserRoles.RESTAURANT_MANAGER_ASSISTANT) >= 0) {

    if (restaurantIds != null && restaurantIds.length === 1) {
      return RestaurantManagerRoutingConstants.getRestaurantDashboardFullUrl(restaurantIds[0]);
    }

    return RestaurantManagerRoutingConstants.getHoldingDashboardFullUrl();
  } else {
    throw new Error(`None of the user roles [${roles.length}] matched. Update the condition if a new role was added.`);
  }
}
