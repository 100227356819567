import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import { ActionTypes } from '../actions/action-types';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
export class TableEffect {
    constructor(actions, tableService) {
        this.actions = actions;
        this.tableService = tableService;
        this.createTableEffect = this.actions.pipe(ofType(ActionTypes.TABLE_CREATE_SUCCESSFUL), switchMap((action) => {
            return this.tableService
                .forceReload()
                .pipe(map(x => action));
        }));
        this.deleteWaiterEffect = this.actions.pipe(ofType(ActionTypes.TABLE_DELETE_SUCCESSFUL), switchMap((action) => {
            return this.tableService
                .currentRestaurantTables$
                .pipe(first(), filter(x => x.isLoaded()), tap(loadedState => {
                const notDeletedTables = loadedState.items.filter(tbl => tbl.id !== action.tableId);
                this.tableService.setValue(notDeletedTables);
            }), map(x => action));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false })
], TableEffect.prototype, "createTableEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false })
], TableEffect.prototype, "deleteWaiterEffect", void 0);
