<div class="br-header">
  <div class="br-header-left"></div>
  <div class="br-header-right">
    <nav class="nav">
      <div ngbDropdown>
        <a href="javascript:void(0);" class="nav-link nav-link-profile" aria-expanded="false" ngbDropdownToggle>
          <span class="logged-name hidden-md-down">{{user.firstName}} {{user.lastName}}</span>
          <i class="icon-user-o"></i>
        </a>

        <div ngbDropdownMenu
             class="dropdown-menu-header wd-250"
             x-placement="bottom-start"
             style="position: absolute; transform: translate3d(-65px, 48px, 0px); top: 0px; left: 0px; will-change: transform;"
             *ngIf="cfg$ | async as cfgNotNull">

          <ul class="list-unstyled user-profile-nav">
            <li><a (click)="onUpdateProfile()" href="javascript:void(0);"><i class="icon-user-o mg-r-5"></i>{{cfgNotNull.editProfileLabel}}</a></li>
            <li><a (click)="onChangePassword()" href="javascript:void(0);"><i class="icon-key mg-r-5"></i>{{cfgNotNull.changeMyPasswordLabel}}</a></li>
            <li><a (click)="onLogout()" href="javascript:void(0);"><i class="icon-logout mg-r-5"></i>{{cfgNotNull.signOutLabel}}</a></li>
          </ul>

          <ng-container *ngIf="cfgNotNull.langsConfig != null">
            <hr />
            <ul class="list-unstyled languages-menu" x-placement="bottom-start">
              <li class="title sidebar-label">{{cfgNotNull.langsConfig.languagesTitleLabel}}</li>
              <li class="lang-item" *ngFor="let lang of cfgNotNull.langsConfig.langs" [class.active]="lang.code == cfgNotNull.langsConfig.activeLanguage.code">
                <a (click)="onChangeLanguage(lang)" href="javascript:void(0);"><i class="icon-angle-right mg-r-5" *ngIf="lang.code == cfgNotNull.langsConfig.activeLanguage.code"></i>{{lang.name}}</a>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </nav>
  </div>
</div>
