import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import { ActionTypes } from '../actions/action-types';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { LocalStorageService } from '../../../services/local-storage.service';
import { getAuthenticatedUserSelector } from '../selectors/restaurant-selectors';
export class LocalizationEffect {
    constructor(actions, trnService, userService, store) {
        this.actions = actions;
        this.trnService = trnService;
        this.userService = userService;
        this.store = store;
        this.userInfoChangedEffect$ = this.actions.pipe(ofType(ActionTypes.CURRENT_USER_INFORMATION_CHANGED), tap((action) => {
            this.changeLanguageIfExists(action.lang);
        }));
        this.userInfoLoadedEffect$ = this.actions.pipe(ofType(ActionTypes.CURRENT_USER_INFORMATION_LOADED), tap((action) => {
            this.changeLanguageIfExists(action.user.preferredLang);
        }));
        this.userLanguageChangedEffect$ = this.actions.pipe(ofType(ActionTypes.CURRENT_USER_SELECTED_LANGUAGE_CHANGED), tap((action) => {
            this.changeLanguageIfExists(action.lang);
        }), switchMap((action) => {
            const authUser$ = this.store.select(getAuthenticatedUserSelector);
            return authUser$.pipe(map(user => {
                return {
                    action: action,
                    user: user
                };
            }));
        }), tap((data) => {
            if (data.user != null) {
                if (data.user.preferredLang == null || data.user.preferredLang.id !== data.action.lang.id) {
                    this.userService.updateUserLanguage(data.action.lang.code).pipe(first()).subscribe();
                }
            }
        }), map(x => x.action));
        this.loginSuccessful$ = this.actions.pipe(ofType(ActionTypes.LOGIN_SUCCESSFUL), tap((action) => {
            const parsedUser = LocalStorageService.setCurrentUser(action.jwtTokenBase64, action.user);
            console.log('change lang on login');
            this.changeLanguageIfExists(parsedUser.user.preferredLang);
        }));
    }
    changeLanguageIfExists(actionLang) {
        const actionLangCode2Lower = actionLang.code.toLocaleLowerCase();
        const langIndex = this.trnService.langs.findIndex(langCode2 => {
            const val1 = langCode2.toLocaleLowerCase();
            return val1 === actionLangCode2Lower;
        });
        if (langIndex >= 0) {
            this.trnService.use(actionLangCode2Lower);
            return true;
        }
        return false;
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false })
], LocalizationEffect.prototype, "userInfoChangedEffect$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false })
], LocalizationEffect.prototype, "userInfoLoadedEffect$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false })
], LocalizationEffect.prototype, "userLanguageChangedEffect$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false })
], LocalizationEffect.prototype, "loginSuccessful$", void 0);
