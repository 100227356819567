import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject} from 'rxjs';
import {positiveOrZeroIntegerValidator, StaticDataService} from 'orderly-web-components';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {MenuItemIngredientDef} from '../../form-fields-definitions';
import {Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import {CompareWithFn} from '@ng-select/ng-select/lib/ng-select.component';
import RestaurantIngredientBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
import Allergen = Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Allergen;
import {RestaurantComponent} from '../../../../../../restaurant.component';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../../../util/form.utils';
import {AppState} from '../../../../../../store/app.state';
import {CurrentRestaurantIngredientsService} from '../../../../../../../services/active-route-bound/current-restaurant-ingredients.service';
import {getTranslatedAllergens} from '../../../../../../../util/trn.utils';
import {compareAllergens} from '../../../../../../../util/utils';

type NewIngredientFormFieldsDef = keyof Pick<MenuItemIngredientDef, 'ingredient'>;


// TODO: override amount property type
interface MenuItemWithNullableAmount {
  amount: number | null;
  id: number | null;
  ingredient: Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
}

@Component({
             selector: 'app-editable-ingredients-list',
             templateUrl: './editable-ingredients-list.component.html',
             styleUrls: ['./editable-ingredients-list.component.scss'],
             encapsulation: ViewEncapsulation.None
           })
export class EditableIngredientsListComponent extends RestaurantComponent implements OnInit, OnDestroy {

  private usedIngredientIdList$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

  @Input()
  public ingredients: FormArray = new FormArray([]);

  public formDef: FormDefinition<NewIngredientFormFieldsDef>;

  public allergens$: Observable<Allergen[]>;
  public existingIngredients$: ReplaySubject<RestaurantIngredientBasic[]> = new ReplaySubject<RestaurantIngredientBasic[]>(1);


  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private staticDataService: StaticDataService,
              private ingredientsService: CurrentRestaurantIngredientsService,
              private trnService: TranslateService,
              private formBuilder: FormBuilder) {

    super(store, activatedRoute);

    this.allergens$ = getTranslatedAllergens(this.staticDataService, this.trnService);

    const formFieldsDefinition: FormFieldsDefinition<NewIngredientFormFieldsDef> = {
      ingredient: new FormFieldDefinition(null, false, [], [])
    };

    this.formDef = new FormDefinition<NewIngredientFormFieldsDef>(formFieldsDefinition, this.formBuilder);

    const loadedIngredients$: Observable<RestaurantIngredientBasic[]> = this.ingredientsService
                                                                            .currentLoadedRestaurantIngredients$;

    combineLatest([loadedIngredients$, this.usedIngredientIdList$])
      .pipe(
        map((data: [RestaurantIngredientBasic[], number[]]) => {
          const items = data[0];
          const selectedIngredientIds = data[1];

          const notSelectedIngredients = items.filter(x => selectedIngredientIds.indexOf(x.id) < 0);

          return notSelectedIngredients;
        })
      )
      .subscribe(this.existingIngredients$);
  }

  public static convertIngredientToFormGroup(ingredient: MenuItemWithNullableAmount, formBuilder: FormBuilder): FormDefinition<keyof MenuItemIngredientDef> {
    const ingredientFieldsDef: FormFieldsDefinition<keyof MenuItemIngredientDef> = {
      alcohol: new FormFieldDefinition(ingredient.ingredient.containsAlcohol, true, [], []),
      allergens: new FormFieldDefinition(ingredient.ingredient.allergens, true, [], []),
      ingredient: new FormFieldDefinition(ingredient.ingredient, true, [], []),
      munitname: new FormFieldDefinition(ingredient.ingredient.measureUnit.name, true, [], []),
      amount: new FormFieldDefinition(ingredient.amount, false, [Validators.required, positiveOrZeroIntegerValidator], []),
      menuItemIngredientId: new FormFieldDefinition(ingredient.id, true, [], []),
    };

    const ingredientForm = new FormDefinition(ingredientFieldsDef, formBuilder);

    return ingredientForm;
  }

  ngOnInit(): void {
    const selectedIngredientIds: number[] = [];

    for (let i = 0; i < this.ingredients.length; i++) {
      const item = this.ingredients.at(i);
      const ingredientControl = item.get('ingredient')!;
      const ingredientControlValue: RestaurantIngredientBasic = ingredientControl.value;

      ingredientControl.disable();

      selectedIngredientIds.push(ingredientControlValue.id);
    }

    const selectedIngredientIdsTmp = [...this.usedIngredientIdList$.value, ...selectedIngredientIds];

    this.usedIngredientIdList$.next(selectedIngredientIdsTmp);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.usedIngredientIdList$.complete();
    this.existingIngredients$.complete();
  }

  public delete(idx: number) {
    const menuIngredientFormGroup: FormGroup = this.ingredients.at(idx) as FormGroup;
    const restaurantIngredientControl: AbstractControl = menuIngredientFormGroup.get('ingredient')!;
    const restaurantIngredient: RestaurantIngredientBasic = restaurantIngredientControl.value;

    const usedIngredientIdsWithoutRemovedIngredient = this.usedIngredientIdList$
                                                          .value
                                                          .filter(value => value !== restaurantIngredient.id);

    this.usedIngredientIdList$.next(usedIngredientIdsWithoutRemovedIngredient);

    this.ingredients.removeAt(idx);
  }

  public onIngredientSelected($event: RestaurantIngredientBasic) {
    if ($event == null) {
      return;
    }

    const menuItemIngredient: MenuItemWithNullableAmount = {
      amount: null,
      id: null,
      ingredient: $event
    };

    const usedIngredientIdsWithAddedIngredient = [...this.usedIngredientIdList$.value, $event.id];
    const group = EditableIngredientsListComponent.convertIngredientToFormGroup(menuItemIngredient, this.formBuilder);

    this.usedIngredientIdList$.next(usedIngredientIdsWithAddedIngredient);
    this.ingredients.push(group.form);

    this.formDef.setControlValue('ingredient', null);
  }

  public compareAllergensSelectItems: CompareWithFn = (a1: Allergen, a2: Allergen) => {
    return compareAllergens(a1, a2);
  };
}
