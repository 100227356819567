import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, of, Subject} from 'rxjs';
import {catchError, distinctUntilChanged, filter, first, map, skipWhile, switchMap, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {
  RestaurantSpecificArrayResultLoadingState
} from './helper.statuses';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {RestaurantTableCheckinsServiceDefinition} from '../RestaurantTableCheckinsServiceDefinition';
import GetCovidTableCheckinListResponse = Orderly.RestaurantWeb.Api.Messages.Checkin.GetCovidTableCheckinListResponse;
import GetCovidTableCheckinListResponseItem = Orderly.RestaurantWeb.Api.Messages.Checkin.GetCovidTableCheckinListResponse.Item;
import {selectRestaurantIdParamFromAppState} from '../../app/store/selectors/restaurant-selectors';
import {
  createFailedResult,
  createNotStartedResult,
  createSuccessResult,
  CurrentRestaurantServiceBase
} from './current-restaurant.service.base';
import GetCovidTableCheckinListRequest = Orderly.RestaurantWeb.Api.Messages.Checkin.GetCovidTableCheckinListRequest;

@Injectable({
              providedIn: 'root'
            })
export class CurrentRestaurantTableCheckinsService
  implements RestaurantTableCheckinsServiceDefinition {

  private baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  private readonly cache$: BehaviorSubject<RestaurantSpecificArrayResultLoadingState<GetCovidTableCheckinListResponseItem>>;
  protected readonly restaurantIdRouteParam$: Observable<number | null> = this.store.pipe(selectRestaurantIdParamFromAppState);


  public get currentRestaurantTableCheckins$(): Observable<RestaurantSpecificArrayResultLoadingState<GetCovidTableCheckinListResponseItem>> {
    return this.cache$;
  }

  constructor(private httpClient: HttpClient, public readonly store: Store<AppState>) {
    const notStartedResult = createNotStartedResult<GetCovidTableCheckinListResponseItem>();

    this.cache$ = new BehaviorSubject<RestaurantSpecificArrayResultLoadingState<GetCovidTableCheckinListResponseItem>>(notStartedResult);

    this.restaurantIdRouteParam$
        .pipe(
          skipWhile(restaurantId => this.cache$.value.isNotStarted()),
          distinctUntilChanged((x, y) => x === y),

          tap(restaurantId => this.pushLoadingOrClearedState(restaurantId)),

          filter(restaurantId => restaurantId != null),

          switchMap((restaurantId: number) => this.loadDataAndConvertToLoadState(restaurantId, new Date()))
        )
        .subscribe(this.cache$);
  }

  protected doLoadData(restaurantId: number, checkinDate: Date): Observable<GetCovidTableCheckinListResponseItem[]> {
    const request: GetCovidTableCheckinListRequest = {
      date: checkinDate,
      topRecords: 100,
    };
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/checkins/list`;
    const response$ = this.httpClient.post<GetCovidTableCheckinListResponse>(url, request);

    return response$.pipe(
      tap(x => {
        if (x.status === GetCovidTableCheckinListResponse.StatusDef.UnknownFailure) {
          throw new Error('Server returned an error. Could not load list of COVID checkins');
        }
      }),
      map(x => x.items)
    );
  }


  public forceReload(checkinDate: Date): Observable<any> {
    if (this.cache$.value.isLoading()) {
      return EMPTY;
    }

    const result = new Subject<any>();

    this.restaurantIdRouteParam$.pipe(
      // NOTE: first is important! it will cancel this subscription after result is loaded
      first(),

      tap(restaurantId => this.pushLoadingOrClearedState(restaurantId)),

      filter(restaurantId => restaurantId != null),

      switchMap((restaurantId: number) => this.loadDataAndConvertToLoadState(restaurantId, checkinDate)),

      tap(newState => this.cache$.next(newState))

    ).subscribe(result);

    return result;
  }

  public downloadCheckinAsPdf(id: string): Observable<Blob> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = `${this.baseURL}/restaurant/${restaurantId}/checkin/${id}/as-pdf`;

                   return this.httpClient.post(url, {},  { responseType: 'blob' });
                 })
               );
  }

  private loadDataAndConvertToLoadState(restaurantId: number, checkinDate: Date)
    : Observable<RestaurantSpecificArrayResultLoadingState<GetCovidTableCheckinListResponseItem>> {
    return this.doLoadData(restaurantId, checkinDate)
               .pipe(
                 map(data => createSuccessResult(data)),
                 catchError((err) => of(createFailedResult<GetCovidTableCheckinListResponseItem>(err)))
               );
  }

  private pushLoadingOrClearedState(restaurantId: number | null) {
    if (restaurantId == null) {
      this.cache$.next(CurrentRestaurantServiceBase.clearedResult);
    } else {
      this.cache$.next(CurrentRestaurantServiceBase.loadingResult);
    }
  }
}
