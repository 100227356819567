import {Component, ElementRef, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {tap, first, catchError, switchMap, map, filter} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddTableModalComponent} from '../add-table-modal/add-table-modal.component';

import {
  ConfirmationModalDialogComponent, ModalMessageTextParagraph,
  NeverError, PaginationFiltersStateDefinition, ToastService
} from 'orderly-web-components';
import DeleteTableResponse = Orderly.RestaurantWeb.Api.Messages.Table.DeleteTableResponse;
import OpStatus = Orderly.RestaurantWeb.Api.Messages.Table.DeleteTableResponse.OpStatus;
import { TableQrCodeModalComponent} from '../table-qr-code-modal/table-qr-code-modal.component'
import {ActivatedRoute} from '@angular/router';
import GetQrCodeResponse = Orderly.RestaurantWeb.Api.Messages.Table.GetQrCodeResponse;
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { saveAs } from 'file-saver';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, throwError} from 'rxjs';
import {TablesListGridItem} from './helper.types';
import moment from 'moment';
import {RestaurantRelatedItemsGrid} from '../../../../dashboard/restaurant-related-items-grid.component';
import {AppState} from '../../../../store/app.state';
import {DeleteModalUtils} from '../../../../../util/delete-modal.utils';
import {trnCancelButtonTitle, trnTryAgainLaterText} from '../../../../../util/trn.utils';
import {formatTableFileNameWithoutExtension, genericErrorHandlerWithToast} from '../../../../../util/utils';
import {GridItemsSourceService} from '../../services/grid-items-source.service';
import {AssignPregeneratedCodeModalComponent} from '../assign-pregenerated-code-modal/assign-pregenerated-code-modal.component';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {CurrentRestaurantTablesService} from '../../../../../services/active-route-bound/current-restaurant-tables.service';
import AssignPregeneratedCodeResponse = Orderly.RestaurantWeb.Api.Messages.Table.AssignPregeneratedCodeResponse;
import AssignPregeneratedCodeResponseStatusDef = Orderly.RestaurantWeb.Api.Messages.Table.AssignPregeneratedCodeResponse.StatusDef;
import {AssignPregeneratedCodeFormDefinition} from '../assign-pregenerated-code-modal/assign-pregenerated-code-form.definition';

@Component({
             selector: 'app-tables-list',
             templateUrl: './tables-list.component.html',
             styleUrls: ['./tables-list.component.scss']
           })
export class TablesListComponent extends RestaurantRelatedItemsGrid<TablesListGridItem, PaginationFiltersStateDefinition> {

  @ViewChild('thead', {static: false})
  thead: ElementRef;

  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private modalService: NgbModal,
              private gridItemsSourceService: GridItemsSourceService,
              private deleteModalUtils: DeleteModalUtils,
              private toastService: ToastService,
              private tableService: CurrentRestaurantTablesService,
              private trnService: TranslateService) {

    super(store, activatedRoute, RestaurantRelatedItemsGrid.basicInitialFiltersState, gridItemsSourceService.items$);
  }

  public refreshTablesList() {
    this.gridItemsSourceService
        .forceReload()
        .pipe(
          switchMap(x => this.filtersState$),
          first(),
          tap(filterState => {
            const currentFiltersStateClone = {...filterState};

            currentFiltersStateClone.currentPage = 1;

            this.pagingFiltersChanged(currentFiltersStateClone);
          })
        )
        .subscribe();
  }

  public addTable() {
    const modalRef = this.modalService.open(AddTableModalComponent, {centered: true, size: 'lg'});

    modalRef.result
            .then((value: boolean | null) => {
              if (value === true) {
                this.gridItemsSourceService
                    .forceReload()
                    .pipe(
                      first()
                    )
                    .subscribe();
              }
            });
  }

  public deleteTable(table: TablesListGridItem) {

    const tryAgainLaterTxt = trnTryAgainLaterText(this.trnService);
    const errorTxt = this.trnService.instant('Failed to remove a table because of unexpected failure.');
    const unexpectedFailureText = `${errorTxt} ${tryAgainLaterTxt}`;

    const onDeleted = (modalRef) => {
      this.gridItemsSourceService
          .delete(table.id)
          .pipe(
            switchMap((response: DeleteTableResponse) => {
              switch (response.status) {
                case OpStatus.Success:
                  modalRef.close(false);

                  return this.gridItemsSourceService.forceReload();
                case OpStatus.Exception:
                  this.toastService.showError(unexpectedFailureText);

                  return of(null);
                default:
                  throw new NeverError(response.status);
              }
            }),
            first(),
            catchError((err, _) => {
              this.toastService.showError(unexpectedFailureText);

              return throwError(err);
            }),
          )
          .subscribe();
    };

    const modalTitle = this.trnService.instant('Delete a table');
    const confirmationText = this.trnService.instant('Are you sure you want to remove a table \'{{name}}\' from your restaurant?',
                                                     {name: table.clientTableName});

    this.deleteModalUtils.showDeleteModal(table, modalTitle, [confirmationText], onDeleted);
  }

  public getTableQrCode(table: TablesListGridItem) {
    const modalRef = this.modalService.open(TableQrCodeModalComponent, {centered: true, size: 'lg'});
    const component: TableQrCodeModalComponent = modalRef.componentInstance;

    component.table = table.source;
  }

  public generateNewQrCode(table: TablesListGridItem) {

    const onAgree = (modal: NgbModalRef) => {

      const tryAgainLaterTxt = trnTryAgainLaterText(this.trnService);
      const errorTxt = this.trnService.instant('Failed to generate new QR code because of unexpected failure.');
      const unexpectedFailureText = `${errorTxt} ${tryAgainLaterTxt}`;

      this.gridItemsSourceService
          .generateNewQrCode(table.id)
          .pipe(
            first(),
            tap((response: GetQrCodeResponse) => {
              switch (response.status) {
                case GetQrCodeResponse.OpStatus.Success:
                  modal.close();

                  this.toastService.showSuccess(this.trnService.instant('New QR code was generated.'));
                  break;
                case GetQrCodeResponse.OpStatus.Exception:
                  this.toastService.showError(unexpectedFailureText);
                  break;
                default:
                  throw new NeverError(response.status);
              }
            }),
            catchError(genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedFailureText)),
          )
          .subscribe();
    };
    const modalRef = this.modalService.open(ConfirmationModalDialogComponent, {centered: true, size: 'lg'});

    const modalInstance: ConfirmationModalDialogComponent = modalRef.componentInstance;

    const confirmationMsgP1 = this.trnService.instant('Are you sure that you want to generate new QR code for table \'{{name}}\'?',
                                                      {name: table.clientTableName});

    const confirmationMsgP2 = this.trnService.instant('You will have to print new QR code to be able to receive notifications from the clients.');

    modalInstance.cancelButtonText = trnCancelButtonTitle(this.trnService);
    modalInstance.okButtonText = this.trnService.instant('Generate');
    modalInstance.data = table;
    modalInstance.title = this.trnService.instant('Generate new QR code for table \'{{name}}\'?', {name: table.clientTableName});
    modalInstance.messages = [new ModalMessageTextParagraph(confirmationMsgP1), new ModalMessageTextParagraph(confirmationMsgP2)];

    const completedSubscription = modalInstance.completed.subscribe(($e) => {
      onAgree(modalRef);
    });

    modalRef.result.finally(() => {
      completedSubscription.unsubscribe();
    });
  }

  public downloadQrCodeAsPdf(table: TablesListGridItem) {
    this.gridItemsSourceService
        .downloadTableQrCodeAsPdf(table.id)
        .pipe(
          first(),
          tap((res: Blob) => {
            const fileName = formatTableFileNameWithoutExtension(table.source) + '.pdf';

            saveAs(res, fileName);
          })
        )
        .subscribe();
  }

  public assignDisplay(table: TablesListGridItem) {

  }

  public updateTable(table: TablesListGridItem) {

  }

  public printQrCodesForSelectedTables() {
    this.gridItemsSourceService
        .items$
        .pipe(
          filter(x => x.isLoaded()),
          map(x => x.items.filter(tbl => tbl.isSelected)),
          tap((tables: TablesListGridItem[]) => {
            if (tables.length > 12) {
              this.showMaxPrintStickersError();
            } else {
              this.showPrintStickersConfirmation(tables);
            }
          }),
          first()
        )
        .subscribe();
  }

  public get isAnyTableSelected(): Observable<boolean> {
    return this.gridItemsSourceService
               .items$
               .pipe(
                 map(x => x.items.filter(tbl => tbl.isSelected).length > 0)
               );
  }

  private showPrintStickersConfirmation(tables: TablesListGridItem[]) {
    const modalRef = this.modalService.open(ConfirmationModalDialogComponent, {centered: true, size: 'lg'});

    const modalInstance: ConfirmationModalDialogComponent = modalRef.componentInstance;
    const maxStickersCount = this.trnService.instant('You are going to print stickers for the following tables:');
    const tableNamesParagraphs: ModalMessageTextParagraph[] = tables.map(
      (tbl, idx) => new ModalMessageTextParagraph(`${idx + 1}) ${tbl.clientTableName}`, 'margin-left: 20px;'));

    modalInstance.cancelButtonText = trnCancelButtonTitle(this.trnService);
    modalInstance.okButtonText = tables.length > 0 ? this.trnService.instant('Print') : this.trnService.instant('Close');
    modalInstance.title = this.trnService.instant('Print QR-code stickers');
    modalInstance.messages = [new ModalMessageTextParagraph(maxStickersCount), ...tableNamesParagraphs];

    const completedSubscription = modalInstance.completed.subscribe(($e) => {
      const unexpectedFailureText = this.trnService.instant('Download failed because of unexpected failure. Please try again later.');
      const tableIds = tables.map(x => x.id);

      if (tables.length > 0) {
        this.gridItemsSourceService
            .downloadTableQrCodesAsStickersImage(tableIds)
            .pipe(
              tap((res: Blob | null) => {
                if (res == null) {
                  return;
                }

                const timestamp = moment().format('YYYYMMDDHHmm');
                const fileName = 'table_stickers_' + timestamp + '.jpg';

                for (const tbl of tables) {
                  tbl.isSelected = false;
                }

                saveAs(res, fileName);
              }),
              first(),
              catchError(genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedFailureText))
            )
            .subscribe();
      }

      modalRef.close();
    });

    modalRef.result.finally(() => {
      completedSubscription.unsubscribe();
    });
  }

  private showMaxPrintStickersError() {
    const modalRef = this.modalService.open(ConfirmationModalDialogComponent, {centered: true, size: 'lg'});

    const modalInstance: ConfirmationModalDialogComponent = modalRef.componentInstance;
    const maxStickersCount = this.trnService.instant('You can print maximum of {{count}} stickers at once', {count: 12});

    modalInstance.cancelButtonText = trnCancelButtonTitle(this.trnService);
    modalInstance.okButtonText = this.trnService.instant('Close');
    modalInstance.title = this.trnService.instant('Print QR-code stickers');
    modalInstance.messages = [new ModalMessageTextParagraph(maxStickersCount)];

    const completedSubscription = modalInstance.completed.subscribe(($e) => {
      modalRef.close();
    });

    modalRef.result.finally(() => {
      completedSubscription.unsubscribe();
    });
  }

  public pagingFiltersChanged($event: PaginationFiltersStateDefinition): void {
    super.pagingFiltersChanged($event);

    if (this.thead.nativeElement.scrollIntoView) {
      this.thead.nativeElement.scrollIntoView(true);
    }
  }

  public assignPregeneratedCode(table: TablesListGridItem): void {
    const modalRef = this.modalService.open(AssignPregeneratedCodeModalComponent, {centered: true, size: 'lg'});
    const modalInstance: AssignPregeneratedCodeModalComponent = modalRef.componentInstance;

    const saveSubscription = modalInstance.save
                 .pipe(
                   tap((formValue: AssignPregeneratedCodeFormDefinition) => {
                     const unexpectedServerFailureMsg = this.trnService.instant(_('Failed to assign a code because of unexpected failure.'));

                     this.tableService
                         .assignPregeneratedCode(table.id, formValue.code)
                         .pipe(
                           first(),
                           tap((response: AssignPregeneratedCodeResponse) => {
                             switch (response.status) {
                               case AssignPregeneratedCodeResponseStatusDef.Success:
                                 const successMsg = this.trnService.instant(_('Code was successfully assigned.'));
                                 this.toastService.showSuccess(successMsg);

                                 modalRef.close();
                                 break;
                               case AssignPregeneratedCodeResponseStatusDef.CodeWasAlreadyUsed:
                                 const codeWasUsedMsg = this.trnService.instant(_('Code was already used.'));
                                 this.toastService.showError(codeWasUsedMsg);
                                 break;
                               case AssignPregeneratedCodeResponseStatusDef.ValidationFailed:
                               case AssignPregeneratedCodeResponseStatusDef.TableDoesNotExist:
                               case AssignPregeneratedCodeResponseStatusDef.UnexpectedError:
                                 this.toastService.showError(unexpectedServerFailureMsg);
                                 break;
                               default:
                                 throw new NeverError(response.status);
                             }
                           }),
                           catchError(
                             genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedServerFailureMsg)
                           )
                         )
                         .subscribe();
                   })
                 )
                 .subscribe();

    modalRef.result.finally(() => {
      saveSubscription.unsubscribe();
    });
  }
}
