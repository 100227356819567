import {Component, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState, LoadingStatusDefinition} from '../../store/app.state';
import {MeasureUnit, NeverError, StaticDataService, ToastService} from 'orderly-web-components';
import {BehaviorSubject, from, Observable, of, ReplaySubject} from 'rxjs';
import {catchError, filter, finalize, first, flatMap, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AddOrEditMenuItemIngredientComponent} from '../../add-or-edit-menu-item-ingredient/add-or-edit-menu-item-ingredient.component';
import {environment} from '../../../environments/environment';
import {DeleteModalUtils} from '../../../util/delete-modal.utils';
import {RestaurantManagerRoutingConstants} from '../../routing-constants';
import {RestaurantComponent} from '../../restaurant.component';
import {CurrentRestaurantIngredientsService} from '../../../services/active-route-bound/current-restaurant-ingredients.service';
import {genericErrorHandlerWithToast} from '../../../util/utils';
import {TranslateService} from '@ngx-translate/core';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../util/form.utils';
import {CurrentRestaurantMenuItemCategoriesService} from '../../../services/active-route-bound/current-restaurant-menu-item-categories.service';
import {FormBuilder} from '@angular/forms';
import {SearchFiltersDefinition} from './search-filters-definition';
import {TrueFalseUnknownSelectItem} from './helper.models';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {CurrentRestaurantMenuItemsService} from '../../../services/active-route-bound/current-restaurant-menu-items.service';
import MenuItemWithAllData = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData;
import MenuItemIngredient = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData.MenuIngredient;
import MenuItemModifier = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData.MenuItemModifier;
import DeleteMenuItemIngredientResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.DeleteMenuItemIngredientResponse;
import DeleteMenuItemIngredientResponseStatusDef =
  Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.DeleteMenuItemIngredientResponse.StatusDef;

import RestaurantIngredientBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
import GetMenuItemIngredientsResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.GetMenuItemIngredientsResponse;
import DeleteMenuItemResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.DeleteMenuItemResponse;
import DeleteMenuItemResponseStatusDef = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.DeleteMenuItemResponse.StatusDef;
import GetMenuItemsResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.GetMenuItemsResponse;
import MenuItemCategory = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemCategoryResponse.MenuItemCategory;
import QrMenuActionTypeDef = Orderly.Common.Enums.QrMenuActionTypeDef;
import IngredientCategory = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddOrUpdateIngredientCategoryResponse.IngredientCategory;
import MenuItemModifierGroupWithModifiers = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupWithModifiers;


type SearchFormFields = 'category' | 'ingredients' | 'withModifiers' | 'withPromotions';

@Component({
             selector: 'app-menu-items-list',
             templateUrl: './menu-items-list.component.html',
             styleUrls: ['./menu-items-list.component.scss'],
             encapsulation: ViewEncapsulation.None
           })
export class MenuItemsListComponent extends RestaurantComponent {

  static initialFiltersState: SearchFiltersDefinition = {
    itemsPerPage: 10,
    currentPage: 1,
    category: null,
    ingredients: [],
    withModifiers: null,
    withPromotions: null
  };

  private measureUnits: Observable<MeasureUnit[]>;

  public editUrl$: Observable<string>;

  public searchButtonVisible: boolean = true;
  public formDef: FormDefinition<SearchFormFields>;
  public status: BehaviorSubject<LoadingStatusDefinition> = new BehaviorSubject<LoadingStatusDefinition>(LoadingStatusDefinition.LOADING);
  public menuItems$: Observable<MenuItemRow[]>;
  public foundMenuItems$: ReplaySubject<MenuItemRow[]> = new ReplaySubject<MenuItemRow[]>(1);
  public ingredients$: Observable<RestaurantIngredientBasic[]>;
  public categories$: Observable<MenuItemCategory[]>;
  public yesNoSelectValues$: ReplaySubject<TrueFalseUnknownSelectItem[]> = new ReplaySubject<TrueFalseUnknownSelectItem[]>(1);
  public onlineMenuIsNotActivated$: Observable<boolean>;


  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private readonly formBuilder: FormBuilder,
              private readonly staticDataService: StaticDataService,
              private readonly router: Router,
              private readonly ingredientsService: CurrentRestaurantIngredientsService,
              private readonly restaurantMenuService: CurrentRestaurantMenuItemsService,
              private readonly menuItemCategoriesService: CurrentRestaurantMenuItemCategoriesService,
              private readonly toastService: ToastService,
              private readonly deleteModalUtils: DeleteModalUtils,
              private readonly trnService: TranslateService,
              private readonly modalService: NgbModal) {
    super(store, activatedRoute);

    this.editUrl$ = this.restaurantIdParam$
                        .pipe(
                          map(id => '/' + RestaurantManagerRoutingConstants.getRestaurantEditUrl(id))
                        );

    this.onlineMenuIsNotActivated$ = this.currentRestaurant$.pipe(map(x => x.settings.qrMenuActionType !== QrMenuActionTypeDef.OnlineMenu));

    this.measureUnits = this.staticDataService
                            .getAllStaticData(environment.baseApiUrlWithTrailingSlash)
                            .pipe(map(x => x.measureUnits));

    this.menuItems$ = this.foundMenuItems$;

    const allKey = _('All');
    const yesKey = _('Yes');
    const noKey = _('No');

    this.trnService
        .stream([allKey, yesKey, noKey])
        .pipe(
          map(x => {
            const allItem: TrueFalseUnknownSelectItem = {
              name: x[allKey],
              value: null
            };
            const yesItem: TrueFalseUnknownSelectItem = {
              name: x[yesKey],
              value: true
            };
            const noItem: TrueFalseUnknownSelectItem = {
              name: x[noKey],
              value: false
            };

            return [allItem, yesItem, noItem];
          })
        ).subscribe(this.yesNoSelectValues$);


    this.initSearchForm();
    this.loadMenuItems();

    this.formDef.disable(['category', 'ingredients']);

    this.categories$ = this.menuItemCategoriesService
                           .currentRestaurantMenuItemCategories$
                           .pipe(
                             filter(x => x.isLoaded()),
                             map(x => x.items),
                             tap(x => {
                               this.formDef.enable(['category']);
                             })
                           );

    this.ingredients$ = this.ingredientsService
                            .currentLoadedRestaurantIngredients$
                            .pipe(
                              tap(x => {
                                this.formDef.enable(['ingredients']);
                              })
                            );
  }

  private initSearchForm() {
    const fieldsDef: FormFieldsDefinition<SearchFormFields> = {
      withModifiers: new FormFieldDefinition(MenuItemsListComponent.initialFiltersState.withModifiers, false, [], []),
      withPromotions: new FormFieldDefinition(MenuItemsListComponent.initialFiltersState.withModifiers, false, [], []),
      ingredients: new FormFieldDefinition(MenuItemsListComponent.initialFiltersState.ingredients, false, [], []),
      category: new FormFieldDefinition(MenuItemsListComponent.initialFiltersState.category, false, [], [])
    };

    this.formDef = new FormDefinition<SearchFormFields>(fieldsDef, this.formBuilder);

    this.formDef
        .form
        .valueChanges
        .pipe(
          tap((currentFiltersState: SearchFiltersDefinition) => this.searchInLoadedMenuItems(currentFiltersState)),
          takeUntil(this.destroyed$)
        )
        .subscribe();
  }

  private searchInLoadedMenuItems(currentFiltersState: SearchFiltersDefinition) {
    this.searchButtonVisible = true;

    const selectedCategory: IngredientCategory | null = currentFiltersState.category;
    const selectedIngredients: RestaurantIngredientBasic[] = currentFiltersState.ingredients;
    const withModifiers: boolean | null = currentFiltersState.withModifiers;

    this.menuItems$ = this.foundMenuItems$
                          .pipe(
                            map(items => items.filter(item => {
                              if (selectedCategory != null) {
                                const categoryFound = item.data.categories.findIndex(
                                  c => c.id === selectedCategory.id) > -1;

                                if (!categoryFound) {
                                  return false;
                                }
                              }

                              if (withModifiers != null) {
                                if (item.data.modifierGroups.length === 0 && withModifiers) {
                                  return false;
                                }

                                const modifiersCount = item.data.modifierGroups.reduce((prevVal: number,
                                                                                        currentVal: MenuItemModifierGroupWithModifiers,
                                                                                        idx,
                                                                                        arr) => {
                                  return prevVal + currentVal.menuItemModifiers.length;
                                }, 0);

                                if (withModifiers) {
                                  return modifiersCount > 0;
                                } else {
                                  return modifiersCount === 0;
                                }
                              }

                              if (selectedIngredients && selectedIngredients.length > 0) {
                                if (item.data.ingredients.length === 0) {
                                  return false;
                                }

                                let foundIngredientsCount = 0;

                                for (let i = 0; i < selectedIngredients.length; i++) {
                                  const selectedIngredientId = selectedIngredients[i].id;
                                  const idx = item.data
                                                  .ingredients
                                                  .findIndex(menuItemIngredient => menuItemIngredient.ingredient.id === selectedIngredientId);

                                  if (idx >= 0) {
                                    foundIngredientsCount++;
                                  }
                                }

                                if (selectedIngredients.length !== foundIngredientsCount) {
                                  return false;
                                }
                              }

                              return true;
                            }))
                          );
  }

  private loadMenuItems() {
    this.searchButtonVisible = false;

    this.restaurantMenuService
        .search()
        .pipe(
          map((x: GetMenuItemsResponse) => x.items.map(i => new MenuItemRow(i))),
          tap((x: MenuItemRow[]) => {
            this.status.next(LoadingStatusDefinition.SUCCESS);
          }),
          first(),
          catchError(err => {
            this.status.next(LoadingStatusDefinition.FAILED);

            return of([]);
          }),
          finalize(() => {
            this.searchButtonVisible = true;
          })
        )
        .subscribe(this.foundMenuItems$);
  }


  public doSearch() {
    this.loadMenuItems();

    this.searchInLoadedMenuItems(this.formDef.form.getRawValue());
  }

  public isLoadedSuccessfully(): Observable<boolean> {
    return this.status.pipe(map(x => x === LoadingStatusDefinition.SUCCESS));
  }

  public isLoadingInProgress(): Observable<boolean> {
    return this.status.pipe(map(x => x === LoadingStatusDefinition.LOADING));
  }

  public isLoadingFailed(): Observable<boolean> {
    return this.status.pipe(map(x => x === LoadingStatusDefinition.FAILED));
  }

  // this method is required to enable syntax highlighting in template; there is not other use of it
  public getIngredients(menuItem: MenuItemWithAllData): MenuItemIngredient[] {
    return menuItem.ingredients;
  }


  // this method is required to enable syntax highlighting in template; there is not other use of it
  public getModifiers(modifierGroup: MenuItemModifierGroupWithModifiers): MenuItemModifier[] {
    return modifierGroup.menuItemModifiers;
  }

  public addOrEditNewMenuItem(data?: MenuItemWithAllData) {
    this.restaurantIdParam$
        .pipe(
          first(),
          switchMap(rid => {
            const url = data == null
                        ? RestaurantManagerRoutingConstants.getRestaurantMenuItemCreateUrl(rid)
                        : RestaurantManagerRoutingConstants.getRestaurantMenuItemEditUrl(rid, data.id);

            return from(this.router.navigateByUrl(url));
          })
        ).subscribe();
  }

  public deleteMenuItem(menuItem: MenuItemRow) {
    const confirmationMsg: string[] = [this.trnService.instant('Are you sure you want to remove a menu item "{{name}}"?', {name: menuItem.data.name})];

    const onDelete = (modalRef: NgbModalRef) => {
      const unexpectedFailureText = this.trnService.instant('Failed to remove a menu item because of unexpected failure. Please try again later.');

      this.restaurantMenuService
          .delete(menuItem.data.id)
          .pipe(
            tap((response: DeleteMenuItemResponse) => {
              switch (response.status) {
                case DeleteMenuItemResponseStatusDef.Success:

                  this.foundMenuItems$
                      .pipe(
                        first(),
                        map(x => {
                          return x.filter(item => item.data.id !== menuItem.data.id);
                        })
                      )
                      .subscribe(this.foundMenuItems$);

                  this.searchInLoadedMenuItems(this.formDef.form.getRawValue());

                  modalRef.close(true);
                  break;
                case DeleteMenuItemResponseStatusDef.UnexpectedError:
                  this.toastService.showError(unexpectedFailureText);
                  break;
                default:
                  throw new NeverError(response.status);
              }
            }),
            first(),
            catchError(genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedFailureText))
          )
          .subscribe();
    };

    this.deleteModalUtils.showDeleteModal(menuItem,
                                          this.trnService.instant('Delete a menu item'),
                                          confirmationMsg,
                                          onDelete);
  }

  public addOrEditMenuItemIngredient(menuItem: MenuItemWithAllData, ingredient?: MenuItemIngredient) {
    this.restaurantIdParam$
        .pipe(
          first(),
          tap(restaurantId => {
            const modalRef = this.modalService.open(AddOrEditMenuItemIngredientComponent, {centered: true, size: 'lg'});

            const modalInstance: AddOrEditMenuItemIngredientComponent = modalRef.componentInstance;

            modalInstance.menuItemId = menuItem.id;
            modalInstance.restaurantId = restaurantId;
            modalInstance.ingredients = this.ingredients$;
            modalInstance.measureUnits = this.measureUnits;

            if (ingredient != null) {
              modalInstance.ingredientToEdit = {
                amount: ingredient.amount,
                id: ingredient.id,
                measureUnitId: ingredient.ingredient.measureUnit.id,
                name: ingredient.ingredient.name,
                ingredientId: ingredient.ingredient.id
              };
            } else {
              modalInstance.ingredientToEdit = {
                amount: null,
                id: null,
                measureUnitId: null,
                name: '',
                ingredientId: null
              };
            }

            const completedSubscription = modalInstance.completed.subscribe(($e) => {
              this.reloadMenuItemIngredients(menuItem);
            });

            modalRef.result.finally(() => {
              completedSubscription.unsubscribe();
            });
          })
        )
        .subscribe();
  }

  public deleteMenuItemIngredient(menuItem: MenuItemWithAllData, menuItemIngredient: MenuItemIngredient) {
    const onDelete = (modalRef: NgbModalRef) => {
      const unexpectedFailureText = this.trnService.instant('Failed to remove an ingredient because of unexpected failure. Please try again later.');

      this.restaurantIdParam$
          .pipe(
            first(),
            flatMap(restaurantId => this.restaurantMenuService.deleteMenuItemIngredient(menuItemIngredient.id)),
            tap((response: DeleteMenuItemIngredientResponse) => {
              switch (response.status) {
                case DeleteMenuItemIngredientResponseStatusDef.Success:
                  modalRef.close(true);

                  menuItem.ingredients = menuItem.ingredients.filter(value => value.id !== menuItemIngredient.id);
                  break;
                case DeleteMenuItemIngredientResponseStatusDef.UnknownFailure:
                  this.toastService.showError(unexpectedFailureText);
                  break;
                default:
                  throw new NeverError(response.status);
              }
            }),
            catchError(genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedFailureText))
          )
          .subscribe();
    };

    const confirmationTitle = this.trnService.instant('Delete an ingredient');
    const confirmationText = this.trnService.instant('Are you sure you want to remove an ingredient "{{name}}"?', {name: menuItemIngredient.ingredient.name});

    this.deleteModalUtils.showDeleteModal(menuItemIngredient,
                                          confirmationTitle,
                                          [confirmationText],
                                          onDelete);
  }

  public reloadMenuItemIngredients(menuItem: MenuItemWithAllData) {
    this.restaurantMenuService
        .getMenuItemIngredients(menuItem.id)
        .pipe(
          tap((response: GetMenuItemIngredientsResponse) => {
            menuItem.ingredients = response.ingredients;
          }),
          first()
        )
        .subscribe();
  }
}

export class MenuItemRow {
  public readonly showContainsAlcoholSign: boolean = false;

  constructor(public readonly data: MenuItemWithAllData,
              public isExpanded: boolean = false) {

    this.showContainsAlcoholSign = data.ingredients.findIndex(x => x.ingredient.containsAlcohol) >= 0;
  }
}

export class MenuItemModifierGroupRow {
  constructor(public readonly data: MenuItemModifierGroupWithModifiers,
              public readonly parent: MenuItemWithAllData,
              public isExpanded: boolean = false) {}
}
