<div class="modal-header pd-y-20 pd-x-25">
  <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold" *ngIf="modifierToEdit.modifier == null">{{'Add menu item modifier' | translate}}</h6>
  <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold" *ngIf="modifierToEdit.modifier != null">{{'Update menu item modifier' | translate}}</h6>
  <button type="button" class="close" [attr.aria-label]="'Close'" (click)="activeModal.dismiss(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pd-25">
  <form [formGroup]="formDef.form" (ngSubmit)="save()">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="form-control-label" for="ingredientOrName">{{'Ingredient or modifier name' | translate}}: <span class="tx-danger">*</span></label>

          <app-shared-modifier-ingredient-select
            [formControl]="formDef.getControl('ingredientOrName')"
            [ingredients$]="ingredients$">

          </app-shared-modifier-ingredient-select>

          <owc-input-validation-errors [control]="formDef.getControl('ingredientOrName')"></owc-input-validation-errors>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="form-control-label" for="amount">{{'Amount' | translate}}:</label>

          <input class="form-control"
                 [formControl]="formDef.getControl('amount')"
                 maxlength="10"
                 inputNumber
                 [acceptDecimalPlaces]="true"
                 [decimalPlaces]="3">

          <owc-input-validation-errors [control]="formDef.getControl('amount')"></owc-input-validation-errors>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label class="form-control-label" for="measureUnitId">{{'Measure unit' | translate}}:
            <span class="tx-danger" *ngIf="formDef.getControl('amount').value != null && formDef.getControl('amount').value != ''">*</span></label>


            <app-shared-measure-unit-select [formControl]="formDef.getControl('measureUnitId')"
                                            id="measureUnitId">
            </app-shared-measure-unit-select>

          <owc-input-validation-errors [control]="formDef.getControl('measureUnitId')"></owc-input-validation-errors>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="form-control-label" for="priceDiff">{{'Price difference' | translate}}: <span class="tx-danger">*</span></label>

          <input class="form-control"
                 [formControl]="formDef.getControl('priceDiff')"
                 maxlength="10"
                 inputNumber
                 [acceptDecimalPlaces]="true"
                 [decimalPlaces]="2">

          <owc-input-validation-errors [control]="formDef.getControl('priceDiff')"></owc-input-validation-errors>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer tx-white">
  <owc-progress-action-button [actionInProgress]="actionInProgress"
                              (execute)="save()"
                              [btnText]="'Save' | translate"
                              type="link"
                              [disabled]="formDef.form.invalid">
  </owc-progress-action-button>
</div>
