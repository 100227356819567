import {Injectable, OnDestroy} from '@angular/core';
import {map} from 'rxjs/operators';
import {NeverError} from 'orderly-web-components';
import {Observable, ReplaySubject} from 'rxjs';
import ExistingTableWithAreaAndDisplays = Orderly.RestaurantWeb.Api.Messages.ExistingTableWithAreaAndDisplays;
import {RestaurantTablesServiceDefinition} from '../../../../services/RestaurantTablesServiceDefinition';
import {
  RestaurantSpecificArrayResultLoadingState,
  RestaurantSpecificResultLoadingStatusDefinition
} from '../../../../services/active-route-bound/helper.statuses';
import {TablesListGridItem} from '../components/tables-list/helper.types';
import {CurrentRestaurantTablesService} from '../../../../services/active-route-bound/current-restaurant-tables.service';

@Injectable({
              providedIn: 'root'
            })
export class GridItemsSourceService implements OnDestroy, RestaurantTablesServiceDefinition {

  private readonly cache$ = new ReplaySubject<RestaurantSpecificArrayResultLoadingState<TablesListGridItem>>(1);

  constructor(private readonly restaurantTableService: CurrentRestaurantTablesService) {
    restaurantTableService.currentRestaurantTables$
                          .pipe(
                            map((x: RestaurantSpecificArrayResultLoadingState<ExistingTableWithAreaAndDisplays>) => {
                              switch (x.loadingStatus) {
                                case RestaurantSpecificResultLoadingStatusDefinition.SUCCESS:
                                  const gridItems = x.items.map(table => new TablesListGridItem(table));
                                  return new RestaurantSpecificArrayResultLoadingState(x.loadingStatus,
                                                                                       gridItems,
                                                                                       x.error);

                                case RestaurantSpecificResultLoadingStatusDefinition.FAILED:
                                case RestaurantSpecificResultLoadingStatusDefinition.CLEARED_BECAUSE_NO_RESTAURANT_ID:
                                case RestaurantSpecificResultLoadingStatusDefinition.LOADING:
                                case RestaurantSpecificResultLoadingStatusDefinition.NOT_STARTED:
                                  return new RestaurantSpecificArrayResultLoadingState(x.loadingStatus,
                                                                                       [],
                                                                                       x.error);

                                default:
                                  throw new NeverError(x.loadingStatus);
                              }
                            })
                          )
                          .subscribe(this.cache$);
  }

  public get items$(): Observable<RestaurantSpecificArrayResultLoadingState<TablesListGridItem>> {
    return this.cache$;
  }

  public forceReload(): Observable<any> {
    return this.restaurantTableService.forceReload();
  }

  public add(data: Orderly.RestaurantWeb.Api.Messages.Table.AddTableRequest): Observable<Orderly.RestaurantWeb.Api.Messages.Table.AddTableResponse> {
    return this.restaurantTableService.add(data);
  }

  public delete(tableId: number): Observable<Orderly.RestaurantWeb.Api.Messages.Table.DeleteTableResponse> {
    return this.restaurantTableService.delete(tableId);
  }

  public generateNewQrCode(tableId: number): Observable<Orderly.RestaurantWeb.Api.Messages.Table.GetQrCodeResponse> {
    return this.restaurantTableService.generateNewQrCode(tableId);
  }

  public getTableQrCodeAsBase64Image(tableId: number): Observable<Orderly.RestaurantWeb.Api.Messages.Table.GetQrCodeResponse> {
    return this.restaurantTableService.getTableQrCodeAsBase64Image(tableId);
  }

  public downloadTableQrCodeAsPdf(tableId: number): Observable<Blob> {
    return this.restaurantTableService.downloadTableQrCodeAsPdf(tableId);
  }

  public downloadTableQrCodeAsImage(tableId: number): Observable<Blob> {
    return this.restaurantTableService.downloadTableQrCodeAsImage(tableId);
  }

  public downloadTableQrCodesAsStickersImage(tableIdList: number[]): Observable<Blob> {
    return this.restaurantTableService.downloadTableQrCodesAsStickersImage(tableIdList);
  }

  ngOnDestroy(): void {
    this.cache$.complete();
  }
}
