import {Component} from '@angular/core';
import {RestaurantComponent} from '../../restaurant.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {ActivatedRoute} from '@angular/router';

@Component({
             selector: 'app-terminals-and-displays-list',
             templateUrl: './terminals-and-displays-list.component.html',
             styleUrls: ['./terminals-and-displays-list.component.scss']
           })
export class TerminalsAndDisplaysListComponent extends RestaurantComponent {

  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute) {

    super(store, activatedRoute);
  }

}
