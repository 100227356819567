import { environment } from '../../environments/environment';
import { filter, switchMap } from 'rxjs/operators';
import { selectRestaurantIdParamFromAppState } from '../../app/store/selectors/restaurant-selectors';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
export class CurrentRestaurantReviewsService {
    constructor(httpClient, store) {
        this.httpClient = httpClient;
        this.store = store;
        this.baseURL = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
        this.restaurantIdRouteParam$ = this.store.pipe(selectRestaurantIdParamFromAppState);
    }
    search(request) {
        return this.restaurantIdRouteParam$
            .pipe(filter((restaurantId) => restaurantId != null), switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/reviews/search`;
            return this.httpClient.post(url, request);
        }));
    }
}
CurrentRestaurantReviewsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentRestaurantReviewsService_Factory() { return new CurrentRestaurantReviewsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: CurrentRestaurantReviewsService, providedIn: "root" });
