import { Component } from '@angular/core';
import {CurrentRestaurantAreasService} from '../../../services/active-route-bound/current-restaurant-areas.service';
import {catchError, first, switchMap, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {ActivatedRoute} from '@angular/router';
import {
  NeverError, PaginationFiltersStateDefinition,
  ToastService
} from 'orderly-web-components';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AddOrEditAreaModalComponent} from '../add-or-edit-area-modal/add-or-edit-area-modal.component';
import {genericErrorHandlerWithToast} from '../../../util/utils';
import {TranslateService} from '@ngx-translate/core';
import DeleteRestaurantAreaResponse = Orderly.RestaurantWeb.Api.Messages.Area.DeleteRestaurantAreaResponse;
import {DeleteModalUtils} from '../../../util/delete-modal.utils';
import {RestaurantRelatedItemsGrid} from '../restaurant-related-items-grid.component';
import RestaurantArea = Orderly.RestaurantWeb.Api.Messages.RestaurantArea;

@Component({
  selector: 'app-areas-list',
  templateUrl: './areas-list.component.html',
  styleUrls: ['./areas-list.component.scss']
})
export class AreasListComponent extends RestaurantRelatedItemsGrid<RestaurantArea, PaginationFiltersStateDefinition> {

  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private modalService: NgbModal,
              private trnService: TranslateService,
              private toastService: ToastService,
              private deleteModalUtils: DeleteModalUtils,
              private restaurantAreasService: CurrentRestaurantAreasService) {

    super(store, activatedRoute, RestaurantRelatedItemsGrid.basicInitialFiltersState, restaurantAreasService.currentRestaurantAreas$);
  }

  public refreshAreasList() {
    this.restaurantAreasService
        .forceReload()
        .pipe(
          switchMap(x => this.filtersState$),
          tap(filterState => {
            const currentFiltersStateClone = {...filterState};

            currentFiltersStateClone.currentPage = 1;

            this.pagingFiltersChanged(currentFiltersStateClone);
          }),
          first()
        )
        .subscribe();
  }

  public addArea() {
    const modalRef = this.modalService.open(AddOrEditAreaModalComponent, {centered: true, size: 'lg'});

    modalRef.result
            .then(
              (value: Orderly.RestaurantWeb.Api.Messages.Area.AddOrUpdateRestaurantAreaResponse | null) => {
                if (value != null && value.status === Orderly.RestaurantWeb.Api.Messages.Area.AddOrUpdateRestaurantAreaResponse.StatusDef.Success) {
                  this.restaurantAreasService.forceReload();
                }
              });
  }

  public updateArea(area: RestaurantArea) {
    const modalRef = this.modalService.open(AddOrEditAreaModalComponent, {centered: true, size: 'lg'});
    const component: AddOrEditAreaModalComponent = modalRef.componentInstance;

    component.existingArea = area;

    modalRef.result
            .then(
              (value: Orderly.RestaurantWeb.Api.Messages.Area.AddOrUpdateRestaurantAreaResponse | null) => {
                if (value != null && value.status === Orderly.RestaurantWeb.Api.Messages.Area.AddOrUpdateRestaurantAreaResponse.StatusDef.Success) {
                  area.name = value.area.name;
                  area.isOutdoor = value.area.isOutdoor;
                  area.isSmokingArea = value.area.isSmokingArea;
                  area.isActive = value.area.isActive;
                }
              });
  }

  public deleteArea(area: RestaurantArea) {
    const onDelete = (modalRef: NgbModalRef) => {

      const unexpectedErrorText = this.trnService.instant('Failed to delete an area because of unexpected error.');

      this.restaurantAreasService
          .delete(area.id)
          .pipe(
            tap(x => {
              switch (x.status) {
                case DeleteRestaurantAreaResponse.StatusDef.Success:
                  this.restaurantAreasService.forceReload();
                  modalRef.close();
                  break;
                case DeleteRestaurantAreaResponse.StatusDef.UnknownFailure:
                  this.toastService.showError(unexpectedErrorText);
                  break;
                case DeleteRestaurantAreaResponse.StatusDef.FailedBecauseDependenciesExist:
                  const dependenciesErrorText = this.trnService.instant(
                    'Failed to delete an area because of [{{tablesCount}}] dependent tables.',
                    {
                      tablesCount: x.dependencies.tablesCount
                    });

                  this.toastService.showError(dependenciesErrorText);
                  break;
                default:
                  throw new NeverError(x.status);
              }
            }),
            catchError(
              genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedErrorText)
            )
          ).subscribe();
    };

    const modalTitle = this.trnService.instant(`Delete area`);
    const modalConfirmText = this.trnService.instant(`Do you want to delete an area '{{name}}'?`, {name: area.name});

    this.deleteModalUtils.showDeleteModal(area, modalTitle, [modalConfirmText], onDelete);
  }
}
