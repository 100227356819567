import {Component, Input} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator
} from '@angular/forms';
import {
  FormArrayDefinition,
  FormDefinition,
  FormFieldsDefinition
} from '../../../../../util/form.utils';
import {
  TextTranslationWithMasterFlagDefinition,
  TranslationsInputFormFieldsDefinition
} from './translations-input-form-fields.definition';
import {TextTranslationDefinition} from '../text-input-with-language-flag/text-translation.definition';
import {filter, tap} from 'rxjs/operators';
import {BaseOrderlyComponent} from '../base-orderly.component';
import {Language} from 'orderly-web-components';

@Component({
             selector: 'app-shared-translations-input',
             templateUrl: './translations-input.component.html',
             styleUrls: ['./translations-input.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: TranslationsInputComponent,
                 multi: true,
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: TranslationsInputComponent,
                 multi: true
               },
             ],
           })
export class TranslationsInputComponent extends BaseOrderlyComponent implements ControlValueAccessor, Validator {

  private initInProgress: boolean = false;

  @Input()
  public languages: Language[];

  @Input()
  public textinputType: 'input' | 'textarea' = 'input';

  @Input()
  public maxlength: number | null = null;

  @Input()
  public primaryTextLanguage: Language | null;

  @Input()
  public primaryTextVisible: boolean = true;

  public formDef: FormDefinition<keyof TranslationsInputFormFieldsDefinition>;

  public isDisabled: boolean = false;


  constructor(public readonly formBuilder: FormBuilder) {

    super();

    const fieldsDef: FormFieldsDefinition<keyof TranslationsInputFormFieldsDefinition> = {
      translations: new FormArrayDefinition([], []),
    };

    this.formDef = new FormDefinition<keyof TranslationsInputFormFieldsDefinition>(fieldsDef, formBuilder);

    this.registerForAutoDestroy(
      this.formDef
          .form
          .valueChanges
          .pipe(
            filter(() => !this.initInProgress),
            tap((x: TranslationsInputFormFieldsDefinition) => {
              this.onChange(x.translations);
            }),
          )
          .subscribe()
    );
  }


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: TextTranslationDefinition[]) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: TextTranslationDefinition[] | null): void {
    this.initInProgress = true;

    const allTranslations = value || [];

    const translationLanguages = allTranslations.map(x => x.langCode2.toLowerCase());
    const enabledLanguages = this.languages.map(x => x.code.toLowerCase());

    const languagesToAdd = enabledLanguages.filter(code2 => translationLanguages.indexOf(code2) === -1);
    const translations = allTranslations.filter(trn => enabledLanguages.indexOf(trn.langCode2.toLowerCase()) >= 0);

    for (let j = 0; j < languagesToAdd.length; j++) {
      translations.push({text: '', langCode2: languagesToAdd[j]});
    }

    this.patchItemsField(translations);

    this.initInProgress = false;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;

    if (isDisabled) {
      this.formDef.disable('all-fields');
    } else {
      this.formDef.enable('all-fields', false);
    }
  }

  private patchItemsField(newValue: TextTranslationDefinition[]) {
    const newTranslations: TextTranslationWithMasterFlagDefinition[] = newValue.map(x => {
      const isMaster = this.primaryTextLanguage == null ? false : this.primaryTextLanguage.code.toLowerCase() === x.langCode2.toLowerCase();
      const result: TextTranslationWithMasterFlagDefinition = {
        text: x.text,
        langCode2: x.langCode2,
        isMaster,
      };

      return result;
    });

    const isMasterEmpty = newTranslations.map((x) => x.isMaster && x.text === '').reduce((prev: boolean, current: boolean) => prev || current, false);

    let sortedArr = newTranslations.sort((a, b) => {
      if (a.isMaster) {
        return -1;
      }

      if (b.isMaster) {
        return 1;
      }

      return a.langCode2.localeCompare(b.langCode2);
    });

    if (!this.primaryTextVisible) {
      sortedArr = sortedArr.filter(x => !x.isMaster);
    }

    const itemsArrField = this.formDef.form.get('translations') as any;

    // TODO: replace code below with:
    // arrayFormField.controls.clear({ emitEvent });

    // eslint-disable-next-line no-underscore-dangle
    itemsArrField._forEachChild((control: any) => control._registerOnCollectionChange(() => {}));
    itemsArrField.controls.splice(0);
    itemsArrField.updateValueAndValidity({ emitEvent: false });

    for (let j = 0; j < sortedArr.length; j++) {
      const disabled = isMasterEmpty || this.isDisabled || sortedArr[j].isMaster;
      const newControl = this.formBuilder.control({ value: sortedArr[j], disabled });

      // TODO: code below can be soon replaced with 'arrayFormField.push(newControl, { emitEvent });'

      itemsArrField.controls.push(newControl);
      // eslint-disable-next-line no-underscore-dangle
      itemsArrField._registerControl(newControl);
    }

    itemsArrField.updateValueAndValidity({ emitEvent: false });
    // eslint-disable-next-line no-underscore-dangle
    itemsArrField._onCollectionChange();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.invalid || this.formDef.form.invalid) {
      return {'translations-input': true};
    }

    return null;
  }
}
