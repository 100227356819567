import {Component} from '@angular/core';
import {throwError} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {catchError, first, flatMap, tap} from 'rxjs/operators';
import {AddUserModalComponent} from './add-user-modal/add-user-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WaiterDeletionSuccessfulAction} from '../../store/actions/waiter.actions';
import {
  NeverError,
  PaginationFiltersStateDefinition,
  ToastService
} from 'orderly-web-components';

import {DeleteModalUtils} from '../../../util/delete-modal.utils';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CurrentRestaurantUsersService} from '../../../services/active-route-bound/current-restaurant-users.service';
import {RestaurantRelatedItemsGrid} from '../restaurant-related-items-grid.component';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import RestaurantUser = Orderly.RestaurantWeb.Api.Messages.Restaurant.GetRestaurantUsersResponse.RestaurantUser;
import DeleteUserResponse = Orderly.RestaurantWeb.Api.Messages.Waiter.DeleteUserResponse;

@Component({
             selector: 'app-restaurant-users-list',
             templateUrl: './restaurant-users-list.component.html',
             styleUrls: ['./restaurant-users-list.component.scss']
           })
export class RestaurantUsersListComponent extends RestaurantRelatedItemsGrid<RestaurantUser, PaginationFiltersStateDefinition> {
  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private trnService: TranslateService,
              private modalService: NgbModal,
              private toastService: ToastService,
              private deleteModalUtils: DeleteModalUtils,
              private restaurantUsersService: CurrentRestaurantUsersService) {

    super(store, activatedRoute, RestaurantRelatedItemsGrid.basicInitialFiltersState,
          restaurantUsersService.currentRestaurantUsers$);
  }

  public addUser() {
    this.modalService.open(AddUserModalComponent, {centered: true, size: 'md'});
  }

  public resendWaiterInvitation(user: RestaurantUser) {
  }

  public deleteWaiter(user: RestaurantUser) {

    const unexpectedErrorMsg = _('Failed to remove a user because of unexpected error. Please try again later.');

    const onDelete = (modalRef) => {
      this.restaurantIdParam$
          .pipe(
            first(),
            flatMap((restaurantId: number) => {
              return this.restaurantUsersService.delete(user.id);
            }),
            tap((response: DeleteUserResponse) => {
              switch (response.status) {
                case DeleteUserResponse.StatusDef.Success:
                  this.store.dispatch(new WaiterDeletionSuccessfulAction(response));

                  modalRef.close(false);
                  break;
                case DeleteUserResponse.StatusDef.Failed:
                  this.toastService.showError(unexpectedErrorMsg);
                  break;
                case DeleteUserResponse.StatusDef.SelfRemovalForbidden:
                  const selfRemovalErrorMsg = _('You cannot remove yourself.');
                  this.toastService.showError(selfRemovalErrorMsg);
                  break;
                default:
                  throw new NeverError(response.status);
              }
            }),
            catchError(err => {
              this.toastService.showError(unexpectedErrorMsg);

              return throwError(err);
            })
          )
          .subscribe();
    };

    const modalTitle = this.trnService.instant('Delete user');
    const confirmationText = this.trnService.instant('Are you sure you want to remove a user \'{{fullName}}\' from your restaurant?',
                                                     {fullName: `${user.firstName} ${user.lastName}`});

    this.deleteModalUtils.showDeleteModal(user, modalTitle, [confirmationText], onDelete);
  }

  public refreshUsersList() {
    this.restaurantUsersService
        .forceReload()
        .pipe(
          first()
        )
        .subscribe();
  }
}
