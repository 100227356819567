import moment, {Moment} from 'moment';
import ExistingTableWithAreaAndDisplays = Orderly.RestaurantWeb.Api.Messages.ExistingTableWithAreaAndDisplays;

export class TablesListGridItem {
  public get area(): Orderly.RestaurantWeb.Api.Messages.RestaurantArea {
    return this.source.area;
  }

  public get clientTableName(): string {
    return this.source.name;
  }

  public readonly createdOn: Moment;

  public get id(): number {
    return this.source.id;
  }

  public get linkedDisplays(): Orderly.RestaurantWeb.Api.Messages.Display.GetDisplaysResponse.Display[] {
    return this.source.linkedDisplays;
  }

  public isSelected: boolean = false;

  constructor(public readonly source: ExistingTableWithAreaAndDisplays) {
    this.createdOn = moment.utc(source.createdOn);
  }
}
