import {Component, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject} from 'rxjs';
import {GetAllStaticDataResponse, Language, StaticDataService} from 'orderly-web-components';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';
import {TranslateService} from '@ngx-translate/core';
import {CurrentUserSelectedLanguageChangedAction} from '../store/actions/user.actions';

@Component({
  selector: 'app-top-bar-with-language-switcher',
  templateUrl: './top-bar-with-language-switcher.component.html',
  styleUrls: ['./top-bar-with-language-switcher.component.scss']
})
export class TopBarWithLanguageSwitcherComponent implements OnDestroy {

  public currentLanguageCode2$: BehaviorSubject<string>;
  public languages$: ReplaySubject<Language[]> = new ReplaySubject<Language[]>(1);

  public activeLanguage$: Observable<Language | null>;

  constructor(private store: Store<AppState>,
              private staticDataService: StaticDataService,
              trnService: TranslateService) {

    this.staticDataService
        .getAllStaticData(environment.baseApiUrlWithTrailingSlash)
        .pipe(
          map((x: GetAllStaticDataResponse) => x.adminSupportedLanguages.map(l => {
            const result: Language = {
              code: l.code.toLowerCase(),
              id: l.id,
              name: l.name
            };

            return result;
          }))
        )
        .subscribe(this.languages$);

    this.currentLanguageCode2$ = new BehaviorSubject<string>((trnService.currentLang || 'en').toLowerCase());
    this.activeLanguage$ = combineLatest([this.currentLanguageCode2$, this.languages$])
      .pipe(
        map((x: [string, Language[]]) => {
          const currentLangCode2 = x[0];
          const availableLanguages = x[1];
          const currentLangArr = availableLanguages.filter(l => l.code === currentLangCode2);

          if (currentLangArr.length === 0) {
            return null;
          }

          return currentLangArr[0];
        })
      );


    trnService.onLangChange
              .pipe(
                map(x => x.lang)
              )
              .subscribe(this.currentLanguageCode2$);
  }

  ngOnDestroy(): void {
    this.currentLanguageCode2$.complete();
    this.languages$.complete();
  }

  onChangeLanguage(lang: Language) {
    this.store.dispatch(new CurrentUserSelectedLanguageChangedAction(lang));
  }
}
