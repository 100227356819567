<app-restaurant-editor [formControl]="formDef.getControl('restaurant')"
                       class="restaurant-editor">
</app-restaurant-editor>

<div class="row">
  <div class="col-12">
    <div style="float: right;">
      <a (click)="discard()" class="btn btn-danger text-white mg-r-10">{{'Discard' | translate}}</a>

      <a (click)="save()" class="btn btn-success text-white" [class.disabled]="formDef.form.invalid">{{'Save' | translate}}</a>
    </div>
  </div>
</div>
