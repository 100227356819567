/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./authenticated-user.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../dist/orderly-web-components/orderly-web-components.ngfactory";
import * as i3 from "orderly-web-components";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./authenticated-user.component";
import * as i7 from "../../services/user.service";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "@ngrx/store";
import * as i10 from "@ngx-translate/core";
var styles_AuthenticatedUserComponent = [i0.styles];
var RenderType_AuthenticatedUserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthenticatedUserComponent, data: {} });
export { RenderType_AuthenticatedUserComponent as RenderType_AuthenticatedUserComponent };
export function View_AuthenticatedUserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "owc-left-menu", [], null, [[null, "menuItemSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("menuItemSelected" === en)) {
        var pd_0 = (_co.menuItemSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LeftMenuComponent_0, i2.RenderType_LeftMenuComponent)), i1.ɵdid(1, 49152, null, 0, i3.LeftMenuComponent, [], { items: [0, "items"] }, { menuItemSelected: "menuItemSelected" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "owc-top-navigation-bar", [], null, [[null, "logout"], [null, "updateProfile"], [null, "changePassword"], [null, "changeLanguage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("logout" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } if (("updateProfile" === en)) {
        var pd_1 = (_co.onUpdateUserProfile() !== false);
        ad = (pd_1 && ad);
    } if (("changePassword" === en)) {
        var pd_2 = (_co.onChangeUserPassword() !== false);
        ad = (pd_2 && ad);
    } if (("changeLanguage" === en)) {
        var pd_3 = (_co.onChangeLanguage($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_TopNavigationBarComponent_0, i2.RenderType_TopNavigationBarComponent)), i1.ɵdid(4, 114688, null, 0, i3.TopNavigationBarComponent, [], { user: [0, "user"], cfg$: [1, "cfg$"] }, { logout: "logout", updateProfile: "updateProfile", changePassword: "changePassword", changeLanguage: "changeLanguage" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "br-mainpanel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "br-pagebody"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.leftMenuItems$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.user)); var currVal_2 = _co.topNavigationBarCfg$; _ck(_v, 4, 0, currVal_1, currVal_2); _ck(_v, 10, 0); }, null); }
export function View_AuthenticatedUserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-authenticated-user", [], null, null, null, View_AuthenticatedUserComponent_0, RenderType_AuthenticatedUserComponent)), i1.ɵdid(1, 114688, null, 0, i6.AuthenticatedUserComponent, [i7.UserService, i8.NgbModal, i9.Store, i3.ToastService, i3.StaticDataService, i10.TranslateService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthenticatedUserComponentNgFactory = i1.ɵccf("app-authenticated-user", i6.AuthenticatedUserComponent, View_AuthenticatedUserComponent_Host_0, {}, {}, []);
export { AuthenticatedUserComponentNgFactory as AuthenticatedUserComponentNgFactory };
