import {Component, OnInit} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  of, throwError,
} from 'rxjs';
import {Store} from '@ngrx/store';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {
  catchError,
  delay,
  filter,
  finalize,
  first,
  map, mergeMap,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder} from '@angular/forms';
import {
  createExistingMenuItemFormFieldsDef,
  createForm,
  createNewMenuItemFormFieldsDef,
  MenuItemFormFieldsDef
} from './form-fields-definitions';
import {DeleteModalUtils} from '../../../../../util/delete-modal.utils';
import {RestaurantComponent} from '../../../../restaurant.component';
import {AppState} from '../../../../store/app.state';
import {RestaurantManagerRoutingConstants} from '../../../../routing-constants';
import {FormDefinition} from '../../../../../util/form.utils';
import {CurrentRestaurantMenuItemsService} from '../../../../../services/active-route-bound/current-restaurant-menu-items.service';
import {NeverError, ProgressDialogService, ToastService} from 'orderly-web-components';
import MenuItemWithAllData = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData;
import GetMenuItemResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.GetMenuItemResponse;
import StatusDef = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.GetMenuItemResponse.StatusDef;
import {TabSettingsDefinition} from './tab-settings.definition';
import RestaurantInfoWithSettingsAndContactDetails = Orderly.RestaurantWeb.Api.Messages.RestaurantInfoWithSettingsAndContactDetails;
import AddOrUpdateMenuItemResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemResponse;
import ImageWithBase64Def = Orderly.Shared.Api.Messages.ImageWithBase64Def;
import AddMenuItemRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddMenuItemRequest;
import MenuItemModifierGroupWithModifiers = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupWithModifiers;
import {genericErrorHandlerWithToast} from '../../../../../util/utils';
import UpdateMenuItemRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.UpdateMenuItemRequest;
import MenuItemImageWithThumbsDef = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemImageWithThumbsDef;
import {MenuItemTranslationsFormFieldsDefinition} from './translations-tab/menu-item-translations-form-fields.definition';
import {DetailsTabFormFieldsDefinition} from './details-tab/details-tab.form-fields-definition';
import {createTranslations} from './translations.helper';


@Component({
             selector: 'app-add-or-edit-menu-item',
             templateUrl: './add-or-edit-menu-item.component.html',
             styleUrls: ['./add-or-edit-menu-item.component.scss'],
           })
export class AddOrEditMenuItemComponent extends RestaurantComponent implements OnInit {

  public activeTab: 'details' | 'modifiers' | 'ingredients' | 'translations' = 'details';

  public indicateNameOrDescriptionChange: boolean = false;

  public actionInProgress: boolean = false;

  public formDef: FormDefinition<keyof MenuItemFormFieldsDef> = createForm(this.formBuilder);

  public tabSettings$: Observable<TabSettingsDefinition>;

  private readonly existingMenuItemId$: BehaviorSubject<number | null> = new BehaviorSubject(null);

  private existingMenuItem$: Observable<MenuItemWithAllData | null>;

  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private readonly menuItemsService: CurrentRestaurantMenuItemsService,
              private readonly toast: ToastService,
              private readonly router: Router,
              private readonly trnService: TranslateService,
              private readonly formBuilder: FormBuilder,
              private readonly deleteModalUtils: DeleteModalUtils,
              private readonly progressDialog: ProgressDialogService) {

    super(store, activatedRoute);

    this.registerForAutoDestroy(this.existingMenuItemId$);

    this.registerForAutoDestroy(
      this.activatedRoute
          .paramMap
          .pipe(
            map((params: ParamMap) => {
              return params.get('menuItemId');
            }),
            filter((menuItemId: string | null) => typeof menuItemId === 'string'),
            map((menuItemId: string) => {
              return parseInt(menuItemId, 10);
            }),
          ).subscribe(this.existingMenuItemId$)
    );

    const menuItem$: Observable<MenuItemWithAllData> = this.existingMenuItemId$
                                                           .pipe(
                                                             filter((menuItemId: number | null) => menuItemId != null),
                                                             switchMap((menuItemId: number) => {
                                                               return this.getMenuItemById(menuItemId);
                                                             }),
                                                             tap((response: GetMenuItemResponse) => {
                                                               switch (response.status) {
                                                                 case StatusDef.Success:
                                                                   break;
                                                                 case StatusDef.UnknownFailure:
                                                                   this.toast.showError(
                                                                     trnService.instant('Failed to load the data'));
                                                                   break;
                                                                 default:
                                                                   throw new NeverError(response.status);
                                                               }
                                                             }),
                                                             filter((response: GetMenuItemResponse) => response.status === StatusDef.Success),
                                                             map((response: GetMenuItemResponse) => response.item),
                                                           );

    this.existingMenuItem$ = menuItem$.pipe(startWith(null), shareReplay({bufferSize: 1, refCount: true}));

    this.tabSettings$ = this.currentRestaurant$
      .pipe(
        map((x: RestaurantInfoWithSettingsAndContactDetails) => {
          return {
            primaryLanguage: x.settings.defaultMenuLanguage,
            menuTranslationLanguages: x.settings.enabledMenuLanguages,
            currency: x.settings.defaultCurrency,
          };
        })
      );

    this.registerForAutoDestroy(
      this.formDef
          .getControl('details')
          .valueChanges
          .pipe(
            tap((detailsValue: DetailsTabFormFieldsDefinition) => {

              const formValue: MenuItemFormFieldsDef = this.formDef.form.getRawValue();

              const nameTranslations = [...formValue.translations.name];
              const descriptionTranslations = [...formValue.translations.description];

              const defaultNameLangTrnIdx = nameTranslations.findIndex((x) => x.langCode2.toLowerCase() === detailsValue.name.langCode2.toLowerCase());
              const defaultDescriptionLangTrnIdx = descriptionTranslations.findIndex((x) => x.langCode2.toLowerCase() === detailsValue.name.langCode2.toLowerCase());

              if (defaultNameLangTrnIdx > -1) {
                nameTranslations[defaultNameLangTrnIdx] = detailsValue.name;
              } else {
                nameTranslations.push(detailsValue.name);
              }

              if (defaultDescriptionLangTrnIdx > -1) {
                descriptionTranslations[defaultDescriptionLangTrnIdx] = detailsValue.description;
              } else {
                descriptionTranslations.push(detailsValue.description);
              }

              const translations: MenuItemTranslationsFormFieldsDefinition = {
                name: nameTranslations,
                description: descriptionTranslations,
              };
              this.formDef.patchValue({translations}, false);
            }),
          )
          .subscribe()
    );
  }

  private getMenuItemById(id: number): Observable<GetMenuItemResponse> {

    const blockingModalToken = this.progressDialog.display();

    return this.menuItemsService
               .get(id)
               .pipe(
                 tap(() => {
                   this.progressDialog.hide(blockingModalToken);
                 }),
                 catchError((err) => {
                   this.progressDialog.hide(blockingModalToken);

                   return throwError(err);
                 })
               );
  }

  public ngOnInit(): void {

    this.registerForAutoDestroy(
      combineLatest([this.existingMenuItem$, this.tabSettings$])
        .pipe(
          tap((x: [MenuItemWithAllData | null, TabSettingsDefinition]) => {
            const menuItem: MenuItemWithAllData | null = x[0];
            const tabSettings: TabSettingsDefinition = x[1];

            if (menuItem == null) {
              const newMenuItemData = createNewMenuItemFormFieldsDef(tabSettings);

              this.formDef.patchValue(newMenuItemData, false);
            } else {
              const existingMenuItemData = createExistingMenuItemFormFieldsDef(tabSettings, menuItem);

              this.formDef.patchValue(existingMenuItemData, false);
            }
          }),
        )
        .subscribe()
    );
  }

  public save(navigateToList: boolean) {
    if (this.formDef.form.invalid) {
      return;
    }

    const blockingModalToken = this.progressDialog.display();

    const unexpectedErrorMsg = this.trnService.instant('Failed to save a menu item because of unexpected failure.');
    const action$: Observable<AddOrUpdateMenuItemResponse> = this.existingMenuItem$
                                                                 .pipe(
                                                                   first(),
                                                                   delay(200),
                                                                   switchMap(existingMenuItem => {
                                                                     if (existingMenuItem == null) {
                                                                       return this.addMenuItem();
                                                                     }

                                                                     return this.updateMenuItem(existingMenuItem);
                                                                   })
                                                                 );

    action$.pipe(
      tap((response: AddOrUpdateMenuItemResponse) => {

        this.progressDialog.hide(blockingModalToken);

        switch (response.status) {
          case AddOrUpdateMenuItemResponse.StatusDef.Success:
            // this.setMenuItemFormValues(response.menuItem);

            this.toast.showSuccess(this.trnService.instant('Menu item was saved'));
            break;
          case AddOrUpdateMenuItemResponse.StatusDef.ValidationFailed:
            this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
            break;
          case AddOrUpdateMenuItemResponse.StatusDef.UnexpectedError:
            this.toast.showError(unexpectedErrorMsg);
            break;
          default:
            throw new NeverError(response.status);
        }
      }),
      switchMap((response: AddOrUpdateMenuItemResponse) => {
        if (response.status === AddOrUpdateMenuItemResponse.StatusDef.Success) {

          if (navigateToList) {
            return this.restaurantIdParam$
                       .pipe(
                         switchMap(rid => {
                           const listUrl = RestaurantManagerRoutingConstants.getRestaurantMenuItemsListUrl(rid);

                           return from(this.router.navigateByUrl(listUrl));
                         }));
          }

          if (this.existingMenuItemId$.value == null) {
            return this.restaurantIdParam$
                       .pipe(
                         switchMap(rid => {
                           const listUrl = RestaurantManagerRoutingConstants.getRestaurantMenuItemEditUrl(rid, response.menuItem.id);

                           return from(this.router.navigateByUrl(listUrl));
                         }));
          }
        }

        return of(response);
      }),
      catchError(
        genericErrorHandlerWithToast(this.toast, this.trnService, unexpectedErrorMsg)
      ),
      finalize(() => {
        this.actionInProgress = false;
      })
           )
           .subscribe();
  }


  private addMenuItem(): Observable<AddOrUpdateMenuItemResponse> {
    const form: MenuItemFormFieldsDef = this.formDef.form.getRawValue();

    const categories = form.details.category instanceof Array ? form.details.category : [form.details.category];
    const ingredients = form.ingredients.map(x => {
      const result: Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.NewMenuItemIngredient = {
        ingredientId: x.ingredient.id,
        amount: x.amount
      };

      return result;
    });

    const sizes = form.details.price.hasMultipleSizes
                  ? form.details.price.sizes.map((x, idx) => {
        const result: Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.ExistingMenuItemSize = {
          amount: x.amount,
          id: x.id,
          name: x.name,
          price: x.price,
          sortingOrder: idx,
        };

        return result;
      })
                  : [];
    const measureUnitId = form.details.price.measureUnit == null
                          ? null
                          : form.details.price.measureUnit!.id;

    const newImages: ImageWithBase64Def[] = form.details.images.map(x => x as ImageWithBase64Def);
    const modifierGroups = this.convertAddModifierGroups(form.modifierGroups);

    return this.tabSettings$
      .pipe(
        map((settings) => {

          const translations = createTranslations(settings, form.translations);

          const request: AddMenuItemRequest = {
            categories: categories,
            description: form.details.description.text,
            ingredients: ingredients,
            name: form.details.name.text,
            price: form.details.price.price,
            modifierGroups: modifierGroups,
            isActive: form.details.isActive,
            amount: form.details.price.amount,
            measureUnitId: measureUnitId,
            sizes: sizes,
            images: newImages,
            translations,
          };

          return request;
        }),
        first(),
        mergeMap((request) => {
          return this.menuItemsService.add(request);
        })
      );
  }

  private updateMenuItem(menuItem: MenuItemWithAllData): Observable<AddOrUpdateMenuItemResponse> {
    const form: MenuItemFormFieldsDef = this.formDef.form.getRawValue();

    const categories = form.details.category instanceof Array ? form.details.category : [form.details.category];

    const ingredients = form.ingredients.map(x => {
      const result: Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.UpdateMenuItemRequest.NewOrExistingMenuItemIngredient = {
        ingredientId: x.ingredient.id,
        amount: x.amount,
        id: x.menuItemIngredientId
      };

      return result;
    });

    const sizes = form.details.price.hasMultipleSizes
                  ? form.details.price.sizes.map((x, idx) => {
        const result: Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.ExistingMenuItemSize = {
          amount: x.amount,
          id: x.id,
          name: x.name,
          price: x.price,
          sortingOrder: idx,
        };

        return result;
      })
                  : [];

    const modifierGroups = this.convertUpdateModifierGroups(form.modifierGroups);

    // tslint:disable-next-line:no-string-literal
    const remainingExistingImageIds: string[] = form.details.images.filter(x => x['thumbs'] != null)
                                                    .map(x => x as MenuItemImageWithThumbsDef).map(
        x => x.originalImage.id);
    // tslint:disable-next-line:no-string-literal
    const newImages: ImageWithBase64Def[] = form.details.images.filter(x => x['contentBase64'] != null).map(
      x => x as ImageWithBase64Def);
    const deletedImageIds: string[] = [];
    const existingImageIds = menuItem.images.map(x => x.originalImage.id);

    for (const imgId of existingImageIds) {
      if (remainingExistingImageIds.indexOf(imgId) < 0) {
        deletedImageIds.push(imgId);
      }
    }

    const measureUnitId = form.details.price.measureUnit == null ? null : form.details.price.measureUnit!.id;

    return this.tabSettings$
               .pipe(
                 map((settings) => {

                   const translations = createTranslations(settings, form.translations);

                   const request: UpdateMenuItemRequest = {
                     description: form.details.description.text,
                     ingredients: ingredients,
                     name: form.details.name.text,
                     price: form.details.price.price,
                     modifierGroups: modifierGroups,
                     isActive: form.details.isActive,
                     measureUnitId: measureUnitId,
                     amount: form.details.price.amount,
                     sizes: sizes,
                     categories: categories,
                     newImages: newImages,
                     deletedImageIds: deletedImageIds,
                     translations,
                   };

                   return request;
                 }),
                 first(),
                 mergeMap((request) => {
                   return this.menuItemsService.update(menuItem.id, request);
                 })
               );
  }

  public navigateToList(withDiscardConfirmation: boolean) {

    if (withDiscardConfirmation) {
      const title = this.trnService.instant('Discard all changes?');

      this.deleteModalUtils
          .showDeleteModal(null, title, [], modalRef => {
                             this.doNavigateToList();

                             modalRef.close(true);
                           },
                           _('Discard'));
    } else {
      this.doNavigateToList();
    }
  }

  private doNavigateToList() {
    this.actionInProgress = true;
    this.formDef.disable('all-fields');

    this.restaurantIdParam$
        .pipe(
          switchMap(rid => {
            const listUrl = RestaurantManagerRoutingConstants.getRestaurantMenuItemsListUrl(rid);

            return from(this.router.navigateByUrl(listUrl));
          })
        )
        .subscribe();
  }

  private convertAddModifierGroups(source: MenuItemModifierGroupWithModifiers[])
    : Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddMenuItemRequest.MenuItemModifierGroup[] {

    const modifierGroups: Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddMenuItemRequest.MenuItemModifierGroup[] = source.map(x => {
      const modifiers: Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddMenuItemRequest.MenuItemModifier[] = x.menuItemModifiers
                                                               .map(m => {
                                                                 const amount: number | null = (m.amount || 0) <= 0 ? null : m.amount;
                                                                 const ingredientId = m.ingredient == null ? null : m.ingredient.id;
                                                                 const measureUnitId = m.ingredient == null
                                                                                       ? m.measureUnit == null
                                                                                         ? null
                                                                                         : m.measureUnit.id
                                                                                       : null;

                                                                 return {
                                                                   id: m.id <= 0 ? null : m.id,
                                                                   amount,
                                                                   priceDiff: m.priceDiff,
                                                                   name: m.ingredient == null ? m.name : '',
                                                                   ingredientId,
                                                                   measureUnitId,
                                                                   overrideIngredientName: false,
                                                                   translations: m.translations
                                                                 };
                                                               });

      return {
        name: x.name,
        maxAllowedAmount: x.maxAllowedAmount,
        minAllowedAmount: x.minAllowedAmount,
        menuItemModifiers: modifiers,
        id: x.id,
        translations: x.translations,
      };
    });

    return modifierGroups;
  }

  private convertUpdateModifierGroups(source: MenuItemModifierGroupWithModifiers[]): Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.UpdateMenuItemRequest.MenuItemModifierGroup[] {

    const modifierGroups: Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.UpdateMenuItemRequest.MenuItemModifierGroup[] = source.map(x => {
      const modifiers: Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddMenuItemRequest.MenuItemModifier[] = x.menuItemModifiers
                                                               .map(m => {
                                                                 const amount: number | null = (m.amount || 0) <= 0 ? null : m.amount;
                                                                 const ingredientId = m.ingredient == null ? null : m.ingredient.id;
                                                                 const measureUnitId = m.ingredient == null
                                                                                       ? m.measureUnit == null
                                                                                         ? null
                                                                                         : m.measureUnit.id
                                                                                       : null;

                                                                 return {
                                                                   id: m.id <= 0 ? null : m.id,
                                                                   amount,
                                                                   priceDiff: m.priceDiff,
                                                                   name: m.ingredient == null ? m.name : '',
                                                                   ingredientId,
                                                                   measureUnitId,
                                                                   overrideIngredientName: false,
                                                                   translations: m.translations,
                                                                 };
                                                               });

      return {
        name: x.name,
        maxAllowedAmount: x.maxAllowedAmount,
        minAllowedAmount: x.minAllowedAmount,
        menuItemModifiers: modifiers,
        id: x.id,
        translations: x.translations,
      };
    });

    return modifierGroups;
  }


  public nameOrDescriptionChanged(): void {
    this.indicateNameOrDescriptionChange = true;
  }
}
