import { OnDestroy } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { BooleanValueItem } from '../../add-or-edit-menu-item-modifier-group/helper.types';
import { map, takeUntil } from 'rxjs/operators';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export class CommonTranslationsUtil {
    constructor(trnService) {
        this.trnService = trnService;
        this.destroy$ = new Subject();
        this.visibilityItems$ = new ReplaySubject(1);
        this.activeItems$ = new ReplaySubject(1);
        const visibleText = _('Visible');
        const invisibleText = _('Hidden');
        const activeText = _('Active');
        const deactivatedText = _('Not active');
        this.trnService
            .stream([visibleText, invisibleText])
            .pipe(takeUntil(this.destroy$), map((x) => {
            const visibleTrn = x[visibleText];
            const invisibleTrn = x[invisibleText];
            return [new BooleanValueItem(true, visibleTrn), new BooleanValueItem(false, invisibleTrn)];
        }))
            .subscribe(this.visibilityItems$);
        this.trnService
            .stream([activeText, deactivatedText])
            .pipe(takeUntil(this.destroy$), map((x) => {
            const activeTrn = x[activeText];
            const deactivatedTrn = x[deactivatedText];
            return [new BooleanValueItem(true, activeTrn), new BooleanValueItem(false, deactivatedTrn)];
        }))
            .subscribe(this.activeItems$);
    }
    ngOnDestroy() {
        this.destroy$.complete();
        this.activeItems$.complete();
        this.activeItems$.complete();
    }
}
CommonTranslationsUtil.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonTranslationsUtil_Factory() { return new CommonTranslationsUtil(i0.ɵɵinject(i1.TranslateService)); }, token: CommonTranslationsUtil, providedIn: "root" });
