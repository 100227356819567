<div class="areas-list-table div-based-table table-dark table-colored">
  <div class="thead" #thead>
    <div class="row">
      <div class="col-11 title">{{'Tables' | translate}}</div>
      <div class="col-1 actions">
        <a class="btn btn-sm btn-outline-secondary float-right" (click)="refreshTablesList()">
          <i class="icon-arrows-cw fa-15x" [class.animate-spin]="listIsLoading$ | async"></i>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="row">
          <div class="col-2 p-0 bd-b-0-force"></div>
          <div class="col-10 p-0 bd-b-0-force bd-l-0-force">{{'Name' | translate}}</div>
        </div>
      </div>
      <div class="col-2">{{'Area' | translate}}</div>
      <div class="col-4">{{'Assigned self-order kiosks' | translate}}</div>
      <div class="col-3"></div>
    </div>
  </div>
  <div class="tbody">
    <ng-container *ngIf="listIsLoaded$ | async">
      <div class="row" *ngFor="let table of displayedGridItems$ | async" style="word-wrap: break-word;">
        <div class="col-3">
          <div class="row">
            <div class="col-2 p-0">
              <input type="checkbox" [(ngModel)]="table.isSelected" />
            </div>
            <div class="col-10 p-0">
              <a (click)="updateTable(table)">{{ table.clientTableName }}</a> <br />
              <span style="font-size: 0.8em;">{{'Added on' | translate}}: {{ table.createdOn | localDate:'DD-MM-YYYY' }}</span>
            </div>
          </div>
        </div>
        <div class="col-2">
          {{ table.area.name }}
        </div>
        <div class="col-4">
          <ng-container *ngIf="table.linkedDisplays.length == 0">
            {{'There are no assigned self-order kiosks' | translate}}
          </ng-container>

          <ng-container *ngFor="let display of table.linkedDisplays">
          <span>
            {{display.displayHeight}}x{{display.displayWidth}} {{display.screenName}}
          </span>
          </ng-container>

          <div>
            <a data-table-id="@item.Id" class="btn btn-sm btn-outline-primary mg-r-5" (click)="assignDisplay(table)">
              <i class="icon-link"></i>{{'Assign a kiosk' | translate}}
            </a>
          </div>
        </div>
        <div class="col-3">
          <a data-table-id="@item.Id" class="btn btn-sm btn-outline-primary mg-r-5" (click)="downloadQrCodeAsPdf(table)">
            <i class="icon-file-pdf"></i>{{'Download as PDF' | translate}}
          </a>

          <a data-table-id="@item.Id" class="btn btn-sm btn-outline-primary mg-r-5" (click)="assignPregeneratedCode(table)">
            <i class="icon-edit"></i>{{'Assign code' | translate}}
          </a>

          <a data-table-id="@item.Id" class="btn btn-sm btn-outline-primary mg-r-5" (click)="getTableQrCode(table)">
            <i class="icon-qrcode"></i>{{'Show QR' | translate}}
          </a>

          <div class="dropdown d-block" style="cursor: pointer">
            <a class="more-actions-button" data-toggle="dropdown">
              <div class="more-dots-container">
                <span>...</span>
              </div>
            </a>

            <div class="dropdown-menu pd-10 wd-300">
              <nav class="nav nav-style-1 flex-column">
                <a class="nav-link modal-table-action generate-new-qr-code" (click)="generateNewQrCode(table)">
                  <i class="icon-arrows-cw"></i>{{'Generate new QR' | translate}}
                </a>

                <a class="nav-link table-action delete-table" (click)="deleteTable(table)"><i class="icon-trash-empty"></i>{{'Delete' | translate}}</a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row" *ngIf="listIsLoading$ | async">
      <div class="ht-60 pos-relative align-items-center col-12">
        <owc-progress-wave></owc-progress-wave>
      </div>
    </div>

    <div class="row" *ngIf="listLoadingFailed$ | async">
      <div class="ht-50 pos-relative align-items-center col-12">
        <p class="loading-failed-text">{{'Could not load list of tables. Check if you have Internet connection and try again.' | translate}}</p>
      </div>
    </div>
  </div>

  <div class="tfoot" *ngIf="listIsLoaded$ | async">
    <div class="row">
      <div class="col-12">
        {{'You can generate and print a unique QR code for each added table.' | translate}}
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <ng-container *ngIf="userIsManager | async">
          <a class="new-element-btn" [class.disabled]="listIsLoading$ | async" (click)="addTable()">
            <i class="icon-plus"></i>{{'Add table' | translate}}
          </a>

          <a class="new-element-btn" [class.disabled]="listIsLoading$ | async"
             (click)="printQrCodesForSelectedTables()"
             *ngIf="isAnyTableSelected | async">
            <i class="icon-plus"></i>{{'Print QR-codes for selected tables' | translate}}
          </a>
        </ng-container>
      </div>
      <div class="col-6 bd-l-0">
        <owc-pagination-block [visible$]="listIsLoaded$"
                              (filtersChanged)="pagingFiltersChanged($event)"
                              [disable$]="listLoadingFailed$"
                              [state$]="paginationState$">
        </owc-pagination-block>
      </div>
    </div>
  </div>
</div>
