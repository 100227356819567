<ng-select [items]="visibilityItems$ | async"
           [ngModel]="lastValue"
           (ngModelChange)="changed($event)"
           bindLabel="name"
           bindValue="value"
           [virtualScroll]="true"
           [clearable]="false"
           [searchable]="false"
           [disabled]="isDisabled">
</ng-select>
