import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {ActivatedRoute} from '@angular/router';
import {first, switchMap, tap} from 'rxjs/operators';
import {CurrentRestaurantOpenedRequestsService} from '../../../services/active-route-bound/current-restaurant-opened-requests.service';
import {RestaurantRelatedItemsGrid} from '../restaurant-related-items-grid.component';
import {PaginationFiltersStateDefinition} from 'orderly-web-components';
import ClientRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.ClientRequest;

@Component({
             selector: 'app-requested-table-actions-list',
             templateUrl: './requested-table-actions-list.component.html',
             styleUrls: ['./requested-table-actions-list.component.scss']
           })
export class RequestedTableActionsListComponent
  extends RestaurantRelatedItemsGrid<ClientRequest, PaginationFiltersStateDefinition>
  implements OnInit {


  @ViewChild('thead', {static: false})
  thead: ElementRef;


  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private readonly restaurantOpenedRequestsService: CurrentRestaurantOpenedRequestsService) {

    super(store, activatedRoute, RestaurantRelatedItemsGrid.basicInitialFiltersState,
          restaurantOpenedRequestsService.currentRestaurantOpenedRequests$);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.restaurantOpenedRequestsService.forceReload().pipe(first()).subscribe();
  }

  public refreshRequestsList() {
    this.restaurantOpenedRequestsService
        .forceReload()
        .pipe(
          first(),
          switchMap(x => this.filtersState$),
          tap((currentState: PaginationFiltersStateDefinition) => {
            const currentStateClone: PaginationFiltersStateDefinition = {...currentState};

            currentStateClone.currentPage = 1;

            this.pagingFiltersChanged(currentStateClone);
          })
        )
        .subscribe();
  }

  public pagingFiltersChanged($event: PaginationFiltersStateDefinition) {
    super.pagingFiltersChanged($event);

    if (this.thead.nativeElement.scrollIntoView) {
      this.thead.nativeElement.scrollIntoView(true);
    }
  }
}
