/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modifier-row.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./modifier-row.component";
var styles_ModifierRowComponent = [i0.styles];
var RenderType_ModifierRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModifierRowComponent, data: {} });
export { RenderType_ModifierRowComponent as RenderType_ModifierRowComponent };
function View_ModifierRowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modifier.ingredient.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ModifierRowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modifier.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ModifierRowComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.modifier.measureUnit.name)); _ck(_v, 1, 0, currVal_0); }); }
function View_ModifierRowComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.modifier.ingredient.measureUnit.name)); _ck(_v, 1, 0, currVal_0); }); }
export function View_ModifierRowComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifierRowComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifierRowComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "col-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", "\n"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifierRowComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModifierRowComponent_4)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "col-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", "\n"])), i1.ɵppd(15, 2), (_l()(), i1.ɵeld(16, 0, null, null, 8, "div", [["class", "col-3 actions-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "a", [["class", "btn btn-sm btn-danger text-white mg-x-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.update.emit(_co.modifier) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "icon-edit"]], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(21, 0, null, null, 3, "a", [["class", "btn btn-sm btn-danger text-white mg-x-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "i", [["class", "icon-trash-empty"]], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.modifier.ingredient != null); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.modifier.ingredient == null); _ck(_v, 5, 0, currVal_1); var currVal_3 = ((_co.modifier.measureUnit != null) && (_co.modifier.ingredient == null)); _ck(_v, 10, 0, currVal_3); var currVal_4 = (_co.modifier.ingredient != null); _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.modifier.amount; _ck(_v, 7, 0, currVal_2); var currVal_5 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v, 0), _co.modifier.priceDiff, "1.2-2")); _ck(_v, 14, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("Edit")); _ck(_v, 19, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("Delete")); _ck(_v, 23, 0, currVal_7); }); }
export function View_ModifierRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modifier-row", [], null, null, null, View_ModifierRowComponent_0, RenderType_ModifierRowComponent)), i1.ɵdid(1, 49152, null, 0, i4.ModifierRowComponent, [], null, null)], null, null); }
var ModifierRowComponentNgFactory = i1.ɵccf("app-modifier-row", i4.ModifierRowComponent, View_ModifierRowComponent_Host_0, { modifier: "modifier" }, { delete: "delete", update: "update" }, []);
export { ModifierRowComponentNgFactory as ModifierRowComponentNgFactory };
