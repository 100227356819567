<div class="row">
  <div class="col-12 col-md-3 col-xl-2">
    <div class="form-group">
      <label class="form-control-label" for="basedOnIngredient">{{'Based on ingredient?' | translate}}: <span class="tx-danger">*</span></label>

      <app-shared-yes-no-switch [formControl]="formDef.getControl('basedOnIngredient')"
                                [notSelectedButtonClass]="'btn-dark'"
                                [selectedTrueButtonClass]="'btn-light'"
                                [selectedFalseButtonClass]="'btn-light'"
                                class="input-group"
                                id="basedOnIngredient">
      </app-shared-yes-no-switch>

      <owc-input-validation-errors [control]="formDef.getControl('basedOnIngredient')"></owc-input-validation-errors>
    </div>
  </div>

  <div class="col-12 col-md-6">
    <div class="form-group" *ngIf="formDef.getControl('basedOnIngredient').value === false">
      <label class="form-control-label" for="name">{{'Name' | translate}}: <span class="tx-danger">*</span></label>

      <app-shared-text-input-with-language-flag [formControl]="formDef.getControl('name')"
                                                id="name">
      </app-shared-text-input-with-language-flag>

      <owc-input-validation-errors [control]="formDef.getControl('name')"></owc-input-validation-errors>
    </div>

    <div class="form-group" *ngIf="formDef.getControl('basedOnIngredient').value === true">
      <label class="form-control-label" for="basedOnIngredient">{{'Ingredient' | translate}}: <span class="tx-danger">*</span></label>

      <ng-select [items]="ingredients$ | async"
                 [formControl]="formDef.getControl('ingredient')"
                 bindLabel="name"
                 [placeholder]="'Select an ingredient' | translate"
                 [virtualScroll]="true">
      </ng-select>

      <owc-input-validation-errors [control]="formDef.getControl('ingredient')"></owc-input-validation-errors>
    </div>
  </div>
</div>

<div class="row" *ngIf="formDef.getControl('basedOnIngredient').value === false">
  <div class="col-12 col-md-6 offset-md-3 offset-xl-2">
    <label class="form-control-label" for="name">{{'Name translations' | translate}}:</label>

    <app-shared-translations-input [languages]="translationLanguages$ | async"
                                   [primaryTextLanguage]="currentRestaurantPrimaryLanguage$ | async"
                                   [primaryTextVisible]="false"
                                   [maxlength]="80"
                                   [formControl]="formDef.getControl('nameTranslations')">
    </app-shared-translations-input>
  </div>
</div>


<div class="row">
  <div class="col-6 col-md-3">
    <div class="form-group">
      <label class="form-control-label" for="amount">{{'Amount' | translate}}:</label>

      <input id="amount"
             class="form-control"
             [formControl]="formDef.getControl('amount')"
             maxlength="10"
             inputNumber
             [acceptDecimalPlaces]="true"
             [decimalPlaces]="3">

      <owc-input-validation-errors [control]="formDef.getControl('amount')"></owc-input-validation-errors>
    </div>
  </div>

  <div class="col-6 col-md-3">
    <div class="form-group">
      <label class="form-control-label" for="measureUnitId">{{'Measure unit' | translate}}:
        <span class="tx-danger" *ngIf="formDef.getControl('amount').value != null && formDef.getControl('amount').value != ''">*</span></label>


      <app-shared-measure-unit-select [formControl]="formDef.getControl('measureUnit')"
                                      id="measureUnitId">
      </app-shared-measure-unit-select>

      <owc-input-validation-errors [control]="formDef.getControl('measureUnit')"></owc-input-validation-errors>
    </div>
  </div>

  <div class="col-12 col-md-3">
    <div class="form-group">
      <label class="form-control-label" for="priceDiff">{{'Price difference' | translate}}: <span class="tx-danger">*</span></label>

      <input class="form-control"
             id="priceDiff"
             [formControl]="formDef.getControl('priceDiff')"
             maxlength="10"
             inputNumber
             [acceptDecimalPlaces]="true"
             [decimalPlaces]="2">

      <owc-input-validation-errors [control]="formDef.getControl('priceDiff')"></owc-input-validation-errors>
    </div>
  </div>
</div>



<div class="row">
  <div class="col-12">

    <a class="btn btn-danger text-white float-right"
       (click)="cancel.emit()">{{'Cancel' | translate}}</a>

    <a class="btn btn-success text-white float-right mg-r-5"
       [class.disabled]="formDef.form.invalid"
       (click)="saveChanges()">{{'Save' | translate}}</a>
  </div>
</div>
