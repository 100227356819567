import { selectRestaurantIdParamFromAppState } from '../../app/store/selectors/restaurant-selectors';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
export class CurrentRestaurantMenuItemsService {
    constructor(httpClient, store) {
        this.httpClient = httpClient;
        this.store = store;
        this.baseURL = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
        this.restaurantIdRouteParam$ = this.store.pipe(selectRestaurantIdParamFromAppState);
    }
    search() {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/menu-items/all`;
            return this.httpClient.get(url);
        }));
    }
    get(menuItemId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/menu-item/${menuItemId}`;
            return this.httpClient.get(url);
        }));
    }
    delete(menuItemId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/menu-item/${menuItemId}/delete`;
            return this.httpClient.delete(url);
        }));
    }
    add(request) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/menu-item/add`;
            return this.httpClient.post(url, request);
        }));
    }
    update(menuItemId, request) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/menu-item/${menuItemId}/update`;
            return this.httpClient.post(url, request);
        }));
    }
    getMenuItemIngredients(menuItemId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/menu-item/${menuItemId}/ingredients/all`;
            return this.httpClient.get(url);
        }));
    }
    deleteMenuItemIngredient(menuItemIngredientId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/menu-item-ingredient/${menuItemIngredientId}/delete`;
            return this.httpClient.delete(url);
        }));
    }
    updateMenuItemIngredient(menuItemIngredientId, request) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/menu-item-ingredient/${menuItemIngredientId}/update`;
            return this.httpClient.post(url, request);
        }));
    }
    addMenuItemIngredient(menuItemId, request) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/menu-item/${menuItemId}/ingredient/add`;
            return this.httpClient.post(url, request);
        }));
    }
    deleteMenuItemModifier(menuItemId, modifierGroupId, modifierId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/` +
                `menu-item/${menuItemId}/` +
                `modifier-group/${modifierGroupId}/` +
                `modifier/${modifierId}/delete`;
            return this.httpClient.delete(url);
        }));
    }
    addMenuItemModifier(menuItemId, modifierGroupId, request) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/` +
                `menu-item/${menuItemId}/` +
                `modifier-group/${modifierGroupId}/` +
                `modifier/add`;
            return this.httpClient.post(url, request);
        }));
    }
    updateMenuItemModifier(menuItemId, modifierGroupId, modifierId, request) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/` +
                `menu-item/${menuItemId}/` +
                `modifier-group/${modifierGroupId}/` +
                `modifier/${modifierId}/update`;
            return this.httpClient.post(url, request);
        }));
    }
    deleteMenuItemModifierGroup(menuItemId, modifierGroupId) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap((restaurantId) => {
            const url = `${this.baseURL}/restaurant/${restaurantId}/menu-item/${menuItemId}/modifier-group/${modifierGroupId}/delete`;
            return this.httpClient.delete(url);
        }));
    }
}
CurrentRestaurantMenuItemsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentRestaurantMenuItemsService_Factory() { return new CurrentRestaurantMenuItemsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: CurrentRestaurantMenuItemsService, providedIn: "root" });
