import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MeasureUnit, NeverError, ToastService} from 'orderly-web-components';
import RestaurantIngredientBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
import {genericErrorHandlerWithToast, nameof} from '../../util/utils';
import AddMenuItemIngredientRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddMenuItemIngredientRequest;
import UpdateMenuItemIngredientRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.UpdateMenuItemIngredientRequest;
import AddOrUpdateMenuItemIngredientResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemIngredientResponse;
import {catchError, finalize, first, tap} from 'rxjs/operators';
import StatusDef = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemIngredientResponse.StatusDef;
import {AddTagFn, CompareWithFn} from '@ng-select/ng-select/lib/ng-select.component';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../util/form.utils';
import {TranslateService} from '@ngx-translate/core';
import {CurrentRestaurantMenuItemsService} from '../../services/active-route-bound/current-restaurant-menu-items.service';

type IngredientComponentFormFields = 'ingredient' | 'amount' | 'measureUnitId';

@Component({
  selector: 'app-add-or-edit-menu-item-ingredient',
  templateUrl: './add-or-edit-menu-item-ingredient.component.html',
  styleUrls: ['./add-or-edit-menu-item-ingredient.component.scss']
})
export class AddOrEditMenuItemIngredientComponent implements OnInit, AfterViewInit {
  public actionInProgress: boolean = false;

  @Input() restaurantId: number;
  @Input() menuItemId: number;
  @Input() measureUnits: Observable<MeasureUnit[]>;
  @Input() ingredients: Observable<RestaurantIngredientBasic[]>;
  @Input() ingredientToEdit: {
    name: string,
    measureUnitId: number | null,
    id: number | null,
    amount: number | null,
    ingredientId: number | null
  };

  @Output()
  completed = new EventEmitter();

  public formDef: FormDefinition<IngredientComponentFormFields>;

  constructor(public restaurantMenuItemsService: CurrentRestaurantMenuItemsService,
              public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private trnService: TranslateService,
              private toastService: ToastService) {
  }

  ngOnInit() {
    const disableSelects = this.ingredientToEdit.id != null;

    const fieldsDef: FormFieldsDefinition<IngredientComponentFormFields> = {
      ingredient: new FormFieldDefinition(this.ingredientToEdit.ingredientId,
                                          disableSelects,
                                          [Validators.required],
                                          [nameof<AddMenuItemIngredientRequest>('ingredientId'),
                                            nameof<AddMenuItemIngredientRequest>('ingredientName')]),
      amount: new FormFieldDefinition(this.ingredientToEdit.amount,
                                      false,
                                      [Validators.required, Validators.min(1)],
                                      [nameof<AddMenuItemIngredientRequest>('amount'),
                                        nameof<UpdateMenuItemIngredientRequest>('amount')]),
      measureUnitId: new FormFieldDefinition(this.ingredientToEdit.measureUnitId,
                                             disableSelects,
                                             [Validators.required],
                                             [nameof<AddMenuItemIngredientRequest>('measureUnitId')])
    };

    this.formDef = new FormDefinition<IngredientComponentFormFields>(fieldsDef, this.formBuilder);
  }

  public save() {
    if (this.formDef.form.invalid) {
      return;
    }

    this.actionInProgress = true;

    const ingredientValue: RestaurantIngredientBasic = this.formDef.getControl('ingredient').value;
    const amount: string = this.formDef.getControl('amount').value;
    const amountInt: number = parseInt(amount, 10);
    let saveAction: Observable<AddOrUpdateMenuItemIngredientResponse>;

    if (this.ingredientToEdit.id == null) {
      const measureUnitIdValue: string = this.formDef.getControl('measureUnitId').value;
      const measureUnitIdInt: number = parseInt(measureUnitIdValue, 10);

      const request: AddMenuItemIngredientRequest = {
        amount: amountInt,
        ingredientId: ingredientValue.id,
        ingredientName: ingredientValue.name,
        measureUnitId: measureUnitIdInt
      };

      saveAction = this.restaurantMenuItemsService.addMenuItemIngredient(this.menuItemId, request);
    } else {
      const request: UpdateMenuItemIngredientRequest = {amount: amountInt};

      saveAction = this.restaurantMenuItemsService.updateMenuItemIngredient(this.ingredientToEdit.id, request);
    }

    const unexpectedErrorMsg = this.trnService.instant('Failed to save an ingredient because of unexpected failure.');

    saveAction.pipe(
      first(),
      tap((response: AddOrUpdateMenuItemIngredientResponse) => {
        switch (response.status) {
          case StatusDef.Success:
            this.completed.emit(response);

            this.activeModal.dismiss(null);

            this.toastService.showSuccess(this.trnService.instant('Ingredient was saved'));
            break;
          case StatusDef.ValidationFailed:
            this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
            break;
          case StatusDef.UnknownFailure:
            this.toastService.showError(unexpectedErrorMsg);
            break;
          default:
            throw new NeverError(response.status);
        }
      }),
      catchError(
        genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedErrorMsg)
      ),
      finalize(() => {
        this.actionInProgress = false;
      })
              )
              .subscribe();
  }

  onIngredientChange($e: null | any) {
    const isIngredient = $e == null ? false : nameof<RestaurantIngredientBasic>('measureUnit') in $e;
    const measureUnitIdControl = this.formDef.getControl('measureUnitId');

    if (isIngredient) {
      const tmp = $e as RestaurantIngredientBasic;

      measureUnitIdControl.disable();
      measureUnitIdControl.setValue(tmp.measureUnit.id);
    } else {
      measureUnitIdControl.enable();
      measureUnitIdControl.setValue(null);
    }
  }

  onSelectNotExistingIngredient: AddTagFn = (term: string): any => {
    return {id: null, name: term};
  };

  compareIngredientOptions: CompareWithFn = (a, b): boolean => {
    let idParam: number;
    let ingredientParam: RestaurantIngredientBasic;

    if (typeof a === 'number') {
      idParam = a;
      ingredientParam = b;
    } else {
      idParam = b;
      ingredientParam = a;
    }

    return ingredientParam.id === idParam;
  };

  ngAfterViewInit(): void {

    this.ingredients
        .pipe(
          first(),
          tap((x: RestaurantIngredientBasic[]) => {
            if (x.length === 0) {
              const errors = [
                this.trnService.instant('FYI: You\'ve either used all available ingredients for this menu item, ' +
                                        'or there are no ingredients at all. But you can add a new ingredient by typing its name above.')
              ];

              this.formDef.addControlError('ingredient', errors);
            }
          })
        )
        .subscribe();
  }
}
