import RestaurantIngredientBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
import ImageWithBase64Def = Orderly.Shared.Api.Messages.ImageWithBase64Def;
import MenuItemImageWithThumbsDef = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemImageWithThumbsDef;
import {
  createDetailsTabFormFieldsDefinition,
  createNewDetailsTabFormFieldsDefinition,
  DetailsTabFormFieldsDefinition, NewDetailsTabFormFieldsDefinition
} from './details-tab/details-tab.form-fields-definition';
import {
  FormArrayDefinition,
  FormDefinition,
  FormFieldDefinition,
  FormFieldsDefinition
} from '../../../../../util/form.utils';
import {FormBuilder} from '@angular/forms';
import MenuItemModifierGroupWithModifiers = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupWithModifiers;
import {TabSettingsDefinition} from './tab-settings.definition';
import {Modify} from '../../../shared/ts-utils';
import MenuItemWithAllData = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData;
import MenuItemTranslationDefinition = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemTranslationDefinition;
import {MenuItemTranslationsFormFieldsDefinition} from './translations-tab/menu-item-translations-form-fields.definition';
import {TextTranslationDefinition} from '../../../shared/components/text-input-with-language-flag/text-translation.definition';

export interface MenuItemIngredientDef {
  menuItemIngredientId: number;
  ingredient: RestaurantIngredientBasic;
  amount: number;
  munitname: string;
  alcohol: boolean;
  allergens: Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Allergen[];
}

export class MenuItemIngredientDefImplementation implements MenuItemIngredientDef {
  public readonly alcohol: boolean;
  public readonly allergens: Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Allergen[];
  public readonly amount: number;
  public readonly ingredient: Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
  public readonly menuItemIngredientId: number;
  public readonly munitname: string;


  constructor(source: Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemWithAllData.MenuIngredient) {
    this.alcohol = source.ingredient.containsAlcohol;
    this.allergens = source.ingredient.allergens;
    this.amount = source.amount;
    this.ingredient = source.ingredient;
    this.menuItemIngredientId = source.id;
    this.munitname = source.ingredient.measureUnit.name;
  }
}

export type MenuItemImageNewOrExisting = ImageWithBase64Def | MenuItemImageWithThumbsDef;

export interface MenuItemFormFieldsDef {
  details: DetailsTabFormFieldsDefinition;
  ingredients: MenuItemIngredientDef[];
  modifierGroups: MenuItemModifierGroupWithModifiers[];
  translations: MenuItemTranslationsFormFieldsDefinition;
}

export interface NewMenuItemFormFieldsDef extends Modify<MenuItemFormFieldsDef,
  {
    details: NewDetailsTabFormFieldsDefinition,
  }> {}

export function createForm(formBuilder: FormBuilder): FormDefinition<keyof MenuItemFormFieldsDef> {
  const fieldsDef: FormFieldsDefinition<keyof MenuItemFormFieldsDef> = {
    details: new FormFieldDefinition(null,
                                     false,
                                     [],
                                     []),
    ingredients: new FormArrayDefinition<keyof MenuItemIngredientDef>([], []),
    modifierGroups: new FormFieldDefinition([],
                                            false,
                                            [],
                                            []),
    translations: new FormFieldDefinition([],
                                            false,
                                            [],
                                            []),
  };

  return new FormDefinition(fieldsDef, formBuilder);
}

export function createNewMenuItemFormFieldsDef(settings: TabSettingsDefinition): NewMenuItemFormFieldsDef {
  const details: NewDetailsTabFormFieldsDefinition = createNewDetailsTabFormFieldsDefinition(settings);
  const translations: MenuItemTranslationsFormFieldsDefinition = {
    name: [],
    description: [],
  };

  return {
    details,
    modifierGroups: [],
    ingredients: [],
    translations,
  }
}

export function createExistingMenuItemFormFieldsDef(settings: TabSettingsDefinition, existingMenuItem: MenuItemWithAllData): NewMenuItemFormFieldsDef {
  const details: NewDetailsTabFormFieldsDefinition = createDetailsTabFormFieldsDefinition(settings, existingMenuItem);
  let translations: MenuItemTranslationDefinition[] = existingMenuItem.translations;
  const primaryLangTranslationIndex = translations.findIndex(x => x.languageCode2.toLowerCase() === settings.primaryLanguage.code.toLowerCase());
  const defaultLanguageTranslation: MenuItemTranslationDefinition = {
    languageCode2: settings.primaryLanguage.code,
    name: existingMenuItem.name,
    description: existingMenuItem.description || ''
  };

  if (primaryLangTranslationIndex > -1) {
    translations[primaryLangTranslationIndex] = defaultLanguageTranslation;
  } else {
    translations = [...translations, defaultLanguageTranslation];
  }

  const nameTranslations = translations.map((x) => {
    const result: TextTranslationDefinition = {
      langCode2: x.languageCode2,
      text: x.name,
    };

    return result;
  });

  const descriptionTranslations = translations.map((x) => {
    const result: TextTranslationDefinition = {
      langCode2: x.languageCode2,
      text: x.description,
    };

    return result;
  });

  const translationsFormValue: MenuItemTranslationsFormFieldsDefinition = {
    name: nameTranslations,
    description: descriptionTranslations,
  };

  return {
    details,
    modifierGroups: existingMenuItem.modifierGroups,
    ingredients: [],
    translations: translationsFormValue,
  }
}
