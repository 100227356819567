<app-top-bar-with-language-switcher></app-top-bar-with-language-switcher>

<div class="justify-content-center bg-br-primary ht-100v register-form-container-sm" style="padding: 25px 0; margin: auto;" *ngIf="translations$ | async as trns">
  <div class="pd-25 pd-xs-40 bg-white rounded shadow-base">

    <div class="tx-center tx-28 tx-inverse pd-md-b-20">{{'Register your business now!' | translate}}</div>

    <form [formGroup]="this.formDef.form" (ngSubmit)="registerRestaurantHolding()" class="form-layout form-layout-1" autocomplete="off">

      <div class="row">
        <div class="col-12">

          <div class="form-group">
            <label class="form-control-label">{{'Language' | translate}}: <span class="tx-danger">*</span></label>

            <div class="row">
              <div class="col-12">
                <app-language2-select [languages$]="languages$"
                                      [formControl]="formDef.getControl('languageCode2')">

                </app-language2-select>

                <owc-input-validation-errors [control]="formDef.getControl('languageCode2')"></owc-input-validation-errors>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-control-label">{{trns.restaurantNameLabel}}: <span class="tx-danger">*</span></label>
            <input type="text"
                   class="form-control"
                   maxlength="50"
                   [formControl]="formDef.getControl('name')"
                   placeholder="{{trns.restaurantInputPlaceholder}}" />

            <owc-input-validation-errors [control]="formDef.getControl('name')"></owc-input-validation-errors>
          </div>

          <div class="form-group">
            <label class="form-control-label">{{'Account manager email' | translate}}: <span class="tx-danger">*</span></label>

            <input type="text"
                   class="form-control"
                   maxlength="120"
                   [formControl]="formDef.getControl('email')"
                   [placeholder]="'Enter account manager email' | translate" />

            <owc-input-validation-errors [control]="formDef.getControl('email')"></owc-input-validation-errors>
          </div>

          <div class="form-group">
            <label class="form-control-label">{{'Password' | translate}}: <span class="tx-danger">*</span></label>

            <input type="password"
                   class="form-control"
                   [formControl]="formDef.getControl('password')"
                   [placeholder]="'Enter password' | translate" />

            <owc-input-validation-errors [control]="formDef.getControl('password')"></owc-input-validation-errors>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-12 align-content-lg-end">
          <div class="form-group form-check">
            <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" />
            <label for="acceptTerms" class="form-check-label">{{trns.acceptTermsAndConditions}}</label>

            <owc-input-validation-errors [control]="formDef.getControl('acceptTerms')"></owc-input-validation-errors>
          </div>

          <div class="float-left">
            <owc-progress-action-button [actionInProgress]="registrationInProgress"
                                        [btnClass]="'btn-danger'"
                                        [btnText]="'Register' | translate"
                                        [type]="'submit'"
                                        [disabled]="formDef.form.invalid"
                                        (execute)="registerRestaurantHolding()">
            </owc-progress-action-button>
          </div>

          <a class="btn btn-link float-right" routerLink="/login">{{'Navigate to login' | translate}}</a>
        </div>
      </div>

    </form>
  </div>
</div>
