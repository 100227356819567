import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import { ActionTypes } from '../actions/action-types';
import { switchMap, tap } from 'rxjs/operators';
import { LocalStorageService } from '../../../services/local-storage.service';
import { getUserDefaultRedirectUrlByRole } from '../../../route.guards';
import { RestaurantManagerRoutingConstants, RoutingConstants } from '../../routing-constants';
import { environment } from '../../../environments/environment';
import { from } from 'rxjs';
export class LoginLogoutEffect {
    constructor(actions, router) {
        this.actions = actions;
        this.router = router;
        this.loginSuccessfulEffect = this.actions.pipe(ofType(ActionTypes.LOGIN_SUCCESSFUL), tap((action) => {
            const parsedUser = LocalStorageService.setCurrentUser(action.jwtTokenBase64, action.user);
            const restaurantIds = parsedUser.user.restaurants.map(x => x.id);
            const currentLocationIsLoginOrRegister = location.pathname.toLowerCase() === '/' + RoutingConstants.LOGIN ||
                location.pathname.toLowerCase() === '/' + RoutingConstants.REGISTRATION;
            if (currentLocationIsLoginOrRegister) {
                const navigationUrl = getUserDefaultRedirectUrlByRole(parsedUser.roles, restaurantIds);
                this.router.navigateByUrl(navigationUrl);
            }
            return true;
        }));
        this.logoutEffect = this.actions.pipe(ofType(ActionTypes.LOGOUT), tap((action) => {
            LocalStorageService.removeCurrentUser();
        }), switchMap((action) => {
            return from(this.router.navigateByUrl('/' + RoutingConstants.LOGIN));
        }));
        this.currentUserInformationChangedEffect = this.actions.pipe(ofType(ActionTypes.CURRENT_USER_INFORMATION_CHANGED), tap((action) => {
            const currentUserInfo = LocalStorageService.getCurrentUser();
            if (currentUserInfo != null) {
                const clonedUser = Object.assign({}, currentUserInfo.user);
                clonedUser.preferredLang = action.lang;
                clonedUser.lastName = action.lastName;
                clonedUser.firstName = action.firstName;
                LocalStorageService.setCurrentUser(currentUserInfo.jwtTokenBase64, clonedUser);
            }
            else if (!environment.production) {
                throw new Error(`Action [${ActionTypes.CURRENT_USER_INFORMATION_CHANGED}] expects that current user information ` +
                    'is available in local storage');
            }
        }));
        this.userLanguageChangedEffect$ = this.actions.pipe(ofType(ActionTypes.CURRENT_USER_SELECTED_LANGUAGE_CHANGED), tap((action) => {
            const currentUserInfo = LocalStorageService.getCurrentUser();
            if (currentUserInfo != null) {
                const clonedUser = Object.assign({}, currentUserInfo.user);
                clonedUser.preferredLang = action.lang;
                LocalStorageService.setCurrentUser(currentUserInfo.jwtTokenBase64, clonedUser);
            }
        }));
        this.restaurantCreateSuccessfulEffect = this.actions.pipe(ofType(ActionTypes.RESTAURANT_CREATE_SUCCESSFUL), tap((action) => {
            const currentUserInfo = LocalStorageService.getCurrentUser();
            if (currentUserInfo != null) {
                currentUserInfo.user.restaurants.push(action.restaurant);
                LocalStorageService.setCurrentUser(currentUserInfo.jwtTokenBase64, currentUserInfo.user);
                this.router.navigateByUrl(RestaurantManagerRoutingConstants.getRestaurantEditUrl(action.restaurant.id));
            }
            else if (!environment.production) {
                throw new Error(`Action [${ActionTypes.RESTAURANT_CREATE_SUCCESSFUL}] expects that current user information ` +
                    'is available in local storage');
            }
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false })
], LoginLogoutEffect.prototype, "loginSuccessfulEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false })
], LoginLogoutEffect.prototype, "logoutEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false })
], LoginLogoutEffect.prototype, "currentUserInformationChangedEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false })
], LoginLogoutEffect.prototype, "userLanguageChangedEffect$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false })
], LoginLogoutEffect.prototype, "restaurantCreateSuccessfulEffect", void 0);
