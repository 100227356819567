/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./holding-dashboard.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../first-steps-wizard/first-steps-wizard.component.ngfactory";
import * as i3 from "../first-steps-wizard/first-steps-wizard.component";
import * as i4 from "@angular/forms";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../services/active-route-bound/current-restaurant-areas.service";
import * as i7 from "../../services/active-route-bound/current-restaurant-tables.service";
import * as i8 from "orderly-web-components";
import * as i9 from "@angular/router";
import * as i10 from "@ngrx/store";
import * as i11 from "./restaurant-card/restaurant-card.component.ngfactory";
import * as i12 from "./restaurant-card/restaurant-card.component";
import * as i13 from "@angular/common";
import * as i14 from "./holding-dashboard.component";
import * as i15 from "../../services/restaurant.service";
var styles_HoldingDashboardComponent = [i0.styles];
var RenderType_HoldingDashboardComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HoldingDashboardComponent, data: {} });
export { RenderType_HoldingDashboardComponent as RenderType_HoldingDashboardComponent };
function View_HoldingDashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-first-steps-wizard", [], null, null, null, i2.View_FirstStepsWizardComponent_0, i2.RenderType_FirstStepsWizardComponent)), i1.ɵdid(1, 245760, null, 0, i3.FirstStepsWizardComponent, [i4.FormBuilder, i5.TranslateService, i6.CurrentRestaurantAreasService, i7.CurrentRestaurantTablesService, i8.ToastService, i9.Router, i10.Store, i9.ActivatedRoute], { createRestaurant: [0, "createRestaurant"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
function View_HoldingDashboardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-xxl-4 col-lg-6 col-12 restaurant-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-restaurant-card", [], null, null, null, i11.View_RestaurantCardComponent_0, i11.RenderType_RestaurantCardComponent)), i1.ɵdid(2, 180224, null, 0, i12.RestaurantCardComponent, [i10.Store, i9.ActivatedRoute], { restaurant: [0, "restaurant"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HoldingDashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HoldingDashboardComponent_3)), i1.ɵdid(2, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i13.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "col-xxl-1 col-lg-2 col-4 restaurant-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "btn btn-outline-primary btn-add-new-restaurant"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i9.RouterLinkWithHref, [i9.Router, i9.ActivatedRoute, i13.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "icon-plus"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.restaurants$)); _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.addRestaurantUrl; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 6).target; var currVal_2 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_HoldingDashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_HoldingDashboardComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i13.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HoldingDashboardComponent_2)), i1.ɵdid(4, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i13.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.showOnboardingWizard$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.showOnboardingWizard$)) === false); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_HoldingDashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-holding-dashboard", [], null, null, null, View_HoldingDashboardComponent_0, RenderType_HoldingDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i14.HoldingDashboardComponent, [i10.Store, i15.RestaurantService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HoldingDashboardComponentNgFactory = i1.ɵccf("app-holding-dashboard", i14.HoldingDashboardComponent, View_HoldingDashboardComponent_Host_0, {}, {}, []);
export { HoldingDashboardComponentNgFactory as HoldingDashboardComponentNgFactory };
