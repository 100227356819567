import {Component, Input} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors, Validator
} from '@angular/forms';
import {BaseOrderlyComponent} from '../../../../shared/components/base-orderly.component';
import {tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {
  createDefaultModifierGroupEditorFormFieldsDefinition, createModifierGroupEditorFormFieldsDefinition,
  ModifierGroupEditorFormFieldsDefinition
} from './modifier-group-editor/modifier-group-editor-form-fields.definition';
import {TabSettingsDefinition} from '../tab-settings.definition';
import {EditModifierGroupModel} from './edit-modifier-group.model';
import {
  createDefaultModifierEditorFormDefinition, createModifierEditorFormDefinition,
  ModifierEditorFormDefinition, NewModifierEditorFormDefinition
} from './modifier-editor/modifier-editor.form-definition';
import MenuItemModifierGroupWithModifiers = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupWithModifiers;
import MenuItemModifierTranslationDefinition = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierTranslationDefinition;
import MenuItemModifierGroupTranslationDefinition = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupTranslationDefinition;

// TODO: redo this component because it is not nicely implemented
@Component({
             selector: 'app-modifiers-tab',
             templateUrl: './modifiers-tab.component.html',
             styleUrls: ['./modifiers-tab.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: ModifiersTabComponent,
                 multi: true,
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: ModifiersTabComponent,
                 multi: true,
               },
             ],
           })
export class ModifiersTabComponent extends BaseOrderlyComponent implements ControlValueAccessor, Validator {

  public isDisabled: boolean = false;

  public groups: MenuItemModifierGroupWithModifiers[] = [];

  public readonly mode$: BehaviorSubject<'list' | 'group' | 'modifier'> = new BehaviorSubject<'list' | 'group' | 'modifier'>('list');

  public currentGroupToEdit: ModifierGroupEditorFormFieldsDefinition | null = null;

  public currentModifierToEdit: {
    modifier: NewModifierEditorFormDefinition,
    modifierIndex: number | null,
    parentGroupIndex: number
  } | null = null;

  @Input()
  public settings: TabSettingsDefinition;


  constructor() {
    super();

    this.registerForAutoDestroy(this.mode$);

    this.registerForAutoDestroy(
      this.mode$
          .pipe(
            tap((value: 'list' | 'group') => {

              switch (value) {
                case 'list':
                  this.currentGroupToEdit = null;
                  break;
              }
            }),
          )
          .subscribe()
    );
  }


  public validate(control: AbstractControl): ValidationErrors | null {
    // TODO: remove?

    if (control.invalid) {
      return {'modifier-groups': true};
    }

    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: MenuItemModifierGroupWithModifiers[]) => {
  };

  onTouched: any = () => {
  };


  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: MenuItemModifierGroupWithModifiers[] | null): void {
    this.groups = obj || [];
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public addModifierGroup(): void {
    this.currentGroupToEdit = createDefaultModifierGroupEditorFormFieldsDefinition(this.settings);

    this.mode$.next('group');
  }

  public saveGroup(data: ModifierGroupEditorFormFieldsDefinition): void {

    const translations: MenuItemModifierGroupTranslationDefinition[] = data.nameTranslations.map(x =>  {
      return {
        langCode2: x.langCode2,
        name: x.text,
      }
    });

    if (this.currentGroupToEdit instanceof EditModifierGroupModel) {

      this.groups[this.currentGroupToEdit.groupIndex] = {
        id: data.id!,
        isActive: data.isActive,
        maxAllowedAmount: data.maxAllowedAmount,
        menuItemModifiers: this.currentGroupToEdit.menuItemModifiers,
        name: data.name.text,
        minAllowedAmount: data.minAllowedAmount,
        nameIsVisibleToUser: true,
        translations,
      };
    } else {

      this.groups.push({
                         id: -1,
                         isActive: data.isActive,
                         maxAllowedAmount: data.maxAllowedAmount,
                         menuItemModifiers: [],
                         name: data.name.text,
                         minAllowedAmount: data.minAllowedAmount,
                         nameIsVisibleToUser: true,
                         translations,
                       });
    }

    this.onChange(this.groups);

    this.mode$.next('list');
  }

  public cancelGroupEditing(): void {
    this.mode$.next('list');
  }

  public cancelModifierEditing(): void {
    this.mode$.next('list');
  }

  public startGroupUpdate(groupIndex: number): void {
    const group = createModifierGroupEditorFormFieldsDefinition(this.groups[groupIndex], this.settings);

    this.currentGroupToEdit = new EditModifierGroupModel(groupIndex,
                                                         this.groups[groupIndex].menuItemModifiers,
                                                         group.id,
                                                         group.name,
                                                         group.minAllowedAmount,
                                                         group.maxAllowedAmount,
                                                         group.isOnlyOnePermitted,
                                                         group.isActive,
                                                         true,
                                                         group.nameTranslations);

    this.mode$.next('group');
  }

  public startModifierUpdate(data: {groupIndex: number; modifierIndex: number}): void {

    const source = this.groups[data.groupIndex].menuItemModifiers[data.modifierIndex];

    this.currentModifierToEdit = {
      modifier: createModifierEditorFormDefinition(source, this.settings),
      modifierIndex: data.modifierIndex,
      parentGroupIndex: data.groupIndex,
    };

    this.mode$.next('modifier');
  }

  public startModifierAdd(data: { groupIndex: number }): void {
    this.currentGroupToEdit = null;
    this.currentModifierToEdit = {
      modifier: createDefaultModifierEditorFormDefinition(this.settings),
      modifierIndex: null,
      parentGroupIndex: data.groupIndex,
    };

    this.mode$.next('modifier');
  }


  public saveModifier(modifier: ModifierEditorFormDefinition): void {
    const data = this.currentModifierToEdit!;
    const translations: MenuItemModifierTranslationDefinition[] = modifier.nameTranslations.map(x => {
      return {
        langCode2: x.langCode2,
        name: x.text
      }
    });


    if (data.modifierIndex == null) {
      this.groups[data.parentGroupIndex]
        .menuItemModifiers
        .push({
                id: modifier.id || -1,
                amount: modifier.amount,
                name: modifier.basedOnIngredient ? '' : modifier.name.text,
                ingredient: modifier.basedOnIngredient ? modifier.ingredient : null,
                priceDiff: modifier.priceDiff,
                isActive: modifier.isActive,
                measureUnit: modifier.measureUnit,
                translations,
              });
    } else {
      this.groups[data.parentGroupIndex].menuItemModifiers[data.modifierIndex] = {
        id: modifier.id || -1,
        isActive: modifier.isActive,
        name: modifier.basedOnIngredient ? '' : modifier.name.text,
        ingredient: modifier.basedOnIngredient ? modifier.ingredient : null,
        priceDiff: modifier.priceDiff,
        amount: modifier.amount,
        measureUnit: modifier.measureUnit,
        translations,
      }
    }

    this.onChange(this.groups);

    this.currentModifierToEdit = null;

    this.mode$.next('list');
  }

  public deleteGroup(index: number): void {
    this.groups.splice(index, 1);

    this.onChange(this.groups);
  }
}
