import {Pipe, PipeTransform} from '@angular/core';
import {YesNoTranslations} from '../../../../util/trn.utils';

@Pipe({name: 'yesno'})
export class YesNoPipe implements PipeTransform {

  transform(value: boolean): string {
    return value ? YesNoTranslations.Yes : YesNoTranslations.No;
  }
}
