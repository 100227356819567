<div class="col-12 col-xxl-3 col-xl-3 col-lg-4 col-md-6">
  <div class="form-group">
    <label class="form-control-label" for="waiterCallSwitch">{{'Guests can call a waiter' | translate}}:</label>

    <app-shared-enabled-disabled-switch [formControl]="formDef.getControl('callWaiterEnabled')"
                                        id="waiterCallSwitch"
                                        class="d-block">
    </app-shared-enabled-disabled-switch>

    <owc-input-validation-errors [control]="formDef.getControl('callWaiterEnabled')"></owc-input-validation-errors>
  </div>
</div>

<div class="col-12 col-xxl-3 col-xl-3 col-lg-4 col-md-6">
  <div class="form-group">
    <label class="form-control-label" for="makeOrdersSwitch">{{'Guests can order from the device' | translate}}:</label>

    <app-shared-enabled-disabled-switch [formControl]="formDef.getControl('guestsCanMakeOrders')"
                                        id="makeOrdersSwitch"
                                        class="d-block">
    </app-shared-enabled-disabled-switch>

    <owc-input-validation-errors [control]="formDef.getControl('guestsCanMakeOrders')"></owc-input-validation-errors>
  </div>
</div>

<div class="col-12 col-xxl-3 col-xl-3 col-lg-4 col-md-6">
  <div class="form-group">
    <label class="form-control-label" for="catListStyle">{{'Menu item categories style' | translate}}:</label>

    <app-menu-categories-list-style-select [formControl]="formDef.getControl('menuCategoriesListStyle')"
                                           id="catListStyle">
    </app-menu-categories-list-style-select>

    <owc-input-validation-errors [control]="formDef.getControl('menuCategoriesListStyle')"></owc-input-validation-errors>
  </div>
</div>
