import {TextTranslationDefinition} from '../../../../shared/components/text-input-with-language-flag/text-translation.definition';
import {FormBuilder} from '@angular/forms';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../../util/form.utils';

export interface MenuItemTranslationsFormFieldsDefinition {
  name: TextTranslationDefinition[];
  description: TextTranslationDefinition[];
}

export function createForm(formBuilder: FormBuilder): FormDefinition<keyof MenuItemTranslationsFormFieldsDefinition> {
  const fieldsDef: FormFieldsDefinition<keyof MenuItemTranslationsFormFieldsDefinition> = {
    name: new FormFieldDefinition([],
                                  false,
                                  [
                                  ],
                                  []),
    description: new FormFieldDefinition([],
                                         false,
                                         [],
                                         []),
  };


  return new FormDefinition(fieldsDef, formBuilder);
}
