<div class="form-layout form-layout-1 mg-b-10 pd-b-0">
  <form [formGroup]="formDef.form">
    <div class="row">
      <div class="col-12 col-md-4 col-xl-3 col-xxl-2">
        <div class="form-group">
          <label class="form-control-label">{{'Area' | translate}}:</label>

          <ng-select [items]="areas$ | async"
                     [formControl]="formDef.getControl('area')"
                     bindLabel="name">
          </ng-select>

          <owc-input-validation-errors [control]="formDef.getControl('area')"></owc-input-validation-errors>
        </div>
      </div>

      <div class="col-12 col-md-8 col-xl-5 col-xxl-4">
        <div class="form-group">
          <label class="form-control-label text-center">{{'Created on' | translate}}:</label>

          <div class="row">
            <div class="col-6">
              <div class="input-group">
                <input #createdOnFromDate="ngbDatepicker"
                       ngbDatepicker
                       class="form-control"
                       placeholder="dd-mm-yyyy"
                       [formControl]="formDef.getControl('createdOnFrom')"
                       [minDate]="minSelectableDate"
                       [maxDate]="maxSelectableFromDate"
                       (click)="createdOnFromDate.open()"
                       (keydown)="$event.preventDefault()">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="createdOnFromDate.toggle()" type="button"><i class="icon-calendar"></i></button>
                </div>
              </div>

              <owc-input-validation-errors [control]="formDef.getControl('createdOnFrom')"></owc-input-validation-errors>
            </div>

            <div class="col-6">
              <div class="input-group">
                <input #createdOnToDate="ngbDatepicker"
                       ngbDatepicker
                       class="form-control"
                       placeholder="dd-mm-yyyy"
                       [formControl]="formDef.getControl('createdOnTo')"
                       [minDate]="minSelectableToDate"
                       [maxDate]="maxSelectableDate"
                       (click)="createdOnToDate.open()"
                       (keydown)="$event.preventDefault()">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="createdOnToDate.toggle()" type="button"><i class="icon-calendar"></i></button>
                </div>
              </div>

              <owc-input-validation-errors [control]="formDef.getControl('createdOnTo')"></owc-input-validation-errors>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4 col-xl-3 col-xxl-2">
        <div class="form-group">
          <label class="form-control-label">{{'Max results' | translate}}:</label>

          <ng-select [formControl]="formDef.getControl('maxResults')"
                     [searchable]="false"
                     [clearable]="false"
                     [items]="maxResults"
                     [multiple]="false">
          </ng-select>

          <owc-input-validation-errors [control]="formDef.getControl('maxResults')"></owc-input-validation-errors>
        </div>
      </div>

      <div class="col-12 col-md-1" *ngIf="searchButtonVisible">
        <div class="form-group">
          <label class="form-control-label">&nbsp;</label>
          <a class="btn btn-info" (click)="doSearch()" [class.disabled]="formDef.form.invalid">{{'Search' | translate}}</a>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="reviews-list-table div-based-table table-dark table-colored">
  <div class="thead">
    <div class="row">
      <div class="col-6 title">{{'Reviews' | translate}}</div>
      <div class="col-6 actions text-right">
        <ng-container *ngIf="lastLoadedOn != null">
          <span>{{'last loaded on' | translate}} {{lastLoadedOn | date:'HH:mm'}}</span> /
          <span *ngIf="reviews$ | async as reviews">{{'num. of displayed items' | translate}}: {{reviews.length}}</span>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-2 col-xxl-3 hidden-lg-down">{{'Comments' | translate}}</div>
      <div class="col-2 col-xxl-2 hidden-lg-down">{{'Service' | translate}}</div>
      <div class="col-2 col-xxl-2 hidden-lg-down">{{'Food' | translate}}</div>
      <div class="col-2 col-xxl-2 hidden-lg-down">{{'Waiting time' | translate}}</div>
      <div class="col-8 hidden-xl-up">{{'Scores' | translate}}</div>
      <div class="col-2 col-xxl-1"></div>
    </div>
  </div>
  <div class="tbody" *ngIf="waitingForFirstSearch">
    <div class="row">
      <div class="col-12 text-center">
        {{'Specify search criteria and click "Search" button to find the reviews' | translate}}
      </div>
    </div>
  </div>
  <div class="tbody" *ngIf="waitingForFirstSearch === false">
    <ng-container *ngIf="listIsLoaded$ | async">
      <ng-container *ngIf="reviews$ | async as reviews">
        <div class="row" *ngIf="reviews.length === 0">
          <div class="col-12 text-center">
            {{'Could not find any reviews that match your search criteria' | translate}}
          </div>
        </div>

        <div class="row" *ngFor="let review of reviews" style="word-wrap: break-word;">
          <div class="col-2">
            <div>
              {{ review.createdOnUtc | localDate:'DD-MM-YYYY HH:mm' }}
            </div>
            <div class="hidden-lg-down">
              <span>{{'Table' | translate}}</span>: <span class="font-weight-normal">{{review.table.name}}</span>
            </div>
            <div class="hidden-lg-down">
              <span>{{'Area' | translate}}</span>: <span class="font-weight-normal">{{review.table.area.name}}</span>
            </div>
          </div>
          <div class="col-2 col-xxl-3 hidden-lg-down">
            <div *ngIf="review.comment != null && review.comment.length > 0">
              <div>{{'Comment' | translate}}:</div>
              <a (click)="showComment(review)" class="font-weight-normal">{{review.comment | showFirstSymbols:35}}</a>
            </div>
          </div>

          <div class="col-2 col-xxl-2 text-center hidden-lg-down">
            <app-start-rating *ngIf="review.questionnaireStyle === 1" [score]="review.serviceLevelScore"></app-start-rating>

            <ng-container *ngIf="review.questionnaireStyle !== 1">
              {{ review.serviceLevelScore }}
            </ng-container>
          </div>
          <div class="col-2 col-xxl-2 text-center hidden-lg-down">
            <app-start-rating *ngIf="review.questionnaireStyle === 1" [score]="review.foodLevelScore"></app-start-rating>

            <ng-container *ngIf="review.questionnaireStyle !== 1">
              {{ review.foodLevelScore }}
            </ng-container>
          </div>
          <div class="col-2 col-xxl-2 text-center hidden-lg-down">
            <app-start-rating *ngIf="review.questionnaireStyle === 1" [score]="review.waitingTimeLevelScore"></app-start-rating>

            <ng-container *ngIf="review.questionnaireStyle !== 1">
              {{ review.waitingTimeLevelScore }}
            </ng-container>
          </div>

          <div class="col-8 hidden-xl-up">
            <div>
              {{'Table' | translate}}: <span class="font-weight-normal">{{review.table.name}}</span>
            </div>
            <div class="pd-b-12">
              {{'Area' | translate}}: <span class="font-weight-normal">{{review.table.area.name}}</span>
            </div>

            <div class="score-line row" *ngIf="review.serviceLevelScore != null">
              <div class="score-line-title col-6">{{'Service' | translate}}:</div>
              <div class="score-line-value col-6">
                <app-start-rating *ngIf="review.questionnaireStyle === 1" [score]="review.serviceLevelScore"></app-start-rating>

                <ng-container *ngIf="review.questionnaireStyle !== 1">
                  {{ review.serviceLevelScore }}
                </ng-container>
              </div>
            </div>

            <div class="score-line row" *ngIf="review.foodLevelScore">
              <div class="score-line-title col-6">{{'Food' | translate}}:</div>
              <div class="score-line-value col-6">
                <app-start-rating *ngIf="review.questionnaireStyle === 1" [score]="review.foodLevelScore"></app-start-rating>

                <ng-container *ngIf="review.questionnaireStyle !== 1">
                  {{ review.foodLevelScore }}
                </ng-container>
              </div>
            </div>

            <div class="score-line row" *ngIf="review.waitingTimeLevelScore">
              <div class="score-line-title col-6">{{'Waiting time' | translate}}:</div>
              <div class="score-line-value col-6">
                <app-start-rating *ngIf="review.questionnaireStyle === 1" [score]="review.waitingTimeLevelScore"></app-start-rating>

                <ng-container *ngIf="review.questionnaireStyle !== 1">
                  {{ review.waitingTimeLevelScore }}
                </ng-container>
              </div>
            </div>

            <div *ngIf="review.comment != null && review.comment.length > 0" class="comment-line">
              <div>{{'Comment' | translate}}:</div>
              <a (click)="showComment(review)" class="font-weight-normal">{{review.comment | showFirstSymbols:35}}</a>
            </div>
          </div>

          <div class="col-2 col-xxl-1">

          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="row" *ngIf="listIsLoading$ | async">
      <div class="ht-60 pos-relative align-items-center col-12">
        <owc-progress-wave></owc-progress-wave>
      </div>
    </div>

    <div class="row" *ngIf="listLoadingFailed$ | async">
      <div class="ht-50 pos-relative align-items-center col-12">
        <p class="loading-failed-text">{{'Could not load terminals list. Check if you have Internet connection and try again.' | translate}}</p>
      </div>
    </div>
  </div>
</div>
