<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs" [destroyOnHide]="false">
  <li [ngbNavItem]="'details'">
    <a ngbNavLink>{{'Details' | translate}}</a>
    <ng-template ngbNavContent>
      <app-menu-item-details-tab [formControl]="formDef.getControl('details')"
                                 (nameOrDescriptionChanged)="nameOrDescriptionChanged()"
                                 style="flex: 1 0 auto;">
      </app-menu-item-details-tab>


      <div class="actions pd-b-20">

        <a (click)="navigateToList(true)"
           [class.disabled]="formDef.form.invalid"
           *ngIf="formDef.form.dirty"
           class="btn btn-danger text-white">

          {{'Discard changes & go to list' | translate}}
        </a>

        <a (click)="navigateToList(false)"
           *ngIf="!formDef.form.dirty"
           class="btn btn-light">

          {{'Go to list' | translate}}
        </a>

        <a (click)="save(true)"
           [class.disabled]="formDef.form.invalid"
           *ngIf="formDef.form.dirty"
           class="btn btn-warning text-white">

          {{'Save & go to list' | translate}}
        </a>


        <a (click)="save(false)"
           [class.disabled]="formDef.form.invalid"
           *ngIf="formDef.form.dirty"
           class="btn btn-success text-white">

          {{'Save & stay' | translate}}
        </a>
      </div>

    </ng-template>
  </li>

  <li [ngbNavItem]="'modifiers'">
    <a ngbNavLink>{{'Modifiers' | translate}}</a>
    <ng-template ngbNavContent>
      <app-modifiers-tab [formControl]="formDef.getControl('modifierGroups')"
                         [settings]="tabSettings$ | async">
      </app-modifiers-tab>
    </ng-template>
  </li>

  <li [ngbNavItem]="'ingredients'">
    <a ngbNavLink>{{'Ingredients' | translate}}</a>
    <ng-template ngbNavContent>
      <app-editable-ingredients-list [ingredients]="formDef.getFormArray('ingredients')">
      </app-editable-ingredients-list>
    </ng-template>
  </li>

  <li [ngbNavItem]="'translations'">
    <a ngbNavLink>
      {{'Translations' | translate}}

      <i class="icon-attention-alt-1 name-or-description-changed-icon" *ngIf="indicateNameOrDescriptionChange"></i>
    </a>
    <ng-template ngbNavContent>
      <app-translations-tab [formControl]="formDef.getControl('translations')"
                            [settings]="tabSettings$ | async">
      </app-translations-tab>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav"></div>
