<div class="col-12 col-xxl-2 col-xl-3 col-lg-4 col-md-6">
  <div class="form-group">
    <label class="form-control-label" for="cityId">{{'City' | translate}}: <span class="tx-danger">*</span></label>

    <ng-select [items]="cities$ | async"
               [formControl]="formDef.getControl('cityId')"
               labelForId="cityId"
               bindLabel="name"
               bindValue="id"
               [placeholder]="'Select city' | translate">
    </ng-select>

    <owc-input-validation-errors [control]="formDef.getControl('cityId')"></owc-input-validation-errors>
  </div>
</div>

<div class="col-12 col-xxl-2 col-xl-3 col-lg-4 col-md-6">
  <label class="form-control-label">{{'Zip Code' | translate}} <span class="tx-danger" *ngIf="formDef.getControl('zipCode').enabled">*</span></label>

  <input type="text"
         class="form-control"
         maxlength="8"
         autocomplete="none"
         [formControl]="formDef.getControl('zipCode')"
         [placeholder]="'Zip Code' | translate" />

  <owc-input-validation-errors [control]="formDef.getControl('zipCode')"></owc-input-validation-errors>
</div>

<div class="col-12 col-xxl-8 col-xl-6 col-lg-6 col-md-6">
  <div class="form-group">
    <label class="form-control-label" for="street">{{'Address' | translate}}: <span class="tx-danger">*</span></label>
    <input class="form-control"
           type="text"
           maxlength="50"
           labelForId="street"
           autocomplete="chrome-off"
           [formControl]="formDef.getControl('street')"
           [placeholder]="'Street, block, house number, floor' | translate">

    <owc-input-validation-errors [control]="formDef.getControl('street')"></owc-input-validation-errors>
  </div>
</div>
