import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseOrderlyComponent} from '../../../../../shared/components/base-orderly.component';
import MenuItemModifierGroupWithModifiers = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemModifierGroup.MenuItemModifierGroupWithModifiers;

@Component({
             selector: 'app-modifier-groups-list',
             templateUrl: './modifier-groups-list.component.html',
             styleUrls: ['./modifier-groups-list.component.scss'],
           })
export class ModifierGroupsListComponent extends BaseOrderlyComponent {

  @Input()
  public isDisabled: boolean = false;

  @Input()
  public groups: MenuItemModifierGroupWithModifiers[] = [];


  @Output()
  public readonly deleteGroup = new EventEmitter<number>();

  @Output()
  public readonly requestGroupUpdate = new EventEmitter<number>();

  @Output()
  public readonly requestModifierUpdate = new EventEmitter<{groupIndex: number, modifierIndex: number}>();

  @Output()
  public readonly requestModifierCreate = new EventEmitter<{groupIndex: number}>();


  constructor() {
    super();
  }

  public addNewModifier(index: number): void {
    this.requestModifierCreate.emit({groupIndex: index});
  }

  public updateModifier(i: number, data: { group: MenuItemModifierGroupWithModifiers; modifierIndex: number }): void {
    this.requestModifierUpdate.emit({modifierIndex: data.modifierIndex, groupIndex: i});
  }
}
