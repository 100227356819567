import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class IngredientsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.baseURL = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
    }
    getAllIngredientCategories(restaurantId) {
        const url = `${this.baseURL}/restaurant/${restaurantId}/ingredient-categories/all`;
        const result = this.httpClient.get(url);
        return result;
    }
    getAllIngredients(restaurantId) {
        const url = `${this.baseURL}/restaurant/${restaurantId}/ingredients/all`;
        const result = this.httpClient.get(url);
        return result;
    }
    addIngredient(restaurantId, request) {
        const url = `${this.baseURL}/restaurant/${restaurantId}/ingredient/add`;
        const result = this.httpClient.post(url, request);
        return result;
    }
    updateIngredient(restaurantId, data) {
        const url = `${this.baseURL}/restaurant/${restaurantId}/ingredient/${data.id}/update`;
        const result = this.httpClient.post(url, data);
        return result;
    }
    deleteIngredient(restaurantId, ingredientId) {
        const url = `${this.baseURL}/restaurant/${restaurantId}/ingredient/${ingredientId}/delete`;
        const result = this.httpClient.delete(url);
        return result;
    }
}
IngredientsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IngredientsService_Factory() { return new IngredientsService(i0.ɵɵinject(i1.HttpClient)); }, token: IngredientsService, providedIn: "root" });
