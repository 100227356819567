import {Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {FileUploadValidatorDefinition} from './file-upload-validator.definition';
import {InvalidFileData, NewFileData} from './new-file-data';
import {ExistingFileData} from './existing-file-data';
import {Subject, timer} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

type FileType = NewFileData | ExistingFileData;

@Component({
             selector: 'app-shared-file-upload',
             templateUrl: './file-upload.component.html',
             styleUrls: ['./file-upload.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: FileUploadComponent,
                 multi: true,
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: FileUploadComponent,
                 multi: true
               }
             ],
           })
export class FileUploadComponent implements ControlValueAccessor, Validator, OnDestroy {

  private destroyed$: Subject<void> = new Subject<void>();

  public isDisabled: boolean = false;

  public validFiles: Readonly<FileType>[] = [];

  public invalidFiles: Readonly<InvalidFileData>[] = [];


  @Input()
  public multipleFilesAllowed: boolean = false;

  @Output()
  public fileBlocked: EventEmitter<InvalidFileData> = new EventEmitter<InvalidFileData>();


  @ViewChild('menuFileInput', {static: false})
  public menuFileInput: ElementRef;

  constructor(private readonly fileValidator: FileUploadValidatorDefinition) {
  }


  public get showSelectFileButton(): boolean {
    if (this.isDisabled) {
      return false;
    }

    if (this.multipleFilesAllowed) {
      return true;
    }

    return !(this.invalidFiles.length > 0 || this.validFiles.length > 0);
  }


  validate(control: AbstractControl): ValidationErrors | null {
    if (control.invalid) {
      return {'file-upload': true};
    }

    if (this.invalidFiles.length > 0) {
      const invalidFileNames = this.invalidFiles.map((x) => x.name);

      return {'file-upload': invalidFileNames};
    }

    return null;
  }

  onChange: any = (value: Readonly<NewFileData | ExistingFileData>[]) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: Readonly<NewFileData | ExistingFileData> | Readonly<NewFileData | ExistingFileData>[] | null): void {
    if (value == null) {
      this.validFiles = [];
    } else if (Array.isArray(value)) {
      this.validFiles = value;
    } else {
      this.validFiles = [value];
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  changed(newValue: Readonly<FileType>[]) {
    this.onTouched();
    this.onChange(newValue);
  }


  public removeMenuFile(index: number) {
    // should work for most modern browsers
    this.menuFileInput.nativeElement.value = null;

    if (this.menuFileInput.nativeElement.files.length > 0) {

      // a hack for other browsers
      const emptyFile = document.createElement('input');
      emptyFile.type = 'file';

      this.menuFileInput.nativeElement.files = emptyFile.files;
    }

    const arr = [...this.validFiles];

    arr.splice(index, 1);

    this.validFiles = arr;

    this.changed(arr);
  }

  public onFileChange(event) {

    if (event.target.files && event.target.files.length) {

      const previewImageFiles = event.target.files;

      if (this.fileValidator.validate(previewImageFiles[0])) {
        const reader = new FileReader();

        reader.onload = () => {
          const arr = [...this.validFiles];

          arr.push(new NewFileData(previewImageFiles[0].name, reader.result as string));

          this.changed(arr);

          this.validFiles = arr;
        };

        reader.readAsDataURL(previewImageFiles[0]);
      } else {
        const arr = [...this.invalidFiles];
        const blockedFile = new InvalidFileData(previewImageFiles[0].name, previewImageFiles[0]);

        arr.push(blockedFile);

        this.invalidFiles = arr;

        this.fileBlocked.emit(blockedFile);

        timer(2000)
          .pipe(
            tap(() => {

              const removedFileIdx = this.invalidFiles.indexOf(blockedFile);

              if (removedFileIdx >= 0) {
                const reducedArr = [...this.invalidFiles];

                reducedArr.splice(removedFileIdx, 1);

                this.invalidFiles = reducedArr;

                this.changed(this.validFiles);
              }
            }),
            takeUntil(this.destroyed$)
          )
          .subscribe();
      }
    }
  }
}
