import {Action} from '@ngrx/store';
import {ActionTypes} from './action-types';
import RestaurantInfoWithSettingsAndContactDetails = Orderly.RestaurantWeb.Api.Messages.RestaurantInfoWithSettingsAndContactDetails;

export class RestaurantSuccessfullyCreatedAction implements Action {
  readonly type = ActionTypes.RESTAURANT_CREATE_SUCCESSFUL;

  constructor(public readonly restaurant: Readonly<RestaurantInfoWithSettingsAndContactDetails>) {
  }
}

export class RestaurantSuccessfullyUpdatedAction implements Action {
  readonly type = ActionTypes.RESTAURANT_UPDATE_SUCCESSFUL;

  constructor(public readonly restaurant: Readonly<RestaurantInfoWithSettingsAndContactDetails>) {
  }
}

export class RestaurantOnboardingCompletedAction implements Action {
  readonly type = ActionTypes.RESTAURANT_ONBOARDING_COMPLETED;

  constructor(public readonly restaurantHoldingId: number) {
  }
}
