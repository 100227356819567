import {Component, Input} from '@angular/core';
import {ImageContentBase64Definition, ImageUrlDefinition} from './image-content.definition';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-preview-modal',
  templateUrl: './image-preview-modal.component.html',
  styleUrls: ['./image-preview-modal.component.scss']
})
export class ImagePreviewModalComponent {

  @Input()
  public base64ContentOrUrl: ImageUrlDefinition | ImageContentBase64Definition;


  public get src(): string {

    if (this.base64ContentOrUrl instanceof ImageUrlDefinition) {
      return this.base64ContentOrUrl.url;
    }

    return this.base64ContentOrUrl.base64Content
  }

  constructor(private readonly activeModal: NgbActiveModal) {
  }

  public close(): void {
    this.activeModal.close();
  }
}
