import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {filter, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {CategoryDetailsFieldsDefinition} from '../details-tab/category-details-fields.definition';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../../util/form.utils';
import {CategoryTranslationsFormFieldsDefinition} from './category-translations-form-fields.definition';
import {ActivatedRoute} from '@angular/router';
import {RestaurantComponent} from '../../../../../restaurant.component';

@Component({
             selector: 'app-menu-item-category-translations-tab',
             templateUrl: './translations-tab.component.html',
             styleUrls: ['./translations-tab.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: TranslationsTabComponent,
                 multi: true,
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: TranslationsTabComponent,
                 multi: true
               },
             ],
           })
export class TranslationsTabComponent extends RestaurantComponent implements ControlValueAccessor, Validator {

  private initInProgress: boolean = false;

  public translationLanguages$: Observable<Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Language[]>;

  public primaryLanguage$: Observable<Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Language>;

  public formDef: FormDefinition<keyof CategoryTranslationsFormFieldsDefinition>;


  constructor(store: Store<AppState>,
              formBuilder: FormBuilder,
              activatedRoute: ActivatedRoute) {

    super(store, activatedRoute);

    this.translationLanguages$ = this.currentRestaurant$
                                     .pipe(
                                       map(x => x.settings.enabledMenuLanguages),
                                     );

    this.primaryLanguage$ = this.currentRestaurant$
                                .pipe(
                                  map(x => x.settings.defaultMenuLanguage),
                                );

    const fieldsDef: FormFieldsDefinition<keyof CategoryTranslationsFormFieldsDefinition> = {
      name: new FormFieldDefinition([],
                                       false,
                                       [],
                                       []),
      description: new FormFieldDefinition([],
                                            false,
                                            [],
                                            []),
    };

    this.formDef = new FormDefinition<keyof CategoryTranslationsFormFieldsDefinition>(fieldsDef, formBuilder);

    this.registerForAutoDestroy(
      this.formDef
          .form
          .valueChanges
          .pipe(
            filter(() => !this.initInProgress),
            tap((x: CategoryTranslationsFormFieldsDefinition) => {
              this.onChange(x);
            }),
          )
          .subscribe()
    );
  }

  onChange: any = (value: CategoryTranslationsFormFieldsDefinition) => {
  };

  onTouched: any = () => {
  };


  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: CategoryDetailsFieldsDefinition | null): void {
    if (value == null) {
      return;
    }

    this.initInProgress = true;

    this.formDef.form.patchValue(value, {emitEvent: false});

    this.initInProgress = false;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formDef.disable('all-fields');
    } else {
      this.formDef.enable('all-fields', false);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.formDef.form.invalid) {
      return {'translations-tab': true};
    }

    return null;
  }

}
