/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./crop-image-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i4 from "ngx-image-cropper";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../../dist/orderly-web-components/orderly-web-components.ngfactory";
import * as i7 from "orderly-web-components";
import * as i8 from "./crop-image-modal.component";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
var styles_CropImageModalComponent = [i0.styles];
var RenderType_CropImageModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CropImageModalComponent, data: {} });
export { RenderType_CropImageModalComponent as RenderType_CropImageModalComponent };
export function View_CropImageModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-header pd-y-20 pd-x-25"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h6", [["class", "tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-body pd-0-force"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "image-cropper", [["format", "jpeg"]], [[4, "text-align", null], [2, "disabled", null]], [[null, "imageCropped"], [null, "imageLoaded"], [null, "loadImageFailed"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("imageCropped" === en)) {
        var pd_5 = (_co.imageCropped($event) !== false);
        ad = (pd_5 && ad);
    } if (("imageLoaded" === en)) {
        var pd_6 = (_co.imageLoaded() !== false);
        ad = (pd_6 && ad);
    } if (("loadImageFailed" === en)) {
        var pd_7 = (_co.imageLoaded() !== false);
        ad = (pd_7 && ad);
    } return ad; }, i3.View_ImageCropperComponent_0, i3.RenderType_ImageCropperComponent)), i1.ɵdid(6, 638976, null, 0, i4.ImageCropperComponent, [i5.DomSanitizer, i1.ChangeDetectorRef], { imageBase64: [0, "imageBase64"], format: [1, "format"], maintainAspectRatio: [2, "maintainAspectRatio"], aspectRatio: [3, "aspectRatio"], cropperMinWidth: [4, "cropperMinWidth"], cropperMinHeight: [5, "cropperMinHeight"], containWithinAspectRatio: [6, "containWithinAspectRatio"] }, { imageCropped: "imageCropped", imageLoaded: "imageLoaded", loadImageFailed: "loadImageFailed" }), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "modal-footer tx-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "owc-progress-action-button", [["type", "link"]], [[2, "d-block", null]], [[null, "execute"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.finishEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ProgressActionButtonComponent_0, i6.RenderType_ProgressActionButtonComponent)), i1.ɵdid(9, 114688, null, 0, i7.ProgressActionButtonComponent, [], { disabled: [0, "disabled"], actionInProgress: [1, "actionInProgress"], btnText: [2, "btnText"], type: [3, "type"] }, { execute: "execute" }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.base64ImageContent; var currVal_4 = "jpeg"; var currVal_5 = true; var currVal_6 = 1; var currVal_7 = 200; var currVal_8 = 200; var currVal_9 = false; _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_11 = false; var currVal_12 = _co.actionInProgress; var currVal_13 = i1.ɵunv(_v, 9, 2, i1.ɵnov(_v, 10).transform("Save")); var currVal_14 = "link"; _ck(_v, 9, 0, currVal_11, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Edit image")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).alignImage; var currVal_2 = i1.ɵnov(_v, 6).disabled; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_10 = i1.ɵnov(_v, 9).displayHostAsBlockElement; _ck(_v, 8, 0, currVal_10); }); }
export function View_CropImageModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-crop-image-modal", [], null, null, null, View_CropImageModalComponent_0, RenderType_CropImageModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.CropImageModalComponent, [i9.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CropImageModalComponentNgFactory = i1.ɵccf("app-crop-image-modal", i8.CropImageModalComponent, View_CropImageModalComponent_Host_0, { base64ImageContent: "base64ImageContent" }, {}, []);
export { CropImageModalComponentNgFactory as CropImageModalComponentNgFactory };
