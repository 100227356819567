import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {isNotNullOrEmptyOrWhiteSpaceValidator} from 'projects/orderly-web-components/src/lib/validators';
import {RestaurantService} from '../../services/restaurant.service';
import {
  ImageFileTypeValidator,
  Language,
  NeverError,
  StaticDataService,
  ToastService,
} from 'orderly-web-components';
import {catchError, finalize, first, flatMap, map, takeUntil, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {genericErrorHandlerWithToast, nameof} from '../../util/utils';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../util/form.utils';
import StatusDef = Orderly.RestaurantWeb.Api.Messages.Restaurant.RegisterRestaurantHoldingSimpleWithPdfMenuResponse.StatusDef;
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';
import {
  RestaurantHoldingRegistrationWithMenuModel
} from './helper.models';
import {RegistrationBaseComponent} from './registration-base.component';
import RegisterRestaurantHoldingSimpleWithPdfMenuResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.RegisterRestaurantHoldingSimpleWithPdfMenuResponse;
import RegisterRestaurantHoldingSimpleWithPdfMenuRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.RegisterRestaurantHoldingSimpleWithPdfMenuRequest;

@Component({
             selector: 'app-registration-with-menu',
             templateUrl: './registration-with-menu.component.html',
             styleUrls: ['./registration.component.scss']
           })
export class RegistrationWithMenuComponent extends RegistrationBaseComponent implements OnInit {

  @ViewChild('menuFileInput', {static: false})
  public menuFileInput: ElementRef;

  public formDef: FormDefinition<keyof RestaurantHoldingRegistrationWithMenuModel>;

  public registrationInProgress: boolean = false;

  public isCompleted: boolean = false;


  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private restaurantService: RestaurantService,
              private toastService: ToastService,
              trnService: TranslateService,
              staticDataService: StaticDataService) {

    super(staticDataService, trnService);

    this.initForm();
  }


  private initForm() {
    const fieldsDef: FormFieldsDefinition<keyof RestaurantHoldingRegistrationWithMenuModel> = {
      name: new FormFieldDefinition('',
                                    false,
                                    [
                                      isNotNullOrEmptyOrWhiteSpaceValidator, Validators.minLength(3),
                                      Validators.maxLength(50)
                                    ],
                                    [nameof<RegisterRestaurantHoldingSimpleWithPdfMenuRequest>('name')]),
      email: new FormFieldDefinition('',
                                     false,
                                     [Validators.required, Validators.email, Validators.maxLength(120)],
                                     [nameof<RegisterRestaurantHoldingSimpleWithPdfMenuRequest>('ownerEmail')]),
      menuFileName: new FormFieldDefinition(null,
                                            false,
                                            [Validators.required],
                                            []),
      menuFileContentBase64: new FormFieldDefinition(null,
                                                     false,
                                                     [Validators.required],
                                                     []),
      acceptTerms: new FormFieldDefinition(null,
                                           false,
                                           [Validators.requiredTrue],
                                           []),
      languageCode2: new FormFieldDefinition(null,
                                             false,
                                             [Validators.required],
                                             []),
    };

    this.formDef = new FormDefinition<keyof RestaurantHoldingRegistrationWithMenuModel>(fieldsDef, this.formBuilder);

    this.formDef
        .getControl('languageCode2')
        .valueChanges
        .pipe(
          takeUntil(this.destroyed$),
          tap((langCode2: string | null) => {
            this.languageChanged(langCode2);
          })
        )
        .subscribe();
  }

  ngOnInit() {
    this.activatedRoute
        .queryParamMap
        .pipe(
          flatMap(params => {
            const preselectedLangCode2 = (params.get('lang') || this.trnService.currentLang || 'en').toLowerCase();

            if (preselectedLangCode2 == null || preselectedLangCode2.length !== 2) {
              return of(null);
            }

            return this.languages$
                       .pipe(
                         map(langs => {
                           const matchedLangs = langs.filter(l => l.code.toLowerCase() === preselectedLangCode2);

                           return matchedLangs.length >= 1 ? matchedLangs[0] : null;
                         })
                       );
          }),
          tap((lang: null | Language) => {
            if (lang == null) {
              return;
            }

            const langControl = this.formDef.getControl('languageCode2');

            if (langControl.value !== lang.id) {
              this.formDef.getControl('languageCode2').setValue(lang.id);
            }

            if (this.trnService.currentLang !== lang.code) {
              this.trnService.use(lang.code);
            }
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe();
  }

  registerRestaurantHolding() {
    if (this.formDef.form.invalid) {
      return;
    }

    this.registrationInProgress = true;

    const model: RestaurantHoldingRegistrationWithMenuModel = this.formDef.form.value;

    this.doRegister(model)
        .pipe(
          first(),
          finalize(() => {
            this.registrationInProgress = false;
          })
        )
        .subscribe();
  }

  private doRegister(model: RestaurantHoldingRegistrationWithMenuModel): Observable<RegisterRestaurantHoldingSimpleWithPdfMenuResponse | null> {
    const disableToken = this.formDef.disable('all-fields');
    const unexpectedServerFailureMsg = _(
      'Failed to register your restaurant. Check your internet connection and try again.');

    return this.restaurantService
               .registerHoldingWithMenu(model)
               .pipe(
                 tap((response: RegisterRestaurantHoldingSimpleWithPdfMenuResponse) => {
                   // required to set the errors
                   disableToken.reenable();

                   switch (response.status) {
                     case StatusDef.ValidationFailed:
                       this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
                       break;
                     case StatusDef.UnexpectedException:
                       const unexpectedExceptionError = 'Failed to register your restaurant because of unexpected error. Please try again.';
                       this.toastService.showError(unexpectedExceptionError);
                       break;
                     case StatusDef.Success:
                       this.isCompleted = true;
                       break;
                     default:
                       throw new NeverError(response.status);
                   }
                 }),
                 catchError(
                   genericErrorHandlerWithToast<RegisterRestaurantHoldingSimpleWithPdfMenuResponse>(this.toastService, this.trnService,
                                                                                   unexpectedServerFailureMsg)
                 ),
                 finalize(() => {
                   disableToken.reenable();
                 })
               );
  }

  private languageChanged(code2: string | null) {
    let langCode2 = this.trnService.defaultLang.toLowerCase();

    if (code2 != null) {
      langCode2 = code2.toLowerCase();
    }

    if (this.trnService.currentLang == null && langCode2 === this.trnService.defaultLang.toLowerCase()) {
      return;
    } else if (this.trnService.currentLang != null) {
      if (langCode2 === this.trnService.currentLang.toLowerCase()) {
        return;
      }
    }

    this.router.navigate([], {queryParams: {lang: langCode2}});
  }

  public onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const previewImageFiles = event.target.files;

      reader.readAsDataURL(previewImageFiles[0]);

      reader.onload = () => {
        const selectedFileName = previewImageFiles[0].name;

        if (ImageFileTypeValidator.validatePdfFileExtension(selectedFileName)) {

          this.formDef.patchValue({
                                    menuFileContentBase64: reader.result,
                                    menuFileName: previewImageFiles[0].name
                                  });
        } else {
          this.formDef.patchValue({
                                    menuFileContentBase64: null,
                                    menuFileName: previewImageFiles[0].name
                                  });
        }
      };
    }
  }

  public clearMenuFile() {
    // should work for most modern browsers
    this.menuFileInput.nativeElement.value = null;

    if (this.menuFileInput.nativeElement.files.length > 0) {

      // a hack for other browsers
      const emptyFile = document.createElement('input');
      emptyFile.type = 'file';

      this.menuFileInput.nativeElement.files = emptyFile.files;
    }

    this.formDef.patchValue({
                              menuFileContentBase64: null,
                              menuFileName: null
                            });
  }
}
