<div class="modal-header pd-y-20 pd-x-25">
    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{cfg.modalTitle}}</h6>
    <button type="button" class="close" [attr.aria-label]="'Close'" (click)="activeModal.dismiss(null)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pd-25">

    <form [formGroup]="form" (ngSubmit)="save()">

      <div class="row">
        <div class="form-layout form-layout-1" style="width: 100%;">

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label">{{cfg.oldPasswordLabel}}: <span class="tx-danger">*</span></label>
                <input class="form-control" type="password" formControlName="oldPassword">

                <owc-input-validation-errors [control]="f.oldPassword"></owc-input-validation-errors>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label">{{cfg.newPasswordLabel}}: <span class="tx-danger">*</span></label>
                <input class="form-control" type="password" formControlName="newPassword" maxlength="20">

                <owc-input-validation-errors [control]="f.newPassword"></owc-input-validation-errors>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label">{{cfg.newPasswordConfirmationLabel}}: <span class="tx-danger">*</span></label>
                <input class="form-control" type="password" formControlName="newPasswordConfirmation" maxlength="20">

                <owc-input-validation-errors [control]="f.newPasswordConfirmation"></owc-input-validation-errors>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer tx-white">
    <owc-progress-action-button [actionInProgress]="actionInProgress | async"
                                [disabled]="form.invalid"
                                (execute)="save()"
                                [btnText]="cfg.changeButtonText"
                                type='link'>
    </owc-progress-action-button>
  </div>
