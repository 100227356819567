import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';

import {
  getAuthenticatedUserIsRestaurantManagerSelector,
  getAuthenticatedUserSelector
} from '../store/selectors/restaurant-selectors';
import {first, map, switchMap} from 'rxjs/operators';
import {RestaurantComponent} from '../restaurant.component';
import {RestaurantService} from '../../services/restaurant.service';
import RestaurantWithStatistics = Orderly.RestaurantWeb.Api.Messages.Restaurant.RestaurantWithStatistics;

@Component({
             selector: 'app-dashboard',
             templateUrl: './dashboard.component.html',
             styleUrls: ['./dashboard.component.scss']
           })
export class DashboardComponent extends RestaurantComponent {

  public userIsManager: Observable<boolean>;
  public showOnboardingWizard$: Observable<boolean>;
  public currentRestaurantWithStats$: Observable<RestaurantWithStatistics>;

  constructor(activatedRoute: ActivatedRoute,
              store: Store<AppState>,
              private readonly restaurantService: RestaurantService) {

    super(store, activatedRoute);

    this.userIsManager = this.store.select(getAuthenticatedUserIsRestaurantManagerSelector);
    this.showOnboardingWizard$ = this.store
                                     .select(getAuthenticatedUserSelector)
                                     .pipe(
                                       // First is required to prevent immediate hide of wizard after table was created.
                                       // User has to read last notes as well.
                                       first(),
                                       map(x => x == null ? false : !x.holdingInfo.isOnboardingCompleted)
                                     );

    this.currentRestaurantWithStats$ = this.restaurantIdParam$
                                           .pipe(
                                             switchMap(rid => {
                                               return this.restaurantService.getRestaurant(rid);
                                             }),
                                             map(x => x.info)
                                           );
  }
}
