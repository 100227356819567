<ng-select [items]="languages$ | async"

           [clearable]="false"
           [multiple]="true"
           [disabled]="isDisabled"
           [searchable]="false"
           [ngModel]="lastValue"
           (ngModelChange)="changed($event)"
           [compareWith]="compareLanguages"

           bindLabel="name">

  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <span class="ng-value-label">
      <span [className]="'flag flag-' + item.code.toLocaleLowerCase()"></span> {{item.name}}
    </span>
    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
    <span [className]="'flag flag-' + item.code.toLocaleLowerCase()"></span> {{item.name}}
  </ng-template>

</ng-select>
