import {Component, OnDestroy} from '@angular/core';
import {ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../../util/form.utils';
import {KioskSettingsFormFieldsDefinition} from './kiosk-settings-form-fields.definition';
import {filter, takeUntil, tap} from 'rxjs/operators';

@Component({
             selector: 'app-kiosk-settings',
             templateUrl: './kiosk-settings.component.html',
             styleUrls: ['./kiosk-settings.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: KioskSettingsComponent,
                 multi: true
               }
             ]
           })
export class KioskSettingsComponent implements ControlValueAccessor, OnDestroy {

  private readonly destroyed$: Subject<any> = new Subject<any>();

  private initInProgress: boolean = false;

  public formDef: FormDefinition<keyof KioskSettingsFormFieldsDefinition>;

  constructor(private readonly formBuilder: FormBuilder) {

    const fieldsDef: FormFieldsDefinition<keyof KioskSettingsFormFieldsDefinition> = {
      menuCategoriesListStyle: new FormFieldDefinition(null,
                                                    false,
                                                    [Validators.required, Validators.min(0)],
                                                    []),
      guestsCanMakeOrders: new FormFieldDefinition(false,
                                              true,
                                              [Validators.required],
                                              []),
      callWaiterEnabled: new FormFieldDefinition(false,
                                              true,
                                              [Validators.required],
                                              []),
      selfOrderingKiosksEnabled: new FormFieldDefinition(false,
                                                         false,
                                                         [Validators.required],
                                                         []),
    };

    this.formDef = new FormDefinition<keyof KioskSettingsFormFieldsDefinition>(fieldsDef, this.formBuilder);

    this.formDef
        .form
        .valueChanges
        .pipe(
          filter(() => !this.initInProgress),
          tap((x: KioskSettingsFormFieldsDefinition) => {
            this.onChange(x);
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();

  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: KioskSettingsFormFieldsDefinition) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: KioskSettingsFormFieldsDefinition | null): void {
    if (value == null) {
      return;
    }

    this.initInProgress = true;

    this.formDef.patchValue(value, false);

    if (value.selfOrderingKiosksEnabled) {
      this.formDef.enable('all-fields');
    } else {
      this.formDef.disable('all-fields');
    }

    this.initInProgress = false;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formDef.disable('all-fields');
    } else {
      this.formDef.enable(['menuCategoriesListStyle'], false);
    }
  }
}
