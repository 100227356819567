import {Component} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {Observable} from 'rxjs';
import {
  AddOrEditIngredientComponent
} from '../../add-or-edit-ingredient/add-or-edit-ingredient.component';
import {
  NeverError,
  StaticDataService,
  ToastService
} from 'orderly-web-components';
import {catchError, first, map, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {CurrentRestaurantIngredientsService} from '../../../services/active-route-bound/current-restaurant-ingredients.service';
import RestaurantIngredientBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
import DeleteIngredientResponseStatusDef = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.DeleteIngredientResponse.StatusDef;
import {DeleteModalUtils} from '../../../util/delete-modal.utils';
import {IngredientsService} from '../../../services/ingredients.service';
import IngredientCategory = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddOrUpdateIngredientCategoryResponse.IngredientCategory;
import {SearchFiltersDefinition} from './search-filters-definition';
import Allergen = Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Allergen;
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../util/form.utils';
import {genericErrorHandlerWithToast} from '../../../util/utils';
import {FormBuilder, Validators} from '@angular/forms';
import {AddOrEditIngredientCategoryComponent} from '../../add-or-edit-ingredient-category/add-or-edit-ingredient-category.component';
import {getTranslatedAllergens} from '../../../util/trn.utils';
import {TranslateService} from '@ngx-translate/core';
import {CurrentRestaurantIngredientCategoriesService} from '../../../services/active-route-bound/current-restaurant-ingredient-categories.service';
import {RestaurantRelatedItemsGrid} from '../restaurant-related-items-grid.component';


type SearchFormFields = 'allergens' | 'category';

@Component({
             selector: 'app-ingredient-list',
             templateUrl: './ingredient-list.component.html',
             styleUrls: ['./ingredient-list.component.scss'],
           })
export class IngredientListComponent extends RestaurantRelatedItemsGrid<RestaurantIngredientBasic, SearchFiltersDefinition> {

  static initialFiltersState: SearchFiltersDefinition = {itemsPerPage: 10, currentPage: 1, category: null, allergens: []};

  public searchFiltersFormDef: FormDefinition<SearchFormFields>;

  public categories$: Observable<IngredientCategory[]>;
  public allergens$: Observable<Allergen[]>;


  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private formBuilder: FormBuilder,
              private toastService: ToastService,
              private modalService: NgbModal,
              private deleteModalUtils: DeleteModalUtils,
              private currentRestaurantIngredientsService: CurrentRestaurantIngredientsService,
              private currentRestaurantIngredientCategoriesService: CurrentRestaurantIngredientCategoriesService,
              private ingredientsService: IngredientsService,
              private trnService: TranslateService,
              private staticDataService: StaticDataService) {

    super(store, activatedRoute, IngredientListComponent.initialFiltersState, currentRestaurantIngredientsService.currentRestaurantIngredients$);

    this.categories$ = this.currentRestaurantIngredientCategoriesService
                           .currentRestaurantCategories$
                           .pipe(
                             map(x => x.items.sort((a, b) => a.name.localeCompare(b.name)))
                           );
    this.allergens$ = getTranslatedAllergens(this.staticDataService, this.trnService);

    const fieldsDef: FormFieldsDefinition<SearchFormFields> = {
      allergens: new FormFieldDefinition(IngredientListComponent.initialFiltersState.allergens, false, [], []),
      category: new FormFieldDefinition(IngredientListComponent.initialFiltersState.category, false, [Validators.required], [])
    };

    this.searchFiltersFormDef = new FormDefinition<SearchFormFields>(fieldsDef, this.formBuilder);

    const categoryCtrl = this.searchFiltersFormDef.getControl('category');
    const allergensCtrl = this.searchFiltersFormDef.getControl('allergens');

    this.searchFiltersFormDef
        .form
        .valueChanges
        .pipe(
          switchMap(formValue => this.filtersState$),
          tap((currentFiltersState: SearchFiltersDefinition) => {
            const currentFiltersStateClone = {...currentFiltersState};
            const selectedCategory = categoryCtrl.value;
            const allergens = allergensCtrl.value;

            currentFiltersStateClone.category = selectedCategory;
            currentFiltersStateClone.allergens = allergens;
            currentFiltersStateClone.currentPage = 1;

            if (!this.compareSearchFilters(currentFiltersState, currentFiltersStateClone)) {
              this.updateSearchFiltersState(currentFiltersStateClone);
            }
          })
        )
        .subscribe();
  }

  protected compareSearchFilters(firstValue: SearchFiltersDefinition, secondValue: SearchFiltersDefinition): boolean {
    const basicComparison = firstValue.currentPage === secondValue.currentPage &&
                            firstValue.itemsPerPage === secondValue.itemsPerPage;

    if (!basicComparison) {
      return false;
    }

    if (firstValue.category == null && secondValue.category != null ||
        firstValue.category != null && secondValue.category == null) {

      return false;
    }

    if (firstValue.category != null &&
        secondValue.category != null &&
        firstValue.category.id !== secondValue.category.id) {

      return false;
    }

    if (firstValue.allergens.length !== secondValue.allergens.length) {
      return false;
    }

    const firstAllergenCodes = firstValue.allergens.map(x => x.code.toLowerCase());
    const secondAllergenCodes = secondValue.allergens.map(x => x.code.toLowerCase());
    const allValues = [...firstAllergenCodes, ...secondAllergenCodes];

    const distinct = (value: string, index: number, arr: string[]) => {
      return arr.indexOf(value) === index;
    };

    const uniqueCodes = allValues.filter(distinct);

    return firstValue.allergens.length === uniqueCodes.length &&
           secondValue.allergens.length === uniqueCodes.length;
  }

  protected preFilterServiceItems(items: RestaurantIngredientBasic[]): RestaurantIngredientBasic[] {
    const selectedCategory = this.searchFiltersFormDef.getControl('category').value;
    const allergens = this.searchFiltersFormDef.getControl('allergens').value;

    if (selectedCategory != null) {
      items = items.filter(i => i.category.id === selectedCategory.id);
    }

    if (allergens.length > 0) {
      for (const selectedAllergen of allergens) {
        items = items.filter(i => i.allergens.find(a => a.code === selectedAllergen.code) != null);
      }
    }

    return items;
  }

  public addIngredient() {
    this.restaurantIdParam$
        .pipe(
          first(),
          tap(restaurantId => {
            const modalRef = this.modalService.open(AddOrEditIngredientComponent, {centered: true, size: 'xl'});
            const componentInstance: AddOrEditIngredientComponent = modalRef.componentInstance;

            componentInstance.restaurantId = restaurantId;

            componentInstance.completed
                             .pipe(
                               first(),
                               tap(x => this.refreshIngredientsList())
                             )
                             .subscribe();
          })
        )
      .subscribe();
  }

  // TODO: specify type
  public updateIngredient(ingredient: RestaurantIngredientBasic) {
    this.restaurantIdParam$
        .pipe(
          first(),
          tap(restaurantId => {
            const modalRef = this.modalService.open(AddOrEditIngredientComponent, {centered: true, size: 'xl'});
            const componentInstance: AddOrEditIngredientComponent = modalRef.componentInstance;

            componentInstance.restaurantId = restaurantId;
            componentInstance.ingredientToEdit = ingredient;

            componentInstance.completed
                             .pipe(
                               first(),
                               tap((x) => {
                                 ingredient.measureUnit = x.measureUnit;
                                 ingredient.name = x.name;
                                 ingredient.containsAlcohol = x.containsAlcohol;
                                 ingredient.allergens = x.allergens;
                                 ingredient.category = x.category;
                               })
                             )
                             .subscribe();
          })
        )
        .subscribe();
  }

  public refreshIngredientsList() {
    this.currentRestaurantIngredientsService
        .forceReload()
        .pipe(
          first(),
          switchMap(x => this.filtersState$),
          tap(currentFilterState => {
            const currentFilterStateCloned = {...currentFilterState};

            currentFilterStateCloned.currentPage = 1;

            this.pagingFiltersChanged(currentFilterStateCloned);
          }),
          first()
        )
        .subscribe();
  }

  public deleteIngredient(ingredient: RestaurantIngredientBasic) {
    const onDelete = (modalRef: NgbModalRef) => {

      const unexpectedErrorText = this.trnService.instant('Failed to delete an ingredient because of unexpected error.');

      this.restaurantIdParam$
          .pipe(
            first(),
            switchMap(rid => this.ingredientsService.deleteIngredient(rid, ingredient.id)),
            tap(x => {
              switch (x.status) {
                case DeleteIngredientResponseStatusDef.Success:

                  this.currentRestaurantIngredientsService.forceReload();

                  modalRef.close();
                  break;
                case DeleteIngredientResponseStatusDef.UnknownFailure:
                  this.toastService.showError(unexpectedErrorText);
                  break;
                case DeleteIngredientResponseStatusDef.DependenciesExist:
                  const dependenciesErrorText = this.trnService.instant(
                    'Failed to delete an ingredient because of dependent records. ' +
                    'There are [{{menuItemsCount}}] menu items and [{{menuItemModifiersCount}}] modifiers.',
                    {
                      menuItemsCount: x.dependencies.menuItemsCount,
                      menuItemModifiersCount: x.dependencies.menuItemModifiersCount
                    });

                  this.toastService.showError(dependenciesErrorText);
                  break;
                default:
                  throw new NeverError(x.status);
              }
            }),
            catchError(
              genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedErrorText)
            )
          )
          .subscribe();
    };

    const modalTitle = this.trnService.instant(`Delete ingredient`);
    const modalConfirmText = this.trnService.instant(`Do you want to delete ingredient '{{name}}'?`, {name: ingredient.name});

    this.deleteModalUtils.showDeleteModal(ingredient, modalTitle, [modalConfirmText], onDelete);
  }

  public addIngredientCategory() {
    this.modalService.open(AddOrEditIngredientCategoryComponent, {centered: true, size: 'xl'});
  }
}
