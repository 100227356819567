import { OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { NeverError } from 'orderly-web-components';
import { ReplaySubject } from 'rxjs';
import { RestaurantSpecificArrayResultLoadingState, RestaurantSpecificResultLoadingStatusDefinition } from '../../../../services/active-route-bound/helper.statuses';
import { TablesListGridItem } from '../components/tables-list/helper.types';
import * as i0 from "@angular/core";
import * as i1 from "../../../../services/active-route-bound/current-restaurant-tables.service";
export class GridItemsSourceService {
    constructor(restaurantTableService) {
        this.restaurantTableService = restaurantTableService;
        this.cache$ = new ReplaySubject(1);
        restaurantTableService.currentRestaurantTables$
            .pipe(map((x) => {
            switch (x.loadingStatus) {
                case RestaurantSpecificResultLoadingStatusDefinition.SUCCESS:
                    const gridItems = x.items.map(table => new TablesListGridItem(table));
                    return new RestaurantSpecificArrayResultLoadingState(x.loadingStatus, gridItems, x.error);
                case RestaurantSpecificResultLoadingStatusDefinition.FAILED:
                case RestaurantSpecificResultLoadingStatusDefinition.CLEARED_BECAUSE_NO_RESTAURANT_ID:
                case RestaurantSpecificResultLoadingStatusDefinition.LOADING:
                case RestaurantSpecificResultLoadingStatusDefinition.NOT_STARTED:
                    return new RestaurantSpecificArrayResultLoadingState(x.loadingStatus, [], x.error);
                default:
                    throw new NeverError(x.loadingStatus);
            }
        }))
            .subscribe(this.cache$);
    }
    get items$() {
        return this.cache$;
    }
    forceReload() {
        return this.restaurantTableService.forceReload();
    }
    add(data) {
        return this.restaurantTableService.add(data);
    }
    delete(tableId) {
        return this.restaurantTableService.delete(tableId);
    }
    generateNewQrCode(tableId) {
        return this.restaurantTableService.generateNewQrCode(tableId);
    }
    getTableQrCodeAsBase64Image(tableId) {
        return this.restaurantTableService.getTableQrCodeAsBase64Image(tableId);
    }
    downloadTableQrCodeAsPdf(tableId) {
        return this.restaurantTableService.downloadTableQrCodeAsPdf(tableId);
    }
    downloadTableQrCodeAsImage(tableId) {
        return this.restaurantTableService.downloadTableQrCodeAsImage(tableId);
    }
    downloadTableQrCodesAsStickersImage(tableIdList) {
        return this.restaurantTableService.downloadTableQrCodesAsStickersImage(tableIdList);
    }
    ngOnDestroy() {
        this.cache$.complete();
    }
}
GridItemsSourceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GridItemsSourceService_Factory() { return new GridItemsSourceService(i0.ɵɵinject(i1.CurrentRestaurantTablesService)); }, token: GridItemsSourceService, providedIn: "root" });
