<ng-select [items]="qrMenuButtonActions$ | async"

           [disabled]="isDisabled"
           [clearable]="false"
           [searchable]="false"
           [ngModel]="lastValue"
           (ngModelChange)="changed($event)"

           bindLabel="name"
           bindValue="value">
</ng-select>
