import * as jwt_decode from "jwt-decode";

export class LocalStorageService {
  private static AUTH_USER_KEY = 'AUTH_USER';

  public static setCurrentUser(
    jwtTokenBase64: string,
    user: Orderly.RestaurantWeb.Api.Messages.UserWithRestaurantsInfo): Readonly<AuthenticatedUserModel> {
    const authModel: AuthenticatedUserModel = new AuthenticatedUserModel(jwtTokenBase64, user);
    const json = JSON.stringify(authModel);

    localStorage.setItem(LocalStorageService.AUTH_USER_KEY, json);

    return authModel;
  }

  public static getCurrentUser(): Readonly<AuthenticatedUserModel> | null {
    const json = localStorage.getItem(LocalStorageService.AUTH_USER_KEY);

    if (!json) {
      return null;
    }

    const authModel: AuthenticatedUserModel = JSON.parse(json);

    return authModel;
  }

  public static removeCurrentUser() {
    localStorage.removeItem(LocalStorageService.AUTH_USER_KEY);
  }
}

export class AuthenticatedUserModel {
  public static LAST_COMPATIBLE_DEFINITION_VERSION: number = 1;
  public readonly CURRENT_DEFINITION_VERSION: number | null;

  public readonly user: Readonly<Orderly.RestaurantWeb.Api.Messages.UserWithRestaurantsInfo>;
  public readonly jwtTokenBase64: string;
  public readonly roles: string[];
  public readonly expiresOn: number;

  constructor(jwtTokenBase64: string, user: Orderly.RestaurantWeb.Api.Messages.UserWithRestaurantsInfo) {
    const jwtToken = jwt_decode(jwtTokenBase64);

    this.jwtTokenBase64 = jwtTokenBase64;
    this.user = user;
    this.roles = jwtToken.role;
    this.expiresOn = jwtToken.exp * 1000;

    this.CURRENT_DEFINITION_VERSION = AuthenticatedUserModel.LAST_COMPATIBLE_DEFINITION_VERSION;
  }

  // NOTE: do not convert to instance method!
  public static isInRole(user: AuthenticatedUserModel, role: string): boolean {
    return user.roles.indexOf(role) >= 0;
  }
}
