import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import { ActionTypes } from '../actions/action-types';
import { tap } from 'rxjs/operators';
export class IngredientCategoryEffect {
    constructor(actions, currentRestaurantIngredientCategoriesService) {
        this.actions = actions;
        this.currentRestaurantIngredientCategoriesService = currentRestaurantIngredientCategoriesService;
        this.addEffect = this.actions.pipe(ofType(ActionTypes.INGREDIENT_CATEGORY_ADD_SUCCESSFUL), tap((action) => {
            this.currentRestaurantIngredientCategoriesService.forceReload();
        }));
        this.deleteEffect = this.actions.pipe(ofType(ActionTypes.INGREDIENT_CATEGORY_DELETE_SUCCESSFUL), tap((action) => {
            this.currentRestaurantIngredientCategoriesService.forceReload();
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false })
], IngredientCategoryEffect.prototype, "addEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false })
], IngredientCategoryEffect.prototype, "deleteEffect", void 0);
