import {AuthenticatedUserModel} from '../../services/local-storage.service';
import {RouterReducerState} from '@ngrx/router-store';
import {LeftMenuItem} from 'orderly-web-components';

export interface AppState {
  readonly auth: AuthState;
  readonly leftMenu: LeftMenuState;
  readonly router: RouterReducerState<any> | null;
}

export interface AuthState {
  readonly currentUser: AuthenticatedUserModel | null;
  readonly loggedInOn: number | null;
}

export interface LeftMenuState {
  items: LeftMenuStateItem[];
  activeItemId: MenuItemDataPayload | null;
}


export class MenuItemDataPayload {
  constructor(public readonly restaurantId: number, public readonly nodeId: string) {
  }
}

export class LeftMenuStateItem {
  public readonly url: string;
  public readonly subItems: LeftMenuStateSubItem[];

  constructor(public readonly id: MenuItemDataPayload,
              public readonly englishName: string,
              url: string,
              subItems: LeftMenuStateSubItem[] | null) {

    this.url = url[0] === '/' ? url : '/' + url;
    this.subItems = subItems == null ? [] : subItems;
  }
}

export class LeftMenuStateSubItem {
  public readonly url: string;
  public readonly subItems: LeftMenuItem[];

  constructor(public readonly id: MenuItemDataPayload,
              public readonly englishName: string,
              url: string) {

    this.url = url[0] === '/' ? url : '/' + url;
  }
}


export interface LoadingState<T> {
  data: Readonly<T> | null;

  loadingStatus: LoadingStatusDefinition;
}

export enum LoadingStatusDefinition {
  LOADING = 1,
  FAILED = 2 ,
  SUCCESS = 3
}
