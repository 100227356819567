/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./categories-order-modal.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/drag-drop";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../../../../../../dist/orderly-web-components/orderly-web-components.ngfactory";
import * as i7 from "orderly-web-components";
import * as i8 from "./categories-order-modal.component";
import * as i9 from "../../../../../../services/active-route-bound/current-restaurant-menu-item-categories.service";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
var styles_CategoriesOrderModalComponent = [i0.styles];
var RenderType_CategoriesOrderModalComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CategoriesOrderModalComponent, data: {} });
export { RenderType_CategoriesOrderModalComponent as RenderType_CategoriesOrderModalComponent };
function View_CategoriesOrderModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 7, "div", [["cdkDrag", ""], ["class", "categories-list-item cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵprd(6144, null, i2.ɵb, null, [i2.CdkDrag]), i1.ɵdid(2, 4866048, [[1, 4]], 3, i2.CdkDrag, [i1.ElementRef, [3, i2.CDK_DROP_LIST], i3.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i2.CDK_DRAG_CONFIG, [2, i4.Directionality], i2.DragDrop, i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 2, { _handles: 1 }), i1.ɵqud(603979776, 3, { _previewTemplate: 0 }), i1.ɵqud(603979776, 4, { _placeholderTemplate: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "icon-angle-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵand(0, null, null, 0))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = i1.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_2); }); }
export function View_CategoriesOrderModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal-header pd-y-20 pd-x-25"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h6", [["class", "tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "close"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "modal-body pd-0-force categories-sorting-list-modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["cdkDropList", ""], ["class", "categories-sorting-list cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.drop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.CDK_DROP_LIST, null, [i2.CdkDropList]), i1.ɵdid(11, 1196032, null, 1, i2.CdkDropList, [i1.ElementRef, i2.DragDrop, i1.ChangeDetectorRef, [2, i4.Directionality], [3, i2.CdkDropListGroup]], { data: [0, "data"] }, { dropped: "cdkDropListDropped" }), i1.ɵqud(603979776, 1, { _draggables: 1 }), i1.ɵprd(256, null, i2.CdkDropListGroup, undefined, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoriesOrderModalComponent_1)), i1.ɵdid(15, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "modal-footer tx-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "owc-progress-action-button", [["type", "link"]], [[2, "d-block", null]], [[null, "execute"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ProgressActionButtonComponent_0, i6.RenderType_ProgressActionButtonComponent)), i1.ɵdid(18, 114688, null, 0, i7.ProgressActionButtonComponent, [], { disabled: [0, "disabled"], actionInProgress: [1, "actionInProgress"], btnText: [2, "btnText"], type: [3, "type"] }, { execute: "execute" }), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.categoriesList; _ck(_v, 11, 0, currVal_6); var currVal_7 = _co.categoriesList; _ck(_v, 15, 0, currVal_7); var currVal_9 = false; var currVal_10 = _co.actionInProgress; var currVal_11 = i1.ɵunv(_v, 18, 2, i1.ɵnov(_v, 19).transform("Save")); var currVal_12 = "link"; _ck(_v, 18, 0, currVal_9, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Change sorting order")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Close")); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 11).id; var currVal_3 = i1.ɵnov(_v, 11).disabled; var currVal_4 = i1.ɵnov(_v, 11)._dropListRef.isDragging(); var currVal_5 = i1.ɵnov(_v, 11)._dropListRef.isReceiving(); _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = i1.ɵnov(_v, 18).displayHostAsBlockElement; _ck(_v, 17, 0, currVal_8); }); }
export function View_CategoriesOrderModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-categories-order-modal", [], null, null, null, View_CategoriesOrderModalComponent_0, RenderType_CategoriesOrderModalComponent)), i1.ɵdid(1, 49152, null, 0, i8.CategoriesOrderModalComponent, [i9.CurrentRestaurantMenuItemCategoriesService, i7.ToastService, i5.TranslateService, i10.NgbActiveModal], null, null)], null, null); }
var CategoriesOrderModalComponentNgFactory = i1.ɵccf("app-categories-order-modal", i8.CategoriesOrderModalComponent, View_CategoriesOrderModalComponent_Host_0, { categoriesList: "categoriesList" }, {}, []);
export { CategoriesOrderModalComponentNgFactory as CategoriesOrderModalComponentNgFactory };
