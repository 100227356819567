import {Component} from '@angular/core';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../util/form.utils';
import {CompletePasswordRecoveryComponentFormFields} from '../helper-classes';
import {FormBuilder, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {mustMatch, NeverError, ToastService} from 'orderly-web-components';
import {TranslateService} from '@ngx-translate/core';
import {nameof} from '../../../util/utils';
import {trnCheckInternetAndTryAgainLaterText, trnTryAgainLaterText} from '../../../util/trn.utils';
import {catchError, finalize, first, map, tap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import ResetPasswordTokenResponse = Orderly.RestaurantWeb.Api.Messages.Authentication.ResetPasswordTokenResponse;
import ResetPasswordTokenRequest = Orderly.RestaurantWeb.Api.Messages.Authentication.ResetPasswordTokenRequest;
import {ActivatedRoute} from '@angular/router';

@Component({
             selector: 'app-complete-password-recovery',
             templateUrl: './complete-password-recovery.component.html',
             styleUrls: ['./complete-password-recovery.component.scss']
           })
export class CompletePasswordRecoveryComponent {

  public actionInProgress: boolean = false;
  public recoveredSuccessfully: boolean = false;

  public formDef: FormDefinition<keyof CompletePasswordRecoveryComponentFormFields>;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private toastService: ToastService,
              private trnService: TranslateService,
              activatedRoute: ActivatedRoute) {

    const fieldsDef: FormFieldsDefinition<keyof CompletePasswordRecoveryComponentFormFields> = {
      token: new FormFieldDefinition(null,
                                     false,
                                     [Validators.required, Validators.maxLength(50)],
                                     []),
      newPassword: new FormFieldDefinition(null,
                                           false,
                                           [Validators.required, Validators.maxLength(50), Validators.minLength(5)],
                                           [nameof<ResetPasswordTokenRequest>('newPassword')]),
      newPasswordConfirmation: new FormFieldDefinition(null,
                                                       false,
                                                       [Validators.required, Validators.maxLength(50)],
                                                       [])
    };

    const error = this.trnService.instant('{{firstControlName}} must match {{secondControlName}}',
                                          {
                                            firstControlName: this.trnService.instant('New password confirmation'),
                                            secondControlName: this.trnService.instant('New password')
                                          });

    const formOpts = {
      validators: mustMatch(
        nameof<CompletePasswordRecoveryComponentFormFields>('newPassword'),
        nameof<CompletePasswordRecoveryComponentFormFields>('newPasswordConfirmation'),
        error)
    };
    this.formDef = new FormDefinition<keyof CompletePasswordRecoveryComponentFormFields>(fieldsDef, this.formBuilder, formOpts);

    activatedRoute.paramMap
                  .pipe(
                    map(pm => pm.get('token')),
                    first(),
                    tap((token: string | null) => {
                      if (token != null) {
                        const tokenCtrl = this.formDef.getControl('token');

                        tokenCtrl.setValue(token);
                        tokenCtrl.disable();
                      }
                    })
                  )
                  .subscribe();
  }

  completeRecovery() {
    if (this.formDef.form.invalid) {
      return;
    }

    const formValue: CompletePasswordRecoveryComponentFormFields = this.formDef.form.getRawValue();

    this.actionInProgress = true;

    const fieldsDisableToken = this.formDef.disable('all-fields');

    const tryAgainLaterTxt = trnTryAgainLaterText(this.trnService);
    const errorTxt = this.trnService.instant(
      'Failed to initiate password reset procedure because of unexpected failure.');
    const unexpectedFailureText = `${errorTxt} ${tryAgainLaterTxt}`;

    this.userService
        .completePasswordReset(formValue.token, formValue.newPassword)
        .pipe(
          tap((response: ResetPasswordTokenResponse) => {
            switch (response.status) {
              case ResetPasswordTokenResponse.StatusDef.ValidationFailed:
                fieldsDisableToken.reenable();
                this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
                break;
              case ResetPasswordTokenResponse.StatusDef.Success:
                this.recoveredSuccessfully = true;
                break;
              case ResetPasswordTokenResponse.StatusDef.ResetPasswordDateExpiredOrDoesNotExist:
                fieldsDisableToken.reenable();

                const tokenExpiredError = this.trnService.instant('Token expired or does not exist. Try to start a recovery process again.');
                this.formDef.getControl('token').setErrors({serverValidation: {messages: [tokenExpiredError]}});
                break;
              case ResetPasswordTokenResponse.StatusDef.UnknownFailure:
                this.toastService.showError(unexpectedFailureText);
                break;
              default:
                throw new NeverError(response.status);
            }
          }),
          catchError((err) => {
            if (err.status === 0) {
              const msg = trnCheckInternetAndTryAgainLaterText(this.trnService);

              this.toastService.showError(msg);
            } else {
              this.toastService.showError(unexpectedFailureText);
            }

            return throwError(err);
          }),
          finalize(() => {
            this.actionInProgress = false;
            fieldsDisableToken.reenable();
          })
        )
        .subscribe();
  }

}
