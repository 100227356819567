import {Component} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {BaseOrderlyComponent} from '../../../../../shared/components/base-orderly.component';
import {PriceEditorFormFieldsDefinition} from './price-editor.form-fields-definition';
import {FormDefinition} from '../../../../../../../util/form.utils';
import {createForm} from './price-editor.form-fields-definition';
import {EditablePricesListComponent} from './editable-prices-list/editable-prices-list.component';
import {distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {ensureNumber, ensureNumberOrNull} from '../../../../../../../util/utils';

@Component({
             selector: 'app-menu-item-price-editor',
             templateUrl: './price-editor.component.html',
             styleUrls: ['./price-editor.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: PriceEditorComponent,
                 multi: true,
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: PriceEditorComponent,
                 multi: true
               },
             ],
           })
export class PriceEditorComponent extends BaseOrderlyComponent implements ControlValueAccessor, Validator {

  private initInProgress: boolean = false;

  public readonly formDef: FormDefinition<keyof PriceEditorFormFieldsDefinition> = createForm(this.formBuilder);


  constructor(private readonly formBuilder: FormBuilder) {
    super();

    this.registerForAutoDestroy(
      this.formDef
          .getControl('amount')
          .valueChanges
          .pipe(
            tap((value: string) => {
              const measureUnitControl = this.formDef.getControl('measureUnit');

              if (value == null || value === '') {
                measureUnitControl.patchValue(null, {emitEvent: false});
                measureUnitControl.disable({emitEvent: false});
              } else {
                measureUnitControl.enable({emitEvent: false});
              }
            })
          )
          .subscribe()
    );

    this.registerForAutoDestroy(
      this.formDef
          .form
          .valueChanges
          .pipe(
            filter(() => !this.initInProgress),
            tap((form: PriceEditorFormFieldsDefinition) => {

              // TODO: need to switch from inputNumber to something else that returns number, not string
              // @ts-ignore
              if (form.price != null && form.price !== '') {
                form.price = ensureNumber(form.price);
              }

              form.amount = ensureNumberOrNull(form.amount);

              this.onChange(form);
            })
          )
          .subscribe()
    );

    this.registerForAutoDestroy(
      this.formDef
          .form
          .valueChanges
          .pipe(
            distinctUntilChanged((x: PriceEditorFormFieldsDefinition, y: PriceEditorFormFieldsDefinition) => {
              return x.hasMultipleSizes === y.hasMultipleSizes && x.amount === y.amount;
            }),
            tap((form: PriceEditorFormFieldsDefinition) => {
              this.updateDependentFieldsState(form.amount, form.hasMultipleSizes);
            })
          )
          .subscribe()
    );
  }

  onChange: any = (value: PriceEditorFormFieldsDefinition) => {
  };

  onTouched: any = () => {
  };


  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: PriceEditorFormFieldsDefinition | null): void {
    if (value == null) {
      return;
    }

    this.initInProgress = true;

    this.formDef.form.patchValue(value, {emitEvent: true});

    this.initInProgress = false;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formDef.disable('all-fields');
    } else {
      this.formDef.enable('all-fields', false);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.formDef.form.invalid) {
      return {'menu-item-price-editor': true};
    }

    return null;
  }


  private updateDependentFieldsState(amount: string | number | null, hasMultipleSizes: boolean) {
    const measureUnitControl = this.formDef.getControl('measureUnit');
    const amountControl = this.formDef.getControl('amount');
    const priceControl = this.formDef.getControl('price');
    const sizesControlArray = this.formDef.getFormArray('sizes');

    if (hasMultipleSizes) {
      amountControl.setValue(null, {emitEvent: false});
      amountControl.disable({emitEvent: false});

      priceControl.setValue(null, {emitEvent: false});
      priceControl.disable({emitEvent: false});

      measureUnitControl.enable({emitEvent: false});

      if (sizesControlArray.length === 0) {
        const sizeControls = [
          EditablePricesListComponent.newSizeFormGroup(this.formBuilder).form,
          EditablePricesListComponent.newSizeFormGroup(this.formBuilder).form
        ];

        sizesControlArray.controls = sizeControls;
      }
    } else {
      amountControl.enable({emitEvent: false});
      priceControl.enable({emitEvent: false});

      if (amount === '' || amount === 0 || amount == null) {
        measureUnitControl.setValue(null, {emitEvent: false});
        measureUnitControl.disable({emitEvent: false});
      } else {
        measureUnitControl.enable({emitEvent: false});
      }
    }
  }
}
