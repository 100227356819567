<div class="form-layout form-layout-1 mg-b-10 pd-b-0">
  <form [formGroup]="formDef.form">
    <div class="row">
      <div class="col-2">
        <div class="form-group">
          <label class="form-control-label">{{'Category' | translate}}:</label>

          <ng-select [items]="categories$ | async"
                     [formControl]="formDef.getControl('category')"
                     [virtualScroll]="true"
                     bindLabel="name">
          </ng-select>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group">
          <label class="form-control-label">{{'Ingredients' | translate}}:</label>

          <ng-select [items]="ingredients$ | async"
                     [formControl]="formDef.getControl('ingredients')"
                     [virtualScroll]="true"
                     bindLabel="name"
                     multiple="true"
                     maxSelectedItems="4">
          </ng-select>
        </div>
      </div>

      <div class="col-2 col-xxl-2">
        <div class="form-group">
          <label class="form-control-label">{{'With modifiers' | translate}}:</label>

          <ng-select [formControl]="formDef.getControl('withModifiers')"
                     [searchable]="false"
                     [clearable]="false"
                     [items]="yesNoSelectValues$ | async"
                     [multiple]="false"
                     bindLabel="name"
                     bindValue="value">
          </ng-select>
        </div>
      </div>

      <div class="col-2 col-xxl-2" *ngIf="isPromotionsEnabled$ | async">
        <div class="form-group">
          <label class="form-control-label">{{'With promotions' | translate}}:</label>

          <ng-select [formControl]="formDef.getControl('withPromotions')"
                     [searchable]="false"
                     [clearable]="false"
                     [items]="yesNoSelectValues$ | async"
                     [multiple]="false"
                     bindLabel="name"
                     bindValue="value">
          </ng-select>
        </div>
      </div>

      <div class="col-1" *ngIf="searchButtonVisible">
        <div class="form-group">
          <label class="form-control-label">&nbsp;</label>
          <a class="btn btn-info" (click)="doSearch()">{{'Search' | translate}}</a>
        </div>
      </div>
    </div>
  </form>
</div>

<div *ngIf="onlineMenuIsNotActivated$ | async" class="alert alert-danger">
  {{'Online menu is currently deactivated.' | translate}}
  <a [routerLink]="editUrl$ | async" style="text-decoration: underline; cursor: pointer;">{{'Click here to change this setting.' | translate}}</a>
</div>

<div class="menu-items-table div-based-table table-dark table-colored">
  <div class="thead">
    <div class="row">
      <div class="col-12 title">{{'Menu items' | translate}}</div>
    </div>
    <div class="row">
      <div class="col-3">{{'Name' | translate}}</div>
      <div class="col-1">{{'Active' | translate}}</div>
      <div class="col-1">{{'Price' | translate}}</div>
      <div class="col-1">{{'Amount' | translate}}</div>
      <div class="col-2">{{'Category' | translate}}</div>
      <div class="col-2">{{'Promotions' | translate}}</div>
      <div class="col-2"></div>
    </div>
  </div>

  <div class="tbody" *ngIf="menuItems$ | async as items; else loading">
    <ng-container *ngFor="let menuItem of items">
      <div class="row" >
        <div class="col-3">
          <a (click)="menuItem.isExpanded = !menuItem.isExpanded" class="pd-5 pd-r-15">
            <i class="pd-r-10"
               [class.icon-plus-squared-alt]="!menuItem.isExpanded"
               [class.icon-minus-squared-alt]="menuItem.isExpanded"></i>
            {{ menuItem.data.name }}</a>
          <sup *ngIf="menuItem.showContainsAlcoholSign" class="contains-alcohol-warn">
            <i class="icon-attention-alt">{{'Contains alcohol' | translate}}</i>
          </sup>
        </div>
        <div class="col-1">
          <i class="icon-ok" *ngIf="menuItem.data.isActive"></i>
        </div>

        <ng-container *ngIf="menuItem.data.sizes.length === 0">
          <div class="col-1">{{ menuItem.data.price | currency:menuItem.data.currency.code3:'symbol-narrow' }}</div>
          <div class="col-1">
            <ng-container *ngIf="menuItem.data.amount != null">
              {{menuItem.data.amount | number}} {{menuItem.data.measureUnit.alias | translate}}
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="menuItem.data.sizes.length !== 0">
          <div class="col-2">
            <span *ngFor="let size of menuItem.data.sizes" class="menu-item-size">
              {{size.name}} ({{size.amount}} {{menuItem.data.measureUnit.alias | translate}}): {{ size.price | currency:menuItem.data.currency.code3:'symbol-narrow' }}
            </span>
          </div>
        </ng-container>

        <div class="col-2">{{menuItem.data.categories | joinMenuItemCategoryNames}}</div>
        <div class="col-2"></div>
        <div class="col-2">
          <ng-container *ngIf="userIsManager | async">
            <a class="btn btn-sm btn-teal text-white mg-x-1" (click)="addOrEditNewMenuItem(menuItem.data)">{{'Update' | translate}}</a>
            <a (click)="deleteMenuItem(menuItem)" class="btn btn-sm btn-danger text-white mg-x-1">{{'Delete' | translate}}</a>
          </ng-container>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="menuItemDetailsTpl;context:menuItem"></ng-container>
    </ng-container>

    <ng-container *ngIf="isLoadedSuccessfully() | async">
      <div class="row" *ngIf="items.length === 0">
        <div class="col-12 text-center">
          {{'There are no menu items' | translate}}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isLoadingFailed() | async">
      <ng-container *ngTemplateOutlet="loadingFailedRow"></ng-container>
    </ng-container>
  </div>

  <div class="tfoot" *ngIf="userIsManager | async">
    <div class="row">
      <div class="col-12">
        <a class="new-element-btn" [class.disabled]="!(isLoadedSuccessfully() | async)" (click)="addOrEditNewMenuItem()">
          <i class="icon-plus"></i>{{'New menu item' | translate}}
        </a>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="tbody">
    <div class="row" *ngIf="isLoadingInProgress() | async">
      <div class="ht-60 pos-relative align-items-center col-12">
        <owc-progress-wave></owc-progress-wave>
      </div>
    </div>

    <ng-container *ngIf="isLoadingFailed() | async">
      <ng-container *ngTemplateOutlet="loadingFailedRow"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #loadingFailedRow>
  <div class="row">
    <div class="ht-50 pos-relative align-items-center col-12">
      <p class="loading-failed-text">{{'Could not load menu items. Check if you have Internet connection and try again.' | translate}}</p>
    </div>
  </div>
</ng-template>

<ng-template #menuItemDetailsTpl let-menuItem="data" let-isVisible="isExpanded">
  <ng-container *ngIf="isVisible">
    <ng-container *ngTemplateOutlet="ingredientsTableTpl;context:{menuItem: menuItem}"></ng-container>

    <app-modifier-groups-table [ngModel]="menuItem"></app-modifier-groups-table>
  </ng-container>
</ng-template>

<ng-template #ingredientsTableTpl let-menuItem="menuItem">
  <div class="menu-item-table--ingredients div-based-table compacted table-dark table-colored">
    <div class="thead">
      <div class="row">
        <div class="col-4">{{'Ingredient name' | translate}}</div>
        <div class="col-5">{{'Required amount' | translate}}</div>
        <div class="col-3"></div>
      </div>
    </div>
    <div class="tbody">
      <div class="row" *ngFor="let ingredient of getIngredients(menuItem)">
        <div class="col-4">
          {{ingredient.ingredient.name}}
          <sup *ngIf="ingredient.ingredient.containsAlcohol" class="contains-alcohol-warn">
            <i class="icon-attention-alt">{{'Contains alcohol' | translate}}</i>
          </sup>
        </div>
        <div class="col-5">{{ingredient.amount}} {{ingredient.ingredient.measureUnit.alias}}</div>
        <div class="col-3">
          <ng-container *ngIf="userIsManager | async">
            <a class="btn btn-sm btn-danger text-white" (click)="deleteMenuItemIngredient(menuItem, ingredient)"><i class="icon-trash-empty"></i></a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
