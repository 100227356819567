import {Component, OnDestroy} from '@angular/core';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../../util/form.utils';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder, FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {ImageFileTypeValidator, ToastService} from 'orderly-web-components';
import {nameof} from '../../../../../../util/utils';
import {OnlineMenuActionFormFieldsDefinition} from './online-menu-action-form-fields.definition';
import {TranslateService} from '@ngx-translate/core';
import {InvalidFileData, NewFileData} from '../../../../shared/components/file-upload/new-file-data';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ExistingFileData} from '../../../../shared/components/file-upload/existing-file-data';

@Component({
             selector: 'app-online-menu-action',
             templateUrl: './online-menu-action.component.html',
             styleUrls: ['./online-menu-action.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: OnlineMenuActionComponent,
                 multi: true
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: OnlineMenuActionComponent,
                 multi: true
               }
             ]
           })
export class OnlineMenuActionComponent implements ControlValueAccessor, Validator, OnDestroy {

  private readonly destroyed$: Subject<any> = new Subject<any>();

  private initInProgress: boolean = false;

  public allowedFileExtensionHint = _('Allowed file extensions: {{ext}}');

  public formDef: FormDefinition<keyof OnlineMenuActionFormFieldsDefinition>;


  constructor(private readonly formBuilder: FormBuilder,
              private readonly trnService: TranslateService,
              private readonly toastService: ToastService) {

    const fieldsDef: FormFieldsDefinition<keyof OnlineMenuActionFormFieldsDefinition> = {
      qrMenuButtonAction: new FormFieldDefinition(false,
                                                  false,
                                                  [Validators.required],
                                                  []),
      menuFiles: new FormFieldDefinition([],
                                         false,
                                         [],
                                         []),
      isMenuReadonly: new FormFieldDefinition(false,
                                              true,
                                              [Validators.required],
                                              []),
      actionTokenValidityPeriod: new FormFieldDefinition(5,
                                                         true,
                                                         [Validators.required],
                                                         []),
    };

    const imageValidator = new ImageFileTypeValidator();
    const menuFileControlName = nameof<OnlineMenuActionFormFieldsDefinition>('menuFiles');
    const menuFileControlValidator = imageValidator.validateControlAsPdf(menuFileControlName, this.trnService);
    const requiredMenuFileValidator = this.getMenuFileValidator();

    const formOpts = {
      validators: [menuFileControlValidator, requiredMenuFileValidator]
    };


    this.formDef = new FormDefinition<keyof OnlineMenuActionFormFieldsDefinition>(fieldsDef,
                                                                                  this.formBuilder,
                                                                                  formOpts);

    this.formDef
        .form
        .valueChanges
        .pipe(
          filter(() => !this.initInProgress),
          tap((x: OnlineMenuActionFormFieldsDefinition) => {
            this.onChange(x);
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();

    this.formDef
        .getControl('qrMenuButtonAction')
        .valueChanges
        .pipe(
          tap((action: Orderly.Common.Enums.QrMenuActionTypeDef) => {
            if (action === Orderly.Common.Enums.QrMenuActionTypeDef.UploadedPdf) {
              this.formDef.enable(['menuFiles'], false);
            } else {
              this.formDef.disable(['menuFiles']);
            }
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe();
  }

  public getMenuFileValidator() {
    return (formGroup: FormGroup) => {
      const qrMenuButtonActionControl = formGroup.controls[nameof<OnlineMenuActionFormFieldsDefinition>('qrMenuButtonAction')];
      const qrMenuAction: Orderly.Common.Enums.QrMenuActionTypeDef = qrMenuButtonActionControl.value;

      if (qrMenuAction !== Orderly.Common.Enums.QrMenuActionTypeDef.UploadedPdf) {
        return;
      }

      const menuFileControl = formGroup.controls[nameof<OnlineMenuActionFormFieldsDefinition>('menuFiles')];
      const menuFiles: Readonly<NewFileData | ExistingFileData>[] | null = menuFileControl.value;

      if (menuFiles === null || menuFiles.length === 0) {
        menuFileControl.setErrors({required: 'Menu file is required'});

        return;
      }

      if (menuFiles.length > 1) {
        const errText = this.trnService.instant(_('Only one file is allowed'));

        menuFileControl.setErrors({message: errText});
      }
    };
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.formDef.form.invalid) {
      return {'restaurant-editor': true};
    }

    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: OnlineMenuActionFormFieldsDefinition) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: OnlineMenuActionFormFieldsDefinition | null): void {
    this.initInProgress = true;

    if (value != null) {
      this.formDef.patchValue(value);

      if (value.qrMenuButtonAction !== Orderly.Common.Enums.QrMenuActionTypeDef.UploadedPdf) {
        this.formDef.disable(['menuFiles']);
      }
    } else {
      this.formDef.disable(['menuFiles']);
    }

    this.initInProgress = false;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formDef.disable('all-fields');
    } else {
      const formValue: OnlineMenuActionFormFieldsDefinition = this.formDef.form.getRawValue();

      if (formValue.qrMenuButtonAction === Orderly.Common.Enums.QrMenuActionTypeDef.UploadedPdf) {
        this.formDef.enable('all-fields', false);
      } else {
        this.formDef.disable(['menuFiles']);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  public menuFileBlocked(fileData: InvalidFileData): void {
    this.toastService.showError(_('Selected file is not allowed'));
  }
}
