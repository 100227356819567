<div class="row">
  <div class="col-12">
    <div class="form-group">
      <label class="form-control-label" for="name">{{'Name' | translate}}: <span class="tx-danger">*</span></label>

      <app-shared-text-input-with-language-flag [formControl]="formDef.getControl('name')"
                                                id="name">
      </app-shared-text-input-with-language-flag>

      <owc-input-validation-errors [control]="formDef.getControl('name')"></owc-input-validation-errors>
    </div>
  </div>

  <div class="col-12">
    <div class="form-group">
      <label class="form-control-label" for="name">{{'Name translations' | translate}}:</label>

      <app-shared-translations-input [languages]="translationLanguages$ | async"
                                     [primaryTextLanguage]="currentRestaurantPrimaryLanguage$ | async"
                                     [primaryTextVisible]="false"
                                     [maxlength]="80"
                                     [formControl]="formDef.getControl('nameTranslations')">
      </app-shared-translations-input>

      <owc-input-validation-errors [control]="formDef.getControl('nameTranslations')"></owc-input-validation-errors>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-6 col-lg-2">
    <div class="form-group">
      <label class="form-control-label" for="isActive">{{'Is active' | translate}}: <span class="tx-danger">*</span></label>

      <app-shared-yes-no-switch [formControl]="formDef.getControl('isActive')"
                                class="input-group"
                                id="isActive">
      </app-shared-yes-no-switch >

    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="form-group">
      <label class="form-control-label">{{'How many modifiers can be selected' | translate}}:</label>


      <app-modifier-group-editor-modifiers-count-switch class="input-group"
                                                        [formControl]="formDef.getControl('isOnlyOnePermitted')">
      </app-modifier-group-editor-modifiers-count-switch>
    </div>
  </div>
</div>

<div class="row" *ngIf="formDef.getControl('isOnlyOnePermitted').value === false">

  <div class="col-6 col-sm-3 col-lg-2">
    <div class="form-group">
      <label class="form-control-label">{{'Min selectable elements' | translate}}: <span class="tx-danger">*</span></label>
      <input class="form-control"
             type="text"
             maxlength="2"
             inputNumber
             [formControl]="formDef.getControl('minAllowedAmount')"
             [acceptDecimalPlaces]="false" />

      <owc-input-validation-errors [control]="formDef.getControl('minAllowedAmount')"></owc-input-validation-errors>
    </div>
  </div>

  <div class="col-6 col-sm-3 col-lg-2">
    <div class="form-group">
      <label class="form-control-label" for="maxAllowedAmount">{{'Max selectable elements' | translate}}:</label>

      <input class="form-control"
             type="text"
             maxlength="2"
             id="maxAllowedAmount"
             inputNumber
             [formControl]="formDef.getControl('maxAllowedAmount')"
             [acceptDecimalPlaces]="false"/>

      <owc-input-validation-errors [control]="formDef.getControl('maxAllowedAmount')"></owc-input-validation-errors>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-12">

    <a class="btn btn-danger text-white float-right" (click)="cancel.emit()">{{'Cancel' | translate}}</a>
    <a class="btn btn-success text-white float-right mg-r-5"
       [class.disabled]="formDef.form.invalid"
       (click)="saveChanges()">{{'Save' | translate}}</a>
  </div>
</div>
