<input type="file"
       name="menu-file[]"
       id="menu-file"
       class="inputfile"
       [multiple]="multipleFilesAllowed"
       (change)="onFileChange($event)"

       #menuFileInput>

<div *ngFor="let fileData of validFiles; let i = index;" style="width: 100%;">
  <label for="menu-file"
         class="tx-white bg-info btn-select-file selected"
         [title]="fileData.name">

    <span>{{fileData.name}}</span>

  </label>

  <a (click)="removeMenuFile(i)"
     class="btn btn-danger btn-clear-file"
     *ngIf="isDisabled === false">

    <i class="icon-trash-empty"></i>
  </a>
</div>

<div *ngFor="let fileData of invalidFiles; let i = index;" style="width: 100%;">
  <label for="menu-file"
         class="tx-white bg-danger btn-select-file error btn"
         [title]="fileData.name">

    <i class="icon-attention-alt"></i>

    {{fileData.name}}

  </label>
</div>

<label *ngIf="showSelectFileButton" for="menu-file" class="btn tx-white bg-info btn-select-menu-file">
  {{'Choose a file' | translate}}...
</label>
