import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { RestaurantComponent } from '../../../restaurant.component';
import { catchError, first, tap } from 'rxjs/operators';
import { AddOrEditMenuItemModifierGroupComponent } from '../../../add-or-edit-menu-item-modifier-group/add-or-edit-menu-item-modifier-group.component';
import { NeverError } from 'orderly-web-components';
import { genericErrorHandlerWithToast } from '../../../../util/utils';
import { AddOrEditMenuItemModifierComponent } from '../../../add-or-edit-menu-item-modifier/add-or-edit-menu-item-modifier.component';
export class ModifierGroupRowComponent extends RestaurantComponent {
    constructor(store, activatedRoute, restaurantMenuService, ingredientsService, trnService, toastService, modalService, deleteModalUtils, ref) {
        super(store, activatedRoute);
        this.restaurantMenuService = restaurantMenuService;
        this.ingredientsService = ingredientsService;
        this.trnService = trnService;
        this.toastService = toastService;
        this.modalService = modalService;
        this.deleteModalUtils = deleteModalUtils;
        this.ref = ref;
        this.deleteGroup = new EventEmitter();
        this.onChange = () => {
        };
        this.onTouched = () => {
        };
        this.ingredients$ = this.ingredientsService.currentLoadedRestaurantIngredients$;
    }
    get value() {
        return this.groupRow;
    }
    ;
    set value(v) {
        if (v !== this.groupRow) {
            this.groupRow = v;
            this.onChange(v);
        }
    }
    writeValue(value) {
        // additional null check is required because of
        // https://github.com/angular/angular/issues/14988
        if (value != null) {
            if (value !== this.groupRow) {
                this.groupRow = value;
                this.ref.detectChanges();
            }
        }
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    getSelectableModifiersCountText(x) {
        if (x.minAllowedAmount === 0 && x.maxAllowedAmount === 1) {
            return this.trnService.instant('Only one');
        }
        let result = this.trnService.instant('Min.') + ' ' + x.minAllowedAmount;
        if (x.maxAllowedAmount != null) {
            result += '; ' + this.trnService.instant('Max.') + ' ' + x.maxAllowedAmount;
        }
        return result;
    }
    editMenuItemModifierGroup() {
        this.restaurantIdParam$
            .pipe(first(), tap(restaurantId => {
            const modalRef = this.modalService.open(AddOrEditMenuItemModifierGroupComponent, { centered: true, size: 'lg' });
            const modalInstance = modalRef.componentInstance;
            modalInstance.menuItemId = this.groupRow.parent.id;
            modalInstance.restaurantId = restaurantId;
            modalInstance.modifierGroupToEdit = Object.assign({}, this.groupRow.data);
            const completedSubscription = modalInstance.completed
                .subscribe(($e) => {
                if ($e.status ===
                    1 /* Success */) {
                    this.handleModifierGroupAddOrUpdateResponse($e.group);
                }
            });
            modalRef.result.finally(() => {
                completedSubscription.unsubscribe();
            });
        }))
            .subscribe();
    }
    handleModifierGroupAddOrUpdateResponse(savedGroup) {
        this.groupRow.data.nameIsVisibleToUser = savedGroup.nameIsVisibleToUser;
        this.groupRow.data.isActive = savedGroup.isActive;
        this.groupRow.data.maxAllowedAmount = savedGroup.maxAllowedAmount;
        this.groupRow.data.minAllowedAmount = savedGroup.minAllowedAmount;
        this.groupRow.data.name = savedGroup.name;
        this.ref.markForCheck();
    }
    addOrEditMenuItemModifier(modifier) {
        const modalRef = this.modalService.open(AddOrEditMenuItemModifierComponent, { centered: true, size: 'lg' });
        const modalInstance = modalRef.componentInstance;
        modalInstance.ingredients$ = this.ingredients$;
        modalInstance.modifierToEdit = {
            modifier: modifier,
            modifierGroupId: this.groupRow.data.id,
            menuItemId: this.groupRow.parent.id,
        };
        const completedSubscription = modalInstance.completed.subscribe(($e) => {
            if ($e.status === 1 /* Success */) {
                if (modifier != null) {
                    const updatedModifierIndex = this.groupRow.data.menuItemModifiers.findIndex(x => x.id === $e.modifier.id);
                    this.groupRow.data.menuItemModifiers[updatedModifierIndex] = $e.modifier;
                }
                else {
                    this.groupRow.data.menuItemModifiers.push($e.modifier);
                }
                this.ref.markForCheck();
            }
        });
        modalRef.result.finally(() => {
            completedSubscription.unsubscribe();
        });
    }
    deleteMenuItemModifier(modifier) {
        const onDelete = (modalRef) => {
            const unexpectedFailureText = this.trnService.instant('Failed to remove a modifier  because of unexpected failure. Please try again later.');
            this.restaurantMenuService
                .deleteMenuItemModifier(this.groupRow.parent.id, this.groupRow.data.id, modifier.id)
                .pipe(tap((response) => {
                switch (response.status) {
                    case 1 /* Success */:
                        modalRef.close(true);
                        this.groupRow.data.menuItemModifiers = this.groupRow.data.menuItemModifiers.filter(value => value.id !== modifier.id);
                        this.ref.markForCheck();
                        const successText = this.trnService.instant('Modifier was successfully deleted.');
                        this.toastService.showSuccess(successText);
                        break;
                    case 2 /* UnknownFailure */:
                        this.toastService.showError(unexpectedFailureText);
                        break;
                    default:
                        throw new NeverError(response.status);
                }
            }), first(), catchError(genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedFailureText)))
                .subscribe();
        };
        const modifierName = modifier.ingredient == null ? modifier.name : modifier.ingredient.name;
        const dialogText = [
            this.trnService.instant('Are you sure you want to remove a modifier "{{modifierName}}" in group "{{modifierGroupName}}"?', {
                modifierName: modifierName,
                modifierGroupName: this.groupRow.data.name
            })
        ];
        this.deleteModalUtils.showDeleteModal(modifier, this.trnService.instant('Delete a modifier'), dialogText, onDelete);
    }
}
