import {TranslateService} from '@ngx-translate/core';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GetAllStaticDataResponse, MeasureUnit, StaticDataService} from 'orderly-web-components';
import Allergen = Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Allergen;
import {environment} from '../environments/environment';


export function trnTryAgainLaterText(trnService: TranslateService): string {
  return trnService.instant(_('Please try again later.'));
}

export function trnCheckInternetAndTryAgainLaterText(trnService: TranslateService) {
  return trnService.instant(_('Connection error. Please check if you have internet connection and try again.'));
}

export function trnUnknownErrorAndTryAgainLaterText(trnService: TranslateService) {
  return trnService.instant(_('An unknown error has occurred while processing your request. Please try again later.'));
}

export function trnCancelButtonTitle(trnService: TranslateService) {
  return trnService.instant(_('Cancel'));
}


export function getTranslatedMeasureUnits(staticDataService: StaticDataService,
                                          trnService: TranslateService): Observable<MeasureUnit[]> {
  return staticDataService.getAllStaticData(environment.baseApiUrlWithTrailingSlash)
                          .pipe(
                            map((x: GetAllStaticDataResponse) => {
                              const result = x.measureUnits
                                              .map(u => {
                                                const unit: MeasureUnit = {
                                                  id: u.id,
                                                  alias: u.alias,
                                                  name: trnService.instant(u.name)
                                                };

                                                return unit;
                                              })
                                              .sort((a, b) => {
                                                if (a.name > b.name) {
                                                  return -1;
                                                }

                                                if (b.name > a.name) {
                                                  return 1;
                                                }

                                                return 0;
                                              });

                              return result.sort((a, b) => a.name.localeCompare(b.name));
                            })
                          );
}

export function getTranslatedAllergens(staticDataService: StaticDataService,
                                       trnService: TranslateService): Observable<Allergen[]> {
  return staticDataService.getAllStaticData(environment.baseApiUrlWithTrailingSlash)
                          .pipe(
                            map((x: GetAllStaticDataResponse) => {
                              const result = x.allergens
                                              .map(a => {
                                                const allergen: Allergen = {
                                                  code: a.code,
                                                  description: trnService.instant(a.description)
                                                };

                                                return allergen;
                                              })
                                              .sort((a, b) => {
                                                if (a.code > b.code) {
                                                  return -1;
                                                }

                                                if (b.code > a.code) {
                                                  return 1;
                                                }

                                                return 0;
                                              });

                              return result.sort((a, b) => a.code.charCodeAt(0) - b.code.charCodeAt(0));
                            })
                          );
}

export function getArrayOfTranslationKeys<T>(translationsDefinition: T): string[] {
  return Object.keys(translationsDefinition)
               .map(prop => {
                 const propValue = translationsDefinition[prop];

                 if (typeof propValue === 'string') {
                   return propValue;
                 }

                 return null;
               })
               .filter(x => x != null && x !== '')
               // note: this is required only because TS transpiler does not parse x != null and thinks that return value can be null
               .map(propValue => propValue as string);
}

export function assignTranslatedProperties<T>(initialTranslationsDefinition: T, translations: T): T {
  const translationKeyDefs = Object.keys(initialTranslationsDefinition)
                                   .map(prop => {
                                     const propValue = initialTranslationsDefinition[prop];

                                     if (typeof propValue === 'string') {
                                       return {propName: prop, trnKey: propValue};
                                     }

                                     return null;
                                   })
                                   .filter(x => x != null);
  const result = {};

  for (const trnKeyDef of translationKeyDefs) {
    const translation = translations[trnKeyDef!.trnKey];

    if (translation == null || translation === '') {
      const initialTranslationValue = initialTranslationsDefinition[trnKeyDef!.propName];

      if (environment.production) {
        result[trnKeyDef!.propName] = initialTranslationValue;
      } else {
        result[trnKeyDef!.propName] = 'TODO-' + initialTranslationValue;
      }
    } else {
      result[trnKeyDef!.propName] = translation;
    }
  }

  return result as T;
}

// required to generate static data translations; otherwise they will not be emitted
// @ts-ignore
class FakeMeasureUnitTranslations {
  public Millimeter = _('Millimeter');
  public Centimeter = _('Centimeter');
  public Meter = _('Meter');
  public Gram = _('Gram');
  public Kilogram = _('Kilogram');
  public Milliliter = _('Milliliter');
  public Centiliter = _('Centiliter');
  public Liter = _('Liter');
  public Pieces = _('Pieces');
}

// @ts-ignore
class FakeMeasureUnitAliasTranslations {
  public Millimeter = _('mm');
  public Centimeter = _('cm');
  public Meter = _('m');
  public Gram = _('g');
  public Kilogram = _('kg');
  public Milliliter = _('ml');
  public Centiliter = _('cl');
  public Liter = _('l');
  public Pieces = _('pcs');
}

// @ts-ignore
class FakeAllergenTranslations {
  public A = _('cereals containing gluten – including wheat (such as spelt and Khorasan), rye, barley and oats');
  public B = _('crustaceans – such as prawns, crabs and lobsters');
  public C = _('eggs');
  public D = _('fish');
  public E = _('peanuts');
  public F = _('soybeans');
  public G = _('milk');
  public H = _('tree nuts – including almonds, hazelnuts, walnuts, brazil nuts, cashews, pecans, pistachios and macadamia nuts');
  public L = _('celery');
  public M = _('mustard');
  public N = _('sesame seeds');
  public O = _('sulphur dioxide and sulphites (if they are at a concentration of more than ten parts per million)');
  public P = _('lupin');
  public R = _('molluscs – such as mussels and oysters');
}

// @ts-ignore
export class YesNoTranslations {
  public static Yes = _('Yes');
  public static No = _('No');
}

// @ts-ignore
class LanguageTranslations {
  public English = _('English');
  public German = _('German');
  public Ukrainian = _('Ukrainian');
}

// @ts-ignore
class CityTranslations {
  public Kyiv = _('Kyiv');
  public Vienna = _('Vienna');
  public Berlin = _('Berlin');
  public Graz = _('Graz');
  public Munich = _('Munich');
  public Innsbruck = _('Innsbruck');
  public Linz = _('Linz');
  public Other = _('Other');
}


export class QrMenuActionTypeDefTranslations {
  public static CallWaiter: string = _('Call a waiter to bring a menu');
  public static OnlineMenu: string = _('Display online menu');
  public static UploadedPdf: string = _('Show uploaded PDF');
}

export class MenuItemGroupsListStyleDefTranslations {
  public static NoImages: string = _('No image, just title');
  public static SmallImages: string = _('Title and small image');
}

export class EnabledDisabledTranslations {
  public static Enabled: string = _('Enabled');
  public static Disabled: string = _('Disabled');
}

export class SharedConfirmationMessages {
  public static RestaurantUpdatedSuccessfully = _('Restaurant was updated successfully');
}

export class RestaurantRoles {
  public static HoldingExecutive = {code: 'holding-executive', name: _('Holding Executive')};
  public static HoldingManager = {code: 'holding-manager', name: _('Holding Manager')};

  public static Owner = {code: 'restaurant-owner', name: _('Owner')};
  public static Waiter = {code: 'waiter', name: _('Waiter')};
  public static Manager = {code: 'restaurant-manager', name: _('Manager')};
  public static ManagerAssistant = {code: 'restaurant-manager-assistant', name: _('Manager\'s assistant')};
}
