import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {ActionTypes} from '../actions/action-types';
import {first, map, switchMap, tap} from 'rxjs/operators';
import {
  CurrentUserInformationChangedAction,
  CurrentUserInformationLoadedAction,
  CurrentUserSelectedLanguageChangedAction, LoginSuccessfulAction
} from '../actions/user.actions';
import {TranslateService} from '@ngx-translate/core';
import {Language} from 'orderly-web-components';
import {UserService} from '../../../services/user.service';
import {LocalStorageService} from '../../../services/local-storage.service';
import {AppState} from '../app.state';
import {getAuthenticatedUserSelector} from '../selectors/restaurant-selectors';
import UserWithRestaurantsInfo = Orderly.RestaurantWeb.Api.Messages.UserWithRestaurantsInfo;


@Injectable()
export class LocalizationEffect {

  constructor(private readonly actions: Actions,
              private readonly trnService: TranslateService,
              private readonly userService: UserService,
              private readonly store: Store<AppState>) {
  }

  @Effect({dispatch: false})
  userInfoChangedEffect$: Observable<Action> = this.actions.pipe(
    ofType<CurrentUserInformationChangedAction>(ActionTypes.CURRENT_USER_INFORMATION_CHANGED),

    tap((action: CurrentUserInformationChangedAction) => {
      this.changeLanguageIfExists(action.lang);
    })
  );

  @Effect({dispatch: false})
  userInfoLoadedEffect$: Observable<Action> = this.actions.pipe(
    ofType<CurrentUserInformationLoadedAction>(ActionTypes.CURRENT_USER_INFORMATION_LOADED),

    tap((action: CurrentUserInformationLoadedAction) => {
      this.changeLanguageIfExists(action.user.preferredLang);
    })
  );

  @Effect({dispatch: false})
  userLanguageChangedEffect$: Observable<Action> = this.actions.pipe(
    ofType<CurrentUserSelectedLanguageChangedAction>(ActionTypes.CURRENT_USER_SELECTED_LANGUAGE_CHANGED),

    tap((action: CurrentUserSelectedLanguageChangedAction) => {
      this.changeLanguageIfExists(action.lang)
    }),

    switchMap((action: CurrentUserSelectedLanguageChangedAction) => {
      const authUser$ = this.store.select(getAuthenticatedUserSelector);

      return authUser$.pipe(
        map(user => {
          return {
            action: action,
            user: user
          };
        })
      );
    }),

    tap((data: {action: CurrentUserSelectedLanguageChangedAction, user: UserWithRestaurantsInfo | null}) => {
      if (data.user != null) {
        if (data.user.preferredLang == null || data.user.preferredLang.id !== data.action.lang.id) {
          this.userService.updateUserLanguage(data.action.lang.code).pipe(first()).subscribe();
        }
      }
    }),

    map(x => x.action)
  );

  @Effect({dispatch: false})
  loginSuccessful$: Observable<Action> = this.actions.pipe(
    ofType<LoginSuccessfulAction>(ActionTypes.LOGIN_SUCCESSFUL),

    tap((action: LoginSuccessfulAction) => {
      const parsedUser = LocalStorageService.setCurrentUser(action.jwtTokenBase64, action.user);

      console.log('change lang on login');

      this.changeLanguageIfExists(parsedUser.user.preferredLang);
    })
  );


  private changeLanguageIfExists(actionLang: Language): boolean {
    const actionLangCode2Lower = actionLang.code.toLocaleLowerCase();

    const langIndex = this.trnService.langs.findIndex(langCode2 => {
      const val1 = langCode2.toLocaleLowerCase();

      return val1 === actionLangCode2Lower;
    });

    if (langIndex >= 0) {
      this.trnService.use(actionLangCode2Lower);

      return true;
    }

    return false;
  }
}
