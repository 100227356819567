import {Component, Input, ViewEncapsulation} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import MenuItemCategory = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemCategoryResponse.MenuItemCategory;
import ReorderMenuItemCategoriesRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.ReorderMenuItemCategoriesRequest;
import {catchError, finalize, first, tap} from 'rxjs/operators';
import ReorderMenuItemCategoriesResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.ReorderMenuItemCategoriesResponse;
import {NeverError, ToastService} from 'orderly-web-components';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {throwError} from 'rxjs';
import {CurrentRestaurantMenuItemCategoriesService} from '../../../../../../services/active-route-bound/current-restaurant-menu-item-categories.service';
import {trnTryAgainLaterText} from '../../../../../../util/trn.utils';

@Component({
  selector: 'app-categories-order-modal',
  templateUrl: './categories-order-modal.component.html',
  styleUrls: ['./categories-order-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategoriesOrderModalComponent {

  public actionInProgress: boolean = false;

  @Input()
  public categoriesList: MenuItemCategory[] = [];

  constructor(private readonly menuItemCategoriesService: CurrentRestaurantMenuItemCategoriesService,
              private readonly toastService: ToastService,
              private readonly trnService: TranslateService,
              public readonly activeModal: NgbActiveModal) { }


  public drop(event: CdkDragDrop<MenuItemCategory[]>) {
    moveItemInArray(this.categoriesList, event.previousIndex, event.currentIndex);
  }


  public save() {
    this.actionInProgress = true;

    const categories = this.categoriesList
                           .map((value, index) => {
                             const result: ReorderMenuItemCategoriesRequest.Category = {
                               id: value.id,
                               sortOrder: index
                             };

                             return result;
                           });

    const request: ReorderMenuItemCategoriesRequest = {
      categories: categories
    };

    const tryAgainLaterMsg = trnTryAgainLaterText(this.trnService);
    const unexpectedServerFailureMsg = this.trnService.instant('Failed to reorder menu item categories because of unexpected failure.')
                                       + ' ' + tryAgainLaterMsg;

    this.menuItemCategoriesService
        .reorder(request)
        .pipe(
          first(),
          tap((response: ReorderMenuItemCategoriesResponse) => {
            switch (response.status) {
              case ReorderMenuItemCategoriesResponse.StatusDef.ValidationFailed:
              case ReorderMenuItemCategoriesResponse.StatusDef.UnknownFailure:
                this.toastService.showError(unexpectedServerFailureMsg);
                break;
              case ReorderMenuItemCategoriesResponse.StatusDef.Success:
                const successMsg = this.trnService.instant('Successfully changed menu item categories sorting order.');

                this.activeModal.close(this.categoriesList);
                this.toastService.showSuccess(successMsg);

                break;
              default:
                throw new NeverError(response.status);
            }
          }),
          catchError(err => {
            this.toastService.showError(unexpectedServerFailureMsg);

            return throwError(err);
          }),
          finalize(() => {
            this.actionInProgress = false;
          })
        )
        .subscribe();
  }
}
