<ng-container>
  <div class="modal-header pd-y-20 pd-x-25">
    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{'Set new pin'}}</h6>
    <button type="button" class="close" [attr.aria-label]="'Close' | translate" (click)="modalService.dismiss(null)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pd-25">

    <form [formGroup]="formDef.form" (ngSubmit)="save()">

      <div class="row">
        <div class="form-layout form-layout-1" style="width: 100%;">

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label">{{'Pin' | translate}}: <span class="tx-danger">*</span></label>
                <input class="form-control"
                       type="text"
                       [formControl]="formDef.getControl('pin')"
                       [maxLength]="4"
                       inputNumber
                       [acceptDecimalPlaces]="false" />

                <owc-input-validation-errors [control]="formDef.getControl('pin')"></owc-input-validation-errors>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer tx-white">
    <owc-progress-action-button [actionInProgress]="actionInProgress"
                                [disabled]="formDef.form.invalid"
                                (execute)="save()"
                                [btnText]="'Set new pin' | translate"
                                type="link">
    </owc-progress-action-button>
  </div>
</ng-container>
