import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IngredientsService} from '../ingredients.service';
import {filter, map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import RestaurantIngredientBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
import {RestaurantSpecificArrayResultLoadingState} from './helper.statuses';
import {CurrentRestaurantServiceBase} from './current-restaurant.service.base';

@Injectable({
              providedIn: 'root'
            })
export class CurrentRestaurantIngredientsService extends CurrentRestaurantServiceBase<RestaurantIngredientBasic> {

  public get currentLoadedRestaurantIngredients$(): Observable<RestaurantIngredientBasic[]> {
    return this.dataFeed$.pipe(
      filter(x => x.isLoaded()),
      map(x => x.items),
    );
  }

  // TODO: obsolete
  public get currentRestaurantIngredients$(): Observable<RestaurantSpecificArrayResultLoadingState<RestaurantIngredientBasic>> {
    return this.dataFeed$;
  }

  constructor(private ingredientsService: IngredientsService, store: Store<AppState>) {
    super(store);
  }

  protected doLoadData(restaurantId: number): Observable<RestaurantIngredientBasic[]> {
    return this.ingredientsService.getAllIngredients(restaurantId).pipe(map(x => x.restaurantIngredients));
  }
}
