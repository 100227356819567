<div class="img-list">
  <div *ngFor="let img of images" class="menu-item-image-container">
    <img *ngIf="img.thumbContentBase64 == null" [src]="img.thumbs[0].url" [alt]="img.originalImage.name" />

    <img *ngIf="img.thumbContentBase64 != null"
         [src]="domSanitizationService.bypassSecurityTrustUrl(img.thumbContentBase64)"
         [alt]="img.name"
         (click)="openImagePreview(img)" />

    <i class="icon-trash-empty btn-delete-image" (click)="deleteImage(img)"></i>
  </div>

  <div class="add-new-image-btn-container">
    <input type="file" name="category-image[]" id="menu-item-image-fi" class="inputfile" (change)="onFileChange($event)" #categoryImageFileInput>

    <label for="menu-item-image-fi" class="btn-select-image">
      <span>{{'Choose an image' | translate}}...</span>
    </label>
  </div>

</div>

<small>{{'An image must be at least 200x200 pixel large' | translate}}</small>
