import {LeftMenuState} from '../../app.state';
import {LoginSuccessfulAction} from '../../actions/user.actions';
import {createLeftMenuStateFromUserInfo} from './helper.functions';

export function loginSuccessfulActionHandler(currentState: LeftMenuState,
                                             action: LoginSuccessfulAction): LeftMenuState {

  return createLeftMenuStateFromUserInfo(action.user.holdingInfo, action.user.restaurants);

}
