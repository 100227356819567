import {AuthState} from '../app.state';
import {ActionTypes} from '../actions/action-types';
import {AuthenticatedUserModel} from '../../../services/local-storage.service';
import {UserActions} from '../actions/user.actions';
import UserWithRestaurantsInfo = Orderly.RestaurantWeb.Api.Messages.UserWithRestaurantsInfo;

export const initialAuthState: AuthState = {
  currentUser: null,
  loggedInOn: null
};

export function userDataReducer(state = initialAuthState, action: UserActions): AuthState {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESSFUL:
      const authenticatedUser: AuthenticatedUserModel = new AuthenticatedUserModel(action.jwtTokenBase64, action.user);

      return {...state, currentUser: authenticatedUser, loggedInOn: Date.now()};

    case ActionTypes.CURRENT_USER_SELECTED_LANGUAGE_CHANGED:
      if (state.currentUser == null) {
        return state;
      }

      const clonedLangUser: UserWithRestaurantsInfo = {...state.currentUser.user};

      clonedLangUser.preferredLang = action.lang;

      const clonedLangCurrentUser: AuthenticatedUserModel = {...state.currentUser, user: clonedLangUser};

      return {...state, currentUser: clonedLangCurrentUser};

    case ActionTypes.CURRENT_USER_INFORMATION_CHANGED:
      if (state.currentUser == null) {
        return state;
      }

      const clonedUser: UserWithRestaurantsInfo = {...state.currentUser.user};

      clonedUser.lastName = action.lastName;
      clonedUser.firstName = action.firstName;
      clonedUser.preferredLang = action.lang;

      const clonedCurrentUser: AuthenticatedUserModel = {...state.currentUser, user: clonedUser};

      return {...state, currentUser: clonedCurrentUser};

    case ActionTypes.LOGOUT:
      return {...state, loggedInOn: null, currentUser: null};

    case ActionTypes.RESTAURANT_ONBOARDING_COMPLETED:
      if (state.currentUser == null) {
        return state;
      }

      const clonedCurrentUserForOnboardingCompleted = cloneUserWithOnboardingCompletedFn(state.currentUser);

      return {...state, currentUser: clonedCurrentUserForOnboardingCompleted};

    case ActionTypes.RESTAURANT_CREATE_SUCCESSFUL:
      if (state.currentUser == null) {
        return state;
      }

      const clonedUserForRestaurantCreated: UserWithRestaurantsInfo = {...state.currentUser.user};
      const currentRestaurants = [...clonedUserForRestaurantCreated.restaurants];

      currentRestaurants.push(action.restaurant);

      clonedUserForRestaurantCreated.restaurants = currentRestaurants;

      const clonedCurrentUserForRestaurantCreated: AuthenticatedUserModel = {
        ...state.currentUser,
        user: clonedUserForRestaurantCreated
      };

      return {...state, currentUser: clonedCurrentUserForRestaurantCreated};

    case ActionTypes.RESTAURANT_UPDATE_SUCCESSFUL:
      if (state.currentUser == null) {
        return state;
      }

      const updatedRestaurantIdx = state.currentUser.user.restaurants.findIndex(x => x.id === action.restaurant.id);

      if (updatedRestaurantIdx < 0) {
        throw new Error('Could not find updated restaurant by its Id: ' + action.restaurant.id);
      }

      const clonedUserForRestaurantUpdated: UserWithRestaurantsInfo = {...state.currentUser.user};
      const currentUserRestaurants = [...clonedUserForRestaurantUpdated.restaurants];

      currentUserRestaurants[updatedRestaurantIdx] = action.restaurant;

      clonedUserForRestaurantUpdated.restaurants = currentUserRestaurants;

      const clonedCurrentUserForRestaurantUpdated: AuthenticatedUserModel = {
        ...state.currentUser,
        user: clonedUserForRestaurantUpdated
      };

      return {...state, currentUser: clonedCurrentUserForRestaurantUpdated};


    default:
      return state;
  }
}

const cloneUserWithOnboardingCompletedFn = (user: Readonly<AuthenticatedUserModel>) => {
  const clonedHoldingForOnboardingCompleted: Orderly.RestaurantWeb.Api.Messages.RestaurantHoldingShortInfo = {...user.user.holdingInfo};

  clonedHoldingForOnboardingCompleted.isOnboardingCompleted = true;

  const clonedUserForOnboardingCompleted: UserWithRestaurantsInfo = {
    ...user.user,
    holdingInfo: clonedHoldingForOnboardingCompleted
  };
  const clonedCurrentUserForOnboardingCompleted: AuthenticatedUserModel = {
    ...user,
    user: clonedUserForOnboardingCompleted
  };

  return clonedCurrentUserForOnboardingCompleted;
};
