<div class="input-group-prepend">
  <span class="input-group-text">
    <span [className]="'flag flag-' + this.lastValue.langCode2?.toLocaleLowerCase()"></span>
  </span>
</div>

<ng-container *ngIf="mode === 'input'">
  <input type="text"
         class="form-control"
         [class.disabled]="isDisabled"
         [attr.disabled]="isDisabled ? 'disabled' : null"
         [attr.maxlength]="maxlength"
         [ngModel]="lastValue.text"
         (ngModelChange)="changed($event)"/>

</ng-container>

<ng-container *ngIf="mode === 'textarea'">
  <textarea class="form-control"
            rows="3"
            [class.disabled]="isDisabled"
            [attr.disabled]="isDisabled ? 'disabled' : null"
            [attr.maxlength]="maxlength"
            [ngModel]="lastValue.text"
            (ngModelChange)="changed($event)">
  </textarea>

</ng-container>
