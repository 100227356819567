import {Component, forwardRef, OnDestroy, ViewEncapsulation} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validators
} from '@angular/forms';
import ExistingMenuItemSize = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.ExistingMenuItemSize;
import {Subject} from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {PricesListFormFieldsDefinition} from './form-fields-definition';
import {
  FormArrayDefinition,
  FormDefinition,
  FormFieldDefinition,
  FormFieldsDefinition
} from '../../../../../../../../util/form.utils';
import {nameof} from '../../../../../../../../util/utils';
import {MenuItemSizeDef} from '../price-editor.form-fields-definition';

@Component({
             selector: 'app-editable-prices-list',
             templateUrl: './editable-prices-list.component.html',
             styleUrls: ['./editable-prices-list.component.scss'],
             encapsulation: ViewEncapsulation.None,
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: forwardRef(() => EditablePricesListComponent),
                 multi: true
               },
               {
                 provide: NG_VALIDATORS,
                 useExisting: EditablePricesListComponent,
                 multi: true
               }
             ]
           })
export class EditablePricesListComponent implements ControlValueAccessor, OnDestroy {

  private readonly destroyed$: Subject<any> = new Subject<any>();
  private initialized: boolean = false;

  public formDef: FormDefinition<keyof PricesListFormFieldsDefinition>;

  public static convertSizeToFormGroup(size: ExistingMenuItemSize,
                                       formBuilder: FormBuilder): FormDefinition<keyof MenuItemSizeDef> {

    const sizeFieldsDef: FormFieldsDefinition<keyof MenuItemSizeDef> = {
      id: new FormFieldDefinition(size.id, false, [], []),
      name: new FormFieldDefinition(size.name, false, [Validators.required], []),
      amount: new FormFieldDefinition(size.amount, false, [Validators.required], []),
      price: new FormFieldDefinition(size.price, false, [Validators.required], []),
      sortingOrder: new FormFieldDefinition(size.sortingOrder, false, [], []),
    };

    const sizeForm = new FormDefinition(sizeFieldsDef, formBuilder);

    return sizeForm;
  }

  public static newSizeFormGroup(formBuilder: FormBuilder): FormDefinition<keyof MenuItemSizeDef> {

    const sizeFieldsDef: FormFieldsDefinition<keyof MenuItemSizeDef> = {
      id: new FormFieldDefinition(null, false, [], []),
      name: new FormFieldDefinition(null, false, [Validators.required], []),
      amount: new FormFieldDefinition(null, false, [Validators.required], []),
      price: new FormFieldDefinition(null, false, [Validators.required], []),
      sortingOrder: new FormFieldDefinition(null, false, [Validators.required], []),
    };

    const sizeForm = new FormDefinition(sizeFieldsDef, formBuilder);

    return sizeForm;
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  constructor(private readonly formBuilder: FormBuilder) {
    const fieldsDef: FormFieldsDefinition<keyof PricesListFormFieldsDefinition> = {
      items: new FormArrayDefinition([], ['']),
    };

    this.formDef = new FormDefinition(fieldsDef, this.formBuilder);

    this.formDef
        .form
        .valueChanges
        .pipe(
          filter(x => this.initialized),
          tap(x => {
            this.triggerOnChange();
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe();
  }

  private resetSortingOrderOnAllItems() {
    const prices = this.formDef.getFormArray('items');

    for (let i = 0; i < prices.length; i++) {
      prices.at(i).get(nameof<MenuItemSizeDef>('sortingOrder'))!.setValue(i);
    }
  }

  private triggerOnChange() {
    const formValue: PricesListFormFieldsDefinition = this.formDef.form.getRawValue();

    this.onChange(formValue.items);
    this.onTouched();
  }

  public delete(idx: number) {
    const prices = this.formDef.getFormArray('items');

    prices.removeAt(idx);

    this.resetSortingOrderOnAllItems();

    this.triggerOnChange();
  }

  public moveUp(idx: number) {
    const prices = this.formDef.getFormArray('items');
    const elementToMoveUp = prices.at(idx);

    prices.removeAt(idx);
    prices.insert(idx - 1, elementToMoveUp);

    this.resetSortingOrderOnAllItems();

    this.triggerOnChange();
  }

  public moveDown(idx: number) {
    const prices = this.formDef.getFormArray('items');
    const elementToMoveUp = prices.at(idx);

    prices.insert(idx + 2, elementToMoveUp);
    prices.removeAt(idx);

    this.resetSortingOrderOnAllItems();

    this.triggerOnChange();
  }

  public addNew() {
    const prices = this.formDef.getFormArray('items');
    const sizeForm = EditablePricesListComponent.newSizeFormGroup(this.formBuilder);

    prices.push(sizeForm.form);

    this.resetSortingOrderOnAllItems();

    this.triggerOnChange();
  }


  public validate(control: AbstractControl): ValidationErrors | null {
    if (control.invalid || this.formDef.form.invalid) {
      return { prices: true };
    }

    return null;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(inputSizes: ExistingMenuItemSize[] | null): void {
    this.initialized = false;

    const source = inputSizes == null ? [] : inputSizes;
    const sizeControls = source.sort((a, b) => a.sortingOrder - b.sortingOrder)
                               .map(x => EditablePricesListComponent.convertSizeToFormGroup(x, this.formBuilder).form);
    const itemsFormArray = this.formDef.getFormArray('items');

    itemsFormArray.clear();

    for (const item of sizeControls) {
      itemsFormArray.push(item);
    }

    this.initialized = true;
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
