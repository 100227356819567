/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./category-image-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "./category-image-selector.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
var styles_CategoryImageSelectorComponent = [i0.styles];
var RenderType_CategoryImageSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoryImageSelectorComponent, data: {} });
export { RenderType_CategoryImageSelectorComponent as RenderType_CategoryImageSelectorComponent };
function View_CategoryImageSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageName; _ck(_v, 1, 0, currVal_0); }); }
function View_CategoryImageSelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "..."])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Choose an image")); _ck(_v, 1, 0, currVal_0); }); }
function View_CategoryImageSelectorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-danger btn-clear-category-image"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearPreviewImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-trash-empty"]], null, null, null, null, null))], null, null); }
export function View_CategoryImageSelectorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { categoryImageFileInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["categoryImageFileInput", 1]], null, 0, "input", [["class", "inputfile"], ["id", "category-image"], ["name", "category-image[]"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "label", [["class", "tx-white bg-info btn-select-category-image"], ["for", "category-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon ion-ios-upload-outline tx-24"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoryImageSelectorComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoryImageSelectorComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoryImageSelectorComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.imageName != null); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.imageName == null); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.imageName != null); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("An image must be at least 200x200 pixel large")); _ck(_v, 12, 0, currVal_3); }); }
export function View_CategoryImageSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-category-image-selector", [], null, null, null, View_CategoryImageSelectorComponent_0, RenderType_CategoryImageSelectorComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.CategoryImageSelectorComponent]), i1.ɵdid(2, 49152, null, 0, i5.CategoryImageSelectorComponent, [i4.FormBuilder, i2.TranslateService, i6.NgbModal], null, null)], null, null); }
var CategoryImageSelectorComponentNgFactory = i1.ɵccf("app-category-image-selector", i5.CategoryImageSelectorComponent, View_CategoryImageSelectorComponent_Host_0, {}, {}, []);
export { CategoryImageSelectorComponentNgFactory as CategoryImageSelectorComponentNgFactory };
