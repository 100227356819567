/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./binary-choice-switch.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../dist/orderly-web-components/orderly-web-components.ngfactory";
import * as i3 from "orderly-web-components";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "./yes-no-switch.component";
import * as i7 from "@ngx-translate/core";
var styles_YesNoSwitchComponent = [i0.styles];
var RenderType_YesNoSwitchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_YesNoSwitchComponent, data: {} });
export { RenderType_YesNoSwitchComponent as RenderType_YesNoSwitchComponent };
export function View_YesNoSwitchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "owc-buttons-group-switch", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = (_co.changed($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonsGroupSwitchComponent_0, i2.RenderType_ButtonsGroupSwitchComponent)), i1.ɵdid(1, 49152, null, 0, i3.ButtonsGroupSwitchComponent, [], { items: [0, "items"], notSelectedButtonClass: [1, "notSelectedButtonClass"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.ButtonsGroupSwitchComponent]), i1.ɵdid(4, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(6, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.enabledDisabledItems$)); var currVal_8 = _co.notSelectedButtonClass; _ck(_v, 1, 0, currVal_7, currVal_8); var currVal_9 = _co.isDisabled; var currVal_10 = _co.lastValue; _ck(_v, 4, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_YesNoSwitchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-shared-yes-no-switch", [], null, null, null, View_YesNoSwitchComponent_0, RenderType_YesNoSwitchComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.YesNoSwitchComponent]), i1.ɵdid(2, 245760, null, 0, i6.YesNoSwitchComponent, [i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var YesNoSwitchComponentNgFactory = i1.ɵccf("app-shared-yes-no-switch", i6.YesNoSwitchComponent, View_YesNoSwitchComponent_Host_0, { notSelectedButtonClass: "notSelectedButtonClass", selectedTrueButtonClass: "selectedTrueButtonClass", selectedFalseButtonClass: "selectedFalseButtonClass" }, {}, []);
export { YesNoSwitchComponentNgFactory as YesNoSwitchComponentNgFactory };
