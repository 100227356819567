import {Observable, throwError} from 'rxjs';
import {ToastService} from 'orderly-web-components';
import {TranslateService} from '@ngx-translate/core';
import Allergen = Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Allergen;
import ExistingTable = Orderly.RestaurantWeb.Api.Messages.ExistingTable;
import {trnCheckInternetAndTryAgainLaterText} from './trn.utils';

export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export function ensureNumber(value: number | string): number {
  if (typeof value === 'number') {
    return value;
  }

  return parseFloat(value);
}

export function ensureNumberOrNull(value: number | string | null): number | null {
  if (value == null || value === '') {
    return null;
  }

  if (typeof value === 'number') {
    return value;
  }

  return parseFloat(value);
}

// TODO: remove
export function parseInteger(value: string | null): number | null {
  if (value == null || value === '') {
    return null;
  }

  return parseInt(value, 10);
}


export function formatTableFileNameWithoutExtension(table: ExistingTable) {
  const tmp = formatFileNameWithoutExtension(table.name);

  return `${tmp}_${table.id}`;
}

export function formatFileNameWithoutExtension(source: string) {
  const tmp = source.replace('/', '')
                    .replace('\\', '')
                    .replace('\'', ' ')
                    .replace('"', ' ')
                    .replace('.', '_')
                    .replace('  ', ' ');

  return tmp;
}

export function genericErrorHandlerWithToast<T>(toastService: ToastService,
                                                trnService: TranslateService,
                                                serverErrorMsg: string)
  : (err: any, caught: Observable<T>) => Observable<never> {

  return (err: any) => {
    if (err.status > 0) {
      toastService.showError(serverErrorMsg);
    } else {
      toastService.showError(trnCheckInternetAndTryAgainLaterText(trnService));
    }

    return throwError(err);
  };
}

export function compareAllergens(a1: Allergen, a2: Allergen): boolean {
  if (a1 != null && a2 == null || a1 == null && a2 != null) {
    return false;
  }

  if (a1 == null || a2 == null) {
    return false;
  }

  const code1 = a1.code.toLocaleLowerCase();
  const code2 = a2.code.toLocaleLowerCase();

  return code1 === code2;
};
