import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import { ActionTypes } from '../actions/action-types';
import { map, switchMap, tap } from 'rxjs/operators';
import { LocalStorageService } from '../../../services/local-storage.service';
export class RestaurantEffect {
    constructor(actions, restaurantService, store) {
        this.actions = actions;
        this.restaurantService = restaurantService;
        this.store = store;
        // TODO:
        // @Effect()
        // loadDashboardDataEffect: Observable<Action> = this.actions.pipe(
        //   ofType<LoadRestaurantDashboardDataAction>(ActionTypes.RESTAURANT_LOAD_DASHBOARD_DATA),
        //
        //   exhaustMap((action: LoadRestaurantDashboardDataAction) => {
        //     return this.restaurantService
        //                .getRestaurant(action.restaurantId)
        //                .pipe(
        //                  map((data: GetRestaurantResponse) => {
        //                    return new RestaurantDashboardDataLoadedSuccessfullyAction(data);
        //                  }),
        //                  catchError((err, _) => {
        //                    return of(new RestaurantDashboardDataLoadFailedAction(err));
        //                  })
        //                );
        //   })
        // );
        this.onboardingCompletedEffect = this.actions.pipe(ofType(ActionTypes.RESTAURANT_ONBOARDING_COMPLETED), switchMap((action) => {
            return this.restaurantService.completeOnboarding(action.restaurantHoldingId);
        }), switchMap((action) => {
            const authUser$ = this.store.select(x => x.auth);
            return authUser$.pipe(map(user => {
                return {
                    action: action,
                    authState: user
                };
            }));
        }), tap(data => {
            if (data.authState.currentUser != null) {
                const clonedUser = Object.assign({}, data.authState.currentUser.user);
                clonedUser.holdingInfo.isOnboardingCompleted = true;
                LocalStorageService.setCurrentUser(data.authState.currentUser.jwtTokenBase64, clonedUser);
            }
        }), map(x => x.action));
        this.restaurantUpdatedEffect = this.actions.pipe(ofType(ActionTypes.RESTAURANT_UPDATE_SUCCESSFUL), tap((action) => {
            const currentUser = LocalStorageService.getCurrentUser();
            if (currentUser != null) {
                const userWithRestaurantsInfo = Object.assign({}, currentUser.user);
                const updatedRestaurantIdx = userWithRestaurantsInfo.restaurants.findIndex(x => x.id === action.restaurant.id);
                if (updatedRestaurantIdx > -1) {
                    const updatedRestaurantClone = Object.assign({}, userWithRestaurantsInfo.restaurants[updatedRestaurantIdx]);
                    updatedRestaurantClone.name = action.restaurant.name;
                    userWithRestaurantsInfo.restaurants[updatedRestaurantIdx] = updatedRestaurantClone;
                    LocalStorageService.setCurrentUser(currentUser.jwtTokenBase64, userWithRestaurantsInfo);
                }
            }
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false })
], RestaurantEffect.prototype, "onboardingCompletedEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false })
], RestaurantEffect.prototype, "restaurantUpdatedEffect", void 0);
