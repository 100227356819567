import { ConfirmationModalDialogComponent, ModalMessageTextParagraph } from 'orderly-web-components';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@ngx-translate/core";
export class DeleteModalUtils {
    constructor(modalService, trnService) {
        this.modalService = modalService;
        this.trnService = trnService;
    }
    showDeleteModal(subject, title, dialogText, onDelete, deleteBtnText = _('Delete')) {
        const modalRef = this.modalService.open(ConfirmationModalDialogComponent, { centered: true, size: 'lg' });
        const modalInstance = modalRef.componentInstance;
        modalInstance.cancelButtonText = this.trnService.instant('Cancel');
        modalInstance.okButtonText = this.trnService.instant(deleteBtnText);
        modalInstance.data = subject;
        modalInstance.title = title;
        modalInstance.messages = dialogText.map(x => new ModalMessageTextParagraph(x));
        const completedSubscription = modalInstance.completed.subscribe(($e) => {
            onDelete(modalRef);
        });
        modalRef.result.finally(() => {
            completedSubscription.unsubscribe();
        });
    }
}
DeleteModalUtils.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeleteModalUtils_Factory() { return new DeleteModalUtils(i0.ɵɵinject(i1.NgbModal), i0.ɵɵinject(i2.TranslateService)); }, token: DeleteModalUtils, providedIn: "root" });
