import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Currency, GetAllStaticDataResponse, StaticDataService} from 'orderly-web-components';
import {environment} from '../../../../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {EMPTY, Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';

@Component({
             selector: 'app-shared-currency-select',
             templateUrl: './currency-select.component.html',
             styleUrls: ['./currency-select.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: CurrencySelectComponent,
                 multi: true,
               },
             ],
           })
export class CurrencySelectComponent implements ControlValueAccessor, OnInit {

  public currencies$: Observable<Currency[]>;

  public lastValue: Currency | null = null;

  public isDisabled: boolean = false;

  public translatedPlaceholder$: Observable<string> = EMPTY;

  @Input()
  public placeholder: string = _('Select currency');


  constructor(private readonly staticDataService: StaticDataService,
              private readonly trnService: TranslateService) {

    this.currencies$ = this.staticDataService
                           .getAllStaticData(environment.baseApiUrlWithTrailingSlash)
                           .pipe(
                             map((x: GetAllStaticDataResponse) => x.currencies),
                             tap((x: Currency[]) => {

                               if (x.length === 0) {
                                 this.isDisabled = true;
                               }
                             })
                           );
  }

  public ngOnInit(): void {
    this.translatedPlaceholder$ = this.placeholder == null || this.placeholder === ''
                                  ? of('')
                                  : this.trnService.get(this.placeholder);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: number | null) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: Currency | null): void {

    this.lastValue = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  changed(newValue: Currency) {
    this.lastValue = newValue;

    this.onTouched();
    this.onChange(newValue);
  }

  public compareItems(a: Currency | null, b: Currency | null): boolean {
    if (a == null || b == null) {
      return false;
    }

    return a.id === b.id;
  }
}
