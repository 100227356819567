import {Action} from '@ngrx/store';
import {ActionTypes} from './action-types';
import AddOrUpdateRestaurantAreaResponse = Orderly.RestaurantWeb.Api.Messages.Area.AddOrUpdateRestaurantAreaResponse;

export class AreaCreationSuccessfulAction implements Action {
  readonly type = ActionTypes.AREA_CREATE_SUCCESSFUL;

  constructor(public readonly data: AddOrUpdateRestaurantAreaResponse) {
  }
}

export type AreaActions = AreaCreationSuccessfulAction;
