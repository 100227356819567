import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {EMPTY, Observable} from 'rxjs';
import {GetAllStaticDataResponse, Language, StaticDataService} from 'orderly-web-components';
import {environment} from '../../../../../environments/environment';
import {map, tap} from 'rxjs/operators';

@Component({
             selector: 'app-shared-language-select',
             templateUrl: './language-select.component.html',
             styleUrls: ['./language-select.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: LanguageSelectComponent,
                 multi: true,
               },
             ],
           })
export class LanguageSelectComponent implements ControlValueAccessor, OnInit {

  @Input()
  public languages$: Observable<Language[]> = EMPTY;

  public lastValue: number | null = null;

  public isDisabled: boolean = false;


  constructor(private readonly staticDataService: StaticDataService) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: number | null) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: number): void {
    this.lastValue = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  changed(newValue: number) {
    this.lastValue = newValue;

    this.onTouched();
    this.onChange(newValue);
  }

  ngOnInit(): void {
    if (this.languages$ === EMPTY) {
      this.languages$ = this.staticDataService
                            .getAllStaticData(environment.baseApiUrlWithTrailingSlash)
                            .pipe(
                              map((x: GetAllStaticDataResponse) => x.allLanguages),
                              tap((x: Language[]) => {

                                if (x.length > 0) {
                                  this.isDisabled = true;
                                }
                              })
                            );
    }
  }

}
