import {Component, Input} from '@angular/core';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../util/form.utils';
import {SetPinFormFieldsDef} from './form-fields-definitions';
import ExistingTerminal = Orderly.RestaurantWeb.Api.Messages.Restaurant.ExistingTerminal;
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {isNotNullOrEmptyOrWhiteSpaceValidator, NeverError, ToastService} from 'orderly-web-components';
import {TranslateService} from '@ngx-translate/core';
import {CurrentRestaurantTerminalsService} from '../../../../services/active-route-bound/current-restaurant-terminals.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {RestaurantComponent} from '../../../restaurant.component';
import {genericErrorHandlerWithToast, nameof} from '../../../../util/utils';
import SetTerminalPinRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.SetTerminalPinRequest;
import {catchError, finalize, first, tap} from 'rxjs/operators';
import AddOrUpdateTerminalResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddOrUpdateTerminalResponse;

@Component({
  selector: 'app-set-pin-modal',
  templateUrl: './set-pin-modal.component.html',
  styleUrls: ['./set-pin-modal.component.scss']
})
export class SetPinModalComponent extends RestaurantComponent {

  public actionInProgress: boolean = false;

  public formDef: FormDefinition<keyof SetPinFormFieldsDef>;

  @Input()
  public existingTerminal: ExistingTerminal;


  constructor(public modalService: NgbActiveModal,
              private formBuilder: FormBuilder,
              private toastService: ToastService,
              private trnService: TranslateService,
              private terminalService: CurrentRestaurantTerminalsService,
              router: Router,
              activatedRoute: ActivatedRoute,
              store: Store<AppState>) {

    super(store, activatedRoute);

    const fieldsDef: FormFieldsDefinition<keyof SetPinFormFieldsDef> = {
      pin: new FormFieldDefinition(null,
                                   false,
                                   [
                                     isNotNullOrEmptyOrWhiteSpaceValidator,
                                     Validators.minLength(4),
                                     Validators.maxLength(4)
                                   ],
                                   [
                                     nameof<SetTerminalPinRequest>('pin'),
                                   ]),
    };

    this.formDef = new FormDefinition(fieldsDef, this.formBuilder);
  }

  public save() {
    if (this.formDef.form.invalid) {
      return;
    }

    const formValue: SetPinFormFieldsDef = this.formDef.form.getRawValue();

    const unexpectedErrorMsg = this.trnService.instant('Failed to set a new pin because of unexpected failure.');

    this.actionInProgress = true;

    this.terminalService
        .setPin(this.existingTerminal.id, formValue.pin)
        .pipe(
          first(),
          tap((response: AddOrUpdateTerminalResponse) => {
            switch (response.status) {
              case AddOrUpdateTerminalResponse.StatusDef.Success:
                this.toastService.showSuccess(this.trnService.instant('New pin was successfully set'));

                this.modalService.close(response);
                break;
              case AddOrUpdateTerminalResponse.StatusDef.ValidationFailed:
                this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
                break;
              case AddOrUpdateTerminalResponse.StatusDef.UnexpectedError:
                this.toastService.showError(unexpectedErrorMsg);
                break;
              default:
                throw new NeverError(response.status);
            }
          }),
          catchError(
            genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedErrorMsg)
          ),
          finalize(() => {
            this.actionInProgress = false;
          })
        )
        .subscribe();
  }
}
