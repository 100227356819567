import {FormGroup} from '@angular/forms';
import {nameof} from '../../../../../../../util/utils';
import {PriceEditorFormFieldsDefinition} from './price-editor.form-fields-definition';

export function measureUnitValidator() {
  return (formGroup: FormGroup) => {
    const hasMultipleSizesControl = formGroup.controls[nameof<PriceEditorFormFieldsDefinition>('hasMultipleSizes')];
    const measureUnitControl = formGroup.controls[nameof<PriceEditorFormFieldsDefinition>('measureUnit')];
    const amountControl = formGroup.controls[nameof<PriceEditorFormFieldsDefinition>('amount')];

    if (hasMultipleSizesControl.value === false) {
      if (amountControl.value == null || amountControl.value === '' || amountControl.value === 0) {
        amountControl.setErrors(null);

        return;
      }
    }

    if (measureUnitControl.value == null || measureUnitControl.value === '') {
      measureUnitControl.setErrors({requiredIfSelectedValidator: true});
    } else {
      amountControl.setErrors(null);
    }
  };
}

export function sizesValidator() {
  return (formGroup: FormGroup) => {
    const hasMultipleSizesControl = formGroup.controls[nameof<PriceEditorFormFieldsDefinition>('hasMultipleSizes')];
    const sizesControl = formGroup.controls[nameof<PriceEditorFormFieldsDefinition>('sizes')];
    const sizesArray: [] | null = sizesControl.value;

    if (hasMultipleSizesControl.value === false) {
      sizesControl.setErrors(null);

      return;
    }

    if (sizesArray == null || sizesArray.length === 0) {
      sizesControl.setErrors({requiredIfSelectedValidator: true});
    } else {
      sizesControl.setErrors(null);
    }
  };
}
