import moment from 'moment';
import GetCovidTableCheckinListResponseItem = Orderly.RestaurantWeb.Api.Messages.Checkin.GetCovidTableCheckinListResponse.Item;
import ExistingTable = Orderly.RestaurantWeb.Api.Messages.ExistingTable;
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import ExistingTableWithAreaAndDisplays = Orderly.RestaurantWeb.Api.Messages.ExistingTableWithAreaAndDisplays;

export class CheckinsListGridItem {

  public isSelected: boolean = false;

  public readonly checkinOn: moment.Moment;

  public readonly checkinOnLocal: moment.Moment;


  public get tabelName(): string {
    return this.source.tableName;
  }

  public get id(): string {
    return this.source.id;
  }

  constructor(public readonly source: GetCovidTableCheckinListResponseItem) {
    this.checkinOn = moment.utc(source.checkinOn);
    this.checkinOnLocal = this.checkinOn.local();
  }

}

export class TableSelectItem {

  public readonly name: string;
  public readonly areaName: string;

  constructor(public readonly source: ExistingTableWithAreaAndDisplays) {
    this.name = source.name;
    this.areaName = source.area.name;
  }
}

export interface SearchFormFieldsDefinition {
  checkinOn: NgbDateStruct;
  table: ExistingTable;
}
