import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, throwError} from 'rxjs';
import {UserService} from '../../services/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  LeftMenuItem,
  ToastService,
  UpdateUserProfileComponent,
  UpdateUserProfileModel,
  ChangePasswordComponent,
  PasswordChangeStatus,
  StaticDataService,
  GetAllStaticDataResponse,
  Language,
  LanguagesConfiguration,
  TopNavigationBarConfiguration,
  MenuItemSelectedData,
  ChangePasswordComponentConfiguration,
  UserData,
} from 'orderly-web-components';
import {Store} from '@ngrx/store';
import {AppState, LeftMenuState} from '../store/app.state';
import {catchError, filter, first, map, startWith, tap} from 'rxjs/operators';
import {getAuthenticatedUserSelector} from '../store/selectors/restaurant-selectors';
import {HttpErrorResponse} from '@angular/common/http';
import {
  CurrentUserInformationChangedAction,
  CurrentUserInformationLoadedAction, CurrentUserSelectedLanguageChangedAction,
  LogoutAction
} from '../store/actions/user.actions';
import {environment} from '../../environments/environment';
import {AppUser} from '../../models/app.models';
import GetCurrentUserResponse = Orderly.RestaurantWeb.Api.Messages.User.GetCurrentUserResponse;
import UserWithRestaurantsInfo = Orderly.RestaurantWeb.Api.Messages.UserWithRestaurantsInfo;
import ChangePasswordResponse = Orderly.RestaurantWeb.Api.Messages.User.ChangePasswordResponse;
import StatusDef = Orderly.RestaurantWeb.Api.Messages.User.ChangePasswordResponse.StatusDef;
import UpdateCurrentUserResponse = Orderly.RestaurantWeb.Api.Messages.User.UpdateCurrentUserResponse;
import UpdateCurrentUserResponseStatusDef = Orderly.RestaurantWeb.Api.Messages.User.UpdateCurrentUserResponse.StatusDef;
import {TranslateService} from '@ngx-translate/core';
import {trnCheckInternetAndTryAgainLaterText, trnTryAgainLaterText} from '../../util/trn.utils';
import {TopNavTranslations} from './translation-definitions';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {getCurrentLanguageSelector} from '../store/selectors/localization.selectors';
import {convertLeftMenuState} from './left-menu.helper-functions';
import {LeftMenuItemSelectedAction} from '../store/actions/left-menu.actions';
import {Router} from '@angular/router';

@Component({
             selector: 'app-authenticated-user',
             templateUrl: './authenticated-user.component.html',
             styleUrls: ['./authenticated-user.component.scss']
           })
export class AuthenticatedUserComponent implements OnInit {
  public user: Observable<UserData>;
  public leftMenuItems$: Observable<LeftMenuItem[]>;
  public actionInProgress: boolean = false;
  public topNavigationBarCfg$: Observable<TopNavigationBarConfiguration>;

  constructor(private userService: UserService,
              private modalService: NgbModal,
              private store: Store<AppState>,
              private toastService: ToastService,
              private staticDataService: StaticDataService,
              private trnService: TranslateService,
              router: Router) {

    const leftMenu$ = this.store.select(x => x.leftMenu);
    const currentLang$ = this.trnService.onLangChange.pipe(map(x => x.lang), startWith(trnService.currentLang));

    this.leftMenuItems$ = combineLatest([leftMenu$, currentLang$])
      .pipe(
        map((x: [LeftMenuState, string]) => {
          const result = convertLeftMenuState(x[0], this.trnService, router);
          return result;
        })
      );

  }

  ngOnInit() {
    this.user = this.store
                    .select(getAuthenticatedUserSelector)
                    .pipe(
                      filter(x => x != null),
                      map((x: UserWithRestaurantsInfo) => {
                            return new UserData(x.id, x.firstName, x.lastName, x.preferredLang);
                          }
                      )
                    );

    const currentLang$ = this.store.select(getCurrentLanguageSelector);
    const staticData$ = this.staticDataService.getAllStaticData(environment.baseApiUrlWithTrailingSlash);
    const topNavTranslations$ = this.getTopNavTranslations();

    this.topNavigationBarCfg$ = combineLatest([currentLang$, staticData$, topNavTranslations$])
      .pipe(
        map((data: [Language, GetAllStaticDataResponse, TopNavTranslations]) => {
          const activeLanguage = data[0];
          const staticData = data[1];
          const translations = data[2];

          const langsConfig: LanguagesConfiguration = {
            languagesTitleLabel: translations.languagesLabel,
            langs: staticData.adminSupportedLanguages,
            activeLanguage: activeLanguage
          };

          return {
            changeMyPasswordLabel: translations.changeMyPasswordLabel,
            editProfileLabel: translations.editProfileLabel,
            signOutLabel: translations.signOutLabel,
            langsConfig: langsConfig
          };
        })
      );
  }

  private getTopNavTranslations(): Observable<TopNavTranslations> {
    const languagesTrnKey = _('Languages');
    const changeMyPasswordTrnKey = _('Change my password');
    const editProfileTrnKey = _('Edit Profile');
    const signOutTrnKey = _('Sign Out');

    return this.trnService
               .stream([languagesTrnKey, changeMyPasswordTrnKey, editProfileTrnKey, signOutTrnKey])
               .pipe(
                 map(x => {
                   return {
                     languagesLabel: x[languagesTrnKey],
                     changeMyPasswordLabel: x[changeMyPasswordTrnKey],
                     editProfileLabel: x[editProfileTrnKey],
                     signOutLabel: x[signOutTrnKey]
                   };
                 })
               );
  }

  public logout() {
    this.store.dispatch(new LogoutAction('user initiated'));
  }

  public onUpdateUserProfile() {
    this.userService
        .getCurrentUser('from-server')
        .pipe(
          first(),
          tap((x: GetCurrentUserResponse) => {
                this.store.dispatch(new CurrentUserInformationLoadedAction(x));

                this.openEditUserDetailsModal(x);
              }
          ),
          catchError((err: HttpErrorResponse) => {
            this.toastService.showError(
              'Connection error. Please check if you have internet connection and try again.');

            return of(null);
          })
        )
        .subscribe();
  }


  private openEditUserDetailsModal(getCurrentUserResponse: GetCurrentUserResponse) {
    const modalRef = this.modalService.open(UpdateUserProfileComponent, {centered: true, size: 'md'});
    const userToEdit: UpdateUserProfileModel = {
      firstName: getCurrentUserResponse.firstName,
      lastName: getCurrentUserResponse.lastName,
      id: getCurrentUserResponse.id,
      preferredLanguage: getCurrentUserResponse.preferredLang
    };
    modalRef.componentInstance.user = userToEdit;
    modalRef.componentInstance.environment = environment;

    const subscription = modalRef.componentInstance.completed.subscribe(($e: UpdateUserProfileModel) => {
      const updatedUserData: AppUser = {
        id: getCurrentUserResponse.id,
        restaurantHoldingId: getCurrentUserResponse.holdingInfo.id,
        firstName: $e.firstName,
        lastName: $e.lastName,
        preferredLanguage: $e.preferredLanguage
      };

      this.userService
          .updateUser(updatedUserData)
          .pipe(
            first(),
            tap((x: UpdateCurrentUserResponse) => {
              switch (x.status) {
                case UpdateCurrentUserResponseStatusDef.Success:
                  modalRef.close();

                  this.store.dispatch(new CurrentUserInformationChangedAction(updatedUserData.preferredLanguage,
                                                                              updatedUserData.firstName,
                                                                              updatedUserData.lastName));
                  break;
                case UpdateCurrentUserResponseStatusDef.ValidationFailed:
                  this.toastService.showError('Failed to update user details. ' +
                                              'Please check if you entered information correctly.');
                  break;
              }
            }),
            catchError((err, caught) => {
              this.toastService.showError('Failed to update user details because of unexpected error. ' +
                                          'Please check if you have Internet connection and try again.');

              return of(null);
            })
          )
          .subscribe();
    });

    modalRef.result.finally(() => subscription.unsubscribe());
  }

  public onChangeUserPassword() {
    this.store
        .select(getAuthenticatedUserSelector)
        .pipe(
          first(),
          tap((user: UserWithRestaurantsInfo | null) => {
            if (user != null) {
              this.doChangePassword(user);
            }
          })
        )
        .subscribe();
  }

  private doChangePassword(user: UserWithRestaurantsInfo) {
    const modalRef = this.modalService.open(ChangePasswordComponent, {centered: true, size: 'md'});
    const lastPasswordChangeStatus = new BehaviorSubject<PasswordChangeStatus>(PasswordChangeStatus.Start);
    const failedToChangePasswordMsg = this.trnService.instant('Could not change password because of unknown error.');
    const tryAgainMsg = trnTryAgainLaterText(this.trnService);
    const serverErrorMsg = `${failedToChangePasswordMsg} ${tryAgainMsg}`;

    const modalCfg: ChangePasswordComponentConfiguration = {
      changeButtonText: this.trnService.instant('Change'),
      modalTitle: this.trnService.instant('Change Password'),
      newPasswordConfirmationLabel: this.trnService.instant('New password confirmation'),
      newPasswordLabel: this.trnService.instant('New password'),
      oldPasswordLabel: this.trnService.instant('Old password'),
      oldPasswordDoesNotMatchErrorText: this.trnService.instant('Old password does not match.')
    };

    modalRef.componentInstance.status = lastPasswordChangeStatus;
    modalRef.componentInstance.cfg = modalCfg;

    const subscription = modalRef.componentInstance.completed.subscribe(($e) => {
      const response = this.userService.changePassword(user.id, $e.oldPassword, $e.newPassword);

      response.pipe(
        first(),
        tap((x: ChangePasswordResponse) => {
          switch (x.status) {
            case StatusDef.Success:
              const successMsg = this.trnService.instant('Password changed');
              this.toastService.showSuccess(successMsg);
              lastPasswordChangeStatus.next(PasswordChangeStatus.Success);
              modalRef.close();
              break;
            case StatusDef.UserWasNotFound:
              const userWasNotFoundMsg = this.trnService.instant('User not found');
              this.toastService.showError(userWasNotFoundMsg);
              lastPasswordChangeStatus.next(PasswordChangeStatus.Error);
              break;
            case StatusDef.ValidationFailed:
              const validationFailedMsg = this.trnService.instant('Validation failed');
              this.toastService.showError(validationFailedMsg);
              lastPasswordChangeStatus.next(PasswordChangeStatus.Error);
              break;
            case StatusDef.UnknownError:
              this.toastService.showError(serverErrorMsg);
              lastPasswordChangeStatus.next(PasswordChangeStatus.Error);
              break;
            case StatusDef.OldPasswordIsWrong:
              const oldPasswordIsWrongMsg = this.trnService.instant('Old password does not match.');
              this.toastService.showError(oldPasswordIsWrongMsg);
              lastPasswordChangeStatus.next(PasswordChangeStatus.OldPasswordIsWrong);
              break;
          }
        }),
        catchError((err, caught) => {
          if (err.status > 0) {
            this.toastService.showError(serverErrorMsg);
          } else {
            this.toastService.showError(trnCheckInternetAndTryAgainLaterText(this.trnService));
          }

          return throwError(err);
        })
      ).subscribe();
    });

    modalRef.result.finally(() => subscription.unsubscribe());
  }

  public onChangeLanguage(lang: Language): void {
    this.store.dispatch(new CurrentUserSelectedLanguageChangedAction(lang));
  }

  public menuItemSelected(data: MenuItemSelectedData): void {
    const selectedId = data.subItem == null ? data.rootItem.id : data.subItem.id;

    this.store.dispatch(new LeftMenuItemSelectedAction(selectedId));
  }
}
