/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./table-qr-code-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../../dist/orderly-web-components/orderly-web-components.ngfactory";
import * as i4 from "orderly-web-components";
import * as i5 from "./table-qr-code-modal.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../../services/active-route-bound/current-restaurant-tables.service";
var styles_TableQrCodeModalComponent = [i0.styles];
var RenderType_TableQrCodeModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TableQrCodeModalComponent, data: {} });
export { RenderType_TableQrCodeModalComponent as RenderType_TableQrCodeModalComponent };
function View_TableQrCodeModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "img", [["height", "260"], ["style", "display: block; margin: 0 auto;"], ["width", "260"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "hidden-xs-up": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, _co.base64QrImageContentLoading); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.domSanitizationService.bypassSecurityTrustUrl(("data:image/jpeg;base64," + _v.context.ngIf)); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.translationsCfg.modalTitle, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_TableQrCodeModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header pd-y-20 pd-x-25"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h6", [["class", "tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalService.dismiss(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "modal-body pd-25 align-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TableQrCodeModalComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "w-100 h-50 align-content-center"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(13, { "hidden-xs-up": 0 }), (_l()(), i1.ɵeld(14, 0, null, null, 1, "owc-progress-wave", [], null, null, null, i3.View_ProgressWaveComponent_0, i3.RenderType_ProgressWaveComponent)), i1.ɵdid(15, 49152, null, 0, i4.ProgressWaveComponent, [], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 6, "div", [["class", "modal-footer tx-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "owc-progress-action-button", [["type", "link"]], [[2, "d-block", null]], [[null, "execute"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.downloadAsPdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ProgressActionButtonComponent_0, i3.RenderType_ProgressActionButtonComponent)), i1.ɵdid(18, 114688, null, 0, i4.ProgressActionButtonComponent, [], { disabled: [0, "disabled"], actionInProgress: [1, "actionInProgress"], btnClass: [2, "btnClass"], btnText: [3, "btnText"], type: [4, "type"] }, { execute: "execute" }), (_l()(), i1.ɵeld(19, 0, null, null, 1, "owc-progress-action-button", [["type", "link"]], [[2, "d-block", null]], [[null, "execute"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.downloadAsImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ProgressActionButtonComponent_0, i3.RenderType_ProgressActionButtonComponent)), i1.ɵdid(20, 114688, null, 0, i4.ProgressActionButtonComponent, [], { disabled: [0, "disabled"], actionInProgress: [1, "actionInProgress"], btnClass: [2, "btnClass"], btnText: [3, "btnText"], type: [4, "type"] }, { execute: "execute" }), (_l()(), i1.ɵeld(21, 0, null, null, 1, "owc-progress-action-button", [["type", "link"]], [[2, "d-block", null]], [[null, "execute"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("execute" === en)) {
        var pd_0 = (_co.downloadAsSticker() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ProgressActionButtonComponent_0, i3.RenderType_ProgressActionButtonComponent)), i1.ɵdid(22, 114688, null, 0, i4.ProgressActionButtonComponent, [], { disabled: [0, "disabled"], actionInProgress: [1, "actionInProgress"], btnClass: [2, "btnClass"], btnText: [3, "btnText"], type: [4, "type"] }, { execute: "execute" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.base64QrImageContent)); _ck(_v, 8, 0, currVal_2); var currVal_3 = "w-100 h-50 align-content-center"; var currVal_4 = _ck(_v, 13, 0, !_co.base64QrImageContentLoading); _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_6 = _co.base64QrImageContentLoading; var currVal_7 = _co.downloadRunning; var currVal_8 = "btn-info btn-sm"; var currVal_9 = _co.translationsCfg.downloadAsPdf; var currVal_10 = "link"; _ck(_v, 18, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_12 = _co.base64QrImageContentLoading; var currVal_13 = _co.downloadRunning; var currVal_14 = "btn-info btn-sm"; var currVal_15 = _co.translationsCfg.downloadAsQrCodeImage; var currVal_16 = "link"; _ck(_v, 20, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_18 = _co.base64QrImageContentLoading; var currVal_19 = _co.downloadRunning; var currVal_20 = "btn-info btn-sm"; var currVal_21 = _co.translationsCfg.downloadAsStickerImage; var currVal_22 = "link"; _ck(_v, 22, 0, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translationsCfg.modalTitle; _ck(_v, 2, 0, currVal_0); var currVal_1 = "Close"; _ck(_v, 3, 0, currVal_1); var currVal_5 = i1.ɵnov(_v, 18).displayHostAsBlockElement; _ck(_v, 17, 0, currVal_5); var currVal_11 = i1.ɵnov(_v, 20).displayHostAsBlockElement; _ck(_v, 19, 0, currVal_11); var currVal_17 = i1.ɵnov(_v, 22).displayHostAsBlockElement; _ck(_v, 21, 0, currVal_17); }); }
export function View_TableQrCodeModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-qr-code-modal", [], null, null, null, View_TableQrCodeModalComponent_0, RenderType_TableQrCodeModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.TableQrCodeModalComponent, [i6.NgbActiveModal, i7.DomSanitizer, i8.TranslateService, i4.ToastService, i9.CurrentRestaurantTablesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableQrCodeModalComponentNgFactory = i1.ɵccf("app-table-qr-code-modal", i5.TableQrCodeModalComponent, View_TableQrCodeModalComponent_Host_0, { table: "table" }, {}, []);
export { TableQrCodeModalComponentNgFactory as TableQrCodeModalComponentNgFactory };
