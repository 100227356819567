<div class="restaurant-card card" *ngIf="restaurant != null">
  <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
    <h6 class="card-title tx-uppercase">
      <a [routerLink]="'edit'" style="cursor: pointer;">{{restaurant.info.name}}</a>
    </h6>
    <span><a [routerLink]="'edit'"><i class="icon-edit"></i></a></span>
  </div>

  <div class="card-body">
    <h6>{{'Contact details' | translate}}</h6>
    <div class="row">
      <div class="col-5">{{'City' | translate}}</div>
      <div class="col-7">
        <ng-container *ngIf="restaurant.info.contactDetails.zipCode != null && restaurant.info.contactDetails.zipCode.length > 0">
          {{restaurant.info.contactDetails.zipCode}}&nbsp;
        </ng-container>
        {{restaurant.info.contactDetails.city.name}}
      </div>
    </div>
    <div class="row">
      <div class="col-5">{{'Street' | translate}}</div>
      <div class="col-7">
        {{restaurant.info.contactDetails.address}}
      </div>
    </div>
    <div class="row">
      <div class="col-5">{{'Contact phone' | translate}}</div>
      <div class="col-7">
        {{restaurant.info.contactDetails.contactPhone}}
      </div>
    </div>
    <div class="row">
      <div class="col-5">{{'Contact email' | translate}}</div>
      <div class="col-7">
        {{restaurant.info.contactDetails.contactEmail}}
      </div>
    </div>

    <h6 class="pd-t-25">{{'Menu settings' | translate}}</h6>

    <div class="row">
      <div class="col-5">{{'Menu language' | translate}}</div>
      <div class="col-7">
        {{restaurant.info.settings.defaultMenuLanguage.name}}
      </div>
    </div>

    <div class="row">
      <div class="col-5">{{'Menu currency' | translate}}</div>
      <div class="col-7">
        {{restaurant.info.settings.defaultCurrency.code3 | uppercase}}
      </div>
    </div>

    <div class="row">
      <div class="col-5">{{'Online "Menu" button action' | translate}}</div>
      <div class="col-7">
        {{restaurant.info.settings.qrMenuActionType | menuButtonActionDefText | async}}
      </div>
    </div>

    <div class="row">
      <div class="col-5">{{'Online reviews' | translate}}</div>
      <div class="col-7">
        {{restaurant.info.settings.reviewsEnabled | enabledDisabledText | async}}
      </div>
    </div>
  </div>

  <div class="card-footer" *ngIf="restaurant.statistics.reviewsLast24Hours > 0 || restaurant.statistics.reviewsLast7Days > 0 || restaurant.statistics.totalExecutedActions24Hours > 0 || restaurant.statistics.totalNotExecutedActions24Hours > 0">
    <div class="stats">
      <span class="stats-header">{{'Reviews 1d / 7d' | translate}}</span>
      <h6 class="stats-value">{{restaurant.statistics.reviewsLast24Hours}} / {{restaurant.statistics.reviewsLast7Days}}</h6>
    </div>
    <div class="stats">
      <span class="stats-header">{{'Processed actions 24h' | translate}}</span>
      <h6 class="stats-value">{{restaurant.statistics.totalExecutedActions24Hours}}</h6>
    </div>
    <div class="stats">
      <span class="stats-header">{{'Missed actions 24h' | translate}}</span>
      <h6 class="stats-value negative">{{restaurant.statistics.totalNotExecutedActions24Hours}}</h6>
    </div>
  </div>
</div>
