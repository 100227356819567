import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {TextTranslationDefinition} from '../../shared/components/text-input-with-language-flag/text-translation.definition';

export class TranslationCommonValidators {
  public static maxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlValue: TextTranslationDefinition | null = control.value;

      if (controlValue == null) {
        return null;
      }

      const textLength = (controlValue.text || '').length;

      if (textLength <= maxLength) {
        return null;
      }

      return {maxlength: {requiredLength: maxLength, actualLength: textLength}};
    };
  }

  public static isNotNullOrEmptyOrWhiteSpaceValidator(control: AbstractControl): ValidationErrors | null {

    if (control.invalid) {
      return null;
    }

    const controlValue: TextTranslationDefinition | null = control.value;

    if (controlValue == null) {

      return {isNotNullOrEmptyOrWhiteSpace: {value: null}};
    }

    const text = (controlValue.text || '');

    if (text.trim().length === 0) {

      return {isNotNullOrEmptyOrWhiteSpace: {value: controlValue}};
    }

    return null;
  }

}
