/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-input-with-language-flag.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./text-input-with-language-flag.component";
var styles_TextInputWithLanguageFlagComponent = [i0.styles];
var RenderType_TextInputWithLanguageFlagComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextInputWithLanguageFlagComponent, data: {} });
export { RenderType_TextInputWithLanguageFlagComponent as RenderType_TextInputWithLanguageFlagComponent };
function View_TextInputWithLanguageFlagComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["class", "form-control"], ["type", "text"]], [[2, "disabled", null], [1, "disabled", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (_co.changed($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.lastValue.text; _ck(_v, 4, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDisabled; var currVal_1 = (_co.isDisabled ? "disabled" : null); var currVal_2 = _co.maxlength; var currVal_3 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 6).ngClassValid; var currVal_8 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
function View_TextInputWithLanguageFlagComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "textarea", [["class", "form-control"], ["rows", "3"]], [[2, "disabled", null], [1, "disabled", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (_co.changed($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["  "]))], function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.lastValue.text; _ck(_v, 4, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDisabled; var currVal_1 = (_co.isDisabled ? "disabled" : null); var currVal_2 = _co.maxlength; var currVal_3 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 6).ngClassValid; var currVal_8 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_TextInputWithLanguageFlagComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "input-group-prepend"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "input-group-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextInputWithLanguageFlagComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextInputWithLanguageFlagComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.mode === "input"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.mode === "textarea"); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("flag flag-" + ((_co.lastValue.langCode2 == null) ? null : _co.lastValue.langCode2.toLocaleLowerCase())); _ck(_v, 2, 0, currVal_0); }); }
export function View_TextInputWithLanguageFlagComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-shared-text-input-with-language-flag", [], [[2, "input-group", null]], null, null, View_TextInputWithLanguageFlagComponent_0, RenderType_TextInputWithLanguageFlagComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.TextInputWithLanguageFlagComponent]), i1.ɵdid(2, 49152, null, 0, i4.TextInputWithLanguageFlagComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).tempField; _ck(_v, 0, 0, currVal_0); }); }
var TextInputWithLanguageFlagComponentNgFactory = i1.ɵccf("app-shared-text-input-with-language-flag", i4.TextInputWithLanguageFlagComponent, View_TextInputWithLanguageFlagComponent_Host_0, { mode: "mode", maxlength: "maxlength" }, {}, []);
export { TextInputWithLanguageFlagComponentNgFactory as TextInputWithLanguageFlagComponentNgFactory };
