import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../util/form.utils';
import {Observable, Subject} from 'rxjs';
import {NeverError, ToastService} from 'orderly-web-components';
import RestaurantIngredientBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
import {catchError, distinctUntilChanged, finalize, first, takeUntil, tap} from 'rxjs/operators';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AddOrEditMenuItemModifierFormFields, MenuItemModifierData} from './helper.types';
import {CurrentRestaurantMenuItemsService} from '../../services/active-route-bound/current-restaurant-menu-items.service';
import AddMenuItemModifierRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddMenuItemModifierRequest;
import AddOrUpdateMenuItemModifierResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemModifierResponse;
import {TranslateService} from '@ngx-translate/core';
import {genericErrorHandlerWithToast} from '../../util/utils';


@Component({
  selector: 'app-add-or-edit-menu-item-modifier',
  templateUrl: './add-or-edit-menu-item-modifier.component.html',
  styleUrls: ['./add-or-edit-menu-item-modifier.component.scss']
})
export class AddOrEditMenuItemModifierComponent implements OnInit, OnDestroy {
  public actionInProgress: boolean = false;

  @Input()
  public modifierToEdit: MenuItemModifierData;

  @Input()
  public ingredients$: Observable<RestaurantIngredientBasic[]>;

  @Output()
  public completed: EventEmitter<AddOrUpdateMenuItemModifierResponse> = new EventEmitter<AddOrUpdateMenuItemModifierResponse>();

  public formDef: FormDefinition<keyof AddOrEditMenuItemModifierFormFields>;

  private destroyed$: Subject<any> = new Subject<any>();


  constructor(public readonly activeModal: NgbActiveModal,
              private readonly toastService: ToastService,
              private readonly trnService: TranslateService,
              private readonly formBuilder: FormBuilder,
              private readonly restaurantMenuService: CurrentRestaurantMenuItemsService) {

    const fieldsDef: FormFieldsDefinition<keyof AddOrEditMenuItemModifierFormFields> = {
      ingredientOrName: new FormFieldDefinition(null, false, [Validators.required], []),
      amount: new FormFieldDefinition(null, false, [Validators.min(1)], []),
      measureUnitId: new FormFieldDefinition(null, false, [Validators.required], []),
      priceDiff: new FormFieldDefinition(null, false, [Validators.required], [])
    };

    this.formDef = new FormDefinition<keyof AddOrEditMenuItemModifierFormFields>(fieldsDef, this.formBuilder);

    this.formDef
        .form
        .valueChanges
        .pipe(
          takeUntil(this.destroyed$),
          distinctUntilChanged((x: AddOrEditMenuItemModifierFormFields, y: AddOrEditMenuItemModifierFormFields) => {
            if (x.ingredientOrName == null && y.ingredientOrName == null) {
              return true;
            }

            if (x.ingredientOrName != null && y.ingredientOrName != null) {
              if (x.ingredientOrName.id !== y.ingredientOrName.id) {
                return false;
              }
            }

            if (x.amount != null && y.amount != null) {
              return x.amount === y.amount;
            }

            return false;
          }),
          tap((formValue: AddOrEditMenuItemModifierFormFields) => {

            if ((formValue.ingredientOrName != null && formValue.ingredientOrName.id != null)) {
              // ingredient selected

              this.formDef.disable(['measureUnitId']);

              const selectedIngredient: RestaurantIngredientBasic = formValue.ingredientOrName as RestaurantIngredientBasic;

              this.formDef.getControl('measureUnitId').setValue(selectedIngredient.measureUnit.id, {emitEvent: false});
            } else {

              // user entered a name, no ingredient available

              // "formValue.amount" can be string when user leaves input empty. Form does not know what type should it be.
              // @ts-ignore
              if (formValue.amount == null || formValue.amount === '') {
                this.formDef.disable(['measureUnitId']);

                this.formDef.getControl('measureUnitId').setValue(null, {emitEvent: false});
              } else {
                this.formDef.enable(['measureUnitId'], false);
              }
            }
          })
        )
        .subscribe();
  }

  ngOnInit() {

    if (this.modifierToEdit.modifier != null) {
      const modifier = this.modifierToEdit.modifier;
      const modifierIngredient = modifier != null
                                 ? modifier.ingredient != null
                                   ? modifier.ingredient
                                   : {name: modifier.name}
                                 : null;
      const measureUnitId = modifier != null && modifier.ingredient != null
                            ? modifier.ingredient.measureUnit.id
                            : modifier != null
                              ? modifier.measureUnit != null
                                ? modifier.measureUnit.id
                                : null
                              : null;

      const formValue: AddOrEditMenuItemModifierFormFields = {
        measureUnitId: measureUnitId,
        ingredientOrName: modifierIngredient,
        priceDiff: modifier == null ? null : modifier.priceDiff,
        amount: modifier == null ? null : modifier.amount
      };

      this.formDef.patchValue(formValue);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  public save() {
    if (this.formDef.form.invalid) {
      return;
    }

    const disableFormToken = this.formDef.disable('all-fields');
    const saveAction$ = this.getSaveAction();
    const unexpectedErrorMsg = this.trnService.instant('Failed to save a modifier because of unexpected failure.');

    saveAction$.pipe(
      first(),
      tap(x => {
            switch (x.status) {
              case AddOrUpdateMenuItemModifierResponse.StatusDef.Success:
                this.completed.emit(x);

                this.activeModal.close();
                break;
              case AddOrUpdateMenuItemModifierResponse.StatusDef.UnknownFailure:
                this.toastService.showError(unexpectedErrorMsg);
                break;
              case AddOrUpdateMenuItemModifierResponse.StatusDef.ValidationFailed:
                disableFormToken.reenable();

                this.formDef.setFormFieldsServerValidationResults(x.validationErrors);
                break;
              default:
                throw new NeverError(x.status);
            }
          }
      ),
      catchError(
        genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedErrorMsg)
      ),
      finalize(() => disableFormToken.reenable())
               )
               .subscribe();
  }

  private getSaveAction(): Observable<AddOrUpdateMenuItemModifierResponse> {

    const data: AddOrEditMenuItemModifierFormFields = this.formDef.form.getRawValue();
    let modifierName: string | null = null;
    let ingredientId: number | null = null;

    if (data.ingredientOrName != null) {
      if (data.ingredientOrName.id == null) {
        modifierName = data.ingredientOrName.name;
      } else {
        ingredientId = data.ingredientOrName.id;
      }
    }

    const request: AddMenuItemModifierRequest = {
      amount: data.amount!,
      priceDiff: data.priceDiff!,
      name: modifierName!,
      ingredientId: ingredientId,
      measureUnitId: data.measureUnitId,
      overrideIngredientName: false
    };

    if (this.modifierToEdit.modifier == null) {
      return this.restaurantMenuService.addMenuItemModifier(this.modifierToEdit.menuItemId,
                                                            this.modifierToEdit.modifierGroupId,
                                                            request);
    }

    return this.restaurantMenuService.updateMenuItemModifier(this.modifierToEdit.menuItemId,
                                                             this.modifierToEdit.modifierGroupId,
                                                             this.modifierToEdit.modifier.id,
                                                             request);
  }
}
