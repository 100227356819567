export enum ActionTypes {
  AUTO_LOGIN_ON_APP_START_FAILED = '[USER] AUTO_LOGIN_ON_APP_START_FAILED',

  LOGIN_FAILED_HTTP = '[USER] LOGIN_FAILED_HTTP',
  LOGIN_FAILED_API = '[USER] LOGIN_FAILED_API',
  LOGIN_SUCCESSFUL = '[USER] LOGIN_SUCCESSFUL',

  LOGOUT = '[USER] LOGOUT',

  RESTAURANT_ONBOARDING_COMPLETED = '[RESTAURANT] ONBOARDING_COMPLEtED',

  RESTAURANT_CREATE = '[RESTAURANT] CREATE',
  RESTAURANT_CREATE_SUCCESSFUL = '[RESTAURANT] CREATE_SUCCESSFUL',
  RESTAURANT_UPDATE_SUCCESSFUL = '[RESTAURANT] UPDATE_SUCCESSFUL',

  WAITER_INVITE_SUCCESSFUL = '[WAITER] INVITE_SUCCESSFUL',
  WAITER_DELETE_SUCCESSFUL = '[WAITER] DELETE_SUCCESSFUL',

  AREA_CREATE_SUCCESSFUL = '[AREA] CREATE_SUCCESSFUL',

  TABLE_CREATE_SUCCESSFUL = '[TABLE] CREATE_SUCCESSFUL',
  TABLE_DELETE_SUCCESSFUL = '[TABLE] DELETE_SUCCESSFUL',

  CURRENT_USER_INFORMATION_LOADED = '[CURRENT_USER] INFORMATION_LOADED',

  CURRENT_USER_SELECTED_LANGUAGE_CHANGED = '[CURRENT_USER] SELECTED LANGUAGE CHANGED',
  CURRENT_USER_INFORMATION_CHANGED = '[CURRENT_USER] INFO CHANGED',


  INGREDIENT_CATEGORY_ADD_SUCCESSFUL = '[INGREDIENT_CATEGORY] ADD_SUCCESSFUL',
  INGREDIENT_CATEGORY_DELETE_SUCCESSFUL = '[INGREDIENT_CATEGORY] DELETE_SUCCESSFUL',

  LEFT_MENU_ITEM_SELECTED = '[LEFT_MENU] ITEM SELECTED',
}
