<ngb-tabset justify="center"
            [ngClass]="'first-steps-wizard'"
            type="pills"
            [activeId]="activeTab">

  <ngb-tab id="restaurantTab" [disabled]="!createRestaurant || newRestaurant != null">
    <ng-template ngbTabTitle>
      <span class="step-num">1</span>
      <span class="step-name">{{'Create restaurant' | translate}}</span>
    </ng-template>
    <ng-template ngbTabContent>
      <div class="row mg-md-t-30">
        <div class="col-xs-12 col-md-4 offset-md-4">
          <app-restaurant-editor #restaurantForm></app-restaurant-editor>

          <div class="row">
            <div class="col-12 pd-r-0">
              <button class="btn btn-link float-right next-step-btn pd-r-0"
                      style="color: white; font-size: 1.7em;"
                      [ngClass]="{disabled: restaurantForm.formDef.form.invalid || actionInProgress}"
                      (click)="saveRestaurant()">{{'Next step' | translate}} <i class="icon-angle-right"></i></button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-tab>

  <ngb-tab id="areaTab" [disabled]="newArea != null">
    <ng-template ngbTabTitle>
      <span class="step-num">2</span>
      <span class="step-name">{{'Add restaurant area' | translate}}</span>
    </ng-template>
    <ng-template ngbTabContent>
      <div class="row mg-md-t-30">
        <form [formGroup]="areaFormDef.form" class="col-xs-12 col-md-4 offset-md-4">

          <div class="row">
            <div class="form-layout form-layout-1" style="width: 100%;">

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-control-label">{{'Name' | translate}}: <span class="tx-danger">*</span></label>
                    <input class="form-control" type="text" [formControl]="areaFormDef.getControl('name')"/>

                    <owc-input-validation-errors [control]="areaFormDef.getControl('name')"></owc-input-validation-errors>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-control-label" for="areaIsSmokingArea">{{'Is smoking allowed' | translate}}: <span class="tx-danger">*</span></label>

                    <ng-select [items]="isSmokingAreaSelectItems$ | async"
                               [formControl]="areaFormDef.getControl('isSmokingArea')"
                               [searchable]="false"
                               labelForId="areaIsSmokingArea"
                               bindValue="value"
                               bindLabel="text">
                    </ng-select>


                    <owc-input-validation-errors [control]="areaFormDef.getControl('isSmokingArea')"></owc-input-validation-errors>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-control-label" for="areaIsOutdoor">{{'Is outdoor' | translate}}: <span class="tx-danger">*</span></label>

                    <ng-select [items]="isOutdoorSelectItems$ | async"
                               [formControl]="areaFormDef.getControl('isOutdoor')"
                               [searchable]="false"
                               labelForId="areaIsOutdoor"
                               bindValue="value"
                               bindLabel="text">
                    </ng-select>


                    <owc-input-validation-errors [control]="areaFormDef.getControl('isOutdoor')"></owc-input-validation-errors>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-12 pd-r-0">
              <button class="btn btn-link float-right next-step-btn pd-r-0"
                      style="color: white; font-size: 1.7em;"
                      [ngClass]="{disabled: areaFormDef.form.invalid || actionInProgress}"
                      (click)="saveArea()">{{'Next step' | translate}} <i class="icon-angle-right"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </ngb-tab>

  <ngb-tab id="tableTab" [disabled]="tableCreated || newArea == null">
    <ng-template ngbTabTitle>
      <span class="step-num">3</span>
      <span class="step-name">{{'Add table' | translate}}</span>
    </ng-template>
    <ng-template ngbTabContent>
      <div class="row mg-md-t-30">
        <form [formGroup]="tableFormDef.form" class="col-xs-12 col-md-4 offset-md-4">

          <div class="row">
            <div class="form-layout form-layout-1" style="width: 100%;">

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-control-label">{{'Name' | translate}}: <span class="tx-danger">*</span></label>
                    <input class="form-control" type="text" [formControl]="tableFormDef.getControl('name')"/>

                    <owc-input-validation-errors [control]="tableFormDef.getControl('name')"></owc-input-validation-errors>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-12 pd-r-0">
              <button class="btn btn-link float-right next-step-btn pd-r-0"
                      style="color: white; font-size: 1.7em;"
                      [ngClass]="{disabled: tableFormDef.form.invalid || actionInProgress}"
                      (click)="saveTable()">{{'Finish' | translate}} <i class="icon-angle-right"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </ngb-tab>

  <ngb-tab id="guideTab" [disabled]="!tableCreated">
    <ng-template ngbTabTitle>
      <span class="step-num">4</span>
      <span class="step-name">{{'Completed!' | translate}}</span>
    </ng-template>
    <ng-template ngbTabContent>
      <div class="row mg-md-t-30">
        <div class="col-xs-12 col-md-4 offset-md-4">
          <div class="form-layout form-layout-1 next-steps-after-setup-form">
            <h4 style="color: black;">{{'Next steps' | translate}}:</h4>

            <ul class="next-steps-after-setup-list">
              <li>
                <a [routerLink]="getTerminalsListUrl() | async">{{'Activate terminal in order to see requests requests from your guests' | translate}}</a>
                ({{'Android-based tablet is required' | translate}})
              </li>
              <li>
                <a [routerLink]="getTablesListUrl() | async">{{'Print stickers with QR-codes for self-service and reviews' | translate}}</a>
              </li>
              <li>
                <a [routerLink]="getWaitersUrl() | async">{{'Invite your colleagues' | translate}}</a>
              </li>
              <li>
                <a [routerLink]="getReviewsUrl() | async">{{'Check the latest reviews' | translate}}</a>
              </li>
              <li>
                <a [routerLink]="getMenuItemsUrl() | async">{{'Add menu items' | translate}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
