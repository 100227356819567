import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {RestaurantSpecificArrayResultLoadingState} from './helper.statuses';
import {CurrentRestaurantServiceBase} from './current-restaurant.service.base';
import {HttpClient} from '@angular/common/http';
import GetAllRestaurantAreasResponse = Orderly.RestaurantWeb.Api.Messages.Area.GetAllRestaurantAreasResponse;
import {environment} from '../../environments/environment';
import AddRestaurantAreaRequest = Orderly.RestaurantWeb.Api.Messages.Area.AddRestaurantAreaRequest;
import AddOrUpdateRestaurantAreaResponse = Orderly.RestaurantWeb.Api.Messages.Area.AddOrUpdateRestaurantAreaResponse;
import UpdateRestaurantAreaRequest = Orderly.RestaurantWeb.Api.Messages.Area.UpdateRestaurantAreaRequest;
import DeleteRestaurantAreaResponse = Orderly.RestaurantWeb.Api.Messages.Area.DeleteRestaurantAreaResponse;
import RestaurantArea = Orderly.RestaurantWeb.Api.Messages.RestaurantArea;

@Injectable({
              providedIn: 'root'
            })
export class CurrentRestaurantAreasService extends CurrentRestaurantServiceBase<RestaurantArea> {
  private baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  public get currentRestaurantAreas$(): Observable<RestaurantSpecificArrayResultLoadingState<RestaurantArea>> {
    return this.dataFeed$;
  }

  constructor(private httpClient: HttpClient, store: Store<AppState>) {
    super(store);
  }

  protected doLoadData(restaurantId: number): Observable<RestaurantArea[]> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/areas/all`;
    const response$ = this.httpClient.get<GetAllRestaurantAreasResponse>(url);

    return response$.pipe(
      tap(x => {
        if (x.status === GetAllRestaurantAreasResponse.StatusDef.UnknownFailure) {
          throw new Error('Server returned an error. Could not load list of areas');
        }
      }),
      map(x => x.items)
    );
  }

  public add(request: AddRestaurantAreaRequest): Observable<AddOrUpdateRestaurantAreaResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   return this.addForRestaurant(restaurantId, request);
                 })
               );
  }

  public addForRestaurant(restaurantId: number, request: AddRestaurantAreaRequest): Observable<AddOrUpdateRestaurantAreaResponse> {
    const url: string = this.baseURL + `/restaurant/${restaurantId}/area/add`;

    return this.httpClient.post<AddOrUpdateRestaurantAreaResponse>(url, request);;
  }

  public update(areaId: number, request: UpdateRestaurantAreaRequest): Observable<AddOrUpdateRestaurantAreaResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/area/${areaId}/update`;

                   return this.httpClient.post<AddOrUpdateRestaurantAreaResponse>(url, request);
                 })
               );
  }

  public delete(areaId: number): Observable<DeleteRestaurantAreaResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap((restaurantId: number) => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/area/${areaId}/delete`;

                   return this.httpClient.delete<DeleteRestaurantAreaResponse>(url);
                 })
               );
  }
}
