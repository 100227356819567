import {Component, Input, OnInit} from '@angular/core';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../util/form.utils';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {TerminalFormFieldsDef} from './form-fields-definitions';
import {
  isNotNullOrEmptyOrWhiteSpaceValidator,
  NeverError,
  requiredIfSelectedValidator,
  ToastService
} from 'orderly-web-components';
import {Observable} from 'rxjs';
import {catchError, distinctUntilChanged, filter, finalize, first, map, takeUntil, tap} from 'rxjs/operators';
import {genericErrorHandlerWithToast, nameof} from '../../../../util/utils';
import {CurrentRestaurantTerminalsService} from '../../../../services/active-route-bound/current-restaurant-terminals.service';
import {ActivatedRoute} from '@angular/router';
import {RestaurantComponent} from '../../../restaurant.component';
import {OwnerSelectItem, RestaurantTerminalTypeSelectItem} from './helper.types';
import ExistingTerminal = Orderly.RestaurantWeb.Api.Messages.Restaurant.ExistingTerminal;
import AddOrUpdateTerminalResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddOrUpdateTerminalResponse;
import AddTerminalRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddTerminalRequest;
import UpdateTerminalRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.UpdateTerminalRequest;
import RestaurantTerminalType = Orderly.Common.Enums.RestaurantTerminalType;
import {CurrentRestaurantUsersService} from '../../../../services/active-route-bound/current-restaurant-users.service';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';

@Component({
             selector: 'app-add-or-edit-terminal-modal',
             templateUrl: './add-or-edit-terminal-modal.component.html',
             styleUrls: ['./add-or-edit-terminal-modal.component.scss']
           })
export class AddOrEditTerminalModalComponent extends RestaurantComponent implements OnInit {

  public actionInProgress: boolean = false;

  public formDef: FormDefinition<keyof TerminalFormFieldsDef>;

  public terminalTypeSelectItems$: Observable<RestaurantTerminalTypeSelectItem[]>;
  public ownerSelectItems$: Observable<OwnerSelectItem[]>;


  @Input()
  public existingTerminal: ExistingTerminal | null;

  constructor(public modalService: NgbActiveModal,
              private formBuilder: FormBuilder,
              private toastService: ToastService,
              private trnService: TranslateService,
              private terminalService: CurrentRestaurantTerminalsService,
              private userService: CurrentRestaurantUsersService,
              activatedRoute: ActivatedRoute,
              store: Store<AppState>) {

    super(store, activatedRoute);

    this.initForm();
    this.initSelectListItems();
  }

  ngOnInit(): void {
    if (this.existingTerminal != null) {
      const group1 = this.existingTerminal.externalId.slice(0, 4);
      const group2 = this.existingTerminal.externalId.slice(4, 8);
      const group3 = this.existingTerminal.externalId.slice(8, 12);

      const externalId = `${group1} - ${group2} - ${group3}`;

      const ownerUserId: number | null = this.existingTerminal.owner != null ? this.existingTerminal.owner.id : null;

      this.formDef.patchValue({
                                type: this.existingTerminal.type,
                                externalId: externalId,
                                ownerUserId: ownerUserId,
                                isActive: this.existingTerminal.isActive,
                                name: this.existingTerminal.name,
                              });
    }
  }

  private initForm() {
    const fieldsDef: FormFieldsDefinition<keyof TerminalFormFieldsDef> = {
      name: new FormFieldDefinition(null,
                                    false,
                                    [isNotNullOrEmptyOrWhiteSpaceValidator, Validators.maxLength(30)],
                                    [
                                      nameof<AddTerminalRequest>('name'),
                                      nameof<UpdateTerminalRequest>('name'),
                                    ]),
      externalId: new FormFieldDefinition(null,
                                          false,
                                          [],
                                          []),
      isActive: new FormFieldDefinition(true,
                                        false,
                                        [Validators.required],
                                        []),
      pin: new FormFieldDefinition(null,
                                   false,
                                   [
                                     isNotNullOrEmptyOrWhiteSpaceValidator,
                                     Validators.minLength(4),
                                     Validators.maxLength(4)
                                   ],
                                   [
                                     nameof<AddTerminalRequest>('pin'),
                                   ]),
      type: new FormFieldDefinition(null,
                                    false,
                                    [
                                      Validators.required
                                    ],
                                    [
                                      nameof<AddTerminalRequest>('type'),
                                    ]),
      ownerUserId: new FormFieldDefinition(null,
                                           false,
                                           [
                                             Validators.required
                                           ],
                                           [
                                             nameof<AddTerminalRequest>('ownerUserId'),
                                           ]),
    };

    const typeCtrlName = nameof<Record<keyof TerminalFormFieldsDef, any>>('type');
    const ownerUserIdCtrlName = nameof<Record<keyof TerminalFormFieldsDef, any>>('ownerUserId');

    const formOpts = {
      validators: [
        requiredIfSelectedValidator(ownerUserIdCtrlName, {
          radioOrCheckboxControlName: typeCtrlName,
          selectedValue: RestaurantTerminalType.Personal
        })
      ]
    };

    this.formDef = new FormDefinition(fieldsDef, this.formBuilder, formOpts);

    this.formDef
        .form
        .valueChanges
        .pipe(
          takeUntil(this.destroyed$),
          distinctUntilChanged((x: TerminalFormFieldsDef, y: TerminalFormFieldsDef) => x.type === y.type),
          tap((formValue: TerminalFormFieldsDef) => {

            if (formValue.externalId != null) {
              this.formDef.disable(['pin', 'type', 'ownerUserId', 'externalId']);
            } else {
              if (formValue.type !== RestaurantTerminalType.Personal) {
                this.formDef.getControl('ownerUserId').setValue(null);
                this.formDef.disable(['ownerUserId']);
              } else {
                this.formDef.enable(['ownerUserId']);
              }
            }
          })
        )
        .subscribe();
  }

  private initSelectListItems() {
    const personalTerminalTypeName = _('Personal');
    const centralTerminalTypeName = _('Central');

    this.terminalTypeSelectItems$ = this.trnService
        .get([personalTerminalTypeName, centralTerminalTypeName])
        .pipe(
          takeUntil(this.destroyed$),
          map(trns => {
            const personalName = trns[personalTerminalTypeName];
            const centralName = trns[centralTerminalTypeName];

            return [
              new RestaurantTerminalTypeSelectItem(RestaurantTerminalType.Central, centralName),
              new RestaurantTerminalTypeSelectItem(RestaurantTerminalType.Personal, personalName)
            ];
          })
        );

    this.ownerSelectItems$ = this.userService
                                 .currentRestaurantUsers$
                                 .pipe(
                                   takeUntil(this.destroyed$),
                                   filter(x => x.isLoaded()),
                                   map(x => x.items.map(i => OwnerSelectItem.from(i)))
                                 );
  }

  public save() {
    if (this.formDef.form.invalid) {
      return;
    }

    const formValue: TerminalFormFieldsDef = this.formDef.form.getRawValue();

    const unexpectedErrorMsg = this.trnService.instant('Failed to save a terminal because of unexpected failure.');
    const action$: Observable<AddOrUpdateTerminalResponse> = this.existingTerminal == null
    ? this.terminalService.add({name: formValue.name, pin: formValue.pin, type: formValue.type!, ownerUserId: formValue.ownerUserId})
    : this.terminalService.update(this.existingTerminal.id, {name: formValue.name, isActive: true});

    this.actionInProgress = true;

    const disableToken = this.formDef.disable('all-fields');

    action$.pipe(
      first(),
      tap((response: AddOrUpdateTerminalResponse) => {
        switch (response.status) {
          case AddOrUpdateTerminalResponse.StatusDef.Success:
            this.toastService.showSuccess(this.trnService.instant('Terminal was saved'));

            this.modalService.close(response);
            break;
          case AddOrUpdateTerminalResponse.StatusDef.ValidationFailed:
            this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
            break;
          case AddOrUpdateTerminalResponse.StatusDef.UnexpectedError:
            this.toastService.showError(unexpectedErrorMsg);
            break;
          default:
            throw new NeverError(response.status);
        }
      }),
      catchError(
        genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedErrorMsg)
      ),
      finalize(() => {
        disableToken.reenable();
        this.actionInProgress = false;
      })
           )
           .subscribe();
  }
}
