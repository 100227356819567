<div class="row">
  <div class="col-xxl-2 col-lg-4 col-xs-12">
    <div class="form-group">
      <label class="form-control-label">{{'Multiple sizes?' | translate}}: <span class="tx-danger">*</span></label>

      <app-shared-yes-no-switch [formControl]="formDef.getControl('hasMultipleSizes')"
                                [notSelectedButtonClass]="'btn-dark'"
                                [selectedTrueButtonClass]="'btn-light'"
                                [selectedFalseButtonClass]="'btn-light'"
                                class="input-group">
      </app-shared-yes-no-switch>

      <owc-input-validation-errors [control]="formDef.getControl('hasMultipleSizes')"></owc-input-validation-errors>
    </div>
  </div>

  <div class="col-xxl-2 col-lg-4 col-xs-12" *ngIf="formDef.getControlValue('hasMultipleSizes') === false">
    <div class="form-group">
      <label class="form-control-label">{{'Price' | translate}}: <span class="tx-danger">*</span></label>

      <div class="input-group">
        <input class="form-control"
               type="text"
               maxlength="10"
               inputNumber
               [formControl]="formDef.getControl('price')"
               [acceptDecimalPlaces]="true"
               [decimalPlaces]="2" />

        <app-shared-currency-select [formControl]="formDef.getControl('currency')"
                                    [placeholder]="''"
                                    class="form-control">
        </app-shared-currency-select>

      </div>

      <owc-input-validation-errors [control]="formDef.getControl('price')"></owc-input-validation-errors>
      <owc-input-validation-errors [control]="formDef.getControl('currency')"></owc-input-validation-errors>
    </div>
  </div>

  <div class="col-xxl-2 col-lg-4 col-xs-12" *ngIf="formDef.getControlValue('hasMultipleSizes') === false">
    <div class="form-group">
      <label class="form-control-label">{{'Amount' | translate}}:</label>

      <div class="input-group">
        <input class="form-control"
               type="text"
               maxlength="10"
               inputNumber
               [formControl]="formDef.getControl('amount')"
               [acceptDecimalPlaces]="true"
               [decimalPlaces]="2" />

        <app-shared-measure-unit-select [formControl]="formDef.getControl('measureUnit')"
                                        [placeholder]="''"
                                        class="form-control">
        </app-shared-measure-unit-select>
      </div>

      <owc-input-validation-errors [control]="formDef.getControl('amount')"></owc-input-validation-errors>
      <owc-input-validation-errors [control]="formDef.getControl('measureUnit')"></owc-input-validation-errors>
    </div>
  </div>


  <div class="col-xxl-2 col-lg-4 col-xs-12" *ngIf="formDef.getControlValue('hasMultipleSizes') === true">
    <div class="form-group">
      <label class="form-control-label">{{'Currency' | translate}}: <span class="tx-danger">*</span></label>

      <app-shared-currency-select [formControl]="formDef.getControl('currency')"
                                  [placeholder]="''"
                                  class="form-control">
      </app-shared-currency-select>

      <owc-input-validation-errors [control]="formDef.getControl('currency')"></owc-input-validation-errors>
    </div>
  </div>

  <div class="col-xxl-2 col-lg-4 col-xs-12" *ngIf="formDef.getControlValue('hasMultipleSizes') === true">
    <div class="form-group">
      <label class="form-control-label">{{'Measure unit' | translate}}: <span class="tx-danger">*</span></label>

      <app-shared-measure-unit-select [formControl]="formDef.getControl('measureUnit')"
                                      [placeholder]="''"
                                      class="form-control">
      </app-shared-measure-unit-select>

      <owc-input-validation-errors [control]="formDef.getControl('measureUnit')"></owc-input-validation-errors>
    </div>
  </div>
</div>

<div class="row" *ngIf="formDef.getControlValue('hasMultipleSizes') === true">
  <div class="col-xxl-6 col-lg-8 col-xs-12">
    <app-editable-prices-list [formControl]="formDef.getFormArray('sizes')"></app-editable-prices-list>
  </div>
</div>
