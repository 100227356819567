import {Component, Input, OnInit} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../util/form.utils';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from 'orderly-web-components';
import {genericErrorHandlerWithToast, nameof} from '../../util/utils';
import {catchError, filter, finalize, first, map, tap} from 'rxjs/operators';
import {TranslationsDefinition} from './translations-definition';
import LinkDisplayToTableRequest = Orderly.RestaurantWeb.Api.Messages.Display.LinkDisplayToTableRequest;
import ChangeLinkDisplayStatusResponse = Orderly.RestaurantWeb.Api.Messages.Display.ChangeLinkDisplayStatusResponse;
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {assignTranslatedProperties, getArrayOfTranslationKeys, trnTryAgainLaterText} from '../../util/trn.utils';
import {CurrentRestaurantTablesService} from '../../services/active-route-bound/current-restaurant-tables.service';
import {CurrentRestaurantDisplaysService} from '../../services/active-route-bound/current-restaurant-displays.service';
import ExistingTableWithAreaAndDisplays = Orderly.RestaurantWeb.Api.Messages.ExistingTableWithAreaAndDisplays;

type AssignDisplayToTableModalComponentFormFields = 'table' | 'display';

@Component({
             selector: 'app-assign-display-to-table-modal',
             templateUrl: './assign-display-to-table-modal.component.html',
             styleUrls: ['./assign-display-to-table-modal.component.scss']
           })
export class AssignDisplayToTableModalComponent implements OnInit {

  @Input()
  public display: Orderly.RestaurantWeb.Api.Messages.Display.GetDisplaysResponse.Display;

  @Input()
  public restaurantId: number;

  public tables$: Observable<ExistingTableWithAreaAndDisplays[]>;

  public translationsCfg: ReplaySubject<TranslationsDefinition> = new ReplaySubject<TranslationsDefinition>(1);

  public formDef: FormDefinition<AssignDisplayToTableModalComponentFormFields>;

  public actionRunning: boolean = false;

  constructor(restaurantTableService: CurrentRestaurantTablesService,
              public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private trnService: TranslateService,
              private toastService: ToastService,
              private displayService: CurrentRestaurantDisplaysService) {
    this.tables$ = restaurantTableService.currentRestaurantTables$
                                         .pipe(
                                           filter(x => x.isLoaded()),
                                           map(x => x.items)
                                         );
  }

  ngOnInit() {
    this.initTranslations();

    const fieldsDef: FormFieldsDefinition<AssignDisplayToTableModalComponentFormFields> = {
      table: new FormFieldDefinition(null,
                                     false,
                                     [Validators.required],
                                     [
                                       nameof<LinkDisplayToTableRequest>('tableId')
                                     ]),
      display: new FormFieldDefinition(this.display.uniqueCode,
                                       true,
                                       [Validators.required],
                                       [
                                         nameof<LinkDisplayToTableRequest>('displayId')
                                       ])
    };

    this.formDef = new FormDefinition<AssignDisplayToTableModalComponentFormFields>(fieldsDef, this.formBuilder);
  }


  private initTranslations() {
    const initialTranslations: TranslationsDefinition = {
      assignButton: _('Assign'),
      cancelButton: _('Cancel'),
      modalTitle: _('Assign display to a table'),
      displayNameLabel: _('Display'),
      tableLabel: _('Table')
    };

    this.translationsCfg.next(initialTranslations);

    const translationKeys = getArrayOfTranslationKeys(initialTranslations);

    this.trnService
        .stream(translationKeys)
        .pipe(
          map(translations => {
            const result: TranslationsDefinition = assignTranslatedProperties(initialTranslations, translations);

            return result;
          })
        )
        .subscribe(this.translationsCfg);
  }

  public assign() {
    if (this.formDef.form.invalid) {
      return;
    }

    const selectedTable: ExistingTableWithAreaAndDisplays = this.formDef.getControl('table').value;

    const request: LinkDisplayToTableRequest = {
      displayId: this.display.id,
      tableId: selectedTable.id
    };

    const tryAgainLaterMsg = trnTryAgainLaterText(this.trnService);
    const unexpectedServerFailureMsg = _('Failed to assign a display because of unexpected failure.') + ' ' + tryAgainLaterMsg;

    const disabledFieldsToken = this.formDef.disable('all-fields');

    this.actionRunning = true;

    this.displayService
        .link(request)
        .pipe(
          first(),
          tap((response: ChangeLinkDisplayStatusResponse) => {
            switch (response.status) {
              case ChangeLinkDisplayStatusResponse.StatusDef.ValidationFailed:
                disabledFieldsToken.reenable();
                this.formDef.setFormFieldsServerValidationResults(response.validationErrors);
                break;
              case ChangeLinkDisplayStatusResponse.StatusDef.Success:
                const successMsg = this.trnService.instant('Successfully assigned display to a table.');
                this.toastService.showSuccess(successMsg);
                break;
              case ChangeLinkDisplayStatusResponse.StatusDef.UnknownFailure:
                this.toastService.showError(unexpectedServerFailureMsg);
                break;
            }
          }),
          catchError(
            genericErrorHandlerWithToast<ChangeLinkDisplayStatusResponse>(this.toastService, this.trnService, unexpectedServerFailureMsg)
          ),
          finalize(() => {
            disabledFieldsToken.reenable();

            this.actionRunning = false;
          })
        )
        .subscribe();
  }
}
