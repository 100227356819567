import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Injectable} from '@angular/core';
import {AppUser} from '../models/app.models';
import GetCurrentUserResponse = Orderly.RestaurantWeb.Api.Messages.User.GetCurrentUserResponse;
import ChangePasswordRequest = Orderly.RestaurantWeb.Api.Messages.User.ChangePasswordRequest;
import ChangePasswordResponse = Orderly.RestaurantWeb.Api.Messages.User.ChangePasswordResponse;
import UpdateCurrentUserResponse = Orderly.RestaurantWeb.Api.Messages.User.UpdateCurrentUserResponse;
import UpdateCurrentUserRequest = Orderly.RestaurantWeb.Api.Messages.User.UpdateCurrentUserRequest;
import ConfirmInvitationRequest = Orderly.RestaurantWeb.Api.Messages.Waiter.ConfirmInvitationRequest;
import ConfirmInvitationResponse = Orderly.RestaurantWeb.Api.Messages.Waiter.ConfirmInvitationResponse;
import ChangeCurrentUserLanguageRequest = Orderly.RestaurantWeb.Api.Messages.User.ChangeCurrentUserLanguageRequest;
import ChangeCurrentUserLanguageResponse = Orderly.RestaurantWeb.Api.Messages.User.ChangeCurrentUserLanguageResponse;
import ResetPasswordResponse = Orderly.RestaurantWeb.Api.Messages.Authentication.ResetPasswordResponse;
import ResetPasswordRequest = Orderly.RestaurantWeb.Api.Messages.Authentication.ResetPasswordRequest;
import ResetPasswordTokenResponse = Orderly.RestaurantWeb.Api.Messages.Authentication.ResetPasswordTokenResponse;
import ResetPasswordTokenRequest = Orderly.RestaurantWeb.Api.Messages.Authentication.ResetPasswordTokenRequest;
import {LocalStorageService} from './local-storage.service';
import {NeverError} from 'orderly-web-components';

@Injectable({
              providedIn: 'root'
            })
export class UserService {
  private readonly baseUrl: string = `${environment.baseApiUrlWithTrailingSlash}v1.0/user`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getCurrentUser(mode: 'from-cache' | 'from-server'): Observable<GetCurrentUserResponse> {
    switch (mode) {
      case 'from-server':
        const url: string = `${this.baseUrl}/current`;
        const serverResult = this.httpClient.get<GetCurrentUserResponse>(url);

        return serverResult;
      case 'from-cache':
        const cachedUser = LocalStorageService.getCurrentUser();

        if (cachedUser == null) {
          throw new Error('Could not get current user from cache');
        }

        const cacheResult: GetCurrentUserResponse = {
          associatedRestaurants: cachedUser.user.restaurants,
          firstName: cachedUser.user.firstName,
          holdingInfo: cachedUser.user.holdingInfo,
          lastName: cachedUser.user.lastName,
          preferredLang: cachedUser.user.preferredLang,
          id: cachedUser.user.id,
          validationErrors: []
        };

        return of(cacheResult);
      default:
        throw new NeverError(mode);
    }
  }

  public updateUser(user: AppUser): Observable<UpdateCurrentUserResponse> {
    const url: string = `${this.baseUrl}/update`;
    const request: UpdateCurrentUserRequest = {
      firstName: user.firstName,
      lastName: user.lastName,
      preferredLanguageCode2: user.preferredLanguage.code,
    };
    const result = this.httpClient.post<UpdateCurrentUserResponse>(url, request);

    return result;
  }

  public updateUserLanguage(langCode2: string): Observable<ChangeCurrentUserLanguageResponse> {
    const url: string = `${this.baseUrl}/current/change-language`;
    const request: ChangeCurrentUserLanguageRequest = {
      languageCode2: langCode2
    };
    const result = this.httpClient.post<ChangeCurrentUserLanguageResponse>(url, request);

    return result;
  }

  public changePassword(userId: number, oldPassword: string, newPassword: string): Observable<ChangePasswordResponse> {
    const url: string = `${this.baseUrl}/change-password`;
    const request: ChangePasswordRequest = {userId: userId, oldPassword: oldPassword, newPassword: newPassword};
    const result = this.httpClient.post<ChangePasswordResponse>(url, request);

    return result;
  }

  public confirmAccount(token: string, password: string): Observable<ConfirmInvitationResponse> {
    const url: string = `${this.baseUrl}/confirm-invitation/${token}`;
    const request: ConfirmInvitationRequest = {password: password};
    const result = this.httpClient.post<ConfirmInvitationResponse>(url, request);

    return result;
  }

  public initiatePasswordReset(email: string, languageCode2: string): Observable<ResetPasswordResponse> {
    const url: string = `${environment.baseApiUrlWithTrailingSlash}v1.0/restaurant/admin/reset-password`;
    const request: ResetPasswordRequest = {email: email, languageCode2: languageCode2};
    const result = this.httpClient.post<ResetPasswordResponse>(url, request);

    return result;
  }

  public completePasswordReset(token: string, newPassword: string): Observable<ResetPasswordTokenResponse> {
    const url: string = `${environment.baseApiUrlWithTrailingSlash}v1.0/restaurant/admin/reset-password/${token}`;
    const request: ResetPasswordTokenRequest = {newPassword: newPassword};
    const result = this.httpClient.post<ResetPasswordTokenResponse>(url, request);

    return result;
  }
}
