import {
  AbstractControl,
  AbstractControlOptions, FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors
} from '@angular/forms';
import ModelStateValidationResult = Orderly.Shared.Api.Messages.ModelStateValidationResult;
import {environment} from '../environments/environment';


export class FormFieldDefinition {
  private readonly requestFieldNameAliases: string[] = [];

  constructor(public readonly value: any | null,
              public readonly disabled: boolean,
              public readonly validators: ((control: AbstractControl) => ValidationErrors | null)[],
              requestFieldNames: string[],
              public readonly asyncValidators = []) {

    for (const item of requestFieldNames) {
      if (this.requestFieldNameAliases.indexOf(item) < 0) {
        this.requestFieldNameAliases.push(item);
      }

      const itemLower = item.toLowerCase();

      if (this.requestFieldNameAliases.indexOf(itemLower) < 0) {
        this.requestFieldNameAliases.push(itemLower);
      }
    }

  }

  public matchesRequestField(name: string) {
    return this.requestFieldNameAliases.indexOf(name) >= 0 ||
           this.requestFieldNameAliases.indexOf(name.toLowerCase()) >= 0;
  }
}

export class FormArrayDefinition<T extends keyof any> {
  constructor(public readonly values: FormDefinition<T>[], requestFieldNames: string[]) {
  }
}

export type FormFieldsDefinition<T extends keyof any> = Record<T, FormFieldDefinition | FormArrayDefinition<any>>;

export interface FromGroupControlsConfig {
  [key: string]: any;
}

export interface FormArrayList {
  [key: string]: FormArray;
}

class ControlDisabledState {
  constructor(public readonly name: string, public readonly control: AbstractControl, public readonly initialState: boolean) {
  }
}

export class FormAfterDisableState {
  private reenabledWasCalled: boolean = false;

  constructor(private readonly controlStates: ControlDisabledState[]) {
  }

  public reenable(emitEvent: boolean = true) {
    if (this.reenabledWasCalled) {
      return;
    }

    this.reenabledWasCalled = true;

    for (const item of this.controlStates) {
      if (!item.initialState) {
        item.control.enable({emitEvent: emitEvent});
      }
    }
  }
}

export class FormDefinition<T extends keyof any> {
  public static readonly SUMMARY_ERROR_KEY: string = '__summary__';

  // tslint:disable-next-line:variable-name
  private _form: FormGroup | null = null;

  public get form(): FormGroup {
    if (this._form == null) {
      this._form = this.formBuilder.group(this.buildFormGroupControlsConfig(this.fields), this.options);
    }

    return this._form!;
  }


  constructor(public readonly fields: FormFieldsDefinition<T>,
              private readonly formBuilder: FormBuilder,
              private readonly options?: AbstractControlOptions | {
                [key: string]: any;
              } | null) {
  }

  public patchValue(formValue: Partial<Record<Extract<keyof FormFieldsDefinition<T>, string>, any>>, emitEvent: boolean = true) {
    this.form.patchValue(formValue, {emitEvent: emitEvent});
  }

  private buildFormGroupControlsConfig(fields: FormFieldsDefinition<any>): FromGroupControlsConfig {
    const props: string[] = Object.keys(fields);
    const result: FromGroupControlsConfig = {};

    for (const propName of props) {
      const propValue = fields[propName];

      if (propValue instanceof FormFieldDefinition) {
        const val = typeof propValue.value === 'undefined' ? null : propValue.value;

        result[propName] = [{value: val, disabled: propValue.disabled}, propValue.validators];
      } else if (propValue instanceof FormArrayDefinition) {
        const cfgs: FromGroupControlsConfig[] = propValue.values.map(
          (item, idx) => item.buildFormGroupControlsConfig(item.fields));

        result[propName] = this.formBuilder.array(cfgs);
      }
    }

    return result;
  }

  public setFormFieldsServerValidationResults(validationErrors: ModelStateValidationResult[]) {
    const props: string[] = Object.keys(this.fields);
    const formErrors: string[] = [];

    for (const error of validationErrors) {
      if (error.key === '') {
        for (const err of error.errors) {
          formErrors.push(err);
        }

        continue;
      }

      for (const propName of props) {
        const propValue = this.fields[propName];

        if (!(propValue instanceof FormArrayDefinition) && propValue.matchesRequestField(error.key)) {
          const control = this.form.controls[propName];

          if (control.disabled && !environment.production) {
            console.warn(`Control ${propName} is disabled. Validation errors will not be set.`);
          }

          control.markAsTouched();
          control.setErrors({serverValidation: {messages: error.errors}});
          break;
        }
      }
    }

    const summaryErrors = {};

    summaryErrors[FormDefinition.SUMMARY_ERROR_KEY] = formErrors;

    this.form.setErrors(summaryErrors);
  }

  public getFormArray(name: Extract<keyof FormFieldsDefinition<T>, string>): FormArray {
    return this.form.controls[name] as FormArray;
  }

  public getControl(name: Extract<keyof FormFieldsDefinition<T>, string>): FormControl {
    return this.form.controls[name] as FormControl;
  }

  public getControlValue(name: Extract<keyof FormFieldsDefinition<T>, string>): any {
    return this.form.controls[name].value;
  }

  public setControlValue(name: Extract<keyof FormFieldsDefinition<T>, string>, value: any | null, opts?: {
    onlySelf?: boolean,
    emitEvent?: boolean,
    emitModelToViewChange?: boolean,
    emitViewToModelChange?: boolean
  }) {
    this.form.controls[name].setValue(value, opts);
  }

  public addControlError(name: Extract<keyof FormFieldsDefinition<T>, string>, messages: string[]) {
    if (messages.length === 0) {
      return;
    }

    const errors = {
      serverValidation: {
        messages: messages
      }
    };

    const control = this.getControl(name);

    control.setErrors(errors);
    control.markAsDirty();
  }

  public enable(fieldNames: Extract<keyof FormFieldsDefinition<T>, string>[] | 'all-fields', emitEvent: boolean = true) {
    if (fieldNames === 'all-fields') {
      const props: string[] = Object.keys(this.fields);

      props.forEach(fieldName => {
        const control = this.form.controls[fieldName];

        control.enable({emitEvent: emitEvent});
      });
    } else {
      fieldNames.forEach(fieldName => {
        const control = this.form.controls[fieldName];

        control.enable({emitEvent: emitEvent});
      });
    }
  }

  public disable(fieldNames: Extract<keyof FormFieldsDefinition<T>, string>[] | 'all-fields'): FormAfterDisableState {
    const fieldNamesToDisable: string[] = fieldNames === 'all-fields'
                                          ? Object.keys(this.fields)
                                          : fieldNames;

    const states: ControlDisabledState[] = fieldNamesToDisable.map((fieldName: Extract<keyof FormFieldsDefinition<T>, string>) => {
      const control = this.getControl(fieldName);

      return new ControlDisabledState(fieldName, control, control.disabled);
    });

    for (const ctrl of states) {
      ctrl.control.disable({emitEvent: false});
    }

    return new FormAfterDisableState(states);
  }
}
