<div class="menu-item-table--modifier-groups div-based-table compacted table-dark table-colored">
  <div class="thead">
    <div class="row">
      <div class="col-3">{{'Modifier group name' | translate}}</div>
      <div class="col-1">{{'Is active' | translate}}</div>
      <div class="col-3">{{'Name is visible to the guests' | translate}}</div>
      <div class="col-2">{{'Selectable modifiers' | translate}}</div>
      <div class="col-3">&nbsp;</div>
    </div>
  </div>
  <div class="tbody">
    <ng-container *ngFor="let modifierGroup of modifierGroupRows">
      <app-modifier-group-row [ngModel]="modifierGroup"
                              (deleteGroup)="deleteMenuItemModifierGroup($event)">
      </app-modifier-group-row>
    </ng-container>
  </div>
</div>
