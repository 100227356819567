export class NewFileData {
  constructor(public readonly name: string,
              public readonly base64: string) {
  }
}

export class InvalidFileData {
  constructor(public readonly name: string,
              public readonly originalFile: File) {
  }
}
