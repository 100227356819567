import {Store} from '@ngrx/store';
import {AppState} from './store/app.state';
import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import {getAuthenticatedUserIsRestaurantManagerSelector} from './store/selectors/restaurant-selectors';
import {filter, map, switchMap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {OnDestroy} from '@angular/core';
import RestaurantInfoWithSettingsAndContactDetails = Orderly.RestaurantWeb.Api.Messages.RestaurantInfoWithSettingsAndContactDetails;
import {BaseOrderlyComponent} from './modules/shared/components/base-orderly.component';
import {Language} from 'orderly-web-components';

export abstract class RestaurantComponent extends BaseOrderlyComponent implements OnDestroy {
  public readonly userIsManager: Observable<boolean> = of(false);
  public readonly restaurantIdParam$: ReplaySubject<number> = new ReplaySubject<number>(1);
  public readonly currentRestaurant$: Observable<RestaurantInfoWithSettingsAndContactDetails>;
  public readonly currentRestaurantPrimaryLanguage$: Observable<Language>;
  public readonly isPromotionsEnabled$: Observable<boolean>;
  public readonly selfOrderingKiosksEnabled$: Observable<boolean>;


  protected readonly destroyed$: Subject<any> = new Subject<any>()

  protected constructor(protected store: Store<AppState>,
                        protected activatedRoute: ActivatedRoute) {

    super();

    this.registerForAutoDestroy(this.restaurantIdParam$);

    this.registerForAutoDestroy(
      activatedRoute.paramMap
                    .pipe(
                      map(pm => pm.get('restaurantId')),
                      filter((restaurantId: string | null) => restaurantId != null),
                      map((restaurantId: string) => parseInt(restaurantId, 10))
                    )
                    .subscribe(this.restaurantIdParam$)
    );

    this.userIsManager = store.select(getAuthenticatedUserIsRestaurantManagerSelector);

    this.currentRestaurant$ = this.restaurantIdParam$
                                  .pipe(
                                    switchMap(rid => {
                                      return store.select(x => {
                                        if (x.auth.currentUser == null) {
                                          throw new Error(
                                            `User navigated to restaurant [id=${rid}] page without being authenticated`);
                                        }

                                        const restaurants = x.auth.currentUser.user.restaurants;
                                        const restaurantIdx = restaurants.findIndex(r => r.id === rid);

                                        if (restaurantIdx < 0) {
                                          throw new Error(
                                            `Could not find a restaurant [id=${rid}] among user's restaurants. UserId: ${x.auth.currentUser.user.id}`);
                                        }

                                        return restaurants[restaurantIdx];
                                      });
                                    })
                                  );

    this.currentRestaurantPrimaryLanguage$ = this.currentRestaurant$
                                                 .pipe(
                                                   map(x => x.settings.defaultMenuLanguage),
                                                 );

    this.isPromotionsEnabled$ = this.currentRestaurant$.pipe(map(x => x.settings.selfOrderingKiosksEnabled));
    this.selfOrderingKiosksEnabled$ = this.currentRestaurant$.pipe(map(x => x.settings.selfOrderingKiosksEnabled));
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
