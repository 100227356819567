import { CurrentRestaurantServiceBase } from './current-restaurant.service.base';
import { environment } from '../../environments/environment';
import { map, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
export class CurrentRestaurantDisplaysService extends CurrentRestaurantServiceBase {
    constructor(httpClient, store) {
        super(store);
        this.httpClient = httpClient;
        this.baseURL = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
    }
    get currentRestaurantDisplays$() {
        return this.dataFeed$;
    }
    doLoadData(restaurantId) {
        const url = `${this.baseURL}/restaurant/${restaurantId}/displays/all`;
        const response$ = this.httpClient.get(url);
        return response$.pipe(map(x => x.displays));
    }
    reportBroken(displayId, data) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap(restaurantId => {
            const url = this.baseURL + `/display/${restaurantId}/report-broken/${displayId}`;
            return this.httpClient.post(url, data);
        }));
    }
    link(data) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap(restaurantId => {
            const url = this.baseURL + `/display/${restaurantId}/link`;
            return this.httpClient.post(url, data);
        }));
    }
    unlink(data) {
        return this.restaurantIdRouteParam$
            .pipe(switchMap(restaurantId => {
            const url = this.baseURL + `/display/${restaurantId}/unlink`;
            return this.httpClient.post(url, data);
        }));
    }
}
CurrentRestaurantDisplaysService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentRestaurantDisplaysService_Factory() { return new CurrentRestaurantDisplaysService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: CurrentRestaurantDisplaysService, providedIn: "root" });
