import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IngredientsService} from '../ingredients.service';
import {map, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {RestaurantSpecificArrayResultLoadingState} from './helper.statuses';
import {CurrentRestaurantServiceBase} from './current-restaurant.service.base';
import IngredientCategory = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddOrUpdateIngredientCategoryResponse.IngredientCategory;
import AddIngredientCategoryRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddIngredientCategoryRequest;
import AddOrUpdateIngredientCategoryResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddOrUpdateIngredientCategoryResponse;
import UpdateIngredientCategoryRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.UpdateIngredientCategoryRequest;
import DeleteIngredientCategoryResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.DeleteIngredientCategoryResponse;
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
              providedIn: 'root'
            })
export class CurrentRestaurantIngredientCategoriesService extends CurrentRestaurantServiceBase<IngredientCategory> {
  private baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  public get currentRestaurantCategories$(): Observable<RestaurantSpecificArrayResultLoadingState<IngredientCategory>> {
    return this.dataFeed$;
  }

  constructor(private ingredientsService: IngredientsService,
              private httpClient: HttpClient,
              store: Store<AppState>) {
    super(store);
  }

  protected doLoadData(restaurantId: number): Observable<IngredientCategory[]> {
    return this.ingredientsService.getAllIngredientCategories(restaurantId).pipe(map(x => x.categories));
  }

  public add(request: AddIngredientCategoryRequest): Observable<AddOrUpdateIngredientCategoryResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap(
                   restaurantId => {
                     const url: string = `${this.baseURL}/restaurant/${restaurantId}/ingredient-category/add`;
                     const result = this.httpClient.post<AddOrUpdateIngredientCategoryResponse>(url, request);

                     return result;
                   }
                 )
               );
  }

  public update(data: UpdateIngredientCategoryRequest): Observable<AddOrUpdateIngredientCategoryResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap(
                   restaurantId => {
                     const url: string = `${this.baseURL}/restaurant/${restaurantId}/ingredient-category/${data.id}/update`;
                     const result = this.httpClient.post<AddOrUpdateIngredientCategoryResponse>(url, data);

                     return result;
                   }
                 )
               );
  }

  public delete(ingredientCategoryId: number): Observable<DeleteIngredientCategoryResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 switchMap(
                   restaurantId => {
                     const url: string = `${this.baseURL}/restaurant/${restaurantId}/ingredient-category/${ingredientCategoryId}/delete`;
                     const result = this.httpClient.delete<DeleteIngredientCategoryResponse>(url);

                     return result;
                   }
                 )
               );
  }
}
