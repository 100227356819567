<div class="modal-header pd-y-20 pd-x-25">
  <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{translationsCfg.modalTitle}}</h6>
  <button type="button" class="close" [attr.aria-label]="'Close'" (click)="modalService.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body pd-25 align-content-center">

  <img *ngIf="base64QrImageContent | async as img"
       [src]="domSanitizationService.bypassSecurityTrustUrl('data:image/jpeg;base64,' + img)"
       alt="{{translationsCfg.modalTitle}}"
       height="260"
       width="260"
       style="display: block; margin: 0 auto;"
       [ngClass]="{'hidden-xs-up': base64QrImageContentLoading}" />

  <div class="w-100 h-50 align-content-center" [ngClass]="{'hidden-xs-up': !base64QrImageContentLoading}">
    <owc-progress-wave></owc-progress-wave>
  </div>
</div>

<div class="modal-footer tx-white">
  <owc-progress-action-button [actionInProgress]="downloadRunning"
                              (execute)="downloadAsPdf()"
                              [btnText]="translationsCfg.downloadAsPdf"
                              [disabled]="base64QrImageContentLoading"
                              [btnClass]="'btn-info btn-sm'"
                              type="link">
  </owc-progress-action-button>

  <owc-progress-action-button [actionInProgress]="downloadRunning"
                              (execute)="downloadAsImage()"
                              [btnText]="translationsCfg.downloadAsQrCodeImage"
                              [disabled]="base64QrImageContentLoading"
                              [btnClass]="'btn-info btn-sm'"
                              type="link">
  </owc-progress-action-button>

  <owc-progress-action-button [actionInProgress]="downloadRunning"
                              (execute)="downloadAsSticker()"
                              [btnText]="translationsCfg.downloadAsStickerImage"
                              [disabled]="base64QrImageContentLoading"
                              [btnClass]="'btn-info btn-sm'"
                              type="link">
  </owc-progress-action-button>
</div>
