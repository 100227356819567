import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {QrMenuActionTypeDefTranslations} from '../util/trn.utils';
import {Observable} from 'rxjs';
import QrMenuActionTypeDef = Orderly.Common.Enums.QrMenuActionTypeDef;
import {NeverError} from 'orderly-web-components';

@Pipe({name: 'menuButtonActionDefText'})
export class MenuButtonActionDefTextPipe implements PipeTransform {
  constructor(private readonly trnService: TranslateService) {
  }

  transform(value: Orderly.Common.Enums.QrMenuActionTypeDef): Observable<string> {
    switch (value) {
      case QrMenuActionTypeDef.CallWaiter:
        return this.trnService.get(QrMenuActionTypeDefTranslations.CallWaiter);
      case QrMenuActionTypeDef.UploadedPdf:
        return this.trnService.get(QrMenuActionTypeDefTranslations.UploadedPdf);
      case QrMenuActionTypeDef.OnlineMenu:
        return this.trnService.get(QrMenuActionTypeDefTranslations.OnlineMenu);

      default:
        throw new NeverError(value);
    }
  }
}
