import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {FormDefinition} from '../../../../../../../util/form.utils';
import {FormBuilder} from '@angular/forms';
import {
  createForm,
  ModifierEditorFormDefinition,
  NewModifierEditorFormDefinition
} from './modifier-editor.form-definition';
import {combineLatest, Observable} from 'rxjs';
import {CurrentRestaurantIngredientsService} from '../../../../../../../services/active-route-bound/current-restaurant-ingredients.service';
import {TextTranslationDefinition} from '../../../../../shared/components/text-input-with-language-flag/text-translation.definition';
import RestaurantIngredientBasic = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.RestaurantIngredientBasic;
import {debounceTime, map, startWith, tap} from 'rxjs/operators';
import {RestaurantComponent} from '../../../../../../restaurant.component';
import {Language} from 'orderly-web-components';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {ActivatedRoute} from '@angular/router';

@Component({
             selector: 'app-modifier-editor',
             templateUrl: './modifier-editor.component.html',
             styleUrls: ['./modifier-editor.component.scss'],
             changeDetection: ChangeDetectionStrategy.OnPush,
           })
export class ModifierEditorComponent extends RestaurantComponent implements OnInit {

  public readonly formDef: FormDefinition<keyof ModifierEditorFormDefinition> = createForm(this.formBuilder);

  public readonly translationLanguages$: Observable<Language[]>;

  public readonly ingredients$: Observable<RestaurantIngredientBasic[]>;

  public isDisabled: boolean = false;

  @Input()
  public modifier: ModifierEditorFormDefinition | NewModifierEditorFormDefinition | null;

  @Output()
  public readonly cancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly save: EventEmitter<ModifierEditorFormDefinition> = new EventEmitter<ModifierEditorFormDefinition>();


  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private readonly formBuilder: FormBuilder,
              private readonly cdr: ChangeDetectorRef,
              ingredientsService: CurrentRestaurantIngredientsService) {

    super(store, activatedRoute);

    this.ingredients$ = ingredientsService.currentLoadedRestaurantIngredients$;

    this.translationLanguages$ = this.currentRestaurant$.pipe(map(x => x.settings.enabledMenuLanguages));

    const basedOnIngredient$: Observable<boolean | null> = this.formDef.getControl('basedOnIngredient').valueChanges.pipe(startWith(null));
    const amount$: Observable<number | null> = this.formDef.getControl('amount').valueChanges.pipe(startWith(null));

    this.registerForAutoDestroy(
      combineLatest([basedOnIngredient$, amount$])
        .pipe(
          tap((value: [boolean | null, number | string | null]) => {

            if (value[0]) {
              this.formDef.disable(['name', 'measureUnit']);

              if (!this.isDisabled) {
                this.formDef.enable(['ingredient'], false);
              }
            } else {
              this.formDef.disable(['ingredient']);

              if (!this.isDisabled) {
                this.formDef.enable(['name', 'measureUnit'], false);
              }
            }

            if (value[1] != null && value[1] !== '') {
              if (!this.isDisabled && value[0] === false) {
                this.formDef.enable(['measureUnit'], false);
              }
            } else {
              this.formDef.getControl('measureUnit').setValue(null, {emitEvent: false});
              this.formDef.disable(['measureUnit']);
            }
          }),
        )
        .subscribe()
    );

    this.registerForAutoDestroy(
      this.formDef
          .getControl('name')
          .valueChanges
          .pipe(
            debounceTime(600),
            tap((value: TextTranslationDefinition) => {

              this.nameChanged(value);
            }),
          )
          .subscribe()
    );

    this.registerForAutoDestroy(
      this.formDef
          .getControl('ingredient')
          .valueChanges
          .pipe(
            tap((value: RestaurantIngredientBasic | null) => {
              if (value != null) {
                this.formDef.getControl('measureUnit').setValue(value.measureUnit, {emitEvent: false});
              }
            }),
          )
          .subscribe()
    );
  }

  ngOnInit() {
    if (this.modifier != null) {
      this.formDef.patchValue(this.modifier, true);

      this.cdr.markForCheck();
    }
  }

  nameChanged(nameValue: TextTranslationDefinition) {
    const nameTranslationsControl = this.formDef.getControl('nameTranslations');
    const translations: TextTranslationDefinition[] = nameTranslationsControl.value;
    const existingNameTranslationIndex =  translations.findIndex(x => x.langCode2.toLowerCase() === nameValue.langCode2.toLowerCase());

    if (existingNameTranslationIndex >= 0) {
      const newNameTranslations = [...translations];

      newNameTranslations[existingNameTranslationIndex].text = nameValue.text;

      nameTranslationsControl.patchValue(newNameTranslations, {emitEvent: false});
    } else {
      const newNameTranslations = [...translations, nameValue];

      nameTranslationsControl.patchValue(newNameTranslations, {emitEvent: false});
    }

    if (nameValue.text == null || nameValue.text === '') {
      nameTranslationsControl.disable({emitEvent: false});
    } else {
      nameTranslationsControl.enable({emitEvent: false});
    }

    this.cdr.markForCheck();
  }

  public saveChanges(): void {
    const formValue: ModifierEditorFormDefinition = this.formDef.form.getRawValue();

    this.save.emit(formValue);
  }
}
