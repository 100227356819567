import { first, flatMap, map, tap } from 'rxjs/operators';
import { CurrentRestaurantServiceBase } from './current-restaurant.service.base';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
export class CurrentRestaurantMenuItemCategoriesService extends CurrentRestaurantServiceBase {
    constructor(httpClient, store) {
        super(store);
        this.httpClient = httpClient;
        this.baseURL = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
    }
    get currentRestaurantMenuItemCategories$() {
        return this.dataFeed$;
    }
    doLoadData(restaurantId) {
        const url = `${this.baseURL}/restaurant/${restaurantId}/menu-item-categories/all`;
        const response$ = this.httpClient.get(url);
        return response$.pipe(tap(x => {
            if (x.status === 2 /* UnknownFailure */) {
                throw new Error('Server returned an error. Could not load list of menu item categories.');
            }
        }), map(x => x.items.sort((i1, i2) => {
            if (i1.displaySortOrder === i2.displaySortOrder) {
                return i1.id - i2.id;
            }
            return i1.displaySortOrder - i2.displaySortOrder;
        })));
    }
    add(request) {
        return this.restaurantIdRouteParam$
            .pipe(first(), flatMap((restaurantId) => {
            const url = this.baseURL + `/restaurant/${restaurantId}/menu-item-category/add`;
            return this.httpClient.post(url, request);
        }));
    }
    reorder(request) {
        return this.restaurantIdRouteParam$
            .pipe(first(), flatMap((restaurantId) => {
            const url = this.baseURL + `/restaurant/${restaurantId}/menu-item-categories/reorder`;
            return this.httpClient.post(url, request);
        }));
    }
    update(categoryId, request) {
        return this.restaurantIdRouteParam$
            .pipe(first(), flatMap((restaurantId) => {
            const url = this.baseURL + `/restaurant/${restaurantId}/menu-item-category/${categoryId}/update`;
            return this.httpClient.post(url, request);
        }));
    }
    delete(categoryId) {
        return this.restaurantIdRouteParam$
            .pipe(first(), flatMap((restaurantId) => {
            const url = this.baseURL + `/restaurant/${restaurantId}/menu-item-category/${categoryId}/delete`;
            return this.httpClient.delete(url);
        }));
    }
}
CurrentRestaurantMenuItemCategoriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentRestaurantMenuItemCategoriesService_Factory() { return new CurrentRestaurantMenuItemCategoriesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: CurrentRestaurantMenuItemCategoriesService, providedIn: "root" });
