import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {RestaurantRoles} from '../util/trn.utils';
import {environment} from '../environments/environment';
import {map} from 'rxjs/operators';

@Pipe({name: 'joinRestaurantRoles'})
export class JoinRestaurantRolesPipe implements PipeTransform {

  constructor(private readonly trnService: TranslateService) {
  }

  transform(roleCodes: string[]): Observable<string> {

    const roles: string[] = roleCodes.map(this.matchRoleCodeToName);

    return this.trnService
               .get(roles)
               .pipe(
                 map((trns: any) => {
                   const names: string[] = [];

                   if (trns[RestaurantRoles.Waiter.name]) {
                     names.push(trns[RestaurantRoles.Waiter.name]);
                   }

                   if (trns[RestaurantRoles.Manager.name]) {
                     names.push(trns[RestaurantRoles.Manager.name]);
                   }

                   if (trns[RestaurantRoles.ManagerAssistant.name]) {
                     names.push(trns[RestaurantRoles.ManagerAssistant.name]);
                   }

                   if (trns[RestaurantRoles.Owner.name]) {
                     names.push(trns[RestaurantRoles.Owner.name]);
                   }

                   return names.sort().join(', ');
                 })
               );
  }

  private matchRoleCodeToName(code: string): string {
    if (code === RestaurantRoles.Waiter.code) {
      return RestaurantRoles.Waiter.name;
    }

    if (code === RestaurantRoles.Manager.code) {
      return RestaurantRoles.Manager.name;
    }

    if (code === RestaurantRoles.ManagerAssistant.code) {
      return RestaurantRoles.ManagerAssistant.name;
    }

    if (code === RestaurantRoles.Owner.code) {
      return RestaurantRoles.Owner.name;
    }

    if (code === RestaurantRoles.HoldingExecutive.code) {
      return RestaurantRoles.HoldingExecutive.name;
    }

    if (code === RestaurantRoles.HoldingManager.code) {
      return RestaurantRoles.HoldingManager.name;
    }

    if (!environment.production) {
      console.log('Unknown user role code: ' + code);
    }

    return code;
  }
}
