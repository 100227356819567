import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {ActionTypes} from '../actions/action-types';
import {map, switchMap} from 'rxjs/operators';
import {AreaCreationSuccessfulAction} from '../actions/area.actions';
import {CurrentRestaurantAreasService} from '../../../services/active-route-bound/current-restaurant-areas.service';


@Injectable()
export class AreaEffect {

  constructor(private readonly actions: Actions,
              private readonly areaService: CurrentRestaurantAreasService) {
  }

  @Effect({dispatch: false})
  creatAreaEffect: Observable<Action> = this.actions.pipe(
    ofType<AreaCreationSuccessfulAction>(ActionTypes.AREA_CREATE_SUCCESSFUL),

    switchMap((action: AreaCreationSuccessfulAction) => {

      return this.areaService
                 .forceReload()
                 .pipe(
                   map(x => action)
                 );
    })
  );
}
