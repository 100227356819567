import {FileUploadValidatorDefinition} from '../../../shared/shared.module';
import {ImageFileTypeValidator} from 'orderly-web-components';

export class FileExtensionValidator extends FileUploadValidatorDefinition {

  constructor(/*private readonly trnService: TranslateService*/) {
    super();
  }

  validate(file: File): boolean {

    return ImageFileTypeValidator.validatePdfFileExtension(file.name);
  }
}
