export class RestaurantManagerRoutingConstants {
  public static DASHBOARD: string = 'dashboard';
  public static RESTAURANT: string = 'restaurant';
  public static RESTAURANT_EDIT: string = 'edit';
  public static RESTAURANT_ADD: string = 'add';
  public static AREAS_LIST: string = 'areas';
  public static TERMINALS_LIST: string = 'terminals';
  public static REVIEWS_LIST: string = 'reviews';
  public static MENU_ITEMS_LIST: string = 'menu-items';
  public static MENU_ITEM_CATEGORIES_LIST: string = 'menu-item-categories';
  public static MENU_ITEM_CATEGORY_CREATE: string = 'add';
  public static MENU_ITEM_CATEGORY_EDIT: string = 'edit';
  public static MENU_ITEM_CATEGORY_EDIT_WITH_ID_PARAM: string = ':menuItemCategoryId/' + RestaurantManagerRoutingConstants.MENU_ITEM_CATEGORY_EDIT;
  public static INGREDIENTS: string = 'ingredients';
  public static INGREDIENT_CATEGORIES: string = 'ingredient-categories';
  public static TABLES: string = 'tables';
  public static REQUESTED_TABLE_ACTIONS: string = 'requested-table-actions';
  public static WAITERS: string = 'waiters';
  public static COVID_CHECKINS: string = 'covid-checkins';
  public static MENU_ITEM_CREATE: string = 'add';
  public static MENU_ITEM_EDIT: string = 'edit';
  public static MENU_ITEM_EDIT_WITH_ID_PARAM: string = RestaurantManagerRoutingConstants.MENU_ITEM_EDIT + '/:menuItemId';
  public static RESTAURANT_WITH_ID_PARAM: string = RestaurantManagerRoutingConstants.RESTAURANT + '/:restaurantId';

  public static getHoldingDashboardFullUrl() {
    return RoutingConstants.ROOT + '/' + RestaurantManagerRoutingConstants.DASHBOARD;
  }

  public static getRestaurantDashboardFullUrl(id: number) {
    return RoutingConstants.ROOT + '/' + RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' + id;
  }

  public static getRestaurantAddUrl() {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT_ADD;
  }

  public static getRestaurantEditUrl(restaurantId: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           restaurantId + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT_EDIT;
  }

  public static getRestaurantIngredientsCreateUrl(restaurantId: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           restaurantId + '/' +
           RestaurantManagerRoutingConstants.INGREDIENTS;
  }

  public static getRestaurantIngredientCategoriesCreateUrl(restaurantId: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           restaurantId + '/' +
           RestaurantManagerRoutingConstants.INGREDIENT_CATEGORIES;
  }

  public static getRestaurantTablesCreateUrl(restaurantId: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           restaurantId + '/' +
           RestaurantManagerRoutingConstants.TABLES;
  }

  public static getRestaurantRequestedTableActionsCreateUrl(restaurantId: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           restaurantId + '/' +
           RestaurantManagerRoutingConstants.REQUESTED_TABLE_ACTIONS;
  }

  public static getCovidCheckinsCreateUrl(restaurantId: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           restaurantId + '/' +
           RestaurantManagerRoutingConstants.COVID_CHECKINS;
  }

  public static getRestaurantWaitersCreateUrl(restaurantId: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           restaurantId + '/' +
           RestaurantManagerRoutingConstants.WAITERS;
  }

  public static getRestaurantMenuItemsListUrl(id: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           id + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEMS_LIST;
  }

  public static getRestaurantMenuItemAddUrl(id: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           id + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEMS_LIST + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEM_CREATE;
  }

  public static getRestaurantMenuItemEditUrlRoot(id: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           id + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEMS_LIST + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEM_EDIT;
  }

  public static getRestaurantAreasListUrl(id: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           id + '/' +
           RestaurantManagerRoutingConstants.AREAS_LIST;
  }

  public static getRestaurantMenuItemCategoriesListUrl(id: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           id + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEM_CATEGORIES_LIST;
  }

  public static getRestaurantMenuItemCategoryEditUrl(restaurantId: number, categoryId: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           restaurantId + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEM_CATEGORIES_LIST + '/' +
           categoryId + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEM_CATEGORY_EDIT;
  }

  public static getRestaurantMenuItemCategoryAddUrl(restaurantId: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           restaurantId + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEM_CATEGORIES_LIST + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEM_CATEGORY_CREATE;
  }

  public static getRestaurantTerminalsListUrl(id: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           id + '/' +
           RestaurantManagerRoutingConstants.TERMINALS_LIST;
  }

  public static getRestaurantReviewsListUrl(id: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           id + '/' +
           RestaurantManagerRoutingConstants.REVIEWS_LIST;
  }

  public static getRestaurantMenuItemCreateUrl(restaurantId: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           restaurantId + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEMS_LIST + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEM_CREATE;
  }

  public static getRestaurantMenuItemEditUrl(id: number, menuItemId: number) {
    return RoutingConstants.ROOT + '/' +
           RestaurantManagerRoutingConstants.DASHBOARD + '/' +
           RestaurantManagerRoutingConstants.RESTAURANT + '/' +
           id + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEMS_LIST + '/' +
           RestaurantManagerRoutingConstants.MENU_ITEM_EDIT + '/' +
           menuItemId;
  }
}

export class RoutingConstants {
  public static ROOT: string = 'admin';
  public static LOGIN: string = 'login';
  public static REGISTRATION: string = 'registration';
  public static REGISTRATION_FULL: string = 'registration-full';
  public static REGISTRATION_WITH_PDF: string = 'registration-with-menu';
  public static ACCOUNT_CONFIRMATION: string = 'confirm-account';
  public static ACCOUNT_CONFIRMATION_WITH_TOKEN_PARAM: string = RoutingConstants.ACCOUNT_CONFIRMATION + '/:token';
  public static FORGOT_PASSWORD: string = 'forgot-password';
  public static RESET_PASSWORD: string = 'reset-password/:token';
}

export class RoutingHelper {
  public static getAccountConfirmationFullUrl(token: string) {
    return RoutingConstants.ACCOUNT_CONFIRMATION + '/' + token;
  }
}
