import moment, {Moment} from 'moment';

export class RestaurantSpecificArrayResultLoadingState<T> {
  public readonly createdOn: Moment;

  constructor(public readonly loadingStatus: RestaurantSpecificResultLoadingStatusDefinition,
              public readonly items: T[],
              public readonly error?: any | null) {
    this.createdOn = moment();
  }

  public isNotStarted(): boolean {
    return this.loadingStatus === RestaurantSpecificResultLoadingStatusDefinition.NOT_STARTED;
  }

  public isFailed(): boolean {
    return this.loadingStatus === RestaurantSpecificResultLoadingStatusDefinition.FAILED;
  }

  public isLoadingOrCleared(): boolean {
    return this.loadingStatus === RestaurantSpecificResultLoadingStatusDefinition.LOADING ||
           this.loadingStatus === RestaurantSpecificResultLoadingStatusDefinition.CLEARED_BECAUSE_NO_RESTAURANT_ID;
  }

  public isLoading(): boolean {
    return this.loadingStatus === RestaurantSpecificResultLoadingStatusDefinition.LOADING;
  }

  public isLoaded(): boolean {
    return this.loadingStatus === RestaurantSpecificResultLoadingStatusDefinition.SUCCESS;
  }
}

export enum RestaurantSpecificResultLoadingStatusDefinition {
  NOT_STARTED = 1,
  LOADING = 2,
  FAILED = 3,
  SUCCESS = 4,
  CLEARED_BECAUSE_NO_RESTAURANT_ID = 5
}
