import {Injectable} from '@angular/core';
import {CurrentRestaurantServiceBase} from './current-restaurant.service.base';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {RestaurantSpecificArrayResultLoadingState} from './helper.statuses';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {flatMap, map} from 'rxjs/operators';
import RestaurantUser = Orderly.RestaurantWeb.Api.Messages.Restaurant.GetRestaurantUsersResponse.RestaurantUser;
import GetRestaurantUsersResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.GetRestaurantUsersResponse;
import AddRestaurantUserRequest = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddRestaurantUserRequest;
import AddRestaurantUserResponse = Orderly.RestaurantWeb.Api.Messages.Restaurant.AddRestaurantUserResponse;
import DeleteUserResponse = Orderly.RestaurantWeb.Api.Messages.Waiter.DeleteUserResponse;

@Injectable({
              providedIn: 'root'
            })
export class CurrentRestaurantUsersService extends CurrentRestaurantServiceBase<RestaurantUser> {
  private baseURL: string = `${environment.baseApiUrlWithTrailingSlash}v1.0`;

  public get currentRestaurantUsers$(): Observable<RestaurantSpecificArrayResultLoadingState<RestaurantUser>> {
    return this.dataFeed$;
  }

  constructor(private httpClient: HttpClient, store: Store<AppState>) {
    super(store);
  }

  protected doLoadData(restaurantId: number): Observable<RestaurantUser[]> {
    const url: string = `${this.baseURL}/restaurant/${restaurantId}/users/all`;
    const response$ = this.httpClient.get<GetRestaurantUsersResponse>(url);

    return response$.pipe(map(x => x.users));
  }


  public invite(data: AddRestaurantUserRequest): Observable<AddRestaurantUserResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 flatMap(restaurantId => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/users/add`;

                   return this.httpClient.post<AddRestaurantUserResponse>(url, data);
                 })
               );
  }

  public delete(waiterUserId: number): Observable<DeleteUserResponse> {
    return this.restaurantIdRouteParam$
               .pipe(
                 flatMap(restaurantId => {
                   const url: string = this.baseURL + `/restaurant/${restaurantId}/user/${waiterUserId}/delete`;

                   return this.httpClient.delete<DeleteUserResponse>(url, {});
                 })
               );
  }
}
