import { of } from 'rxjs';
import { environment } from '../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { NeverError } from 'orderly-web-components';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.baseUrl = `${environment.baseApiUrlWithTrailingSlash}v1.0/user`;
    }
    getCurrentUser(mode) {
        switch (mode) {
            case 'from-server':
                const url = `${this.baseUrl}/current`;
                const serverResult = this.httpClient.get(url);
                return serverResult;
            case 'from-cache':
                const cachedUser = LocalStorageService.getCurrentUser();
                if (cachedUser == null) {
                    throw new Error('Could not get current user from cache');
                }
                const cacheResult = {
                    associatedRestaurants: cachedUser.user.restaurants,
                    firstName: cachedUser.user.firstName,
                    holdingInfo: cachedUser.user.holdingInfo,
                    lastName: cachedUser.user.lastName,
                    preferredLang: cachedUser.user.preferredLang,
                    id: cachedUser.user.id,
                    validationErrors: []
                };
                return of(cacheResult);
            default:
                throw new NeverError(mode);
        }
    }
    updateUser(user) {
        const url = `${this.baseUrl}/update`;
        const request = {
            firstName: user.firstName,
            lastName: user.lastName,
            preferredLanguageCode2: user.preferredLanguage.code,
        };
        const result = this.httpClient.post(url, request);
        return result;
    }
    updateUserLanguage(langCode2) {
        const url = `${this.baseUrl}/current/change-language`;
        const request = {
            languageCode2: langCode2
        };
        const result = this.httpClient.post(url, request);
        return result;
    }
    changePassword(userId, oldPassword, newPassword) {
        const url = `${this.baseUrl}/change-password`;
        const request = { userId: userId, oldPassword: oldPassword, newPassword: newPassword };
        const result = this.httpClient.post(url, request);
        return result;
    }
    confirmAccount(token, password) {
        const url = `${this.baseUrl}/confirm-invitation/${token}`;
        const request = { password: password };
        const result = this.httpClient.post(url, request);
        return result;
    }
    initiatePasswordReset(email, languageCode2) {
        const url = `${environment.baseApiUrlWithTrailingSlash}v1.0/restaurant/admin/reset-password`;
        const request = { email: email, languageCode2: languageCode2 };
        const result = this.httpClient.post(url, request);
        return result;
    }
    completePasswordReset(token, newPassword) {
        const url = `${environment.baseApiUrlWithTrailingSlash}v1.0/restaurant/admin/reset-password/${token}`;
        const request = { newPassword: newPassword };
        const result = this.httpClient.post(url, request);
        return result;
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
