/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-preview-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./image-preview-modal.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_ImagePreviewModalComponent = [i0.styles];
var RenderType_ImagePreviewModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImagePreviewModalComponent, data: {} });
export { RenderType_ImagePreviewModalComponent as RenderType_ImagePreviewModalComponent };
export function View_ImagePreviewModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header pd-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "btn btn-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-cancel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "modal-body pd-0-force"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "image-preview"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; var currVal_1 = _co.base64ContentOrUrl.name; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_ImagePreviewModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-preview-modal", [], null, null, null, View_ImagePreviewModalComponent_0, RenderType_ImagePreviewModalComponent)), i1.ɵdid(1, 49152, null, 0, i2.ImagePreviewModalComponent, [i3.NgbActiveModal], null, null)], null, null); }
var ImagePreviewModalComponentNgFactory = i1.ɵccf("app-image-preview-modal", i2.ImagePreviewModalComponent, View_ImagePreviewModalComponent_Host_0, { base64ContentOrUrl: "base64ContentOrUrl" }, {}, []);
export { ImagePreviewModalComponentNgFactory as ImagePreviewModalComponentNgFactory };
