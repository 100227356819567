<app-top-bar-with-language-switcher></app-top-bar-with-language-switcher>

<div class="d-flex align-items-center justify-content-center bg-br-primary ht-100v">

  <div class="login-wrapper wd-400 wd-xs-400 wd-lg-500 pd-25 pd-xs-40 bg-white rounded shadow-base">
    <div class="signin-logo tx-center tx-28 tx-bold tx-inverse pd-md-b-20"><span class="tx-normal">[</span>MY<span class="tx-info">CAFY</span> <span class="tx-normal">]</span></div>

    <div *ngIf="requestedSuccessfully" class="text-justify">
      <h4>{{'We sent an email on your email-address. Follow the steps described in the email to complete the recovery process.' | translate}}</h4>
      <h6 class="mg-xl-t-30-force mg-xs-t-20-force"><a routerLink="/login">{{'Click here to navigate to login page' | translate}}</a></h6>
    </div>

    <form [formGroup]="formDef.form" (ngSubmit)="requestRecovery()" *ngIf="!requestedSuccessfully" style="width: 100%;">
      <h4 class="mg-b-15">{{'Please, provide your email address that you used to register' | translate}}:</h4>

      <div class="form-group">
        <input class="form-control" type="text" [formControl]="formDef.getControl('email')" [placeholder]="'E-Mail' | translate" />

        <owc-input-validation-errors [control]="formDef.getControl('email')"></owc-input-validation-errors>
      </div>

      <owc-progress-action-button type="submit"
                                  [btnText]="'Request recovery code' | translate"
                                  [btnClass]="'btn-info btn-block'"
                                  (execute)="requestRecovery()"
                                  [actionInProgress]="actionInProgress"
                                  [disabled]="formDef.form.invalid"
                                  class="d-block">
      </owc-progress-action-button>
    </form>

  </div>
</div>
