import {Component} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';
import {GetAllStaticDataResponse, Language, StaticDataService} from 'orderly-web-components';
import {environment} from '../../../../../environments/environment';
import {map, tap} from 'rxjs/operators';

@Component({
             selector: 'app-shared-languages-select',
             templateUrl: './languages-select.component.html',
             styleUrls: ['./languages-select.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: LanguagesSelectComponent,
                 multi: true,
               },
             ],
           })
export class LanguagesSelectComponent implements ControlValueAccessor {

  public languages$: Observable<Language[]>;

  public lastValue: Language[] = [];

  public isDisabled: boolean = false;


  constructor(private readonly staticDataService: StaticDataService) {
    this.languages$ = this.staticDataService
                          .getAllStaticData(environment.baseApiUrlWithTrailingSlash)
                          .pipe(
                            map((x: GetAllStaticDataResponse) => x.allLanguages),
                            tap((x: Language[]) => {

                              if (x.length === 0) {
                                this.isDisabled = true;
                              }
                            })
                          );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: Language[]) => {
  };

  onTouched: any = () => {
  };

  public compareLanguages(a: Language, b: Language) {
    return a.id === b.id;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: Language[] | null): void {
    this.lastValue = value || [];
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  changed(newValue: Language[]) {
    this.lastValue = newValue;

    this.onTouched();
    this.onChange(newValue);
  }

}
