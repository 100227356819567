import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {combineLatest, EMPTY, from, Observable, ReplaySubject} from 'rxjs';
import {TranslationsDefinition} from './translations-definition';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Language, NeverError, ToastService} from 'orderly-web-components';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {catchError, filter, finalize, first, flatMap, map, switchMap, tap} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {FormDefinition, FormFieldDefinition, FormFieldsDefinition} from '../../../../../util/form.utils';
import {CurrentRestaurantMenuItemCategoriesService} from '../../../../../services/active-route-bound/current-restaurant-menu-item-categories.service';
import {
  assignTranslatedProperties,
  getArrayOfTranslationKeys,
  trnTryAgainLaterText
} from '../../../../../util/trn.utils';
import {genericErrorHandlerWithToast, parseInteger} from '../../../../../util/utils';
import {RestaurantComponent} from '../../../../restaurant.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {ActivatedRoute, Router} from '@angular/router';
import {MenuItemCategoryFormFieldsDefinition} from './menu-item-category-form-fields.definition';
import {CategoryDetailsFieldsDefinition} from './details-tab/category-details-fields.definition';
import {ImageFromServerModel} from './image-from-server.model';
import {CategoryImageFromServerModel} from './category-image-from-server.model';
import {TextTranslationDefinition} from '../../../shared/components/text-input-with-language-flag/text-translation.definition';
import {CategoryTranslationsFormFieldsDefinition} from './translations-tab/category-translations-form-fields.definition';
import {ProgressDialogService} from '../../../../../../../orderly-web-components/src/lib/progress-dialog.service';
import {RestaurantManagerRoutingConstants} from '../../../../routing-constants';
import MenuItemCategory = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemCategoryResponse.MenuItemCategory;
import AddOrUpdateMenuItemCategoryResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemCategoryResponse;
import UpdateMenuItemCategoryRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.UpdateMenuItemCategoryRequest;
import AddMenuItemCategoryRequest = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddMenuItemCategoryRequest;
import MenuCategoryItemsThumbVisibilityDef = Orderly.Common.Enums.MenuCategoryItemsThumbVisibilityDef;
import RestaurantInfoWithSettingsAndContactDetails = Orderly.RestaurantWeb.Api.Messages.RestaurantInfoWithSettingsAndContactDetails;
import StatusDef = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.AddOrUpdateMenuItemCategoryResponse.StatusDef;
import MenuItemCategoryTranslationDefinition = Orderly.RestaurantWeb.Api.Messages.RestaurantMenu.MenuItemCategoryTranslationDefinition;
import ImageWithBase64Def = Orderly.Shared.Api.Messages.ImageWithBase64Def;

@Component({
  selector: 'app-add-or-edit-menu-item-category',
  templateUrl: './add-or-edit-menu-item-category.component.html',
  styleUrls: ['./add-or-edit-menu-item-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddOrEditMenuItemCategoryComponent extends RestaurantComponent implements OnInit, OnDestroy {

  public categoryToEdit$: Observable<MenuItemCategory | null> = this.getCurrentCategory();

  public activeTab: 'details' | 'translations' = 'details';

  @ViewChild('categoryImageFileInput', {static: false})
  categoryImageFileInput: ElementRef;

  public indicateNameOrDescriptionChange: boolean = false;

  public formDef: FormDefinition<keyof MenuItemCategoryFormFieldsDefinition> = this.buildFormDefinition();

  public translations$: ReplaySubject<TranslationsDefinition> = new ReplaySubject<TranslationsDefinition>();


  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private readonly formBuilder: FormBuilder,
              private readonly router: Router,
              private readonly trnService: TranslateService,
              private readonly toastService: ToastService,
              private readonly progressDialogService: ProgressDialogService,
              private readonly menuItemCategoriesService: CurrentRestaurantMenuItemCategoriesService,
              private readonly cdr: ChangeDetectorRef,
              public readonly domSanitizationService: DomSanitizer) {

    super(store, activatedRoute);

    const detailsValueChanges$: Observable<CategoryDetailsFieldsDefinition> = this.formDef.getControl('details').valueChanges;

    this.registerForAutoDestroy(this.translations$);

    this.registerForAutoDestroy(
      combineLatest([this.currentRestaurantPrimaryLanguage$, detailsValueChanges$])
        .pipe(
          tap((x) => {

            const formValue: MenuItemCategoryFormFieldsDefinition = this.formDef.form.getRawValue();
            const translations = this.buildCategoryTextsTranslations(x[0], x[1], formValue.translations)

            this.formDef.getControl('translations').patchValue(translations, {emitEvent: false});

            this.cdr.markForCheck();
          }),
        )
        .subscribe()
    );

    this.initTranslations();
  }

  private buildFormDefinition(): FormDefinition<keyof MenuItemCategoryFormFieldsDefinition> {
    const fieldsDef: FormFieldsDefinition<keyof MenuItemCategoryFormFieldsDefinition> = {
      details: new FormFieldDefinition(null,
                                       false,
                                       [],
                                       []),
      translations: new FormFieldDefinition(null,
                                            false,
                                            [],
                                            []),
    };

    return new FormDefinition<keyof MenuItemCategoryFormFieldsDefinition>(fieldsDef, this.formBuilder);
  }

  private getCurrentCategory(): Observable<MenuItemCategory | null> {
    return this.menuItemCategoriesService
               .currentRestaurantMenuItemCategories$
               .pipe(
                 filter(x => x.isLoaded()),
                 map(x => x.items),
                 switchMap(categories => {
                   return this.menuItemCategoryIdParam$
                              .pipe(
                                map((id: string | null) => {

                                  const idAsInt: number | null = parseInteger(id);

                                  if (idAsInt == null) {
                                    return null;
                                  }

                                  return categories.find(c => c.id === idAsInt) || null;
                                }),
                              );
                 }),
               );
  }

  private buildCategoryTextsTranslations(
    primaryLanguage: Language,
    categoryDetails: CategoryDetailsFieldsDefinition,
    currentTranslations: CategoryTranslationsFormFieldsDefinition | null): CategoryTranslationsFormFieldsDefinition {

    const translations: CategoryTranslationsFormFieldsDefinition = currentTranslations || {name: [], description: []};

    const namePrimaryLangTranslationIdx = translations.name.findIndex(
      (trn) => trn.langCode2.toLowerCase() === primaryLanguage.code.toLowerCase());
    const descPrimaryLangTranslationIdx = translations.description.findIndex(
      (trn) => trn.langCode2.toLowerCase() === primaryLanguage.code.toLowerCase());

    if (namePrimaryLangTranslationIdx >= 0) {
      translations.name[namePrimaryLangTranslationIdx].text = categoryDetails.name.text;
    } else {
      translations.name.push(categoryDetails.name);
    }

    if (descPrimaryLangTranslationIdx >= 0) {
      translations.description[descPrimaryLangTranslationIdx].text = categoryDetails.description.text;
    } else {
      translations.description.push(categoryDetails.description);
    }

    return translations;
  }

  ngOnInit(): void {
    this.registerForAutoDestroy(
      combineLatest([this.categoryToEdit$, this.currentRestaurant$])
        .pipe(
          tap((x: [MenuItemCategory | null, RestaurantInfoWithSettingsAndContactDetails]) => {

            const category: MenuItemCategory | null = x[0];
            const currentRestaurant: RestaurantInfoWithSettingsAndContactDetails = x[1];
            const image: CategoryImageFromServerModel | null =
              category == null
              ? null
              : category.image == null
                ? null
                : new CategoryImageFromServerModel(ImageFromServerModel.from(category.image)!,
                                                   ImageFromServerModel.from(category.thumb)!);


            const nameWithPrimaryLanguage: TextTranslationDefinition = {
              langCode2: currentRestaurant.settings.defaultMenuLanguage.code,
              text: category == null ? '' : category.name,
            };
            const descriptionWithPrimaryLanguage: TextTranslationDefinition = {
              langCode2: currentRestaurant.settings.defaultMenuLanguage.code,
              text: category == null ? '' : category.description,
            };
            const isMenuItemsThumbsVisible = category == null
                                             ? true
                                             : category.itemsThumbVisibility === MenuCategoryItemsThumbVisibilityDef.Visible;
            const details: CategoryDetailsFieldsDefinition = {
              isMenuItemsThumbsVisible,
              id: category == null ? null : category.id,
              description: descriptionWithPrimaryLanguage,
              name: nameWithPrimaryLanguage,
              image,
            };

            const translations: CategoryTranslationsFormFieldsDefinition = {
              name: [],
              description: []
            };

            // nameWithPrimaryLanguage
            // descriptionWithPrimaryLanguage

            if (category == null) {
              translations.name.push(nameWithPrimaryLanguage);
              translations.description.push(descriptionWithPrimaryLanguage);
            } else {
              const nameTranslations: TextTranslationDefinition[] = category.translations
                                                                            .map(trn => {
                                                                              return {
                                                                                langCode2: trn.langCode2,
                                                                                text: trn.name
                                                                              };
                                                                            });
              const descriptionTranslations: TextTranslationDefinition[] = category.translations
                                                                                   .map(trn => {
                                                                                     return {
                                                                                       langCode2: trn.langCode2,
                                                                                       text: trn.description
                                                                                     };
                                                                                   });

              const primaryLanguageNameTranslationsIdx = nameTranslations.findIndex(trn => trn.langCode2.toLowerCase() === currentRestaurant.settings.defaultMenuLanguage.code.toLowerCase());
              const primaryLanguageDescriptionTranslationsIdx = descriptionTranslations.findIndex(trn => trn.langCode2.toLowerCase() === currentRestaurant.settings.defaultMenuLanguage.code.toLowerCase());

              if (primaryLanguageNameTranslationsIdx >= 0) {
                nameTranslations[primaryLanguageNameTranslationsIdx] = nameWithPrimaryLanguage;
              } else {
                nameTranslations.push(nameWithPrimaryLanguage);
              }

              if (primaryLanguageDescriptionTranslationsIdx >= 0) {
                descriptionTranslations[primaryLanguageDescriptionTranslationsIdx] = descriptionWithPrimaryLanguage;
              } else {
                descriptionTranslations.push(descriptionWithPrimaryLanguage);
              }

              translations.name = nameTranslations;
              translations.description = descriptionTranslations;
            }

            this.formDef.patchValue({details, translations}, false);

            this.cdr.markForCheck();
          }),
        )
        .subscribe()
    );
  }

  private get menuItemCategoryIdParam$(): Observable<string | null> {
    return this.activatedRoute.paramMap.pipe(map(pm => pm.get('menuItemCategoryId')));
  }

  private initTranslations() {
    const initialTranslations: TranslationsDefinition = {
      addTitle: _('Add ingredient category'),
      updateTitle: _('Update ingredient category')
    };

    this.translations$.next(initialTranslations);

    const translationKeys = getArrayOfTranslationKeys(initialTranslations);

    this.trnService
        .stream(translationKeys)
        .pipe(
          map(translations => {
            const result: TranslationsDefinition = assignTranslatedProperties(initialTranslations, translations);

            return result;
          })
        )
        .subscribe(this.translations$);
  }

  public save() {
    if (this.formDef.form.invalid) {
      this.formDef.form.markAllAsTouched();
    }

    let actionToExecute: Observable<AddOrUpdateMenuItemCategoryResponse>;
    let unexpectedServerFailureMsg: string;
    let successMsg: string;
    const tryAgainLaterMsg = trnTryAgainLaterText(this.trnService);

    const formValue: MenuItemCategoryFormFieldsDefinition = this.formDef.form.getRawValue()
    const itemsThumbVisibility: MenuCategoryItemsThumbVisibilityDef = formValue.details.isMenuItemsThumbsVisible
                                                                      ? MenuCategoryItemsThumbVisibilityDef.Visible
                                                                      : MenuCategoryItemsThumbVisibilityDef.Hidden;
    const translations = this.convertTranslations(formValue.translations);
    const imageUpdated: boolean = !(formValue.details.image instanceof CategoryImageFromServerModel);
    const image: ImageWithBase64Def | null = (formValue.details.image instanceof CategoryImageFromServerModel) ? null : formValue.details.image;

    if (formValue.details.id != null) {
      const request: UpdateMenuItemCategoryRequest = {
        name: formValue.details.name.text,
        description: formValue.details.description.text,
        image,
        imageUpdated,
        itemsThumbVisibility,
        translations: translations,
      };

      actionToExecute = this.menuItemCategoriesService.update(formValue.details.id, request);
      unexpectedServerFailureMsg = _('Failed to update a menu item category because of unexpected failure.') + ' ' + tryAgainLaterMsg;
      successMsg = _('Successfully updated a menu item category.');
    } else {
      const request: AddMenuItemCategoryRequest = {
        name: formValue.details.name.text,
        description: formValue.details.description.text,
        image,
        itemsThumbVisibility,
        translations: translations,
      };

      actionToExecute = this.menuItemCategoriesService.add(request);
      unexpectedServerFailureMsg = _('Failed to add a menu item category because of unexpected failure.') + ' ' + tryAgainLaterMsg;
      successMsg = _('Successfully added a menu item category.');
    }


    const blockingModalDisplayToken = this.progressDialogService.display();

    actionToExecute.pipe(
      first(),
      tap((x: AddOrUpdateMenuItemCategoryResponse) => {
        switch (x.status) {
          case AddOrUpdateMenuItemCategoryResponse.StatusDef.Success:
            this.toastService.showSuccess(successMsg);
            break;
          case AddOrUpdateMenuItemCategoryResponse.StatusDef.ValidationFailed:
            this.progressDialogService.hide(blockingModalDisplayToken);

            this.formDef.setFormFieldsServerValidationResults(x.validationErrors);
            break;
          case AddOrUpdateMenuItemCategoryResponse.StatusDef.UnknownFailure:
            this.toastService.showError(unexpectedServerFailureMsg);
            break;
          default:
            throw new NeverError(x.status);
        }
      }),
      flatMap((response: AddOrUpdateMenuItemCategoryResponse) => {
        return this.menuItemCategoriesService.forceReload().pipe(map(tmp => response));
      }),
      flatMap((x: AddOrUpdateMenuItemCategoryResponse) => {
        if (x.status !== StatusDef.Success) {
          return EMPTY;
        }

        return this.restaurantIdParam$
                   .pipe(
                     switchMap(rid => {
                       const listUrl = RestaurantManagerRoutingConstants.getRestaurantMenuItemCategoriesListUrl(rid);

                       return from(this.router.navigateByUrl(listUrl));
                     }));
      }),
      catchError(
        genericErrorHandlerWithToast<any>(this.toastService, this.trnService, unexpectedServerFailureMsg)
      ),
      finalize(() => {
        this.progressDialogService.hide(blockingModalDisplayToken);
      }))
                   .subscribe();
  }

  private convertTranslations(source: CategoryTranslationsFormFieldsDefinition): MenuItemCategoryTranslationDefinition[] {
    const translations: { [langCode2: string]: {name: string, description: string} } = {};

    for (const trn of source.name) {
      const existingTranslation = translations[trn.langCode2];

      if (existingTranslation != null) {
        existingTranslation.name = trn.text;
      } else {
        translations[trn.langCode2] = {name: trn.text, description: ''};
      }
    }

    for (const trn of source.description) {
      const existingTranslation = translations[trn.langCode2];

      if (existingTranslation != null) {
        existingTranslation.description = trn.text;
      } else {
        translations[trn.langCode2] = {name: '', description: trn.text};
      }
    }

    const result: MenuItemCategoryTranslationDefinition[] = [];

    for (const key of Object.keys(translations)) {
      const trn = translations[key];

      result.push({langCode2: key, description: trn.description, name: trn.name});
    }

    return result;
  }

  public nameOrDescriptionChanged(): void {
    this.indicateNameOrDescriptionChange = true;
  }

  public cancel(): void {
    this.registerForAutoDestroy(
      this.restaurantIdParam$
          .pipe(
            first(),
            map(rid => RestaurantManagerRoutingConstants.getRestaurantMenuItemCategoriesListUrl(rid)),
            map(url => from(this.router.navigateByUrl(url))),
          )
          .subscribe()
    );
  }
}
