<div class="modal-header pd-y-20 pd-x-25">
  <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{{'Edit image' | translate}}</h6>
</div>
<div class="modal-body pd-0-force">
  <image-cropper
    [imageBase64]="base64ImageContent"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="false"
    [cropperMinWidth]="200"
    [cropperMinHeight]="200"
    [aspectRatio]="1"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (loadImageFailed)="imageLoaded()">
  </image-cropper>

</div>
<div class="modal-footer tx-white">
  <owc-progress-action-button [actionInProgress]="actionInProgress"
                              [disabled]="false"
                              (execute)="finishEdit()"
                              [btnText]="'Save' | translate"
                              type=link>
  </owc-progress-action-button>
</div>
