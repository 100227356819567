<form [formGroup]="formDef.form" (ngSubmit)="save()">
  <div class="modal-header pd-y-20 pd-x-25">
    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold" *ngIf="modifierGroupToEdit == null">{{'Add modifier group' | translate}}</h6>
    <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold" *ngIf="modifierGroupToEdit != null">{{'Update modifier group' | translate}}</h6>
    <button type="button" class="close" [attr.aria-label]="'Close'" (click)="activeModal.dismiss(null)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pd-25">

      <div class="row">
        <div class="col-12 col-lg-7">
          <div class="form-group">
            <label class="form-control-label" for="name">{{'Name' | translate}}: <span class="tx-danger">*</span></label>
            <input class="form-control" type="text" [formControl]="formDef.getControl('name')">

            <owc-input-validation-errors [control]="formDef.getControl('name')"></owc-input-validation-errors>
          </div>
        </div>

        <div class="col-12 col-lg-5">
          <div class="form-group">
            <label class="form-control-label" for="isVisible">{{'Name is visible to the guests' | translate}}: <span class="tx-danger">*</span></label>

            <ng-select [items]="visibilityItems$ | async"
                       [formControl]="formDef.getControl('isVisible')"
                       labelForId="isVisible"
                       bindLabel="name"
                       bindValue="value"
                       [virtualScroll]="true"
                       [clearable]="false"
                       [searchable]="false">
            </ng-select>

          </div>
        </div>
      </div>

      <div class="row" *ngIf="modifierGroupToEdit != null && modifierGroupToEdit.id != null">
        <div class="col-12 col-lg-5">
          <div class="form-group">
            <label class="form-control-label" for="isActive">{{'Is active' | translate}}: <span class="tx-danger">*</span></label>

            <ng-select [items]="activeItems$ | async"
                       [formControl]="formDef.getControl('isActive')"
                       labelForId="isActive"
                       bindLabel="name"
                       bindValue="value"
                       [virtualScroll]="true"
                       [clearable]="false">
            </ng-select>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-control-label">{{'How many modifiers can be selected' | translate}}:</label>

            <div class="mg-l-15" style="display: inline-block;">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="onlyOneModifierRadio" [value]="true" formControlName="isOnlyOnePermitted">
                <label class="form-check-label" for="onlyOneModifierRadio">{{'Only one' | translate}}</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="moreThanOneModifierRadio" [value]="false" formControlName="isOnlyOnePermitted">
                <label class="form-check-label" for="moreThanOneModifierRadio">{{'More than one' | translate}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="showMinMaxControls">
        <div class="col-xs-12 col-lg-6">
          <div class="form-group">
            <label class="form-control-label">{{'Min selectable elements' | translate}}: <span class="tx-danger">*</span></label>
            <input class="form-control"
                   type="text"
                   maxlength="2"
                   inputNumber
                   [formControl]="formDef.getControl('minAllowedAmount')"
                   [acceptDecimalPlaces]="true" />

            <owc-input-validation-errors [control]="formDef.getControl('minAllowedAmount')"></owc-input-validation-errors>
          </div>
        </div>

        <div class="col-xs-12 col-lg-6">
          <div class="form-group">
            <label class="form-control-label" for="maxAllowedAmount">{{'Max selectable elements' | translate}}:</label>
            <input class="form-control"
                   type="text"
                   maxlength="2"
                   inputNumber
                   [formControl]="formDef.getControl('maxAllowedAmount')"
                   [acceptDecimalPlaces]="false"/>

            <owc-input-validation-errors [control]="formDef.getControl('maxAllowedAmount')"></owc-input-validation-errors>
          </div>
        </div>
      </div>
  </div>
  <div class="modal-footer tx-white">
    <owc-progress-action-button [actionInProgress]="actionInProgress"
                                (execute)="save()"
                                [disabled]="formDef.form.invalid"
                                [btnText]="'Save' | translate"
                                autoFocus="true"
                                type="submit">
    </owc-progress-action-button>
  </div>
</form>
