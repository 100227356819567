import {Component, HostBinding, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TextTranslationDefinition} from './text-translation.definition';

@Component({
             selector: 'app-shared-text-input-with-language-flag',
             templateUrl: './text-input-with-language-flag.component.html',
             styleUrls: ['./text-input-with-language-flag.component.scss'],
             providers: [
               {
                 provide: NG_VALUE_ACCESSOR,
                 useExisting: TextInputWithLanguageFlagComponent,
                 multi: true,
               },
             ],
           })
export class TextInputWithLanguageFlagComponent implements ControlValueAccessor {

  @HostBinding('class.input-group')
  tempField: boolean = true;

  @Input()
  public mode: 'input' | 'textarea' = 'input';

  @Input()
  public maxlength: number | null = null;

  public lastValue: TextTranslationDefinition;

  public isDisabled: boolean = false;


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: any = (value: string | null) => {
  };

  onTouched: any = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: TextTranslationDefinition | null): void {
    this.lastValue = value == null
                     ? {langCode2: 'xx', text: ''}
                     : value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public changed(newText: string | null) {
    this.lastValue = {...this.lastValue, text: newText || ''};

    this.onTouched();
    this.onChange(this.lastValue);
  }
}
