import {OnDestroy} from '@angular/core';
import {combineLatest, ReplaySubject, Subject} from 'rxjs';
import {City, GetAllStaticDataResponse, Language, StaticDataService} from 'orderly-web-components';
import {TranslationsDefinition} from './translations-definition';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {assignTranslatedProperties, getArrayOfTranslationKeys} from '../../util/trn.utils';
import {first, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';


export class RegistrationBaseComponent implements OnDestroy {

  public cities$: ReplaySubject<City[]> = new ReplaySubject<City[]>(1);
  public languages$: ReplaySubject<Language[]> = new ReplaySubject<Language[]>(1);
  public translations$: ReplaySubject<TranslationsDefinition> = new ReplaySubject<TranslationsDefinition>(1);

  protected destroyed$: Subject<any> = new Subject<any>();


  constructor(private readonly staticDataService: StaticDataService,
              protected readonly trnService: TranslateService) {

    this.initTranslations();
  }



  private initTranslations() {

    const initialTranslations: TranslationsDefinition = {
      holdingNameLabel: _('Company/holding name'),
      holdingNameInputPlaceholder: _('Enter company/holding name'),
      holdingAddressLabel: _('Company/holding address'),
      holdingPhoneLabel: _('Company\'s/holding\'s contact phone'),

      restaurantNameLabel: _('Restaurant name'),
      restaurantInputPlaceholder: _('Enter restaurant name'),
      restaurantAddressLabel: _('Restaurant address'),
      restaurantPhoneLabel: _('Restaurant\'s contact phone'),

      acceptTermsAndConditions: _('Accept Terms & Conditions')
    };

    this.translations$.next(initialTranslations);

    const translationKeys: string[] = getArrayOfTranslationKeys(initialTranslations);

    this.trnService
        .stream(translationKeys)
        .pipe(
          map(translations => {
            const result: TranslationsDefinition = assignTranslatedProperties(initialTranslations, translations);

            return result;
          })
        )
        .subscribe(this.translations$);

    this.staticDataService
        .getAllStaticData(environment.baseApiUrlWithTrailingSlash)
        .pipe(
          map((x: GetAllStaticDataResponse) => x.adminSupportedLanguages.map(l => {
            const result: Language = {
              code: l.code.toLowerCase(),
              id: l.id,
              name: l.name
            };

            return result;
          })),
          first()
        )
        .subscribe(this.languages$);

    const langsAndCityTranslations$ = this.trnService
                                          .stream(['Vienna', 'Kyiv', 'Munich', 'Other']);

    const cities$ = this.staticDataService
                        .getAllStaticData(environment.baseApiUrlWithTrailingSlash)
                        .pipe(
                          map((x: GetAllStaticDataResponse) => x.cities)
                        );

    combineLatest([cities$, langsAndCityTranslations$])
      .pipe(
        map((data: [City[], any]) => {
          const nameTranslations = data[1];

          const cities = data[0].map(c => {
            const nameTranslation = nameTranslations[c.name] || c.name;

            const city: City = {
              id: c.id,
              countryCode2: c.countryCode2,
              name: nameTranslation,
            };

            return city;
          });

          const result = cities.sort((a, b) => {
            if (a.id === 1000 || b.id === 1000) {

              // special case for 'Other' to place it at the end of the list
              return a.id === 1000 ? 1 : -1;
            }

            return a.name.localeCompare(b.name);
          });

          return result;
        })
      )
      .subscribe(this.cities$)

  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();

    this.languages$.complete();
    this.cities$.complete();
    this.translations$.complete();
  }

}
