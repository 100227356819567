import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {first, switchMap, tap} from 'rxjs/operators';
import {AppState} from '../../store/app.state';

import Display = Orderly.RestaurantWeb.Api.Messages.Display.GetDisplaysResponse.Display;
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReportBrokenDisplayModalComponent} from '../../report-broken-display-modal/report-broken-display-modal.component';
import {AssignDisplayToTableModalComponent} from '../../assign-display-to-table-modal/assign-display-to-table-modal.component';
import {CurrentRestaurantDisplaysService} from '../../../services/active-route-bound/current-restaurant-displays.service';
import {PaginationFiltersStateDefinition} from 'orderly-web-components';
import {RestaurantRelatedItemsGrid} from '../restaurant-related-items-grid.component';

@Component({
  selector: 'app-displays-list',
  templateUrl: './displays-list.component.html',
  styleUrls: ['./displays-list.component.scss']
})
export class DisplaysListComponent extends RestaurantRelatedItemsGrid<Display, PaginationFiltersStateDefinition> {

  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private modalService: NgbModal,
              private restaurantDisplaysService: CurrentRestaurantDisplaysService) {

    super(store, activatedRoute, RestaurantRelatedItemsGrid.basicInitialFiltersState, restaurantDisplaysService.currentRestaurantDisplays$);
  }

  public assignDisplayToTable(display: Orderly.RestaurantWeb.Api.Messages.Display.GetDisplaysResponse.Display) {
    this.restaurantIdParam$
        .pipe(
          first(),
          tap(restaurantId => {
            const modalRef = this.modalService.open(AssignDisplayToTableModalComponent, {centered: true, size: 'lg'});

            const modalInstance: AssignDisplayToTableModalComponent = modalRef.componentInstance;

            modalInstance.display = display;
            modalInstance.restaurantId = restaurantId;

            modalRef.result
                    .then(x => {
                      if (x === true) {
                        // todo: reload displays & tables
                      }
                    });
          })
        )
        .subscribe();
  }

  public reportBrokenDisplay(display: Orderly.RestaurantWeb.Api.Messages.Display.GetDisplaysResponse.Display) {
    this.restaurantIdParam$
        .pipe(
          first(),
          tap(restaurantId => {
            const modalRef = this.modalService.open(ReportBrokenDisplayModalComponent, {centered: true, size: 'lg'});

            const modalInstance: ReportBrokenDisplayModalComponent = modalRef.componentInstance;

            modalInstance.display = display;
            modalInstance.restaurantId = restaurantId;

            modalRef.result
                    .then(x => {
                      if (x === true) {
                        this.restaurantDisplaysService.forceReload();
                      }
                    });
          })
        )
        .subscribe();
  }

  public requestMoreDisplays() {

  }

  public refreshDisplaysList() {
    this.restaurantDisplaysService
        .forceReload()
        .pipe(
          switchMap(x => this.filtersState$),
          first(),
          tap(filterState => {
            const currentFiltersStateClone = {...filterState};

            currentFiltersStateClone.currentPage = 1;

            this.pagingFiltersChanged(currentFiltersStateClone);
          })
        )
        .subscribe();
  }

}
