import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddOrEditMenuItemComponent} from './components/add-or-edit-menu-item/add-or-edit-menu-item.component';
import {EditableIngredientsListComponent} from './components/add-or-edit-menu-item/ingredients-tab/editable-ingredients-list/editable-ingredients-list.component';
import {ImagesListComponent} from './components/add-or-edit-menu-item/details-tab/images-list/images-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbNavModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {InputNumberModule} from '@deividfortuna/input-number';
import {ImageCropperModule} from 'ngx-image-cropper';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {
  CustomMissingTranslationHandler,
  TranslationHttpLoaderFactory,
  TranslationsModule
} from '../translations/translations.module';
import {OrderlyWebComponentsModule} from 'orderly-web-components';
import {NgSelectModule} from '@ng-select/ng-select';
import {JoinAllergenCodesPipe} from './pipes/join-allergen-codes-pipe';
import {ModifierGroupsListComponent} from './components/add-or-edit-menu-item/modifiers-tab/modifier-groups-list/modifier-groups-list.component';
import {ModifierGroupRowComponent} from './components/add-or-edit-menu-item/modifiers-tab/modifier-groups-list/modifier-group-row/modifier-group-row.component';
import {SharedModule} from '../shared/shared.module';
import {DetailsTabComponent} from './components/add-or-edit-menu-item/details-tab/details-tab.component';
import {IngredientsTabComponent} from './components/add-or-edit-menu-item/ingredients-tab/ingredients-tab.component';
import {ModifiersTabComponent} from './components/add-or-edit-menu-item/modifiers-tab/modifiers-tab.component';
import {ModifierRowComponent} from './components/add-or-edit-menu-item/modifiers-tab/modifier-groups-list/modifier-group-row/modifier-row/modifier-row.component';
import {PriceEditorComponent} from './components/add-or-edit-menu-item/details-tab/price-editor/price-editor.component';
import {EditablePricesListComponent} from './components/add-or-edit-menu-item/details-tab/price-editor/editable-prices-list/editable-prices-list.component';
import {ModifierGroupEditorComponent} from './components/add-or-edit-menu-item/modifiers-tab/modifier-group-editor/modifier-group-editor.component';
import {ModifiersCountSwitchComponent} from './components/add-or-edit-menu-item/modifiers-tab/modifier-group-editor/modifiers-count-switch/modifiers-count-switch.component';
import {ModifierEditorComponent} from './components/add-or-edit-menu-item/modifiers-tab/modifier-editor/modifier-editor.component';
import { TranslationsTabComponent } from './components/add-or-edit-menu-item/translations-tab/translations-tab.component';




@NgModule({
            declarations: [
              AddOrEditMenuItemComponent,
              EditableIngredientsListComponent,
              EditablePricesListComponent,
              ImagesListComponent,

              JoinAllergenCodesPipe,

              ModifierGroupsListComponent,

              ModifierGroupRowComponent,

              ModifierRowComponent,

              DetailsTabComponent,

              IngredientsTabComponent,

              ModifiersTabComponent,

              PriceEditorComponent,

              ModifierGroupEditorComponent,

              ModifiersCountSwitchComponent,

              ModifierEditorComponent,

              TranslationsTabComponent,
            ],
            imports: [
              CommonModule,
              FormsModule,
              ReactiveFormsModule,
              NgbTooltipModule,
              InputNumberModule,
              ImageCropperModule,
              TranslationsModule,
              NgbNavModule,

              TranslateModule.forRoot({
                                        loader: {
                                          provide: TranslateLoader,
                                          useFactory: TranslationHttpLoaderFactory,
                                          deps: [HttpClient]
                                        },
                                        missingTranslationHandler: {
                                          provide: MissingTranslationHandler,
                                          useClass: CustomMissingTranslationHandler
                                        },
                                        isolate: true
                                      }),
              OrderlyWebComponentsModule,
              NgSelectModule,
              SharedModule,
            ],
            entryComponents: [
              AddOrEditMenuItemComponent
            ],
            exports: [
              AddOrEditMenuItemComponent
            ]
          })
export class MenuItemsModule {}


export {AddOrEditMenuItemComponent};
