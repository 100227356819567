import { environment } from '../environments/environment';
import { switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user.service";
export class RestaurantService {
    constructor(httpClient, userService) {
        this.httpClient = httpClient;
        this.userService = userService;
        this.baseURL = `${environment.baseApiUrlWithTrailingSlash}v1.0`;
    }
    getRestaurant(restId) {
        const url = `${this.baseURL}/restaurant/${restId}`;
        return this.httpClient.get(url);
    }
    getAllHoldingRestaurants(restaurantHoldingId) {
        const url = `${this.baseURL}/restaurant-holding/${restaurantHoldingId}/restaurants/all`;
        return this.httpClient.get(url);
    }
    completeOnboarding(restaurantHoldingId) {
        const url = `${this.baseURL}/restaurant-holding/${restaurantHoldingId}/onboarding/complete`;
        return this.httpClient.post(url, {});
    }
    registerHoldingWithMenu(restaurant) {
        const url = `${this.baseURL}/restaurant-holding/register-simple-with-pdf`;
        const request = {
            languageCode2: restaurant.languageCode2,
            menu: {
                contentBase64: restaurant.menuFileContentBase64,
                name: restaurant.menuFileName
            },
            name: restaurant.name,
            ownerEmail: restaurant.email
        };
        const result = this.httpClient.post(url, request);
        return result;
    }
    registerHolding(restaurant) {
        const url = `${this.baseURL}/restaurant-holding/register`;
        const request = {
            address: restaurant.address,
            cityId: restaurant.cityId,
            contactPhone: restaurant.contactPhone,
            isChain: restaurant.isChain,
            languageCode2: restaurant.languageCode2,
            ownerFirstName: restaurant.firstName,
            ownerLastName: restaurant.lastName,
            ownerEmail: restaurant.email,
            ownerPassword: restaurant.password,
            name: restaurant.name,
            zipCode: restaurant.zipCode
        };
        const result = this.httpClient.post(url, request);
        return result;
    }
    createNewRestaurant(data) {
        return this.userService
            .getCurrentUser('from-cache')
            .pipe(switchMap(user => {
            const url = this.baseURL + `/restaurant-holding/${user.holdingInfo.id}/restaurant/add`;
            return this.httpClient.post(url, data);
        }));
    }
    updateRestaurant(restId, data) {
        const url = `${this.baseURL}/restaurant/${restId}/update`;
        return this.httpClient.post(url, data);
    }
}
RestaurantService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestaurantService_Factory() { return new RestaurantService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserService)); }, token: RestaurantService, providedIn: "root" });
