import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EnabledDisabledTranslations} from '../util/trn.utils';
import {Observable} from 'rxjs';

@Pipe({name: 'enabledDisabledText'})
export class EnabledDisabledTextPipe implements PipeTransform {
  constructor(private readonly trnService: TranslateService) {
  }

  transform(value: boolean): Observable<string> {
    if (value) {
      return this.trnService.get(EnabledDisabledTranslations.Enabled);
    }

    return this.trnService.get(EnabledDisabledTranslations.Disabled);
  }
}
