import { Component } from '@angular/core';
import IngredientCategory = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.AddOrUpdateIngredientCategoryResponse.IngredientCategory;
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {ActivatedRoute} from '@angular/router';
import {catchError, first, switchMap, tap} from 'rxjs/operators';
import {CurrentRestaurantIngredientCategoriesService} from '../../../services/active-route-bound/current-restaurant-ingredient-categories.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {
  NeverError, PaginationFiltersStateDefinition,
  ToastService
} from 'orderly-web-components';
import {genericErrorHandlerWithToast} from '../../../util/utils';
import {TranslateService} from '@ngx-translate/core';
import DeleteIngredientCategoryResponse = Orderly.RestaurantWeb.Api.Messages.RestaurantIngredients.DeleteIngredientCategoryResponse;
import {DeleteModalUtils} from '../../../util/delete-modal.utils';
import {AddOrEditIngredientCategoryComponent} from '../../add-or-edit-ingredient-category/add-or-edit-ingredient-category.component';
import {RestaurantRelatedItemsGrid} from '../restaurant-related-items-grid.component';
import {IngredientCategoryDeleteSuccessfulAction} from '../../store/actions/ingredient-category.actions';

@Component({
  selector: 'app-ingredient-categories-list',
  templateUrl: './ingredient-categories-list.component.html',
  styleUrls: ['./ingredient-categories-list.component.scss']
})
export class IngredientCategoriesListComponent extends RestaurantRelatedItemsGrid<IngredientCategory, PaginationFiltersStateDefinition> {

  constructor(store: Store<AppState>,
              activatedRoute: ActivatedRoute,
              private currentRestaurantIngredientCategoriesService: CurrentRestaurantIngredientCategoriesService,
              private toastService: ToastService,
              private trnService: TranslateService,
              private modalService: NgbModal,
              private deleteModalUtils: DeleteModalUtils) {

    super(store,
          activatedRoute,
          RestaurantRelatedItemsGrid.basicInitialFiltersState,
          currentRestaurantIngredientCategoriesService.currentRestaurantCategories$);
  }

  public updateCategoryIngredient(category: IngredientCategory) {
    const modalRef = this.modalService.open(AddOrEditIngredientCategoryComponent, {centered: true, size: 'xl'});
    const componentInstance: AddOrEditIngredientCategoryComponent = modalRef.componentInstance;

    componentInstance.categoryToEdit = category;

    componentInstance.completed
                     .pipe(
                       first(),
                       tap(x => {

                         category.name = x.name;
                       })
                     )
                     .subscribe();
  }

  public deleteCategoryIngredient(category: IngredientCategory) {
    const onDelete = (modalRef: NgbModalRef) => {

      const unexpectedErrorText = this.trnService.instant('Failed to delete an ingredient category because of unexpected error.');

      this.currentRestaurantIngredientCategoriesService
          .delete(category.id)
          .pipe(
            first(),
            tap(x => {
              switch (x.status) {
                case DeleteIngredientCategoryResponse.StatusDef.Success:

                  this.store.dispatch(new IngredientCategoryDeleteSuccessfulAction(x));

                  modalRef.close();
                  break;
                case DeleteIngredientCategoryResponse.StatusDef.UnknownFailure:
                  this.toastService.showError(unexpectedErrorText);
                  break;
                case DeleteIngredientCategoryResponse.StatusDef.DependenciesExist:
                  const dependenciesErrorText = this.trnService.instant(
                    'Failed to delete an ingredient category because of dependent records. ' +
                    'There are [{{categoryIngredientsCount}}] ingredients in this category.',
                    {categoryIngredientsCount: x.categoryIngredientsCount});

                  this.toastService.showError(dependenciesErrorText);
                  break;
                default:
                  throw new NeverError(x.status);
              }
            }),
            catchError(
              genericErrorHandlerWithToast(this.toastService, this.trnService, unexpectedErrorText)
            )
          )
          .subscribe();
    };

    const modalTitle = this.trnService.instant(`Delete ingredient category`);
    const modalConfirmText = this.trnService.instant(`Do you want to delete ingredient category '{{name}}'?`, {name: category.name});

    this.deleteModalUtils.showDeleteModal(category, modalTitle, [modalConfirmText], onDelete);
  }

  public addIngredientCategory() {
    this.modalService.open(AddOrEditIngredientCategoryComponent, {centered: true, size: 'xl'});
  }

  public refreshIngredientCategoriesList() {
    this.currentRestaurantIngredientCategoriesService
        .forceReload()
        .pipe(
          switchMap(x => this.filtersState$),
          first(),
          tap(filterState => {
            const currentFiltersStateClone = {...filterState};

            currentFiltersStateClone.currentPage = 1;

            this.pagingFiltersChanged(currentFiltersStateClone);
          })
        )
        .subscribe();
  }
}
